import React, { useEffect, useState } from 'react';
import 'devextreme/data/odata/store';
import { Row, Col, Button, Tabs, Form, message } from 'antd';
import PropTypes from 'prop-types';
import qs from 'querystring';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setInformationFromPaymentURL as setInformationFromPaymentURLAction } from '../../actions/auth';
import { setNotification as setNotificationAction } from '../../actions/help';
import './index.scss';

const Help = ({ setNotification, location, setInformationFromPaymentURL }) => {
  const { TabPane } = Tabs;
  const [form] = Form.useForm();
  const { foco_client, foco_payment_id, foco_token, foco_username } = qs.parse(
    location.search.replace('?', '')
  );
  const [savingEdition, setSavingEdition] = useState(false);
  useEffect(() => {
    if (foco_token) {
      setInformationFromPaymentURL(foco_client, foco_payment_id, foco_token, foco_username);
      sessionStorage.setItem('foco_token', foco_token);
    }
  });

  return (
    <div className="pnf" style={{ textAlign: 'center' }}>
      <Row gutter={[30, 30]}>
        <Col className="production-col mt-3 pt-0" span={24}>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Bienvenido al Soporte Focoenobra" key="1">
              <div style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
                Horario de atención
              </div>
              <div>Lunes - Viernes : 8:30 a 18:00 hrs</div>
              <div style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
                Canales de Atención
              </div>
              <div>
                <span style={{ marginRight: '10px' }}>
                  {' '}
                  <i className="fa fa-envelope-open fa-1x" />
                </span>
                <span style={{ marginRight: '20px' }}>soporte@focoenobra.cl</span>
                <span style={{ marginRight: '10px' }}>
                  {' '}
                  <i className="fa fa-phone fa-1x" /> 41 2285317
                </span>
              </div>
              <div>
                Si necesitas dejarnos un mensaje lo puedes realizar en el siguiente enlace :
              </div>
              <Form layout="vertical" form={form} loading={false}>
                <Form.Item
                  name="Message"
                  label=""
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese un mensaje',
                    },
                    {
                      validator: async (obj, value) => {
                        if (value.length && value.trim().length === 0) {
                          return Promise.reject(new Error('No se permiten solo espacios vacios'));
                        }

                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <textarea rows={5} cols={100} />
                </Form.Item>
                <div className="modal-footer">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={savingEdition}
                    onClick={() => {
                      setSavingEdition(true);
                      setNotification(form.getFieldValue('Message'))
                        .then(() => {
                          setSavingEdition(false);
                          form.setFieldsValue({ Message: '' });
                          message.success('Mensaje enviado correctamente');
                        })
                        .catch(() => {
                          setSavingEdition(false);
                        });
                    }}
                  >
                    Enviar Mensaje
                  </Button>
                </div>
              </Form>
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};
Help.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.object,
    token: PropTypes.string,
  }).isRequired,
  ItemsInRedux: PropTypes.shape({
    request: PropTypes.array,
  }).isRequired,
  setNotification: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  setInformationFromPaymentURL: PropTypes.func.isRequired,
};

export default connect(
  (store) => ({
    auth: store.auth,
    ItemsInRedux: store.Help,
  }),
  (dispatch) => ({
    setInformationFromPaymentURL: bindActionCreators(setInformationFromPaymentURLAction, dispatch),
    setNotification: bindActionCreators(setNotificationAction, dispatch),
  })
)(Help);
