import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import _ from 'lodash';
import 'moment/locale/es';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import update from 'immutability-helper';
import './index.scss';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import Table from '../../components/TableExtend';
import OptionsForm from './optionsForm';
import columns from './columns';
import { getChildren, getNodeObjectToUpdate } from '../../utils/budgetAndProductionHelpers';
import {
  getInputsTypes as getInputsTypesAction,
  getUnits as getUnitsAction,
} from '../../actions/budget';
import {
  getAccountOfCost as getAccountOfCostAction,
  getAccountOfCostItems as getAccountOfCostItemsAction,
} from '../../actions/accountOfCost';
import { getOptionScreen as getOptionScreenAction } from '../../actions/options';

const Options = ({ getInputsTypes, inputsTypes, access, getOptionScreen, options }) => {
  const [tree, setTree] = useState(null);
  const [expandedRowKeys, setExpandeRowKeys] = useState([]);
  const [familiesOptions, setFamiliesOptions] = useState(null);
  const [chapters, setChapters] = useState([]);
  const [activeChapter, setActiveChapter] = useState(null);

  const { data: optionsData, isFetching: isFetchingOptions } = options;

  const createTreeData = (treeData) => {
    if (treeData && treeData.length > 0) {
      let treeObject = {};

      const initialExpandedRowKeys = [];

      treeData.forEach((item) => {
        if (treeObject[item.ID_PADRE]) {
          treeObject = update(treeObject, {
            [item.ID_PADRE]: {
              $push: [item],
            },
          });
        } else {
          treeObject = update(treeObject, {
            [item.ID_PADRE]: {
              $set: [item],
            },
          });
        }

        if (item.ID_PADRE === 0) {
          initialExpandedRowKeys.push(item.ID_MENU);
        }
      });

      const newTree = [];
      const paths = {
        [treeObject[0][0].ID_MENU]: '0',
      };
      const savePath = (id, path) => {
        if (!paths[id]) {
          paths[id] = path;
        }
      };

      treeObject[0].forEach((current, i) => {
        newTree.push({
          ...current,
          PATH: `${i}`,
          children: getChildren(current.ID_MENU, treeObject, `${i}`, savePath),
        });
      });

      setTree(newTree);

      setExpandeRowKeys(initialExpandedRowKeys);
    }
  };

  useEffect(() => {
    getOptionScreen();
  }, []);

  useEffect(() => {
    createTreeData(optionsData);
  }, [optionsData]);

  useEffect(() => {
    if (!inputsTypes.data && !inputsTypes.isFetching) {
      getInputsTypes();
    } else if (!familiesOptions && inputsTypes.data) {
      setFamiliesOptions(inputsTypes.data);
    }
  }, [inputsTypes]);

  const changeExpandedRows = (expanded, ID_MENU) => {
    if (expanded) {
      setExpandeRowKeys(
        update(expandedRowKeys, {
          $push: [ID_MENU],
        })
      );
    } else {
      const index = expandedRowKeys.indexOf(ID_MENU);
      if (index >= 0) {
        setExpandeRowKeys(
          update(expandedRowKeys, {
            $splice: [[index, 1]],
          })
        );
      }
    }
  };

  // eslint-disable-next-line no-unused-vars
  const handleSearch = (value) => {};

  const addAccountOfCostToTree = (parent, element, oldParent) => {
    const parsedPath = parent.PATH ? parent.PATH.split('.') : null;
    const PATH = parent.PATH ? `${parent.PATH}.${parent.children.length}` : `${chapters.length}`;
    const newElement = {
      ...element,
      PATH,
    };

    let newTree = _.cloneDeep(tree);

    if (newElement.ES_CUENTA_COSTO === 1 && !chapters.find((e) => e.ID_MENU === element.ID_MENU)) {
      setChapters(update(chapters, { $push: [newElement] }));
    }

    const updateToDo = {
      $apply: (data) => {
        const newData = _.cloneDeep(data);
        newData.push({
          ...newElement,
          ...(activeChapter && activeChapter.children ? { children: activeChapter.children } : {}),
        });
        newData.sort((a, b) => {
          if (a.ES_CUENTA_COSTO < b.ES_CUENTA_COSTO) return 1;
          if (a.ES_CUENTA_COSTO > b.ES_CUENTA_COSTO) return -1;
          return 0;
        });
        return newData;
      },
    };
    const updatedNode =
      parsedPath && newElement.ID_MENU_SUP !== 0
        ? getNodeObjectToUpdate(parsedPath, 0, {
            children: updateToDo,
          })
        : updateToDo;

    newTree = update(newTree, updatedNode);
    if (oldParent) {
      const oldParentPath = oldParent.PATH ? oldParent.PATH.split('.') : null;
      const nodeTofind = oldParentPath ? oldParent.children : tree;
      const index = nodeTofind.findIndex((e) => e.ID_MENU === element.ID_MENU);
      const updateOperation = { $splice: [[index, 1]] };
      const deleteUpdate = oldParentPath
        ? getNodeObjectToUpdate(oldParentPath, 0, {
            children: updateOperation,
          })
        : updateOperation;
      newTree = update(newTree, deleteUpdate);
    }
    setTree(newTree);
    setActiveChapter(null);
  };

  const updateAccountOfCostInTree = (parent, element) => {
    const parsedPath = parent.PATH ? parent.PATH.split('.') : null;
    const nodeTofind = _.get(parent, 'children', tree);
    const index = nodeTofind.findIndex((e) => e.ID_MENU === element.ID_MENU);
    const updatedNode = parsedPath
      ? getNodeObjectToUpdate(parsedPath, 0, {
          children: { [index]: { $merge: element } },
        })
      : { [index]: { $merge: element } };

    const newTree = update(tree, updatedNode);
    setTree(newTree);
    setActiveChapter(null);
  };

  return (
    <div className="cost-center">
      <Row gutter={[30, 30]}>
        <Col className="cost-center-col" span={24}>
          <div className="buttons">
            <OptionsForm
              tree={tree}
              chapters={chapters}
              addAccountOfCostToTree={addAccountOfCostToTree}
              activeRecord={activeChapter}
              updateAccountOfCostInTree={updateAccountOfCostInTree}
              onCancel={() => setActiveChapter(null)}
            />
          </div>
          <Table
            className="common-table"
            autoHeight
            width={500}
            hover={false}
            rowKey="ID_MENU"
            bordered
            headerHeight={75}
            rowHeight={28}
            virtualized
            shouldUpdateScroll={false}
            isTree
            locale={{
              emptyMessage: 'No hay datos para mostrar',
              loading: 'Cargando...',
            }}
            loading={isFetchingOptions}
            data={tree || []}
            renderTreeToggle={(_icon, _row, expanded) => {
              if (expanded) {
                return <CaretUpOutlined />;
              }
              return <CaretDownOutlined />;
            }}
            expandedRowKeys={expandedRowKeys}
            onExpandChange={(expanded, rowData) => changeExpandedRows(expanded, rowData.ID_MENU)}
          >
            {columns({ setActiveChapter }, access)}
          </Table>
        </Col>
      </Row>
    </div>
  );
};

Options.propTypes = {
  getInputsTypes: PropTypes.func.isRequired,
  inputsTypes: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  getOptionScreen: PropTypes.func.isRequired,
  access: PropTypes.bool.isRequired,
};

export default connect(
  ({ production, works, accountOfCost, budget: { inputsTypes, units }, options }) => ({
    production,
    selectedWork: works.selectedWork,
    accountOfCostInfo: accountOfCost,
    inputsTypes,
    units,
    options,
  }),
  (dispatch) => ({
    getInputsTypes: bindActionCreators(getInputsTypesAction, dispatch),
    getAccountOfCost: bindActionCreators(getAccountOfCostAction, dispatch),
    getUnits: bindActionCreators(getUnitsAction, dispatch),
    getAccountOfCostItems: bindActionCreators(getAccountOfCostItemsAction, dispatch),
    getOptionScreen: bindActionCreators(getOptionScreenAction, dispatch),
  })
)(Options);
