import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Modal } from 'antd';
import Table from '../../../components/Table';
import NewWorkingDay from '../NewWorkingDay';
import { deleteSchedule as deleteScheduleAction } from '../../../actions/schedule';

import './index.scss';

const ScheduleList = ({ collationsType, deleteSchedule, schedules, workers }) => {
  const [infoToEdit, setInfoToEdit] = useState(null);
  const [infoToDelete, setInfoToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [workersInSchedule, setWorkersInSchedule] = useState([]);

  const canDelete = (record) => {
    const workersInTheSchedule = [];
    workers.forEach((e) => {
      if (e.ID_JORNADA === record.ID_JORNADA) {
        workersInTheSchedule.push(`${e.NOMBRE_TRA} ${e.APELLIDO_TRA}`);
      }
    });

    if (workersInTheSchedule.length) {
      setWorkersInSchedule(workersInTheSchedule);
    } else {
      setInfoToDelete(record);
    }
  };

  const columns = [
    {
      title: '',
      dataIndex: 'operation',
      width: '100px',
      render: (_, record) => {
        return (
          <div className="icons">
            <i className="fa fa-edit edit-icon" onClick={() => setInfoToEdit(record)} />
            <i className="fa fa-trash" onClick={() => canDelete(record)} />
          </div>
        );
      },
    },
    {
      title: 'Nombre Jornada',
      render: (_, record) => record.NOMBRE_JORNADA,
    },
    {
      title: 'Calendario',
      render: (_, record) => record.NOMBRE_CALENDARIO,
    },
    {
      title: 'Días Trabajados',
      dataIndex: 'TRABAJADOS',
    },
  ];

  const handleDelete = () => {
    setIsDeleting(true);
    deleteSchedule(infoToDelete.ID_JORNADA)
      .then(() => {
        setIsDeleting(false);
        setInfoToDelete(null);
      })
      .catch(() => {
        setIsDeleting(false);
        setInfoToDelete(null);
      });
  };

  return (
    <div className="scheduleList">
      <Table loading={false} dataSource={schedules} columnsInfo={columns} showSearch={false} />
      <Modal
        title="Eliminando jornada"
        visible={infoToDelete}
        onOk={() => setInfoToDelete(null)}
        onCancel={() => setInfoToDelete(null)}
        footer={[
          <Button key="back" disabled={isDeleting} onClick={() => setInfoToDelete(null)}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            disabled={isDeleting}
            loading={isDeleting}
            type="primary"
            onClick={() => handleDelete()}
          >
            Eliminar
          </Button>,
        ]}
      >
        <p style={{ textAlign: 'center' }}>{`¿Confirma que desea eliminar a ${
          infoToDelete && infoToDelete.NOMBRE_JORNADA
        }?`}</p>
      </Modal>
      {infoToEdit && (
        <NewWorkingDay
          collationsType={collationsType}
          infoToEdit={infoToEdit}
          onClose={() => setInfoToEdit(null)}
          title="Editar Jornada"
        />
      )}
      {workersInSchedule.length > 0 && (
        <Modal
          title="Advertencia"
          visible
          onOk={() => setWorkersInSchedule([])}
          onCancel={() => setWorkersInSchedule([])}
          footer={null}
        >
          <p>Los siguientes trabajadores están asociados a la jornada que se quiere eliminar:</p>
          <div style={{ marginLeft: '20px' }}>
            {workersInSchedule.map((e) => (
              <p>{e}</p>
            ))}
          </div>
        </Modal>
      )}
    </div>
  );
};

ScheduleList.propTypes = {
  collationsType: PropTypes.arrayOf(PropTypes.object).isRequired,
  deleteSchedule: PropTypes.func.isRequired,
  schedules: PropTypes.arrayOf(PropTypes.object).isRequired,
  workers: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default connect(
  () => ({}),
  (dispatch) => ({
    deleteSchedule: bindActionCreators(deleteScheduleAction, dispatch),
  })
)(ScheduleList);
