import update from 'immutability-helper';

export const GET_ACCOUNT_COST = 'GET_ACCOUNT_COST';
export const ACCOUNT_COST_FETCHED = 'ACCOUNT_COST_FETCHED';
export const GET_ACCOUNT_COST_ERROR = 'GET_ACCOUNT_COST_ERROR';

export const GET_ACCOUNT_COST_ITEMS = 'GET_ACCOUNT_COST_ITEMS';
export const GET_ACCOUNT_COST_CLASSIFICATION = 'GET_ACCOUNT_COST_CLASSIFICATION';
export const ACCOUNT_COST_ITEMS_FETCHED = 'ACCOUNT_COST_ITEMS_FETCHED';
export const GET_ACCOUNT_COST_ITEMS_ERROR = 'GET_ACCOUNT_COST_ITEMS_ERROR';
export const ACCOUNT_COST_CLASSIFICATION_FETCHED = 'ACCOUNT_COST_CLASSIFICATION_FETCHED';
export const GET_ACCOUNT_COST_CLASSIFICATION_ERROR = 'GET_ACCOUNT_COST_CLASSIFICATION_ERROR';

const accountOfCostInit = {
  data: {},
  isFetching: false,
  error: false,
  items: {
    data: null,
    isFetching: false,
    error: false,
  },
  classification: {
    data: null,
    isFetching: false,
    error: false,
  },
};

const accountOfCost = (state = accountOfCostInit, action) => {
  switch (action.type) {
    case GET_ACCOUNT_COST:
      return update(state, {
        isFetching: { $set: true },
      });

    case ACCOUNT_COST_FETCHED:
      return update(state, {
        data: {
          [action.workId]: {
            $set: action.accountOfCost,
          },
        },
        isFetching: { $set: false },
        error: { $set: false },
      });

    case GET_ACCOUNT_COST_ERROR:
      return update(state, {
        error: { $set: true },
        isFetching: { $set: false },
      });

    case GET_ACCOUNT_COST_ITEMS:
      return update(state, {
        items: {
          isFetching: { $set: true },
        },
      });

    case GET_ACCOUNT_COST_CLASSIFICATION:
      return update(state, {
        classification: {
          isFetching: { $set: true },
        },
      });

    case ACCOUNT_COST_ITEMS_FETCHED:
      return update(state, {
        items: {
          data: {
            $set: action.accountOfCostItems,
          },
          isFetching: { $set: false },
          error: { $set: false },
        },
      });

    case ACCOUNT_COST_CLASSIFICATION_FETCHED:
      return update(state, {
        classification: {
          data: {
            $set: action.classification,
          },
          isFetching: { $set: false },
          error: { $set: false },
        },
      });

    case GET_ACCOUNT_COST_ITEMS_ERROR:
      return update(state, {
        items: {
          isFetching: { $set: false },
          error: { $set: true },
        },
      });

    case GET_ACCOUNT_COST_CLASSIFICATION_ERROR:
      return update(state, {
        classification: {
          isFetching: { $set: false },
          error: { $set: true },
        },
      });

    case 'AUTH_LOGOUT':
      return accountOfCostInit;

    default:
      return state;
  }
};

export default accountOfCost;
