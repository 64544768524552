import _ from 'lodash';
import { message } from 'antd';
import api, { getHeaders } from '../utils/api';
import store from '../store';
import {
  SUMMARY_CAS_FETCHED,
  GET_SUMMARY_CAS,
  GET_SUMMARY_CAS_ERROR,
} from '../reducers/SummaryCas';

export const summaryCasFetched = (summaryCas, workId, attr) => ({
  type: SUMMARY_CAS_FETCHED,
  summaryCas,
  workId,
  attr,
});

export const getSummaryCas = (FECHA_CORTE, ID_OBR, TIPO_CONTRATO) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: GET_SUMMARY_CAS });

    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR,
      FECHA_CORTE,
      TIPO_CONTRATO,
    };
    api

      .post('api/CostoInformeCas/GetResumenInformeCas', requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch(summaryCasFetched(res.data, ID_OBR));
          resolve(res.data);
        } else {
          dispatch({
            type: GET_SUMMARY_CAS_ERROR,
          });
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_SUMMARY_CAS_ERROR,
        });
        reject(err);
      });
  });

export const setSummaryCas =
  (
    FECHA_CORTE,
    TIPO_CONTRATO,
    UTILIDAD_VIGENTE,
    UTILIDAD_FORECAST,
    PROFORMA_VIGENTE,
    PROFORMA_FORECAST,
    PORC_AVANCE_FISICO,
    REAJUSTE_ACUMULADO,
    UTILIDAD_ACUMULADA,
    OTRAS_VENTAS,
    BONOS_MO,
    OTRAS_VENTAS_ACUMULADO,
    BONOS_MO_ACUMULADO
  ) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      const ID_OBR = _.get(store.getState(), 'works.selectedWork', '');
      const errorMessage = 'Error al editar las celdas';
      const requestBody = {
        USUARIO: localStorage.getItem('foco_username'),
        EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
        ID_OBR,
        FECHA_CORTE,
        TIPO_CONTRATO,
        UTILIDAD_VIGENTE,
        UTILIDAD_FORECAST,
        PROFORMA_VIGENTE,
        PROFORMA_FORECAST,
        REAJUSTE_ACUMULADO,
        UTILIDAD_ACUMULADA,
        PORC_AVANCE_FISICO,
        OTRAS_VENTAS,
        BONOS_MO,
        OTRAS_VENTAS_ACUMULADO,
        BONOS_MO_ACUMULADO,
      };
      api
        .post('api/CostoInformeCas/SetResumenInformeCas', requestBody, getHeaders())
        .then((res) => {
          if (res.status === 200) {
            message.success('Celdas editadas correctamente', 8);
            getSummaryCas(FECHA_CORTE, ID_OBR, TIPO_CONTRATO)(dispatch);
            resolve(res.data);
          } else {
            message.error(errorMessage);
            reject(res.data);
          }
        })
        .catch(() => {
          dispatch({
            type: GET_SUMMARY_CAS_ERROR,
          });
        });
    });
