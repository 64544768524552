import CompanyList from '../Company/List';
import ProjectList from '../Project/List';
import Cost from '../CostCenter';
import AreasAndPositions from '../AreasAndPositions';
import BudgetControl from '../BudgetControl';
import Production from '../ProductionControl';
import Schedule from '../Schedule';
import Workers from '../Workers';
import AttendanceControl from '../AttendanceControl';
import Calendar from '../Calendar';
import PaymentStatesList from '../PaymentStates/List';
import PaymentStateManagement from '../PaymentStates/Management';
import PNF from '../PNF';
import CAS from '../CAS';
import JobCost from '../JobCost';
import OverheadProjection from '../OverheadProjection';
import OverheadProjectionV2 from '../overheadProjectionV2';
import BaseBudgetOP from '../BaseBudgetOP';
import Welcome from '../ClientsList/Welcome';
import CASCD from '../CASCD';
import RateScreen from '../RateScreen';
import TenderGG from '../TenderGG';
import ProfileScreen from '../Profile/List';
import Users from '../Users';
import Options from '../options';
import BiReport from '../BiReport/index';

export default [
  {
    path: '/home/welcome',
    Component: Welcome,
  },
  {
    path: '/home/company/list',
    Component: CompanyList,
  },
  {
    path: '/home/project/list',
    Component: ProjectList,
  },
  {
    path: '/home/cost/tree',
    Component: Cost,
    showWorksSelect: true,
  },
  {
    path: '/home/budget',
    Component: BudgetControl,
    showWorksSelect: true,
  },
  {
    path: '/home/jornada/list',
    Component: Schedule,
    showWorksSelect: true,
  },
  {
    path: '/home/trabajadores/list',
    Component: Workers,
    showWorksSelect: true,
  },
  {
    path: '/home/calendario/list',
    Component: Calendar,
    showWorksSelect: true,
  },
  {
    path: '/home/asistencia/list',
    Component: AttendanceControl,
    showWorksSelect: true,
  },
  {
    path: '/home/production',
    Component: Production,
    showWorksSelect: true,
  },
  {
    path: '/home/areas_cargos/list',
    Component: AreasAndPositions,
    showWorksSelect: true,
  },
  {
    path: '/home/payment_states',
    Component: PaymentStatesList,
    showWorksSelect: true,
  },
  {
    path: '/home/payment_state/:id',
    Component: PaymentStateManagement,
    showWorksSelect: true,
  },
  {
    path: '/home/profile',
    Component: ProfileScreen,
  },
  {
    path: '/home/pnf',
    Component: PNF,
    showWorksSelect: true,
  },
  {
    path: '/home/cas',
    Component: CAS,
    showWorksSelect: true,
  },
  {
    path: '/home/cascd',
    Component: CASCD,
    showWorksSelect: true,
  },
  {
    path: '/home/reportEmbed',
    Component: BiReport,
    showWorksSelect: true,
  },
  {
    path: '/home/jobCost',
    Component: JobCost,
    showWorksSelect: true,
  },
  {
    path: '/home/overheadProjection',
    Component: OverheadProjection,
    showWorksSelect: true,
  },
  {
    path: '/home/overheadProjectionV2',
    Component: OverheadProjectionV2,
    showWorksSelect: true,
  },
  {
    path: '/home/baseBudgetOP',
    Component: BaseBudgetOP,
    showWorksSelect: true,
  },
  {
    path: '/home/rate',
    Component: RateScreen,
    showWorksSelect: true,
  },
  {
    path: '/home/tendergg',
    Component: TenderGG,
    showWorksSelect: true,
  },
  {
    path: '/home/users',
    Component: Users,
  },
  {
    path: '/home/options',
    Component: Options,
  },
];
