import React, { useState, useEffect, useCallback } from 'react';
import { message, Modal, Button, Tabs, Input } from 'antd';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { TreeList } from '@progress/kendo-react-treelist';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { TdWrapper } from '../columns';
import api, { getHeaders } from '../../../utils/api';
import InputWithTopLabel from '../../../components/InputWithTopLabel';
import { getFormattedNumber } from '../../../utils';

import './index.scss';

const { TabPane } = Tabs;
const commonTableProps = {
  noRecords: 'No hay datos disponibles',
  style: {
    height: `450px`,
    width: `720px`,
    overflowY: 'auto',
  },
  tableProps: {
    style: {
      tableLayout: 'fixed',
      width: 700,
    },
  },
  rowHeight: 28,
  scrollable: 'virtual',
  expandField: 'expanded',
  subItemsField: 'children',
};

const EditableCell = ({ qty, save }) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(qty);
  }, [qty]);

  return (
    <td className="editable-name-cell k-border-right">
      <Input
        type="number"
        value={value}
        onBlur={() => value !== qty && save(value)}
        onPressEnter={() => value !== qty && save(value)}
        onChange={(e) => setValue(e.target.value)}
      />
    </td>
  );
};

EditableCell.propTypes = {
  qty: PropTypes.number.isRequired,
  save: PropTypes.func.isRequired,
};

const ContractView = ({ record, onCancel, user, client, canAdd, date, editRow }) => {
  if (!record) return null;

  const [loadingQty, setLoadingQty] = useState(false);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [saving, setSaving] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [dataQty, setDataQty] = useState([]);
  const [dataHistory, setDataHistory] = useState([]);
  const [fieldsToSave, setFieldsToSave] = useState([]);

  const { t } = useTranslation();

  const getQtyData = useCallback(async () => {
    setLoadingQty(true);
    const requestBody = {
      USUARIO: user,
      EMPRESA: client,
      ID_INS: record.ID_INS,
    };
    const response = await api.post('api/Avances/GetAvaCantidadMedida', requestBody, getHeaders());
    setLoadingQty(false);
    if (response && response.status === 200) {
      setDataQty(response.data);
    } else {
      message.error('Ocurrió un error al obtener la información');
    }
  }, [record]);

  const getHistoryData = useCallback(async () => {
    setLoadingHistory(true);
    const requestBody = {
      USUARIO: user,
      EMPRESA: client,
      ID_INS: record.ID_INS,
    };
    const response = await api.post(
      'api/Avances/GetAvaCantidadMedidaHistorial',
      requestBody,
      getHeaders()
    );
    setLoadingHistory(false);
    if (response && response.status === 200) {
      setDataHistory(response.data);
    } else {
      message.error(t('Ocurrió un error al obtener la información'));
    }
  }, [record]);

  useEffect(() => {
    getQtyData();
    getHistoryData();
  }, [record]);

  const save = () => {
    const requestBody = {
      USUARIO: user,
      EMPRESA: client,
      ID_INS: record.ID_INS,
      EDICION: fieldsToSave,
      FECHA_AVA: date,
    };
    setSaving(true);
    api
      .post('api/Avances/SetAvaCantidadMedidaMultiple', requestBody, getHeaders())
      .then((res) => {
        message.success('Información guardada correctamente');
        onCancel();
        setFieldsToSave([]);
        editRow([
          {
            ...res.data,
            PATH: record.PATH,
          },
        ]);
      })
      .catch((err) => {
        message.error(t('Ocurrió un error al guardar la información'));
      })
      .finally(() => setSaving(false));
  };

  const editCell = (qty, dataItem) => {
    const index = fieldsToSave.findIndex((e) => e.ID_PAR_UCO === dataItem.ID_PAR_UCO);
    if (index === -1) {
      setFieldsToSave([
        ...fieldsToSave,
        { ID_PAR_UCO: dataItem.ID_PAR_UCO, FACTOR_PAR_UCO: Number(qty) },
      ]);
    } else {
      setFieldsToSave(
        update(fieldsToSave, {
          [index]: {
            FACTOR_PAR_UCO: { $set: Number(qty) },
          },
        })
      );
    }
  };

  return (
    <Modal
      title="Edición de Cantidad Medida"
      visible
      footer={null}
      onCancel={onCancel}
      width={800}
      className="qty-modal"
    >
      <div className="pb-3 d-flex">
        <InputWithTopLabel
          disabled
          keepShowingLabel
          value={record.NOMBRE_INS}
          label="Nombre de Actividad"
          style={{ width: 250 }}
        />
        <InputWithTopLabel
          className="ml-3 disabled-input"
          disabled
          keepShowingLabel
          value={record.UM_EST}
          label="UM"
          style={{ width: 90 }}
        />
        <InputWithTopLabel
          className="ml-3 disabled-input"
          disabled
          keepShowingLabel
          value={record.CANT_INI}
          label="Cant. Adjudicada"
        />
        <InputWithTopLabel
          className="ml-3 disabled-input"
          disabled
          keepShowingLabel
          value={record.CANT_OFICIAL}
          label="Cant. Vigente"
        />
        <InputWithTopLabel
          className="ml-3 disabled-input"
          disabled
          keepShowingLabel
          value={record.CANT_MEDIDA}
          label="Cant. Medida"
        />
      </div>
      {canAdd && (
        <div className="d-flex w-100 justify-content-end mt-3">
          <Button className="ml-2" type="primary" onClick={() => {}}>
            Agregar nota de cambio
          </Button>
        </div>
      )}
      <Tabs activeKey={activeTab} onChange={setActiveTab}>
        <TabPane tab="Editar Cantidades" key="1">
          <TreeList
            {...commonTableProps}
            className={`kendo-table mx-auto ${loadingQty ? 'loading' : ' '}`}
            data={dataQty}
            columns={[
              {
                field: 'NOMBRE_UCO',
                title: 'Recinto',
                width: 240,
                cell: ({ dataItem }) => {
                  return (
                    <TdWrapper record={dataItem} className="text-align-center">
                      {dataItem.NOMBRE_UCO}
                    </TdWrapper>
                  );
                },
              },
              {
                field: 'NOMBRE_INS',
                title: 'Actividad de Avance',
                width: 240,
                cell: ({ dataItem }) => {
                  return (
                    <TdWrapper record={dataItem} className="text-align-center">
                      {dataItem.NOMBRE_INS}
                    </TdWrapper>
                  );
                },
              },
              {
                field: 'FACTOR_PAR_UCO',
                title: 'Cant. Medida',
                width: 140,
                cell: ({ dataItem }) => {
                  const fieldToSave = (
                    fieldsToSave.find((field) => field.ID_PAR_UCO === dataItem.ID_PAR_UCO) || {}
                  ).FACTOR_PAR_UCO;
                  const qty = fieldToSave ?? dataItem.FACTOR_PAR_UCO;
                  return (
                    <TdWrapper record={dataItem} className="text-align-center">
                      <EditableCell save={(value) => editCell(value, dataItem)} qty={qty} />
                      {/*          <span title={getFormattedNumber(dataItem.FACTOR_PAR_UCO)}>
                          {getFormattedNumber(dataItem.FACTOR_PAR_UCO, 3)}
                        </span> */}
                    </TdWrapper>
                  );
                },
              },
            ]}
          />
        </TabPane>
        <TabPane tab="Historial de ajustes" key="2">
          <TreeList
            {...commonTableProps}
            className={`kendo-table mx-auto ${loadingHistory ? 'loading' : ' '}`}
            data={dataHistory}
            columns={[
              {
                field: 'NOMBRE_UCO',
                title: 'Recinto',
                width: 180,
                cell: ({ dataItem }) => {
                  return (
                    <TdWrapper record={dataItem} className="text-align-center">
                      {dataItem.NOMBRE_UCO}
                    </TdWrapper>
                  );
                },
              },
              {
                field: 'NOMBRE_INS',
                title: 'Actividad de Avance',
                width: 220,
                cell: ({ dataItem }) => {
                  return (
                    <TdWrapper record={dataItem} className="text-align-center">
                      {dataItem.NOMBRE_INS}
                    </TdWrapper>
                  );
                },
              },
              {
                field: 'FACTOR_PAR_UCO',
                title: 'Cant. Medida',
                width: 100,
                cell: ({ dataItem }) => {
                  return (
                    <TdWrapper record={dataItem} className="text-align-center">
                      <span title={getFormattedNumber(dataItem.FACTOR_PAR_UCO)}>
                        {getFormattedNumber(dataItem.FACTOR_PAR_UCO, 3)}
                      </span>
                    </TdWrapper>
                  );
                },
              },
              {
                field: 'NOMBRE_USU',
                title: 'Ajustado por',
                width: 100,
                cell: ({ dataItem }) => {
                  return (
                    <TdWrapper record={dataItem} className="text-align-center">
                      {dataItem.NOMBRE_USU}
                    </TdWrapper>
                  );
                },
              },
              {
                field: 'FECHA_AVA',
                title: 'Control al',
                width: 100,
                cell: ({ dataItem }) => {
                  return (
                    <TdWrapper record={dataItem} className="text-align-center">
                      {dataItem.FECHA_AVA}
                    </TdWrapper>
                  );
                },
              },
            ]}
          />
        </TabPane>
      </Tabs>
      <div className="d-flex justify-content-end mt-4">
        <Button
          disabled={saving}
          onClick={() => {
            onCancel();
            setFieldsToSave([]);
          }}
        >
          Cancelar
        </Button>
        <Button loading={saving} className="ml-2" type="primary" onClick={save}>
          Guardar
        </Button>
      </div>
    </Modal>
  );
};

ContractView.propTypes = {
  record: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  work: PropTypes.object.isRequired,
  user: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  canAdd: PropTypes.bool,
  date: PropTypes.string.isRequired,
  editRow: PropTypes.func.isRequired,
};

ContractView.defaultProps = {
  canAdd: false,
};

export default ContractView;
