import update from 'immutability-helper';

export const SUMMARY_CAS_FETCHED = 'SUMMARY_CAS_FETCHED';
export const GET_SUMMARY_CAS = 'GET_SUMMARY_CAS';
export const GET_SUMMARY_CAS_ERROR = 'GET_SUMMARY_CAS_ERROR';

const summaryCasInit = {
  data: {},
  isFetching: false,
  error: false,
};

const summaryCas = (state = summaryCasInit, action) => {
  switch (action.type) {
    case 'WORKS_FETCHED': {
      let newCas = { ...state };
      action.works.forEach(({ ID_OBR }) => {
        newCas = update(newCas, {
          data: {
            [ID_OBR]: { $set: null },
          },
        });
      });

      return newCas;
    }

    case SUMMARY_CAS_FETCHED: {
      return update(state, {
        data: {
          [action.workId]: {
            $set: action.summaryCas,
          },
        },
        isFetching: { $set: false },
        error: { $set: false },
      });
    }

    case GET_SUMMARY_CAS_ERROR:
      return update(state, { error: { $set: true }, isFetching: { $set: false } });
    case GET_SUMMARY_CAS:
      return {
        ...state,
        isFetching: true,
        error: false,
      };

    case 'AUTH_LOGOUT':
      return summaryCasInit;

    default:
      return state;
  }
};

export default summaryCas;
