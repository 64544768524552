import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Popover, Row, Col, Switch, Select, Tooltip, Form } from 'antd';
import update from 'immutability-helper';
import { InfoCircleOutlined, CloseOutlined } from '@ant-design/icons';
import InputWithTopLabel from '../../../components/InputWithTopLabel';
import SelectWithTopLabel from '../../../components/SelectWithTopLabel';
import ViewOptions from '../ViewOptions';
import MassAttendance from '../MassAttendance';
import NewWorker from '../../Workers/NewWorker';
import { months, SearchIcon } from '../../../utils';

import './index.scss';

const NewButton = ({
  attendanceTypes,
  attendanceViewType,
  closedAttendance,
  className,
  handleAttendance,
  icon,
  form,
  name,
  onClick,
  setViewOptions,
  supervisors,
  typeButton,
  viewOptions,
}) => {
  const [showForm, toggleForm] = useState(false);

  return (
    <div className={`buttonContainer ${className}`}>
      <Button
        type={`${typeButton} btn-block`}
        style={typeButton === 'secondary' ? { color: 'white', backgroundColor: 'gray' } : {}}
        onClick={() => {
          if (onClick) {
            onClick();
          } else {
            toggleForm(!showForm);
          }
        }}
      >
        {icon}
        {name}
      </Button>
      {showForm && form === 'ViewOptions' && (
        <ViewOptions
          onClose={() => toggleForm(false)}
          onSave={setViewOptions}
          options={viewOptions}
        />
      )}
      {showForm && form === 'MassAttendance' && (
        <MassAttendance
          attendanceTypes={attendanceTypes}
          attendanceViewType={attendanceViewType}
          closedAttendanceSet={new Set(closedAttendance)}
          handleAttendance={handleAttendance}
          onClose={() => toggleForm(false)}
        />
      )}
      {showForm && form === 'NewWorker' && (
        <NewWorker onClose={() => toggleForm(false)} supervisors={supervisors} />
      )}
    </div>
  );
};

NewButton.defaultProps = {
  attendanceTypes: [],
  attendanceViewType: 1,
  closedAttendance: [],
  className: '',
  form: '',
  handleAttendance: () => {},
  icon: null,
  onClick: null,
  setViewOptions: () => {},
  supervisors: [],
  typeButton: 'primary',
  viewOptions: [],
};

NewButton.propTypes = {
  attendanceTypes: PropTypes.arrayOf(PropTypes.object),
  attendanceViewType: PropTypes.number,
  closedAttendance: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  handleAttendance: PropTypes.func,
  form: PropTypes.string,
  icon: PropTypes.element,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  setViewOptions: PropTypes.func,
  supervisors: PropTypes.arrayOf(PropTypes.object),
  typeButton: PropTypes.string,
  viewOptions: PropTypes.arrayOf(PropTypes.object),
};

const Header = ({
  attendanceTypes,
  attendanceViewType,
  closedAttendance,
  editSupervisorState,
  handleAttendance,
  handleExportData,
  handleFilters,
  handleFiltersButton,
  handleMassEditSupervisor,
  filters,
  month,
  setMonth,
  setViewOptions,
  setYear,
  supervisors,
  viewOptions,
  year,
  getWorks,
  getMenu,
  getCommunes,
  menu,
  communes,
  history,
  works,
  setSelectedWork,
  savingExcel,
  setState,
  state,
}) => {
  const years = [];
  for (let i = moment().year() - 5; i <= moment().year(); i++) {
    years.push({ name: i, value: i });
  }
  years.reverse();

  const [visible, setVisible] = useState(false);
  const [stateSwitch, setStateSwitch] = useState(false);

  const titlePopover = () => (
    <Row align="middle">
      <Col span={20} className="text-center">
        Más Filtros
      </Col>
      <Col span={2}>
        <Button
          type="text"
          icon={<CloseOutlined style={{ fontSize: '14px' }} />}
          style={{ marginBottom: '10px' }}
          className="border-0"
          onClick={() => setVisible(false)}
        />
      </Col>
    </Row>
  );
  const content = () => (
    <div>
      <InputWithTopLabel
        className="marginRight pb-3"
        icon={<SearchIcon />}
        label="Área"
        onChange={(value) => handleFilters('area', value)}
        // value={filters.area}
      />
      <InputWithTopLabel
        className="marginRight pb-3"
        icon={<SearchIcon />}
        label="Cargo"
        onChange={(value) => handleFilters('position', value)}
        // value={filters.position}
      />
      <InputWithTopLabel
        className="marginRight pb-3"
        icon={<SearchIcon />}
        label="Trabajador"
        onChange={(value) => handleFilters('name', value)}
        // value={filters.name}
      />

      {!stateSwitch && (
        <InputWithTopLabel
          className="marginRight pb-3"
          icon={<SearchIcon />}
          onChange={(value) => handleFilters('supervisorName', value)}
          label="Supervisor"
          // value={filters.supervisorName}
        />
      )}
      <Tooltip placement="topLeft" title="Filtrar datos">
        <span className="value">
          <NewButton className="col" name="Aplicar" onClick={handleFiltersButton} />
        </span>
      </Tooltip>
      {/* <Row>
        <Switch
          onChange={(checked) => {
            setStateSwitch(!stateSwitch);
            const filterText = checked ? 'Sin Definir' : '';
            return handleFilters('supervisorName', filterText);
          }}
        />
        Trabajadores Sin Supervisor
      </Row> */}
    </div>
  );

  const contentInfo = (
    <>
      {attendanceTypes?.map((attendanceType) => (
        <Row key={`legend${attendanceType.ID_CNS}`} className="paddingRowPopover ">
          <Col span={3}>
            <div
              className="typesAttendancePopover  pl-1"
              style={{ backgroundColor: attendanceType.ID_CNS === 5 ? '#6aa84f' : '#007bff' }}
            >
              {attendanceViewType === 2 && attendanceType.ID_CNS === 5
                ? '1'
                : attendanceType.ABREVIA_CNS}
            </div>
          </Col>
          <Col span={21}>
            {attendanceViewType === 2 && attendanceType.ID_CNS === 5
              ? 'JORNADA TRABAJADA'
              : attendanceType.NOMBRE_CNS}
          </Col>
        </Row>
      ))}
      <Row key={`legend${attendanceTypes.length + 1}`} className="paddingRowPopover">
        <Col span={3}>
          <div
            className="typesAttendancePopover"
            style={{ color: '#fadcab', backgroundColor: '#fadcab' }}
          >
            SS
          </div>
        </Col>
        <Col span={21}>SIN SUPERVISOR</Col>
      </Row>
    </>
  );

  // eslint-disable-next-line no-return-assign
  return (
    <div className="attendanceControlHeader pr-2 pl-2">
      <div className="d-flex flex-row w-100">
        <Form layout="inline">
          <Form.Item
            name="Proyecto"
            label="Proyecto"
            initialValue={works.selectedWork ? String(works.selectedWork) : undefined}
          >
            <Select
              className="selected mr-4"
              onChange={(value) => setSelectedWork(Number(value))}
              defaultValue={works.selectedWork ? String(works.selectedWork) : undefined}
              disabled={!works.data || !works.data.length}
            >
              {works.data.map((work) => (
                <Select.Option value={work.ID_OBR} key={`work${work.ID_OBR}`}>
                  {work.NOMABR_OBR}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
        <SelectWithTopLabel
          className="mr-4"
          label="Año"
          onChange={setYear}
          options={years}
          value={year}
          width={120}
        />
        <SelectWithTopLabel
          className="col-1 col-md"
          label="Mes"
          onChange={setMonth}
          options={months}
          value={month}
          width={120}
        />

        <Popover
          placement="bottom"
          title={titlePopover}
          content={content}
          trigger="click"
          visible={visible}
          onClick={() => setVisible(true)}
        >
          <Tooltip placement="topLeft" title="Filtros adicionales">
            <span className="value">
              <NewButton name="Más Filtros" className="col-2 col-md" />
            </span>
          </Tooltip>
        </Popover>
        {editSupervisorState ? (
          <>
            <Tooltip placement="topLeft" title="Guardar los datos modificados">
              <span className="value">
                <NewButton
                  className="col-2 col-md"
                  name="Guardar"
                  onClick={() => handleMassEditSupervisor(true)}
                />
              </span>
            </Tooltip>
            <Tooltip placement="topLeft" title="Cancelar modificación de datos">
              <span className="value">
                <NewButton
                  className="col-2 col-md"
                  name="Cancelar"
                  typeButton="secondary"
                  onClick={() => handleMassEditSupervisor(false)}
                />
              </span>
            </Tooltip>
          </>
        ) : (
          <>
            <Tooltip placement="topLeft" title="Permite la edición de supervisores">
              <span className="value">
                <NewButton
                  className="col-2 col-md"
                  name="Edición Masiva Supervisor"
                  onClick={handleMassEditSupervisor}
                />
              </span>
            </Tooltip>
            <Tooltip
              placement="topLeft"
              title="Permite la asignación de asistencia masiva por día y rango de fechas"
            >
              <span className="value">
                <NewButton
                  className="col-2 col-md"
                  attendanceTypes={attendanceTypes}
                  attendanceViewType={attendanceViewType}
                  closedAttendance={closedAttendance}
                  handleAttendance={handleAttendance}
                  name="Asistencia Masiva"
                  form="MassAttendance"
                />
              </span>
            </Tooltip>
            <Tooltip placement="topLeft" title="Agregar nuevo trabajador">
              <span className="value">
                <NewButton
                  className="col-2 col-md"
                  icon={<i className="fa fa-plus iconSpace" />}
                  name="Trabajador"
                  form="NewWorker"
                  supervisors={supervisors}
                />
              </span>
            </Tooltip>
            <Tooltip placement="topLeft" title="Descargar Excel">
              <span className="value">
                <Button
                  loading={savingExcel}
                  type="primary"
                  onClick={() => {
                    setState(update(state, { savingExcel: { $set: true } }));
                  }}
                >
                  Exportar
                </Button>
              </span>
            </Tooltip>
          </>
        )}
        <Tooltip placement="topLeft" title="Seleccione las columnas que desea Visualizar">
          <span className="value">
            <NewButton
              className="col pr-4 "
              name="Vista"
              form="ViewOptions"
              setViewOptions={setViewOptions}
              viewOptions={viewOptions}
            />
          </span>
        </Tooltip>
        <div className="title">
          <Row>
            <Col span={1}>
              <Popover
                placement="bottomRight"
                title="Leyendas"
                content={contentInfo}
                trigger="click"
              >
                <Button
                  type="primary"
                  shape="circle"
                  className="pb-4 pt-0"
                  icon={<InfoCircleOutlined style={{ fontSize: '30px' }} />}
                />
              </Popover>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  attendanceTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  attendanceViewType: PropTypes.number.isRequired,
  closedAttendance: PropTypes.arrayOf(PropTypes.string).isRequired,
  editSupervisorState: PropTypes.bool.isRequired,
  handleAttendance: PropTypes.func.isRequired,
  handleExportData: PropTypes.func.isRequired,
  handleFilters: PropTypes.func.isRequired,
  handleFiltersButton: PropTypes.func.isRequired,
  handleMassEditSupervisor: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  month: PropTypes.number.isRequired,
  savingExcel: PropTypes.bool.isRequired,
  state: PropTypes.object.isRequired,
  setMonth: PropTypes.func.isRequired,
  setViewOptions: PropTypes.func.isRequired,
  setYear: PropTypes.func.isRequired,
  supervisors: PropTypes.arrayOf(PropTypes.object).isRequired,
  viewOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  year: PropTypes.number.isRequired,
  setSelectedWork: PropTypes.func.isRequired,
  setState: PropTypes.func.isRequired,
  getCommunes: PropTypes.func.isRequired,
  getMenu: PropTypes.func.isRequired,
  getWorks: PropTypes.func.isRequired,
  works: PropTypes.shape({
    data: PropTypes.array,
    selectedWork: PropTypes.number,
    isFetching: PropTypes.bool,
  }).isRequired,
  menu: PropTypes.shape({
    data: PropTypes.array,
    isFetching: PropTypes.bool,
  }).isRequired,
  communes: PropTypes.shape({
    data: PropTypes.array,
    isFetching: PropTypes.bool,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
};

export default Header;
