import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import update from 'immutability-helper';
import { Row, Col, Tabs, Button, message } from 'antd';
import get from 'lodash/get';
import 'moment/locale/es';
import locale from 'antd/es/date-picker/locale/es_ES';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DatePickerWithTopLabel from '../../components/DatePickerWithTopLabel';
import { getInputsTypes as getInputsTypesAction } from '../../actions/budget';
import { getCasCd as getCasCdAction, editCasCd } from '../../actions/casCd';
import { getCasGG as getCasGGAction } from '../../actions/casGG';
import { getSummaryCas as getSummaryCasAction } from '../../actions/SummaryCas';
import { getAccountOfCost as getAccountOfCostAction } from '../../actions/accountOfCost';
import ViewOptionsRadio from '../../components/ViewOptionsRadio';
import './index.scss';
import TableCD from './TableCD';
import TableGG from './TableGG';
import SummaryCas from './SummaryCas';
import InputWithTopLabel from '../../components/InputWithTopLabel';
import ModalSummary from './modalSummaryCas';
import { getChildren, getNodeObjectToUpdate } from '../../utils/budgetAndProductionHelpers';

import exportExcel from './exportExcel';

const { TabPane } = Tabs;

moment.locale('es');

const CASCD = ({
  selectedWork,
  casCd,
  casGG,
  getCasCd,
  getCasGG,
  getInputsTypes,
  inputsTypes,
  summaryCas,
  getSummaryCas,
}) => {
  const [active, setActive] = useState(null);
  const [casDate, setCasDate] = useState(moment());
  const [casDateInic, setCasDateInic] = useState(moment());
  const [casDateEnd, setCasDateEnd] = useState(moment());
  const [familiesOptions, setFamiliesOptions] = useState(null);
  const [activeTab, setActiveTab] = useState('1');
  const [activeOption, setActiveOption] = useState(0);
  const [isEditingCasCd, setIsEditingCasCd] = useState(false);
  const [fieldsToSave, setFieldsToSave] = useState([]);
  const [cancelEditionClicked, setCancelEditionClicked] = useState(false);
  const [inputValue, setInputValue] = useState({
    GteProyecto: '',
    IngAdministrador: '',
    DuracionObra: '',
    NMes: '',
    AvanceProgramado: '',
    AvanceFisicoReal: '',
  });
  const [treeCd, setTreeCd] = useState(null);
  const [treeGg, setTreeGg] = useState(null);
  const [lastSavedTreeCd, setLastSavedTreeCd] = useState(null);
  const [expandedRowKeysCd, setExpandeRowKeysCd] = useState([]);
  const [expandedRowKeysGg, setExpandeRowKeysGg] = useState([]);

  const { GteProyecto, IngAdministrador, DuracionObra, NMes, AvanceFisicoReal } = inputValue;

  const handleInputChange = (value, key) => {
    setInputValue({ ...inputValue, [key]: value });
  };

  const { data: dataSummaryCas } = summaryCas;

  useEffect(() => {
    if (dataSummaryCas && dataSummaryCas[selectedWork]) {
      setInputValue({
        GteProyecto: dataSummaryCas[selectedWork].GTE_PROYECTO,
        IngAdministrador: dataSummaryCas[selectedWork].ING_ADMINISTRADOR,
        DuracionObra: dataSummaryCas[selectedWork].DURACION,
        NMes: dataSummaryCas[selectedWork].N_MES,
        AvanceProgramado: dataSummaryCas[selectedWork].PRC_PROGRAMADO,
        AvanceFisicoReal: dataSummaryCas[selectedWork].PRC_FISICO,
      });
      setCasDateInic(moment(dataSummaryCas[selectedWork].FECHA_INICIO, 'DDMMYYYY'));
      setCasDateEnd(moment(dataSummaryCas[selectedWork].FECHA_TERMINO, 'DDMMYYYY'));
    }
  }, [dataSummaryCas]);

  const { isFetching } = casCd;

  const onChangeDate = (value) => {
    setCasDate(value);
    getCasCd(value.format('DD-MM-YYYY'), selectedWork);
    getCasGG(value.format('DD-MM-YYYY'), selectedWork);
  };

  const onChangeDateInic = (value) => {
    setCasDateInic(value);
  };
  const saveCd = () => {
    message.loading('Guardando...');
    const data = fieldsToSave.map((field) => ({
      ID_CC: Math.abs(field.ID_CC),
      CODIGO_CC: field.CODIGO_CC,
      ID_TIN: field.ID_TIN,
      MONTO: field.PROY_CU_SALDO,
      COMENTARIO: field.PROYECCION_MANUAL_COMENTARIO,
    }));

    editCasCd(casDate, selectedWork, data).then(() => {
      message.success('Guardado con éxito');
      getCasCd(casDate.format('DD-MM-YYYY'), selectedWork);
      setIsEditingCasCd(false);
      setFieldsToSave([]);
    });
  };
  const onChangeDateEnd = (value) => {
    setCasDateEnd(value);
  };

  useEffect(() => {
    if (!inputsTypes.data && !inputsTypes.isFetching) {
      getInputsTypes();
    } else if (!familiesOptions && inputsTypes.data) {
      setFamiliesOptions(inputsTypes.data);
    }
  }, [inputsTypes]);

  const getCommonProps = (key) => ({
    onClick: () => setActive(key),
    onClose: () => setActive(null),
    show: active === key,
  });

  const changeTab = (key) => {
    setActiveTab(key);
  };

  useEffect(() => {
    getCasCd(casDate, selectedWork);
    getCasGG(casDate, selectedWork);
  }, []);

  const updateData = (opt) => {
    console.log('######');
    let getFunction = () => {};
    if (activeTab === '1') {
      getFunction = getSummaryCas;
    } else if (activeTab === '2') {
      getFunction = getCasCd;
    } else {
      getFunction = getCasGG;
    }
    getFunction(casDate, selectedWork, opt + 1);
  };

  const createTreeDataCd = (treeData) => {
    if (treeData && treeData.length > 0) {
      let treeObject = {};

      const initialExpandedRowKeys = [];

      treeData.forEach((item) => {
        if (treeObject[item.ID_CC_SUP]) {
          treeObject = update(treeObject, {
            [item.ID_CC_SUP]: {
              $push: [item],
            },
          });
        } else {
          treeObject = update(treeObject, {
            [item.ID_CC_SUP]: {
              $set: [item],
            },
          });
        }
        if (item.TIPO === 1 && !treeObject[item.ID_CC]) {
          treeObject = update(treeObject, {
            [item.ID_CC]: {
              $set: [],
            },
          });
        }

        if (item.TIPO === 1) {
          initialExpandedRowKeys.push(item.ID_CC);
        }
      });

      const newTree = [];
      const paths = {
        [treeObject[0][0].ID_CC]: '0',
      };
      const savePath = (id, path) => {
        if (!paths[id]) {
          paths[id] = path;
        }
      };

      treeObject[0].forEach((current, i) => {
        newTree.push({
          ...current,
          PATH: `${i}`,
          children: getChildren(current.ID_CC, treeObject, `${i}`, savePath, null, 'ID_CC'),
        });
      });

      setTreeCd(newTree);
      setLastSavedTreeCd(newTree);
      setExpandeRowKeysCd(initialExpandedRowKeys);
    } else if (treeData && treeData.length === 0) {
      setTreeCd([]);
      setLastSavedTreeCd([]);
      setExpandeRowKeysCd([]);
    }
  };

  const createTreeDataGg = (treeData) => {
    if (treeData && treeData.length > 0) {
      let treeObject = {};

      const initialExpandedRowKeys = [];

      treeData.forEach((item) => {
        if (treeObject[item.ID_CC_SUP]) {
          treeObject = update(treeObject, {
            [item.ID_CC_SUP]: {
              $push: [item],
            },
          });
        } else {
          treeObject = update(treeObject, {
            [item.ID_CC_SUP]: {
              $set: [item],
            },
          });
        }
        if (item.TIPO === 1 && !treeObject[item.ID_CC]) {
          treeObject = update(treeObject, {
            [item.ID_CC]: {
              $set: [],
            },
          });
        }

        if (item.TIPO === 1) {
          initialExpandedRowKeys.push(item.ID_CC);
        }
      });

      const newTree = [];
      const paths = {
        [treeObject[0][0].ID_CC]: '0',
      };
      const savePath = (id, path) => {
        if (!paths[id]) {
          paths[id] = path;
        }
      };

      treeObject[0].forEach((current, i) => {
        newTree.push({
          ...current,
          PATH: `${i}`,
          children: getChildren(current.ID_CC, treeObject, `${i}`, savePath, null, 'ID_CC'),
        });
      });

      setTreeGg(newTree);
      setExpandeRowKeysGg(initialExpandedRowKeys);
    } else if (treeData && treeData.length === 0) {
      setTreeGg([]);
      setExpandeRowKeysGg([]);
    }
  };

  useEffect(() => {
    console.log('####', { casGG });
    if (get(casGG, ['data', selectedWork], null)) {
      const treeData = casGG.data[selectedWork];
      createTreeDataGg(treeData);
    }
  }, [casGG]);

  useEffect(() => {
    if (get(casCd, ['data', selectedWork], null)) {
      const treeData = casCd.data[selectedWork];
      createTreeDataCd(treeData);
    }
  }, [casCd]);

  console.log({ treeCd, treeGg, dataSummaryCas, summary: dataSummaryCas[selectedWork] });

  return (
    <div className="cas">
      <Row gutter={[30, 30]}>
        <Col className="first-buttons-col nowrap-inputs" span={24}>
          <div className="date-picker-wrapper mr-auto">
            <DatePickerWithTopLabel
              value={casDate}
              onChange={onChangeDate}
              label="Fecha CAS al"
              placeholder="MM-YYYY"
              format="MMMM, YYYY"
              disabled={isFetching}
              keepShowingLabel
              picker="month"
              locale={locale}
              monthCellRender={(d) => d.format('MMMM')}
              allowClear={false}
              className="input-width mr-3 mb-3"
            />
            {activeTab === '1' && (
              <>
                <InputWithTopLabel
                  keepShowingLabel
                  label="GTE. Proyecto"
                  className="input-width mr-3 mb-3 disabled-inputs"
                  value={GteProyecto}
                  onChange={(value) => handleInputChange(value, 'GteProyecto')}
                  allowClear={false}
                />
                <InputWithTopLabel
                  keepShowingLabel
                  label="Ing Administrador"
                  className="input-width mr-3 mb-3 disabled-inputs"
                  name="IngAdministrador"
                  value={IngAdministrador}
                  onChange={(value) => handleInputChange(value, 'IngAdministrador')}
                  allowClear={false}
                />
                <DatePickerWithTopLabel
                  value={casDateInic}
                  onChange={onChangeDateInic}
                  label="Fecha de Inicio"
                  placeholder="DD-MM-YYYY"
                  format="DD, MMMM, YYYY"
                  disabled={isFetching}
                  keepShowingLabel
                  // picker="month"
                  locale={locale}
                  monthCellRender={(d) => d.format('MMMM')}
                  allowClear={false}
                  className="input-width mr-3 mb-3 disabled-inputs"
                />
                <DatePickerWithTopLabel
                  value={casDateEnd}
                  onChange={onChangeDateEnd}
                  label="Fecha Término"
                  placeholder="DD-MM-YYYY"
                  format="DD, MMMM, YYYY"
                  disabled={isFetching}
                  keepShowingLabel
                  // picker="month"
                  locale={locale}
                  monthCellRender={(d) => d.format('MMMM')}
                  allowClear={false}
                  className="input-width mr-3 mb-3 disabled-inputs"
                />
                <InputWithTopLabel
                  keepShowingLabel
                  label="Duracion Obra (Meses)"
                  className="input-width mr-3 mb-3 disabled-inputs"
                  name="DuracionObra"
                  value={DuracionObra}
                  onChange={(value) => handleInputChange(value, 'DuracionObra')}
                  allowClear={false}
                />

                <InputWithTopLabel
                  keepShowingLabel
                  label="Nº Mes"
                  className="input-width mr-3 mb-3 disabled-inputs"
                  name="NMes"
                  value={NMes}
                  onChange={(value) => handleInputChange(value, 'NMes')}
                  allowClear={false}
                />

                <InputWithTopLabel
                  keepShowingLabel
                  label="% Avance Fisico Real"
                  className="input-width mr-3 mb-3 disabled-inputs"
                  name="AvanceFisicoReal"
                  value={AvanceFisicoReal}
                  onChange={(value) => handleInputChange(value, 'AvanceFisicoReal')}
                />
              </>
            )}
          </div>
          <Button
            disabled={!treeCd || !treeGg || !dataSummaryCas || !dataSummaryCas[selectedWork]}
            onClick={() => {
              exportExcel(
                get(dataSummaryCas, [selectedWork, 'RESUMEN_CAS'], []),
                treeCd,
                treeGg,
                casDate.format('DD-MM-YYYY')
              );
            }}
          >
            Exportar
          </Button>
          <div className="ViewOptionsRadio-cascd">
            <ViewOptionsRadio
              updateOption={(opt) => setActiveOption(opt)}
              activeOptionProp={activeOption}
              options={['Contrato Vigente', 'Contrato Forecast']}
              {...getCommonProps('view')}
            />
          </div>
          {activeTab === '1' && (
            <div>
              <ModalSummary
                dataSummary={dataSummaryCas}
                selectedWork={selectedWork}
                casDate={casDate}
                activeOption={activeOption}
              />
            </div>
          )}
        </Col>
      </Row>
      <Row gutter={[30, 30]}>
        <Col className="p-0 position-relative" span={24}>
          <div className="position-absolute" style={{ right: 0 }}>
            {!isEditingCasCd ? (
              <>
                {activeTab === '2' && (
                  <Button
                    className="cursor-pointer mr-2"
                    style={{ right: 0, top: 0, zIndex: 1 }}
                    onClick={() => setIsEditingCasCd(true)}
                  >
                    Editar Cd saldo obra
                  </Button>
                )}
                <Button
                  type="primary"
                  className="cursor-pointer"
                  style={{ right: 0, top: 0, zIndex: 1 }}
                  loading={summaryCas.isFetching || casCd.isFetching || casGG.isFetching}
                  onClick={() => updateData(activeOption + 1)}
                >
                  Actualizar
                </Button>
              </>
            ) : (
              <>
                {activeTab === '2' && (
                  <Button
                    className="cursor-pointer mr-2"
                    style={{ right: 0, top: 0, zIndex: 1 }}
                    onClick={() => {
                      setIsEditingCasCd(false);
                      setFieldsToSave([]);
                    }}
                  >
                    Cancelar
                  </Button>
                )}
                <Button
                  type="primary"
                  className="cursor-pointer"
                  style={{ right: 0, top: 0, zIndex: 1 }}
                  onClick={saveCd}
                  disabled={fieldsToSave.length === 0}
                >
                  Guardar
                </Button>
              </>
            )}
          </div>
          <Tabs activeKey={activeTab} defaultActiveKey="1" onChange={changeTab}>
            <TabPane tab="Resumen CAS" key="1">
              {activeTab === '1' && (
                <div className="mt-4">
                  <SummaryCas activeOption={activeOption} casDate={casDate} />
                </div>
              )}
            </TabPane>
            <TabPane tab="CAS CD" key="2">
              {activeTab === '2' && (
                <div className="mt-4">
                  <TableCD
                    activeOption={activeOption}
                    cancelEditionClicked={cancelEditionClicked}
                    casDate={casDate}
                    expandedRowKeysCd={expandedRowKeysCd}
                    isEditingCasCd={isEditingCasCd}
                    lastSavedTreeCd={lastSavedTreeCd}
                    setCancelEditionClicked={setCancelEditionClicked}
                    setExpandeRowKeysCd={setExpandeRowKeysCd}
                    setFieldsToSave={setFieldsToSave}
                    setTree={setTreeCd}
                    tree={treeCd}
                  />
                </div>
              )}
            </TabPane>
            <TabPane tab="CAS GG" key="3">
              {activeTab === '3' && (
                <div className="mt-4">
                  <TableGG
                    activeOption={activeOption}
                    casDate={casDate}
                    tree={treeGg}
                    setTree={setTreeGg}
                    expandedRowKeysGg={expandedRowKeysGg}
                    setExpandeRowKeysGg={setExpandeRowKeysGg}
                  />
                </div>
              )}
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};
CASCD.propTypes = {
  selectedWork: PropTypes.number.isRequired,
  casCd: PropTypes.object.isRequired,
  casGG: PropTypes.object.isRequired,
  summaryCas: PropTypes.object.isRequired,
  getCasCd: PropTypes.func.isRequired,
  getInputsTypes: PropTypes.func.isRequired,
  inputsTypes: PropTypes.object.isRequired,
  getAccountOfCost: PropTypes.func.isRequired,
  accountOfCostInfo: PropTypes.object.isRequired,
  getCasGG: PropTypes.func.isRequired,
  getSummaryCas: PropTypes.func.isRequired,
  editCasCd: PropTypes.func.isRequired,
};

export default connect(
  ({ production, works, casCd, casGG, accountOfCost, summaryCas, budget: { inputsTypes } }) => ({
    production,
    selectedWork: works.selectedWork,
    casCd,
    summaryCas,
    casGG,
    accountOfCostInfo: accountOfCost,
    inputsTypes,
  }),
  (dispatch) => ({
    getInputsTypes: bindActionCreators(getInputsTypesAction, dispatch),
    getAccountOfCost: bindActionCreators(getAccountOfCostAction, dispatch),
    getCasCd: bindActionCreators(getCasCdAction, dispatch),
    getCasGG: bindActionCreators(getCasGGAction, dispatch),
    getSummaryCas: bindActionCreators(getSummaryCasAction, dispatch),
  })
)(CASCD);
