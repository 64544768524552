import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd';
import es from 'antd/lib/locale/es_ES';
import reportWebVitals from './reportWebVitals';

import App from './App';
import store from './store';

import 'antd/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.css';
import './atoms/css/bootstrap.min.css';
import './atoms/css/clean.min.css';
import './atoms/css/pixeladmin.min.css';

import './index.scss';
import './i18n';

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <ConfigProvider locale={es}>
        <App />
      </ConfigProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
