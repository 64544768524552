/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';

const advanceControlProptypes = {
  areasAndPositions: PropTypes.shape({
    data: PropTypes.array,
  }).isRequired,
  attendance: PropTypes.shape({
    attendanceTypes: PropTypes.array,
    attendanceViewType: PropTypes.number,
    closedAttendance: PropTypes.array,
    data: PropTypes.array,
  }).isRequired,
  auth: PropTypes.shape({
    user: PropTypes.object,
    token: PropTypes.string,
  }).isRequired,
  location: PropTypes.object.isRequired,
  schedule: PropTypes.shape({ data: PropTypes.array }).isRequired,
  setInformationFromAttendanceURL: PropTypes.func.isRequired,
  workers: PropTypes.shape({
    data: PropTypes.array,
    supervisors: PropTypes.array,
  }).isRequired,
  works: PropTypes.shape({ data: PropTypes.array }).isRequired,
};

const filtersOperators = {
  RUTA_LINEA_BASE: { operator: 'eq', value: 1 },
  RUTA_CONTROL: { operator: 'eq', value: 1 },
  NUM_VINC: { operator: 'eq', value: 0 },
  PRC_AVA: { operator: 'lt', value: 1 },
  HITO: { operator: 'neq', value: 1 },
  PRC_DESV_QA: { operator: 'neq', value: 0 },
  NUM_REST: { operator: 'gt', value: 0 },
  NUM_REST_VENC: { operator: 'gt', value: 0 },
  PRC_DESV: { operator: 'lt', value: 0 },
};

const intervalFilterKeys = ['HOLGURA_TOT', 'HOLGURA_TOT_PROY', 'PRC_AVA_INPUTS', 'PRC_PROG'];
const impactedActivitiesFilterKeys = ['ID_TIPO_RESTRICCION', 'RITMO_EST'];

const getTableWidth = (tableColumns) =>
  tableColumns.reduce(
    (acc, current) =>
      acc + (current.children ? getTableWidth(current.children) : current.width || 100),
    0
  );

const initialFilters = { NIVEL_INS: '', NUM_FILA: '', NIVEL: null };

const schemaLevels = [
  { name: 'Mostrar Todo', value: '' },
  { name: 'Nivel 1', value: '1' },
  { name: 'Nivel 2', value: '2' },
  { name: 'Nivel 3', value: '3' },
  { name: 'Nivel 4', value: '4' },
  { name: 'Nivel 5', value: '5' },
  { name: 'Nivel 6', value: '6' },
  { name: 'Nivel 7', value: '7' },
  { name: 'Nivel 8', value: '8' },
  { name: 'Nivel 9', value: '9' },
  { name: 'Nivel 10', value: '10' },
];

const getInitialState = (project) => ({
  works: {
    data: null,
    isFetching: false,
    selected: Number(project),
  },
  gantt: {
    data: {},
    isFetching: false,
  },
  dates: {
    data: {},
    isFetching: false,
  },
  savingEdition: false,
  tree: {},
  indexes: {},
  lastSavedTree: {},
  isFetchingData: false,
  expandedRowKeys: [],
  hasFiltersApplied: false,
  expanded: [],
  isEditing: false,
  filter: [],
  capFilter: [],
  intervalFilters: [],
  impactedActivitiesFilter: [],
  dateFilter: null,
  filters: initialFilters,
  fieldsToSave: [],
  validDates: [],
  reset: false,
  nameFilterValue: '',
  numberRowFilterValue: '',
  levelFilter: '',
  access: [],
  userId: null,
  loadingOpenOrCloseAdvance: false,
  fieldsWithErrors: [],
});
export {
  filtersOperators,
  intervalFilterKeys,
  getTableWidth,
  initialFilters,
  advanceControlProptypes,
  schemaLevels,
  getInitialState,
  impactedActivitiesFilterKeys,
};
