import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { DatePicker } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setAdvanceControlEdition as setAdvanceControlEditionAction } from '../../../actions/advanceControlEdition';

import './index.scss';

const dateFormat = 'DD-MM-YYYY';

const DatePickerEditableCell = (props) => {
  const { editable, record, onChange, dataKey, validDates, className, selectValue, ...rest } =
    props;
  const [show, setShow] = useState(false);
  const [currentDate, setCurrentDate] = useState(
    record[dataKey] ? moment(record[dataKey], dateFormat) : null
  );

  const onChangeDate = (date) => {
    setCurrentDate(date);
    setShow(false);
    onChange(
      [
        {
          [dataKey]: date ? date.format(dateFormat) : '',
          ID_INS: record.ID_INS,
          PATH: record.PATH,
        },
      ],
      record
    );
  };

  useEffect(() => {
    if (selectValue === 0 && currentDate) {
      onChangeDate(null);
    }
  }, [selectValue]);

  const canEdit = editable(record);
  return (
    <td {...rest} className={`date-picker-editable-cell ${canEdit ? 'editable' : ''} ${className}`}>
      {canEdit && show ? (
        <DatePicker
          disabledDate={(current) => current && !validDates.includes(current.format('DD/MM/YYYY'))}
          format={dateFormat}
          onChange={onChangeDate}
          value={currentDate}
          onBlur={() => setShow(false)}
          autoFocus
          open
        />
      ) : (
        <div
          className={`date-picker-modal-toggle ${record.ID_NAT === 1 ? 'cap-level' : ''}`}
          onClick={() => (canEdit ? setShow(true) : null)}
        >
          {currentDate instanceof moment ? currentDate.format(dateFormat) : null}
        </div>
      )}
    </td>
  );
};

DatePickerEditableCell.propTypes = {
  editable: PropTypes.func,
  record: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  dataKey: PropTypes.string.isRequired,
  validDates: PropTypes.array.isRequired,
  className: PropTypes.string,
  selectValue: PropTypes.number,
};

DatePickerEditableCell.defaultProps = {
  editable: () => true,
  className: '',
  selectValue: null,
};

export default connect(
  (store) => ({
    advanceControlEdition: store.advanceControlEdition,
  }),
  (dispatch) => ({
    onChange: bindActionCreators(setAdvanceControlEditionAction, dispatch),
  })
)(DatePickerEditableCell);
