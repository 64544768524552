import _ from 'lodash';
import axios from 'axios';
import { message } from 'antd';
import api, { getHeaders } from '../utils/api';
import { USERS_FETCHED, GET_USERS, GET_USERS_ERROR } from '../reducers/users';
import store from '../store';

export const usersFetched = (users) => ({
  type: USERS_FETCHED,
  users,
});

export const getUsers = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: GET_USERS });
    const errorMessageError = 'Ocurrió  un error obteniendo la lista de usuarios de conpax';
    axios
      .get('https://cas.conpax.cl/api/v1/accesos?token=Foco.2k21')
      .then((res) => {
        if (res.status === 201) {
          const requestBody = {
            USUARIO: localStorage.getItem('foco_username'),
            EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
            USUARIOS: res.data.map((e) => e.nom_usuario).join(';'),
          };

          const usersMap = {};

          res.data.forEach((user) => {
            usersMap[user.nom_usuario] = user;
          });
          api
            .post('/api/ConfiguracionSuite/GetConfiguracionUsuarios', requestBody, getHeaders())
            .then((response) => {
              if (response.status === 200) {
                const newData = response.data.map((e) => ({ ...e, ...usersMap[e.USUARIO] }));
                dispatch(usersFetched(newData));
                resolve(newData);
              } else {
                dispatch({
                  type: GET_USERS_ERROR,
                });
                reject();
              }
            })
            .catch((err) => {
              dispatch({
                type: GET_USERS_ERROR,
              });
              reject(err);
            });
        } else {
          message.error(errorMessageError);
          dispatch({ type: GET_USERS });
        }
      })
      .catch(() => {
        dispatch({ type: GET_USERS_ERROR });
        message.error(errorMessageError);
      });
  });

export const saveUsers =
  (nom_usuario, selectAccess, selectProjects, selectProfile, allowProjects) => (dispatch) =>
    new Promise((resolve) => {
      const requestBody = {
        USUARIO: localStorage.getItem('foco_username'),
        EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
        USUARIO_CAS: nom_usuario,
        ACCESO_CAS: selectAccess,
        PROYECTOS: selectProjects,
        PERFILES: selectProfile,
        RESTRINGE_PROYECTO: allowProjects,
      };

      api
        .post(`api/ConfiguracionSuite/SetConfiguracionUsuarios`, requestBody, getHeaders())
        .then((res) => {
          if (res.status === 200) {
            getUsers()(dispatch);
            message.success('Usuario guardado correctamente', 4);
          } else {
            message.success('Error guardando el usuario', 4);
          }
          resolve();
        })
        .catch(() => message.error('Error guardando el usuario', 4));
    });
