import React, { useState } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Button, Checkbox, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { normalizeString } from '../../../utils';
import './index.scss';

const selectAllKey = 'Seleccionar Todos';

const AccountOfCostSelector = ({
  onChange,
  onClose,
  options,
  subtitle,
  title,
  showSearch,
  scrollable,
  selectedOptions,
  onSave,
  elementTitleKey,
  elementKey,
  elementSecondaryTitleKey,
}) => {
  const [search, onChangeSearch] = useState('');
  const [showOptions, setShowOptions] = useState(true);
  const [expandedSections, setExpandedSections] = useState({});
  const [wasChanged, setWasChanged] = useState(false);
  const isStringList = get(options, 'length') && typeof options[0] === 'string';
  const filteredOptions =
    options.length > 0
      ? options.filter((key) => {
          const value = isStringList ? key : get(key, `[${elementTitleKey}]`, '');
          return normalizeString(value).includes(normalizeString(search));
        })
      : options;
  const toggleSections = (key) => {
    const oldExpandedValue = !!expandedSections[key];
    setExpandedSections({ ...expandedSections, [key]: !oldExpandedValue });
  };
  const getOptions = (list, level) => {
    return (
      <>
        {list.length > 0 &&
          list.map((current) => {
            const currentIsString = typeof current === 'string';

            const hasChildren = current.children && current.children.length > 0;
            const text = (
              <div>
                {currentIsString
                  ? current
                  : `${current[elementTitleKey]} ${
                      current[elementSecondaryTitleKey]
                        ? ` (${current[elementSecondaryTitleKey]})`
                        : ''
                    }`}
              </div>
            );
            const isChecked = currentIsString
              ? selectedOptions.indexOf(current) !== -1
              : selectedOptions.find((e) => e[elementKey] === current[elementKey]);
            let head = (
              <div className="node-element">
                <Checkbox
                  checked={isChecked}
                  onChange={() => {
                    setWasChanged(true);
                    onChange(
                      isChecked && currentIsString && current !== selectAllKey ? null : current,
                      isChecked
                    );
                  }}
                />
                {text}
              </div>
            );

            const key = current.ID_ITEM_COSTO;

            if (!currentIsString && hasChildren) {
              head = (
                <div className="head-toggle">
                  <Checkbox
                    checked={isChecked}
                    onChange={() => {
                      setWasChanged(true);
                      onChange(isChecked && currentIsString ? null : current);
                    }}
                  />
                  <i
                    className={`fa fa-chevron-${
                      expandedSections[key] ? 'up' : 'down'
                    } toggle-button`}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleSections(key);
                    }}
                  />
                  {text}
                </div>
              );
            }

            const content = (
              <div className={`checkbox-row level-${level}`}>
                {head}
                {!currentIsString &&
                  hasChildren &&
                  expandedSections[key] &&
                  getOptions(current.children, level + 1)}
              </div>
            );

            return content;
          })}
      </>
    );
  };

  return (
    <div className="activities-filter">
      <div className="options-container not-popup">
        <div className="options-header">
          <div />
          <span>{title}</span>
          <i
            className="fa fa-times"
            onClick={() => {
              setWasChanged(false);
              onClose();
            }}
          />
        </div>
        {showSearch && (
          <div className="search-wrapper">
            <Input
              size="small"
              onChange={(e) => onChangeSearch(e.target.value)}
              placeholder="Búsqueda por texto"
              prefix={<SearchOutlined />}
            />
          </div>
        )}
        {options.length > 0 && subtitle && (
          <div className="toggle-filters" onClick={() => setShowOptions(!showOptions)}>
            <div />
            <span>{subtitle}</span>
            <i className={`fa fa-chevron-${showOptions ? 'up' : 'down'}`} />
          </div>
        )}
        {showOptions && (
          <div className={`options ${scrollable ? 'scrollable' : ''}`}>
            {getOptions(filteredOptions, 1)}
          </div>
        )}
        <div className="buttons">
          <Button
            type="default"
            onClick={() => {
              setWasChanged(false);
              onClose();
            }}
          >
            Cancelar
          </Button>
          <Button
            disabled={!wasChanged}
            onClick={() => {
              onSave();
              setWasChanged(false);
            }}
            type="primary"
          >
            Aplicar
          </Button>
        </div>
      </div>
    </div>
  );
};

AccountOfCostSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  showSearch: PropTypes.bool,
  scrollable: PropTypes.bool,
  selectedOptions: PropTypes.array,
  onSave: PropTypes.func,
  elementTitleKey: PropTypes.string,
  elementKey: PropTypes.string,
  elementSecondaryTitleKey: PropTypes.string,
};

AccountOfCostSelector.defaultProps = {
  showSearch: true,
  scrollable: false,
  selectedOptions: [],
  onSave: () => {},
  elementTitleKey: 'title',
  elementKey: 'title',
  elementSecondaryTitleKey: '',
};

export default AccountOfCostSelector;
