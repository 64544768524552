import _ from 'lodash';
import { message } from 'antd';
import api, { getHeaders } from '../utils/api';

import {
  BASE_OVERHEADPROJECTION_V2_FETCHED,
  GET_BASE_OVERHEADPROJECTION_V2,
  GET_BASE_OVERHEADPROJECTION_V2_ERROR,
  BASE_OVERHEADPROJECTION_V2_ADD_MONTH,
  GET_INPUTS_TYPES_GG_OVERHEADPROJECTION_V2,
  INPUTS_TYPES_GG_FETCHED_OVERHEADPROJECTION_V2,
  GET_INPUTS_TYPES_GG_OVERHEADPROJECTION_V2_ERROR,
} from '../reducers/overheadProjectionV2';
import store from '../store';

export const baseOverheadProjectionV2Fetched = (baseOverheadProjectionV2, workId, attr) => ({
  type: BASE_OVERHEADPROJECTION_V2_FETCHED,
  baseOverheadProjectionV2,
  workId,
  attr,
});

export const addMonthOverheadProjectionV2 = (workId) => (dispatch) =>
  dispatch({
    type: BASE_OVERHEADPROJECTION_V2_ADD_MONTH,
    workId,
  });

export const inputsTypesFetched = (inputsTypes) => ({
  type: INPUTS_TYPES_GG_FETCHED_OVERHEADPROJECTION_V2,
  inputsTypes,
});

export const getBaseOverheadProjectionV2 = (ID_OBR, FECHA_CORTE, ID_PPTO_TIPO) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: GET_BASE_OVERHEADPROJECTION_V2 });

    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR,
      FECHA_CORTE: ID_PPTO_TIPO === 4 ? FECHA_CORTE : null,
      ID_PPTO_TIPO: ID_PPTO_TIPO || ID_PPTO_TIPO === 3 || ID_PPTO_TIPO === 2,
    };
    api
      .post('/api/GastosGenerales/GetPresupuestoGG', requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch(baseOverheadProjectionV2Fetched(res.data, ID_OBR));
          resolve(res.data);
        } else {
          dispatch({
            type: GET_BASE_OVERHEADPROJECTION_V2_ERROR,
          });
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_BASE_OVERHEADPROJECTION_V2_ERROR,
        });
        reject(err);
      });
  });

export const setBaseOverheadProjectionV2 = (body, FECHA_CORTE, ID_PPTO_TIPO) => (dispatch) =>
  new Promise((resolve, reject) => {
    const ID_OBR = _.get(store.getState(), 'works.selectedWork', '');
    const errorMessage = 'Error al editar las celdas';
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR,
      ...body,
      FECHA_CORTE: ID_PPTO_TIPO === 4 ? FECHA_CORTE : null,
      ID_PPTO_TIPO,
    };
    api
      .post('api/GastosGenerales/SetPresupuestoGG', requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          message.success('Celdas editadas correctamente', 8);
          dispatch(baseOverheadProjectionV2Fetched(res.data, ID_OBR));
          resolve(res.data);
        } else {
          message.error(errorMessage);
          reject(res.data);
        }
      })
      .catch((err) => {
        message.error(errorMessage);
        reject(err);
      });
  });

export const getInputsTypesOverheadProjectionV2 = (ID_PPTO_TIPO) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: GET_INPUTS_TYPES_GG_OVERHEADPROJECTION_V2 });

    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_PPTO_TIPO,
    };
    api
      .post(`/api/Presupuesto/GetTipoInsumosGG`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch(inputsTypesFetched(res.data));
          resolve(res.data);
        } else {
          dispatch({
            type: GET_INPUTS_TYPES_GG_OVERHEADPROJECTION_V2_ERROR,
          });
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_INPUTS_TYPES_GG_OVERHEADPROJECTION_V2_ERROR,
        });
        reject(err);
      });
  });

export const deleteRowOverheadProjectionV2 = (ID_ITEM, FECHA_CORTE, ID_PPTO_TIPO) => (dispatch) =>
  new Promise((resolve, reject) => {
    const ID_OBR = _.get(store.getState(), 'works.selectedWork', '');
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_ITEM,
    };
    api
      .post(`api/Presupuesto/DelItem`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          message.success('Fila eliminada correctamente', 4);
          getBaseOverheadProjectionV2(ID_OBR, FECHA_CORTE, ID_PPTO_TIPO)(dispatch);
          resolve();
        } else {
          message.error('Error al eliminar el Fila', 4);
          reject();
        }
      })
      .catch(() => {
        message.error('Error al eliminar el Fila', 4);
        reject();
      });
  });

export const setOverheadSelectV2 = (FECHA_CORTE, ID_PPTO_TIPO) => (dispatch) =>
  new Promise((resolve, reject) => {
    const ID_OBR = _.get(store.getState(), 'works.selectedWork', '');
    const errorMessage = 'Error al editar las celdas';
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR,
      FECHA_CORTE,
      ID_PPTO_TIPO,
    };
    api
      .post('api/GastosGenerales/SetImportarGG', requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          message.success(
            ID_PPTO_TIPO === 3
              ? 'GG Estudio se ha cargado correctamente'
              : 'GG Cero se ha cargado correctamente',
            8
          );
          getBaseOverheadProjectionV2(ID_OBR, FECHA_CORTE, ID_PPTO_TIPO)(dispatch);
          resolve(res.data);
        } else {
          message.error(errorMessage);
          reject(res.data);
        }
      })
      .catch((err) => {
        message.error(errorMessage);
        reject(err);
      });
  });
