import update from 'immutability-helper';

export const GET_ADVANCE_DATES_IN_ADVANCE_MATRIX = 'GET_ADVANCE_DATES_IN_ADVANCE_MATRIX';
export const GET_ENCLOSURES_IN_ADVANCE = 'GET_ENCLOSURES_IN_ADVANCE';
export const GET_ENCLOSURES_AND_ITEMS_IN_ADVANCE = 'GET_ENCLOSURES_AND_ITEMS_IN_ADVANCE';
export const GET_ITEMS_IN_ADVANCE = 'GET_ITEMS_IN_ADVANCE';
export const GET_ITEMS_TREE = 'GET_ITEMS_TREE';
export const SET_ADVANCE = 'SET_ADVANCE';
export const GET_ENCLOSURE_TREE_IN_ADVANCE = 'GET_ENCLOSURE_TREE_IN_ADVANCE';
export const GET_ENCLOSURE_TYPES_IN_ADVANCE = 'GET_ENCLOSURE_TYPES_IN_ADVANCE';

const initialState = {
  advanceDates: null,
  enclosures: null,
  enclosuresAndItems: null,
  enclosuresTree: null,
  enclosuresTypes: null,

  items: null,
  itemsTree: null,
};

const advanceMatrix = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADVANCE_DATES_IN_ADVANCE_MATRIX: {
      const { advanceDates } = action.payload;
      return update(state, {
        advanceDates: { $set: advanceDates },
      });
    }

    case GET_ENCLOSURES_IN_ADVANCE: {
      const { enclosures } = action.payload;
      return update(state, {
        enclosures: { $set: enclosures },
      });
    }

    case GET_ITEMS_IN_ADVANCE: {
      const { items } = action.payload;
      return update(state, {
        items: { $set: items },
      });
    }

    case GET_ENCLOSURES_AND_ITEMS_IN_ADVANCE: {
      const { enclosuresAndItems } = action.payload;
      return update(state, {
        enclosuresAndItems: { $set: enclosuresAndItems },
      });
    }

    case SET_ADVANCE: {
      const { ID_INS, ID_UCO, PORCEN_DAV } = action.payload;
      const index = state.enclosuresAndItems.findIndex(
        (e) => e.ID_INS === ID_INS && e.ID_UCO === ID_UCO
      );

      return update(state, {
        enclosuresAndItems: {
          [index]: {
            PORCEN_DAV: { $set: PORCEN_DAV },
          },
        },
      });
    }

    case GET_ITEMS_TREE: {
      const { itemsTree } = action.payload;
      return update(state, {
        itemsTree: { $set: itemsTree },
      });
    }

    case GET_ENCLOSURE_TREE_IN_ADVANCE: {
      const { enclosuresTree } = action.payload;
      return update(state, {
        enclosuresTree: { $set: enclosuresTree },
      });
    }

    case GET_ENCLOSURE_TYPES_IN_ADVANCE: {
      const { enclosuresTypes } = action.payload;
      return update(state, {
        enclosuresTypes: { $set: enclosuresTypes },
      });
    }

    default:
      return state;
  }
};
export default advanceMatrix;
