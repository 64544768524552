import React from 'react';
import { Column, HeaderCell, Cell, ColumnGroup } from 'rsuite-table';
import DoubleLineCell from '../../../components/DoubleLineCell';
import EditableCell from '../../../components/EditableCell/Production';
import { hiddeCellRender } from '../../../utils/budgetAndProductionHelpers';
import { getFormattedNumber } from '../../../utils';

import './index.scss';

export const getTotals = (record, key = 'CANTIDAD_VIGENTE', totalKey = 'PRECIO_ITEM_VENTA') => {
  const ID_NAT = 3;
  let total = record.ID_NAT === ID_NAT ? record[key] * record[totalKey] : 0;
  if (record.ID_NAT !== ID_NAT && record.children && record.children.length) {
    total = record.children.reduce((acc, childrenRecord) => {
      return acc + getTotals(childrenRecord, key, totalKey);
    }, 0);
  }
  return total;
};

const getMin = (record) => {
  let min = null;
  const totalPrevAcc = getTotals(record, 'CANT_EP_ANT', 'PRECIO_VENTA_EP_ANT');
  const currenTotal = record.CANTIDAD_VIGENTE * record.PRECIO_ITEM_VENTA;

  if (totalPrevAcc === 0 || currenTotal === 0) {
    min = 0;
  }

  min = (totalPrevAcc * 100) / currenTotal;

  return min;
};

const columns = (activeOptions, activeEdition, handleSave, helpers) => {
  const canEdit = activeEdition === 'editPaymentState';
  const columnsResult = [
    <Column
      resizable
      fixed
      verticalAlign="middle"
      align="center"
      width={90}
      className="border-right"
    >
      <HeaderCell>Ítem </HeaderCell>
      <Cell dataKey="CODIGO_ITEM" />
    </Column>,
    <Column
      resizable
      fixed
      treeCol
      verticalAlign="middle"
      align="left"
      width={400}
      className="border-right"
    >
      <HeaderCell>Descripción </HeaderCell>
      <Cell dataKey="NOMBRE_ITEM" />
    </Column>,
    <Column
      resizable
      fixed
      verticalAlign="middle"
      align="center"
      width={90}
      className="border-right"
    >
      <HeaderCell>PU Venta</HeaderCell>
      <Cell dataKey="PRECIO_ITEM_VENTA" className="align-right">
        {(record) => hiddeCellRender(record.PRECIO_ITEM_VENTA, record, true, true, 2)}
      </Cell>
    </Column>,
    <Column
      resizable
      fixed
      verticalAlign="middle"
      align="center"
      width={90}
      className="border-right"
    >
      <HeaderCell>Moneda</HeaderCell>
      <Cell dataKey="Moneda">{(record) => hiddeCellRender('$', record, true, true)}</Cell>
    </Column>,

    <Column
      resizable
      fixed
      verticalAlign="middle"
      align="center"
      width={70}
      className="border-right"
    >
      <HeaderCell>Ud</HeaderCell>
      <Cell dataKey="NOMABR_UNI">{(record) => hiddeCellRender(record.NOMABR_UNI, record)}</Cell>
    </Column>,

    <ColumnGroup align="center" header="Contrato Vigente">
      {[
        <Column resizable verticalAlign="middle" width={90}>
          <HeaderCell>Cantidad</HeaderCell>
          <Cell className="align-right">
            {(record) => hiddeCellRender(record.CANTIDAD_VIGENTE, record, true, false, 1)}
          </Cell>
        </Column>,

        <Column resizable verticalAlign="middle" align="center" width={140}>
          <HeaderCell>Total Venta</HeaderCell>
          <Cell dataKey="TOTAL_VENTA_" className="align-right border-right">
            {({ CANTIDAD_VIGENTE, PRECIO_ITEM_VENTA }) => {
              const total = CANTIDAD_VIGENTE * PRECIO_ITEM_VENTA;
              return (
                <span title={`$${getFormattedNumber(total)}`}>{`$${getFormattedNumber(
                  total,
                  null,
                  true
                )}`}</span>
              );
            }}
          </Cell>
        </Column>,
      ]}
    </ColumnGroup>,
    ...(activeOptions.option0
      ? [
          <ColumnGroup align="center" header="Contrato Actualizado">
            {[
              <Column resizable verticalAlign="middle" width={90}>
                <HeaderCell>Cantidad</HeaderCell>
                <Cell className="align-right">
                  {(record) => hiddeCellRender(record.CANTIDAD_VIGENTE, record, true, false)}
                </Cell>
              </Column>,
              <Column resizable verticalAlign="middle" align="center" width={140}>
                <HeaderCell>Total CD</HeaderCell>
                <Cell dataKey="TOTAL_MOD" className="align-right border-right">
                  {({ CANTIDAD_VIGENTE, PRECIO_ITEM_VENTA }) => {
                    const total = CANTIDAD_VIGENTE * PRECIO_ITEM_VENTA;
                    return (
                      <span title={`$${getFormattedNumber(total)}`}>{`$${getFormattedNumber(
                        total,
                        null,
                        true
                      )}`}</span>
                    );
                  }}
                </Cell>
              </Column>,
            ]}
          </ColumnGroup>,
        ]
      : []),
    ...(activeOptions.option1
      ? [
          <ColumnGroup
            align="center"
            header={
              <DoubleLineCell firstValue="Producción Acum. al" secondValue={helpers.advanceDate} />
            }
          >
            {[
              <Column resizable verticalAlign="middle" width={60}>
                <HeaderCell>%</HeaderCell>
                <Cell dataKey="PROD_PORCENTAJE">
                  {(record) => {
                    const totalPrevAcc = getTotals(record, 'CANT_AVA');
                    const totalMod = getTotals(record, 'CANTIDAD_VIGENTE');
                    if (totalPrevAcc === 0) {
                      return <span title="0%">0%</span>;
                    }
                    const total = (totalPrevAcc * 100) / totalMod;
                    return (
                      <span title={`${getFormattedNumber(total)}%`}>{`$${getFormattedNumber(
                        total,
                        0
                      )}%`}</span>
                    );
                  }}
                </Cell>
              </Column>,
              <Column resizable verticalAlign="middle" align="center" width={140}>
                <HeaderCell>Cantidad</HeaderCell>
                <Cell className="align-right">
                  {(record) => hiddeCellRender(record.CANT_AVA, record, true, false, 1)}
                </Cell>
              </Column>,
              <Column resizable verticalAlign="middle" align="center" width={140}>
                <HeaderCell>Total Venta</HeaderCell>
                <Cell dataKey="TOTAL_ACUM_ANT" className="align-right border-right">
                  {(record) => {
                    const total = getTotals(record, 'CANT_AVA');
                    return (
                      <span title={`$${getFormattedNumber(total)}`}>{`$${getFormattedNumber(
                        total,
                        null,
                        true
                      )}`}</span>
                    );
                  }}
                </Cell>
              </Column>,
            ]}
          </ColumnGroup>,
        ]
      : []),
    <ColumnGroup
      align="center"
      header={
        <DoubleLineCell
          firstValue="Estado de Pago Acum."
          secondValue={` al ${helpers.paymentStateDate || ''}`}
        />
      }
    >
      {[
        <Column resizable verticalAlign="middle" width={canEdit ? 140 : 60}>
          <HeaderCell>%</HeaderCell>
          <EditableCell
            dataIndex="PORC_EP"
            editable={canEdit}
            unit="%"
            decimalsIndex={(record) => {
              const totalAcc = getTotals(record, 'CANT_EP');
              const totalMod = record.CANTIDAD_VIGENTE * record.PRECIO_ITEM_VENTA;
              if (totalAcc === 0 || totalMod === 0) return 0;
              const total = (totalAcc * 100) / totalMod;

              return total === 100 ? -1 : 0;
            }}
            isUnitPrefix={false}
            includeRecordFields={['ID_ITEM_SUP', 'ID_NAT']}
            validator={(value, record) => {
              const min = getMin(record);
              return value >= min && value <= 100;
            }}
            maxButtonValue={() => 100}
            additionalInputFields={(record) => {
              const min = getMin(record);
              return {
                type: 'number',
                min,
                max: 100,
              };
            }}
            calculateValue={(record) => {
              const totalAcc = getTotals(record, 'CANT_EP');
              const totalMod = record.CANTIDAD_VIGENTE * record.PRECIO_ITEM_VENTA;
              if (totalAcc === 0 || totalMod === 0) return 0;
              const total = (totalAcc * 100) / totalMod;

              return total;
            }}
            handleSave={handleSave}
            objectModifier={(obj, record, value) => {
              const quantity = record.CANTIDAD_VIGENTE;
              const cant = (value * quantity) / 100;
              obj.CANT_EP = (value * record.CANTIDAD_VIGENTE) / 100;
              obj.TOTAL_EP = cant * record.PRECIO_ITEM_VENTA;
              obj.COMENTARIO_EP = record.COMENTARIO_EP || '';

              return obj;
            }}
            conditionToEditable={(record) => record.ID_NAT === 3}
            className={() => (canEdit ? 'editable' : '')}
          />
        </Column>,
        <Column resizable verticalAlign="middle" align="center" width={140}>
          <HeaderCell>Cantidad</HeaderCell>
          <EditableCell
            dataIndex="CANT_EP"
            decimalsIndex={1}
            calculateValue={(record) => {
              if (record.ID_NAT === 3) {
                return record.CANT_EP;
              }

              return null;
            }}
            maxButtonValue={({ CANTIDAD_VIGENTE }) => CANTIDAD_VIGENTE}
            canShow={(record) => record.ID_NAT !== 1}
            editable={canEdit}
            includeRecordFields={['ID_ITEM_SUP', 'ID_NAT']}
            handleSave={handleSave}
            validator={(value, record) => {
              return value >= 0 && value <= record.CANTIDAD_VIGENTE;
            }}
            additionalInputFields={(record) => {
              return {
                type: 'number',
                min: 0,
                max: record.CANTIDAD_VIGENTE,
              };
            }}
            objectModifier={(obj, record, value) => {
              obj.PORC_EP = (value * 100) / record.CANTIDAD_VIGENTE;
              obj.TOTAL_EP = value * record.PRECIO_ITEM_VENTA;
              obj.COMENTARIO_EP = record.COMENTARIO_EP || '';
              return obj;
            }}
            conditionToEditable={(record) => record.ID_NAT === 3}
            customClass="align-right"
            className={() => (canEdit ? 'editable' : '')}
          />
        </Column>,
        <Column resizable verticalAlign="middle" align="center" width={140}>
          <HeaderCell>Total Venta</HeaderCell>
          <EditableCell
            dataIndex="TOTAL_EP"
            onlyInteger
            editable={canEdit}
            validator={(value, record) => {
              const quantity = record.CANTIDAD_VIGENTE;
              return value >= 0 && value <= quantity * record.PRECIO_ITEM_VENTA;
            }}
            maxButtonValue={({ CANTIDAD_VIGENTE, PRECIO_ITEM_VENTA }) => {
              const quantity = CANTIDAD_VIGENTE;
              return quantity * PRECIO_ITEM_VENTA;
            }}
            includeRecordFields={['ID_ITEM_SUP', 'ID_NAT']}
            additionalInputFields={(record) => {
              return {
                type: 'number',
                min: 0,
                max: record.CANTIDAD_VIGENTE * record.PRECIO_ITEM_VENTA,
              };
            }}
            unit="$"
            calculateValue={(record) => getTotals(record, 'CANT_EP')}
            objectModifier={(obj, record, value) => {
              const cant = Number(value) / record.PRECIO_ITEM_VENTA;
              const updatedQuantity = record.CANTIDAD_VIGENTE;
              obj.CANT_EP = cant;
              obj.PORC_EP = (cant * 100) / updatedQuantity;
              obj.COMENTARIO_EP = record.COMENTARIO_EP || '';

              return obj;
            }}
            handleSave={handleSave}
            conditionToEditable={(record) => record.ID_NAT === 3}
            customClass="align-right border-right"
            className={() => (canEdit ? 'editable' : '')}
          />
        </Column>,
      ]}
    </ColumnGroup>,

    <ColumnGroup
      align="center"
      header={
        helpers.paymentStatePreviousDate ? (
          <DoubleLineCell
            firstValue="Estado de Pago Acum. Anterior al"
            secondValue={helpers.paymentStatePreviousDate}
          />
        ) : (
          'No existe Estado de Pago Acum. Anterior'
        )
      }
    >
      {[
        <Column resizable verticalAlign="middle" width={60}>
          <HeaderCell>%</HeaderCell>
          <Cell dataKey="PROD_PORCENTAJE">
            {(record) => {
              const totalPrevAcc = getTotals(record, 'CANT_EP_ANT', 'PRECIO_VENTA_EP_ANT');
              const currenTotal = record.CANTIDAD_VIGENTE * record.PRECIO_ITEM_VENTA;
              if (totalPrevAcc === 0 || currenTotal === 0) {
                return <span title="0%">0%</span>;
              }
              const total = (totalPrevAcc * 100) / currenTotal;
              return (
                <span title={`${getFormattedNumber(total)}%`}>{`${getFormattedNumber(
                  total,
                  0
                )}%`}</span>
              );
            }}
          </Cell>
        </Column>,
        <Column resizable verticalAlign="middle" align="center" width={140}>
          <HeaderCell>Cantidad</HeaderCell>
          <Cell className="align-right">
            {(record) => hiddeCellRender(record.CANT_EP_ANT, record, true, false)}
          </Cell>
        </Column>,
        <Column resizable verticalAlign="middle" align="center" width={140}>
          <HeaderCell>Total Venta</HeaderCell>
          <Cell dataKey="TOTAL_EP_ANT" className="align-right border-right">
            {(record) => {
              const total = getTotals(record, 'CANT_EP_ANT', 'PRECIO_VENTA_EP_ANT');
              return (
                <span title={`$${getFormattedNumber(total)}`}>{`$${getFormattedNumber(
                  total,
                  null,
                  true
                )}`}</span>
              );
            }}
          </Cell>
        </Column>,
      ]}
    </ColumnGroup>,

    ...(canEdit
      ? [
          <Column resizable verticalAlign="middle" width={90}>
            <HeaderCell align="center">
              <DoubleLineCell firstValue="Motivo" secondValue="Comentario" />
            </HeaderCell>
            <EditableCell
              dataIndex="COMENTARIO_EP"
              editable
              handleSave={handleSave}
              type="string"
              canShow={(record) => record.ID_NAT !== 1}
              conditionToEditable={(record) => record.ID_NAT === 3}
              className={() => 'editable'}
            />
          </Column>,
        ]
      : []),
    <ColumnGroup align="center" header="Estado de Pago Parcial">
      {[
        <Column resizable verticalAlign="middle" width={60}>
          <HeaderCell>%</HeaderCell>
          <Cell dataKey="PROD_PORCENTAJE">
            {(record) => {
              const totalAcc = getTotals(record, 'CANT_EP');
              const currentTotal = record.CANTIDAD_VIGENTE * record.PRECIO_ITEM_VENTA;
              const total1 =
                totalAcc === 0 || currentTotal === 0 ? 0 : (totalAcc * 100) / currentTotal;

              const totalPrevAcc = getTotals(record, 'CANT_EP_ANT', 'PRECIO_VENTA_EP_ANT');
              const total2 =
                totalPrevAcc === 0 || currentTotal === 0
                  ? 0
                  : `${(totalPrevAcc * 100) / currentTotal}`;
              const total = total1 - total2;
              console.log('@', { total1, total2, total });

              return (
                <span title={`${getFormattedNumber(total)}%`}>{`${getFormattedNumber(
                  total,
                  0
                )}%`}</span>
              );
            }}
          </Cell>
        </Column>,
        <Column resizable verticalAlign="middle" align="center" width={140}>
          <HeaderCell>Cantidad</HeaderCell>
          <Cell className="align-right">
            {(record) => {
              if (record.ID_NAT === 1) return null;
              const total = record.CANT_EP - record.CANT_EP_ANT;
              return <span title={getFormattedNumber(total)}>{getFormattedNumber(total, 1)}</span>;
            }}
          </Cell>
        </Column>,
        <Column resizable verticalAlign="middle" align="center" width={140}>
          <HeaderCell>Total Venta</HeaderCell>
          <Cell dataKey="TOTAL_EP_ANT" className="align-right ">
            {(record) => {
              const prevTotal = getTotals(record, 'CANT_EP_ANT', 'PRECIO_VENTA_EP_ANT');
              const currentTotal = getTotals(record, 'CANT_EP');
              const total = currentTotal - prevTotal;
              return (
                <span title={getFormattedNumber(total, null, false, '$')}>
                  {getFormattedNumber(total, null, true, '$')}
                </span>
              );
            }}
          </Cell>
        </Column>,
      ]}
    </ColumnGroup>,
  ];
  return columnsResult;
};

export default columns;
