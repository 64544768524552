import React from 'react';
import { compose, withProps } from 'recompose';
import { withScriptjs, withGoogleMap, GoogleMap } from 'react-google-maps';

const MyMapComponent = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?sensor=false&key=AIzaSyCrqlHKIoZv7G14fYEJesfg_OajP2ulV8E',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `47%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => <GoogleMap defaultZoom={props.defaultZoom} defaultCenter={props.defaultCenter} />);

export default MyMapComponent;
