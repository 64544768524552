import React from 'react';
import { Checkbox, Modal } from 'antd';
import get from 'lodash/get';
import { Column, HeaderCell, Cell, ColumnGroup } from 'rsuite-table';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import DoubleLineCell from '../../components/DoubleLineCell';
import EditableCell from '../../components/EditableCell/Budget';
import EditableFamilyCell from './EditableFamilyCell';
import { hiddeCellRender } from '../../utils/budgetAndProductionHelpers';

import { getFormattedNumber, toFixed } from '../../utils';

export const getTotals = (
  record,
  key = 'CANTIDAD_MOD',
  percentageKey = 'PORCENTAJE_EXITO_MOD',
  priceKey = 'PRECIO_ITEM'
) => {
  const percentage = record[percentageKey] ? record[percentageKey] / 100 : 1;
  let total = record.ID_NAT !== 1 ? record[key] * record[priceKey] * percentage : 0;
  if (record.ID_NAT === 1 && record.children && record.children.length) {
    total = record.children.reduce((acc, childrenRecord) => {
      return acc + getTotals(childrenRecord, key, percentageKey, priceKey);
    }, 0);
  }
  return total;
};

const { confirm } = Modal;

const columns = (activeOptions, activeEdition, handleSave, helpers, fieldsToSave, deleteBudget) => {
  const isEditableSalePrice = activeEdition === 'set_sale_price';
  const isEditableAccountOfCost = activeEdition === 'account_of_cost';
  const isEditableUpdatedBudget = activeEdition === 'updated_budget';
  const isEditableCurrentBudget = activeEdition === 'current_budget';

  const conditionalConfirm = (record) => {
    const hasChildren = get(record, 'children.length', 0) !== 0;
    let text = '';

    if (record.ID_NAT === 1) {
      text = hasChildren
        ? 'No se puede eliminar un Capítulo con Partidas asociadas. Para eliminar este Capítulo primero debe eliminar las Partidas'
        : 'Esta seguro que desea borrar este Capítulo?';
    }
    if (record.ID_NAT === 3) {
      text = 'Esta seguro que desea borrar esta Partida?';
    }
    return text;
  };

  const showConfirm = (record) => {
    confirm({
      title: record.ID_NAT === 1 ? ' Borrar Capítulo' : 'Borrar partida',
      icon: <ExclamationCircleOutlined />,
      content: conditionalConfirm(record),

      onOk() {
        if (
          (record.ID_NAT === 1 && get(record, 'children.length', null) === 0) ||
          record.ID_NAT === 3
        ) {
          deleteBudget(record.ID_ITEM);
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const canShowButtonEdit = (record) => {
    return (
      (record.ID_NAT === 1 && helpers.access.CONTROL_ALCANCE_EDITAR_CAPITULO) ||
      (record.ID_NAT === 3 && helpers.access.CONTROL_ALCANCE_EDITAR_PARTIDA)
    );
  };
  const canShowButtondelete = (record) => {
    return (
      (record.ID_NAT === 1 && helpers.access.CONTROL_ALCANCE_ELIMINAR_CAPITULO) ||
      (record.ID_NAT === 3 && helpers.access.CONTROL_ALCANCE_ELIMINAR_PARTIDA)
    );
  };

  const columnsResult = [
    <Column
      resizable
      className="border-right"
      verticalAlign="middle"
      align="center"
      width={100}
      fixed
    >
      <HeaderCell>Ítem </HeaderCell>
      <Cell dataKey="CODIGO_ITEM">
        {(record) => {
          return (
            <div className="container-icons-edit">
              <div className="edit_icon_control">
                {canShowButtonEdit(record) ? (
                  <i
                    role="button"
                    tabIndex={-1}
                    onKeyDown={null}
                    className="fa fa-edit edit-icon"
                    onClick={() =>
                      record.ID_NAT === 1
                        ? helpers.setActiveChapterEdition(record)
                        : helpers.setActiveItemEdition(record)
                    }
                  />
                ) : null}
              </div>
              <div className="delete_icon">
                {canShowButtondelete(record) ? (
                  <i
                    role="button"
                    tabIndex={-1}
                    onKeyDown={null}
                    className="fas fa-trash"
                    onClick={() => showConfirm(record)}
                  />
                ) : null}
              </div>
              <div>{record.CODIGO_ITEM}</div>
            </div>
          );
        }}
      </Cell>
    </Column>,

    <Column
      resizable
      className="border-right"
      treeCol
      verticalAlign="middle"
      align="left"
      width={400}
      fixed
    >
      <HeaderCell>Descripción </HeaderCell>
      <Cell dataKey="NOMBRE_ITEM" />
    </Column>,
    activeOptions.option7 ? (
      <ColumnGroup align="center" header="Precios Contrato">
        <Column resizable verticalAlign="middle" width={90} fixed>
          <HeaderCell>PU CD</HeaderCell>
          <Cell dataKey="PRECIO_ITEM" className="align-right">
            {(record) => {
              if (record.ID_NAT === 1) return null;
              return (
                <span title={`$${getFormattedNumber(record.PRECIO_ITEM, 2)}`}>
                  {getFormattedNumber(record.PRECIO_ITEM, null, true, '$')}
                </span>
              );
            }}
          </Cell>
        </Column>
        <Column resizable verticalAlign="middle" width={140} fixed>
          <HeaderCell>GG+UT</HeaderCell>
          <Cell dataKey="CG_UTILIDADES" className="align-right">
            {(record) => {
              if ([0, 1, 4].some((e) => e === record.ID_NAT)) return null;
              const total = record.PRECIO_ITEM_VENTA - record.PRECIO_ITEM;
              return (
                <span title={getFormattedNumber(total, null, null, '$')}>
                  {getFormattedNumber(total, null, true, '$')}
                </span>
              );
            }}
          </Cell>
        </Column>
        <Column resizable verticalAlign="middle" width={90} fixed>
          <HeaderCell>PU VENTA</HeaderCell>
          <EditableCell
            dataIndex="PRECIO_ITEM_VENTA"
            handleSave={handleSave}
            editable={isEditableSalePrice}
            validator={(value) => value >= 0}
            additionalInputFields={() => ({
              type: 'number',
              min: 0,
            })}
            canShow={(record) => record.ID_NAT === 3}
            unit="$"
            customClass="align-right border-right"
            className={() => (isEditableSalePrice ? 'editable' : '')}
          />
        </Column>
      </ColumnGroup>
    ) : activeOptions.option9 ? (
      [
        <Column align="center" verticalAlign="middle" width={90}>
          <HeaderCell>PU CD</HeaderCell>
          <Cell dataKey="PRECIO_ITEM" className="align-right">
            {(record) => {
              if (record.ID_NAT === 1) return null;
              return (
                <span title={`$${getFormattedNumber(record.PRECIO_ITEM, 2)}`}>
                  {getFormattedNumber(record.PRECIO_ITEM, null, true, '$')}
                </span>
              );
            }}
          </Cell>
        </Column>,
      ]
    ) : (
      []
    ),
    ...(activeOptions.option8
      ? [
          <Column resizable align="center" verticalAlign="middle" width={90}>
            <HeaderCell>PU Venta</HeaderCell>
            <Cell dataKey="PRECIO_ITEM_VENTA" className="align-right border-right border-left">
              {(record) => {
                if (record.ID_NAT !== 3) return null;
                return (
                  <span title={`${getFormattedNumber(record.PRECIO_ITEM_VENTA, 2)}`}>
                    {getFormattedNumber(record.PRECIO_ITEM_VENTA, null, true)}
                  </span>
                );
              }}
            </Cell>
          </Column>,
        ]
      : []),

    <Column resizable verticalAlign="middle" align="center" width={90} className="border-right">
      <HeaderCell>Ud</HeaderCell>
      <Cell dataKey="NOMABR_UNI">
        {(record) =>
          hiddeCellRender(record.ID_NAT !== 4 && record.ID_NAT && record.NOMABR_UNI, record)
        }
      </Cell>
    </Column>,
    ...(activeOptions.option1
      ? [
          <ColumnGroup align="center" header="Plan de Cuentas">
            <Column resizable verticalAlign="middle" width={140} fixed>
              <HeaderCell>Id CAS</HeaderCell>
              <EditableCell
                dataIndex="CODIGO_COSTO_ITEM"
                editable={isEditableAccountOfCost}
                handleSave={handleSave}
                includeRecordFields={['ID_ITEM_SUP']}
                type="string"
                canShow={(record) => record.ID_NAT === 4}
                validator={(value) => helpers.findAccountOfCostCode(value)}
                className={() => (isEditableAccountOfCost ? 'editable' : '')}
                editButtonCallback={helpers.openAccountOfCostEditionModal}
              />
            </Column>
            <Column resizable verticalAlign="middle" width={140} fixed>
              <HeaderCell>Cuenta CAS</HeaderCell>
              <Cell dataKey="NOMBRE_COSTO_ITEM">
                {(record) => {
                  if (record.ID_NAT !== 4) return null;

                  const element = (
                    helpers.findAccountOfCostCode(record.CODIGO_COSTO_ITEM, false, false) || {}
                  ).NOMBRE_COSTO_ITEM;

                  return element || '';
                }}
              </Cell>
            </Column>
            <Column
              resizable
              className={() => 'border-right'}
              verticalAlign="middle"
              width={140}
              fixed
            >
              <HeaderCell>Naturaleza</HeaderCell>
              <EditableFamilyCell
                dataIndex="ID_TIN_CC"
                editable={isEditableAccountOfCost}
                handleSave={handleSave}
                calculateValue={(record) => {
                  return get(helpers, `inputsTypesObject[${record.ID_TIN_CC}]`, '');
                }}
                canShow={(record) => record.ID_NAT === 4}
                className={() => (isEditableAccountOfCost ? 'editable' : '')}
                editButtonCallback={helpers.openFamilyEditionModal}
                disableInput
              />
            </Column>
          </ColumnGroup>,
        ]
      : []),
    ...(activeOptions.option2
      ? [
          <ColumnGroup align="center" header="Contrato Base">
            {[
              ...(activeOptions.option0
                ? [
                    <Column
                      resizable
                      verticalAlign="middle"
                      align="center"
                      width={90}
                      className="border-left "
                    >
                      <HeaderCell>Factor</HeaderCell>
                      <Cell dataKey="FACTOR_BASE">
                        {(record) => hiddeCellRender(record.FACTOR_BASE, record)}
                      </Cell>
                    </Column>,
                  ]
                : []),
              <Column resizable className="" verticalAlign="middle" align="center" width={90}>
                <HeaderCell>Cantidad</HeaderCell>
                <Cell dataKey="CANTIDAD_BASE" className="align-right">
                  {(record) => {
                    if (record.ID_NAT === 1) return null;
                    const decimal = record.ID_NAT === 3 ? 1 : 3;
                    return (
                      <span title={`$${getFormattedNumber(record.CANTIDAD_BASE)}`}>
                        {getFormattedNumber(record.CANTIDAD_BASE, decimal)}
                      </span>
                    );
                  }}
                </Cell>
              </Column>,
              ...(activeOptions.option9
                ? [
                    <Column resizable verticalAlign="middle" align="center" width={140}>
                      <HeaderCell>Total CD</HeaderCell>
                      <Cell dataKey="TOTAL_BASE" className="align-right">
                        {(record) => {
                          const total = String(record.CANTIDAD_BASE * record.PRECIO_ITEM);
                          return (
                            <span title={`$${getFormattedNumber(total)}`}>{`$${getFormattedNumber(
                              total,
                              null,
                              true
                            )}`}</span>
                          );
                        }}
                      </Cell>
                    </Column>,
                  ]
                : []),
              <Column resizable verticalAlign="middle" align="center" width={140}>
                <HeaderCell>Total Venta</HeaderCell>
                <Cell dataKey="TOTAL_VENTA_BASE" className="align-right border-right-ext">
                  {(record) => {
                    const total = String(
                      getTotals(
                        record,
                        'CANTIDAD_BASE',
                        'PORCENTAJE_EXITO_MOD',
                        'PRECIO_ITEM_VENTA'
                      )
                    );

                    return (
                      <span title={`$${getFormattedNumber(total)}`}>{`$${getFormattedNumber(
                        total,
                        null,
                        true
                      )}`}</span>
                    );
                  }}
                </Cell>
              </Column>,
            ]}
          </ColumnGroup>,
        ]
      : []),
    ...(isEditableCurrentBudget
      ? [
          <Column resizable verticalAlign="middle" align="center" width={100}>
            <HeaderCell>
              <DoubleLineCell firstValue="Traspasar" secondValue="Forecast" />
            </HeaderCell>
            <Cell className="editable" dataKey="TRASPASAR_PPTO_ACTUALIZADO">
              {(record) => {
                if (record.ID_NAT !== 3) return null;
                return (
                  <Checkbox
                    className="keep-checkbox"
                    onChange={(e) =>
                      helpers.changeInfoToCopyUpdatedBudget(e.target.checked, record)
                    }
                  />
                );
              }}
            </Cell>
          </Column>,
        ]
      : []),
    ...(activeOptions.option3
      ? [
          <ColumnGroup align="center" header="Contrato Vigente">
            {[
              ...(activeOptions.option0
                ? [
                    <Column resizable verticalAlign="middle" align="center" width={90}>
                      <HeaderCell>Factor</HeaderCell>
                      <Cell dataKey="FACTOR_VIGENTE">
                        {(record) => hiddeCellRender(record.FACTOR_BASE, record)}
                      </Cell>
                    </Column>,
                  ]
                : []),
              <Column
                resizable
                verticalAlign="middle"
                width={90}
                fixed
                className={() => (!activeOptions.option8 ? 'align-right' : '')}
              >
                <HeaderCell>Cantidad</HeaderCell>
                <EditableCell
                  decimalsIndex={(record) => {
                    if (record.ID_NAT === 3) {
                      return 1;
                    }
                    if (record.ID_NAT === 4) {
                      return 3;
                    }
                    return null;
                  }}
                  dataIndex="CANTIDAD_VIGENTE"
                  editable={isEditableCurrentBudget}
                  handleSave={handleSave}
                  validator={(value) => value >= 0}
                  conditionToEditable={(record) => record.ID_NAT === 3}
                  additionalInputFields={() => ({
                    type: 'number',
                    min: 0,
                  })}
                  objectModifier={(obj, record, value) => {
                    const { COMENTARIO_VIGENTE } = record;
                    obj.COMENTARIO_VIGENTE = COMENTARIO_VIGENTE || '';
                    if (get(record, 'children[0].ID_NAT') === 5) {
                      obj.children = record.children.map((e) => ({
                        ...e,
                        CANTIDAD_VIGENTE: value,
                        COMENTARIO_VIGENTE: obj.COMENTARIO_VIGENTE,
                      }));
                    }

                    return obj;
                  }}
                  canShow={(record) => record.ID_NAT !== 1 && record.ID_NAT !== 4 && record.ID_NAT}
                  customClass="align-right"
                  className={() => (isEditableCurrentBudget ? 'editable' : '')}
                />
              </Column>,
              ...(activeOptions.option9
                ? [
                    <Column resizable verticalAlign="middle" align="center" width={140}>
                      <HeaderCell>Total CD</HeaderCell>
                      <Cell dataKey="TOTAL_VIGENTE" className="align-right">
                        {(record) => {
                          const total = String(getTotals(record, 'CANTIDAD_VIGENTE'));
                          return (
                            <span title={`$${getFormattedNumber(total)}`}>{`$${getFormattedNumber(
                              total,
                              null,
                              true
                            )}`}</span>
                          );
                        }}
                      </Cell>
                    </Column>,
                  ]
                : []),
              ...(activeOptions.option8
                ? [
                    <Column resizable verticalAlign="middle" align="center" width={140}>
                      <HeaderCell>Total Venta</HeaderCell>
                      <Cell
                        dataKey="TOTAL_VENTA_VIGENTE"
                        className={
                          !activeOptions.option10 ? 'border-right align-right' : 'align-right'
                        }
                      >
                        {(record) => {
                          if (record.ID_NAT === 4 || !record.ID_NAT) return null;
                          const total = String(
                            getTotals(
                              record,
                              'CANTIDAD_VIGENTE',
                              'PORCENTAJE_EXITO_MOD',
                              'PRECIO_ITEM_VENTA'
                            )
                          );

                          return (
                            <span title={`$${getFormattedNumber(total)}`}>{`$${getFormattedNumber(
                              total,
                              null,
                              true
                            )}`}</span>
                          );
                        }}
                      </Cell>
                    </Column>,
                  ]
                : []),

              ...(activeOptions.option10
                ? [
                    <Column resizable verticalAlign="middle" align="center" width={150}>
                      <HeaderCell>
                        <DoubleLineCell firstValue="Variación CD" secondValue="(Vigente - Base)" />
                      </HeaderCell>
                      <Cell dataKey="VARIACION_VIGENTE" className="align-right border-right">
                        {(record) => {
                          const variation =
                            getTotals(record, 'CANTIDAD_VIGENTE') -
                            record.CANTIDAD_BASE * record.PRECIO_ITEM;
                          const sign = variation < 0 ? '-' : '';

                          return (
                            <div
                              title={`${sign} $${getFormattedNumber(
                                String(toFixed(Math.abs(variation)))
                              )}`}
                              className={variation < 0 ? 'negative' : ''}
                            >{`${sign} $${getFormattedNumber(
                              String(toFixed(Math.abs(variation)))
                            )}`}</div>
                          );
                        }}
                      </Cell>
                    </Column>,
                  ]
                : []),
            ]}
          </ColumnGroup>,
          ...(isEditableCurrentBudget
            ? [
                <Column resizable verticalAlign="middle" width={90}>
                  <HeaderCell>Comentario</HeaderCell>
                  <EditableCell
                    dataIndex="COMENTARIO_VIGENTE"
                    editable
                    handleSave={handleSave}
                    type="string"
                    canShow={(record) => record.ID_NAT === 3}
                    conditionToEditable={(record) =>
                      record.ID_NAT === 3 &&
                      fieldsToSave.find(({ ID_ITEM }) => record.ID_ITEM === ID_ITEM)
                    }
                    className={() => 'editable'}
                  />
                </Column>,
              ]
            : []),
        ]
      : []),
    ...(activeOptions.option4
      ? [
          <ColumnGroup align="center" header="Forecast">
            {[
              ...(activeOptions.option0
                ? [
                    <Column resizable verticalAlign="middle" align="center" width={90}>
                      <HeaderCell>Factor</HeaderCell>
                      <Cell dataKey="FACTOR_MOD" className="align-right">
                        {(record) => hiddeCellRender(record.FACTOR_MOD, record)}
                      </Cell>
                    </Column>,
                  ]
                : []),
              ...(isEditableUpdatedBudget
                ? [
                    <Column resizable verticalAlign="middle" width={90} fixed>
                      <HeaderCell>% exito</HeaderCell>
                      <Cell dataKey="PORCENTAJE_EXITO_MOD" className="align-right">
                        {(record) => hiddeCellRender(record.PORCENTAJE_EXITO_MOD, record, 0)}
                      </Cell>
                    </Column>,
                  ]
                : []),
              <Column resizable verticalAlign="middle" width={90} fixed>
                <HeaderCell>Cantidad</HeaderCell>
                <EditableCell
                  dataIndex="CANTIDAD_MOD"
                  decimalsIndex={(record) => {
                    if (record.ID_NAT === 3) {
                      return 1;
                    }
                    if (record.ID_NAT === 4) {
                      return 3;
                    }
                    return null;
                  }}
                  editable={isEditableUpdatedBudget}
                  handleSave={handleSave}
                  canShow={(record) => record.ID_NAT !== 1 && record.ID_NAT !== 4 && record.ID_NAT}
                  validator={(value) => value >= 0}
                  additionalInputFields={() => ({
                    type: 'number',
                    min: 0,
                  })}
                  conditionToEditable={(record) => record.ID_NAT === 3}
                  objectModifier={(obj, record, value) => {
                    const { COMENTARIO_MOD } = record;
                    obj.COMENTARIO_MOD = COMENTARIO_MOD || '';
                    if (get(record, 'children[0].ID_NAT') === 5) {
                      obj.children = record.children.map((e) => ({
                        ...e,
                        CANTIDAD_MOD: value,
                        COMENTARIO_MOD: obj.COMENTARIO_MOD,
                      }));
                    }

                    return obj;
                  }}
                  customClass="align-right"
                  className={() => (isEditableUpdatedBudget ? 'editable' : '')}
                />
              </Column>,
              ...(activeOptions.option9
                ? [
                    <Column resizable verticalAlign="middle" align="center" width={140}>
                      <HeaderCell>Total CD</HeaderCell>
                      <Cell dataKey="TOTAL_MOD" className="align-right">
                        {(record) => {
                          const total = String(getTotals(record, 'CANTIDAD_MOD'));
                          return (
                            <span title={`$${getFormattedNumber(total)}`}>{`$${getFormattedNumber(
                              total,
                              null,
                              true
                            )}`}</span>
                          );
                        }}
                      </Cell>
                    </Column>,
                  ]
                : []),
              ...(activeOptions.option8
                ? [
                    <Column resizable verticalAlign="middle" align="center" width={140}>
                      <HeaderCell>Total Venta</HeaderCell>
                      <Cell
                        dataKey="TOTAL_VENTA_MOD"
                        className={
                          !activeOptions.option10 ? 'border-right align-right' : 'align-right'
                        }
                      >
                        {(record) => {
                          if (record.ID_NAT === 4 || !record.ID_NAT) return null;
                          const total = String(
                            getTotals(
                              record,
                              'CANTIDAD_MOD',
                              'PORCENTAJE_EXITO_MOD',
                              'PRECIO_ITEM_VENTA'
                            )
                          );

                          return (
                            <span title={`$${getFormattedNumber(total)}`}>{`$${getFormattedNumber(
                              total,
                              null,
                              true
                            )}`}</span>
                          );
                        }}
                      </Cell>
                    </Column>,
                  ]
                : []),

              ...(activeOptions.option10
                ? [
                    <Column
                      resizable
                      verticalAlign="middle"
                      align="center"
                      width={180}
                      className="border-right"
                    >
                      <HeaderCell>
                        <DoubleLineCell
                          firstValue="Variación CD"
                          secondValue="(Forecast - PPTO. Vigente)"
                        />
                      </HeaderCell>
                      <Cell dataKey="VARIACION_VIGENTE" className="align-right">
                        {(record) => {
                          const variation =
                            getTotals(record, 'CANTIDAD_MOD') -
                            getTotals(record, 'CANTIDAD_VIGENTE');

                          const sign = variation < 0 ? '-' : '';
                          return (
                            <div
                              title={`${sign} $${getFormattedNumber(
                                String(toFixed(Math.abs(variation)))
                              )}`}
                              className={variation < 0 ? 'negative' : ''}
                            >{`${sign} $${getFormattedNumber(
                              String(toFixed(Math.abs(variation))),
                              2
                            )}`}</div>
                          );
                        }}
                      </Cell>
                    </Column>,
                  ]
                : []),
            ]}
          </ColumnGroup>,
          ...(isEditableUpdatedBudget
            ? [
                <Column resizable verticalAlign="middle" width={90}>
                  <HeaderCell>Comentario</HeaderCell>
                  <EditableCell
                    dataIndex="COMENTARIO_MOD"
                    editable
                    handleSave={handleSave}
                    type="string"
                    canShow={(record) => record.ID_NAT === 3}
                    conditionToEditable={(record) =>
                      record.ID_NAT === 3 &&
                      fieldsToSave.find(({ ID_ITEM }) => record.ID_ITEM === ID_ITEM)
                    }
                    className={() => 'editable'}
                  />
                </Column>,
              ]
            : []),
        ]
      : []),
    ...(activeOptions.option5
      ? [
          <ColumnGroup
            align="center"
            header={
              get(helpers, 'advanceDatesData[0].FECHA_AVA') ? (
                <DoubleLineCell
                  firstValue="Producción Acum."
                  secondValue={`al ${get(helpers, 'advanceDatesData[0].FECHA_AVA')}`}
                />
              ) : (
                'No existe Producción Acum.'
              )
            }
          >
            {[
              <Column resizable verticalAlign="middle" width={90} fixed>
                <HeaderCell>Cantidad</HeaderCell>
                <Cell dataKey="PRECIO_ITEM" className="align-right">
                  {(record) => {
                    if (record.ID_NAT === 1) return null;
                    const decimal = record.ID_NAT === 3 ? 1 : 3;
                    return (
                      <span title={`$${getFormattedNumber(record.CANT_AVA)}`}>
                        {getFormattedNumber(record.CANT_AVA, decimal)}
                      </span>
                    );
                  }}
                </Cell>
              </Column>,
              <Column resizable verticalAlign="middle" align="center" width={140}>
                <HeaderCell>Total CD</HeaderCell>
                <Cell dataKey="TOTAL_MOD" className="align-right">
                  {(record) => {
                    const total = `${getTotals(record, 'CANT_AVA')}`;

                    return (
                      <span title={`$${getFormattedNumber(total)}`}>{`$${getFormattedNumber(
                        total,
                        null,
                        true
                      )}`}</span>
                    );
                  }}
                </Cell>
              </Column>,
            ]}
          </ColumnGroup>,
          ...(isEditableUpdatedBudget
            ? [
                <Column resizable verticalAlign="middle" width={90}>
                  <HeaderCell>Comentario</HeaderCell>
                  <EditableCell
                    dataIndex="COMENTARIO_MOD"
                    editable
                    handleSave={handleSave}
                    type="string"
                    canShow={(record) => record.ID_NAT === 3}
                    conditionToEditable={(record) =>
                      record.ID_NAT === 3 &&
                      fieldsToSave.find(({ ID_ITEM }) => record.ID_ITEM === ID_ITEM)
                    }
                    className={() => 'editable'}
                  />
                </Column>,
              ]
            : []),
        ]
      : []),
  ];
  return columnsResult;
};

export default columns;
