import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, message, Modal } from 'antd';
import _ from 'lodash';
import 'moment/locale/es';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import update from 'immutability-helper';
import { CaretDownOutlined, CaretUpOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Table from '../../components/TableExtend';

import InputWithTopLabel from '../../components/InputWithTopLabel';

import ChapterForm from './ChapterForm';
import AccountOfCostForm from './AccountOfCostForm';

import columns from './columns';
import { SearchIcon } from '../../utils';

import { getChildren, getNodeObjectToUpdate } from '../../utils/budgetAndProductionHelpers';
import {
  getInputsTypes as getInputsTypesAction,
  getUnits as getUnitsAction,
} from '../../actions/budget';
import {
  getAccountOfCost as getAccountOfCostAction,
  getAccountOfCostItems as getAccountOfCostItemsAction,
  getClassification as getClassificationAction,
  deleteAccountOfCostElement,
} from '../../actions/accountOfCost';

import './index.scss';

const CostCenter = ({
  selectedWork,
  getInputsTypes,
  inputsTypes,
  accountOfCostInfo,
  getAccountOfCost,
  units,
  getUnits,
  getAccountOfCostItems,
  access,
  getClassification,
}) => {
  const [tree, setTree] = useState(null);
  const [expandedRowKeys, setExpandeRowKeys] = useState([]);
  const [familiesOptions, setFamiliesOptions] = useState(null);
  const [chapters, setChapters] = useState([]);
  const [activeAccountOfCost, setActiveAccountOfCost] = useState(null);
  const [activeChapter, setActiveChapter] = useState(null);
  const [isTreeUpdated, setIsTreeUpdated] = useState({});
  const [activeToDelete, setActiveToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const resetState = () => {
    setFamiliesOptions(null);
    setChapters([]);
    setActiveAccountOfCost(null);
  };

  const {
    data: accountOfCostData,
    isFetching: isFetchingAccountOfCost,
    items,
    classification,
  } = accountOfCostInfo;

  const createTreeData = (treeData) => {
    if (treeData && treeData.length > 0) {
      let treeObject = {};

      const initialExpandedRowKeys = [];
      const chaptersList = [];

      treeData.forEach((item) => {
        if (treeObject[item.ID_ITEM_COSTO_SUP]) {
          treeObject = update(treeObject, {
            [item.ID_ITEM_COSTO_SUP]: {
              $push: [item],
            },
          });
        } else {
          treeObject = update(treeObject, {
            [item.ID_ITEM_COSTO_SUP]: {
              $set: [item],
            },
          });
        }

        if (item.ES_CUENTA_COSTO === 1 && !treeObject[item.ID_ITEM_COSTO]) {
          treeObject = update(treeObject, {
            [item.ID_ITEM_COSTO]: {
              $set: [],
            },
          });
        }

        if (item.ID_ITEM_COSTO_SUP === 0) {
          initialExpandedRowKeys.push(item.ID_ITEM_COSTO);
        }
      });

      const newTree = [];
      const paths = {
        [treeObject[0][0].ID_ITEM_COSTO]: '0',
      };
      const savePath = (id, path) => {
        if (!paths[id]) {
          paths[id] = path;
        }
      };

      const saveParent = (element) => {
        if (!chaptersList.some((e) => e.ID_ITEM_COSTO === element.ID_ITEM_COSTO)) {
          chaptersList.push(element);
        }
      };
      treeObject[0].forEach((current, i) => {
        const element = {
          ...current,
          PATH: `${i}`,
          children: getChildren(
            current.ID_ITEM_COSTO,
            treeObject,
            `${i}`,
            savePath,
            saveParent,
            'ID_ITEM_COSTO',
            false,
            0,
            'ES_CUENTA_COSTO',
            1,
            3,
            true
          ),
        };
        saveParent(element);

        newTree.push(element);
      });

      setTree(newTree);
      setExpandeRowKeys(initialExpandedRowKeys);
      setChapters(chaptersList);
    } else if (treeData && treeData.length === 0) {
      setTree([]);
      setExpandeRowKeys([]);
    }
  };

  useEffect(() => {
    if (
      !isFetchingAccountOfCost &&
      (!accountOfCostData[selectedWork] || isTreeUpdated[selectedWork])
    ) {
      getAccountOfCost(selectedWork);
      resetState();
      setIsTreeUpdated({ ...isTreeUpdated, [selectedWork]: false });
    } else if (accountOfCostData[selectedWork] && !isFetchingAccountOfCost) {
      const treeData = accountOfCostData[selectedWork];
      createTreeData(treeData);
    }
  }, [selectedWork]);

  useEffect(() => {
    if (accountOfCostData[selectedWork]) {
      const treeData = accountOfCostData[selectedWork];
      createTreeData(treeData);
    }
  }, [accountOfCostData]);

  const markTreeAsUpdated = () => {
    if (!isTreeUpdated[selectedWork]) {
      setIsTreeUpdated({ ...isTreeUpdated, [selectedWork]: true });
    }
  };

  const removeElement = (record) => {
    const { ID_ITEM_COSTO, ES_CUENTA_COSTO } = record;
    setIsDeleting(true);
    deleteAccountOfCostElement(ID_ITEM_COSTO, ES_CUENTA_COSTO === 1)
      .then(() => {
        markTreeAsUpdated();

        let newTree = _.cloneDeep(tree);
        const path = record.PATH.split('.');
        if (path.length === 1) {
          const index = tree.findIndex((e) => e.ID_ITEM_COSTO === record.ID_ITEM_COSTO);
          newTree = update(tree, { $splice: [[index, 1]] });
        } else {
          const newPath = update(path, { $splice: [[path.length - 1, 1]] });

          const updatedNode = getNodeObjectToUpdate(newPath, 0, {
            children: {
              $apply: (childrens) =>
                childrens.filter(
                  (currentChildren) => currentChildren.ID_ITEM_COSTO !== record.ID_ITEM_COSTO
                ),
            },
          });

          newTree = update(tree, updatedNode);
        }
        setTree(newTree);
        setActiveToDelete(null);
        setIsDeleting(false);
      })
      .catch(() => {
        message.error();
        setIsDeleting(false);
        setActiveToDelete(null);
      });
  };

  const showConfirm = (record) => {
    Modal.confirm({
      title: `Eliminar ${record.ES_CUENTA_COSTO === 1 ? 'capítulo' : 'cuenta de costo'}`,
      icon: <ExclamationCircleOutlined />,
      content: 'Confirma esta acción',
      okText: 'Ok',
      cancelText: 'Cancelar',
      onOk: () => removeElement(record),
    });
  };

  useEffect(() => {
    if (activeToDelete) {
      const { ES_CUENTA_COSTO, children } = activeToDelete;
      if (ES_CUENTA_COSTO === 1) {
        if (children.length) {
          message.error(
            'No puede eliminar este capítulo, primero debe eliminar las cuentas de costo asociadas'
          );
          setActiveToDelete(null);
        } else {
          showConfirm(activeToDelete);
        }
      } else {
        showConfirm(activeToDelete);
      }
    }
  }, [activeToDelete]);

  useEffect(() => {
    if (!inputsTypes.data && !inputsTypes.isFetching) {
      getInputsTypes();
    } else if (!familiesOptions && inputsTypes.data) {
      setFamiliesOptions(inputsTypes.data);
    }
  }, [inputsTypes]);

  const changeExpandedRows = (expanded, ID_ITEM_COSTO) => {
    if (expanded) {
      setExpandeRowKeys(
        update(expandedRowKeys, {
          $push: [ID_ITEM_COSTO],
        })
      );
    } else {
      const index = expandedRowKeys.indexOf(ID_ITEM_COSTO);
      if (index >= 0) {
        setExpandeRowKeys(
          update(expandedRowKeys, {
            $splice: [[index, 1]],
          })
        );
      }
    }
  };

  const addAccountOfCostToTree = (parent, element, oldParent) => {
    markTreeAsUpdated();
    const parsedPath = parent.PATH ? parent.PATH.split('.') : null;
    const PATH = parent.PATH ? `${parent.PATH}.${parent.children.length}` : `${chapters.length}`;
    const newElement = {
      ...element,
      PATH,
    };

    let newTree = _.cloneDeep(tree);

    if (
      newElement.ES_CUENTA_COSTO === 1 &&
      !chapters.find((e) => e.ID_ITEM_COSTO === element.ID_ITEM_COSTO)
    ) {
      setChapters(update(chapters, { $push: [newElement] }));
    }

    const updateToDo = {
      $apply: (data) => {
        const newData = _.cloneDeep(data);
        newData.push({
          ...newElement,
          ...(activeChapter && activeChapter.children ? { children: activeChapter.children } : {}),
        });
        newData.sort((a, b) => {
          if (a.ES_CUENTA_COSTO < b.ES_CUENTA_COSTO) return 1;
          if (a.ES_CUENTA_COSTO > b.ES_CUENTA_COSTO) return -1;
          return 0;
        });
        return newData;
      },
    };
    const updatedNode =
      parsedPath && newElement.ID_ITEM_COSTO_SUP !== 0
        ? getNodeObjectToUpdate(parsedPath, 0, {
            children: updateToDo,
          })
        : updateToDo;

    newTree = update(newTree, updatedNode);
    if (oldParent) {
      const oldParentPath = oldParent.PATH ? oldParent.PATH.split('.') : null;
      const nodeTofind = oldParentPath ? oldParent.children : tree;
      const index = nodeTofind.findIndex((e) => e.ID_ITEM_COSTO === element.ID_ITEM_COSTO);
      const updateOperation = { $splice: [[index, 1]] };
      const deleteUpdate = oldParentPath
        ? getNodeObjectToUpdate(oldParentPath, 0, {
            children: updateOperation,
          })
        : updateOperation;
      newTree = update(newTree, deleteUpdate);
    }
    setTree(newTree);
    setActiveChapter(null);
    setActiveAccountOfCost(null);
  };

  const updateAccountOfCostInTree = (parent, element) => {
    markTreeAsUpdated();
    const parsedPath = parent.PATH ? parent.PATH.split('.') : null;
    const nodeTofind = _.get(parent, 'children', tree);
    const index = nodeTofind.findIndex((e) => e.ID_ITEM_COSTO === element.ID_ITEM_COSTO);
    const updatedNode = parsedPath
      ? getNodeObjectToUpdate(parsedPath, 0, {
          children: { [index]: { $merge: element } },
        })
      : { [index]: { $merge: element } };

    const newTree = update(tree, updatedNode);
    setTree(newTree);
    setActiveChapter(null);
    setActiveAccountOfCost(null);
  };

  return (
    <div className="cost-center-view">
      <Row gutter={[30, 30]}>
        <Col className="cost-center-col" span={24}>
          <div className="buttons">
            {!!access.PLAN_CTAS_CAS_CREAR_CAPITULO && (
              <ChapterForm
                tree={tree}
                chapters={chapters}
                addAccountOfCostToTree={addAccountOfCostToTree}
                activeRecord={activeChapter}
                updateAccountOfCostInTree={updateAccountOfCostInTree}
                onCancel={() => setActiveChapter(null)}
              />
            )}

            {!!access.PLAN_CTAS_CAS_AGREGAR_CUENTA && (
              <AccountOfCostForm
                tree={tree}
                chapters={chapters}
                units={units}
                getUnits={getUnits}
                addAccountOfCostToTree={addAccountOfCostToTree}
                activeRecord={activeAccountOfCost}
                updateAccountOfCostInTree={updateAccountOfCostInTree}
                onCancel={() => setActiveAccountOfCost(null)}
                items={items}
                getAccountOfCostItems={getAccountOfCostItems}
                getClassification={getClassification}
                classification={classification}
              />
            )}
          </div>
          <Table
            className="common-table"
            height={window.innerHeight - 168}
            hover={false}
            rowKey="ID_ITEM_COSTO"
            bordered
            headerHeight={75}
            rowHeight={28}
            virtualized
            shouldUpdateScroll={false}
            isTree
            locale={{
              emptyMessage: 'No hay datos para mostrar',
              loading: 'Cargando...',
            }}
            loading={isFetchingAccountOfCost || isDeleting}
            data={tree || []}
            rowClassName={(record) => {
              if (record) {
                let type = 'level';
                if (record.ES_CUENTA_COSTO === 1) {
                  type = 'green-row';
                } else {
                  type = 'second-level';
                }
                if (!_.get(record, 'children.length')) {
                  type += ' without-children';
                }
                return type;
              }
              return '';
            }}
            renderTreeToggle={(_icon, _row, expanded) => {
              if (expanded) {
                return <CaretUpOutlined />;
              }
              return <CaretDownOutlined />;
            }}
            expandedRowKeys={expandedRowKeys}
            onExpandChange={(expanded, rowData) =>
              changeExpandedRows(expanded, rowData.ID_ITEM_COSTO)
            }
          >
            {columns({ setActiveAccountOfCost, setActiveChapter, setActiveToDelete })}
          </Table>
        </Col>
      </Row>
    </div>
  );
};

CostCenter.propTypes = {
  selectedWork: PropTypes.number.isRequired,
  getInputsTypes: PropTypes.func.isRequired,
  inputsTypes: PropTypes.object.isRequired,
  accountOfCostInfo: PropTypes.object.isRequired,
  getAccountOfCost: PropTypes.func.isRequired,
  units: PropTypes.bool.isRequired,
  getUnits: PropTypes.func.isRequired,
  getAccountOfCostItems: PropTypes.func.isRequired,
  access: PropTypes.object.isRequired,
  getClassification: PropTypes.func.isRequired,
};

export default connect(
  ({ production, works, accountOfCost, budget: { inputsTypes, units } }) => ({
    production,
    selectedWork: works.selectedWork,
    accountOfCostInfo: accountOfCost,
    inputsTypes,
    units,
  }),
  (dispatch) => ({
    getInputsTypes: bindActionCreators(getInputsTypesAction, dispatch),
    getAccountOfCost: bindActionCreators(getAccountOfCostAction, dispatch),
    getUnits: bindActionCreators(getUnitsAction, dispatch),
    getAccountOfCostItems: bindActionCreators(getAccountOfCostItemsAction, dispatch),
    getClassification: bindActionCreators(getClassificationAction, dispatch),
  })
)(CostCenter);
