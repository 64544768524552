import React, { useState, useEffect } from 'react';
import update from 'immutability-helper';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Modal, Button, DatePicker, Form, Select } from 'antd';
import { getConfigAction, commonPropTypes } from '../../../../utils';
import { getAdvanceDates as getAdvanceDatesAction } from '../../../../actions/production';
import { createPaymentState as createPaymentStateAction } from '../../../../actions/paymentState';

import './index.scss';

const AddPaymentState = ({
  contractCurrency,
  getConfig,
  getAdvanceDates,
  production,
  selectedWork,
  lastNumberEp,
  createPaymentState,
  history,
  list,
  access,
}) => {
  const [state, setState] = useState({
    show: false,
    saving: false,
    advanceDates: [],
  });
  const { data: contractCurrencyData = [] } = contractCurrency;
  const {
    advanceDates: { isFetching: isFetchingAdvanceDates, data: advanceDatesData = [] },
  } = production;
  const [form] = Form.useForm();

  const { show, saving } = state;

  const productionOptions = (advanceDatesData[selectedWork] || [])
    .filter(
      (e) =>
        form.getFieldValue('FECHA_EP') &&
        form.getFieldValue('FECHA_EP').isAfter(moment(e.FECHA_AVA, 'DD-MM-YYYY'))
    )
    .map(({ FECHA_AVA, ID_AVA_ENC }) => ({
      label: FECHA_AVA,
      value: ID_AVA_ENC,
    }));

  const submit = (values) => {
    setState(update(state, { saving: { $set: true } }));
    const requestBody = {
      ...values,
      ID_ESTADO_EP: 0,
      NUMERO_EP: lastNumberEp,
      NOMBRE_ESTADO_EP: 'POR PRESENTAR',
      OBSERVACION_EP: '',
      FECHA_EP: moment(values.FECHA_EP).format('DD-MM-YYYY'),
      ID_AVA_ENC: values.ID_AVA_ENC || 0,
      VALOR_CAMBIO_EP: 0,
    };
    const stateUpdates = { saving: { $set: false }, show: { $set: false } };
    createPaymentState(requestBody)
      .then((ID_EP_ENC) => {
        setState(update(state, stateUpdates));
        history.push(`/home/payment_state/${ID_EP_ENC}?project=${selectedWork}`);
      })
      .catch(() => {
        setState(update(state, stateUpdates));
      });
  };

  useEffect(() => {
    if (!advanceDatesData[selectedWork]) {
      getAdvanceDates(selectedWork);
    }
  }, [advanceDatesData, selectedWork]);

  useEffect(() => {
    if (!contractCurrencyData) {
      getConfig('CONTRACT_CURRENCY', 'contractCurrency', 'GetProyectoMonedaContrato');
    }
    setTimeout(() => {
      form.setFieldsValue({ FECHA_EP: moment() });
    }, 500);
  }, []);

  useEffect(() => {
    if (!show) {
      form.resetFields();
      form.setFieldsValue({ FECHA_EP: moment() });
    }
  }, [show]);

  const onValuesChange = (changedValues) => {
    const { FECHA_EP } = changedValues;
    const productionDateId = form.getFieldValue('ID_AVA_ENC');
    const options = advanceDatesData[selectedWork];
    const productionDate = productionDateId
      ? (options.find((e) => e.ID_AVA_ENC === productionDateId) || {}).FECHA_AVA
      : null;
    if (FECHA_EP) {
      if (productionDate && !FECHA_EP.isAfter(moment(productionDate, 'DD-MM-YYYY'))) {
        const { ID_AVA_ENC } =
          options.find((e) => FECHA_EP.isAfter(moment(e.FECHA_AVA, 'DD-MM-YYYY'))) || {};
        form.setFieldsValue({ ID_AVA_ENC });
      }

      setState(
        update(state, {
          date: { $set: FECHA_EP },
        })
      );
    }
  };

  return (
    <div className="add-payment-state">
      {access.CONTROL_EP_CREAR && (
        <Button
          onClick={() =>
            setState(
              update(state, {
                show: { $set: true },
              })
            )
          }
          type="primary"
          className="new-item-button"
        >
          <i className="fa fa-plus" /> &nbsp;Nuevo Estado de Pago
        </Button>
      )}

      <Modal
        closable={false}
        wrapClassName="common-modal add-payment-state-modal"
        visible={show}
        width={700}
        onCancel={() => {
          setState(
            update(state, {
              show: { $set: false },
            })
          );
        }}
        footer={null}
        okText="Guardar"
      >
        <div className="modal-container">
          <div className="modal-header">
            <div />
            <span>Nuevo Estado de Pago</span>
            <i
              className="fa fa-times"
              onClick={() => {
                setState(
                  update(state, {
                    show: { $set: false },
                  })
                );
              }}
            />
          </div>
          <div className="form-body">
            <Form onValuesChange={onValuesChange} form={form} layout="vertical" onFinish={submit}>
              <Form.Item
                label="Ingresar Fecha de EP al"
                name="FECHA_EP"
                required
                rules={[
                  {
                    required: true,
                    message: 'Por favor seleccione una fecha',
                  },
                  {
                    validator: async (_obj, value) => {
                      if (value) {
                        const isRepeatedDate = list.some(
                          (e) => e.FECHA_EP === value.format('DD-MM-YYYY') && e.ID_ESTADO_EP !== 2
                        );
                        if (isRepeatedDate) {
                          return Promise.reject(
                            new Error(
                              'Ya se esta usando esta fecha en otro encabezado de estado de pago'
                            )
                          );
                        }
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <DatePicker placeholder="dd-mm-aaaa" format="DD-MM-YYYY" />
              </Form.Item>
              <Form.Item
                label="Proponer Producción al"
                name="ID_AVA_ENC"
                initialValue={
                  productionOptions && productionOptions.length ? productionOptions[0].value : null
                }
                required
                rules={
                  productionOptions && productionOptions.length
                    ? [
                        {
                          required: true,
                          message: 'Por favor seleccione una fecha',
                        },
                      ]
                    : []
                }
              >
                <Select
                  disabled={
                    isFetchingAdvanceDates || !productionOptions || !productionOptions.length
                  }
                  loading={isFetchingAdvanceDates}
                  options={productionOptions}
                />
              </Form.Item>
              <div className="modal-footer">
                <Button
                  disabled={saving}
                  onClick={() =>
                    setState(
                      update(state, {
                        show: { $set: false },
                      })
                    )
                  }
                >
                  Cancelar
                </Button>
                <Button loading={saving} disabled={saving} type="primary" htmlType="submit">
                  Generar nuevo estado de pago
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

AddPaymentState.propTypes = {
  contractCurrency: commonPropTypes.isRequired,
  getConfig: PropTypes.func.isRequired,
  getAdvanceDates: PropTypes.func.isRequired,
  selectedWork: PropTypes.number.isRequired,
  production: PropTypes.object.isRequired,
  lastNumberEp: PropTypes.number.isRequired,
  createPaymentState: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
  access: PropTypes.object.isRequired,
};

export default connect(
  ({ contractCurrency, production, works }) => ({
    contractCurrency,
    production,
    selectedWork: works.selectedWork,
  }),
  (dispatch) => ({
    getConfig: bindActionCreators(getConfigAction, dispatch),
    getAdvanceDates: bindActionCreators(getAdvanceDatesAction, dispatch),
    createPaymentState: bindActionCreators(createPaymentStateAction, dispatch),
  })
)(AddPaymentState);
