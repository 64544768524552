import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, DatePicker, Form, Input, Modal, Popover } from 'antd';
import './index.scss';

const isValidValueHour = (attendanceTypeSet, tValue) => {
  if (tValue.length === 0) {
    return true;
  }

  if (tValue.length > 2) {
    return false;
  }
  const valueUC = tValue.toUpperCase();

  return Boolean(/^([1]?[0-9]|[2]?[0-4])$/.test(valueUC) || attendanceTypeSet.has(valueUC));
};

const isValidValueDay = (attendanceTypeSet, tValue) => {
  if (tValue.length === 0) {
    return true;
  }

  if (tValue.length > 4) {
    return false;
  }

  if (Number.isNaN(parseFloat(tValue))) {
    const valueUC = tValue.toUpperCase();
    return Boolean(attendanceTypeSet.has(valueUC));
  }
  return Boolean(/^(?:[1-9][0-9]{0,9}(?:\.[0-9])?|0?[.][1-9])$/.test(tValue));
};
const MassAttendance = ({
  attendanceTypes,
  attendanceViewType,
  closedAttendanceSet,
  handleAttendance,
  onClose,
}) => {
  const [form] = Form.useForm();

  const submit = (values) => {
    const { attendanceType, endDate, startDate } = values;
    const dates = [moment(startDate).format('DD/MM/YYYY'), moment(endDate).format('DD/MM/YYYY')];
    if (attendanceType.length === 1) {
      handleAttendance(dates, 'multiple', attendanceType.toUpperCase());
    } else {
      handleAttendance(dates, 'multiple', attendanceType);
    }

    onClose();
  };

  const attendanceTypeSet = new Set(attendanceTypes.map((e) => e.ABREVIA_CNS));

  const legends = (
    <div className="legendsMassAttendance">
      {attendanceTypes.map((attendanceType) => (
        <div className="legendContainer" key={`legend${attendanceType.ID_CNS}`}>
          <div style={{ backgroundColor: attendanceType.ID_CNS === 5 ? '#6aa84f' : '#007bff' }}>
            {attendanceType.ABREVIA_CNS}
          </div>
          <span>
            {attendanceViewType === 1
              ? attendanceType.ID_CNS === 5
                ? 'Horas trabajadas, por defecto nueve (ingrese las horas que correspondan)'
                : attendanceType.NOMBRE_CNS
              : attendanceType.ID_CNS === 5
              ? 'Jornada trabajada (1 es el valor máximo y equivale a una jornada de 9 horas por empleado)'
              : attendanceType.NOMBRE_CNS}
          </span>
        </div>
      ))}
    </div>
  );

  return (
    <Modal
      title="Ingreso Asistencia Masiva"
      visible
      onCancel={() => onClose()}
      width={400}
      footer={null}
      wrapClassName="massAttendance"
    >
      <Form layout="vertical" form={form} onFinish={submit}>
        <Form.Item
          label="Día Inicial"
          name="startDate"
          initialValue={moment()}
          rules={[
            {
              required: true,
              message: 'Por favor ingrese el día inicial',
            },
            {
              validator: async (obj, value) => {
                const startDate = moment(value).format('YYYY-MM-DD');
                const endDate = form.getFieldValue('endDate');
                const formattedEndDate = endDate && moment(endDate).format('YYYY-MM-DD');

                if (endDate && formattedEndDate < startDate) {
                  return Promise.reject(
                    new Error('El día inicial no puede ser mayor al día final')
                  );
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <DatePicker
            disabledDate={(current) => closedAttendanceSet.has(current.format('DD/MM/YYYY'))}
            placeholder="dd/mm/aaaa"
            format="DD/MM/YYYY"
          />
        </Form.Item>

        <Form.Item
          label="Día Final"
          name="endDate"
          initialValue={moment()}
          rules={[
            {
              required: true,
              message: 'Por favor ingrese el día final',
            },
            {
              validator: async (obj, value) => {
                const endDate = moment(value).format('YYYY-MM-DD');
                const startDate = form.getFieldValue('startDate');
                const formattedStartDate = startDate && moment(startDate).format('YYYY-MM-DD');

                if (startDate && formattedStartDate > endDate) {
                  return Promise.reject(
                    new Error('El día final no puede ser menor al día inicial')
                  );
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <DatePicker
            disabledDate={(current) => closedAttendanceSet.has(current.format('DD/MM/YYYY'))}
            placeholder="dd/mm/aaaa"
            format="DD/MM/YYYY"
          />
        </Form.Item>

        <div className="attendanceTypeAndIcon">
          <Form.Item
            label="Marca del Día"
            name="attendanceType"
            rules={[
              {
                required: true,
                message: 'Por favor ingrese la marca del día',
              },
              {
                validator: async (obj, value) => {
                  if (attendanceViewType === 1 && !isValidValueHour(attendanceTypeSet, value)) {
                    return Promise.reject(new Error('Por favor ingrese una marca del día válida'));
                  }
                  if (attendanceViewType === 2 && !isValidValueDay(attendanceTypeSet, value)) {
                    return Promise.reject(new Error('Por favor ingrese una marca del día válida'));
                  }

                  return Promise.resolve();
                },
              },
            ]}
            initialValue={`${attendanceViewType === 2 ? '1' : '9'}`}
          >
            <Input />
          </Form.Item>
          <Popover content={legends} placement="right">
            <i className="fa fa-info-circle" />
          </Popover>
        </div>

        <div className="buttons">
          <Form.Item>
            <Button onClick={() => onClose()}>Cancelar</Button>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Agregar Asistencia
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

MassAttendance.propTypes = {
  attendanceTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  attendanceViewType: PropTypes.number.isRequired,
  closedAttendanceSet: PropTypes.object.isRequired,
  handleAttendance: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MassAttendance;
