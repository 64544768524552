import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import './index.scss';

const { Option } = Select;

const SelectWithTopLabel = ({
  className,
  label,
  onChange,
  options,
  value,
  width,
  keepShowingLabel,
  disabled,
  ...restOfProps
}) => {
  return (
    <div className={`selectContainer ${className}`}>
      {(typeof value === 'number' || keepShowingLabel) && (
        <div className="selectLabel">{label}</div>
      )}
      <Select
        value={value}
        onChange={onChange}
        style={{ width }}
        {...restOfProps}
        disabled={disabled}
      >
        {options.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.name}
          </Option>
        ))}
      </Select>
    </div>
  );
};

SelectWithTopLabel.defaultProps = {
  className: '',
  width: 100,
  keepShowingLabel: false,
  disabled: false,
};

SelectWithTopLabel.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.number.isRequired,
  width: PropTypes.number,
  keepShowingLabel: PropTypes.bool,
};

export default SelectWithTopLabel;
