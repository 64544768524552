import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import InputWithTopLabel from '../../../../components/InputWithTopLabel';
import EditPaymentState from '../EditPaymentState';
import './index.scss';

const LeftTools = ({ info, access, selectedWork }) => {
  const { data: worksData } = useSelector((state) => state.works);

  const currentWork = worksData.find((work) => `${work.ID_OBR}` === `${selectedWork}`);

  return (
    <div className="left-tools">
      <InputWithTopLabel
        keepShowingLabel
        disabled
        label="Proyecto"
        valueProp={currentWork.NOMABR_OBR || ''}
      />
      <InputWithTopLabel keepShowingLabel disabled label="EP al" valueProp={info.FECHA_EP || ''} />
      <div className="number-ep">
        <InputWithTopLabel
          keepShowingLabel
          disabled
          label="Número EP"
          valueProp={`N° ${info.NUMERO_EP || ''}`}
        />
      </div>
      <InputWithTopLabel
        keepShowingLabel
        disabled
        label="Estado EP"
        valueProp={info.NOMBRE_ESTADO_EP || ''}
      />
      <EditPaymentState selectedWork={selectedWork} info={info} access={access} />
    </div>
  );
};

LeftTools.propTypes = {
  info: PropTypes.object.isRequired,
  access: PropTypes.object.isRequired,
  selectedWork: PropTypes.string.isRequired,
};

export default LeftTools;
