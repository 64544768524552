import _ from 'lodash';
import { message } from 'antd';
import api, { getHeaders } from '../utils/api';
import {
  PAYMENT_STATE_FETCHED,
  GET_PAYMENT_STATE,
  GET_PAYMENT_STATE_ERROR,
  STATES_FETCHED,
  PAYMENT_STATE_CREATED,
  EDIT_PAYMENT_STATE_RESUME,
  EDIT_PAYMENT_STATE,
  EDIT_PAYMENT_STATE_TOTALS,
} from '../reducers/paymentState';

import store from '../store';

export const paymentStateFetched = (paymentState, workId, attr) => ({
  type: PAYMENT_STATE_FETCHED,
  paymentState,
  workId,
  attr,
});

export const statesFetched = (states) => ({
  type: STATES_FETCHED,
  states,
});

export const paymentStateCreated = (paymentState, workId) => ({
  type: PAYMENT_STATE_CREATED,
  paymentState,
  workId,
});

export const editPaymentStateResume = (id, parameters, workId) => ({
  type: EDIT_PAYMENT_STATE_RESUME,
  id,
  workId,
  parameters,
});

export const editPaymentStateSuccess = (body, workId) => ({
  type: EDIT_PAYMENT_STATE,
  body,
  workId,
});

export const getPaymentStates = (ID_OBR) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: GET_PAYMENT_STATE });

    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR,
    };

    api
      .post('/api/CostoEstadoPago/GetCostoEstadoPagoEnc', requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch(paymentStateFetched(res.data, ID_OBR, 'list'));
          resolve(res.data);
        } else {
          dispatch({
            type: GET_PAYMENT_STATE_ERROR,
          });
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_PAYMENT_STATE_ERROR,
        });
        reject(err);
      });
  });

export const getPaymentState =
  (ID_OBR, ID_EP_ENC, filters = null) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: GET_PAYMENT_STATE });

      const requestBody = {
        USUARIO: localStorage.getItem('foco_username'),
        EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
        ID_OBR: Number(ID_OBR),
        ID_EP_ENC: Number(ID_EP_ENC),
        ...(filters || {
          CAPITULOS: [],
          PARTIDAS: [],
        }),
      };
      api
        .post('/api/CostoEstadoPago/GetCostoEstadoPago', requestBody, getHeaders())
        .then((res) => {
          if (res.status === 200) {
            dispatch(paymentStateFetched(res.data, ID_OBR, 'data'));
            resolve(res.data);
          } else {
            dispatch({
              type: GET_PAYMENT_STATE_ERROR,
            });
            reject(res.data);
          }
        })
        .catch((err) => {
          dispatch({
            type: GET_PAYMENT_STATE_ERROR,
          });
          reject(err);
        });
    });

export const getStates = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: GET_PAYMENT_STATE });

    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
    };
    api
      .post('/api/CostoEstadoPago/GetEstados', requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch(statesFetched(res.data));
          resolve(res.data);
        } else {
          dispatch({
            type: GET_PAYMENT_STATE_ERROR,
          });
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_PAYMENT_STATE_ERROR,
        });
        reject(err);
      });
  });

export const createPaymentState = (body) => (dispatch) =>
  new Promise((resolve, reject) => {
    const ID_OBR = _.get(store.getState(), 'works.selectedWork', '');
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR,
      ...body,
    };
    api
      .post('/api/CostoEstadoPago/SetCostoEstadoPagoEnc', requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          message.success('Estado de pago agregado correctamente');
          dispatch(paymentStateCreated({ ...res.data, ...body }, ID_OBR));
          resolve(res.data.ID_EP_ENC);
        } else {
          message.error('Hubo un error al crear el estado de pago');
          reject();
        }
      })
      .catch((err) => {
        message.error('Hubo un error al crear el estado de pago');
        reject(err);
      });
  });

export const editPaymentState = (body, workId) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ...body,
    };
    api
      .post('/api/CostoEstadoPago/UpdCostoEstadoPagoEnc', requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          message.success('Estado de pago editado correctamente');
          dispatch(editPaymentStateSuccess(body, workId));
          resolve();
        } else {
          message.error('Hubo un error al editar el estado de pago');
          reject();
        }
      })
      .catch((err) => {
        console.log('error', err);
        message.error('Hubo un error al editar el estado de pago');
        reject(err);
      });
  });

const editSuccessMessage = 'Valores editados correctamente';
const editErrorMessage = 'Ocurrió un error al editar los valores';

export const editPaymentStateCells = (data, ID_EP_ENC, resumeHasChanged, workId) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_EP_ENC,
      ...data,
    };
    api
      .post('/api/CostoEstadoPago/SetCantidadEP', requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200 && res.data === 'OK') {
          resolve(res.data);
          if (resumeHasChanged) {
            const parameters = { DESCUENTO_EP: data.DESCUENTO_EP, REAJUSTE_EP: data.REAJUSTE_EP };
            dispatch(editPaymentStateResume(ID_EP_ENC, parameters, workId));
          }
          message.success(editSuccessMessage, 8);
        } else {
          reject(res.data);
          message.error(editErrorMessage, 8);
        }
      })
      .catch((err) => {
        console.log('eeee', err);
        message.error(editErrorMessage, 8);
        reject(err);
      });
  });

export const editTotals = (body) => (dispatch) => {
  const workId = _.get(store.getState(), 'works.selectedWork', '');
  dispatch({
    type: EDIT_PAYMENT_STATE_TOTALS,
    body,
    workId,
  });
};
