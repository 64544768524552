import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { reverse } from 'lodash';
import { Button, Checkbox } from 'antd';
import './index.scss';

const selectAllKey = 'Seleccionar Todos';

const Filter = ({
  buttonTitle,
  onClick,
  onClose,
  options,
  show,
  subtitle,
  title,
  popupMode,
  scrollable,
  allowSelectAll,
  elementKey,
  onSave,
  elementTitleKey,
  data,
  extraKey,
}) => {
  const [search, onChangeSearch] = useState('');
  const [showOptions, setShowOptions] = useState(true);
  const [expandedSections, setExpandedSections] = useState({});
  const [lastSavedOptions, setLastSavedOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const isStringList = options.length && typeof options[0] === 'string';
  const filteredOptions =
    options.length > 0 && isStringList
      ? options.filter((key) => key.toLowerCase().includes(search))
      : options;
  const toggleSections = (key) => {
    const oldExpandedValue = !!expandedSections[key];
    setExpandedSections({ ...expandedSections, [key]: !oldExpandedValue });
  };

  useEffect(() => {
    if (show) {
      setSelectedOptions(lastSavedOptions);
    }
  }, [show]);

  const getIndexesToUnselect = (items) => {
    const indexes = [];
    items.forEach(({ [elementKey]: id }) => {
      const index = selectedOptions.findIndex((current) => current[elementKey] === id);
      indexes.push([index, 1]);
    });

    return indexes;
  };

  const getNodes = (id) => {
    const result = [];
    data.forEach((current) => {
      if (current[`${elementKey}_SUP`] === id) {
        result.push(...[current, ...getNodes(current[elementKey])]);
      }
    });

    return result;
  };

  const changeFilters = (param) => {
    const id = param[elementKey];
    const index = selectedOptions.findIndex((current) => current[elementKey] === id);
    const isSelected = index >= 0;
    const items = [param];
    items.push(...getNodes(id));
    setSelectedOptions(
      update(
        selectedOptions,
        isSelected ? { $splice: getIndexesToUnselect(reverse(items)) } : { $push: items }
      )
    );
  };

  const getOptions = (list, level) => {
    return (
      <>
        {list.length > 0 &&
          [...(allowSelectAll ? [selectAllKey] : []), ...list].map((current) => {
            const currentIsString = typeof current === 'string';
            const isChecked = currentIsString
              ? selectedOptions.indexOf(current) !== -1
              : selectedOptions.find((e) => e[elementKey] === current[elementKey]);

            const hasChildren = current.children && current.children.length > 0;
            const text = (
              <div className="option-text">
                {currentIsString
                  ? current
                  : `${
                      current.CODIGO_COSTO_ITEM && level > 1 ? `(${current.CODIGO_COSTO_ITEM})` : ''
                    } ${current[elementTitleKey]}`}
              </div>
            );
            let head = (
              <div className="node-element">
                <Checkbox checked={isChecked} onChange={() => changeFilters(current)} />
                {text}
              </div>
            );

            const key = current[elementKey];

            if (hasChildren) {
              head = (
                <div className="head-toggle">
                  <i
                    className={`fa fa-chevron-${
                      expandedSections[key] ? 'up' : 'down'
                    } toggle-button`}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleSections(key);
                    }}
                  />
                  <Checkbox checked={isChecked} onChange={() => changeFilters(current)} />
                  {text}
                </div>
              );
            } else if (!current[extraKey]) {
              return null;
            }

            const content = (
              <div className={`checkbox-row level-${level}`}>
                {head}
                {!currentIsString &&
                  hasChildren &&
                  expandedSections[key] &&
                  getOptions(current.children, level + 1)}
              </div>
            );

            return content;
          })}
      </>
    );
  };

  return (
    <div className="activities-filter">
      {popupMode && (
        <Button className="main-button" onClick={() => onClick()} type="default">
          {buttonTitle}
        </Button>
      )}
      {(show || !popupMode) && (
        <div className={`options-container ${!popupMode ? 'not-popup' : ''}`}>
          <div className="options-header">
            <div />
            <span>{title}</span>
            <i
              className="fa fa-times"
              onClick={() => {
                onClose();
              }}
            />
          </div>

          {options.length > 0 && subtitle && (
            <div className="toggle-filters" onClick={() => setShowOptions(!showOptions)}>
              <div />
              <span>{subtitle}</span>
              <i className={`fa fa-chevron-${showOptions ? 'up' : 'down'}`} />
            </div>
          )}
          {showOptions && (
            <div className={`options ${scrollable ? 'scrollable' : ''}`}>
              {getOptions(filteredOptions, 1)}
            </div>
          )}
          <div className="buttons">
            <Button
              type="default"
              onClick={() => {
                onClose();
                setSelectedOptions(lastSavedOptions);
                onChangeSearch('');
              }}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => {
                onSave(selectedOptions);
                setLastSavedOptions(selectedOptions);
                onChangeSearch('');
              }}
              type="primary"
            >
              Aplicar
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

Filter.propTypes = {
  buttonTitle: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  show: PropTypes.bool.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  popupMode: PropTypes.bool,
  allowSelectAll: PropTypes.bool,
  scrollable: PropTypes.bool,
  onSave: PropTypes.func,
  elementTitleKey: PropTypes.string,
  elementKey: PropTypes.string.isRequired,
  extraKey: PropTypes.string,
};

Filter.defaultProps = {
  popupMode: true,
  scrollable: false,
  allowSelectAll: false,
  onSave: () => {},
  elementTitleKey: 'title',
  extraKey: 'key',
};

export default Filter;
