import * as FileSaver from 'file-saver';
import ExcelJS from 'exceljs';
import get from 'lodash/get';

const addRowsToWorksheetCd = (worksheet, currentTree) => {
  worksheet.properties.defaultColWidth = 100;
  currentTree.forEach((current) => {
    worksheet.addRow({
      ...current,
      CODIGO_CC: !current.ID_CC_SUP || current.TIPO === 5 ? '' : current.CODIGO_CC,
      ESTUDIO_UM: current.ID_CC_SUP ? current.ESTUDIO_UM : '',
      ESTUDIO_CANT: current.TIPO !== 1 ? current.ESTUDIO_CANT : '',
      PERIODO_CANT_AVA: current.TIPO !== 1 ? current.PERIODO_CANT_AVA : '',
      ACUMULADO_CANT_AVA: current.TIPO !== 1 ? current.ACUMULADO_CANT_AVA : '',
      ACUMULADO_COSTO: current.ACUMULADO_COSTO,
    });
    const color = '00FFFFFF';

    worksheet.lastRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: color },
    };

    worksheet.lastRow.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    if (current.children && current.children.length) {
      addRowsToWorksheetCd(worksheet, current.children);
    }
  });
};

const addRowsToWorksheetGg = (worksheet, currentTree) => {
  worksheet.properties.defaultColWidth = 100;
  currentTree.forEach((current) => {
    worksheet.addRow({
      ...current,
      CODIGO_CC: !current.ID_CC_SUP || current.TIPO === 5 ? '' : current.CODIGO_CC,
      ESTUDIO_UM: current.ID_CC_SUP ? current.ESTUDIO_UM : '',
      ESTUDIO_CANT: current.TIPO !== 1 ? current.ESTUDIO_CANT : '',
      PERIODO_CANT_AVA: current.TIPO !== 1 ? current.PERIODO_CANT_AVA : '',
      ACUMULADO_CANT_AVA: current.TIPO !== 1 ? current.ACUMULADO_CANT_AVA : '',
      ACUMULADO_COSTO: current.ACUMULADO_COSTO,
    });
    const color = '00FFFFFF';

    worksheet.lastRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: color },
    };

    worksheet.lastRow.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    if (current.children && current.children.length) {
      addRowsToWorksheetGg(worksheet, current.children);
    }
  });
};

const addRowsToWorksheetCas = (worksheet, currentTree) => {
  worksheet.properties.defaultColWidth = 100;
  currentTree.forEach((current) => {
    worksheet.addRow(current);
    let color = '00FFFFFF';
    if (current.ID_NAT === 3) {
      color = '00F2F2F2';
    } else if (current.ID_NAT === 1) {
      color = '00CCCCCC';
    }

    worksheet.lastRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: color },
    };

    worksheet.lastRow.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
  });
};

export default (treeCas, treeCd, treeGg, casDate) => {
  const workbook = new ExcelJS.Workbook();
  workbook.creator = 'Conpax';
  workbook.lastModifiedBy = 'Conpax';
  const currentDate = new Date();
  workbook.created = currentDate;
  workbook.modified = currentDate;

  const worksheetCas = workbook.addWorksheet('Resumen cas', {
    pageSetup: { paperSize: 9, orientation: 'landscape' },
  });

  worksheetCas.columns = [
    {
      header: 'Categoría de Cuenta',
      key: 'NOMBRE',
      width: 40,
      numFmt: '',
    },
    {
      header: 'Presupuesto -> Vigente (Base + Modif. aprobadas)',
      key: 'PPTO_VIGENTE',
      width: 50,
    },
    {
      header: 'Presupuesto -> Forecast (presupuesto proyectado a término)',
      key: 'PPTO_FORECAST',
      width: 50,
    },
    {
      header: 'Presupuesto -> Aumento o disminución',
      key: 'PPTO_AUMENTO',
      width: 50,
    },
    {
      header: 'Presupuesto Para Gastar -> Parcial',
      key: 'PROD_PARCIAL',
      width: 50,
    },
    {
      header: 'Presupuesto Para Gastar -> Acumulado',
      key: 'PROD_ACUM',
      width: 50,
    },
    {
      header: 'Costos -> Parcial',
      key: 'COSTO_PARCIAL',
      width: 50,
    },
    {
      header: 'Costos -> Acumulado',
      key: 'COSTO_ACUM',
      width: 50,
    },
    {
      header: 'Ahorro o perdida segun avance -> Parcial',
      key: 'DESV_FECHA_PARCIAL',
      width: 50,
    },
    {
      header: 'Ahorro o perdida segun avance -> Acumulado',
      key: 'DESV_FECHA_ACUM',
      width: 50,
    },
    {
      header: 'Proyección de Resultado -> Estimado costo del saldo de obra',
      key: 'PROY_TERM_SALDO',
      width: 50,
    },
    {
      header: 'Proyección de Resultado -> Total Costo a término',
      key: 'PROY_TERM_TOTAL',
      width: 50,
    },
    {
      header: 'Proyección de Resultado -> Ahorro o Perdida (Variación)',
      key: 'PROY_TERM_DESV',
      width: 50,
    },
  ];
  const colors = [
    '00FFFFFF',
    '00A4C788',
    '00A4C788',
    '00A4C788',
    'FFE2AA41',
    'FFE2AA41',
    'FFE2AA41',
    'FFE2AA41',
    'FFE2AA41',
    'FFE2AA41',
    'FF4397E6',
    'FF4397E6',
    'FF4397E6',
  ];
  worksheetCas.getRow(1).eachCell((cell, i) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: colors[i - 1] },
    };
    cell.font = { bold: true };
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.border = {
      top: { style: 'thin', color: { argb: 'FF000000' } },
      left: { style: 'thin', color: { argb: 'FF000000' } },
      bottom: { style: 'thin', color: { argb: 'FF000000' } },
      right: { style: 'thin', color: { argb: 'FF000000' } },
    };
  });

  addRowsToWorksheetCas(worksheetCas, treeCas);

  worksheetCas.eachRow((row, rowNumber) => {
    row.height = 28;
    console.log('row', row);
    if (rowNumber >= 2) {
      row.eachCell((cell, colNumber) => {
        if (treeCas[rowNumber - 2]?.BOLD) {
          cell.font = { bold: true };
        }
        console.log('asdads', colNumber);
        if (colNumber >= 2) {
          const { value } = cell;
          if (typeof value === 'number') {
            cell.numFmt = '$#,##0';
          }
        }
      });
    }
  });

  const worksheetCd = workbook.addWorksheet('CAS CD', {
    pageSetup: { paperSize: 9, orientation: 'landscape' },
  });

  worksheetCd.columns = [
    { header: 'Item', key: 'CODIGO_CC', width: 10 },
    {
      header: 'Actividad',
      key: 'NOMBRE_CC',
      width: 20,
    },
    {
      header: 'Ppto. Estudio -> Ud',
      key: 'ESTUDIO_UM',
      width: 20,
    },
    {
      header: 'Ppto. Estudio -> Cant',
      key: 'ESTUDIO_CANT',
      width: 50,
    },
    {
      header: 'Ppto. Estudio -> Monto ($)',
      key: 'ESTUDIO_VALOR',
      width: 50,
    },
    {
      header: 'Ejecutado Período -> Avance Cant',
      key: 'PERIODO_CANT_AVA',
      width: 50,
    },
    {
      header: 'Ejecutado Período -> Costo ($)',
      key: 'PERIODO_COSTO',
      width: 50,
    },
    {
      header: 'Total Acumulado -> Avance Cant',
      key: 'ACUMULADO_CANT_AVA',
      width: 50,
    },
    {
      header: 'Total Acumulado -> Costo ($)',
      key: 'ACUMULADO_COSTO',
      width: 50,
    },
    {
      header: 'Porcentajes -> % Avance',
      key: 'PORC_AVANCE',
      width: 50,
    },
    {
      header: 'Porcentajes -> % Gastado',
      key: 'PORC_GASTADO',
      width: 50,
    },
    {
      header: 'Costos Unitarios -> Presente Estado',
      key: 'COSTO_UNI_PERIODO',
      width: 50,
    },
    {
      header: 'Costos Unitarios -> Acum Anterior',
      key: 'COSTO_UNI_ANT',
      width: 50,
    },
    {
      header: 'Costos Unitarios -> Acum. Actual',
      key: 'COSTO_UNI_ACT',
      width: 50,
    },
    {
      header: 'Costos Unitarios -> Estimado Base',
      key: 'COSTO_UNI_BASE',
      width: 50,
    },
    {
      header: 'Gastado Sobre el Histórico -> A Término',
      key: 'GAST_HIST_TERM',
      width: 50,
    },
    {
      header: 'Gastado Sobre el Histórico -> Periodo',
      key: 'GAST_HIST_PER',
      width: 50,
    },
    {
      header: 'Gastado Sobre el Histórico -> Acum. Actual',
      key: 'GAST_HIST_ACT',
      width: 50,
    },
    {
      header: 'Proyección a Término de Obra -> Cantidad Saldo Obra',
      key: 'PROY_CANT_SALDO',
      width: 50,
    },
    {
      header: 'Proyección a Término de Obra -> Costo Unitario Saldo Obra',
      key: 'PROY_CU_SALDO',
      width: 50,
    },
    {
      header: 'Proyección a Término de Obra -> Desviación a Término',
      key: 'PROY_DESV_TERM',
      width: 50,
    },
    {
      header: '',
      key: 'TIPO',
      hidden: true,
    },
  ];

  worksheetCd.getRow(1).eachCell((cell) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF007BFF' },
    };
    cell.font = { bold: true };
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.border = {
      top: { style: 'thin', color: { argb: 'FF000000' } },
      left: { style: 'thin', color: { argb: 'FF000000' } },
      bottom: { style: 'thin', color: { argb: 'FF000000' } },
      right: { style: 'thin', color: { argb: 'FF000000' } },
    };
  });

  addRowsToWorksheetCd(worksheetCd, treeCd);
  worksheetCd.eachRow((row, rowNumber) => {
    row.height = 28;
    const itemType = get(row, ['_cells', 21, '_value', 'model', 'value']);

    if (rowNumber === 2 || itemType === 1) {
      row.font = { color: { argb: 'FF008000' }, bold: true };
    }

    if (rowNumber >= 2) {
      row.eachCell((cell, colNumber) => {
        if (colNumber === 2) {
          if (itemType === 1) {
            cell.value = `    ${cell.value}`;
          } else if (itemType === 3) {
            cell.value = `        ${cell.value}`;
          } else if (itemType === 5) {
            cell.value = `            ${cell.value}`;
          }
        }
        if ([4, 6, 8, 11, 12, 13, 14, 15, 16, 17, 19, 20].includes(colNumber - 1)) {
          cell.numFmt = '$#,##0';
        } else if ([3, 5, 7, 18].includes(colNumber - 1)) {
          cell.numFmt = '#,##0.00';
        } else if ([9, 10].includes(colNumber - 1)) {
          cell.value /= 100;
          cell.numFmt = '0.0%';
        }
      });
    }
  });

  const worksheetGg = workbook.addWorksheet('CAS GG', {
    pageSetup: { paperSize: 9, orientation: 'landscape' },
  });

  worksheetGg.columns = [
    { header: 'Item', key: 'CODIGO_CC', width: 10 },
    {
      header: 'Actividad',
      key: 'NOMBRE_CC',
      width: 20,
    },
    {
      header: 'Ppto. Estudio -> Ud',
      key: 'ESTUDIO_UM',
      width: 20,
    },
    {
      header: 'Ppto. Estudio -> Cant',
      key: 'ESTUDIO_CANT',
      width: 50,
    },
    {
      header: 'Ppto. Estudio -> Monto ($)',
      key: 'ESTUDIO_VALOR',
      width: 50,
    },
    {
      header: 'Ejecutado Período -> Avance Cant',
      key: 'PERIODO_CANT_AVA',
      width: 50,
    },
    {
      header: 'Ejecutado Período -> Costo ($)',
      key: 'PERIODO_COSTO',
      width: 50,
    },
    {
      header: 'Total Acumulado -> Avance Cant',
      key: 'ACUMULADO_CANT_AVA',
      width: 50,
    },
    {
      header: 'Total Acumulado -> Costo ($)',
      key: 'ACUMULADO_COSTO',
      width: 50,
    },
    {
      header: 'Porcentajes -> % Avance',
      key: 'PORC_AVANCE',
      width: 50,
    },
    {
      header: 'Porcentajes -> % Gastado',
      key: 'PORC_GASTADO',
      width: 50,
    },
    {
      header: 'Costos Unitarios -> Presente Estado',
      key: 'COSTO_UNI_PERIODO',
      width: 50,
    },
    {
      header: 'Costos Unitarios -> Acum Anterior',
      key: 'COSTO_UNI_ANT',
      width: 50,
    },
    {
      header: 'Costos Unitarios -> Acum. Actual',
      key: 'COSTO_UNI_ACT',
      width: 50,
    },
    {
      header: 'Costos Unitarios -> Estimado Base',
      key: 'COSTO_UNI_BASE',
      width: 50,
    },
    {
      header: 'Gastado Sobre el Histórico -> A Término',
      key: 'GAST_HIST_TERM',
      width: 50,
    },
    {
      header: 'Gastado Sobre el Histórico -> Periodo',
      key: 'GAST_HIST_PER',
      width: 50,
    },
    {
      header: 'Gastado Sobre el Histórico -> Acum. Actual',
      key: 'GAST_HIST_ACT',
      width: 50,
    },
    {
      header: 'Proyección a Término de Obra -> Cantidad Saldo Obra',
      key: 'PROY_CANT_SALDO',
      width: 50,
    },
    {
      header: 'Proyección a Término de Obra -> Costo Unitario Saldo Obra',
      key: 'PROY_CU_SALDO',
      width: 50,
    },
    {
      header: 'Proyección a Término de Obra -> Desviación a Término',
      key: 'PROY_DESV_TERM',
      width: 50,
    },
    {
      header: '',
      key: 'TIPO',
      hidden: true,
    },
  ];
  worksheetGg.getRow(1).eachCell((cell) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF007BFF' },
    };
    cell.font = { bold: true };
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.border = {
      top: { style: 'thin', color: { argb: 'FF000000' } },
      left: { style: 'thin', color: { argb: 'FF000000' } },
      bottom: { style: 'thin', color: { argb: 'FF000000' } },
      right: { style: 'thin', color: { argb: 'FF000000' } },
    };
  });

  addRowsToWorksheetGg(worksheetGg, treeGg);

  worksheetGg.eachRow((row, rowNumber) => {
    row.height = 28;
    const itemType = get(row, ['_cells', 21, '_value', 'model', 'value']);

    if (rowNumber === 2 || itemType === 1) {
      row.font = { color: { argb: 'FF008000' }, bold: true };
    }
    if (rowNumber >= 2) {
      row.eachCell((cell, colNumber) => {
        if (colNumber === 2) {
          if (itemType === 1) {
            cell.value = `    ${cell.value}`;
          } else if (itemType === 3) {
            cell.value = `        ${cell.value}`;
          } else if (itemType === 5) {
            cell.value = `            ${cell.value}`;
          }
        }
        if ([4, 6, 8, 11, 12, 13, 14, 15, 16, 17, 19, 20].includes(colNumber - 1)) {
          cell.numFmt = '$#,##0';
        } else if ([3, 5, 7, 18].includes(colNumber - 1)) {
          cell.numFmt = '#,##0.00';
        } else if ([9, 10].includes(colNumber - 1)) {
          cell.value /= 100;
          cell.numFmt = '0.0%';
        }
      });
    }
  });

  workbook.xlsx
    .writeBuffer()
    .then((buffer) =>
      FileSaver.saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `resumen_cas_cd_gg_${casDate}.xlsx`
      )
    );
};
