import React, { useState, useEffect } from 'react';
import update from 'immutability-helper';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Modal, Button, Input, InputNumber, Form, Select, TreeSelect } from 'antd';
import { saveAccountOfCost } from '../../../actions/accountOfCost';

const { TreeNode } = TreeSelect;

const AddAccountOfCostForm = ({
  isFetchingList,
  tree,
  chapters,
  units,
  addAccountOfCostToTree,
  activeRecord,
  onCancel,
  updateAccountOfCostInTree,
  items,
  getAccountOfCostItems,
  getUnits,
  getClassification,
  classification,
}) => {
  const [state, setState] = useState({
    show: false,
    saving: false,
    advanceDates: [],
    pristine: true,
    selectedChapter: null,
  });
  const { data: itemsData, isFetching: isFetchingItems } = items;
  const { data: classificationData, isFetching: isFetchingClassification } = classification;

  const [form] = Form.useForm();
  const { show, saving, pristine } = state;
  const { data: unitsData, isFetching: isFetchingUnits } = units;

  useEffect(() => {
    if (activeRecord) {
      setState(
        update(state, {
          show: { $set: true },
          selectedChapter: { $set: activeRecord.ID_ITEM_COSTO_SUP },
        })
      );

      const {
        ID_ITEM_COSTO_SUP,
        CODIGO_COSTO_ITEM,
        NOMBRE_COSTO_ITEM,
        ID_UNI,
        ID_RUB,
        ID_CLASIF_CC,
        CANTIDAD_VIGENTE,
        CANTIDAD_MOD,
      } = activeRecord;

      form.setFieldsValue({
        ID_ITEM_COSTO_SUP,
        CODIGO_COSTO_ITEM,
        NOMBRE_COSTO_ITEM,
        ID_UNI,
        ID_RUB,
        ID_CLASIF_CC: ID_CLASIF_CC !== 0 ? ID_CLASIF_CC : null,
        CANTIDAD_VIGENTE,
        CANTIDAD_MOD,
      });
    }
  }, [activeRecord]);

  useEffect(() => {
    getAccountOfCostItems();
    getClassification();
    getUnits();
  }, []);

  const submit = (values) => {
    setState(update(state, { saving: { $set: true } }));
    const requestBody = {
      ...values,
      ES_CUENTA_COSTO: 3,
      ID_CLASIF_CC: values.ID_CLASIF_CC === undefined ? 0 : values.ID_CLASIF_CC,
    };
    debugger;
    if (activeRecord) {
      requestBody.ID_ITEM_COSTO = activeRecord.ID_ITEM_COSTO;
    }
    const stateUpdates = {
      saving: { $set: false },
      show: { $set: false },
      selectedChapter: { $set: null },
    };
    const selectedChapterId = _.get(state, 'selectedChapter.ID_ITEM_COSTO', state.selectedChapter);

    const selectedChapter = chapters.find((e) => e.ID_ITEM_COSTO === selectedChapterId);

    const { NOMABR_UNI } = unitsData && unitsData.find((e) => e.ID_UNI === values.ID_UNI);

    saveAccountOfCost(requestBody, !!activeRecord)
      .then((res) => {
        const parentHasChanged =
          activeRecord && activeRecord.ID_ITEM_COSTO_SUP !== values.ID_ITEM_COSTO_SUP;
        const element = {
          ID_ITEM_COSTO: res.ID_ITEM_COSTO,
          NOMABR_UNI,
          NOM_CLASIF_CC: (
            classificationData.find((e) => e.ID_CLASIF_CC === values.ID_CLASIF_CC) || {
              NOM_CLASIF_CC: '',
            }
          ).NOM_CLASIF_CC,
          ...requestBody,
        };
        if (!activeRecord || parentHasChanged) {
          let oldParent = null;

          if (parentHasChanged) {
            oldParent = chapters.find((e) => e.ID_ITEM_COSTO === activeRecord.ID_ITEM_COSTO_SUP);
          }
          addAccountOfCostToTree(selectedChapter, element, oldParent);
        } else if (activeRecord && !parentHasChanged) {
          updateAccountOfCostInTree(selectedChapter, { ...element, PATH: activeRecord.PATH });
        }
        form.resetFields();
        onCancel();
        setState(update(state, stateUpdates));
      })
      .catch(() => {
        setState(update(state, stateUpdates));
      });
  };

  const onChangeForm = () => {
    if (activeRecord && pristine) {
      setState(update(state, { pristine: { $set: false } }));
    }
  };

  const getTreeNodes = (nodes) => {
    return nodes.map((currentNode) => {
      const primaryLabel = currentNode.NOMBRE_COSTO_ITEM;

      return (
        <TreeNode
          value={currentNode.ID_ITEM_COSTO}
          primaryLabel={primaryLabel}
          node={currentNode}
          title={
            <div className="tree-select-node">
              <span>{primaryLabel}</span>
            </div>
          }
        >
          {currentNode.ES_CUENTA_COSTO === 1 &&
          (_.get(currentNode, 'children', []).some((e) => e.ES_CUENTA_COSTO === 1) ||
            _.get(currentNode, 'children.length', null) === 0)
            ? getTreeNodes(currentNode.children.filter((e) => e.ES_CUENTA_COSTO === 1))
            : null}
        </TreeNode>
      );
    });
  };

  const onSelect = ({ node }) => {
    form.setFieldsValue({
      ID_ITEM_COSTO_SUP: node.ID_ITEM_COSTO,
    });

    setState(
      update(state, {
        selectedChapter: { $set: node },
        ...(activeRecord && pristine ? { pristine: { $set: false } } : {}),
      })
    );
  };

  const onChange = () => {};

  return (
    <div className="add-account">
      <Button
        className="add-account-button"
        disabled={isFetchingList}
        loading={isFetchingList}
        onClick={() =>
          setState(
            update(state, {
              show: { $set: true },
            })
          )
        }
        type="primary"
      >
        Agregar Cuenta
      </Button>
      <Modal
        closable={false}
        wrapClassName="common-modal add-pnf-modal"
        visible={show}
        width={700}
        onCancel={() => {
          setState(
            update(state, {
              show: { $set: false },
            })
          );
        }}
        footer={null}
        okText="Guardar"
      >
        <div className="modal-container">
          <div className="modal-header">
            <div />
            <span>{activeRecord ? 'Editar cuenta' : 'Agregar Nueva Cuenta'}</span>
            <i
              className="fa fa-times"
              onClick={() => {
                setState(
                  update(state, {
                    show: { $set: false },
                  })
                );
              }}
            />
          </div>
          <div className="form-body">
            <Form form={form} layout="vertical" onFinish={submit} onValuesChange={onChangeForm}>
              {tree && (
                <Form.Item
                  label="Capítulo Padre"
                  name="ID_ITEM_COSTO_SUP"
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Por favor seleccione el capítulo padre',
                    },
                  ]}
                >
                  <TreeSelect
                    showSearch
                    placeholder=""
                    dropdownClassName="caps-dropdown"
                    style={{ width: '100%' }}
                    value={_.get(state, 'selectedChapter.ID_ITEM_COSTO_SUP', state.selectedChapter)}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    allowClear
                    treeNodeFilterProp="primaryLabel"
                    treeNodeLabelProp="primaryLabel"
                    onChange={onChange}
                    onSelect={(_value, obj) => onSelect(obj)}
                    treeDefaultExpandedKeys={tree && tree.length ? tree : []}
                  >
                    {getTreeNodes(tree)}
                  </TreeSelect>
                </Form.Item>
              )}
              <Form.Item
                label="Código Cuenta"
                name="CODIGO_COSTO_ITEM"
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese un código de Cuenta',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Nombre Cuenta"
                name="NOMBRE_COSTO_ITEM"
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese un nombre de Cuenta',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Por favor seleccione una Unidad',
                  },
                ]}
                label="Unidad"
                name="ID_UNI"
              >
                <Select
                  disabled={isFetchingUnits}
                  loading={isFetchingUnits}
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {(unitsData || []).map((opt) => (
                    <Select.Option value={opt.ID_UNI} key={opt.ID_UNI}>
                      {opt.NOMBRE_UNI}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Rubro"
                name="ID_RUB"
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese un rubro',
                  },
                ]}
              >
                <Select disabled={isFetchingItems} loading={isFetchingItems}>
                  {itemsData &&
                    itemsData.map((opt) => (
                      <Select.Option value={opt.ID_RUB} key={opt.ID_RUB}>
                        {`${opt.NOMBRE_RUB} (${opt.NOMABR_RUB})`}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item label=" Clasificación" name="ID_CLASIF_CC">
                <Select
                  allowClear
                  disabled={isFetchingClassification}
                  loading={isFetchingClassification}
                >
                  {classificationData &&
                    classificationData.map((opt) => (
                      <Select.Option value={opt.ID_CLASIF_CC} key={opt.ID_CLASIF_CC}>
                        {opt.NOM_CLASIF_CC}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Cantidad Vigente"
                name="CANTIDAD_VIGENTE"
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese la cantidad vigente',
                  },
                ]}
              >
                <InputNumber min={0} />
              </Form.Item>
              <Form.Item
                label="Cantidad Forecast"
                name="CANTIDAD_MOD"
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese la cantidad forecast',
                  },
                ]}
              >
                <InputNumber min={0} />
              </Form.Item>{' '}
              <div className="modal-footer">
                <Button
                  disabled={saving}
                  onClick={() => {
                    setState(
                      update(state, {
                        show: { $set: false },
                      })
                    );
                    onCancel();
                    form.resetFields();
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  loading={saving}
                  disabled={saving /*  || (activeRecord && pristine) */}
                  type="primary"
                  htmlType="submit"
                >
                  {activeRecord ? 'Guardar' : 'Agregar'}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

AddAccountOfCostForm.propTypes = {
  chapters: PropTypes.array.isRequired,
  units: PropTypes.array.isRequired,
  isFetchingList: PropTypes.bool.isRequired,
  addAccountOfCostToTree: PropTypes.func.isRequired,
  activeRecord: PropTypes.object.isRequired,
  updateAccountOfCostInTree: PropTypes.func.isRequired,
  onCancel: PropTypes.string.isRequired,
  tree: PropTypes.array.isRequired,
  items: PropTypes.object.isRequired,
  classification: PropTypes.object.isRequired,
  getAccountOfCostItems: PropTypes.func.isRequired,
  getUnits: PropTypes.func.isRequired,
  getClassification: PropTypes.func.isRequired,
};

export default AddAccountOfCostForm;
