import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import { Modal, Card, Switch, Select, Button } from 'antd';
import './index.scss';
import { saveUsers as saveUsersAction } from '../../../actions/users';

const { Option } = Select;

const EditModal = ({ activeRecord, onCancel, projects, profile, saveUsers }) => {
  const {
    nombre,
    ap_pat,
    ap_mat,
    nom_usuario,
    ACCESO_CAS,
    PROYECTOS,
    PERFILES,
    RESTRINGE_PROYECTO,
  } = activeRecord || {};

  const [state, setState] = useState({
    allowProjects: RESTRINGE_PROYECTO,
    selectProjects: [],
    selectProfile: [],
    selectAccess: ACCESO_CAS,
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (activeRecord) {
      setState(
        update(state, {
          selectProjects: {
            $set: PROYECTOS.split(';')
              .filter((e) => e)
              .map((e) => Number(e)),
          },
          selectProfile: {
            $set: PERFILES.split(';')
              .filter((e) => e)
              .map((e) => Number(e)),
          },
          selectAccess: { $set: !!ACCESO_CAS },

          allowProjects: { $set: !!RESTRINGE_PROYECTO },
        })
      );
    }
  }, [activeRecord]);

  const getRow = (key, value) => (
    <div className="d-flex justify-content-start border-bottom py-3">
      <div className="mr-5 w-40">{key}</div> <div className="ml-5">{value}</div>
    </div>
  );

  const { allowProjects, selectProjects, selectProfile, selectAccess } = state;

  const submit = () => {
    setIsLoading(true);

    saveUsers(
      nom_usuario,
      Number(selectAccess),
      selectProjects.join(';'),
      selectProfile.join(';'),
      Number(allowProjects)
    ).then(() => {
      setIsLoading(false);
      onCancel();
    });
  };

  return (
    <Modal
      visible={activeRecord}
      title="Información de usuario"
      onOk={submit}
      onCancel={onCancel}
      className="edit-modal"
      width={800}
      footer={null}
    >
      <Card title="Información Basica">
        <div className="d-flex flex-column">
          {getRow('Nombre', nombre)}
          {getRow('Apellidos', `${ap_pat} ${ap_mat}`)}
          {getRow('Correo Electrónico', nom_usuario)}
          {/* <div className="d-flex justify-content-start border-bottom py-3">
            <div className="mr-5 w-40">Acceso a CAS online</div>{' '}
            <div className="ml-5">
              <Switch
                disabled
                checked={selectAccess}
                onChange={(checked) =>
                  setState(
                    update(state, {
                      selectAccess: { $set: checked },
                    })
                  )
                }
              />
            </div>
          </div> */}
        </div>
      </Card>
      <Card title="Proyectos" className="mt-2">
        <div>
          <strong>Selecciona los proyectos a los que tendra acceso el usuario</strong>
        </div>
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-start border-bottom py-3">
            <div className="mr-5 w-40">Restringir Proyectos</div>
            <div className="ml-5">
              <Switch
                // disabled
                checked={allowProjects}
                onChange={(checked) =>
                  setState(
                    update(state, {
                      allowProjects: { $set: checked },
                    })
                  )
                }
              />
            </div>
          </div>
          <div className="d-flex justify-content-start border-bottom py-3">
            {allowProjects && (
              <>
                <div className="mr-5 w-40">Proyectos</div>
                <div className="ml-5">
                  {projects.data && PROYECTOS !== undefined && (
                    <Select
                      mode="multiple"
                      className="user-modal-select"
                      allowClear
                      placeholder="Por favor seleccione los proyectos"
                      value={selectProjects}
                      onChange={(value) => {
                        setState(
                          update(state, {
                            selectProjects: { $set: value },
                          })
                        );
                      }}
                    >
                      {projects.data.map((e) => (
                        <Option key={e.NOMABR_OBR} value={e.ID_OBR}>
                          {e.NOMABR_OBR}
                        </Option>
                      ))}
                    </Select>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </Card>
      <Card title="Perfiles" className="mt-2">
        <div>
          <strong>Selecciona los perfiles a los que tendra acceso el usuario</strong>
        </div>
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-start border-bottom py-3">
            <div className="mr-5 w-40">Perfiles</div>
            <div className="ml-5">
              {profile.data && PERFILES !== undefined && (
                <Select
                  mode="multiple"
                  className="user-modal-select"
                  allowClear
                  placeholder="Por favor seleccione los perfiles"
                  value={selectProfile}
                  onChange={(value) => {
                    setState(
                      update(state, {
                        selectProfile: { $set: value },
                      })
                    );
                  }}
                >
                  {profile.data.map((e) => (
                    <Option key={e.NOMBRE_PERFIL} value={e.ID_PERFIL_SUITE}>
                      {e.NOMBRE_PERFIL}
                    </Option>
                  ))}
                </Select>
              )}
            </div>
          </div>
        </div>
      </Card>
      <div className="buttonUserModal ">
        <div className="buttonUser">
          <Button onClick={onCancel} disabled={isLoading}>
            Cancelar
          </Button>
        </div>
        <div>
          <Button type="primary" onClick={submit} loading={isLoading} disabled={isLoading}>
            Guardar
          </Button>
        </div>
      </div>
    </Modal>
  );
};

EditModal.propTypes = {
  activeRecord: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  saveUsers: PropTypes.func.isRequired,
  projects: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};

export default connect(
  (store) => ({
    users: store.users,
  }),
  (dispatch) => ({
    saveUsers: bindActionCreators(saveUsersAction, dispatch),
  })
)(EditModal);
