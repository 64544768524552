import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { CheckTree } from 'rsuite';
import './index.scss';

const TreeFilter = ({
  buttonTitle,
  classNameToFirstButton,
  classNameToPrimaryButton,
  defaultExpandItemValues,
  defaultValue,
  onClick,
  onClose,
  onSave,
  show,
  title,
  tree,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(defaultValue);

  return (
    <div className="tree-filter">
      <Button
        className={`main-button ${classNameToPrimaryButton} ${classNameToFirstButton}`}
        onClick={() => onClick()}
        type="default"
      >
        {buttonTitle}
      </Button>

      {show && (
        <div className={`options-container `}>
          <div className="options-header">
            <div />
            <span>{title}</span>
            <i className="fa fa-times" onClick={() => onClose()} />
          </div>
          <CheckTree
            defaultExpandAll
            defaultValue={selectedOptions}
            cascade
            data={tree}
            onChange={(e) => setSelectedOptions(e)}
            defaultExpandItemValues={defaultExpandItemValues}
          />
          <div className="buttons">
            <Button type="default" onClick={() => onClose()}>
              Cancelar
            </Button>
            <Button
              onClick={() => onSave(selectedOptions)}
              type="primary"
              className={classNameToPrimaryButton}
            >
              Aplicar
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

TreeFilter.defaultProps = {
  classNameToFirstButton: '',
  classNameToPrimaryButton: '',
};

TreeFilter.propTypes = {
  buttonTitle: PropTypes.string.isRequired,
  classNameToFirstButton: PropTypes.string,
  classNameToPrimaryButton: PropTypes.string,
  defaultExpandItemValues: PropTypes.arrayOf(PropTypes.number).isRequired,
  defaultValue: PropTypes.arrayOf(PropTypes.number).isRequired,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  tree: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default TreeFilter;
