/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import update from 'immutability-helper';
import { validarRUT } from 'validar-rut';
import PropTypes from 'prop-types';
import { Form, Input, Button, Upload, Modal, Select, Radio, message, InputNumber } from 'antd';
import _ from 'lodash';
import { InfoCircleOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { saveCompany as saveCompanyAction, getCompanyLogo } from '../../../actions/companies';
import {
  commonPropTypes,
  commonSelectProps,
  getFormattedRut,
  normalizeString,
} from '../../../utils';

import FormBox from '../../../components/FormBox';

import './index.scss';

const { confirm } = Modal;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/png' || file.type === 'image/jpeg';
  if (!isJpgOrPng) {
    message.error('Puedes subir solo imagenes en formato PNG o JPG!');
  }
  const isLt1M = file.size / 1024 / 1024 < 1;

  if (!isLt1M) {
    message.error('El tamaño de la imagen debe ser menor a 1MB');
  }
  return isJpgOrPng && isLt1M;
}

const getAssociatedCompanies = (
  values,
  associatedCompanies,
  editData,
  deletedAssociatedCompanies
) => {
  if (!associatedCompanies.length) return [];
  const newAssociatedCompanies = [];
  associatedCompanies.forEach((key) => {
    newAssociatedCompanies.push({
      RUT_EMP_ASOCIADA: values[`RUT-${key}`],
      RAZON_SOCIAL_EMP_ASOCIADA: values[`RAZON_SOCIAL_EMP-${key}`],
      PORCENTAJE_PART: values[`PORCENTAJE_PART-${key}`],
      ID_EMP_ASOCIADA: `${key}`.includes('NEW-')
        ? ''
        : editData.EMPRESA_ASOCIADA.find((company) => company.ID_EMP_ASOCIADA === key)
            .ID_EMP_ASOCIADA,
      ID_EMP: `${key}`.includes('NEW-') ? '' : editData.ID_EMP,
    });
  });

  if (editData) {
    editData.EMPRESA_ASOCIADA.forEach((company) => {
      if (deletedAssociatedCompanies.indexOf(company.ID_EMP_ASOCIADA) !== -1) {
        newAssociatedCompanies.push({
          ...company,
          ID_EMP: '',
        });
      }
    });
  }

  return newAssociatedCompanies;
};
const CompanyForm = ({ saveCompany, onCancel, editData, communes, companies }) => {
  const [form] = Form.useForm();
  const [associatedCompanies, setAssociatedCompanies] = useState([]);
  const [deletedAssociatedCompanies, setDeletedAssociatedCompanies] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [associatedCompaniesCount, setAssociatedCompaniesCount] = useState(0);
  const [needsEditAssociatedCompanies, setNeedsEditAssociatedCompanies] = useState(false);
  const [isConsortium, setIfIsConsortium] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [imageUrl, setImageUrl] = useState(false);
  const [hasImageToEdit, setIfHasImageToEdit] = useState(false);
  const [imageHasChangedInEditionMode, setIfimageHasChangedInEditionMode] = useState(false);

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoadingImage(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoadingImage(false);
        setImageUrl(url);
        if (hasImageToEdit) {
          setIfimageHasChangedInEditionMode(true);
        }
      });
    }
  };

  useEffect(() => {
    setImageUrl(null);
    setLoadingImage(false);

    async function getLogo() {
      setLoadingImage(true);
      const result = await getCompanyLogo(editData.ID_EMP);
      setLoadingImage(false);

      if (result.status === 200 && result.data) {
        let extension = '';
        if (result.data[0] === '/') {
          extension = 'jpeg';
        }
        if (result.data[0] === 'i') {
          extension = 'png';
        }
        setImageUrl(`data:image/${extension};base64,${result.data}`);
        setIfHasImageToEdit(true);
      }
    }

    if (editData) {
      form.setFieldsValue(editData);
      const numberOfAssociatedCompanies = editData.EMPRESA_ASOCIADA.length;

      if (numberOfAssociatedCompanies > 0) {
        const result = editData.EMPRESA_ASOCIADA.map(({ ID_EMP_ASOCIADA }) => ID_EMP_ASOCIADA);
        setAssociatedCompanies(result);
        setIfIsConsortium(true);
        setNeedsEditAssociatedCompanies(true);
      } else {
        setAssociatedCompanies([]);
        setIfIsConsortium(false);
      }
      getLogo();
    } else {
      form.resetFields();
      setAssociatedCompanies([]);
      setIfIsConsortium(false);
    }
  }, [editData]);

  useEffect(() => {
    const associatedCompaniesData = {};
    if (needsEditAssociatedCompanies) {
      editData.EMPRESA_ASOCIADA.forEach((company) => {
        associatedCompaniesData[`RUT-${company.ID_EMP_ASOCIADA}`] = company.RUT_EMP_ASOCIADA;
        associatedCompaniesData[`PORCENTAJE_PART-${company.ID_EMP_ASOCIADA}`] =
          company.PORCENTAJE_PART;
        associatedCompaniesData[`RAZON_SOCIAL_EMP-${company.ID_EMP_ASOCIADA}`] =
          company.RAZON_SOCIAL_EMP_ASOCIADA;
      });

      form.setFieldsValue(associatedCompaniesData);
      setNeedsEditAssociatedCompanies(false);
    }
  }, [needsEditAssociatedCompanies]);

  const submit = (values) => {
    const {
      DIRECCION_EMP = '',
      GIRO_EMP = '',
      ID_COMUNA = '',
      NOMBRE_CLASIFICADOR_COSTO = '',
      NOMBRE_EMP,
      NOMBRE_TIPO_RECURSO = '',
      RAZON_SOCIAL_EMP,
      RUTMAS_EMP,
    } = values;
    const companyData = {
      ID_EMP: _.get(editData, 'ID_EMP', ''),
      NOMBRE_EMP,
      RAZON_SOCIAL_EMP,
      RUTMAS_EMP: RUTMAS_EMP.replaceAll('.', ''),
      GIRO_EMP,
      ID_COMUNA,
      NOMBRE_COMUNA:
        (communes.data.find((commune) => commune.ID_COMUNA === ID_COMUNA) || {}).NOMBRE_COMUNA ||
        '',
      DIRECCION_EMP,
      NOMBRE_CLASIFICADOR_COSTO,
      NOMBRE_TIPO_RECURSO,
      EMPRESA_ASOCIADA: isConsortium
        ? getAssociatedCompanies(values, associatedCompanies, editData, deletedAssociatedCompanies)
        : _.get(editData, 'EMPRESA_ASOCIADA', []).map((company) => ({ ...company, ID_EMP: '' })),
    };

    const associatedCompaniesTotalPercentage = companyData.EMPRESA_ASOCIADA.reduce(
      (acc, current) => acc + current.PORCENTAJE_PART,
      0
    );

    if (isConsortium && associatedCompaniesTotalPercentage !== 100) {
      message.error(
        'El total de porcentajes de participación de las empresas asociadas debe sumar 100'
      );
    } else {
      setIsSaving(true);

      const logo = imageUrl ? imageUrl.split(',')[1] : '';
      saveCompany(companyData, editData ? 'edit' : 'create', logo, imageHasChangedInEditionMode)
        .then(() => {
          onCancel();
          setIsSaving(false);
          form.resetFields();
          setAssociatedCompanies([]);
          setAssociatedCompaniesCount(0);
          setIfIsConsortium(false);
          setImageUrl(null);
          setLoadingImage(false);
        })
        .catch(() => {
          setIsSaving(false);
        });
    }
  };

  const addParameter = () => {
    setAssociatedCompanies(
      update(associatedCompanies, { $push: [`NEW-${associatedCompaniesCount}`] })
    );
    setAssociatedCompaniesCount(associatedCompaniesCount + 1);
  };

  const removeParameter = (key) => {
    const index = associatedCompanies.findIndex((e) => e === key);
    if (!`${key}`.includes('NEW-')) {
      setDeletedAssociatedCompanies(update(deletedAssociatedCompanies, { $push: [key] }));
    }

    setAssociatedCompanies(
      update(associatedCompanies, {
        $splice: [[index, 1]],
      })
    );
  };

  const uploadButton = (
    <div className="image-uploader">
      {loadingImage ? <LoadingOutlined /> : <PlusOutlined />}
      <div>{loadingImage ? 'Cargando Imagen' : 'Elegir Archivo'}</div>
    </div>
  );

  const showConfirm = (key) => {
    confirm({
      title: 'Esta a punto de eliminar la Empresa Asociada?',
      content: 'Confirma esta acción',
      onOk() {
        removeParameter(key);
      },
    });
  };

  const isWrongCompanyRut = (value) =>
    Boolean(
      companies.data.find(
        ({ RUTMAS_EMP, ID_EMP }) =>
          RUTMAS_EMP.replaceAll('-', '') === value.replaceAll('.', '').replaceAll('-', '') &&
          (!editData || ID_EMP !== editData.ID_EMP)
      )
    );

  const isWrongAssociatedCompanyRut = (value) =>
    Boolean(
      companies.data.find(
        ({ RUTMAS_EMP }) =>
          RUTMAS_EMP.replaceAll('-', '') === value.replaceAll('.', '').replaceAll('-', '')
      )
    );

  return (
    <div className="company-form">
      <Form
        initialValues={{
          requiredMark: 'optional',
        }}
        requiredMark="optional"
        scrollToFirstError
        form={form}
        layout="vertical"
        onFinish={submit}
      >
        <FormBox title="Datos Empresa">
          <Form.Item
            label="RUT"
            name="RUTMAS_EMP"
            rules={[
              {
                required: true,
                message: 'Por favor ingrese un RUT valido',
              },
              {
                validator: async (obj, value) => {
                  if (value && !validarRUT(value)) {
                    return Promise.reject(new Error('Por favor ingrese un RUT valido'));
                  }

                  if (value && isWrongCompanyRut(value)) {
                    return Promise.reject(new Error('Ya existe una empresa con este RUT'));
                  }

                  if (
                    associatedCompanies.some(
                      (e) =>
                        getFormattedRut(form.getFieldValue(`RUT-${e}`)) === getFormattedRut(value)
                    )
                  ) {
                    return Promise.reject(new Error('Ya se esta usando este RUT'));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              placeholder="30.686.957-4"
              disabled={Boolean(editData)}
              onBlur={() => {
                const rut = form.getFieldValue('RUTMAS_EMP');
                if (rut && validarRUT(rut) && !isWrongCompanyRut(rut)) {
                  form.setFieldsValue({ RUTMAS_EMP: getFormattedRut(rut) });
                }
              }}
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Por favor ingrese un Nombre',
              },
              {
                validator: async (obj, value) => {
                  if (
                    companies.data.find(
                      ({ NOMBRE_EMP, ID_EMP }) =>
                        normalizeString(NOMBRE_EMP) === normalizeString(value) &&
                        (!editData || ID_EMP !== editData.ID_EMP)
                    )
                  ) {
                    return Promise.reject(new Error('Ya existe una empresa con este Nombre'));
                  }
                  return Promise.resolve();
                },
              },
            ]}
            name="NOMBRE_EMP"
            label="Nombre de Fantasía"
            required
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="RAZON_SOCIAL_EMP"
            rules={[
              {
                required: true,
                message: 'Por favor ingrese una Razón Social',
              },
            ]}
            required
            label="Razón Social"
          >
            <Input />
          </Form.Item>
          <Form.Item name="GIRO_EMP" required label="Giro">
            <Input />
          </Form.Item>
          <Form.Item name="DIRECCION_EMP" required label="Dirección">
            <Input />
          </Form.Item>
          <Form.Item name="ID_COMUNA" required label="Comuna">
            <Select {...commonSelectProps}>
              {communes.data.map(({ ID_COMUNA, NOMBRE_COMUNA }) => (
                <Select.Option value={ID_COMUNA}>{NOMBRE_COMUNA}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <div className="consortium-toggle">
            <label>Corresponde a Consorcio</label>
            <Radio.Group onChange={(e) => setIfIsConsortium(e.target.value)} value={isConsortium}>
              <Radio value>Sí</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
            {isConsortium && associatedCompanies && (
              <div className="consortium-add">
                <button type="button" className="new-button" onClick={addParameter}>
                  Nueva Empresa <i className="fa fa-plus-circle" />
                </button>
                {associatedCompanies.length > 0 && (
                  <div className="custom-field gray">
                    <div>Rut</div>
                    <div>Razón Social</div>
                    <div>% de Participación</div>
                  </div>
                )}
                {associatedCompanies.map((key) => (
                  <div key={key} className="custom-field">
                    <div>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: 'Por favor ingrese un RUT valido',
                          },
                          {
                            validator: async (obj, value) => {
                              if (value && !validarRUT(value)) {
                                return Promise.reject(new Error('Por favor ingrese un RUT valido'));
                              }
                              if (
                                (value && value === form.getFieldValue('RUTMAS_EMP')) ||
                                associatedCompanies.some(
                                  (e) =>
                                    e !== key &&
                                    getFormattedRut(form.getFieldValue(`RUT-${e}`)) ===
                                      getFormattedRut(value)
                                )
                              ) {
                                return Promise.reject(new Error('Ya se esta usando este RUT'));
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                        name={`RUT-${key}`}
                        label=""
                        required
                      >
                        <Input
                          onBlur={() => {
                            const rut = form.getFieldValue(`RUT-${key}`);
                            if (rut && validarRUT(rut) && !isWrongAssociatedCompanyRut(rut)) {
                              form.setFieldsValue({ [`RUT-${key}`]: getFormattedRut(rut) });
                            }
                          }}
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: 'Por favor ingrese una razón social',
                          },
                        ]}
                        name={`RAZON_SOCIAL_EMP-${key}`}
                        label=""
                        required
                      >
                        <Input />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: 'Por favor ingrese un porcentaje de participación',
                          },
                        ]}
                        name={`PORCENTAJE_PART-${key}`}
                        label=""
                        required
                      >
                        <InputNumber min={1} max={100} />
                      </Form.Item>
                    </div>
                    <i
                      role="button"
                      tabIndex={-1}
                      onKeyDown={null}
                      className="fa fa-trash"
                      onClick={() => showConfirm(key)}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </FormBox>
        <FormBox title="Parámetros Adicionales">
          <Form.Item
            label="Nombre Clasificador de Costo"
            required
            name="NOMBRE_CLASIFICADOR_COSTO"
            tooltip={{
              title:
                'Es el que realiza el registro de costos de acuerdo a su tipo o función dentro de la empresa',
              icon: <InfoCircleOutlined />,
            }}
          >
            <Input />
          </Form.Item>
          {/*  <Form.Item
            required
            name="NOMBRE_TIPO_RECURSO"
            label="Nombre Clasificador Tipo de Recurso"
            tooltip={{
              title:
                'Nombre del tipo de recurso que se requiere para que una empresa pueda lograr sus objetivos',
              icon: <InfoCircleOutlined />,
            }}
          >
            <Input />
          </Form.Item> */}
          <Form.Item label="Logo (PNG/JPG)" className="upload-form-item">
            <Upload
              name="avatar"
              className={`avatar-uploader ${loadingImage ? 'loading' : ''}`}
              showUploadList={false}
              action="https://jsonplaceholder.typicode.com/posts"
              beforeUpload={beforeUpload}
              onChange={handleChange}
            >
              <div className="upload-container">
                {loadingImage || !imageUrl ? (
                  uploadButton
                ) : (
                  <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
                )}
              </div>
            </Upload>
            {imageUrl && !loadingImage && (
              <i
                className="fa fa-trash"
                onClick={(e) => {
                  setImageUrl(null);
                  if (hasImageToEdit && !imageHasChangedInEditionMode) {
                    setIfimageHasChangedInEditionMode(true);
                  }
                  e.stopPropagation();
                }}
              />
            )}
          </Form.Item>
        </FormBox>
        <div className="buttons-wrapper">
          <Form.Item>
            <Button
              disabled={isSaving}
              onClick={() => {
                if (editData) {
                  form.setFieldsValue(editData);
                } else {
                  form.resetFields();
                  setLoadingImage(false);
                  setAssociatedCompanies([]);
                  setIfIsConsortium(false);
                  setAssociatedCompaniesCount(0);
                  setImageUrl(null);
                }
                onCancel();
              }}
            >
              Salir
            </Button>
          </Form.Item>

          <Form.Item>
            <Button disabled={isSaving} loading={isSaving} type="primary" htmlType="submit">
              Guardar
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

CompanyForm.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  saveCompany: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  editData: PropTypes.object,
  communes: commonPropTypes.isRequired,
  companies: commonPropTypes.isRequired,
};

CompanyForm.defaultProps = {
  onCancel: () => null,
  editData: null,
};
export default connect(
  (store) => ({
    communes: store.communes,
    companies: store.companies,
  }),
  (dispatch) => ({
    saveCompany: bindActionCreators(saveCompanyAction, dispatch),
  })
)(CompanyForm);
