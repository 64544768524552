import genericReducer from '../../utils';

export const CONTRACT_CURRENCY_FETCHED = 'CONTRACT_CURRENCY_FETCHED';
export const GET_CONTRACT_CURRENCY = 'GET_CONTRACT_CURRENCY';
export const GET_CONTRACT_CURRENCY_ERROR = 'GET_CONTRACT_CURRENCY_ERROR';

const contractCurrency = (contractCurrencyInfo, action) =>
  genericReducer(contractCurrencyInfo, action, 'CONTRACT_CURRENCY', 'contractCurrency');

export default contractCurrency;
