import React, { useState, useEffect } from 'react';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import { Modal, Button, Input, Form, Select } from 'antd';

import { commonPropTypes } from '../../../../utils';

import '../index.scss';
import './index.scss';

const NewResource = ({ units, inputsTypesData, createResource }) => {
  const [state, setState] = useState({
    show: false,
    saving: false,
  });
  const { data: unitsData, isFetching: isFetchingUnits } = units;

  const [form] = Form.useForm();

  const { show, saving } = state;

  const submit = (values) => {
    setState(update(state, { saving: { $set: true } }));

    const changeState = () =>
      setState(update(state, { saving: { $set: false }, show: { $set: false } }));
    createResource({
      ...values,
      PRECIO_ITEM: Number(values.PRECIO_ITEM),
      ID_ITEM: 0,
      FACTOR_MOD: 1,
      CANTIDAD_MOD: 0,
      ID_NAT: 4,
    })
      .then(() => {
        changeState();
      })
      .catch(() => {
        changeState();
      });
  };

  useEffect(() => {
    if (!show) {
      form.resetFields();
    }
  }, [show]);

  return (
    <div className="add-resource">
      <Button
        onClick={() =>
          setState(
            update(state, {
              show: { $set: true },
            })
          )
        }
        type="primary"
        className="new-item-button"
      >
        <i className="fa fa-plus" /> &nbsp;Crear Recurso
      </Button>
      <Modal
        closable={false}
        wrapClassName="item-modal"
        visible={show}
        width={900}
        onCancel={() => {
          setState(
            update(state, {
              show: { $set: false },
            })
          );
        }}
        footer={null}
        okText="Guardar"
      >
        <div className="modal-container">
          <div className="modal-header">
            <div />
            <span>Selección de Recurso - Nuevo</span>
            <i
              className="fa fa-times"
              onClick={() => {
                setState(
                  update(state, {
                    show: { $set: false },
                  })
                );
              }}
            />
          </div>
          <div className="form-body">
            <Form form={form} layout="vertical" onFinish={submit}>
              <Form.Item
                label="Nombre Recurso"
                name="NOMBRE_ITEM"
                required
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese un nombre de recurso',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Código Recurso"
                name="CODIGO_ITEM"
                required
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese un codigo de item',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Unidad"
                name="ID_UNI"
                required
                rules={[
                  {
                    required: true,
                    message: 'Por favor seleccione una unidad',
                  },
                ]}
              >
                <Select
                  loading={isFetchingUnits}
                  disabled={isFetchingUnits}
                  optionFilterProp="children"
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {(unitsData || []).map(({ NOMABR_UNI, ID_UNI, NOMBRE_UNI }) => (
                    <Select.Option value={ID_UNI} key={ID_UNI}>
                      {`${NOMBRE_UNI} (${NOMABR_UNI})`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Precio Unitario"
                name="PRECIO_ITEM"
                required
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese el Precio Unitario',
                  },
                ]}
              >
                <Input type="number" min={1} />
              </Form.Item>
              <Form.Item
                label="Naturaleza"
                name="ID_TIN"
                required
                rules={[
                  {
                    required: true,
                    message: 'Por favor seleccione una Naturaleza para el recurso',
                  },
                ]}
              >
                <Select
                  loading={!inputsTypesData}
                  disabled={!inputsTypesData}
                  optionFilterProp="children"
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {(inputsTypesData || []).map(({ ID_TIN, NOMBRE_TIN, NOMABR_TIN }) => (
                    <Select.Option value={ID_TIN} key={ID_TIN}>
                      {`${NOMBRE_TIN} (${NOMABR_TIN})`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <div className="modal-footer">
                <Button
                  onClick={() =>
                    setState(
                      update(state, {
                        show: { $set: false },
                      })
                    )
                  }
                >
                  Cancelar
                </Button>
                <Button type="primary" htmlType="submit" disabled={saving} loading={saving}>
                  Agregar
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

NewResource.propTypes = {
  units: commonPropTypes.isRequired,
  inputsTypesData: PropTypes.array.isRequired,
  createResource: PropTypes.func.isRequired,
};

export default NewResource;
