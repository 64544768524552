import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ListFilter from '../../../components/ListFilter';
import TreeFilter from '../../../components/TreeFilter';
import SwitchFilter from '../../../components/SwitchFilter';
import {
  getChaptersTree,
  getEnclosureTree,
  getSelectedChapters,
  getSelectedEnClosure,
} from '../utils';
import './index.scss';

const Header = ({
  chapters,
  chaptersToShow,
  enclosureTree,
  enclosureTypes,
  enclosureToShow,
  items,
  loading,
  optionsToMoreFilters,
  setChaptersToShow,
  setEnclosureToShow,
  setEnclosureTypesToShow,
  setItemsToShow,
  setSelectedFilters,
}) => {
  const [active, setActive] = useState(null);

  const tree = getEnclosureTree(enclosureTree);
  const chaptersTree = getChaptersTree(chapters);

  return (
    <div className="paymentStatusHeader">
      <div className="filters">
        <div className="marginRight">
          <TreeFilter
            buttonTitle="Capítulo"
            defaultExpandItemValues={[chaptersTree[0].ID_INS_EP]}
            defaultValue={chaptersToShow}
            onClick={() => setActive('chapters')}
            onClose={() => setActive(null)}
            show={active === 'chapters'}
            title="Filtro Capítulo"
            tree={chaptersTree}
            onSave={(selectedOptions) => {
              setActive(null);
              setChaptersToShow(getSelectedChapters(chapters, selectedOptions));
            }}
            classNameToPrimaryButton={`orangeButton ${loading ? 'disabledButton' : ''}`}
          />
        </div>
        <div className="marginRight">
          <ListFilter
            allowSelectAll
            title="Filtro Partidas"
            subtitle="Lista de Partidas"
            buttonTitle="Partida"
            elementKey="ID_INS_EP"
            elementTitleKey="NOMBRE_INS_EP"
            onSave={(selectedOptions) => {
              setActive(null);
              setItemsToShow(selectedOptions);
            }}
            options={items}
            onClick={() => setActive('item')}
            onClose={() => setActive(null)}
            show={active === 'item'}
            classNameToPrimaryButton={`orangeButton ${loading ? 'disabledButton' : ''}`}
          />
        </div>
        <div className="marginRight">
          <ListFilter
            allowSelectAll
            title="Filtro Tipos de Recinto"
            subtitle="Lista de Tipos de Recinto"
            buttonTitle="Tipos de Recinto"
            elementKey="ID_TUC"
            elementTitleKey="NOMBRE_TUC"
            onSave={(selectedOptions) => {
              setActive(null);
              setEnclosureTypesToShow(selectedOptions);
            }}
            options={enclosureTypes}
            onClick={() => setActive('enclosureTypes')}
            onClose={() => setActive(null)}
            show={active === 'enclosureTypes'}
            classNameToPrimaryButton={`orangeButton ${loading ? 'disabledButton' : ''}`}
          />
        </div>
        <div className="marginRight">
          <TreeFilter
            buttonTitle="Recinto"
            defaultExpandItemValues={[tree[0].ID_UCO]}
            defaultValue={enclosureToShow}
            onClick={() => setActive('enclosure')}
            onClose={() => setActive(null)}
            show={active === 'enclosure'}
            title="Filtro Recinto"
            tree={tree}
            onSave={(selectedOptions) => {
              setActive(null);
              setEnclosureToShow(getSelectedEnClosure(enclosureTree, selectedOptions));
            }}
            classNameToPrimaryButton={`orangeButton ${loading ? 'disabledButton' : ''}`}
          />
        </div>
        <div>
          <SwitchFilter
            buttonTitle="Más Filtros"
            classNameToPrimaryButton={`orangeButton ${loading ? 'disabledButton' : ''}`}
            onClick={() => setActive('moreFilters')}
            onClose={() => setActive(null)}
            show={active === 'moreFilters'}
            title="Más Filtros"
            onSave={(selectedOptions) => {
              setActive(null);
              setSelectedFilters(selectedOptions);
            }}
            options={optionsToMoreFilters}
          />
        </div>
      </div>
      <div className="buttons" />
    </div>
  );
};

Header.propTypes = {
  chapters: PropTypes.arrayOf(PropTypes.object).isRequired,
  chaptersToShow: PropTypes.arrayOf(PropTypes.number).isRequired,
  enclosureTree: PropTypes.arrayOf(PropTypes.object).isRequired,
  enclosureToShow: PropTypes.arrayOf(PropTypes.number).isRequired,
  enclosureTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  optionsToMoreFilters: PropTypes.arrayOf(PropTypes.object).isRequired,
  setChaptersToShow: PropTypes.func.isRequired,
  setEnclosureToShow: PropTypes.func.isRequired,
  setEnclosureTypesToShow: PropTypes.func.isRequired,
  setItemsToShow: PropTypes.func.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
};

export default Header;
