/* eslint-disable react/prop-types */
import React from 'react';
import { Tooltip } from 'antd';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { getFormattedNumber } from '../../utils';
import EditableCell from './EditableCell';

const getClassName = (element) => {
  let newClassName = '';
  if (element.ID_NAT === 1) {
    newClassName = 'cap-level';
  }

  if (element.NUM_REST > 0) {
    newClassName = 'negative';
  }

  return newClassName;
};

const TdWrapper = ({ className, children, record, onClick, ...rest }) => {
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <td
      style={{ cursor: 'pointer' }}
      onClick={onClick}
      className={`${className} ${getClassName(record)}`}
      {...rest}
    >
      {children}
    </td>
  );
};
const ConnectedTdWrapper = connect(
  (store) => ({
    advanceIncomeEdition: store.advanceIncomeEdition,
  }),
  () => ({})
)(({ className, children, record, onClick, advanceIncomeEdition, ...rest }) => {
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <td
      style={{ cursor: 'pointer' }}
      onClick={onClick}
      className={`${className} ${getClassName(record)}`}
      {...rest}
    >
      {children(advanceIncomeEdition)}
    </td>
  );
});

const getCellClassName = (record, activeRow) => {
  let newClassName = '';
  if (get(activeRow, 'ID_INS') === record.ID_INS) {
    newClassName = 'selected ';
  }

  if (record.ID_NAT !== 1) {
    newClassName += ' cursor-pointer';
  }

  return newClassName;
};

export const columnsLeft = ({ setActiveRow, activeRow }) => {
  return [
    {
      title: ' Itemizado de Avance',
      children: [
        {
          field: 'CODIGO_INS',
          title: 'Código',
          width: 80,
          cell: ({ dataItem }) => {
            return (
              <TdWrapper
                onClick={() => dataItem.ID_NAT !== 1 && setActiveRow(dataItem)}
                record={dataItem}
                className={`text-align-right k-border-right ${getCellClassName(
                  dataItem,
                  activeRow
                )}`}
              >
                {dataItem.CODIGO_INS}
              </TdWrapper>
            );
          },
        },
        {
          field: 'NOMBRE_INS',
          expandable: true,
          title: 'Actividad',
          width: 280,
          cell: (props) => {
            const { dataItem, expanded, onExpandChange, level, className } = props;

            return (
              <TdWrapper
                record={dataItem}
                onClick={() => dataItem.ID_NAT !== 1 && setActiveRow(dataItem)}
                {...props}
                className={`${className} k-border-right text-align-center ${getCellClassName(
                  dataItem,
                  activeRow
                )}`}
              >
                <div
                  role="button"
                  tabIndex={-1}
                  onClick={(e) => onExpandChange(e, dataItem, level)}
                  className={`d-flex expandable-cell padding-${level.length}`}
                >
                  {dataItem.children && (
                    <div>{expanded ? <CaretUpOutlined /> : <CaretDownOutlined />}</div>
                  )}

                  <Tooltip
                    placement="topLeft"
                    destroyTooltipOnHide={{ keepParent: false }}
                    title={dataItem.NOMBRE_INS}
                  >
                    <div className="name-ins text-align-left"> {dataItem.NOMBRE_INS}</div>
                  </Tooltip>
                </div>
              </TdWrapper>
            );
          },
        },
        {
          field: 'UM',
          title: 'UM',
          width: 90,
          cell: ({ dataItem }) => {
            return (
              <TdWrapper
                onClick={() => dataItem.ID_NAT !== 1 && setActiveRow(dataItem)}
                record={dataItem}
                className={`text-align-center k-border-right ${getCellClassName(
                  dataItem,
                  activeRow
                )}`}
              >
                {dataItem.NOMABR_UNI}
              </TdWrapper>
            );
          },
        },
        {
          field: 'Q_PPTO',
          title: 'Q. Ptto',
          width: 90,
          cell: ({ dataItem }) => {
            return (
              <TdWrapper
                onClick={() => dataItem.ID_NAT !== 1 && setActiveRow(dataItem)}
                record={dataItem}
                className={`text-align-center k-border-right ${getCellClassName(
                  dataItem,
                  activeRow
                )}`}
              >
                {dataItem.ID_NAT === 3 ? (
                  <span title={getFormattedNumber(dataItem.Q_PPTO)}>
                    {getFormattedNumber(dataItem.Q_PPTO, 1)}
                  </span>
                ) : null}
              </TdWrapper>
            );
          },
        },
        {
          field: 'PRC_DAV',
          title: '% Avance',
          width: 90,
          cell: ({ dataItem }) => {
            return (
              <TdWrapper
                onClick={() => dataItem.ID_NAT !== 1 && setActiveRow(dataItem)}
                record={dataItem}
                className={`text-align-center k-border-right ${getCellClassName(
                  dataItem,
                  activeRow
                )}`}
              >
                {dataItem.ID_NAT === 3 ? (
                  <span title={`${getFormattedNumber(dataItem.PRC_DAV)}%`}>
                    {`${getFormattedNumber(dataItem.PRC_DAV, 1)}%`}
                  </span>
                ) : null}
              </TdWrapper>
            );
          },
        },
      ],
    },
  ];
};

export const columnsRight = ({
  editCell,
  canEdit,
  activeViewOptions,
  editLeftTable,
  activeRow,
}) => {
  return [
    {
      field: 'NOMBRE_UCO',
      expandable: true,
      title: 'Recinto de Control',
      locked: true,
      width: 200,
      cell: (props) => {
        const { dataItem, expanded, onExpandChange, level, className } = props;

        return (
          <TdWrapper
            record={dataItem}
            onClick={() => dataItem.ID_NAT !== 1}
            {...props}
            className={`${className} k-border-right text-align-center`}
          >
            <div
              role="button"
              tabIndex={-1}
              onClick={(e) => onExpandChange(e, dataItem, level)}
              className={`d-flex expandable-cell padding-${level.length}`}
            >
              {dataItem.children && (
                <div>{expanded ? <CaretUpOutlined /> : <CaretDownOutlined />}</div>
              )}

              <Tooltip
                placement="topLeft"
                destroyTooltipOnHide={{ keepParent: false }}
                title={dataItem.NOMBRE_UCO}
              >
                <div className="name-ins text-align-left"> {dataItem.NOMBRE_UCO}</div>
              </Tooltip>
            </div>
          </TdWrapper>
        );
      },
    },
    ...(activeViewOptions.option0
      ? [
          {
            title: 'Avance por %',
            children: [
              {
                field: 'PRC_AVANCE',
                title: '% Acumulado',
                width: 140,
                cell: ({ dataItem }) => {
                  return (
                    <EditableCell
                      focusNextInputAfterEdit={false}
                      keepEditable
                      maxButtonValue={100}
                      rowData={dataItem}
                      dataIndex="PRC_AVANCE"
                      editable={canEdit}
                      activeRow={activeRow}
                      decimalsIndex={1}
                      editLeftTable={editLeftTable}
                      additionalInputFields={() => ({
                        type: 'number',
                        min: 0,
                        max: 100,
                      })}
                      unit="%"
                      idKey="ID_PAR_UCO"
                      includeRecordFields={[]}
                      isUnitPrefix={false}
                      customClass={(record) =>
                        `text-align-center k-border-right withButton ${getClassName(record)}`
                      }
                      canShow={(record) => record.ID_PAR_UCO !== 0}
                      conditionToEditable={(record) => record.ID_PAR_UCO !== 0}
                      handleSave={editCell}
                      objectModifier={(obj, record, value) => {
                        obj.CANTIDAD_AVANCE = (value * record.FACTOR_PAR_UCO) / 100;
                        if (value > 100) {
                          obj.PRC_AVANCE = 100;
                          obj.CANTIDAD_AVANCE = record.FACTOR_PAR_UCO;
                        }
                        return obj;
                      }}
                      type="number"
                      className={(record) => `${getClassName(record)} ${canEdit && 'editable'}`}
                    />
                  );
                },
              },
              {
                field: 'NOMBRE_UCO',
                expandable: true,
                title: '% Anterior',
                width: 90,
                cell: (props) => {
                  const { dataItem, className } = props;

                  return (
                    <TdWrapper
                      record={dataItem}
                      onClick={() => dataItem.ID_NAT !== 1}
                      {...props}
                      className={`${className} k-border-right text-align-center`}
                    >
                      {dataItem.ID_PAR_UCO !== 0 ? (
                        <span title={`${getFormattedNumber(dataItem.PRC_AVANCE_ANT)}%`}>
                          {`${getFormattedNumber(dataItem.PRC_AVANCE_ANT, 1)}%`}
                        </span>
                      ) : null}
                    </TdWrapper>
                  );
                },
              },
              {
                field: 'NOMBRE_UCO',
                expandable: true,
                title: '% Periodo',
                width: 90,
                cell: (props) => {
                  const { dataItem, className } = props;
                  return (
                    <ConnectedTdWrapper
                      record={dataItem}
                      onClick={() => dataItem.ID_NAT !== 1}
                      {...props}
                      className={`${className} k-border-right text-align-center`}
                    >
                      {(advanceIncomeEdition) => {
                        const qty = advanceIncomeEdition.find(
                          (e) => e.ID_PAR_UCO === dataItem.ID_PAR_UCO
                        );
                        const total =
                          (qty ? qty.PRC_AVANCE : dataItem.PRC_AVANCE) - dataItem.PRC_AVANCE_ANT;

                        return dataItem.ID_PAR_UCO !== 0 ? (
                          <span title={`${getFormattedNumber(total)}%`}>
                            {`${getFormattedNumber(total, 1)}%`}
                          </span>
                        ) : null;
                      }}
                    </ConnectedTdWrapper>
                  );
                },
              },
            ],
          },
        ]
      : []),
    ...(activeViewOptions.option1
      ? [
          {
            title: 'Avance por Cantidad',
            children: [
              {
                field: 'CANTIDAD_AVANCE',
                title: 'Acumulado',
                width: 90,
                cell: ({ dataItem }) => {
                  return (
                    <EditableCell
                      focusNextInputAfterEdit={false}
                      keepEditable
                      rowData={dataItem}
                      dataIndex="CANTIDAD_AVANCE"
                      editable={canEdit}
                      activeRow={activeRow}
                      editLeftTable={editLeftTable}
                      decimalsIndex={3}
                      idKey="ID_PAR_UCO"
                      additionalInputFields={(record) => ({
                        type: 'number',
                        min: 0,
                        max: record.PRC_AVANCE * record.FACTOR_PAR_UCO,
                      })}
                      objectModifier={(obj, record, value) => {
                        obj.PRC_AVANCE = (value * 100) / record.FACTOR_PAR_UCO;
                        const maxValue = (record.PRC_AVANCE * record.FACTOR_PAR_UCO) / 100;
                        if (value > maxValue) {
                          obj.CANTIDAD_AVANCE = record.FACTOR_PAR_UCO;
                          obj.PRC_AVANCE = 100;
                        }
                        return obj;
                      }}
                      isUnitPrefix={false}
                      canShow={(record) => record.ID_PAR_UCO !== 0}
                      conditionToEditable={(record) => record.ID_PAR_UCO !== 0}
                      customClass={(record) =>
                        `text-align-center k-border-right  ${getClassName(record)}`
                      }
                      handleSave={editCell}
                      type="number"
                      className={(record) => `${getClassName(record)} ${canEdit && 'editable'}`}
                    />
                  );
                },
              },
              {
                field: 'CANTIDAD_AVANCE_ANT',
                expandable: true,
                title: 'Anterior',
                width: 90,
                cell: (props) => {
                  const { dataItem, className } = props;

                  return (
                    <TdWrapper
                      record={dataItem}
                      onClick={() => dataItem.ID_NAT !== 1}
                      {...props}
                      className={`${className} k-border-right text-align-center`}
                    >
                      {dataItem.ID_PAR_UCO !== 0
                        ? getFormattedNumber(dataItem.CANTIDAD_AVANCE_ANT, 3)
                        : null}
                    </TdWrapper>
                  );
                },
              },
              {
                field: 'NOMBRE_UCO',
                expandable: true,
                title: 'Periodo',
                width: 90,
                cell: (props) => {
                  const { dataItem, className } = props;
                  return (
                    <ConnectedTdWrapper
                      record={dataItem}
                      onClick={() => dataItem.ID_NAT !== 1}
                      {...props}
                      className={`${className} k-border-right text-align-center`}
                    >
                      {(advanceIncomeEdition) => {
                        const qty = advanceIncomeEdition.find(
                          (e) => e.ID_PAR_UCO === dataItem.ID_PAR_UCO
                        );
                        const total =
                          (qty ? qty.CANTIDAD_AVANCE : dataItem.CANTIDAD_AVANCE) -
                          dataItem.CANTIDAD_AVANCE_ANT;

                        return dataItem.ID_PAR_UCO !== 0 ? getFormattedNumber(total, 3) : null;
                      }}
                    </ConnectedTdWrapper>
                  );
                },
              },
            ],
          },
        ]
      : []),
  ];
};
