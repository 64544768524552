import React from 'react';
import img1 from '../../images/home/conpax.jpeg';

const Welcome = () => {
  return (
    <div className="container-welcome">
      <di>
        <h1>Bienvenidos a la plataforma online</h1>
        <img src={img1} alt="" />
      </di>
    </div>
  );
};

export default Welcome;
