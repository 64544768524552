import React, { useState, useEffect } from 'react';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import { Modal, Button, Input, Form } from 'antd';
import { editChapter } from '../../../actions/budget';

import './index.scss';

const EditChapter = ({ chapter, onClose, onSuccess, chapters }) => {
  const [state, setState] = useState({
    saving: false,
    currentChapterId: null,
  });

  const [form] = Form.useForm();
  useEffect(() => {
    if (chapter && state.currentChapterId !== chapter?.ID_ITEM) {
      form.setFieldsValue({ CODIGO_ITEM: chapter.CODIGO_ITEM, NOMBRE_ITEM: chapter.NOMBRE_ITEM });
      setState((prev) => update(prev, { currentChapterId: { $set: chapter.ID_ITEM } }));
    }
  }, [chapter?.ID_ITEM]);

  const { saving } = state;

  const submit = (values) => {
    setState(update(state, { saving: { $set: true } }));
    const requestBody = {
      ID_ITEM: chapter.ID_ITEM,
      ...values,
    };

    const stateUpdates = { saving: { $set: false } };
    editChapter(requestBody)
      .then(() => {
        setState(update(state, stateUpdates));
        form.resetFields();
        onSuccess({ ...requestBody, PATH: chapter.PATH });
      })
      .catch(() => {
        setState(update(state, stateUpdates));
      });
  };

  if (!chapter) return null;

  return (
    <Modal
      closable={false}
      wrapClassName="common-modal add-pnf-modal"
      visible={!!chapter}
      width={700}
      onCancel={onClose}
      footer={null}
      okText="Guardar"
    >
      <div className="modal-container">
        <div className="modal-header">
          <div />
          <span>Editar Capítulo</span>
          <i className="fa fa-times" onClick={onClose} />
        </div>
        <div className="form-body">
          <Form form={form} layout="vertical" onFinish={submit}>
            <Form.Item
              label="Código Item"
              name="CODIGO_ITEM"
              required
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un código',
                },
                {
                  validator: async (_obj, value) => {
                    if (value) {
                      const isRepeatedDate = chapters.some(
                        (e) => e.ID_ITEM !== chapter.ID_ITEM && e.CODIGO_ITEM === value
                      );
                      if (isRepeatedDate) {
                        return Promise.reject(new Error('Ya se esta usando este código'));
                      }
                    }

                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Nombre Item"
              name="NOMBRE_ITEM"
              required
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un nombre',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <div className="modal-footer">
              <Button
                disabled={saving}
                onClick={() => {
                  onClose();
                  form.resetFields();
                }}
              >
                Cancelar
              </Button>
              <Button loading={saving} disabled={saving} type="primary" htmlType="submit">
                Guardar
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

EditChapter.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  chapter: PropTypes.bool.isRequired,
  onClose: PropTypes.bool.isRequired,
  chapters: PropTypes.array.isRequired,
};

export default EditChapter;
