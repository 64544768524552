import update from 'immutability-helper';

export const SET_ADVANCE_INCOME_FIELDS = 'SET_ADVANCE_INCOME_FIELDS';
export const RESET_ADVANCE_INCOME_FIELDS = 'RESET_ADVANCE_INCOME_FIELDS';

const defaultState = [];

const advanceIncomeEdition = (state = defaultState, action) => {
  switch (action.type) {
    case SET_ADVANCE_INCOME_FIELDS: {
      const { fieldsToUpdate } = action.payload;
      return update(state, {
        $apply: (fields) => {
          let newFields = [...fields];

          fieldsToUpdate.forEach((current) => {
            const currentIndex = newFields.findIndex((e) => e.ID_PAR_UCO === current.ID_PAR_UCO);

            if (currentIndex !== -1) {
              newFields = update(newFields, {
                [currentIndex]: {
                  $set: {
                    ...newFields[currentIndex],
                    ...current,
                  },
                },
              });
            } else {
              newFields = update(newFields, {
                $push: [current],
              });
            }
          });

          return newFields;
        },
      });
    }

    case RESET_ADVANCE_INCOME_FIELDS: {
      return [];
    }

    default:
      return state;
  }
};

export default advanceIncomeEdition;
