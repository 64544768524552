import _ from 'lodash';
import { message } from 'antd';
import api, { getHeaders } from '../utils/api';
import {
  DELETE_CALENDAR,
  GET_CALENDARS,
  SET_CALENDAR,
  SET_ERROR_CALENDARS,
  SET_INITIAL_VALUES_IN_CALENDAR,
  UPDATE_CALENDAR,
} from '../reducers/calendars';
import store from '../store';

export const setError = (error = '') => ({
  type: SET_ERROR_CALENDARS,
  payload: { error },
});

export const setInitialValuesInCalendar = () => ({
  type: SET_INITIAL_VALUES_IN_CALENDAR,
  payload: {},
});

export const getCalendars = (selectedWork) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(setInitialValuesInCalendar());

    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR: selectedWork,
    };
    const errorMessage = 'No se cargaron éxitosamente los calendarios.';
    api
      .post(`/api/Productividad/GetCalendarios`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: GET_CALENDARS, payload: { calendars: res.data } });
          resolve(res.data);
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });

export const setCalendar = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ...data,
    };
    const errorMessage = 'No se pudo guardar el nuevo calendario';
    api
      .post(`/api/Productividad/SetCalendario`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          const { ID_CALENDARIO } = res.data;
          if (ID_CALENDARIO === 0) {
            dispatch(setError(errorMessage));
            message.error(errorMessage, 4);
            reject(res.data);
          } else {
            const newCalendar = { ...data, EMPRESA: null, ID_CALENDARIO, USUARIO: null };

            dispatch({ type: SET_CALENDAR, payload: { newCalendar } });
            message.success('Calendario agregado correctamente', 4);
            resolve(res.data);
          }
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });

export const updateCalendar = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ...data,
    };
    const errorMessage = 'No se pudo actualizar el calendario.';
    api
      .post(`/api/Productividad/UpdCalendario`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          const { ID_CALENDARIO } = res.data;
          if (ID_CALENDARIO === 0) {
            dispatch(setError(errorMessage));
            message.error(errorMessage, 4);
            reject(res.data);
          } else {
            dispatch({ type: UPDATE_CALENDAR, payload: { data } });
            message.success('Calendario actualizado correctamente', 4);
            resolve(res.data);
          }
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });

export const deleteCalendar = (calendarId) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_CALENDARIO: calendarId,
      USUARIO: localStorage.getItem('foco_username'),
    };
    const errorMessage = 'No se pudo eliminar el calendario.';
    api
      .post(`/api/Productividad/DelCalendario`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          const { ID_CALENDARIO } = res.data;
          if (ID_CALENDARIO === 0) {
            dispatch(setError(errorMessage));
            message.error(errorMessage, 4);
            reject(res.data);
          } else {
            dispatch({ type: DELETE_CALENDAR, payload: { calendarId } });
            message.success('Calendario eliminado correctamente', 4);
            resolve(res.data);
          }
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });
