/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Cell } from 'rsuite-table';
import { getValueWithUnit } from '../../utils/budgetAndProductionHelpers';

import './index.scss';

const EditableCell = ({
  editable,
  dataIndex,
  editButtonCallback,
  rowData,
  handleSave,
  unit,
  canShow,
  type,
  validator,
  additionalInputFields,
  objectModifier,
  conditionToEditable,
  className,
  calculateValue,
  isUnitPrefix,
  maxButtonValue,
  extraSave,
  decimalsIndex,
  disableInput,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);

  const [edited, setEdited] = useState(false);

  const calculatedValue = calculateValue(rowData);

  const toggleEdit = () => {
    setEditing(!editing);
  };

  useEffect(() => {
    if (!editable) {
      setEdited(false);
    }
  }, [editable]);
  const cellValue = calculatedValue;
  let children = getValueWithUnit(unit, cellValue, isUnitPrefix, type, decimalsIndex);

  if (editable && conditionToEditable(rowData)) {
    children = (
      <div className="editable-cell-value-wrap flex align-items-center" onClick={toggleEdit}>
        {cellValue}
        <i
          className="fa fa-edit mr-2"
          onMouseDown={(e) => {
            e.stopPropagation();
            editButtonCallback(rowData[dataIndex], rowData);
          }}
        />
      </div>
    );
  } else {
    children = cellValue;
  }

  if (!canShow(rowData)) {
    return (
      <Cell {...restProps} className={className(rowData)} dataKey={dataIndex}>
        {null}
      </Cell>
    );
  }

  return (
    <Cell
      {...restProps}
      className={`table-editable-cell ${
        className(rowData) && conditionToEditable(rowData) ? className(rowData) : ''
      } ${edited ? 'edited' : ''}`}
    >
      {children}
    </Cell>
  );
};

EditableCell.propTypes = {
  editable: PropTypes.bool.isRequired,
  dataIndex: PropTypes.string.isRequired,
  editButtonCallback: PropTypes.func,
  rowData: PropTypes.object.isRequired,
  handleSave: PropTypes.func.isRequired,
  unit: PropTypes.string,
  canShow: PropTypes.bool,
  type: PropTypes.string,
  validator: PropTypes.func,
  additionalInputFields: PropTypes.func,
  objectModifier: PropTypes.func,
  conditionToEditable: PropTypes.func,
  className: PropTypes.func,
  calculateValue: PropTypes.func,
  isUnitPrefix: PropTypes.bool,
  maxButtonValue: PropTypes.number,
  extraSave: PropTypes.func,
  decimalsIndex: PropTypes.number,
  disableInput: PropTypes.bool,
};

EditableCell.defaultProps = {
  editButtonCallback: null,
  unit: '',
  canShow: () => true,
  type: 'number',
  validator: () => true,
  additionalInputFields: () => ({}),
  objectModifier: (obj) => obj,
  conditionToEditable: () => true,
  className: () => '',
  calculateValue: () => '',
  isUnitPrefix: true,
  maxButtonValue: '',
  extraSave: () => [],
  decimalsIndex: null,
  disableInput: false,
};

export default EditableCell;
