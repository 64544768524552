import React, { useState } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Modal } from 'antd';
import {
  deleteArea as deleteAreaAction,
  deletePosition as deletePositionAction,
} from '../../../actions/areasAndPositions';
import NewArea from '../NewArea';
import NewPosition from '../NewPosition';
import { getFormattedNumber } from '../../../utils';
import './index.scss';

const List = ({ deleteArea, deletePosition, treeData, workers }) => {
  const [areasToHide, setAreasToHide] = useState({});
  const [areaToEdit, setAreaToEdit] = useState(null);
  const [positionToEdit, setPositionToEdit] = useState(null);
  const [infoToDelete, setInfoToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [workersInAreasOrPositions, setWorkersInAreasOrPositions] = useState([]);

  const handleAreas = (areaId) => {
    setAreasToHide(
      update(areasToHide, {
        [areaId]: { $set: Boolean(!areasToHide[areaId]) },
      })
    );
  };

  const handleDelete = () => {
    setIsDeleting(true);

    (infoToDelete.type === 'área'
      ? deleteArea(infoToDelete.areaId)
      : deletePosition(infoToDelete.areaId, infoToDelete.positionId)
    )
      .then(() => {
        setIsDeleting(false);
        setInfoToDelete(null);
      })
      .catch(() => {
        setIsDeleting(false);
        setInfoToDelete(null);
      });
  };

  const canDelete = (element) => {
    const workersWithConflict = workers
      .filter((e) =>
        element.type === 'área' ? e.ID_ARE === element.areaId : e.ID_CAR === element.positionId
      )
      .map((e) => `${e.NOMBRE_TRA} ${e.APELLIDO_TRA}`);

    if (workersWithConflict.length) {
      setWorkersInAreasOrPositions(workersWithConflict);
    } else {
      setInfoToDelete(element);
    }
  };

  const tree = [];

  treeData.forEach((e) => {
    const { CARGOS_ASOCIADOS, ID_ARE, ID_OBR, NOMBRE_ARE } = e;

    tree.push({
      areaId: ID_ARE,
      areaName: NOMBRE_ARE,
      cost: CARGOS_ASOCIADOS.length ? CARGOS_ASOCIADOS[0].COSTO_CAR : '',
      erp: CARGOS_ASOCIADOS.length ? CARGOS_ASOCIADOS[0].CODEXT_CAR : '',
      hasChildren: Boolean(CARGOS_ASOCIADOS.length),
      positionId: CARGOS_ASOCIADOS.length ? CARGOS_ASOCIADOS[0].ID_CAR : '',
      positionName: CARGOS_ASOCIADOS.length ? CARGOS_ASOCIADOS[0].NOMBRE_CAR : '',
      projectId: ID_OBR,
    });

    if (!areasToHide[ID_ARE] && CARGOS_ASOCIADOS.length > 1) {
      CARGOS_ASOCIADOS.forEach((positions, i) => {
        if (i > 0) {
          const { COSTO_CAR, CODEXT_CAR, ID_CAR, NOMBRE_CAR } = positions;
          tree.push({
            areaId: ID_ARE,
            areaName: '',
            cost: COSTO_CAR || 0,
            erp: CODEXT_CAR || '',
            positionId: ID_CAR,
            positionName: NOMBRE_CAR,
            hasChildren: true,
          });
        }
      });
    }
  });

  return (
    <div className="list">
      {treeData.length === 0 ? (
        <span>No hay elementos para mostrar</span>
      ) : (
        <div className="containerList">
          <div className="headerList header-table-color">
            <div className="headerArea">Área</div>
            <div className="headerPosition">Cargo</div>
            <div className="headerCost">Costo Cargo</div>
          </div>

          {tree.map((e, i) => (
            <div key={`tree${e.areaId + i}`}>
              <div className="contentList">
                <div
                  className={`contentArea ${
                    e.areaName ? 'withTopAndBottomBorder' : 'withoutBorder'
                  }`}
                >
                  <div>{e.areaName}</div>
                  {e.areaName && (
                    <div className="floatIcons">
                      {e.hasChildren && (
                        <div className="arrowIcon" onClick={() => handleAreas(e.areaId)}>
                          <i
                            className={`fa fa-angle-${
                              e.areaName && e.hasChildren && areasToHide[e.areaId]
                                ? 'right'
                                : 'down'
                            }`}
                            tabIndex={-1}
                          />
                        </div>
                      )}

                      <i
                        role="button"
                        tabIndex={-1}
                        onKeyDown={null}
                        className="fa fa-edit edit-icon"
                        onClick={() => setAreaToEdit(e.areaId)}
                      />
                      <i
                        onKeyDown={null}
                        role="button"
                        tabIndex={-1}
                        className="fa fa-trash"
                        type="primary"
                        onClick={() =>
                          canDelete({ areaId: e.areaId, type: 'área', name: e.areaName })
                        }
                      />
                      {areaToEdit === e.areaId && (
                        <NewArea
                          areaId={e.areaId}
                          oldName={e.areaName}
                          oldProject={String(e.projectId)}
                          onClose={() => setAreaToEdit(null)}
                          title="Editar Área"
                        />
                      )}
                    </div>
                  )}
                </div>
                <div className="contentPosition">
                  {!areasToHide[e.areaId] && e.hasChildren && (
                    <>
                      <div className="positionButtons">
                        <i
                          role="button"
                          tabIndex={-1}
                          onKeyDown={null}
                          className="fa fa-edit edit-icon"
                          onClick={() => setPositionToEdit(e.positionId)}
                        />
                        <i
                          onKeyDown={null}
                          role="button"
                          tabIndex={-1}
                          className="fa fa-trash"
                          type="primary"
                          onClick={() => {
                            canDelete({
                              areaId: e.areaId,
                              name: e.positionName,
                              positionId: e.positionId,
                              type: 'cargo',
                            });
                          }}
                        />
                        {positionToEdit === e.positionId && (
                          <NewPosition
                            areaId={e.areaId}
                            oldCompanyPrice={String(e.cost || 0)}
                            oldErp={String(e.erp)}
                            oldName={e.positionName}
                            onClose={() => setPositionToEdit(null)}
                            positionId={e.positionId}
                            title="Editar Cargo"
                          />
                        )}
                      </div>
                      <div className="positionName">{e.positionName}</div>
                    </>
                  )}
                </div>
                <div className="contentCost">{`${
                  !areasToHide[e.areaId] && e.hasChildren
                    ? `$${getFormattedNumber(String(e.cost))}`
                    : ''
                }`}</div>
              </div>
            </div>
          ))}
        </div>
      )}
      <Modal
        title={`Eliminando ${infoToDelete && infoToDelete.type}`}
        visible={infoToDelete}
        onOk={() => setInfoToDelete(null)}
        onCancel={() => setInfoToDelete(null)}
        footer={[
          <Button key="back" disabled={isDeleting} onClick={() => setInfoToDelete(null)}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            disabled={isDeleting}
            loading={isDeleting}
            type="primary"
            onClick={() => handleDelete()}
          >
            Eliminar
          </Button>,
        ]}
      >
        <p style={{ textAlign: 'center' }}>{`¿Confirma que desea eliminar a ${
          infoToDelete && infoToDelete.name
        }?`}</p>
      </Modal>
      {workersInAreasOrPositions.length > 0 && (
        <Modal
          title="Advertencia"
          visible
          onOk={() => setWorkersInAreasOrPositions([])}
          onCancel={() => setWorkersInAreasOrPositions([])}
          footer={null}
        >
          <p>Los siguientes trabajadores están asociados el elemento que se quiere eliminar:</p>
          <div style={{ marginLeft: '20px' }}>
            {workersInAreasOrPositions.map((e) => (
              <p>{e}</p>
            ))}
          </div>
        </Modal>
      )}
    </div>
  );
};

List.propTypes = {
  deleteArea: PropTypes.func.isRequired,
  deletePosition: PropTypes.func.isRequired,
  treeData: PropTypes.arrayOf(PropTypes.object).isRequired,
  workers: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default connect(
  () => ({}),
  (dispatch) => ({
    deleteArea: bindActionCreators(deleteAreaAction, dispatch),
    deletePosition: bindActionCreators(deletePositionAction, dispatch),
  })
)(List);
