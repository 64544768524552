import _ from 'lodash';
import { message } from 'antd';
import api, { getHeaders } from '../utils/api';
import store from '../store';
import { getMenu } from './menu';
import {
  ADD_NEW_PROFILE,
  EDIT_PROFILE,
  GET_DATA_TREE,
  GET_DATA_TREE_ERROR,
  GET_DATA_TREE_FETCHED,
  GET_PROFILE,
  GET_PROFILE_ERROR,
  PROFILE_FETCHED,
} from '../reducers/Profile';

export const addNewProfile = (profile) => ({
  type: ADD_NEW_PROFILE,
  profile,
});

export const setProfile = (profile) => ({
  type: PROFILE_FETCHED,
  profile,
});

export const editProfile = (profile) => ({
  type: EDIT_PROFILE,
  profile,
});

export const error = () => ({
  type: GET_PROFILE_ERROR,
});

export const setConfig = (config) => ({
  type: GET_DATA_TREE_FETCHED,
  config,
});

export const errorTree = () => ({
  type: GET_DATA_TREE_ERROR,
});

export const getProfile = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: GET_PROFILE });
    const requestBody = {
      usuario: localStorage.getItem('foco_username'),
      empresa: _.get(store.getState(), 'auth.user.client', ''),
    };
    api
      .post(`api/ConfiguracionSuite/GetPerfiles`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch(setProfile(res.data));
          resolve(res.data);
        } else {
          dispatch(error());
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(error());
        reject(err);
      });
  });

export const getConfig = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: GET_DATA_TREE });
    const requestBody = {
      usuario: localStorage.getItem('foco_username'),
      empresa: _.get(store.getState(), 'auth.user.client', ''),
    };
    api
      .post(`api/ConfiguracionSuite/GetMenuConfiguracionV2`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch(setConfig(res.data));
          resolve(res.data);
        } else {
          dispatch(error());
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(error());
        reject(err);
      });
  });

export const saveProfile = (NOMBRE_PERFIL, ACCESOS, ID_PERFIL_SUITE) => (dispatch) =>
  new Promise((resolve) => {
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      NOMBRE_PERFIL,
      ACCESOS,
      ...(ID_PERFIL_SUITE ? { ID_PERFIL_SUITE } : {}),
    };

    api
      .post(
        `api/ConfiguracionSuite/${ID_PERFIL_SUITE ? 'UpdPerfil' : 'SetPerfil'}`,
        requestBody,
        getHeaders()
      )
      .then((res) => {
        if (res.status === 200) {
          getProfile()(dispatch);
          getMenu(localStorage.getItem('foco_client'), false)(dispatch);
          message.success('Perfil guardado correctamente', 4);
        } else {
          message.success('Error guardando el perfil', 4);
        }
        resolve();
      })
      .catch(() => message.error('Error guardando el perfil', 4));
  });

export const deleteProfile = (ID_PERFIL_SUITE) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_PERFIL_SUITE,
    };

    api
      .post(`api/ConfiguracionSuite/DelPerfil`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          message.success('Perfil eliminado correctamente', 4);
          getProfile()(dispatch);
          resolve();
        } else {
          message.error('Error al eliminar el perfil', 4);
          reject();
        }
      })
      .catch(() => {
        message.error('Error al eliminar el perfil', 4);
        reject();
      });
  });
