import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import { Modal, Button, TreeSelect, Form, Input, InputNumber, Tooltip } from 'antd';
import { saveOptions as saveOptionsAction } from '../../../actions/options';
import fontAwesomeIconsNames from '../../../utils/fontAwesomeIconsNames';

import './index.scss';

const { TreeNode } = TreeSelect;

const OptionsForm = ({ activeRecord, onCancel, tree, saveOptions }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    show: false,
    pristine: true,
    selectedChapter: null,
    selectedIcon: null,
  });

  const { show, saving, pristine, selectedIcon } = state;

  const [form] = Form.useForm();

  useEffect(() => {
    if (activeRecord && !show) {
      setState(
        update(state, {
          show: { $set: true },
          selectedChapter: { $set: activeRecord.NOMBRE_MENU },
          selectedIcon: { $set: activeRecord.CSS_ICON },
        })
      );
      form.setFieldsValue(activeRecord);
    }
  }, [activeRecord]);

  useEffect(() => {
    if (activeRecord && !show) {
      onCancel();
    }
  }, [show]);

  const submit = (values) => {
    setIsLoading(true);
    saveOptions(
      values.ID_PADRE,
      activeRecord.ID_MENU,
      values.NOMBRE_MENU,
      values.ORDEN,
      selectedIcon
    ).then(() => {
      setIsLoading(false);
      setState(
        update(state, {
          show: { $set: false },
        })
      );

      form.resetFields();
    });
  };

  const onChangeForm = () => {
    if (activeRecord && pristine) {
      setState(update(state, { pristine: { $set: false } }));
    }
  };

  const getTreeNodes = (nodes) => {
    return nodes.map((currentNode) => {
      const primaryLabel = currentNode.NOMBRE_MENU;

      return (
        <TreeNode
          value={currentNode.ID_MENU}
          primaryLabel={primaryLabel}
          node={currentNode}
          title={
            <div className="tree-select-node">
              <span>{primaryLabel}</span>
            </div>
          }
        >
          {_.get(currentNode, 'children.length', null) === 0
            ? getTreeNodes(currentNode.children)
            : null}
        </TreeNode>
      );
    });
  };

  return (
    <div className="add-option">
      <Modal
        closable={false}
        wrapClassName="common-modal add-pnf-modal"
        visible={show}
        width={700}
        onCancel={() => {
          setState(
            update(state, {
              show: { $set: false },
            })
          );
        }}
        footer={null}
        okText="Guardar"
      >
        <div className="modal-container">
          <div className="modal-header">
            <div />
            <span>Editar</span>
            <i
              className="fa fa-times"
              onClick={() => {
                setState(
                  update(state, {
                    show: { $set: false },
                  })
                );
              }}
            />
          </div>
          <div className="form-body">
            <Form form={form} layout="vertical" onFinish={submit} onValuesChange={onChangeForm}>
              {tree && activeRecord && activeRecord.ID_PADRE !== 0 && (
                <Form.Item
                  label="Nombre Padre"
                  name="ID_PADRE"
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Por favor seleccione el nombre Menu',
                    },
                  ]}
                >
                  <TreeSelect>{getTreeNodes(tree)}</TreeSelect>
                </Form.Item>
              )}
              <Form.Item
                label="Nombre"
                name="NOMBRE_MENU"
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese un nombre ',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Orden"
                name="ORDEN"
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese un numero',
                  },
                  {
                    required: true,
                    validator: async (_obj, value) => {
                      if (value === 0) {
                        return Promise.reject(
                          new Error('Por favor ingrese un numero diferente a 0')
                        );
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>
              <div className="d-flex flex-column icons-list-wrapper ant-row">
                <div className="d-flex align-items-center justify-content-start">
                  <span>Icono del Menú:</span>
                  {selectedIcon && (
                    <div className="icon-wrapper d-flex align-items-end justify-content-center">
                      <i className={`fa fas ${selectedIcon}`} />
                    </div>
                  )}
                </div>
                <div className="d-flex flex-wrap icons-list">
                  {fontAwesomeIconsNames.map((icon) => (
                    <div
                      onClick={() => {
                        setState(
                          update(state, {
                            selectedIcon: { $set: `menu-icon fas ${icon}` },
                          })
                        );
                      }}
                      className={`icon-wrapper d-flex align-items-center justify-content-center ${
                        selectedIcon && selectedIcon === `menu-icon fas ${icon}` ? 'selected' : ''
                      }`}
                    >
                      <Tooltip
                        placement="topLeft"
                        destroyTooltipOnHide={{ keepParent: false }}
                        title={icon}
                      >
                        <i className={`fa fas ${icon}`} />
                      </Tooltip>
                    </div>
                  ))}
                </div>
              </div>
              <div className="modal-footer">
                <Button
                  disabled={saving}
                  onClick={() => {
                    setState(
                      update(state, {
                        show: { $set: false },
                      })
                    );
                    form.resetFields();
                  }}
                >
                  Cancelar
                </Button>
                <Button loading={isLoading} disabled={isLoading} type="primary" htmlType="submit">
                  Guardar
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

OptionsForm.propTypes = {
  activeRecord: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  tree: PropTypes.array.isRequired,
  saveOptions: PropTypes.func.isRequired,
};

export default connect(
  ({ options }) => ({
    options,
  }),
  (dispatch) => ({
    saveOptions: bindActionCreators(saveOptionsAction, dispatch),
  })
)(OptionsForm);
