import update from 'immutability-helper';

export const COST_CENTER_FETCHED = 'COST_CENTER_FETCHED';
export const GET_COST_CENTER = 'GET_COST_CENTER';
export const GET_COST_CENTER_ERROR = 'GET_COST_CENTER_ERROR';

const costCenterInit = {
  data: {},
  isFetching: false,
  error: false,
};

const costCenter = (state = costCenterInit, action) => {
  switch (action.type) {
    case 'WORKS_FETCHED': {
      let newCostCenter = { ...state };
      action.works.forEach(({ ID_OBR }) => {
        newCostCenter = update(newCostCenter, {
          data: {
            [ID_OBR]: { $set: null },
          },
        });
      });

      return newCostCenter;
    }

    case COST_CENTER_FETCHED: {
      return update(state, {
        data: {
          [action.workId]: {
            $set: action.costCenter,
          },
        },
        isFetching: { $set: false },
        error: { $set: false },
      });
    }

    case GET_COST_CENTER_ERROR:
      return update(state, { error: { $set: true }, isFetching: { $set: false } });
    case GET_COST_CENTER:
      return {
        ...state,
        isFetching: true,
        error: false,
      };

    case 'AUTH_LOGOUT':
      return costCenterInit;

    default:
      return state;
  }
};

export default costCenter;
