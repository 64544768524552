import update from 'immutability-helper';

export const AUTH_LOGGED = 'AUTH_LOGGED';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_INFORMATION_FROM_ATTENDANCE_URL = 'SET_INFORMATION_FROM_ATTENDANCE_URL';
export const SET_INFORMATION_FROM_PAYMENT_URL = 'SET_INFORMATION_FROM_PAYMENT_URL';
export const SET_INFORMATION_FROM_ADVANCE_URL = 'SET_INFORMATION_FROM_ADVANCE_URL';

const authInit = {
  token: null,
  user: {},
  error: false,
  isFetching: false,
  isLogged: false,
  isUpdating: false,
};

const auth = (authInfo = authInit, action) => {
  switch (action.type) {
    case AUTH_LOGGED:
      return update(authInfo, {
        user: { $merge: action.user },
        token: { $set: action.token },
        isFetching: { $set: false },
        isLogged: { $set: true },
        isUpdating: { $set: false },
        error: { $set: false },
      });

    case AUTH_ERROR:
      return {
        ...authInfo,
        error: true,
        isFetching: false,
        isUpdating: false,
      };
    case AUTH_LOGIN:
      return {
        ...authInfo,
        token: null,
        user: {},
        isFetching: true,
        isLogged: null,
        error: false,
      };

    case AUTH_LOGOUT:
      return authInit;

    case 'GET_MENU':
      return update(authInfo, {
        user: {
          client: { $set: action.client },
        },
      });

    case SET_INFORMATION_FROM_ATTENDANCE_URL: {
      const { client, token, username } = action.payload;
      return update(authInfo, {
        token: { $set: token },
        user: {
          client: { $set: client },
          username: { $set: username },
        },
      });
    }

    case SET_INFORMATION_FROM_PAYMENT_URL: {
      const { client, token, username } = action.payload;
      return update(authInfo, {
        token: { $set: token },
        user: {
          client: { $set: client },
          username: { $set: username },
        },
      });
    }

    case SET_INFORMATION_FROM_ADVANCE_URL: {
      const { client, token, username } = action.payload;
      return update(authInfo, {
        token: { $set: token },
        user: {
          client: { $set: client },
          username: { $set: username },
        },
      });
    }

    default:
      return authInfo;
  }
};

export default auth;
