import React, { useState, useEffect, useCallback } from 'react';
import { Form, Input, Button, DatePicker, Modal, Checkbox, Row, Col, message } from 'antd';
import './index.scss';
import { reject } from 'lodash';
import { advanceControlProptypes } from '../helpers';
import SelectWithTopLabel from '../../../components/SelectWithTopLabel';
import api, { getHeaders } from '../../../utils/api';

const NewProjectModal = ({
  projectToEdit,
  setProjectToEdit,
  cities,
  foco_client,
  foco_username,
  projects,
  getProjects,
  getWorks,
  works,
}) => {
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [cityValue, setCityValue] = useState([]);
  const [diasCierre, setDiasCierre] = useState([]);
  const [FocoCalidad, setFocoCalidad] = useState(1);
  const [FocoCalidadCierre, setFocoCalidadCierre] = useState(0);

  const diasSemana = [
    { ID_DIA: 0, NOMBRE_DIA: 'Lunes' },
    { ID_DIA: 1, NOMBRE_DIA: 'Martes' },
    { ID_DIA: 2, NOMBRE_DIA: 'Miercoles' },
    { ID_DIA: 3, NOMBRE_DIA: 'Jueves' },
    { ID_DIA: 4, NOMBRE_DIA: 'Viernes' },
    { ID_DIA: 5, NOMBRE_DIA: 'Sabado' },
    { ID_DIA: 6, NOMBRE_DIA: 'Domingo' },
  ];

  const saveProject = useCallback(
    async (
      USUARIO,
      EMPRESA,
      ID_OBR,
      ID_CIU,
      NOMBRE_OBR,
      FECINI_OBR,
      FECFIN_OBR,
      DIACORTEAVA_SEM,
      ELEMENTO_EP,
      GERENTE_PROY,
      DIRECTOR_OBR,
      DIAS_CIERRE,
      PLAZO_REV,
      NOMBRE_CARTILLA,
      FOCO_CALIDAD,
      FOCO_CALIDAD_CIERRE
    ) => {
      const ID_OBR_EDIT = projectToEdit === 'edit' ? ID_OBR : '';
      const requestBody = {
        USUARIO,
        EMPRESA,
        ID_OBR: ID_OBR_EDIT,
        ID_CIU,
        NOMBRE_OBR,
        FECINI_OBR,
        FECFIN_OBR,
        DIACORTEAVA_SEM,
        ELEMENTO_EP,
        GERENTE_PROY,
        DIRECTOR_OBR,
        DIAS_CIERRE,
        PLAZO_REV,
        NOMBRE_CARTILLA,
        FOCO_CALIDAD,
        FOCO_CALIDAD_CIERRE,
      };

      const errorMessage = `Error al ${
        projectToEdit === 'create' ? 'crear' : 'editar'
      } el proyecto`;
      api
        .post(
          `api/Proyecto/${projectToEdit === 'create' ? 'SetProyecto' : 'UpdProyecto'}`,
          requestBody,
          getHeaders()
        )
        .then((res) => {
          if (res.status === 200) {
            console.log('res', res);
            setConfirmLoading(false);
            setProjectToEdit('');
            form.resetFields();
            getProjects();
            getWorks();
          }
        })
        .catch(() => {
          message.error(errorMessage, 4);
          reject();
        });
    }
  );

  const submit = (values) => {
    const USUARIO = foco_username;
    const EMPRESA = foco_client;
    const ID_OBR = works.selected;

    const {
      NOMBRE_OBR,
      FECINI_OBR,
      FECFIN_OBR,
      DIACORTEAVA_SEM,
      ELEMENTO_EP,
      GERENTE_PROY,
      DIRECTOR_OBR,
      DIAS_CIERRE,
      PLAZO_REV,
      NOMBRE_CARTILLA,
    } = values;

    setConfirmLoading(true);
    saveProject(
      USUARIO,
      EMPRESA,
      ID_OBR,
      cityValue,
      NOMBRE_OBR,
      FECINI_OBR.format('DD-MM-YYYY'),
      FECFIN_OBR.format('DD-MM-YYYY'),
      DIACORTEAVA_SEM,
      ELEMENTO_EP,
      GERENTE_PROY,
      DIRECTOR_OBR,
      Number(DIAS_CIERRE),
      Number(PLAZO_REV),
      NOMBRE_CARTILLA,
      FocoCalidad,
      FocoCalidadCierre
    );
  };

  useEffect(() => {
    if (projectToEdit === 'edit') {
      const NEW_FECINI_OBR = moment(projects.data.FECINI_OBR, 'DD/MM/YYYY');
      const NEW_FECFIN_OBR = moment(projects.data.FECFIN_OBR, 'DD/MM/YYYY');

      setFocoCalidad(projects.data.FOCO_CALIDAD);
      setFocoCalidadCierre(projects.data.FOCO_CALIDAD_CIERRE);
      setCityValue(projects.data.ID_CIU);

      form.setFieldsValue({
        ID_CIU: projects.data.ID_CIU,
        NOMBRE_OBR: projects.data.NOMBRE_OBR,
        FECINI_OBR: NEW_FECINI_OBR,
        FECFIN_OBR: NEW_FECFIN_OBR,
        DIACORTEAVA_SEM: projects.data.DIACORTEAVA_SEM,
        ELEMENTO_EP: projects.data.ELEMENTO_EP,
        GERENTE_PROY: projects.data.GERENTE_PROY,
        DIRECTOR_OBR: projects.data.DIRECTOR_OBR,
        DIAS_CIERRE: projects.data.DIAS_CIERRE,
        PLAZO_REV: projects.data.PLAZO_REV,
        NOMBRE_CARTILLA: projects.data.NOMBRE_CARTILLA,
      });
    } else {
      form.resetFields();
    }
  }, [projectToEdit]);

  return (
    <Modal
      className="modal-management-position"
      title={projectToEdit === 'edit' ? 'Editar Proyecto' : 'Nuevo Proyecto'}
      visible={projectToEdit}
      confirmLoading={confirmLoading}
      onCancel={() => setProjectToEdit('')}
      footer
    >
      <div className="container-modal w-100">
        <Form form={form} layout="vertical" onFinish={submit} scrollToFirstError>
          <>
            <div title="Datos Proyecto">
              <div className="container-items pt-5">
                <Form.Item
                  label="Nombre"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese un Nombre de Proyecto',
                    },
                  ]}
                  name="NOMBRE_OBR"
                  required
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  initialValue=""
                  required
                  label="Codigo Contable"
                  name="ELEMENTO_EP"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese Codigo',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Gerente Proyecto"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese Nombre Gerente',
                    },
                  ]}
                  name="GERENTE_PROY"
                  required
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  required
                  label="Administrador"
                  name="DIRECTOR_OBR"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese Nombre Administrador',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="container-items ">
                <Form.Item
                  label="Inicio Contractual"
                  name="FECINI_OBR"
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Por favor seleccione una Fecha de Inicio',
                    },
                  ]}
                >
                  <DatePicker placeholder="dd/mm/aaaa" format="DD/MM/YYYY" className=" w-100" />
                </Form.Item>
                <Form.Item
                  label="Término Contractual"
                  name="FECFIN_OBR"
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Por favor seleccione una Fecha de Término',
                    },
                  ]}
                >
                  <DatePicker placeholder="dd/mm/aaaa" format="DD/MM/YYYY" className=" w-100" />
                </Form.Item>

                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese un Dia de la Semana',
                    },
                  ]}
                  name="DIACORTEAVA_SEM"
                  required
                >
                  <SelectWithTopLabel
                    name="DIACORTEAVA_SEM"
                    label="Dias Corte Avance"
                    placeholder="Selecciona un dia"
                    className="top-select-modal"
                    keepShowingLabel
                    value={diasCierre}
                    options={(diasSemana || []).map(({ ID_DIA, NOMBRE_DIA }) => ({
                      name: NOMBRE_DIA,
                      value: ID_DIA,
                    }))}
                    onChange={(value) => setDiasCierre(value)}
                  />
                </Form.Item>

                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese una ciudad',
                    },
                  ]}
                  name="ID_CIU"
                  required
                >
                  <SelectWithTopLabel
                    name="ID_CIU"
                    label="Ciudad"
                    placeholder="Selecciona una Ciudad"
                    className="top-select-modal"
                    keepShowingLabel
                    disabled={!cities.data}
                    value={cities}
                    options={(cities.data || []).map(({ ID_CIU, NOMBRE_CIU }) => ({
                      name: NOMBRE_CIU,
                      value: ID_CIU,
                    }))}
                    onChange={(value) => setCityValue(value)}
                  />
                </Form.Item>
              </div>

              <div className="container-items ">
                <Form.Item required label="Parametros Foco Calidad">
                  <div className="checkbox-container">
                    <Row>
                      <Col span={9}>
                        <Checkbox
                          className="mt-4"
                          checked={FocoCalidad}
                          onChange={(e) => setFocoCalidad(Number(e.target.checked))}
                          name="FOCO_CALIDAD"
                        >
                          Utiliza Foco Calidad
                        </Checkbox>
                      </Col>
                      <Col span={15}>
                        <Checkbox
                          className="mt-4"
                          checked={FocoCalidadCierre}
                          onChange={(e) => setFocoCalidadCierre(Number(e.target.checked))}
                          name="FOCO_CALIDAD_CIERRE"
                        >
                          Cerrar Modulo Protocolos de Calidad
                        </Checkbox>
                      </Col>
                    </Row>
                  </div>
                </Form.Item>
              </div>

              <div className="container-items ">
                <Form.Item
                  required
                  label=" Nº dias cierre automático de protocolos"
                  name="DIAS_CIERRE"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese Numero de Dias',
                    },
                  ]}
                >
                  <Input type="number" />
                </Form.Item>
              </div>
              <div className="container-items ">
                <Form.Item
                  required
                  label="Nº dias de plazo para revisión de protocolos"
                  name="PLAZO_REV"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese Numero de Dias de Plazo',
                    },
                  ]}
                >
                  <Input min={0} type="number" />
                </Form.Item>
                <Form.Item
                  required
                  label="Nombre Cartilla de Control para formato Serviu"
                  name="NOMBRE_CARTILLA"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese Nombre Cartilla',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
            </div>
            <div className="control-buttons">
              <Button className=" mr-2" onClick={() => setProjectToEdit('')}>
                Cancelar
              </Button>

              <Button
                className="color-button ml-2"
                loading={confirmLoading}
                type=""
                htmlType="submit"
                onClick
              >
                Aceptar
              </Button>
            </div>
          </>
        </Form>
      </div>
    </Modal>
  );
};
NewProjectModal.propTypes = advanceControlProptypes;

export default NewProjectModal;
