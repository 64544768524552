import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { Row, Col, Button, Modal, message } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import update from 'immutability-helper';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import Table from '../../components/TableExtend';
import ViewOptions from '../../components/ViewOptions';
import AccountOfCostSelector from './AccountOfCostSelector';
import ListFilter from '../../components/ListFilter';
import TreeFilter from '../../components/BudgetProductionTreeFilter';
import NewItem from './NewItem';
import { getAdvanceDates as getAdvanceDatesAction } from '../../actions/production';
import {
  deleteBudget as deleteBudgetAction,
  getBudget as getBudgetAction,
  editBudgetCells,
  resetBudget as resetBudgetAction,
  getInputsTypes as getInputsTypesAction,
} from '../../actions/budget';

import ChapterFilter from '../../components/ChapterFilter';

import { getAccountOfCost as getAccountOfCostAction } from '../../actions/accountOfCost';
import columns from './columns';
import {
  activeEditionsTitles,
  variationFilterOptions,
  groupObjectInArray,
  getChildren,
  changeItemFilters,
  getTreeList,
  getNodeObjectToUpdate,
} from '../../utils/budgetAndProductionHelpers';
import { commonPropTypes } from '../../utils';

import './index.scss';
import EditChapter from './EditChapter';
import Modalforecast from './ModalForecast/ModalForecast';

const BudgetControl = ({
  getBudget,
  budget,
  accountOfCostInfo,
  selectedWork,
  getAccountOfCost,
  resetBudget,
  production,
  getAdvanceDates,
  access,
  getInputsTypes,
  deleteBudget,
}) => {
  const [active, setActive] = useState(null);
  const [tree, setTree] = useState(null);
  const [lastSavedTree, setLastSavedTree] = useState(null);
  const [expandedRowKeys, setExpandeRowKeys] = useState([]);
  const [showCostAccountsModal, setShowCostAccountsModal] = useState(false);
  const [selectedAccount, setAccount] = useState(null);
  const [showFamilyModal, setShowFamilyModal] = useState(false);
  const [selectedFamily, setFamily] = useState(null);
  const [activeRecord, setActiveRecord] = useState(null);
  const [activeEdition, setActiveEdition] = useState(null);
  const [copyUpdatedBudgetInfo, setCopyUpdatedBudgetInfo] = useState({});
  const [fieldsToSave, setFieldsToSave] = useState([]);
  const [savingEdition, setSavingEdition] = useState(false);
  const [itemFilters, setItemFilters] = useState([]);
  const [itemFilterOptions, setItemFilterOptions] = useState([]);
  const [resourcesData, setResourcesData] = useState([]);
  const [resourcesExpandedRowKeys, setResourcesExpandedRowKeys] = useState([]);
  const [itemParents, setItemParents] = useState([]);
  const [hasFiltersApplied, setHasFiltersApplied] = useState(false);
  const [activeChapterEdition, setActiveChapterEdition] = useState(false);
  const [activeItemEdition, setActiveItemEdition] = useState(null);
  const [chapters, setChapters] = useState([]);
  const [inputsTypesObject, setInputsTypesObject] = useState(null);
  const [isTreeUpdated, setIsTreeUpdated] = useState({});
  const [isModalVisibleForecast, setIsModalVisibleForecast] = useState(false);

  const [showFilterBar, setShowFilterBar] = useState(false);

  const {
    advanceDates: { data: advanceDatesData },
  } = production;
  const [filters, setFilters] = useState({
    accountOfCost: [],
    items: [],
    resources: [],
    variation: { current: false, updated: false, percentage: false },
  });

  const { isFetching, data, inputsTypes } = budget;

  const { data: accountOfCostData, isFetching: isFetchingAccountOfCost } = accountOfCostInfo;

  const [activeOptions, setActiveOptions] = useState({
    option3: true,
    option4: true,
    option8: true,
  });

  const accountOfCostDataOptions =
    accountOfCostData[selectedWork] &&
    accountOfCostData[selectedWork]
      .filter(({ ES_CUENTA_COSTO }) => ES_CUENTA_COSTO)
      .map(
        ({ CODIGO_COSTO_ITEM, NOMBRE_COSTO_ITEM }) =>
          `(${CODIGO_COSTO_ITEM}) - ${NOMBRE_COSTO_ITEM}`
      );

  useEffect(() => {
    if (!advanceDatesData[selectedWork]) {
      getAdvanceDates(selectedWork);
    }
  }, [advanceDatesData, selectedWork]);

  useEffect(() => {
    if (!isFetching && (!data[selectedWork] || isTreeUpdated[selectedWork])) {
      getBudget(selectedWork);
      setFieldsToSave([]);
      setIsTreeUpdated({ ...isTreeUpdated, [selectedWork]: false });
    }

    if (!accountOfCostData[selectedWork] && !isFetchingAccountOfCost) {
      getAccountOfCost(selectedWork);
    }
  }, [selectedWork]);

  useEffect(() => {
    if (inputsTypes.data && !inputsTypesObject) {
      const newInputTypesObject = {};

      inputsTypes.data.forEach((e) => {
        newInputTypesObject[e.ID_TIN] = e.NOMABR_TIN;
      });
      setInputsTypesObject(newInputTypesObject);
    }
  }, [inputsTypes]);

  useEffect(() => {
    const treeData = data[selectedWork];
    if (treeData) {
      if (treeData.length > 0) {
        let treeObject = {};
        const treeDataItemsOptions = [];
        const treeDataResourcesOptions = {};
        const initialExpandedRowKeys = [];
        const eqsExpandedRowKeys = [];
        const parentsOfItems = [];
        const chaptersElements = [];

        treeData.forEach((item) => {
          if (treeObject[item.ID_ITEM_SUP]) {
            treeObject = update(treeObject, {
              [item.ID_ITEM_SUP]: {
                $push: [item],
              },
            });
          } else {
            treeObject = update(treeObject, {
              [item.ID_ITEM_SUP]: {
                $set: [item],
              },
            });
          }

          if (!treeObject[item.ID_ITEM] && item.ID_NAT === 1) {
            treeObject = update(treeObject, {
              [item.ID_ITEM]: {
                $set: [],
              },
            });
          }

          if (item.ID_NAT === 1) {
            initialExpandedRowKeys.push(item.ID_ITEM);
            chaptersElements.push(item);
          }

          if (item.ID_NAT === 1 || item.ID_NAT === 3) {
            eqsExpandedRowKeys.push(item.ID_ITEM);
          }

          if (item.ID_NAT === 3) {
            treeDataItemsOptions.push(item);
          } else if (item.ID_NAT === 4) {
            if (!treeDataResourcesOptions[item.ID_ITEM]) {
              treeDataResourcesOptions[item.ID_ITEM] = item;
            }
          }
        });

        const newTreeDataResourcesOptions = Object.keys(treeDataResourcesOptions).map(
          (key) => treeDataResourcesOptions[key]
        );

        const newTree = [];
        const paths = {
          [treeObject[0][0].ID_ITEM]: '0',
        };
        const savePath = (id, path) => {
          if (!paths[id]) {
            paths[id] = path;
          }
        };

        const saveParent = (element) => {
          parentsOfItems.push(element);
        };

        treeObject[0].forEach((current, i) => {
          const element = {
            ...current,
            PATH: `${i}`,
            children: getChildren(
              current.ID_ITEM,
              treeObject,
              `${i}`,
              savePath,
              saveParent,
              'ID_ITEM',
              false,
              0,
              'ID_NAT',
              1,
              3,
              true
            ),
          };

          parentsOfItems.push(element);
          newTree.push(element);
        });

        if (!itemFilterOptions.length) {
          setItemFilterOptions(treeDataItemsOptions);
        }
        if (!resourcesData.length) {
          setResourcesData(newTreeDataResourcesOptions);
        }
        setItemParents(parentsOfItems);
        setTree(newTree);
        if (!chapters.length) {
          setChapters(chaptersElements);
        }
        if (!hasFiltersApplied) {
          setLastSavedTree(newTree);
        }
        setExpandeRowKeys(initialExpandedRowKeys);
        setResourcesExpandedRowKeys(eqsExpandedRowKeys);
      } else {
        setItemParents([]);
        setTree([]);
        setExpandeRowKeys([]);
        setResourcesExpandedRowKeys([]);
      }
    }
  }, [data, selectedWork]);

  React.useEffect(() => {
    if (!inputsTypes.data) {
      getInputsTypes();
    }
    return () => {
      resetBudget();
    };
  }, []);

  const getCommonProps = (key) => ({
    onClick: () => setActive(key),
    onClose: () => setActive(null),
    show: active === key,
  });

  useEffect(() => {
    if (activeOptions.option6) {
      setExpandeRowKeys(resourcesExpandedRowKeys);
    }
  }, [activeOptions.option6]);

  const editCell = (id, row, remove = false) => {
    const fieldToUpdate = {
      ID_ITEM: id,
      ...row,
      PATH: undefined,
      PORCENTAJE_EXITO_EDITADO: undefined,
      ID_ITEM_SUP: activeEdition === 'account_of_cost' ? row.ID_ITEM_SUP : undefined,
    };
    setFieldsToSave((prev) => {
      if (remove) {
        return prev.filter((e) => e.ID_ITEM !== id);
      }
      const index = prev.findIndex((e) => e.ID_ITEM === id);

      if (index !== -1) {
        return update(prev, {
          [index]: { $merge: fieldToUpdate },
        });
      }
      return update(prev, {
        $push: [fieldToUpdate],
      });
    });
    const path = row.PATH.split('.');
    const updatedNode = getNodeObjectToUpdate(path, 0, { $merge: row });
    setTree(update(tree, updatedNode));
  };

  const saveEdition = () => {
    const groupedFieldsToSave = groupObjectInArray(fieldsToSave);
    const restOfFields = [];
    groupedFieldsToSave.forEach((e) => {
      if (e.children) {
        const field = activeEdition === 'updated_budget' ? 'CANTIDAD_MOD' : 'CANTIDAD_VIGENTE';
        e.children.forEach((child) =>
          restOfFields.push({
            ID_ITEM: child.ID_ITEM,
            [field]: child[field],
            ...(activeEdition === 'updated_budget'
              ? { COMENTARIO_MOD: child.COMENTARIO_MOD }
              : { COMENTARIO_VIGENTE: child.COMENTARIO_VIGENTE }),
          })
        );
      }
    });

    const finalFields = [
      ...groupedFieldsToSave.map((e) => ({ ...e, children: undefined })),
      ...restOfFields,
    ];

    setSavingEdition(true);
    editBudgetCells(finalFields, activeEdition)
      .then(() => {
        if (!isTreeUpdated[selectedWork]) {
          setIsTreeUpdated({ ...isTreeUpdated, [selectedWork]: true });
        }
        setSavingEdition(false);
        setActiveEdition(null);
        setLastSavedTree(cloneDeep(tree));
        setFieldsToSave([]);
      })
      .catch(() => setSavingEdition(false));
  };

  const handleEditAccountOfCost = () => {
    const values = selectedAccount.split(' - ');
    const accountCode = values[0].substring(1, values[0].length - 1);
    const row = {
      CODIGO_COSTO_ITEM: accountCode,
      ID_ITEM_SUP: activeRecord.ID_ITEM_SUP,
      PATH: activeRecord.PATH,
    };
    editCell(activeRecord.ID_ITEM, row);
    setShowCostAccountsModal(false);
    setAccount(null);
  };

  const openAccountOfCostEditionModal = (value, record) => {
    setShowCostAccountsModal(true);
    setActiveRecord(record);
  };

  const handleEditFamily = () => {
    const { ID_TIN } = selectedFamily;
    const row = {
      ID_TIN_CC: ID_TIN,
      ID_ITEM_SUP: activeRecord.ID_ITEM_SUP,
      PATH: activeRecord.PATH,
    };
    editCell(activeRecord.ID_ITEM, row);
    setShowFamilyModal(false);
    setFamily(null);
  };
  const openFamilyEditionModal = (value, record) => {
    setShowFamilyModal(true);
    setActiveRecord(record);
  };

  const changeInfoToCopyUpdatedBudget = (checked, record) => {
    const id = record.ID_ITEM;
    if (checked) {
      setCopyUpdatedBudgetInfo({
        ...copyUpdatedBudgetInfo,
        [id]: record.CANTIDAD_VIGENTE,
      });
      editCell(id, { CANTIDAD_VIGENTE: record.CANTIDAD_MOD, PATH: record.PATH });
    } else {
      setCopyUpdatedBudgetInfo({
        ...copyUpdatedBudgetInfo,
        [id]: undefined,
      });
      editCell(id, { CANTIDAD_VIGENTE: copyUpdatedBudgetInfo[id], PATH: record.PATH }, true);
    }
  };

  const findAccountOfCostCode = (value, returnBool = true, showError = true) => {
    const accountOfCostCode =
      accountOfCostData[selectedWork] &&
      accountOfCostData[selectedWork].find(({ CODIGO_COSTO_ITEM }) => CODIGO_COSTO_ITEM === value);

    if (!accountOfCostCode && showError) {
      message.error('El Codigo ingresado no pertenece a ninguna cuenta de costo existente');
    }

    if (returnBool) return !!accountOfCostCode;

    return accountOfCostCode;
  };

  const activeEditMode = (key, optionKey) => {
    setActiveEdition(key);
    setActiveOptions({ ...activeOptions, [optionKey]: true });
  };

  useEffect(() => {
    if (tree) {
      const {
        accountOfCost,
        items = [],
        resources = [],
        variation,
        chapters: chapterFilters = [],
      } = filters;
      const toFilter = {
        PARTIDAS: items.map((id) => ({ ID_ITEM: id })),
        RECURSOS: resources.map((id) => ({ ID_ITEM: id })),
        CUENTAS: accountOfCost.map(({ CODIGO_COSTO_ITEM }) => ({ CODIGO_COSTO_ITEM })),
        CAPITULOS: chapterFilters.map((id) => ({ ID_ITEM: id })),
        VARIACION_VIGENTE: variation.current ? 1 : 0,
        VARIACION_ACTUALIZADO: variation.updated ? 1 : 0,
        EXITO_DISTINTO_100: variation.successPercentage ? 1 : 0,
      };
      getBudget(selectedWork, toFilter);
    }
  }, [filters]);

  const applyFilters = (filterType, selectedOptions) => {
    if (filterType === 'accountOfCost') {
      setFilters({
        ...filters,
        accountOfCost: selectedOptions.filter(({ ES_CUENTA_COSTO }) => ES_CUENTA_COSTO),
      });
    } else if (filterType === 'resource') {
      setFilters({ ...filters, resources: selectedOptions });
    } else if (filterType === 'item') {
      setFilters({ ...filters, items: selectedOptions });
    } else if (filterType === 'chapter') {
      setFilters({ ...filters, chapters: selectedOptions });
    } else if (filterType === 'variation') {
      const variation = {
        current: selectedOptions.includes(1),
        updated: selectedOptions.includes(2),
        successPercentage: selectedOptions.includes(3),
      };
      setFilters({ ...filters, variation });
    }

    if (!hasFiltersApplied) {
      setHasFiltersApplied(true);
    }

    setActive(null);
  };

  const changeExpandedRows = (expanded, rowData) => {
    if (activeOptions.option6) {
      setActiveOptions({ ...activeOptions, option6: false });
    }
    if (expanded) {
      setExpandeRowKeys(
        update(expandedRowKeys, {
          $push: [rowData.ID_ITEM],
        })
      );
    } else {
      const index = expandedRowKeys.indexOf(rowData.ID_ITEM);

      setExpandeRowKeys(
        update(expandedRowKeys, {
          $splice: [[index, 1]],
        })
      );
    }
  };

  const reloadTree = () => {
    getBudget(selectedWork);
  };

  const addChapterToTree = (parent, newItem) => {
    const path = parent.PATH.split('.');
    const updatedNode = getNodeObjectToUpdate(path, 0, { children: { $push: [newItem] } });
    const newTree = update(tree, updatedNode);
    setTree(newTree);
    setLastSavedTree(newTree);
    setItemParents(update(itemParents, { $push: [newItem] }));
    setChapters(update(chapters, { $push: [newItem] }));
  };

  const onEditChapter = (body) => {
    editCell(body.ID_ITEM, body);
    setActiveChapterEdition(null);
    const chapterIndex = chapters.findIndex((e) => e.ID_ITEM === body.ID_ITEM);
    const toMerge = { CODIGO_ITEM: body.CODIGO_ITEM, NOMBRE_ITEM: body.NOMBRE_ITEM };
    setChapters(update(chapters, { [chapterIndex]: { $merge: toMerge } }));
  };

  const loadingTable =
    (!tree && selectedWork) || isFetching || isFetchingAccountOfCost || savingEdition;

  const showModal = () => {
    setIsModalVisibleForecast(true);
  };

  const editChapter = React.useMemo(
    () => (
      <EditChapter
        chapter={activeChapterEdition}
        onClose={() => setActiveChapterEdition(null)}
        chapters={chapters}
        onSuccess={onEditChapter}
      />
    ),
    [activeChapterEdition, chapters, onEditChapter]
  );

  return (
    <div className="budget-control">
      <Modalforecast
        isModalVisibleForecast={isModalVisibleForecast}
        setIsModalVisibleForecast={setIsModalVisibleForecast}
      />
      <Row gutter={[30, 30]}>
        <Col className="first-buttons-col" span={24}>
          {activeEdition ? (
            <div className="edit-buttons">
              <h4>{activeEditionsTitles[activeEdition]}</h4>
              <div className="control-buttons">
                <Button
                  onClick={() => {
                    setActiveEdition(null);
                    setTree(lastSavedTree);
                    setFieldsToSave([]);
                  }}
                  disabled={savingEdition}
                >
                  Cancelar
                </Button>
                <Button
                  disabled={savingEdition || fieldsToSave.length === 0}
                  type="primary"
                  onClick={saveEdition}
                >
                  Guardar
                </Button>
              </div>
            </div>
          ) : (
            <>
              <Button
                loading={budget.isFetching}
                onClick={() => getBudget(selectedWork)}
                type="primary"
              >
                Actualizar
              </Button>
              {!!access.CONTROL_ALCANCE_NUEVA_PARTIDA && (
                <NewItem
                  reloadTree={reloadTree}
                  addChapterToTree={addChapterToTree}
                  itemParents={itemParents}
                  resourcesData={resourcesData}
                  tree={lastSavedTree}
                  disabledButton={loadingTable}
                  inputsTypes={inputsTypes}
                  showButton
                />
              )}

              {!!access.CONTROL_ALCANCE_AJUSTAR_PRECIO && (
                <Button onClick={() => activeEditMode('set_sale_price', 'option7')} type="primary">
                  Ajustar PU Venta
                </Button>
              )}

              {!!access.CONTROL_ALCANCE_AJUSTAR_CONTRATO && (
                <Button onClick={() => activeEditMode('current_budget', 'option3')} type="primary">
                  Ajustar Contrato Vigente
                </Button>
              )}
              {!!access.CONTROL_ALCANCE_AJUSTAR_FORECAST && (
                <Button onClick={() => activeEditMode('updated_budget', 'option4')} type="primary">
                  Ajustar Forecast
                </Button>
              )}

              {!!access.CONTROL_ALCANCE_EDITAR_CC && (
                <Button
                  disabled={!inputsTypesObject}
                  type="primary"
                  onClick={() => activeEditMode('account_of_cost', 'option1')}
                >
                  Editar Plan de Cuentas
                </Button>
              )}
              {!!access.CONTROL_ALCANCE_GUARDAR_FORECAST && (
                <Button type="primary" onClick={showModal}>
                  Guardar Forecast
                </Button>
              )}
              <Button type="Default" onClick={() => setShowFilterBar(!showFilterBar)}>
                <i className="fa fa-filter mr-1" />
                {!showFilterBar ? 'Filtros' : 'Ocultar Filtros'}
              </Button>
              <div>
                <ViewOptions
                  defaultActiveOptions={activeOptions}
                  updateOptions={(opt) => setActiveOptions(opt)}
                  activeOptionsProps={activeOptions}
                  {...getCommonProps('view')}
                />
              </div>
            </>
          )}
        </Col>
      </Row>
      {showFilterBar && (
        <Row gutter={[30, 30]}>
          <Col
            className={`second-buttons-col ${!tree || isFetchingAccountOfCost ? 'blocked' : ''}`}
            span={24}
          >
            <ChapterFilter
              title="Filtro Capítulos"
              tree={lastSavedTree || []}
              buttonTitle="Capítulo"
              defaultExpandItemValues={
                lastSavedTree && lastSavedTree.length ? [lastSavedTree[0].ID_ITEM] : []
              }
              onSave={(selectedOptions) => applyFilters('chapter', selectedOptions)}
              {...getCommonProps('chapter')}
            />
            <ListFilter
              allowSelectAll
              title="Filtro Partidas"
              subtitle="Lista de Partidas"
              onChange={(value, isChecked) =>
                changeItemFilters(value, isChecked, {
                  callback: setItemFilters,
                  filters: itemFilters,
                  options: itemFilterOptions,
                })
              }
              buttonTitle="Partida"
              elementKey="ID_ITEM"
              elementTitleKey="NOMBRE_ITEM"
              onSave={(selectedOptions) => applyFilters('item', selectedOptions)}
              options={itemFilterOptions}
              {...getCommonProps('item')}
            />
            <ListFilter
              allowSelectAll
              title="Filtro de Recursos"
              subtitle="Lista de Recursos"
              buttonTitle="Recurso"
              elementKey="ID_ITEM"
              elementTitleKey="NOMBRE_ITEM"
              onSave={(selectedOptions) => applyFilters('resource', selectedOptions)}
              options={resourcesData}
              {...getCommonProps('resource')}
            />
            <TreeFilter
              allowSelectAll={false}
              title="Filtros Cuentas de Costos"
              subtitle="Lista de Cuentas"
              buttonTitle="Cuenta"
              data={accountOfCostData[selectedWork]}
              elementTitleKey="NOMBRE_COSTO_ITEM"
              elementKey="ID_ITEM_COSTO"
              showSearch
              extraKey="ES_CUENTA_COSTO"
              onSave={(selectedOptions) => applyFilters('accountOfCost', selectedOptions)}
              options={
                accountOfCostData[selectedWork]
                  ? getTreeList(
                      accountOfCostData[selectedWork],
                      0,
                      'ID_ITEM_COSTO',
                      'ID_ITEM_COSTO_SUP'
                    )
                  : []
              }
              {...getCommonProps('account')}
            />
            <ListFilter
              title="Filtros Adicionales"
              allowSelectAll={false}
              buttonTitle="Más filtros"
              elementTitleKey="TITLE"
              elementKey="ID_ITEM"
              onSave={(selectedOptions) => applyFilters('variation', selectedOptions)}
              options={variationFilterOptions}
              showSearch={false}
              {...getCommonProps('variation')}
            />
          </Col>
        </Row>
      )}
      <Row gutter={[30, 30]}>
        <Col className="budget-control-col mt-3 pt-0" span={24}>
          <Modal
            closable={false}
            wrapClassName="accounts-cost-modal"
            title="Basic Modal"
            visible={showCostAccountsModal}
            onOk={() => {
              setShowCostAccountsModal(false);
              setActiveRecord(null);
            }}
            onCancel={() => {
              setShowCostAccountsModal(false);
              setActiveRecord(null);
            }}
            okText="Guardar"
            width={340}
          >
            <AccountOfCostSelector
              title="Cuentas de Costo"
              subtitle="Listado Cuentas"
              buttonTitle="Actividad"
              scrollable
              selectedOptions={selectedAccount ? [selectedAccount] : []}
              onChange={(value) => setAccount(value)}
              onSave={handleEditAccountOfCost}
              onClose={() => {
                setShowCostAccountsModal(false);
                setAccount(null);
              }}
              options={accountOfCostDataOptions}
            />
          </Modal>
          <Modal
            closable={false}
            wrapClassName="accounts-cost-modal"
            title="Basic Modal"
            visible={showFamilyModal}
            onOk={() => {
              setShowFamilyModal(false);
              setActiveRecord(null);
            }}
            onCancel={() => {
              setShowFamilyModal(false);
              setActiveRecord(null);
            }}
            okText="Guardar"
            width={340}
          >
            <AccountOfCostSelector
              title="Naturalezas"
              subtitle="Listado Naturalezas"
              buttonTitle="Actividad"
              elementKey="ID_TIN"
              elementTitleKey="NOMBRE_TIN"
              elementSecondaryTitleKey="NOMABR_TIN"
              scrollable
              selectedOptions={selectedFamily ? [selectedFamily] : []}
              onChange={(value) => setFamily(value)}
              onSave={handleEditFamily}
              onClose={() => {
                setShowFamilyModal(false);
                setFamily(null);
              }}
              options={inputsTypes.data}
            />
          </Modal>
          {editChapter}
          <Table
            height={window.innerHeight - (showFilterBar ? 263 : 180)}
            hover={false}
            className="common-table"
            headerHeight={75}
            rowHeight={28}
            rowKey="ID_ITEM"
            bordered
            virtualized
            shouldUpdateScroll={false}
            isTree
            locale={{
              emptyMessage: 'No hay datos para mostrar',
              loading: savingEdition ? 'Guardando...' : 'Cargando...',
            }}
            loading={loadingTable}
            data={tree || []}
            rowClassName={(record) => {
              if (record) {
                const { ID_NAT } = record;
                let className = '';
                if (get(record, 'children.length', null) === 0) {
                  className += 'hide-expand';
                }
                if (ID_NAT === 1) {
                  className = 'green-row';
                } else if (ID_NAT === 3) {
                  className += ' second-level';
                } else if (ID_NAT === 4) {
                  className += ' third-level';
                }

                return className;
              }

              return '';
            }}
            renderTreeToggle={(_icon, _row, expanded) => {
              if (expanded) {
                return <CaretUpOutlined />;
              }
              return <CaretDownOutlined />;
            }}
            expandedRowKeys={expandedRowKeys}
            onExpandChange={(expanded, rowData) => changeExpandedRows(expanded, rowData)}
          >
            {columns(
              activeOptions,
              activeEdition,
              editCell,
              {
                openAccountOfCostEditionModal,
                openFamilyEditionModal,
                changeInfoToCopyUpdatedBudget,
                findAccountOfCostCode,
                advanceDatesData: advanceDatesData ? advanceDatesData[selectedWork] : [],
                setActiveChapterEdition,
                setActiveItemEdition,
                inputsTypesObject,
                access,
              },
              fieldsToSave,
              deleteBudget
            )}
          </Table>
          <NewItem
            reloadTree={reloadTree}
            addChapterToTree={addChapterToTree}
            itemParents={itemParents}
            resourcesData={resourcesData}
            tree={lastSavedTree}
            disabledButton={loadingTable}
            inputsTypes={inputsTypes}
            activeItemEdition={activeItemEdition}
            onClose={() => setActiveItemEdition(null)}
          />
        </Col>
      </Row>
    </div>
  );
};

BudgetControl.propTypes = {
  budget: commonPropTypes.isRequired,
  getBudget: PropTypes.func.isRequired,
  deleteBudget: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  works: PropTypes.object.isRequired,
  getAccountOfCost: PropTypes.func.isRequired,
  resetBudget: PropTypes.func.isRequired,
  selectedWork: PropTypes.number.isRequired,
  accountOfCostInfo: commonPropTypes.isRequired,
  production: commonPropTypes.isRequired,
  getAdvanceDates: PropTypes.func.isRequired,
  getInputsTypes: PropTypes.func.isRequired,
  access: PropTypes.object.isRequired,
};

export default connect(
  ({ budget, works, accountOfCost, production }) => ({
    budget,
    selectedWork: works.selectedWork,
    accountOfCostInfo: accountOfCost,
    production,
  }),
  (dispatch) => ({
    getBudget: bindActionCreators(getBudgetAction, dispatch),
    getAccountOfCost: bindActionCreators(getAccountOfCostAction, dispatch),
    resetBudget: bindActionCreators(resetBudgetAction, dispatch),
    getAdvanceDates: bindActionCreators(getAdvanceDatesAction, dispatch),
    getInputsTypes: bindActionCreators(getInputsTypesAction, dispatch),
    deleteBudget: bindActionCreators(deleteBudgetAction, dispatch),
  })
)(BudgetControl);
