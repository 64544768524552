import _ from 'lodash';
import { message } from 'antd';
import api, { getHeaders } from '../utils/api';
import {
  DELETE_AREA,
  DELETE_POSITION,
  GET_AREAS_AND_POSITIONS,
  SET_ERROR_AREAS_AND_POSITIONS,
  SET_AREA,
  SET_POSITION,
  UPDATE_AREA,
  UPDATE_POSITION,
} from '../reducers/areasAndPositions';
import store from '../store';

export const setError = (error = '') => ({
  type: SET_ERROR_AREAS_AND_POSITIONS,
  payload: { error },
});

export const getAreasAndPositions = () => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
    };
    const errorMessage = 'No se cargaron éxitosamente las áreas y los cargos.';
    api
      .post(`/api/Productividad/GetAreasCargos`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: GET_AREAS_AND_POSITIONS, payload: { areasAndPositions: res.data } });
          resolve(res.data);
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });

export const getAreasAndPositionsAttendance = () => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR: _.get(store.getState(), 'works.selectedWork', ''),
    };
    const errorMessage = 'No se cargaron éxitosamente las áreas y los cargos.';
    api
      .post(`/api/Productividad/GetAreasCargosAsistencia`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: GET_AREAS_AND_POSITIONS, payload: { areasAndPositions: res.data } });
          resolve(res.data);
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });

export const saveArea = (areaData) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ...areaData,
    };
    const errorMessage = 'No se pudo guardar la nueva área.';
    api
      .post(`/api/Productividad/SetArea`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          const { ID_ARE } = res.data;
          if (ID_ARE === 0) {
            dispatch(setError(errorMessage));
            message.error(errorMessage, 4);
            reject(res.data);
          } else {
            const area = { ...areaData, EMPRESA: null, ID_ARE, USUARIO: null };

            dispatch({ type: SET_AREA, payload: { area } });
            message.success('Área agregada correctamente', 4);
            resolve(res.data);
          }
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });

export const savePosition = (positionData) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ...positionData,
    };
    const errorMessage = 'No se pudo guardar el nuevo cargo.';

    api
      .post(`/api/Productividad/SetCargo`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          const { ID_CAR } = res.data;
          if (ID_CAR === 0) {
            dispatch(setError(errorMessage));
            message.error(errorMessage, 4);
            reject(res.data);
          } else {
            const position = {
              ...positionData,
              EMPRESA: null,
              USUARIO: null,
              ID_CAR,
            };

            dispatch({ type: SET_POSITION, payload: { position } });
            message.success('Cargo agregado correctamente', 4);
            resolve(res.data);
          }
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });

export const updateArea = (areaData) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ...areaData,
    };
    const errorMessage = 'No se pudo actualizar el área.';
    api
      .post(`/api/Productividad/UpdArea`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          const { ID_ARE } = res.data;
          if (ID_ARE === 0) {
            dispatch(setError(errorMessage));
            message.error(errorMessage, 4);
            reject(res.data);
          } else {
            dispatch({ type: UPDATE_AREA, payload: { area: areaData } });
            message.success('Área actualizada correctamente', 4);
            resolve(res.data);
          }
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });

export const deleteArea = (areaId) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_ARE: areaId,
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
    };
    const errorMessage = 'No se pudo eliminar el área.';
    api
      .post(`/api/Productividad/DelArea`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          const { ID_ARE } = res.data;
          if (ID_ARE === 0) {
            dispatch(setError(errorMessage));
            message.error(errorMessage, 4);
            reject(res.data);
          } else {
            dispatch({ type: DELETE_AREA, payload: { areaId } });
            message.success('Área eliminada correctamente', 4);
            resolve(res.data);
          }
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });

export const updatePosition = (positionData, oldAreaId) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ...positionData,
    };
    const errorMessage = 'No se pudo actualizar el cargo.';
    api
      .post(`/api/Productividad/UpdCargo`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          const { ID_CAR } = res.data;
          if (ID_CAR === 0) {
            dispatch(setError(errorMessage));
            message.error(errorMessage, 4);
            reject(res.data);
          } else {
            dispatch({ type: UPDATE_POSITION, payload: { position: positionData, oldAreaId } });
            message.success('Cargo actualizado correctamente', 4);
            resolve(res.data);
          }
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });

export const deletePosition = (areaId, positionId) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_CAR: positionId,
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
    };
    const errorMessage = 'No se pudo eliminar el cargo.';
    api
      .post(`/api/Productividad/DelCargo`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          const { ID_CAR } = res.data;
          if (ID_CAR === 0) {
            dispatch(setError(errorMessage));
            message.error(errorMessage, 4);
            reject(res.data);
          } else {
            dispatch({ type: DELETE_POSITION, payload: { areaId, positionId } });
            message.success('Cargo eliminado correctamente', 4);
            resolve(res.data);
          }
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });
