import _ from 'lodash';
import { message } from 'antd';
import api, { getHeaders } from '../utils/api';
import {
  BASE_TENDER_FETCHED,
  GET_BASE_TENDER,
  GET_BASE_TENDER_ERROR,
  BASE_TENDER_ADD_MONTH,
  GET_INPUTS_TYPES_GG_TENDER,
  INPUTS_TYPES_GG_FETCHED_TENDER,
  GET_INPUTS_TYPES_GG_TENDER_ERROR,
} from '../reducers/TenderGG';
import store from '../store';

export const baseTenderFetched = (baseTender, workId, attr) => ({
  type: BASE_TENDER_FETCHED,
  baseTender,
  workId,
  attr,
});

export const addMonthTender = (workId) => (dispatch) =>
  dispatch({
    type: BASE_TENDER_ADD_MONTH,
    workId,
  });

export const inputsTypesFetched = (inputsTypes) => ({
  type: INPUTS_TYPES_GG_FETCHED_TENDER,
  inputsTypes,
});

export const getBaseTender = (ID_OBR) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: GET_BASE_TENDER });

    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_PPTO_TIPO: 3,
      ID_OBR,
    };
    api
      .post('/api/GastosGenerales/GetPresupuestoGG', requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch(baseTenderFetched(res.data, ID_OBR));
          resolve(res.data);
        } else {
          dispatch({
            type: GET_BASE_TENDER_ERROR,
          });
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_BASE_TENDER_ERROR,
        });
        reject(err);
      });
  });

export const setBaseTender = (body) => (dispatch) =>
  new Promise((resolve, reject) => {
    const ID_OBR = _.get(store.getState(), 'works.selectedWork', '');
    const errorMessage = 'Error al editar las celdas';
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR,
      ID_PPTO_TIPO: 3,
      ...body,
    };
    api
      .post('api/GastosGenerales/SetPresupuestoGG', requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          message.success('Celdas editadas correctamente', 8);
          dispatch(baseTenderFetched(res.data, ID_OBR));
          resolve(res.data);
        } else {
          message.error(errorMessage);
          reject(res.data);
        }
      })
      .catch((err) => {
        message.error(errorMessage);
        reject(err);
      });
  });

export const getInputsTypesTender = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: GET_INPUTS_TYPES_GG_TENDER });

    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_PPTO_TIPO: 3,
    };
    api
      .post(`/api/Presupuesto/GetTipoInsumosGG`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch(inputsTypesFetched(res.data));
          resolve(res.data);
        } else {
          dispatch({
            type: GET_INPUTS_TYPES_GG_TENDER_ERROR,
          });
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_INPUTS_TYPES_GG_TENDER_ERROR,
        });
        reject(err);
      });
  });

export const deleteRowTender = (ID_ITEM) => (dispatch) =>
  new Promise((resolve, reject) => {
    const ID_OBR = _.get(store.getState(), 'works.selectedWork', '');
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_ITEM,
    };
    api
      .post(`api/Presupuesto/DelItem`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          message.success('Fila eliminada correctamente', 4);
          getBaseTender(ID_OBR)(dispatch);
          resolve();
        } else {
          message.error('Error al eliminar el Fila', 4);
          reject();
        }
      })
      .catch(() => {
        message.error('Error al eliminar el Fila', 4);
        reject();
      });
  });
