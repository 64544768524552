/* eslint-disable react/prop-types */
import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import { TreeList } from '@progress/kendo-react-treelist';
import { useTranslation } from 'react-i18next';
import { TdWrapper } from '../columns';

const ValorizationInfo = ({ record, onCancel, work }) => {
  const { t } = useTranslation();

  if (!record) return null;
  return (
    <Modal
      title={t('advanceControl.valorizationInfo.title')}
      visible
      footer={null}
      onCancel={onCancel}
    >
      <div className="border p-3 rounded">
        <div className="d-flex flex-column mb-1">{`${t('common.work')}: ${work}`}</div>
        <div className="d-flex flex-column mb-1">{`${t('advanceControl.activityName')}: ${
          record.NOMBRE_INS
        }`}</div>
      </div>
      <TreeList
        className="kendo-table"
        style={{
          height: '450px',
          overflow: 'auto',
          width: '450px',
        }}
        tableProps={{
          style: {
            tableLayout: 'fixed',
            width: 450,
          },
        }}
        rowHeight={28}
        scrollable="virtual"
        data={[]}
        noRecords={t('common.dataEmpty')}
        expandField="expanded"
        subItemsField="children"
        columns={[
          {
            field: 'COMIENZO_PROY',
            title: 'C 1',
            width: 150,
            cell: ({ dataItem }) => {
              return (
                <TdWrapper
                  record={dataItem}
                  className={`text-align-center ${dataItem.RUTA_CONTROL === 1 ? 'blue-cell' : ''} `}
                >
                  {dataItem.COMIENZO_PROY}
                </TdWrapper>
              );
            },
          },
          {
            field: 'COMIENZO_PROY',
            title: 'C 2',
            width: 150,
            cell: ({ dataItem }) => {
              return (
                <TdWrapper
                  record={dataItem}
                  className={`text-align-center ${dataItem.RUTA_CONTROL === 1 ? 'blue-cell' : ''} `}
                >
                  {dataItem.COMIENZO_PROY}
                </TdWrapper>
              );
            },
          },
          {
            field: 'COMIENZO_PROY',
            title: 'C 3',
            width: 150,
            cell: ({ dataItem }) => {
              return (
                <TdWrapper
                  record={dataItem}
                  className={`text-align-center ${dataItem.RUTA_CONTROL === 1 ? 'blue-cell' : ''} `}
                >
                  {dataItem.COMIENZO_PROY}
                </TdWrapper>
              );
            },
          },
        ]}
      />
    </Modal>
  );
};

ValorizationInfo.propTypes = {
  record: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  work: PropTypes.object.isRequired,
};

export default ValorizationInfo;
