import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import qs from 'querystring';
import _ from 'lodash';
import update from 'immutability-helper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LoadingOutlined } from '@ant-design/icons';
import Table from './Table';
import Header from './Header';
import { setInformationFromAdvanceUrl as setInformationFromAdvanceUrlAction } from '../../actions/auth';
import {
  getAdvanceDates as getAdvanceDatesAction,
  getEnclosures as getEnclosuresAction,
  getEnclosuresAndItems as getEnclosuresAndItemsAction,
  getEnclosuresTree as getEnclosuresTreeAction,
  getEnclosuresTypes as getEnclosuresTypesAction,
  getItems as getItemsAction,
  getItemsTree as getItemsTreeAction,
} from '../../actions/advanceMatrix';
import './index.scss';

const AdvanceMatrix = ({
  advanceMatrix,
  auth,
  getAdvanceDates,
  getEnclosures,
  getEnclosuresAndItems,
  getEnclosuresTree,
  getEnclosuresTypes,
  getItems,
  getItemsTree,
  location,
  setInformationFromAdvanceUrl,
  works,
}) => {
  const [dataTable, setDataTable] = useState([]);
  const [maxHeaderLength, setMaxHeaderLength] = useState(0);
  const [headerTable, setHeaderTable] = useState([]);
  const [fixedColumn, setFixedColumn] = useState([]);
  const [loading, setLoading] = useState(false);
  const [advanceDate, setAdvanceDate] = useState(null);
  const [itemsToShow, setItemsToShow] = useState([]);
  const [enclosureInTreeToShow, setEnclosureInTreeToShow] = useState([]);
  const [itemsTreeToShow, setItemsTreeToShow] = useState([]);
  const [enclosureTypesToShow, setEnclosureTypesToShow] = useState([]);
  const [loadAdvance, setLoadAdvance] = useState(false);

  const { foco_client, foco_token, foco_username, foco_work_id } = qs.parse(
    location.search.replace('?', '')
  );
  const {
    advanceDates,
    enclosures,
    enclosuresAndItems,
    enclosuresTree,
    enclosuresTypes,
    items,
    itemsTree,
  } = advanceMatrix;

  useEffect(() => {
    if (foco_token) {
      setInformationFromAdvanceUrl(foco_client, foco_token, foco_username, foco_work_id);
      localStorage.setItem('foco_token', foco_token);
    }
  }, []);

  useEffect(() => {
    if (auth.token && auth.user.username && works.selectedWork) {
      if (!enclosures) {
        getEnclosures();
      }

      if (!items) {
        getItems();
      }

      if (!advanceDates) {
        getAdvanceDates();
      }

      if (!itemsTree) {
        getItemsTree();
      }

      if (!enclosuresTree) {
        getEnclosuresTree();
      }

      if (!enclosuresTypes) {
        getEnclosuresTypes();
      }
    }
  }, [auth, works]);

  useEffect(() => {
    if (advanceDate && loadAdvance) {
      setLoading(true);

      getEnclosuresAndItems(advanceDate)
        .then(() => {
          setLoadAdvance(false);
          setLoading(false);
        })
        .catch(() => {
          setLoadAdvance(false);
          setLoading(false);
        });
    }
  }, [loadAdvance]);

  useEffect(() => {
    if (enclosures && items && enclosuresAndItems && advanceDates && !loading && !loadAdvance) {
      const enclosuresAndItemsMap = new Map(
        enclosuresAndItems.map((e) => [
          `${e.ID_UCO}_${e.ID_INS}`,
          {
            PORCEN_DAV: _.parseInt(e.PORCEN_DAV),
          },
        ])
      );

      let newDataTable = [];
      let newFixedColumn = [];
      const newHeaderTable = [];
      let heightHeader = 0;
      const enclosuresMap = new Map(enclosures.map((e) => [e.ID_UCO, e.NOMBRE_UCO]));

      items.forEach((item) => {
        let newRow = { ...item, cells: [] };

        newFixedColumn.push(item.NOMBRE_INS);

        enclosures.forEach((encl) => {
          const value = enclosuresAndItemsMap.get(`${encl.ID_UCO}_${item.ID_INS}`)
            ? enclosuresAndItemsMap.get(`${encl.ID_UCO}_${item.ID_INS}`).PORCEN_DAV
            : '';

          newRow = update(newRow, {
            cells: {
              $push: [
                {
                  CELL_COLOR:
                    // eslint-disable-next-line no-nested-ternary
                    typeof value === 'number' ? (value === 100 ? '#6aa84f' : '#ffffff') : '#f7f3f8',
                  ID_UCO: encl.ID_UCO,
                  NOMBRE_UCO: encl.NOMBRE_UCO,
                  PORCEN_DAV: enclosuresAndItemsMap.has(`${encl.ID_UCO}_${item.ID_INS}`)
                    ? enclosuresAndItemsMap.get(`${encl.ID_UCO}_${item.ID_INS}`).PORCEN_DAV
                    : '',
                  TEXT_COLOR: '#000',
                },
              ],
            },
          });
        });

        newDataTable.push(newRow);
      });

      if (
        itemsToShow.length !== 0 ||
        enclosureInTreeToShow.length !== 0 ||
        itemsTreeToShow.length !== 0 ||
        enclosureTypesToShow.length !== 0
      ) {
        const filteredData = [];
        const filteredColumn = [];
        let itemsToShowSet = new Set();
        let enclosuresToFilter = new Set();
        const enclosureTypesToFilter = [];
        const newItems = [];
        const newEnclosures = new Set();
        const hasItemFilter = Boolean(itemsToShow.length !== 0 || itemsTreeToShow.length !== 0);
        const hasEnclosureFilter = Boolean(
          enclosureInTreeToShow.length !== 0 || enclosureTypesToShow.length !== 0
        );

        if (enclosureTypesToShow.length !== 0) {
          const enclosureTypesToShowSet = new Set(enclosureTypesToShow);

          enclosures.forEach((e) => {
            if (enclosureTypesToShowSet.has(e.ID_TUC)) {
              enclosureTypesToFilter.push(e.ID_UCO);
            }
          });
        }

        if (enclosureInTreeToShow.length !== 0 && enclosureTypesToFilter.length === 0) {
          enclosuresToFilter = new Set(enclosureInTreeToShow);
        }

        if (enclosureInTreeToShow.length === 0 && enclosureTypesToFilter.length !== 0) {
          enclosuresToFilter = new Set(enclosureTypesToFilter);
        }

        if (enclosureInTreeToShow.length !== 0 && enclosureTypesToFilter.length !== 0) {
          enclosuresToFilter = new Set(
            _.intersection(enclosureInTreeToShow, enclosureTypesToFilter)
          );
        }

        if (itemsToShow.length !== 0 && itemsTreeToShow.length === 0) {
          itemsToShowSet = new Set(itemsToShow);
        }

        if (itemsToShow.length === 0 && itemsTreeToShow.length !== 0) {
          itemsToShowSet = new Set(itemsTreeToShow);
        }

        if (itemsToShow.length !== 0 && itemsTreeToShow.length !== 0) {
          itemsToShowSet = new Set(_.intersection(itemsToShow, itemsTreeToShow));
        }

        const enclosureToShowSet = new Set(enclosuresToFilter);

        for (let i = 0; i < newDataTable.length; i++) {
          let addItem = false;
          const item = newDataTable[i];

          for (let j = 0; j < item.cells.length; j++) {
            const cell = item.cells[j];

            if (
              (itemsToShowSet.size === 0 && !hasItemFilter
                ? true
                : itemsToShowSet.has(item.ID_INS)) &&
              (enclosureToShowSet.size === 0 && !hasEnclosureFilter
                ? true
                : enclosureToShowSet.has(cell.ID_UCO) && typeof cell.PORCEN_DAV === 'number')
            ) {
              if (typeof cell.PORCEN_DAV === 'number') {
                addItem = true;
                newEnclosures.add(cell.ID_UCO);
              }
            }
          }

          if (addItem) {
            newItems.push(item);
          }
        }

        newItems.forEach((item) => {
          filteredData.push(
            update(item, {
              cells: {
                $apply: (cells) => cells.filter((e) => newEnclosures.has(e.ID_UCO)),
              },
            })
          );
          filteredColumn.push(item.NOMBRE_INS);
        });

        newDataTable = filteredData;
        newFixedColumn = filteredColumn;
      }

      if (newDataTable.length) {
        newDataTable[0].cells.forEach((e) => {
          newHeaderTable.push(enclosuresMap.get(e.ID_UCO));
          heightHeader =
            enclosuresMap.get(e.ID_UCO).length > heightHeader
              ? enclosuresMap.get(e.ID_UCO).length
              : heightHeader;
        });
      }

      setDataTable(newDataTable);
      setHeaderTable(newHeaderTable);
      setFixedColumn(newFixedColumn);
      setMaxHeaderLength(heightHeader);
    }
  }, [loading, loadAdvance, enclosuresAndItems]);

  return (
    <div className="advanceMatrix">
      {!enclosures ||
      !items ||
      !advanceDates ||
      !itemsTree ||
      !enclosuresTree ||
      !enclosuresTypes ? (
        <div className="spin">
          <LoadingOutlined />
        </div>
      ) : (
        <>
          <Header
            advanceDate={advanceDate}
            advanceDates={advanceDates}
            enclosuresTree={enclosuresTree}
            enclosureInTreeToShow={enclosureInTreeToShow}
            enclosuresTypes={enclosuresTypes}
            enclosureTypesToShow={enclosureTypesToShow}
            itemsToShow={itemsToShow}
            items={items}
            itemsTree={itemsTree}
            itemsTreeToShow={itemsTreeToShow}
            loading={loading}
            setAdvanceDate={setAdvanceDate}
            setEnclosureInTreeToShow={setEnclosureInTreeToShow}
            setEnclosureTypesToShow={setEnclosureTypesToShow}
            setItemsToShow={setItemsToShow}
            setItemsTreeToShow={setItemsTreeToShow}
            setLoadAdvance={setLoadAdvance}
          />
          <Table
            advanceDate={advanceDate}
            dataTable={dataTable}
            fixedColumn={fixedColumn}
            fixedColumnWidth={300}
            header={headerTable}
            heightHeader={maxHeaderLength * 9}
            loading={loading}
          />
        </>
      )}
    </div>
  );
};

AdvanceMatrix.propTypes = {
  advanceMatrix: PropTypes.shape({
    advanceDates: PropTypes.array,
    enclosures: PropTypes.array,
    enclosuresAndItems: PropTypes.array,
    enclosuresTree: PropTypes.array,
    enclosuresTypes: PropTypes.array,
    items: PropTypes.array,
    itemsTree: PropTypes.array,
  }).isRequired,
  auth: PropTypes.shape({
    user: PropTypes.object,
    token: PropTypes.string,
  }).isRequired,
  getAdvanceDates: PropTypes.func.isRequired,
  getEnclosures: PropTypes.func.isRequired,
  getEnclosuresAndItems: PropTypes.func.isRequired,
  getEnclosuresTree: PropTypes.func.isRequired,
  getEnclosuresTypes: PropTypes.func.isRequired,
  getItems: PropTypes.func.isRequired,
  getItemsTree: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  setInformationFromAdvanceUrl: PropTypes.func.isRequired,
  works: PropTypes.shape({
    selectedWork: PropTypes.number,
  }).isRequired,
};

export default connect(
  (store) => ({
    advanceMatrix: store.advanceMatrix,
    auth: store.auth,
    works: store.works,
  }),
  (dispatch) => ({
    getAdvanceDates: bindActionCreators(getAdvanceDatesAction, dispatch),
    getEnclosures: bindActionCreators(getEnclosuresAction, dispatch),
    getEnclosuresAndItems: bindActionCreators(getEnclosuresAndItemsAction, dispatch),
    getEnclosuresTree: bindActionCreators(getEnclosuresTreeAction, dispatch),
    getEnclosuresTypes: bindActionCreators(getEnclosuresTypesAction, dispatch),
    getItems: bindActionCreators(getItemsAction, dispatch),
    getItemsTree: bindActionCreators(getItemsTreeAction, dispatch),
    setInformationFromAdvanceUrl: bindActionCreators(setInformationFromAdvanceUrlAction, dispatch),
  })
)(AdvanceMatrix);

// http://localhost:8096/advance-matrix?foco_token=iBdrLT4Xep9wHqfgqDBwLzT7LDdzAO81zW9C9J1TKSwBLh6lc72J6bSdGRlvOTWr2aBBjP_m1iwSr2LO_CAfIIXxV3_00Cvdthh7DyzNl2AVc411XYGajz4xdq8bmoqEV3LhMZRztJIVVIHW44qdFYgvtMrB1G8iqcI4lW_V57-Tt0NNgDTzyGbrhT6T_gTTI0FWtxLmYEgzVxFgzKKR8pjLY4HBo2F8lq8IFNq2AF0&foco_username=j.marin&foco_client=DEMO+EEPPCLOUD&foco_work_id=68
