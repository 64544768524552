import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import moment from 'moment';
import { Row, Col, Button } from 'antd';
import 'moment/locale/es';
import locale from 'antd/es/date-picker/locale/es_ES';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import update from 'immutability-helper';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import DatePickerWithTopLabel from '../../components/DatePickerWithTopLabel';
import Table from '../../components/TableExtend';
import TreeFilter from '../../components/BudgetProductionTreeFilter';

import ViewOptionsRadio from '../../components/ViewOptionsRadio';
import ListFilter from '../../components/ListFilter';

import { getCas as getCasAction } from '../../actions/cas';

import columns from './columns';
import { getChildren, getTreeList } from '../../utils/budgetAndProductionHelpers';
import { getInputsTypes as getInputsTypesAction } from '../../actions/budget';
import { getAccountOfCost as getAccountOfCostAction } from '../../actions/accountOfCost';

import './index.scss';

moment.locale('es');

const CAS = ({
  selectedWork,
  cas,
  getCas,
  getInputsTypes,
  inputsTypes,
  accountOfCostInfo,
  getAccountOfCost,
}) => {
  const [active, setActive] = useState(null);
  const [tree, setTree] = useState(null);
  const [expandedRowKeys, setExpandeRowKeys] = useState([]);
  const [isFirstRequest, setIsFirstRequest] = useState(true);
  const [casDate, setCasDate] = useState(moment());
  const [familiesOptions, setFamiliesOptions] = useState(null);
  const [filters, setFilters] = useState({ family: [], accountOfCost: [], extraFilters: [] });
  const [showFilterBar, setShowFilterBar] = useState(false);

  const { data, isFetching } = cas;

  const onChangeDate = (value) => {
    setCasDate(value);
    getCas(value.format('DD-MM-YYYY'), selectedWork);
  };

  const { data: accountOfCostData, isFetching: isFetchingAccountOfCost } = accountOfCostInfo;

  const [activeOption, setActiveOption] = useState(1);

  const createTreeData = (treeData) => {
    if (treeData && treeData.length > 0) {
      let treeObject = {};

      const initialExpandedRowKeys = [];

      treeData.forEach((item) => {
        if (treeObject[item.ID_CC_SUP]) {
          treeObject = update(treeObject, {
            [item.ID_CC_SUP]: {
              $push: [item],
            },
          });
        } else {
          treeObject = update(treeObject, {
            [item.ID_CC_SUP]: {
              $set: [item],
            },
          });
        }
        if (item.TIPO === 1 && !treeObject[item.ID_CC]) {
          treeObject = update(treeObject, {
            [item.ID_CC]: {
              $set: [],
            },
          });
        }

        if (item.TIPO === 1) {
          initialExpandedRowKeys.push(item.ID_CC);
        }
      });

      const newTree = [];
      const paths = {
        [treeObject[0][0].ID_CC]: '0',
      };
      const savePath = (id, path) => {
        if (!paths[id]) {
          paths[id] = path;
        }
      };

      treeObject[0].forEach((current, i) => {
        newTree.push({
          ...current,
          PATH: `${i}`,
          children: getChildren(current.ID_CC, treeObject, `${i}`, savePath, null, 'ID_CC'),
        });
      });

      setTree(newTree);
      setExpandeRowKeys(initialExpandedRowKeys);
    } else if (treeData && treeData.length === 0) {
      setTree([]);
      setExpandeRowKeys([]);
    }
  };

  useEffect(() => {
    if (!accountOfCostData[selectedWork] && !isFetchingAccountOfCost) {
      getAccountOfCost(selectedWork);
    }

    if ((!data[selectedWork] || isFirstRequest) && !isFetching) {
      getCas(moment().format('DD-MM-YYYY'), selectedWork);
      setIsFirstRequest(false);
    } else if (data[selectedWork] && !isFetching) {
      const treeData = data[selectedWork];
      createTreeData(treeData);
    }
  }, [selectedWork]);

  useEffect(() => {
    if (data[selectedWork]) {
      const treeData = data[selectedWork];
      createTreeData(treeData);
    }
  }, [data]);

  useEffect(() => {
    if (!inputsTypes.data && !inputsTypes.isFetching) {
      getInputsTypes();
    } else if (!familiesOptions && inputsTypes.data) {
      setFamiliesOptions(inputsTypes.data);
    }
  }, [inputsTypes]);

  const getCommonProps = (key) => ({
    onClick: () => setActive(key),
    onClose: () => setActive(null),
    show: active === key,
  });

  const changeExpandedRows = (expanded, ID_CC) => {
    if (expanded) {
      setExpandeRowKeys(
        update(expandedRowKeys, {
          $push: [ID_CC],
        })
      );
    } else {
      const index = expandedRowKeys.indexOf(ID_CC);

      if (index >= 0) {
        setExpandeRowKeys(
          update(expandedRowKeys, {
            $splice: [[index, 1]],
          })
        );
      }
    }
  };

  const applyFilters = (filterType, selectedOptions) => {
    if (filterType === 'item') {
      setFilters({ ...filters, items: selectedOptions });
    } else if (filterType === 'chapters') {
      setFilters({ ...filters, chapters: selectedOptions });
    }
    setActive(null);
  };

  return (
    <div className="cas">
      <Row gutter={[30, 30]}>
        <Col className="first-buttons-col" span={24}>
          <div className="date-picker-wrapper">
            <DatePickerWithTopLabel
              value={casDate}
              onChange={onChangeDate}
              label="Fecha CAS al"
              placeholder="MM-YYYY"
              format="MMMM, YYYY"
              disabled={isFetching}
              keepShowingLabel
              picker="month"
              locale={locale}
              monthCellRender={(d) => d.format('MMMM')}
              allowClear={false}
            />
          </div>
          <div>
            <Button type="Default" onClick={() => setShowFilterBar(!showFilterBar)}>
              <i className="fa fa-filter mr-1" />
              {!showFilterBar ? 'Filtros' : 'Ocultar Filtros'}
            </Button>
          </div>
          <div className="viewoptionsRadio-cas">
            <ViewOptionsRadio
              updateOption={(opt) => setActiveOption(opt)}
              activeOptionProp={activeOption}
              options={['Contrato Vigente', 'Contrato Forecast']}
              {...getCommonProps('view')}
            />
          </div>
        </Col>
      </Row>
      {showFilterBar && (
        <Row gutter={[30, 30]}>
          <Col className={`second-buttons-col ${!tree ? 'blocked' : ''}`} span={24}>
            <TreeFilter
              allowSelectAll={false}
              title="Filtros Cuentas de Costos"
              subtitle="Lista de Cuentas"
              buttonTitle="Cuenta"
              data={accountOfCostData[selectedWork]}
              elementTitleKey="NOMBRE_COSTO_ITEM"
              elementKey="ID_ITEM_COSTO"
              showSearch
              extraKey="ES_CUENTA_COSTO"
              onSave={(selectedOptions) => applyFilters('accountOfCost', selectedOptions)}
              options={
                accountOfCostData[selectedWork]
                  ? getTreeList(
                      accountOfCostData[selectedWork],
                      0,
                      'ID_ITEM_COSTO',
                      'ID_ITEM_COSTO_SUP'
                    )
                  : []
              }
              {...getCommonProps('account')}
            />
            <ListFilter
              allowSelectAll
              title="Filtro de Naturalezas"
              subtitle="Lista de Naturalezas"
              buttonTitle="Naturaleza"
              elementKey="ID_TIN"
              elementTitleKey="NOMBRE_TIN"
              onSave={(selectedOptions) => applyFilters('family', selectedOptions)}
              options={familiesOptions || []}
              {...getCommonProps('family')}
            />
            <ListFilter
              title="Filtros Adicionales"
              allowSelectAll={false}
              buttonTitle="Más filtros"
              elementTitleKey="TITLE"
              elementKey="ID"
              onSave={() => {}}
              options={[
                {
                  ID: 1,
                  TITLE: 'filtro 1',
                },
              ]}
              showSearch={false}
              {...getCommonProps('variation')}
            />
          </Col>
        </Row>
      )}
      <Row gutter={[30, 30]}>
        <Col span={24} className="mt-3 p-0">
          <Table
            className="common-table"
            height={window.innerHeight - (showFilterBar ? 265 : 180)}
            hover={false}
            rowKey="ID_CC"
            bordered
            headerHeight={75}
            rowHeight={28}
            virtualized
            shouldUpdateScroll={false}
            isTree
            locale={{
              emptyMessage: 'No hay datos para mostrar',
              loading: 'Cargando...',
            }}
            loading={isFetching}
            data={tree || []}
            rowClassName={(record) => {
              if (record) {
                let type = 'level';
                if (record.TIPO === 1) {
                  type = 'green-row';
                } else {
                  type = 'second-level';
                }
                if (!get(record, 'children.length')) {
                  type += ' without-children';
                }
                return type;
              }
              return '';
            }}
            renderTreeToggle={(_icon, _row, expanded) => {
              if (expanded) {
                return <CaretUpOutlined />;
              }
              return <CaretDownOutlined />;
            }}
            expandedRowKeys={expandedRowKeys}
            onExpandChange={(expanded, rowData) => changeExpandedRows(expanded, rowData.ID_CC)}
          >
            {columns(activeOption)}
          </Table>
        </Col>
      </Row>
    </div>
  );
};

CAS.propTypes = {
  selectedWork: PropTypes.number.isRequired,
  cas: PropTypes.object.isRequired,
  getCas: PropTypes.func.isRequired,
  getInputsTypes: PropTypes.func.isRequired,
  inputsTypes: PropTypes.object.isRequired,
  getAccountOfCost: PropTypes.func.isRequired,
  accountOfCostInfo: PropTypes.object.isRequired,
};

export default connect(
  ({ production, works, cas, accountOfCost, budget: { inputsTypes } }) => ({
    production,
    selectedWork: works.selectedWork,
    cas,
    accountOfCostInfo: accountOfCost,
    inputsTypes,
  }),
  (dispatch) => ({
    getCas: bindActionCreators(getCasAction, dispatch),
    getInputsTypes: bindActionCreators(getInputsTypesAction, dispatch),
    getAccountOfCost: bindActionCreators(getAccountOfCostAction, dispatch),
  })
)(CAS);

/*
export default connect(
  ({ budget: { units, inputsTypes } }) => ({
    units,
    inputsTypes,
  }),
  (dispatch) => ({
    getUnits: bindActionCreators(getUnitsAction, dispatch),
    getInputsTypes: bindActionCreators(getInputsTypesAction, dispatch),
  })
)(NewItem);

*/
