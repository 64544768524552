import update from 'immutability-helper';

export const SET_ERROR_ENCLOSURE = 'SET_ERROR_ENCLOSURE';
export const GET_LISTADO_PANEL = 'GET_LISTADO_PANEL';
export const SET_PANEL = 'SET_PANEL';
export const GET_USER = 'GET_USER';
export const GET_GMAIL_ACCOUNT = 'GET_GMAIL_ACCOUNT';
export const SET_GMAIL_ACCOUNT = 'SET_GMAIL_ACCOUNT';
export const SET_UNLINK_GMAIL = 'SET_UNLINK_GMAIL';
export const SET_PASSWORD = 'SET_PASSWORD';
export const GET_PROJECT_USR = 'GET_PROJECT_USR';
export const SET_USER = 'SET_USER';

const initialState = {
  isPanel: null,
  isSavePanel: null,
  isUser: null,
  Account: null,
  answer: null,
  unlink: null,
  key: null,
  project: null,
  request: null,
  isFetching: true,
  error: '',
};

const configuration = (state = initialState, action) => {
  switch (action.type) {
    case SET_ERROR_ENCLOSURE: {
      const { error } = action.payload;
      return update(state, {
        error: { $set: error },
        isFetching: { $set: false },
      });
    }
    case SET_UNLINK_GMAIL: {
      const { unlink } = action.payload;
      return update(state, {
        unlink: { $set: unlink },
        isFetching: { $set: false },
      });
    }
    case SET_USER: {
      const { request } = action.payload;
      return update(state, {
        request: { $set: request },
        isFetching: { $set: false },
      });
    }
    case GET_PROJECT_USR: {
      const { project } = action.payload;
      return update(state, {
        project: { $set: project },
        isFetching: { $set: false },
      });
    }
    case SET_PASSWORD: {
      const { key } = action.payload;
      return update(state, {
        key: { $set: key },
        isFetching: { $set: false },
      });
    }
    case GET_LISTADO_PANEL: {
      const { isPanel } = action.payload;
      return update(state, {
        isPanel: { $set: isPanel },
        isFetching: { $set: false },
      });
    }
    case GET_USER: {
      const { isUser } = action.payload;
      return update(state, {
        isUser: { $set: isUser },
        isFetching: { $set: false },
      });
    }
    case GET_GMAIL_ACCOUNT: {
      const { Account } = action.payload;
      return update(state, {
        Account: { $set: Account },
        isFetching: { $set: false },
      });
    }
    case SET_GMAIL_ACCOUNT: {
      const { answer } = action.payload;
      return update(state, {
        answer: { $set: answer },
        isFetching: { $set: false },
      });
    }
    case SET_PANEL: {
      const { isSavePanel } = action.payload;
      return update(state, {
        isSavePanel: { $set: isSavePanel },
        isFetching: { $set: false },
      });
    }
    default:
      return state;
  }
};
export default configuration;
