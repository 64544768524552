import _ from 'lodash';
import api, { getHeaders } from '../utils/api';
import store from '../store';

import { WORKS_FETCHED, GET_WORKS, GET_WORKS_ERROR, SET_SELECTED_WORK } from '../reducers/works';

export const setWorks = (works) => ({
  type: WORKS_FETCHED,
  works,
});

export const error = () => ({
  type: GET_WORKS_ERROR,
});

export const setSelectedWork = (workId) => (dispatch) => {
  dispatch({ type: SET_SELECTED_WORK, payload: { workId } });
};

export const getWorks = (client) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: GET_WORKS });
    const requestBody = {
      usuario:
        _.get(store.getState(), 'auth.user.username', '') || localStorage.getItem('foco_username'),
      ip: '1.1.1.1',
      dispositivo: 'web',
      empresa: client,
    };
    api
      .post(`/api/Acceso/GetObrasSuite`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch(setWorks(res.data));
          resolve(res.data);
        } else {
          dispatch(error());
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(error());
        reject(err);
      });
  });
