import genericReducer from '../utils';

export const CLIENTS_FETCHED = 'CLIENTS_FETCHED';
export const GET_CLIENTS = 'GET_CLIENTS';
export const GET_CLIENTS_ERROR = 'GET_CLIENTS_ERROR';

const clientsInit = {
  data: null,
  isFetching: true,
  error: false,
};

const clients = (clientsInfo = clientsInit, action) =>
  genericReducer(clientsInfo, action, 'CLIENTS');

export default clients;
