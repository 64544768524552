import React from 'react';
import { Column, HeaderCell, Cell, ColumnGroup } from 'rsuite-table';
import { getFormattedNumber, toFixed } from '../../utils';

import './index.scss';

export const getTotals = (record, key = 'MONTO_PROD_ANT', priceKey = null) => {
  let total = record.TIPO !== 1 ? record[key] * (priceKey ? record[priceKey] : 1) : 0;
  if (record.TIPO === 1) {
    total = record.children.reduce((acc, childrenRecord) => {
      return acc + childrenRecord[key] * (priceKey ? childrenRecord[priceKey] : 1);
    }, 0);
  }
  return total;
};

const columns = (activeOption) => {
  const isCurrentBudget = activeOption === 0;

  const columnsResult = [
    <Column
      resizable
      fixed
      verticalAlign="middle"
      align="center"
      width={90}
      className="border-right"
    >
      <HeaderCell>Código</HeaderCell>
      <Cell dataKey="CODIGO_CC">
        {(record) => {
          if (!record.ID_CC_SUP || record.TIPO === 5) return null;

          return record.CODIGO_CC;
        }}
      </Cell>
    </Column>,
    <Column
      resizable
      fixed
      treeCol
      verticalAlign="middle"
      align="left"
      width={250}
      className="border-right"
    >
      <HeaderCell>Nombre Cuenta de Costo</HeaderCell>
      <Cell dataKey="NOMBRE_CC" />
    </Column>,
    <Column
      resizable
      fixed
      verticalAlign="middle"
      align="center"
      width={120}
      className="border-right"
    >
      <HeaderCell>PU CD</HeaderCell>
      <Cell dataKey="PRECIO_CD" className="align-right">
        {(record) => {
          if (!record.ID_CC_SUP) return null;

          const priceMod = record.CANTIDAD_MOD !== 0 ? record.TOTAL_MOD / record.CANTIDAD_MOD : 0;
          const price = isCurrentBudget ? record.PRECIO_CD : priceMod;

          return (
            <span title={`$${getFormattedNumber(price)}`}>{`$${getFormattedNumber(
              price,
              2,
              true
            )}`}</span>
          );
        }}
      </Cell>
    </Column>,
    <Column
      resizable
      fixed
      verticalAlign="middle"
      align="center"
      width={70}
      className="border-right"
    >
      <HeaderCell>Ud</HeaderCell>
      <Cell dataKey="UM">
        {(record) => {
          if (!record.ID_CC_SUP) return null;

          return record.UM;
        }}
      </Cell>
    </Column>,
    <ColumnGroup
      align="center"
      header={`${isCurrentBudget ? 'Contrato VIGENTE' : 'Contrato Forecast'}`}
    >
      {[
        <Column resizable verticalAlign="middle" width={90}>
          <HeaderCell>Cantidad</HeaderCell>
          <Cell className="align-right">
            {(record) => {
              const qty = isCurrentBudget ? record.CANTIDAD_VIGENTE : record.CANTIDAD_MOD;
              return (
                <span title={`${getFormattedNumber(qty)}`}>{`${getFormattedNumber(qty, 1)}`}</span>
              );
            }}
          </Cell>
        </Column>,
        <Column resizable verticalAlign="middle" align="center" width={140}>
          <HeaderCell>Total CD</HeaderCell>
          <Cell dataKey="TOTAL_VENTA" className="align-right border-right">
            {({ TOTAL_MOD, TOTAL_CD }) => {
              const total = isCurrentBudget ? TOTAL_CD : TOTAL_MOD;
              return (
                <span title={`$${getFormattedNumber(total)}`}>{`$${getFormattedNumber(
                  total,
                  null,
                  true
                )}`}</span>
              );
            }}
          </Cell>
        </Column>,
      ]}
    </ColumnGroup>,
    <ColumnGroup align="center" header="Cantidades de Avance">
      {[
        <Column resizable verticalAlign="middle" align="center" width={90}>
          <HeaderCell>Acum. Anterior</HeaderCell>
          <Cell dataKey="CANT_PROD_ANT" className="align-righ">
            {(record) => {
              const total = isCurrentBudget ? record.CANT_PROD_ANT : record.CANT_PROD_ANT_MOD;
              return (
                <span title={`${getFormattedNumber(total)}`}>{`${getFormattedNumber(
                  total,
                  1
                )}`}</span>
              );
            }}
          </Cell>
        </Column>,
        <Column resizable verticalAlign="middle" align="center" width={90}>
          <HeaderCell>Acum. Actual</HeaderCell>
          <Cell dataKey="CANT_PROD_ACT" className="align-right">
            {(record) => {
              const total = isCurrentBudget ? record.CANT_PROD_ACT : record.CANT_PROD_ACT_MOD;
              return (
                <span title={`${getFormattedNumber(total)}`}>{`${getFormattedNumber(
                  total,
                  1
                )}`}</span>
              );
            }}
          </Cell>
        </Column>,
        <Column resizable verticalAlign="middle" align="center" width={90}>
          <HeaderCell>Periodo</HeaderCell>
          <Cell dataKey="PERIODO_PROD" className="align-right border-right">
            {(record) => {
              const { CANT_PROD_ACT, CANT_PROD_ACT_MOD, CANT_PROD_ANT, CANT_PROD_ANT_MOD } = record;

              const total = toFixed(
                isCurrentBudget
                  ? CANT_PROD_ACT - CANT_PROD_ANT
                  : CANT_PROD_ACT_MOD - CANT_PROD_ANT_MOD
              );

              return (
                <span title={`${getFormattedNumber(total)}`}>{`${getFormattedNumber(
                  total,
                  1
                )}`}</span>
              );
            }}
          </Cell>
        </Column>,
      ]}
    </ColumnGroup>,
    <ColumnGroup align="center" header="Montos de Avances">
      {[
        <Column resizable verticalAlign="middle" align="center" width={140}>
          <HeaderCell>Acum. Anterior</HeaderCell>
          <Cell dataKey="MONTO_PROD_ANT" className="align-right">
            {(record) => {
              const total = isCurrentBudget ? record.MONTO_PROD_ANT : record.MONTO_PROD_ANT_MOD;
              return (
                <span title={`$${getFormattedNumber(total)}`}>{`$${getFormattedNumber(
                  total,
                  null,
                  true
                )}`}</span>
              );
            }}
          </Cell>
        </Column>,
        <Column resizable verticalAlign="middle" align="center" width={140}>
          <HeaderCell>Acum. Actual</HeaderCell>
          <Cell dataKey="MONTO_PROD_ACT" className="align-right">
            {(record) => {
              const total = isCurrentBudget ? record.MONTO_PROD_ACT : record.MONTO_PROD_ACT_MOD;
              return (
                <span title={`$${getFormattedNumber(total)}`}>{`$${getFormattedNumber(
                  total,
                  null,
                  true
                )}`}</span>
              );
            }}
          </Cell>
        </Column>,
        <Column resizable verticalAlign="middle" align="center" width={140}>
          <HeaderCell>Periodo</HeaderCell>
          <Cell dataKey="PERIODO_PROD" className="align-right border-right">
            {(record) => {
              const { MONTO_PROD_ACT, MONTO_PROD_ACT_MOD, MONTO_PROD_ANT, MONTO_PROD_ANT_MOD } =
                record;

              const total = toFixed(
                isCurrentBudget
                  ? MONTO_PROD_ACT - MONTO_PROD_ANT
                  : MONTO_PROD_ACT_MOD - MONTO_PROD_ANT_MOD
              );

              return (
                <span title={getFormattedNumber(total, null, null, '$')}>
                  {getFormattedNumber(total, null, true, '$')}
                </span>
              );
            }}
          </Cell>
        </Column>,
      ]}
    </ColumnGroup>,
    <ColumnGroup align="center" header="Montos de Costo">
      {[
        <Column resizable verticalAlign="middle" align="center" width={140}>
          <HeaderCell>Acum. Anterior</HeaderCell>
          <Cell dataKey="MONTO_COSTO_ANT" className="align-right">
            {(record) => {
              const total = record.MONTO_COSTO_ANT;
              return (
                <span title={`$${getFormattedNumber(total)}`}>{`$${getFormattedNumber(
                  total,
                  null,
                  true
                )}`}</span>
              );
            }}
          </Cell>
        </Column>,
        <Column resizable verticalAlign="middle" align="center" width={140}>
          <HeaderCell>Acum. Actual</HeaderCell>
          <Cell dataKey="MONTO_COSTO_ACT" className="align-right">
            {(record) => {
              const total = record.MONTO_COSTO_ACT;
              return (
                <span title={`$${getFormattedNumber(total)}`}>{`$${getFormattedNumber(
                  total,
                  null,
                  true
                )}`}</span>
              );
            }}
          </Cell>
        </Column>,
        <Column resizable verticalAlign="middle" align="center" width={140}>
          <HeaderCell>Periodo</HeaderCell>
          <Cell dataKey="PERIODO_COSTO" className="align-right border-right">
            {(record) => {
              const { MONTO_COSTO_ACT, MONTO_COSTO_ANT } = record;
              const total = MONTO_COSTO_ACT - MONTO_COSTO_ANT;

              return (
                <span title={getFormattedNumber(total, null, null, '$')}>
                  {getFormattedNumber(total, null, true, '$')}
                </span>
              );
            }}
          </Cell>
        </Column>,
      ]}
    </ColumnGroup>,
    <ColumnGroup align="center" header="Resultado">
      {[
        <Column resizable verticalAlign="middle" align="center" width={160}>
          <HeaderCell>Acum. Anterior </HeaderCell>
          <Cell dataKey="RESULTADO_ACUM_ANT" className="align-right">
            {(record) => {
              const { MONTO_COSTO_ANT, MONTO_PROD_ANT, MONTO_PROD_ANT_MOD } = record;

              const total = toFixed(
                (isCurrentBudget ? MONTO_PROD_ANT : MONTO_PROD_ANT_MOD) - MONTO_COSTO_ANT
              );

              const className = total >= 0 ? 'positive' : 'negative';
              return (
                <span className={className} title={getFormattedNumber(total, null, null, '$')}>
                  {getFormattedNumber(total, null, true, '$')}
                </span>
              );
            }}
          </Cell>
        </Column>,
        <Column resizable verticalAlign="middle" align="center" width={160}>
          <HeaderCell>Acum. Actual</HeaderCell>
          <Cell dataKey="RESULTADO_ACUM_ACT" className="align-right">
            {(record) => {
              const { MONTO_COSTO_ACT, MONTO_PROD_ACT, MONTO_PROD_ACT_MOD } = record;

              const total = toFixed(
                (isCurrentBudget ? MONTO_PROD_ACT : MONTO_PROD_ACT_MOD) - MONTO_COSTO_ACT
              );

              const className = total >= 0 ? 'positive' : 'negative';
              return (
                <span className={className} title={getFormattedNumber(total, null, null, '$')}>
                  {getFormattedNumber(total, null, true, '$')}
                </span>
              );
            }}
          </Cell>
        </Column>,
        <Column resizable verticalAlign="middle" align="center" width={140}>
          <HeaderCell>Periodo</HeaderCell>
          <Cell dataKey="PERIODO_PROD" className="align-right">
            {(record) => {
              const { MONTO_PROD_ACT, MONTO_PROD_ANT, MONTO_PROD_ACT_MOD, MONTO_PROD_ANT_MOD } =
                record;
              const total1 = isCurrentBudget
                ? MONTO_PROD_ACT - MONTO_PROD_ANT
                : MONTO_PROD_ACT_MOD - MONTO_PROD_ANT_MOD;
              const { MONTO_COSTO_ACT, MONTO_COSTO_ANT } = record;
              const total2 = MONTO_COSTO_ACT - MONTO_COSTO_ANT;

              const total = toFixed(total1 - total2);

              const className = total >= 0 ? 'positive' : 'negative';
              return (
                <span className={className} title={getFormattedNumber(total, null, null, '$')}>
                  {getFormattedNumber(total, null, true, '$')}
                </span>
              );
            }}
          </Cell>
        </Column>,
      ]}
    </ColumnGroup>,
  ];
  return columnsResult;
};

export default columns;
