import { AUTH_LOGOUT } from './auth';

export const PROFILE_FETCHED = 'PROFILE_FETCHED';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILE_ERROR = 'GET_PROFILE_ERROR';
export const ADD_NEW_PROFILE = 'ADD_NEW_PROFILE';
export const DELETE_PROFILE = 'DELETE_PROFILE';
export const EDIT_PROFILE = 'EDIT_PROFILE';

export const GET_DATA_TREE_FETCHED = 'GET_DATA_TREE_FETCHED';
export const GET_DATA_TREE_ERROR = 'GET_DATA_TREE_ERROR';
export const GET_DATA_TREE = 'GET_DATA_TREE';

const profileInit = {
  data: null,
  isFetching: true,
  error: false,
  config: {
    data: null,
    isFetching: true,
    error: false,
  },
};

const profile = (profileInfo = profileInit, action) => {
  switch (action.type) {
    case PROFILE_FETCHED:
      return { ...profileInfo, data: action.profile, isFetching: false, error: false };
    case GET_PROFILE_ERROR:
      return { ...profileInfo, data: null, isFetching: false, error: true };
    case GET_PROFILE:
      return {
        ...profileInit,
        isFetching: true,
        error: false,
      };

    case GET_DATA_TREE_FETCHED:
      return {
        ...profileInfo,
        config: { data: action.config, isFetching: false, error: false },
      };
    case GET_DATA_TREE_ERROR:
      return {
        ...profileInfo,
        config: { data: null, isFetching: false, error: true },
      };
    case GET_DATA_TREE:
      return {
        ...profileInfo,
        config: { ...profileInit.config, isFetching: true, error: false },
      };

    case AUTH_LOGOUT:
      return profileInit;

    case ADD_NEW_PROFILE:
      return {
        ...profileInfo,
        data: [...profileInfo.data, action.profile],
      };

    default:
      return profileInfo;
  }
};

export default profile;
