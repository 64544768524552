import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Form, Input, Button, Select, Steps, DatePicker } from 'antd';

import {
  getConfigAction,
  commonPropTypes,
  commonSelectProps,
  normalizeString,
} from '../../../utils';
import { saveProject as saveProjectAction } from '../../../actions/projects';
import FormBox from '../../../components/FormBox';

import './index.scss';

const { Step } = Steps;
const { Option } = Select;

const ProjectForm = ({
  contractType,
  contractCurrency,
  parameterConfig,
  getConfig,
  communes,
  onCancel,
  companies,
  saveProject,
  editData,
  projects,
  role,
}) => {
  const [form] = Form.useForm();
  const [step, setStep] = useState(0);
  const [currencyPreffix, setCurrencyPreffix] = useState('-');
  const [stateForm, setStateForm] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [needsEditAdditionalParameters, setNeedsEditAdditionalParameters] = useState(false);

  const { isFetching: contractTypeLoading, data: contractTypeData } = contractType;

  const { isFetching: companiesLoading, data: companiesData } = companies;

  const { isFetching: contractCurrencyLoading, data: contractCurrencyData } = contractCurrency;

  const { data: parameterConfigData } = parameterConfig;

  const { isFetching: communesLoading, data: communesData } = communes;

  useEffect(() => {
    if (editData) {
      const newEditData = {
        ...editData,
        FECFINCONT: moment(editData.FECFINCONT, 'DD/MM/YYYY'),
        FECINICONT: moment(editData.FECINICONT, 'DD/MM/YYYY'),
      };
      delete newEditData.PROYECTO_PARAMETRO;
      if (newEditData.ID_COMUNA === 0) newEditData.ID_COMUNA = null;
      if (newEditData.ID_MONEDA_CONTRATO === 0) {
        newEditData.ID_MONEDA_CONTRATO = null;
        if (newEditData.MONTO_CONTRATO === 0) newEditData.MONTO_CONTRATO = null;
        if (newEditData.RETENCION_PRC === 0) newEditData.RETENCION_PRC = null;
        if (newEditData.ANTICIPO_PRC === 0) newEditData.ANTICIPO_PRC = null;
        if (newEditData.MONTO_CONTRATO === 0) newEditData.MONTO_CONTRATO = null;
        if (newEditData.VALOR_CAMBIO === 0) newEditData.VALOR_CAMBIO = null;
      }
      if (newEditData.ID_TIPO_CONTRATO === 0) newEditData.ID_TIPO_CONTRATO = null;

      form.setFieldsValue(newEditData);
      setStateForm(newEditData);
      if (editData.NOMBRE_MONEDA_CONTRATO) {
        setCurrencyPreffix(editData.NOMBRE_MONEDA_CONTRATO);
      }
      setNeedsEditAdditionalParameters(true);
    } else {
      form.resetFields();
    }
  }, [editData]);

  useEffect(() => {
    const additionalParametersData = {};
    if (needsEditAdditionalParameters && step === 2) {
      editData.PROYECTO_PARAMETRO.forEach((parameter) => {
        additionalParametersData[`ADDITIONAL-PARAMETER-${parameter.ID_PARAMETRO}`] =
          parameter.VALOR_PARAMETRO;
      });
      form.setFieldsValue(additionalParametersData);
      setNeedsEditAdditionalParameters(false);
    }
  }, [step]);

  useEffect(() => {
    if (!parameterConfigData) {
      getConfig('PARAMETER_CONFIG', 'parameterConfig', 'GetProyectoConfiguracionParametro');
    }

    if (!contractCurrencyData) {
      getConfig('CONTRACT_CURRENCY', 'contractCurrency', 'GetProyectoMonedaContrato');
    }

    if (!contractTypeData) {
      getConfig('CONTRACT_TYPE', 'contractType', 'GetProyectoTipoContrato');
    }

    if (!communesData) {
      getConfig('COMMUNES', 'communes', 'GetComunas');
    }
  }, []);

  const cancel = () => {
    onCancel();
    if (!editData) {
      form.resetFields();
      setStateForm({});
      setCurrencyPreffix('-');
    }

    setStep(0);
  };

  const submit = (values) => {
    if (step < 2) {
      setStateForm({ ...stateForm, ...values });
      setStep(step + 1);
    } else {
      setIsSaving(true);
      const projectParams = [];

      const parametersData = editData ? editData.PROYECTO_PARAMETRO : parameterConfigData;
      parametersData.forEach((parameter) => {
        const value = values[`ADDITIONAL-PARAMETER-${parameter.ID_PARAMETRO}`];
        projectParams.push({
          ...parameter,
          ID_OBR: editData ? editData.ID_OBR : '',
          ID_OBR_PARAMETRO: editData ? parameter.ID_OBR_PARAMETRO : '',
          VALOR_PARAMETRO: value || '',
        });
      });

      const requestBody = {
        ...stateForm,

        FECFINCONT: moment(stateForm.FECFINCONT).format('DD/MM/YYYY'),
        FECINICONT: moment(stateForm.FECINICONT).format('DD/MM/YYYY'),
        NOMBRE_EMP:
          editData && stateForm.ID_EMP
            ? companiesData.find(({ ID_EMP }) => stateForm.ID_EMP === ID_EMP).NOMBRE_EMP
            : '',
        NOMBRE_COMUNA:
          editData && stateForm.ID_COMUNA
            ? communesData.find(({ ID_COMUNA }) => stateForm.ID_COMUNA === ID_COMUNA).NOMBRE_COMUNA
            : '',
        NOMBRE_TIPO_CONTRATO:
          editData && stateForm.ID_TIPO_CONTRATO
            ? contractTypeData.find(
                ({ ID_TIPO_CONTRATO }) => stateForm.ID_TIPO_CONTRATO === ID_TIPO_CONTRATO
              ).NOMBRE_TIPO_CONTRATO
            : '',

        NOMBRE_MONEDA_CONTRATO:
          editData && stateForm.ID_MONEDA_CONTRATO
            ? contractCurrencyData.find(
                ({ ID_MONEDA_CONTRATO }) => stateForm.ID_MONEDA_CONTRATO === ID_MONEDA_CONTRATO
              ).NOMBRE_MONEDA_CONTRATO
            : '',
        VIGENTE_OBR: editData ? editData.VIGENTE_OBR : '',
        PROYECTO_PARAMETRO: projectParams,
        ID_OBR: editData ? editData.ID_OBR : '',
      };

      saveProject(requestBody, editData ? 'edit' : 'create')
        .then(() => {
          cancel();
          setIsSaving(false);
        })
        .catch(() => {
          setIsSaving(false);
        });
    }
  };

  return (
    <div className="project-form">
      <Steps className="s" size="small" current={step}>
        <Step />
        <Step />
        <Step />
      </Steps>
      <Form
        initialValues={{
          requiredMark: 'optional',
        }}
        requiredMark="optional"
        form={form}
        layout="vertical"
        onFinish={submit}
        scrollToFirstError
      >
        {step === 0 && (
          <FormBox title="Datos Proyecto">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Por favor seleccione una Empresa',
                },
              ]}
              required
              label="Empresa"
              name="ID_EMP"
            >
              <Select
                disabled={companiesLoading || role !== 'editor'}
                loading={companiesLoading}
                {...commonSelectProps}
              >
                {(companiesData || []).map(({ ID_EMP, NOMBRE_EMP }) => (
                  <Option value={ID_EMP}>{NOMBRE_EMP}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Nombre Proyecto"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un Nombre',
                },
                {
                  validator: async (obj, value) => {
                    const companyId = form.getFieldValue('ID_EMP');
                    const nameAlreadyExists = projects.data.find((p) => {
                      const condition =
                        p.ID_EMP === companyId &&
                        normalizeString(value) === normalizeString(p.NOMBRE_OBR);
                      return (
                        (!editData && condition) ||
                        (editData && condition && p.ID_OBR !== editData.ID_OBR)
                      );
                    });
                    if (nameAlreadyExists) {
                      return Promise.reject(
                        new Error(
                          'Ya existe un proyecto con este nombre para la empresa seleccionada'
                        )
                      );
                    }

                    return Promise.resolve();
                  },
                },
              ]}
              name="NOMBRE_OBR"
              required
            >
              <Input disabled={role !== 'editor'} />
            </Form.Item>
            <Form.Item initialValue="" required label="Nombre Abreviado" name="NOMABR_OBR">
              <Input disabled={role !== 'editor'} />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Por favor seleccione una fecha de inicio',
                },
                {
                  validator: async (obj, value) => {
                    const initDate = moment(value).format('YYYY-MM-DD');
                    const endDate = form.getFieldValue('FECFINCONT');
                    const formattedEndDate = endDate && moment(endDate).format('YYYY-MM-DD');

                    if (endDate && formattedEndDate < initDate) {
                      return Promise.reject(
                        new Error('La fecha de inicio no puede ser mayor a la fecha de fin')
                      );
                    }

                    return Promise.resolve();
                  },
                },
              ]}
              label="Inicio Contractual"
              name="FECINICONT"
              required
            >
              <DatePicker
                disabled={role !== 'editor'}
                placeholder="dd/mm/aaaa"
                format="DD/MM/YYYY"
              />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Por favor seleccione una fecha de término',
                },
                {
                  validator: async (obj, value) => {
                    const endDate = moment(value).format('YYYY-MM-DD');
                    const startDate = form.getFieldValue('FECINICONT');
                    const formattedStartDate = startDate && moment(startDate).format('YYYY-MM-DD');

                    if (startDate && formattedStartDate > endDate) {
                      return Promise.reject(
                        new Error('La fecha de fin no puede ser menor a la fecha de inicio')
                      );
                    }

                    return Promise.resolve();
                  },
                },
              ]}
              label="Término Contractual"
              name="FECFINCONT"
              required
            >
              <DatePicker
                disabled={role !== 'editor'}
                placeholder="dd/mm/aaaa"
                format="DD/MM/YYYY"
              />
            </Form.Item>
            <Form.Item
              initialValue=""
              required
              label="Cantidad Meses Gastos Generales"
              name="CANTIDAD_MESES_GG"
            >
              <Input min={0} type="number" />
            </Form.Item>
            <Form.Item
              initialValue=""
              required
              label="Administrador del Proyecto"
              name="DIRECTOR_OBR"
            >
              <Input disabled={role !== 'editor'} />
            </Form.Item>
            <Form.Item initialValue="" required label="Gerente del Proyecto" name="GERENTE_PROY">
              <Input disabled={role !== 'editor'} />
            </Form.Item>
            <Form.Item initialValue="" required label="Dirección" name="DIRECCION_OBR">
              <Input disabled={role !== 'editor'} />
            </Form.Item>
            <Form.Item initialValue="" required label="Comuna" name="ID_COMUNA">
              <Select
                disabled={communesLoading || role !== 'editor'}
                loading={communesLoading}
                {...commonSelectProps}
              >
                {(communesData || []).map(({ ID_COMUNA, NOMBRE_COMUNA }) => (
                  <Option value={ID_COMUNA}>{NOMBRE_COMUNA}</Option>
                ))}
              </Select>
            </Form.Item>
          </FormBox>
        )}

        {step === 1 && (
          <FormBox title="Datos del Contrato">
            <Form.Item initialValue="" required label="Tipo Contrato" name="ID_TIPO_CONTRATO">
              <Select
                disabled={contractTypeLoading || role !== 'editor'}
                loading={contractTypeLoading}
                {...commonSelectProps}
              >
                {(contractTypeData || []).map(({ ID_TIPO_CONTRATO, NOMBRE_TIPO_CONTRATO }) => (
                  <Option value={ID_TIPO_CONTRATO}>{NOMBRE_TIPO_CONTRATO}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item initialValue="" required label="Moneda Base" name="ID_MONEDA_CONTRATO">
              <Select
                onChange={(value, { children }) => {
                  setCurrencyPreffix(children);
                }}
                disabled={contractCurrencyLoading || role !== 'editor'}
                loading={contractCurrencyLoading}
                {...commonSelectProps}
              >
                {(contractCurrencyData || []).map(
                  ({ ID_MONEDA_CONTRATO, NOMBRE_MONEDA_CONTRATO }) => (
                    <Option value={ID_MONEDA_CONTRATO}>{NOMBRE_MONEDA_CONTRATO}</Option>
                  )
                )}
              </Select>
            </Form.Item>
            <Form.Item initialValue="" required label="Valor Moneda Base" name="VALOR_CAMBIO">
              <Input
                disabled={!form.getFieldValue('ID_MONEDA_CONTRATO') || role !== 'editor'}
                min={0}
                type="number"
              />
            </Form.Item>
            <Form.Item
              className="contract-amount-form-item"
              initialValue=""
              required
              label="Monto Contrato"
              name="MONTO_CONTRATO"
            >
              <Input
                min={1}
                disabled={!form.getFieldValue('ID_MONEDA_CONTRATO') || role !== 'editor'}
                type="number"
                className="with-prefix"
                prefix={<div className="prefix-label">{currencyPreffix}</div>}
              />
            </Form.Item>
            <Form.Item
              required
              className="percentage-form-item"
              label="% Anticipo"
              initialValue=""
              name="ANTICIPO_PRC"
              rules={[
                {
                  validator: async (obj, value) => {
                    if (value > 100 || value < 0) {
                      return Promise.reject(new Error('El valor debe estar entre 0 y 100'));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                type="number"
                className="with-prefix"
                min={0}
                disabled={!form.getFieldValue('ID_MONEDA_CONTRATO') || role !== 'editor'}
                max={100}
                prefix={<div className="prefix-label">%</div>}
              />
            </Form.Item>
            <Form.Item
              initialValue=""
              className="percentage-form-item"
              rules={[
                {
                  validator: async (obj, value) => {
                    if (value > 100 || value < 0) {
                      return Promise.reject(new Error('El valor debe estar entre 0 y 100'));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              required
              label="% Retención"
              name="RETENCION_PRC"
            >
              <Input
                type="number"
                min={0}
                max={100}
                disabled={!form.getFieldValue('ID_MONEDA_CONTRATO') || role !== 'editor'}
                className="with-prefix"
                prefix={<div className="prefix-label">%</div>}
              />
            </Form.Item>
            <Form.Item required label="Código ERP" name="CODIGO_ERP">
              <Input disabled={role !== 'editor'} />
            </Form.Item>
          </FormBox>
        )}
        {step === 2 && (
          <FormBox title="Configuración Proyecto">
            <div className="additional-parameters">
              <div className="custom-field gray">
                <div>Parámetro</div>
                <div>Valor</div>
              </div>
              {parameterConfigData.map(({ ID_PARAMETRO, DESCRIPCION_PARAMETRO }) => (
                <div key={ID_PARAMETRO} className="custom-field values">
                  <div>{DESCRIPCION_PARAMETRO}</div>
                  <div>
                    <Form.Item name={`ADDITIONAL-PARAMETER-${ID_PARAMETRO}`} label="" required>
                      <Input disabled={role !== 'editor'} />
                    </Form.Item>
                  </div>
                </div>
              ))}
            </div>
          </FormBox>
        )}
        <div className="buttons-wrapper">
          <Button className="cancel-button" onClick={() => cancel()} disabled={isSaving}>
            Salir
          </Button>
          <div className="steps-control-buttons">
            {step > 0 && (
              <Button disabled={isSaving} onClick={() => setStep(step - 1)}>
                Anterior (Paso {step})
              </Button>
            )}
            <Button
              disabled={isSaving || (role !== 'editor' && step === 2)}
              loading={isSaving}
              type="primary"
              htmlType="submit"
            >
              {step < 2 ? `Siguiente (Paso ${step + 2})` : 'Finalizar'}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

ProjectForm.propTypes = {
  contractCurrency: commonPropTypes.isRequired,
  contractType: commonPropTypes.isRequired,
  parameterConfig: commonPropTypes.isRequired,
  communes: commonPropTypes.isRequired,
  companies: commonPropTypes.isRequired,
  getConfig: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  editData: PropTypes.object,
  saveProject: PropTypes.func.isRequired,
  projects: PropTypes.array.isRequired,
  role: PropTypes.string.isRequired,
};

ProjectForm.defaultProps = {
  editData: null,
};

export default connect(
  ({ contractCurrency, contractType, parameterConfig, communes, companies, projects }) => ({
    contractCurrency,
    contractType,
    parameterConfig,
    communes,
    companies,
    projects,
  }),
  (dispatch) => ({
    getConfig: bindActionCreators(getConfigAction, dispatch),
    saveProject: bindActionCreators(saveProjectAction, dispatch),
  })
)(ProjectForm);
