import React from 'react';
import { Column, HeaderCell, Cell } from 'rsuite-table';

import './index.scss';

const columns = (helpers, access) => {
  const columnsResult = [
    ...(access && access.OPCIONES_CAS_EDITAR
      ? [
          <Column
            resizable
            fixed
            verticalAlign="middle"
            align="center"
            width={100}
            className="border-right"
          >
            <HeaderCell />
            <Cell dataKey="CODIGO_CC">
              {(record) => {
                return (
                  <div className="edit-cell">
                    <i className="fas fa-edit" onClick={() => helpers.setActiveChapter(record)} />
                  </div>
                );
              }}
            </Cell>
          </Column>,
        ]
      : []),

    <Column
      resizable
      fixed
      treeCol
      verticalAlign="middle"
      align="left"
      width={300}
      className="border-right"
    >
      <HeaderCell>Nombre</HeaderCell>
      <Cell dataKey="NOMBRE_MENU" />
    </Column>,
    <Column
      resizable
      fixed
      verticalAlign="middle"
      align="center"
      width={100}
      className="border-right"
    >
      <HeaderCell>Orden</HeaderCell>
      <Cell dataKey="ORDEN">
        {(record) => {
          return record.ORDEN;
        }}
      </Cell>
    </Column>,
  ];
  return columnsResult;
};

export default columns;
