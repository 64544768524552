import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'antd';
import * as FileSaver from 'file-saver';
import ExcelJS from 'exceljs';
import moment from 'moment';
import update from 'immutability-helper';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Column, HeaderCell, Cell } from 'rsuite-table';

import {
  SearchIcon,
  normalizeString,
  commonPropTypes,
  getFormattedNumber,
  months,
} from '../../../utils';
import Table from '../../../components/TableExtend';
import AddPaymentState from './AddPaymentState';
import InputWithTopLabel from '../../../components/InputWithTopLabel';
import { getPaymentStates as getPaymentStatesAction } from '../../../actions/paymentState';

import './index.scss';

const columnsKeys = [
  'NUMERO_EP',
  'FECHA_EP',
  'NOMBRE_ESTADO_EP',
  'PRC_ACUM',
  'MONTO_ACUM',
  'PRC_PERIODO',
  'MONTO_PERIODO',
];
const PaymentStatesList = ({ getPaymentStates, paymentState, selectedWork, history, access }) => {
  const [state, setstate] = useState({ filter: '', data: [] });
  const { isFetching, list } = paymentState;
  const { data } = state;
  const lastNumberEp = data && data.length ? _.maxBy(data, 'NUMERO_EP').NUMERO_EP + 1 : 1;
  useEffect(() => {
    if (!_.get(paymentState, `list[${selectedWork}]`, null) && !paymentState.isFetching) {
      getPaymentStates(selectedWork);
    } else if (_.get(paymentState, `list[${selectedWork}]`, null)) {
      const accs = [];
      const totalReadjustment = list[selectedWork].reduce((acc, current) => {
        accs.push(acc);
        return acc + current.REAJUSTE_EP;
      }, 0);

      const newData = list[selectedWork].map((e, i) => {
        return { ...e, REAJUSTE_ACUM: totalReadjustment - accs[i] };
      });
      setstate(update(state, { data: { $set: newData } }));
    }
  }, [paymentState, selectedWork]);

  const handleSearch = (value) => {
    if (value !== '') {
      const newData = state.data.filter((record) => {
        return columnsKeys.some((item) => {
          let val = record[item];

          val = normalizeString(`${val}`);
          return val.indexOf(normalizeString(value)) !== -1;
        });
      });
      setstate(update(state, { data: { $set: newData } }));
    } else if (value === '') {
      setstate(update(state, { data: { $set: list[selectedWork] } }));
    }
  };

  const addRowsToWorksheet = (worksheet) => {
    worksheet.properties.defaultColWidth = 100;
    data.forEach((current) => {
      const date = moment(current.FECHA_EP, 'DD-MM-YYYY');
      const month = months.find((e) => e.value === Number(date.format('M')) - 1).name;
      const invoiceDate =
        current.NOMBRE_ESTADO_EP !== 'FACTURADO' ? null : current.FECHA_FACTURACION;
      worksheet.addRow({
        ...current,
        MONTH: `${month} ${date.format('YYYY')}`,
        INVOICE_DATE: invoiceDate,
        TOTAL: current.MONTO_PERIODO + current.REAJUSTE_EP,
      });
      let color = '00FFFFFF';
      if (current.ID_NAT === 3) {
        color = '00F2F2F2';
      } else if (current.ID_NAT === 1) {
        color = '00CCCCCC';
      }

      worksheet.lastRow.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: color },
      };

      worksheet.lastRow.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });
  };

  const exportToExcel = () => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'Conpax';
    workbook.lastModifiedBy = 'Conpax';
    const currentDate = new Date();
    workbook.created = currentDate;
    workbook.modified = currentDate;
    const worksheet = workbook.addWorksheet('sheet', {
      pageSetup: { paperSize: 9, orientation: 'landscape' },
    });

    worksheet.columns = [
      { header: 'EDP', key: 'NUMERO_EP', width: 20 },
      { header: 'Mes de Producción', key: 'MONTH', width: 20 },
      { header: 'Fecha de facturación', key: 'INVOICE_DATE', width: 20 },
      { header: 'Estado', key: 'NOMBRE_ESTADO_EP', width: 20 },
      { header: 'Monto Periodo', key: 'MONTO_PERIODO', width: 20 },
      { header: 'Monton Reajuste', key: 'REAJUSTE_EP', width: 20 },
      { header: 'Monto Total Periodo', key: 'TOTAL', width: 20 },
      { header: 'Monto Acum. EDP', key: 'MONTO_ACUM', width: 20 },
      {
        header: 'Monto Reajuste Acum.',
        key: 'REAJUSTE_ACUM',
        width: 20,
      },
      {
        header: '% Periodo',
        key: 'PRC_PERIODO',
        width: 20,
      },
      {
        header: '% Acumulado',
        key: 'PRC_ACUM',
        width: 20,
      },
    ];

    addRowsToWorksheet(worksheet, data);
    workbook.xlsx.writeBuffer().then(function (buffer) {
      FileSaver.saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        'estados_de_pago.xlsx'
      );
    });
  };

  return (
    <div className="payment-states-list">
      <Row gutter={[30, 30]}>
        <Col className="first-buttons-col " span={24}>
          <InputWithTopLabel
            icon={<SearchIcon />}
            onChange={(value) => handleSearch(value)}
            label="Buscar"
            className="ml-4"
          />

          <AddPaymentState
            list={data}
            history={history}
            lastNumberEp={lastNumberEp}
            access={access}
          />
          <Button onClick={exportToExcel} className="button-printer" type="primary">
            Exportar
          </Button>
        </Col>
        <Col className="table-wrapper" span={24}>
          <Table
            height={window.innerHeight - 225}
            hover={false}
            headerHeight={40}
            className="common-table"
            rowHeight={32}
            rowKey="ID_ITEM"
            bordered
            shouldUpdateScroll={false}
            locale={{
              emptyMessage: 'No hay datos para mostrar',
              loading: 'Cargando...',
            }}
            loading={isFetching}
            data={data || []}
            rowClassName={() => {
              return '';
            }}
          >
            <Column
              resizable
              verticalAlign="middle"
              align="center"
              fixed
              className="border-right"
              width={50}
            >
              <HeaderCell />
              <Cell dataKey="LINK">
                {(record) => (
                  <Link to={`/home/payment_state/${record.ID_EP_ENC}?project=${selectedWork}`}>
                    <i className="fa fa-external-link color-link" />
                  </Link>
                )}
              </Cell>
            </Column>
            <Column
              resizable
              verticalAlign="middle"
              align="center"
              fixed
              className="border-right"
              width={60}
            >
              <HeaderCell>EDP</HeaderCell>
              <Cell dataKey="NUMERO_EP" />
            </Column>
            <Column
              resizable
              verticalAlign="middle"
              align="center"
              fixed
              className="border-right"
              width={140}
            >
              <HeaderCell>Mes de Producción</HeaderCell>
              <Cell dataKey="FECHA_EP">
                {(record) => {
                  try {
                    const date = moment(record.FECHA_EP, 'DD-MM-YYYY');
                    const month = months.find((e) => e.value === Number(date.format('M')) - 1).name;
                    return `${month} ${date.format('YYYY')}`;
                  } catch (error) {
                    return '';
                  }
                }}
              </Cell>
            </Column>
            <Column
              resizable
              verticalAlign="middle"
              align="center"
              fixed
              className="border-right"
              width={140}
            >
              <HeaderCell>Fecha de Facturación</HeaderCell>
              <Cell dataKey="FECHA_FACTURACION">
                {(record) => {
                  if (record.NOMBRE_ESTADO_EP !== 'FACTURADO') return null;
                  return record.FECHA_FACTURACION;
                }}
              </Cell>
            </Column>
            <Column resizable verticalAlign="middle" align="center" fixed className="border-right">
              <HeaderCell>Estado</HeaderCell>
              <Cell dataKey="NOMBRE_ESTADO_EP" />
            </Column>
            <Column
              resizable
              verticalAlign="middle"
              align="center"
              fixed
              className="border-right"
              width={160}
            >
              <HeaderCell>Monto Período</HeaderCell>
              <Cell dataKey="MONTO_PERIODO">
                {({ MONTO_PERIODO }) => (
                  <span title={getFormattedNumber(MONTO_PERIODO, null, false, '$')}>
                    {getFormattedNumber(MONTO_PERIODO, null, true, '$')}
                  </span>
                )}
              </Cell>
            </Column>
            <Column
              width={160}
              resizable
              verticalAlign="middle"
              align="center"
              fixed
              className="border-right"
            >
              <HeaderCell>Monto Reajuste</HeaderCell>
              <Cell dataKey="REAJUSTE_EP">
                {({ REAJUSTE_EP }) => (
                  <span title={getFormattedNumber(REAJUSTE_EP, null, false, '$')}>
                    {getFormattedNumber(REAJUSTE_EP, null, true, '$')}
                  </span>
                )}
              </Cell>
            </Column>
            <Column
              resizable
              verticalAlign="middle"
              align="center"
              fixed
              className="border-right"
              width={160}
              style={{ backgroundColor: 'orange' }}
            >
              <HeaderCell>Monto Total Periodo</HeaderCell>
              <Cell dataKey="MONTO_ACUM">
                {({ MONTO_PERIODO, REAJUSTE_EP }) => {
                  const total = MONTO_PERIODO + REAJUSTE_EP;
                  return (
                    <span title={getFormattedNumber(total, null, false, '$')}>
                      {getFormattedNumber(total, null, true, '$')}
                    </span>
                  );
                }}
              </Cell>
            </Column>
            <Column
              resizable
              verticalAlign="middle"
              align="center"
              fixed
              className="border-right"
              width={160}
            >
              <HeaderCell>Monto Acum. EDP</HeaderCell>
              <Cell dataKey="MONTO_ACUM">
                {({ MONTO_ACUM }) => (
                  <span title={getFormattedNumber(MONTO_ACUM, null, false, '$')}>
                    {getFormattedNumber(MONTO_ACUM, null, true, '$')}
                  </span>
                )}
              </Cell>
            </Column>
            <Column
              width={160}
              resizable
              verticalAlign="middle"
              align="center"
              className="border-right"
            >
              <HeaderCell>Monto Reajuste Acum.</HeaderCell>
              <Cell dataKey="REAJUSTE_EP">
                {({ REAJUSTE_ACUM }) => {
                  return (
                    <span title={getFormattedNumber(REAJUSTE_ACUM, null, false, '$')}>
                      {getFormattedNumber(REAJUSTE_ACUM, null, true, '$')}
                    </span>
                  );
                }}
              </Cell>
            </Column>
            <Column resizable verticalAlign="middle" align="center" className="border-right">
              <HeaderCell>% Período</HeaderCell>
              <Cell dataKey="PRC_PERIODO">
                {({ PRC_PERIODO }) => (
                  <span title={`${getFormattedNumber(PRC_PERIODO)}%`}>{`${getFormattedNumber(
                    PRC_PERIODO,
                    0
                  )}%`}</span>
                )}
              </Cell>
            </Column>
            <Column resizable verticalAlign="middle" align="center" className="border-right">
              <HeaderCell>% Acumulado</HeaderCell>
              <Cell dataKey="PRC_ACUM">
                {({ PRC_ACUM }) => (
                  <span title={`${getFormattedNumber(PRC_ACUM)}%`}>{`${getFormattedNumber(
                    PRC_ACUM,
                    0
                  )}%`}</span>
                )}
              </Cell>
            </Column>
          </Table>
        </Col>
      </Row>
    </div>
  );
};

PaymentStatesList.propTypes = {
  getPaymentStates: PropTypes.func.isRequired,
  paymentState: commonPropTypes.isRequired,
  selectedWork: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  access: PropTypes.object.isRequired,
};

export default connect(
  ({ paymentState, works }) => ({
    paymentState,
    selectedWork: works.selectedWork,
  }),
  (dispatch) => ({
    getPaymentStates: bindActionCreators(getPaymentStatesAction, dispatch),
  })
)(PaymentStatesList);
