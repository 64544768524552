import update from 'immutability-helper';
import { SET_INFORMATION_FROM_PAYMENT_URL } from './auth';

export const SET_ERROR_PAYMENT = 'SET_ERROR_PAYMENT';
export const SET_SELECTED_PAYMENT = 'SET_SELECTED_PAYMENT';

const initialState = {
  data: null,
  isFetching: true,
  error: '',
  selectedPayment: null,
};

const payments = (state = initialState, action) => {
  switch (action.type) {
    case SET_ERROR_PAYMENT: {
      const { error } = action.payload;
      return update(state, {
        error: { $set: error },
        isFetching: { $set: false },
      });
    }

    case SET_SELECTED_PAYMENT: {
      const { id } = action.payload;
      return update(state, {
        selectedPayment: { $set: id },
      });
    }

    case SET_INFORMATION_FROM_PAYMENT_URL: {
      const { paymentId } = action.payload;

      return update(state, {
        selectedPayment: { $set: Number(paymentId) },
      });
    }

    default:
      return state;
  }
};
export default payments;
