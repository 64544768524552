import React from 'react';
import { Column, HeaderCell, Cell, ColumnGroup } from 'rsuite-table';
import { getFormattedNumber } from '../../utils';

import './index.scss';

export const getTotals = (record, key = 'MONTO_PROD_ANT', priceKey = null) => {
  let total = record.TIPO !== 1 ? record[key] * (priceKey ? record[priceKey] : 1) : 0;
  if (record.TIPO === 1) {
    total = record.children.reduce((acc, childrenRecord) => {
      return acc + childrenRecord[key] * (priceKey ? childrenRecord[priceKey] : 1);
    }, 0);
  }
  return total;
};

const columns = (activeOption) => {
  const isCurrentBudget = activeOption === 0;

  const columnsResult = [
    <Column
      fixed
      resizable
      verticalAlign="middle"
      align="center"
      width={90}
      className="border-right"
    >
      <HeaderCell>Item</HeaderCell>
      <Cell dataKey="CODIGO_CC">
        {(record) => {
          if (!record.ID_CC_SUP || record.TIPO === 5) return null;

          return record.CODIGO_CC;
        }}
      </Cell>
    </Column>,
    <Column
      resizable
      treeCol
      verticalAlign="middle"
      align="left"
      width={250}
      fixed
      className="border-right"
    >
      <HeaderCell>Actividad</HeaderCell>
      <Cell dataKey="NOMBRE_CC" />
    </Column>,

    <ColumnGroup
      align="center"
      header={`${isCurrentBudget ? 'Ppto. Estudio' : 'Contrato Forecast'}`}
    >
      {[
        <Column resizable verticalAlign="middle" align="center" width={70} className="border-right">
          <HeaderCell>Ud</HeaderCell>
          <Cell dataKey="ESTUDIO_UM">
            {(record) => {
              if (!record.ID_CC_SUP) return null;

              return record.ESTUDIO_UM;
            }}
          </Cell>
        </Column>,
        <Column resizable verticalAlign="middle" align="center" width={70}>
          <HeaderCell>Cant</HeaderCell>
          <Cell dataKey="ESTUDIO_CANT" className="align-right border-right">
            {(record) => {
              if (record.TIPO === 1) return null;
              const qty = record.ESTUDIO_CANT;
              const numberOfDecimals = record.TIPO === 5 ? 3 : 1;
              return (
                <span title={`${getFormattedNumber(qty)}`}>{`${getFormattedNumber(
                  qty,
                  numberOfDecimals
                )}`}</span>
              );
            }}
          </Cell>
        </Column>,
        <Column resizable verticalAlign="middle" align="center" width={120}>
          <HeaderCell>Monto ($)</HeaderCell>
          <Cell dataKey="ESTUDIO_VALOR" className="align-right border-right">
            {(record) => {
              const value = record.ESTUDIO_VALOR;
              return (
                <span
                  title={`${getFormattedNumber(value, null, false, '$')}`}
                >{`${getFormattedNumber(value, null, true, '$')}`}</span>
              );
            }}
          </Cell>
        </Column>,
      ]}
    </ColumnGroup>,
    <ColumnGroup align="center" header="Ejecutado Período">
      {[
        <Column resizable verticalAlign="middle" align="center" width={100}>
          <HeaderCell>Avance Cant</HeaderCell>
          <Cell dataKey="PERIODO_CANT_AVA" className="align-right">
            {(record) => {
              if (record.TIPO === 1) return null;
              const qty = record.PERIODO_CANT_AVA;
              const numberOfDecimals = record.TIPO === 5 ? 3 : 1;
              return (
                <span title={`${getFormattedNumber(qty)}`}>{`${getFormattedNumber(
                  qty,
                  numberOfDecimals
                )}`}</span>
              );
            }}
          </Cell>
        </Column>,
        <Column resizable verticalAlign="middle" align="center" width={80}>
          <HeaderCell>Costo ($)</HeaderCell>
          <Cell dataKey="PERIODO_COSTO" className="align-right border-right">
            {(record) => {
              const value = record.PERIODO_COSTO;
              return (
                <span
                  title={`${getFormattedNumber(value, null, false, '$')}`}
                >{`${getFormattedNumber(value, null, true, '$')}`}</span>
              );
            }}
          </Cell>
        </Column>,
      ]}
    </ColumnGroup>,
    <ColumnGroup align="center" header="Total Acumulado">
      {[
        <Column resizable verticalAlign="middle" align="center" width={110}>
          <HeaderCell>Avance Cant</HeaderCell>
          <Cell dataKey="ACUMULADO_CANT_AVA" className="align-right">
            {(record) => {
              if (record.TIPO === 1) return null;
              const qty = record.ACUMULADO_CANT_AVA;
              const numberOfDecimals = record.TIPO === 5 ? 3 : 1;
              return (
                <span title={`${getFormattedNumber(qty)}`}>{`${getFormattedNumber(
                  qty,
                  numberOfDecimals
                )}`}</span>
              );
            }}
          </Cell>
        </Column>,
        <Column resizable verticalAlign="middle" align="center" width={120}>
          <HeaderCell>Costo ($)</HeaderCell>
          <Cell dataKey="ACUMULADO_COSTO" className="align-right border-right">
            {(record) => {
              const total = record.ACUMULADO_COSTO;
              return (
                <span
                  title={`${getFormattedNumber(total, null, false, '$')}`}
                >{`${getFormattedNumber(total, null, true, '$')}`}</span>
              );
            }}
          </Cell>
        </Column>,
      ]}
    </ColumnGroup>,
    <ColumnGroup align="center" header="Porcentajes">
      {[
        <Column resizable verticalAlign="middle" width={60}>
          <HeaderCell>% Avance</HeaderCell>

          <Cell dataKey="PORC_AVANCE" className="align-right">
            {(record) => `${getFormattedNumber(record.PORC_AVANCE, 0)}%`}
          </Cell>
        </Column>,
        <Column resizable verticalAlign="middle" width={70}>
          <HeaderCell>% Gastado</HeaderCell>

          <Cell dataKey="PORC_GASTADO" className="align-right border-right">
            {(record) => `${getFormattedNumber(record.PORC_GASTADO, 0)}%`}
          </Cell>
        </Column>,
      ]}
    </ColumnGroup>,
    <ColumnGroup align="center" header="Costos Unitarios">
      {[
        <Column resizable verticalAlign="middle" align="center" width={120}>
          <HeaderCell>Presente Estado </HeaderCell>

          <Cell dataKey="COSTO_UNI_PERIODO" className="align-right">
            {(record) => {
              const total = record.COSTO_UNI_PERIODO;
              return (
                <span
                  title={`${getFormattedNumber(total, null, false, '$')}`}
                >{`${getFormattedNumber(total, null, true, '$')}`}</span>
              );
            }}
          </Cell>
        </Column>,
        <Column resizable verticalAlign="middle" align="center" width={120}>
          <HeaderCell>Acum Anterior</HeaderCell>

          <Cell dataKey="COSTO_UNI_ANT" className="align-right">
            {(record) => {
              const total = record.COSTO_UNI_ANT;
              return (
                <span
                  title={`${getFormattedNumber(total, null, false, '$')}`}
                >{`${getFormattedNumber(total, null, true, '$')}`}</span>
              );
            }}
          </Cell>
        </Column>,
        <Column resizable verticalAlign="middle" align="center" width={120}>
          <HeaderCell>Acum Actual</HeaderCell>

          <Cell dataKey="COSTO_UNI_ACT" className="align-right">
            {(record) => {
              const total = record.COSTO_UNI_ACT;
              return (
                <span
                  title={`${getFormattedNumber(total, null, false, '$')}`}
                >{`${getFormattedNumber(total, null, true, '$')}`}</span>
              );
            }}
          </Cell>
        </Column>,
        <Column resizable verticalAlign="middle" align="center" width={120}>
          <HeaderCell>Estimado Base</HeaderCell>

          <Cell dataKey="COSTO_UNI_BASE" className="align-right border-right">
            {(record) => {
              const total = record.COSTO_UNI_BASE;
              return (
                <span
                  title={`${getFormattedNumber(total, null, false, '$')}`}
                >{`${getFormattedNumber(total, null, true, '$')}`}</span>
              );
            }}
          </Cell>
        </Column>,
      ]}
    </ColumnGroup>,
    <ColumnGroup align="center" header="Gastado Sobre el Histórico">
      {[
        <Column resizable verticalAlign="middle" align="center" width={120}>
          <HeaderCell>A Término</HeaderCell>

          <Cell dataKey="GAST_HIST_TERM" className="align-right">
            {(record) => {
              const total = record.GAST_HIST_TERM;

              return (
                <span
                  title={`${getFormattedNumber(total, null, false, '$')}`}
                >{`${getFormattedNumber(total, null, true, '$')}`}</span>
              );
            }}
          </Cell>
        </Column>,
        <Column resizable verticalAlign="middle" align="center" width={140}>
          <HeaderCell>Periodo</HeaderCell>

          <Cell dataKey="GAST_HIST_PER" className="align-right">
            {(record) => {
              const total = record.GAST_HIST_PER;
              return (
                <span
                  title={`${getFormattedNumber(total, null, false, '$')}`}
                >{`${getFormattedNumber(total, null, true, '$')}`}</span>
              );
            }}
          </Cell>
        </Column>,
        <Column resizable verticalAlign="middle" align="center" width={140}>
          <HeaderCell>Acum Actual</HeaderCell>

          <Cell dataKey="GAST_HIST_ACT" className="align-right border-right">
            {(record) => {
              const total = record.GAST_HIST_ACT;

              return (
                <span
                  title={`${getFormattedNumber(total, null, false, '$')}`}
                >{`${getFormattedNumber(total, null, true, '$')}`}</span>
              );
            }}
          </Cell>
        </Column>,
      ]}
    </ColumnGroup>,
    <ColumnGroup align="center" header="Proyección a Término de Obra">
      {[
        <Column resizable verticalAlign="middle" align="center" width={140}>
          <HeaderCell>Cantidad Saldo Obra</HeaderCell>

          <Cell dataKey="PROY_CANT_SALDO" className="align-right">
            {(record) => {
              const total = record.PROY_CANT_SALDO;
              return <span title={getFormattedNumber(total)}>{getFormattedNumber(total, 1)}</span>;
            }}
          </Cell>
        </Column>,
        <Column resizable verticalAlign="middle" align="center" width={160}>
          <HeaderCell>Costo Unitario Saldo Obra</HeaderCell>

          <Cell dataKey="PROY_CU_SALDO" className="align-right">
            {(record) => {
              const total = record.PROY_CU_SALDO;
              return (
                <span title={`$${getFormattedNumber(total)}`}>{`$${getFormattedNumber(
                  total,
                  null,
                  true
                )}`}</span>
              );
            }}
          </Cell>
        </Column>,
        <Column resizable verticalAlign="middle" align="center" width={130}>
          <HeaderCell>Desviación a Término</HeaderCell>

          <Cell dataKey="PROY_DESV_TERM" className="align-right">
            {(record) => {
              const total = record.PROY_DESV_TERM;

              return (
                <span
                  title={`${getFormattedNumber(total, null, false, '$')}`}
                >{`${getFormattedNumber(total, null, true, '$')}`}</span>
              );
            }}
          </Cell>
        </Column>,
      ]}
    </ColumnGroup>,
  ];
  return columnsResult;
};

export default columns;
