import update from 'immutability-helper';
import { SET_INFORMATION_FROM_ATTENDANCE_URL, SET_INFORMATION_FROM_ADVANCE_URL } from './auth';

export const WORKS_FETCHED = 'WORKS_FETCHED';
export const GET_WORKS = 'GET_WORKS';
export const GET_WORKS_ERROR = 'GET_WORKS_ERROR';
export const SET_SELECTED_WORK = 'SET_SELECTED_WORK';

const initialState = {
  data: null,
  isFetching: false,
  error: '',
  selectedWork: null,
};

const works = (state = initialState, action) => {
  switch (action.type) {
    case WORKS_FETCHED: {
      const { works: data } = action;
      return update(state, {
        data: { $set: data },
        ...(data && data.length && state.selectedWork === null
          ? {
              selectedWork: { $set: Number(data[0].ID_OBR) },
            }
          : {}),
        isFetching: { $set: false },
      });
    }

    case GET_WORKS: {
      return update(state, {
        isFetching: { $set: true },
      });
    }

    case GET_WORKS_ERROR: {
      return update(state, {
        data: { $set: null },
        isFetching: { $set: false },
        error: { $set: true },
      });
    }

    case SET_SELECTED_WORK: {
      const { workId } = action.payload;
      return update(state, {
        selectedWork: { $set: workId },
      });
    }

    case SET_INFORMATION_FROM_ATTENDANCE_URL: {
      const { project } = action.payload;
      return update(state, {
        selectedWork: { $set: Number(project) },
      });
    }

    case SET_INFORMATION_FROM_ADVANCE_URL: {
      const { work } = action.payload;
      return update(state, {
        selectedWork: { $set: Number(work) },
      });
    }

    default:
      return state;
  }
};

export default works;
