import _ from 'lodash';
import { message } from 'antd';
import api, { getHeaders } from '../utils/api';
import {
  OVERHEAD_PROJECTION_FETCHED,
  GET_OVERHEAD_PROJECTION,
  GET_OVERHEAD_PROJECTION_ERROR,
  ADD_MONTH,
} from '../reducers/overheadProjection';
import store from '../store';

export const overheadProjectionFetched = (overheadProjection, workId, attr) => ({
  type: OVERHEAD_PROJECTION_FETCHED,
  overheadProjection,
  workId,
  attr,
});

export const addMonth = (workId) => (dispatch) =>
  dispatch({
    type: ADD_MONTH,
    workId,
  });

export const getOverheadProjection = (FECHA_CORTE, ID_OBR) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: GET_OVERHEAD_PROJECTION });

    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR,
      FECHA_CORTE,
    };
    api
      .post('/api/GastosGenerales/GetProyeccionIndV2', requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch(overheadProjectionFetched(res.data, ID_OBR));
          resolve(res.data);
        } else {
          dispatch({
            type: GET_OVERHEAD_PROJECTION_ERROR,
          });
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_OVERHEAD_PROJECTION_ERROR,
        });
        reject(err);
      });
  });

export const setOverheadProjection = (body) => (dispatch) =>
  new Promise((resolve, reject) => {
    const ID_OBR = _.get(store.getState(), 'works.selectedWork', '');
    const errorMessage = 'Error al editar las celdas';
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR,
      ...body,
    };
    api
      .post('api/GastosGenerales/SetProyeccionInd', requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          message.success('Celdas editadas correctamente', 8);
          dispatch(overheadProjectionFetched(res.data, ID_OBR));
          resolve(res.data);
        } else {
          message.error(errorMessage);
          reject(res.data);
        }
      })
      .catch((err) => {
        message.error(errorMessage);
        reject(err);
      });
  });

export const setOverheadSelect = (FECHA_CORTE, ID_PPTO_TIPO) => (dispatch) =>
  new Promise((resolve, reject) => {
    const ID_OBR = _.get(store.getState(), 'works.selectedWork', '');
    const errorMessage = 'Error al editar las celdas';
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR,
      FECHA_CORTE,
      ID_PPTO_TIPO,
    };
    api
      .post('api/GastosGenerales/SetImportarGG', requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          message.success(
            ID_PPTO_TIPO === 3
              ? 'GG Estudio se ha cargado correctamente'
              : 'GG Cero se ha cargado correctamente',
            8
          );
          getOverheadProjection(FECHA_CORTE, ID_OBR)(dispatch);
          resolve(res.data);
        } else {
          message.error(errorMessage);
          reject(res.data);
        }
      })
      .catch((err) => {
        message.error(errorMessage);
        reject(err);
      });
  });
