import api, { getHeaders } from '../utils/api';

import { CLIENTS_FETCHED, GET_CLIENTS, GET_CLIENTS_ERROR } from '../reducers/clients';

export const setClients = (clients) => ({
  type: CLIENTS_FETCHED,
  clients,
});

export const error = () => ({
  type: GET_CLIENTS_ERROR,
});

export const getClients = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: GET_CLIENTS });
    const requestBody = {
      usuario: localStorage.getItem('foco_username'),
      ip: '1.1.1.1',
      dispositivo: 'web',
    };
    api
      .post(`/api/Acceso/GetClientes`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch(setClients(res.data));
          resolve(res.data);
        } else {
          dispatch(error());
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(error());
        reject(err);
      });
  });
