import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Modal } from 'antd';
import Table from '../../../components/Table';
import NewCalendar from '../NewCalendar';
import { deleteCalendar as deleteCalendarAction } from '../../../actions/calendars';
import './index.scss';

const CalendarList = ({ calendars, deleteCalendar, schedule }) => {
  const [infoToEdit, setInfoToEdit] = useState(null);
  const [infoToDelete, setInfoToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [schedulesInCalendar, setSchedulesInCalendar] = useState([]);

  const canDelete = (record) => {
    const schedulesInTheCalendar = [];
    schedule.forEach((e) => {
      if (e.ID_CALENDARIO === record.ID_CALENDARIO) {
        schedulesInTheCalendar.push(e.NOMBRE_JORNADA);
      }
    });

    if (schedulesInTheCalendar.length) {
      setSchedulesInCalendar(schedulesInTheCalendar);
    } else {
      setInfoToDelete(record);
    }
  };

  const columns = [
    {
      title: '',
      dataIndex: 'operation',
      width: '100px',
      render: (_, record) => {
        return (
          <div className="icons">
            <i className="fa fa-edit edit-icon" onClick={() => setInfoToEdit(record)} />
            <i className="fa fa-trash" onClick={() => canDelete(record)} />
          </div>
        );
      },
    },
    {
      title: 'Nombre',
      render: (_, record) => record.NOMBRE_CALENDARIO.toLowerCase(),
    },
    {
      title: 'Días Feriados',
      dataIndex: 'FERIADOS',
    },
    {
      title: 'Plantilla',
      render: () => {
        return 'Algo';
      },
    },
    {
      title: 'Jornadas',
      render: (_, record) => {
        return <div className="schedule">{record.JORNADAS}</div>;
      },
    },
  ];

  const handleDelete = () => {
    setIsDeleting(true);
    deleteCalendar(infoToDelete.ID_CALENDARIO)
      .then(() => {
        setIsDeleting(false);
        setInfoToDelete(null);
      })
      .catch(() => {
        setIsDeleting(false);
        setInfoToDelete(null);
      });
  };

  return (
    <div className="calendarList">
      <Table
        loading={false}
        dataSource={calendars}
        columnsInfo={columns}
        rowClassName="editable-row"
        showSearch={false}
      />
      <Modal
        title="Eliminando calendario"
        visible={infoToDelete}
        onOk={() => setInfoToDelete(null)}
        onCancel={() => setInfoToDelete(null)}
        footer={[
          <Button key="back" disabled={isDeleting} onClick={() => setInfoToDelete(null)}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            disabled={isDeleting}
            loading={isDeleting}
            type="primary"
            onClick={() => handleDelete()}
          >
            Eliminar
          </Button>,
        ]}
      >
        <p style={{ textAlign: 'center' }}>{`¿Confirma que desea eliminar a ${
          infoToDelete && infoToDelete.NOMBRE_CALENDARIO
        }?`}</p>
      </Modal>
      {infoToEdit && (
        <NewCalendar
          infoToEdit={infoToEdit}
          onClose={() => setInfoToEdit(null)}
          title="Editar Calendario"
        />
      )}
      {schedulesInCalendar.length > 0 && (
        <Modal
          title="Advertencia"
          visible
          onOk={() => setSchedulesInCalendar([])}
          onCancel={() => setSchedulesInCalendar([])}
          footer={null}
        >
          <p>Las siguientes jornadas están asociadas al calendario que se quiere eliminar:</p>
          <div style={{ marginLeft: '20px' }}>
            {schedulesInCalendar.map((e) => (
              <p>{e}</p>
            ))}
          </div>
        </Modal>
      )}
    </div>
  );
};

CalendarList.propTypes = {
  calendars: PropTypes.arrayOf(PropTypes.object).isRequired,
  deleteCalendar: PropTypes.func.isRequired,
  schedule: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default connect(
  () => ({}),
  (dispatch) => ({
    deleteCalendar: bindActionCreators(deleteCalendarAction, dispatch),
  })
)(CalendarList);
