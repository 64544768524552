import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _, { get, cloneDeep } from 'lodash';
import { Row, Col, Button } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import update from 'immutability-helper';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import * as FileSaver from 'file-saver';
import ExcelJS from 'exceljs';
import Table from '../../components/TableExtend';
import ViewOptions from '../../components/ViewOptions';
import ListFilter from '../../components/ListFilter';
import NewAdvance from './NewAdvance';
import SelectWithTopLabel from '../../components/SelectWithTopLabel';
import {
  getProduction as getProductionAction,
  editAdvanceQuantity,
  getAdvanceDates as getAdvanceDatesAction,
} from '../../actions/production';

import ChapterFilter from '../../components/ChapterFilter';
import { getAccountOfCost as getAccountOfCostAction } from '../../actions/accountOfCost';
import columns, { getTotals } from './columns';
import {
  activeEditionsTitles,
  getChildren,
  changeItemFilters,
  getTreeList,
  getNodeObjectToUpdate,
} from '../../utils/budgetAndProductionHelpers';
import TreeFilter from '../../components/BudgetProductionTreeFilter';
import { commonPropTypes } from '../../utils';

import './index.scss';

const addRowsToWorksheet = (worksheet, tree) => {
  worksheet.properties.defaultColWidth = 140;
  tree.forEach((current) => {
    const totalAcc_porc = getTotals(current, 'CANT_AVA', 3);
    const totalMod_porc = current.CANTIDAD_MOD * current.PRECIO_ITEM;
    const TOTAL_AVA_ = getTotals(current, 'CANT_AVA', 3, 'PRECIO_ITEM');
    const TOTAL_VENTA_AVA_ = getTotals(current, 'CANT_AVA', 3, 'PRECIO_ITEM_VENTA');
    const totalPrevAcc_ = getTotals(current, 'CANT_AVA_ANT', 3);
    const totalMod_ = getTotals(current, 'CANTIDAD_MOD', 3);
    const totalAcc = current.PRECIO_ITEM * current.CANT_AVA;
    const totalMod = current.PRECIO_ITEM * current.CANTIDAD_MOD;
    const totalAccAnt = current.PRECIO_ITEM * current.CANT_AVA_ANT;
    const PROD_PORCENTAJE_ = totalPrevAcc_ === 0 ? 0 : (totalPrevAcc_ * 100) / totalMod_;
    const totalPercAcc = totalAcc && totalMod ? (totalAcc * 100) / totalMod : 0;
    const totalPercAccAnt = totalAccAnt && totalMod ? (totalAccAnt * 100) / totalMod : 0;
    const total_AVA = totalPercAcc - totalPercAccAnt;
    const total_PORCENTAJE_AVA = total_AVA;
    let result_;
    if (current.ID_NAT === 3) {
      if (current.TIPO_AVANCE === 1) {
        const totalAcc_ = getTotals(current, 'CANT_AVA', 4, 'PRECIO_ITEM', (e) => e.ID_NAT === 5);
        const totalAccAnt_ = getTotals(
          current,
          'CANT_AVA_ANT',
          4,
          'PRECIO_ITEM',
          (e) => e.ID_NAT === 5
        );
        result_ = (totalAcc_ - totalAccAnt_) * current.CANTIDAD_MOD;
      }
      result_ = (current.CANT_AVA - current.CANT_AVA_ANT) * current.PRECIO_ITEM;
    } else if ((current.ID_NAT === 4 && current.ID_NAT_SUP === 3) || current.ID_NAT === 5) {
      result_ = (current.CANT_AVA - current.CANT_AVA_ANT) * current.PRECIO_ITEM;
    } else {
      const totalAcc_ = getTotals(current, 'CANT_AVA', 3);
      const totalAccAnt_ = getTotals(current, 'CANT_AVA_ANT', 3);
      result_ = (totalAcc_ - totalAccAnt_) * current.CANTIDAD_MOD;
    }

    let result_TOTAL_VENTA;
    if (current.ID_NAT === 3) {
      if (current.TIPO_AVANCE === 1) {
        const totalAccc_ = getTotals(
          current,
          'CANT_AVA',
          4,
          'PRECIO_ITEM_VENTA',
          (e) => e.ID_NAT === 5
        );
        const totalAccAnt_c = getTotals(
          current,
          'CANT_AVA_ANT',
          4,
          'PRECIO_ITEM_VENTA',
          (e) => e.ID_NAT === 5
        );
        result_TOTAL_VENTA = (totalAccc_ - totalAccAnt_c) * current.CANTIDAD_MOD;
      }
      result_TOTAL_VENTA = (current.CANT_AVA - current.CANT_AVA_ANT) * current.PRECIO_ITEM_VENTA;
    } else if ((current.ID_NAT === 4 && current.ID_NAT_SUP === 3) || current.ID_NAT === 5) {
      result_TOTAL_VENTA = (current.CANT_AVA - current.CANT_AVA_ANT) * current.PRECIO_ITEM_VENTA;
    } else {
      const totalAccc_ = getTotals(current, 'CANT_AVA', 3, 'PRECIO_ITEM_VENTA');
      const totalAccAnt_c = getTotals(current, 'CANT_AVA_ANT', 3, 'PRECIO_ITEM_VENTA');
      result_TOTAL_VENTA = (totalAccc_ - totalAccAnt_c) * current.CANTIDAD_MOD;
    }

    worksheet.addRow({
      CODIGO_ITEM: current.CODIGO_ITEM,
      NOMBRE_ITEM: current.NOMBRE_ITEM,
      PRECIO_ITEM: current.PRECIO_ITEM,
      NOMABR_UNI: current.ID_NAT === 1 ? '' : current.NOMABR_UNI,
      PRECIO_ITEM_VENTA: current.PRECIO_ITEM_VENTA,
      CANTIDAD_MOD_: current.CANTIDAD_MOD,
      TOTAL_MOD: current.CANTIDAD_MOD * current.PRECIO_ITEM,
      TOTAL_VENTA: current.CANTIDAD_MOD * current.PRECIO_ITEM_VENTA,
      PORC_AVA: (totalAcc_porc * 100) / totalMod_porc,
      CANT_AVA: current.ID_NAT === 1 ? '' : current.CANT_AVA,
      TOTAL_AVA: TOTAL_AVA_,
      TOTAL_VENTA_AVA: TOTAL_VENTA_AVA_,
      PROD_PORCENTAJE: PROD_PORCENTAJE_,
      CANT_AVA_ANT: current.ID_NAT === 1 ? '' : current.CANT_AVA_ANT,
      TOTAL_ACUM_ANT: getTotals(current, 'CANT_AVA_ANT', 3),
      TOTAL_VENTA_AVA_ANT: getTotals(current, 'CANT_AVA_ANT', 3, 'PRECIO_ITEM_VENTA'),
      PORCENTAJE_AVA: total_PORCENTAJE_AVA,
      CANTIDAD: current.ID_NAT === 1 ? '' : getTotals(current, 'CANT_AVA_ANT', 4, 'PRECIO_ITEM'),
      PROD_TOTAL_PARCIAL: result_,
      PROD_TOTAL_PARCIAL_VENTA: result_TOTAL_VENTA,
      ID_ITEM: current.ID_ITEM,
      ID_ITEM_SUP: current.ID_ITEM_SUP,
      ID_NAT: current.ID_NAT,
    });
    let color = '00FFFFFF';
    if (current.ID_NAT === 3) {
      color = '00F2F2F2';
    } else if (current.ID_NAT === 1) {
      color = '00CCCCCC';
    }

    worksheet.getColumn(4).alignment = { vertical: 'top', horizontal: 'center' };

    worksheet.lastRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: color },
    };

    worksheet.lastRow.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };

    worksheet.lastRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: color },
    };

    worksheet.lastRow.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };

    worksheet.getRow(1).font = {
      color: { argb: 'd4cbcb' },
    };
    worksheet.getRow(1).fill = {
      color: { argb: 'ffffff' },
    };

    if (current.children && current.children.length) {
      addRowsToWorksheet(worksheet, current.children);
    }
  });
};

const Production = ({
  getProduction,
  accountOfCostInfo,
  production,
  selectedWork,
  getAdvanceDates,
  getAccountOfCost,
  access,
}) => {
  const [active, setActive] = useState(null);
  const [tree, setTree] = useState(null);

  const [lastSavedTree, setLastSavedTree] = useState(null);
  const [expandedRowKeys, setExpandeRowKeys] = useState([]);
  const [lastSavedExpandedRowKeys, setLastSavedExpandedRowKeys] = useState(null);
  const [activeEdition, setActiveEdition] = useState(null);
  const [fieldsToSave, setFieldsToSave] = useState([]);
  const [savingEdition, setSavingEdition] = useState(false);
  const [itemFilters, setItemFilters] = useState([]);
  const [itemFilterOptions, setItemFilterOptions] = useState([]);
  const [resourceFilterOptions, setResourceFilterOptions] = useState([]);
  const [hasSomeSwitchChecked, setHasSomeSwitchChecked] = useState(false);
  const [hasFiltersApplied, setHasFiltersApplied] = useState(false);
  const [filters, setFilters] = useState({
    accountOfCost: [],
    items: [],
    resources: [],
    chapters: [],
    activity: {},
  });

  const {
    isFetching,
    data,
    advanceDates: { isFetching: isFetchingAdvanceDates, data: advanceDatesData, lastAddedIndex },
  } = production;

  const [selectedAdvanceDate, setSelectedAdvanceDate] = useState(
    get(advanceDatesData, `[${selectedWork}][${lastAddedIndex}].ID_AVA_ENC`, null)
  );

  const [selectedPreviousAdvanceDate, setSelectedPreviousAdvanceDate] = useState(
    get(advanceDatesData, `[${selectedWork}][${lastAddedIndex + 1}].ID_AVA_ENC`, null)
  );
  const { data: accountOfCostData, isFetching: isFetchingAccountOfCost } = accountOfCostInfo;

  const [activeOptions, setActiveOptions] = useState({
    option0: false,
    option1: false,
    option2: true,
  });

  const [showFilterBar, setShowFilterBar] = useState(false);

  const exportToExcelProduction = () => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'Foco en Obra';
    workbook.lastModifiedBy = 'Foco en Obra';
    workbook.created = new Date();
    workbook.modified = new Date();

    const worksheet = workbook.addWorksheet('sheet', {
      pageSetup: { paperSize: 9, orientation: 'landscape' },
    });

    const dateEp = _.get(advanceDatesData, `${selectedWork}[0].FECHA_AVA`, '...');

    const rowValues = [];

    rowValues[1] = 'Item';
    rowValues[2] = 'Descripción';
    rowValues[3] = 'PU CD';
    rowValues[4] = 'ud';
    rowValues[5] = 'PU Venta';
    rowValues[6] = 'Forecast -> Cantidad';
    rowValues[7] = 'Forecast -> Total CD';
    rowValues[8] = 'Forecast -> Total Venta';
    rowValues[9] = `Producción Acum. al ${dateEp} -> %`;
    rowValues[10] = `Producción Acum. al ${dateEp} -> Cantidad`;
    rowValues[11] = `Producción Acum. al ${dateEp} -> Total CD`;
    rowValues[12] = `Producción Acum. al ${dateEp} -> Total Venta`;
    rowValues[13] = `Producción Acum. anterior al ${dateEp} -> %`;
    rowValues[14] = `Producción Acum. anterior al ${dateEp} -> Cantidad`;
    rowValues[15] = `Producción Acum. anterior al ${dateEp} -> Total CD`;
    rowValues[16] = `Producción Acum. anterior al ${dateEp} -> Total Venta`;
    rowValues[17] = `Producción Parcial -> %`;
    rowValues[18] = `Producción Parcial -> Cantidad`;
    rowValues[19] = `Producción Parcial -> Total CD`;
    rowValues[20] = `Producción Parcial -> Total Venta`;
    rowValues[21] = 'ID_ITEM';
    rowValues[22] = 'ID_ITEM_SUP';
    rowValues[23] = 'ID_NAT';

    worksheet.insertRow(2, rowValues);

    worksheet.columns = [
      { header: 'CODIGO_ITEM', key: 'CODIGO_ITEM', width: 15 },
      { header: 'NOMBRE_ITEM', key: 'NOMBRE_ITEM', width: 40 },
      { header: 'PRECIO_ITEM', key: 'PRECIO_ITEM', width: 20 },
      { header: 'NOMABR_UNI', key: 'NOMABR_UNI', width: 10 },
      { header: 'PRECIO_ITEM_VENTA', key: 'PRECIO_ITEM_VENTA', width: 15 },
      { header: 'CANTIDAD_MOD', key: 'CANTIDAD_MOD_', width: 20 },
      { header: 'TOTAL_MOD', key: 'TOTAL_MOD', width: 20 },
      { header: 'TOTAL_VENTA', key: 'TOTAL_VENTA', width: 20 },
      { header: `PORC_AVA`, key: 'PORC_AVA', width: 35 },
      { header: `CANT_AVA`, key: 'CANT_AVA', width: 38 },
      { header: `TOTAL_AVA`, key: 'TOTAL_AVA', width: 38 },
      {
        header: `TOTAL_VENTA_AVA`,
        key: 'TOTAL_VENTA_AVA',
        width: 35,
      },
      { header: `PROD_PORCENTAJE`, key: 'PROD_PORCENTAJE', width: 35 },
      { header: `CANT_AVA_ANT`, key: 'CANT_AVA_ANT', width: 35 },
      { header: `TOTAL_ACUM_ANT`, key: 'TOTAL_ACUM_ANT', width: 35 },
      {
        header: `TOTAL_VENTA_AVA_ANT`,
        key: 'TOTAL_VENTA_AVA_ANT',
        width: 35,
      },
      { header: `PORCENTAJE_AVA`, key: 'PORCENTAJE_AVA', width: 30 },
      { header: 'CANTIDAD', key: 'CANTIDAD', width: 30 },
      { header: 'PROD_TOTAL_PARCIAL', key: 'PROD_TOTAL_PARCIAL', width: 30 },
      { header: 'PROD_TOTAL_PARCIAL_VENTA', key: 'PROD_TOTAL_PARCIAL_VENTA', width: 30 },
      { header: 'ID_ITEM', key: 'ID_ITEM', width: 10 },
      { header: 'ID_ITEM_SUP', key: 'ID_ITEM_SUP', width: 10 },
      { header: 'ID_NAT', key: 'ID_NAT', width: 10 },
    ];

    addRowsToWorksheet(worksheet, tree);
    workbook.xlsx.writeBuffer().then(function (buffer) {
      FileSaver.saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'test.xlsx');
    });
  };

  useEffect(() => {
    if (advanceDatesData[selectedWork]) {
      setSelectedAdvanceDate(
        get(advanceDatesData, `${selectedWork}[${lastAddedIndex}].ID_AVA_ENC`, null)
      );
      setSelectedPreviousAdvanceDate(
        get(advanceDatesData, `${selectedWork}[${lastAddedIndex + 1}].ID_AVA_ENC`, null)
      );
      setFieldsToSave([]);
    } else {
      getAdvanceDates(selectedWork);
    }

    if (!accountOfCostData[selectedWork] && !isFetchingAccountOfCost) {
      getAccountOfCost(selectedWork);
    }
  }, [advanceDatesData, selectedWork]);

  const getItemNode = (pathArray, index = 0, treeData = tree) => {
    try {
      if (treeData[pathArray[index]].ID_NAT === 3) {
        return treeData[pathArray[index]];
      }
    } catch (error) {
      console.log(error);
    }

    return getItemNode(pathArray, index + 1, treeData[pathArray[index]].children);
  };

  const getItemPercentage = (path) => {
    const pathArray = path.split('.').map((e) => Number(e));
    const record = getItemNode(pathArray, 0);
    const totalAcc = getTotals(record, 'CANT_AVA');
    const totalMod = record.PRECIO_ITEM * record.CANTIDAD_MOD;
    if (totalAcc === 0) return 0;
    return (totalAcc * 100) / totalMod;
  };

  const getParentQuantity = (path) => {
    const pathArray = path.split('.').map((e) => Number(e));
    const record = getItemNode(pathArray, 0);
    return record.CANT_AVA;
  };

  const createTreeData = (treeData) => {
    if (treeData && treeData.length > 0) {
      let treeObject = {};
      const treeDataItemsOptions = [];
      const treeDataResourcesOptions = {};
      const initialExpandedRowKeys = [];

      treeData.forEach((item) => {
        if (treeObject[item.ID_ITEM_SUP]) {
          treeObject = update(treeObject, {
            [item.ID_ITEM_SUP]: {
              $push: [item],
            },
          });
        } else {
          treeObject = update(treeObject, {
            [item.ID_ITEM_SUP]: {
              $set: [item],
            },
          });
        }

        if (item.ID_NAT === 1 || (item.ID_NAT === 3 && item.TIPO_AVANCE === 1)) {
          initialExpandedRowKeys.push(item.ID_ITEM);
        }

        if (item.ID_NAT === 3) {
          treeDataItemsOptions.push(item);
        } else if (item.ID_NAT === 4) {
          if (!treeDataResourcesOptions[item.ID_ITEM]) {
            treeDataResourcesOptions[item.ID_ITEM] = item;
          }
        }
      });

      const newTreeDataResourcesOptions = Object.keys(treeDataResourcesOptions).map(
        (key) => treeDataResourcesOptions[key]
      );

      const newTree = [];
      const paths = {
        [treeObject[0][0].ID_ITEM]: '0',
      };
      const savePath = (id, path) => {
        if (!paths[id]) {
          paths[id] = path;
        }
      };

      treeObject[0].forEach((current, i) => {
        newTree.push({
          ...current,
          PATH: `${i}`,
          children: getChildren(
            current.ID_ITEM,
            treeObject,
            `${i}`,
            savePath,
            null,
            'ID_ITEM',
            true
          ),
        });
      });
      if (!itemFilterOptions.length) {
        setItemFilterOptions(treeDataItemsOptions);
      }
      if (!resourceFilterOptions.length) {
        setResourceFilterOptions(newTreeDataResourcesOptions);
      }
      setTree(newTree);
      if (!hasFiltersApplied) {
        setLastSavedTree(newTree);
      }
      setExpandeRowKeys(initialExpandedRowKeys);
    } else if (treeData && treeData.length === 0) {
      setTree([]);
      setItemFilterOptions([]);
      setResourceFilterOptions([]);
    }
  };

  useEffect(() => {
    if (advanceDatesData[selectedWork] && advanceDatesData[selectedWork].length) {
      getProduction(selectedWork, selectedAdvanceDate, selectedPreviousAdvanceDate);
    } else {
      setTree([]);
      setItemFilterOptions([]);
      setResourceFilterOptions([]);
    }
  }, [selectedAdvanceDate, selectedPreviousAdvanceDate, selectedWork]);

  useEffect(() => {
    const treeData = get(data, `${selectedWork}.${selectedAdvanceDate}`);
    createTreeData(treeData);
  }, [data]);

  const getCommonProps = (key) => ({
    onClick: () => setActive(key),
    onClose: () => setActive(null),
    show: active === key,
  });

  const editCell = (fieldsToUpdate) => {
    setFieldsToSave(
      update(fieldsToSave, {
        $apply: (fields) => {
          let newFields = [...fields];

          fieldsToUpdate.forEach((current) => {
            if (current.ID_NAT === 3) current.ID_ITEM_SUP = 0;

            const currentIndex = newFields.findIndex(
              (e) => e.ID_ITEM === current.ID_ITEM && e.ID_ITEM_SUP === current.ID_ITEM_SUP
            );

            if (currentIndex !== -1) {
              newFields = update(newFields, {
                [currentIndex]: { $merge: current },
              });
            } else {
              newFields = update(newFields, {
                $push: [current],
              });
            }

            if (current.TIPO_AVANCE === 0 && current.ID_NAT === 3) {
              newFields = update(newFields, {
                $apply: (childrens) =>
                  childrens.filter(
                    (currentChildren) => currentChildren.ID_ITEM_SUP !== current.ID_ITEM
                  ),
              });
            }
          });

          return newFields;
        },
      })
    );

    let newTree = [...tree];

    fieldsToUpdate.forEach((element) => {
      if (element.TIPO_AVANCE && !expandedRowKeys.includes(element.ID_ITEM)) {
        setExpandeRowKeys(
          update(expandedRowKeys, {
            $push: [element.ID_ITEM],
          })
        );
      }
      const path = element.PATH.split('.');
      const updatedNode = getNodeObjectToUpdate(path, 0, { $merge: element });

      newTree = update(newTree, updatedNode);
    });

    setTree(newTree);
  };

  const saveEdition = () => {
    if (fieldsToSave.length) {
      const requestBody = fieldsToSave.map(
        ({ ID_ITEM, COMENTARIO_AVA, CANT_AVA, ID_ITEM_SUP, TIPO_AVANCE }) => ({
          ID_ITEM,
          COMENTARIO_AVA,
          CANT_AVA,
          ID_ITEM_SUP,
          TIPO_AVANCE,
        })
      );
      setSavingEdition(true);
      editAdvanceQuantity(requestBody, selectedAdvanceDate)
        .then(() => {
          let newTree = cloneDeep(tree);

          fieldsToSave.forEach((element) => {
            if (element.ORIGINAL_TIPO_AVANCE !== undefined) {
              const path = element.PATH.split('.');
              const updatedNode = getNodeObjectToUpdate(path, 0, {
                $merge: { ORIGINAL_TIPO_AVANCE: undefined },
              });

              newTree = update(newTree, updatedNode);
            }
          });
          setSavingEdition(false);
          setActiveEdition(null);
          setTree(newTree);
          setLastSavedTree(newTree);
          setFieldsToSave([]);
          setHasSomeSwitchChecked(false);

          setLastSavedExpandedRowKeys(null);
        })
        .catch(() => setSavingEdition(false));
    } else {
      setActiveEdition(null);
      setLastSavedExpandedRowKeys(null);
      setHasSomeSwitchChecked(false);
      setLastSavedTree(tree);
    }
  };

  const activeEditMode = (key, optionKey) => {
    setActiveEdition(key);
    setActiveOptions({ ...activeOptions, [optionKey]: true });
  };

  useEffect(() => {
    if (tree) {
      const { accountOfCost, items, resources, chapters, activity } = filters;
      const toFilter = {
        PARTIDAS: items.map((id) => ({ ID_ITEM: id })),
        RECURSOS: resources.map((id) => ({ ID_ITEM: id })),
        CUENTAS: accountOfCost.map(({ CODIGO_COSTO_ITEM }) => ({ CODIGO_COSTO_ITEM })),
        CAPITULOS: chapters.map((id) => ({ ID_ITEM: id })),
        CON_AVANCE_PARTIDAS: activity.item ? 1 : 0,
        CON_AVANCE_RECURSO: activity.resource ? 1 : 0,
        CON_AVANCE_PARCIAL: activity.partial ? 1 : 0,
      };
      getProduction(selectedWork, selectedAdvanceDate, selectedPreviousAdvanceDate, toFilter);
    }
  }, [filters]);

  const applyFilters = (filterType, selectedOptions) => {
    if (filterType === 'accountOfCost') {
      setFilters({
        ...filters,
        accountOfCost: selectedOptions.filter(({ ES_CUENTA_COSTO }) => ES_CUENTA_COSTO),
      });
    } else if (filterType === 'resource') {
      setFilters({ ...filters, resources: selectedOptions });
    } else if (filterType === 'item') {
      setFilters({ ...filters, items: selectedOptions });
    } else if (filterType === 'chapters') {
      setFilters({ ...filters, chapters: selectedOptions });
    } else if (filterType === 'activity') {
      const activity = {
        item: selectedOptions.includes(1),
        resource: selectedOptions.includes(2),
        partial: selectedOptions.includes(3),
      };
      setFilters({ ...filters, activity });
    }

    if (!hasFiltersApplied) {
      setHasFiltersApplied(true);
    }

    setActive(null);
  };

  const changeExpandedRows = (expanded, ID_ITEM) => {
    if (expanded) {
      setExpandeRowKeys(
        update(expandedRowKeys, {
          $push: [ID_ITEM],
        })
      );
    } else {
      const index = expandedRowKeys.indexOf(ID_ITEM);

      if (index >= 0) {
        setExpandeRowKeys(
          update(expandedRowKeys, {
            $splice: [[index, 1]],
          })
        );
      }
    }
  };

  const getSelectedPreviousAdvanceDateOptions = () => {
    const initialOptions = advanceDatesData[selectedWork] || [];

    const selectedAdvanceDateIndex = initialOptions.findIndex(
      (e) => e.ID_AVA_ENC === selectedAdvanceDate
    );

    const result = [];
    initialOptions.forEach(({ FECHA_AVA, ID_AVA_ENC }, index) => {
      if (index > selectedAdvanceDateIndex) {
        result.push({
          name: FECHA_AVA,
          value: ID_AVA_ENC,
        });
      }
    });

    if (!result.length && selectedPreviousAdvanceDate !== null) {
      setSelectedPreviousAdvanceDate(null);
    }

    return result;
  };

  const previousAdvanceDateOptions = getSelectedPreviousAdvanceDateOptions();

  return (
    <div className="production-control">
      <Row gutter={[30, 30]}>
        {!!access.CONTROL_PRODUCCION_AJUSTAR && (
          <Col className="first-buttons-col" span={24}>
            {activeEdition ? (
              <div className="edit-buttons">
                <h4>{activeEditionsTitles[activeEdition]}</h4>
                <div className="control-buttons">
                  <Button
                    onClick={() => {
                      setExpandeRowKeys(lastSavedExpandedRowKeys);
                      setLastSavedExpandedRowKeys(null);
                      setActiveEdition(null);
                      setTree(lastSavedTree);
                      setFieldsToSave([]);
                      setHasSomeSwitchChecked(false);
                    }}
                    disabled={savingEdition}
                  >
                    Cancelar
                  </Button>
                  <Button
                    disabled={savingEdition || (fieldsToSave.length === 0 && !hasSomeSwitchChecked)}
                    type="primary"
                    onClick={saveEdition}
                  >
                    Guardar
                  </Button>
                </div>
              </div>
            ) : (
              <>
                <SelectWithTopLabel
                  label="Producción al"
                  className="production-select"
                  loading={isFetchingAdvanceDates}
                  disabled={
                    isFetchingAdvanceDates ||
                    isFetching ||
                    !advanceDatesData[selectedWork] ||
                    !advanceDatesData[selectedWork].length
                  }
                  value={selectedAdvanceDate}
                  keepShowingLabel
                  onChange={(value) => {
                    const index = advanceDatesData[selectedWork].findIndex(
                      (e) => e.ID_AVA_ENC === value
                    );
                    if (index < advanceDatesData[selectedWork].length - 1) {
                      setSelectedPreviousAdvanceDate(
                        advanceDatesData[selectedWork][index + 1].ID_AVA_ENC
                      );
                    } else if (index === advanceDatesData[selectedWork].length - 1) {
                      setSelectedPreviousAdvanceDate(null);
                    }
                    setSelectedAdvanceDate(value);
                  }}
                  options={(advanceDatesData[selectedWork] || []).map(
                    ({ FECHA_AVA, ID_AVA_ENC }) => ({
                      name: FECHA_AVA,
                      value: ID_AVA_ENC,
                    })
                  )}
                />

                <SelectWithTopLabel
                  label="Producción anterior al"
                  className={`production-select last ${'with-margin'}`}
                  keepShowingLabel
                  loading={isFetchingAdvanceDates}
                  disabled={
                    isFetchingAdvanceDates || isFetching || !previousAdvanceDateOptions.length
                  }
                  value={selectedPreviousAdvanceDate}
                  onChange={(value) => setSelectedPreviousAdvanceDate(value)}
                  options={previousAdvanceDateOptions}
                />

                <Button
                  type="primary"
                  loading={production.isFetching}
                  onClick={() =>
                    getProduction(selectedWork, selectedAdvanceDate, selectedPreviousAdvanceDate)
                  }
                >
                  Actualizar
                </Button>
                {!!access.CONTROL_PRODUCCION_CREAR_AVANCE && (
                  <NewAdvance
                    advanceDates={advanceDatesData[selectedWork]}
                    selectedAdvanceDate={selectedAdvanceDate}
                    selectedWork={selectedWork}
                  />
                )}
                {!!access.CONTROL_PRODUCCION_AJUSTAR && (
                  <Button
                    type="primary"
                    onClick={() => {
                      setLastSavedExpandedRowKeys(expandedRowKeys);
                      activeEditMode('editProduction', 'option0');
                    }}
                  >
                    Ajustar Producción
                  </Button>
                )}
                <Button type="primary" onClick={exportToExcelProduction}>
                  Exportar
                </Button>
                <Button type="Default" onClick={() => setShowFilterBar(!showFilterBar)}>
                  <i className="fa fa-filter mr-1" />
                  {!showFilterBar ? 'Filtros' : 'Ocultar Filtros'}
                </Button>
                <div>
                  <ViewOptions
                    defaultActiveOptions={activeOptions}
                    updateOptions={(opt) => setActiveOptions(opt)}
                    activeOptionsProps={activeOptions}
                    options={[
                      'Producción Parcial',
                      'Producción Acumulada Anterior',
                      'Mostrar Total Venta',
                      'Mostrar CD',
                    ]}
                    {...getCommonProps('view')}
                  />
                </div>
              </>
            )}
          </Col>
        )}
      </Row>
      {showFilterBar && (
        <Row gutter={[30, 30]}>
          <Col
            className={`second-buttons-col ${!tree || isFetchingAccountOfCost ? 'blocked' : ''}`}
            span={24}
          >
            <ChapterFilter
              title="Filtro Capítulos"
              tree={lastSavedTree || []}
              buttonTitle="Capítulo"
              defaultExpandItemValues={
                lastSavedTree && lastSavedTree.length ? [lastSavedTree[0].ID_ITEM] : []
              }
              onSave={(selectedOptions) => applyFilters('chapters', selectedOptions)}
              {...getCommonProps('chapters')}
            />
            <ListFilter
              allowSelectAll
              title="Filtro Partidas"
              subtitle="Lista de Partidas"
              onChange={(value, isChecked) =>
                changeItemFilters(value, isChecked, {
                  callback: setItemFilters,
                  filters: itemFilters,
                  options: itemFilterOptions,
                })
              }
              buttonTitle="Partida"
              elementKey="ID_ITEM"
              elementTitleKey="NOMBRE_ITEM"
              onSave={(selectedOptions) => applyFilters('item', selectedOptions)}
              options={itemFilterOptions}
              {...getCommonProps('item')}
            />
            <ListFilter
              allowSelectAll
              title="Filtro de Recursos"
              subtitle="Lista de Recursos"
              buttonTitle="Recurso"
              elementKey="ID_ITEM"
              elementTitleKey="NOMBRE_ITEM"
              onSave={(selectedOptions) => applyFilters('resource', selectedOptions)}
              options={resourceFilterOptions}
              {...getCommonProps('resource')}
            />
            <TreeFilter
              allowSelectAll={false}
              title="Filtros Cuentas de Costos"
              subtitle="Lista de Cuentas"
              buttonTitle="Cuenta"
              data={accountOfCostData[selectedWork]}
              elementTitleKey="NOMBRE_COSTO_ITEM"
              elementKey="ID_ITEM_COSTO"
              showSearch
              extraKey="ES_CUENTA_COSTO"
              onSave={(selectedOptions) => applyFilters('accountOfCost', selectedOptions)}
              options={
                accountOfCostData[selectedWork]
                  ? getTreeList(
                      accountOfCostData[selectedWork],
                      0,
                      'ID_ITEM_COSTO',
                      'ID_ITEM_COSTO_SUP'
                    )
                  : []
              }
              {...getCommonProps('account')}
            />
            <ListFilter
              singleSelection
              title="Filtros Adicionales"
              allowSelectAll={false}
              buttonTitle="Más filtros"
              elementTitleKey="TITLE"
              elementKey="ID"
              onSave={(selectedOptions) => applyFilters('activity', selectedOptions)}
              options={[
                {
                  ID: 1,
                  TITLE: 'Sólo Actividades con Avance Acumulado Hasta la fecha',
                },
                {
                  ID: 2,
                  TITLE: 'Sólo Actividades con Avance Acumulado por Recurso Hasta la fecha',
                },
                {
                  ID: 3,
                  TITLE: 'Sólo Actividades con Avance Parcial Hasta la Fecha',
                },
              ]}
              showSearch={false}
              {...getCommonProps('variation')}
            />
          </Col>
        </Row>
      )}
      <Row gutter={[30, 30]}>
        <Col className="production-col p-0 mt-3" span={24}>
          <Table
            height={window.innerHeight - (showFilterBar ? 243 : 160)}
            hover={false}
            className="common-table production-table"
            rowKey="ID_ITEM"
            bordered
            headerHeight={75}
            rowHeight={28}
            virtualized
            shouldUpdateScroll={false}
            isTree
            locale={{
              emptyMessage: 'No hay datos para mostrar',
              loading: savingEdition ? 'Guardando...' : 'Cargando...',
            }}
            loading={
              (!tree && advanceDatesData[selectedWork] && advanceDatesData[selectedWork].length) ||
              isFetching ||
              savingEdition ||
              isFetchingAccountOfCost ||
              isFetchingAdvanceDates
            }
            data={tree || []}
            rowClassName={(record) => {
              if (record) {
                let type = '';
                const { ID_NAT } = record;
                if (ID_NAT === 1) {
                  type = 'green-row';
                } else if (ID_NAT === 3) {
                  type = 'second-level';
                } else if (ID_NAT === 4) {
                  type = 'third-level';
                }
                return `${type} ${!record.TIPO_AVANCE && record.ID_NAT === 3 ? 'hideExpand' : ''} `;
              }
              return '';
            }}
            renderTreeToggle={(icon, row, expanded) => {
              if (expanded) {
                return <CaretUpOutlined />;
              }
              return <CaretDownOutlined />;
            }}
            expandedRowKeys={expandedRowKeys}
            onExpandChange={(expanded, rowData) =>
              changeExpandedRows(expanded, rowData.ID_ITEM, activeEdition === 'editProduction')
            }
          >
            {columns(
              activeOptions,
              activeEdition,
              editCell,
              {
                changeExpandedRows,
                getItemPercentage,
                getParentQuantity,
                getItemNode,
                selectedAdvanceDate:
                  advanceDatesData[selectedWork] &&
                  (
                    advanceDatesData[selectedWork].find(
                      ({ ID_AVA_ENC }) => ID_AVA_ENC === selectedAdvanceDate
                    ) || {}
                  ).FECHA_AVA,
                selectedPreviousAdvanceDate:
                  advanceDatesData[selectedWork] &&
                  (
                    advanceDatesData[selectedWork].find(
                      ({ ID_AVA_ENC }) => ID_AVA_ENC === selectedPreviousAdvanceDate
                    ) || {}
                  ).FECHA_AVA,
              },
              fieldsToSave
            )}
          </Table>
        </Col>
      </Row>
    </div>
  );
};

Production.propTypes = {
  production: commonPropTypes.isRequired,
  getProduction: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  works: PropTypes.object.isRequired,
  resetProduction: PropTypes.func.isRequired,
  getAdvanceDates: PropTypes.func.isRequired,
  selectedWork: PropTypes.number.isRequired,
  getAccountOfCost: PropTypes.func.isRequired,
  accountOfCostInfo: commonPropTypes.isRequired,
  access: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default connect(
  ({ production, works, accountOfCost }) => ({
    production,
    selectedWork: works.selectedWork,
    accountOfCostInfo: accountOfCost,
  }),
  (dispatch) => ({
    getProduction: bindActionCreators(getProductionAction, dispatch),
    getAdvanceDates: bindActionCreators(getAdvanceDatesAction, dispatch),
    getAccountOfCost: bindActionCreators(getAccountOfCostAction, dispatch),
  })
)(Production);
