import React, { useState } from 'react';
import { Input, Select, Button, Modal, Form } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
  saveArea as saveAreaAction,
  updateArea as updateAreaAction,
} from '../../../actions/areasAndPositions';
import { normalizeString, normalizeStringToApi } from '../../../utils';
import './index.scss';

const NewArea = ({
  areaId,
  areasAndPositions,
  oldName,
  oldProject,
  onClose,
  saveArea,
  selectedWork,
  title,
  updateArea,
  works,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [form] = Form.useForm();
  const submit = (values) => {
    setIsSaving(true);
    const { area, project } = values;

    if (areaId) {
      const areaData = {
        ID_ARE: areaId,
        ID_OBR: Number(project),
        NOMBRE_ARE: normalizeStringToApi(area),
      };

      updateArea(areaData)
        .then(() => {
          onClose();
          setIsSaving(false);
        })
        .catch(() => {
          setIsSaving(false);
        });
    } else {
      const projectName = works.find((e) => e.ID_OBR === project).NOMBRE_OBR;
      const areaData = {
        CARGOS_ASOCIADOS: [],
        ID_ARE: '',
        ID_OBR: Number(project),
        NOMBRE_ARE: normalizeStringToApi(area),
        NOMBRE_OBR: projectName,
      };

      saveArea(areaData)
        .then(() => {
          onClose();
          setIsSaving(false);
        })
        .catch(() => {
          setIsSaving(false);
        });
    }
  };

  return (
    <Modal
      title={title}
      visible
      onOk={() => onClose()}
      onCancel={() => onClose()}
      width={400}
      footer={null}
      wrapClassName="areaContainer"
    >
      <Form layout="vertical" form={form} onFinish={submit}>
        <Form.Item
          name="project"
          label="Proyecto"
          initialValue={oldProject || String(selectedWork)}
        >
          <Select disabled>
            {works.map((opt) => (
              <Select.Option value={opt.ID_OBR} key={`optionInNewArea${opt.ID_OBR}`}>
                {opt.NOMBRE_OBR}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="area"
          label="Nombre Área"
          initialValue={oldName || ''}
          rules={[
            {
              required: true,
              message: 'Por favor ingrese un área',
            },
            {
              validator: async (obj, value) => {
                if (!areaId || oldName !== value) {
                  if (
                    areasAndPositions.some(
                      (e) =>
                        normalizeString(e.NOMBRE_ARE) === normalizeString(value) &&
                        e.ID_OBR === Number(form.getFieldValue('project'))
                    )
                  ) {
                    return Promise.reject(new Error('Ya existe un área con este nombre'));
                  }
                }

                if (!/^[a-zA-Z\u00C0-\u024F0-9 ]*$/.test(value)) {
                  return Promise.reject(new Error('No se permiten caracteres especiales'));
                }

                if (value.length && value.trim().length === 0) {
                  return Promise.reject(new Error('No se permiten solo espacios vacios'));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input maxLength={200} />
        </Form.Item>
        <div className="buttons">
          <Form.Item>
            <Button disabled={isSaving} onClick={() => onClose()}>
              Cancelar
            </Button>
          </Form.Item>
          <Form.Item>
            <Button disabled={isSaving} loading={isSaving} type="primary" htmlType="submit">
              Aceptar
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

NewArea.defaultProps = {
  areaId: null,
  oldName: '',
  oldProject: null,
  title: 'Nueva Área',
};

NewArea.propTypes = {
  areaId: PropTypes.number,
  areasAndPositions: PropTypes.array.isRequired,
  oldName: PropTypes.string,
  oldProject: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  saveArea: PropTypes.func.isRequired,
  selectedWork: PropTypes.number.isRequired,
  title: PropTypes.string,
  updateArea: PropTypes.func.isRequired,
  works: PropTypes.array.isRequired,
};

export default connect(
  (store) => ({
    areasAndPositions: store.areasAndPositions.data,
    selectedWork: store.works.selectedWork,
    works: store.works.data,
  }),
  (dispatch) => ({
    saveArea: bindActionCreators(saveAreaAction, dispatch),
    updateArea: bindActionCreators(updateAreaAction, dispatch),
  })
)(NewArea);
