import React, { useEffect, useState } from 'react';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import { Button, Switch } from 'antd';

import './index.scss';

const SwitchFilter = ({
  buttonTitle,
  classNameToPrimaryButton,
  onClick,
  onClose,
  onSave,
  options,
  show,
  title,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (show) {
      setSelectedOptions(options);
    }
  }, [show]);

  const handleOnSave = () => {
    const optionsToSave = [];

    selectedOptions.forEach((e, i) => {
      if (e.value) {
        optionsToSave.push(i);
      }
    });
    onSave(optionsToSave);
  };

  return (
    <div className="switch-filter">
      <Button
        className={`main-button ${classNameToPrimaryButton}`}
        onClick={() => onClick()}
        type="default"
      >
        {buttonTitle}
      </Button>

      {show && (
        <div className={`options-container `}>
          <div className="options-header">
            <div />
            <span>{title}</span>
            <i className="fa fa-times" onClick={() => onClose()} />
          </div>

          <div className="options-content">
            {selectedOptions.map((option, index) => (
              <div className="option" key={`option-${option.name}`}>
                <Switch
                  className="switch"
                  checked={option.value}
                  onChange={(checked) =>
                    setSelectedOptions(
                      update(selectedOptions, { [index]: { value: { $set: checked } } })
                    )
                  }
                />
                <span className="optionName">{option.name}</span>
              </div>
            ))}
          </div>

          <div className="buttons">
            <Button type="default" onClick={() => onClose()}>
              Cancelar
            </Button>
            <Button
              onClick={() => handleOnSave()}
              type="primary"
              className={classNameToPrimaryButton}
            >
              Aplicar
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

SwitchFilter.defaultProps = {
  classNameToPrimaryButton: '',
};

SwitchFilter.propTypes = {
  buttonTitle: PropTypes.string.isRequired,
  classNameToPrimaryButton: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default SwitchFilter;
