import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LoadingOutlined } from '@ant-design/icons';
import { getCalendars as getCalendarsAction } from '../../actions/calendars';
import { getSchedule as getScheduleAction } from '../../actions/schedule';
import { normalizeString } from '../../utils';
import Header from './Header';
import List from './List';
import './index.scss';

const Calendar = ({ calendars, getCalendars, getSchedule, schedule, selectedWork }) => {
  const [filters, setFilters] = useState({ name: '' });

  useEffect(() => {
    getCalendars(selectedWork);
    getSchedule();
    setFilters({ name: '' });
  }, [selectedWork]);

  const handleFilters = (filter, value) =>
    setFilters(
      update(filters, {
        [filter]: { $set: value },
      })
    );

  let filteredCalendars = [];

  if (calendars.data) {
    filteredCalendars = calendars.data.filter((e) =>
      normalizeString(e.NOMBRE_CALENDARIO).includes(normalizeString(filters.name))
    );
  }

  return (
    <>
      {!calendars.data || !schedule.data ? (
        <div className="spin">
          <LoadingOutlined />
        </div>
      ) : (
        <>
          <Header handleFilters={handleFilters} filters={filters} />
          <List calendars={filteredCalendars} schedule={schedule.data} />
        </>
      )}
    </>
  );
};

Calendar.propTypes = {
  calendars: PropTypes.shape({
    data: PropTypes.array,
    isFetching: PropTypes.bool,
  }).isRequired,
  getCalendars: PropTypes.func.isRequired,
  getSchedule: PropTypes.func.isRequired,
  schedule: PropTypes.shape({ data: PropTypes.array }).isRequired,
  selectedWork: PropTypes.number.isRequired,
};

export default connect(
  (store) => ({
    calendars: store.calendars,
    schedule: store.schedule,
    selectedWork: store.works.selectedWork,
  }),
  (dispatch) => ({
    getCalendars: bindActionCreators(getCalendarsAction, dispatch),
    getSchedule: bindActionCreators(getScheduleAction, dispatch),
  })
)(Calendar);
