import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import { Modal, Button, TreeSelect, Form, Input } from 'antd';
import { saveAccountOfCost } from '../../../actions/accountOfCost';

import './index.scss';

const { TreeNode } = TreeSelect;

const ChapterForm = ({
  isFetchingList,
  activeRecord,
  addAccountOfCostToTree,
  updateAccountOfCostInTree,
  chapters,
  onCancel,
  tree,
}) => {
  const [state, setState] = useState({
    show: false,
    saving: false,
    pristine: true,
    selectedChapter: null,
  });
  const { show, saving, pristine } = state;
  const finalChapters = [{ ID_ITEM_COSTO: 0, NOMBRE_COSTO_ITEM: 'Sin Padre (Raíz)' }, ...chapters];

  const filteredFinalChapters = activeRecord
    ? finalChapters.filter((e) => e.ID_ITEM_COSTO !== activeRecord.ID_ITEM_COSTO)
    : finalChapters;

  const [form] = Form.useForm();

  useEffect(() => {
    if (activeRecord) {
      setState((prev) =>
        update(prev, {
          show: { $set: true },
          selectedChapter: { $set: activeRecord.ID_ITEM_COSTO_SUP },
        })
      );
      form.setFieldsValue({
        ID_ITEM_COSTO_SUP: activeRecord.ID_ITEM_COSTO_SUP,
        NOMBRE_COSTO_ITEM: activeRecord.NOMBRE_COSTO_ITEM,
      });
    }
  }, [activeRecord]);

  const submit = (values) => {
    setState(update(state, { saving: { $set: true } }));
    const requestBody = {
      ...values,
      ES_CUENTA_COSTO: 1,
      CANTIDAD_VIGENTE: 0,
      CANTIDAD_MOD: 0,
      CODIGO_COSTO_ITEM: 'RAIZ',
      ID_UNI: 1,
      ID_RUB: 0,
    };
    if (activeRecord) {
      requestBody.ID_ITEM_COSTO = activeRecord.ID_ITEM_COSTO;
    }
    const stateUpdates = { saving: { $set: false }, show: { $set: false } };
    const { selectedChapter } = state;

    saveAccountOfCost(requestBody, !!activeRecord)
      .then((res) => {
        const parentHasChanged =
          activeRecord && activeRecord.ID_ITEM_COSTO_SUP !== values.ID_ITEM_COSTO_SUP;
        const element = {
          ID_ITEM_COSTO: res.ID_ITEM_COSTO,
          ...requestBody,
          ...(!activeRecord ? { children: [] } : {}),
        };

        if (!activeRecord || parentHasChanged) {
          let oldParent = null;

          if (parentHasChanged) {
            oldParent = filteredFinalChapters.find(
              (e) => e.ID_ITEM_COSTO === activeRecord.ID_ITEM_COSTO_SUP
            );
          }

          addAccountOfCostToTree(selectedChapter, element, oldParent);
        } else if (activeRecord && !parentHasChanged) {
          updateAccountOfCostInTree(selectedChapter, { ...element, PATH: activeRecord.PATH });
        }
        form.resetFields();
        onCancel();
        setState(update(state, stateUpdates));
      })
      .catch(() => {
        setState(update(state, stateUpdates));
      });
  };

  const onChangeForm = () => {
    if (activeRecord && pristine) {
      setState(update(state, { pristine: { $set: false } }));
    }
  };

  const getTreeNodes = (nodes) => {
    return nodes
      .filter((e) => !activeRecord || activeRecord.ID_ITEM_COSTO !== e.ID_ITEM_COSTO)
      .map((currentNode) => {
        const primaryLabel = currentNode.NOMBRE_COSTO_ITEM;

        return (
          <TreeNode
            value={currentNode.ID_ITEM_COSTO}
            primaryLabel={primaryLabel}
            node={currentNode}
            title={
              <div className="tree-select-node">
                <span>{primaryLabel}</span>
              </div>
            }
          >
            {currentNode.ES_CUENTA_COSTO === 1 &&
            (_.get(currentNode, 'children', []).some((e) => e.ES_CUENTA_COSTO === 1) ||
              _.get(currentNode, 'children.length', null) === 0)
              ? getTreeNodes(currentNode.children.filter((e) => e.ES_CUENTA_COSTO === 1))
              : null}
          </TreeNode>
        );
      });
  };

  const onSelect = ({ node }) => {
    form.setFieldsValue({
      ID_ITEM_COSTO_SUP: node.ID_ITEM_COSTO,
    });

    setState(
      update(state, {
        selectedChapter: { $set: node },
        ...(activeRecord && pristine ? { pristine: { $set: false } } : {}),
      })
    );
  };

  const onChange = () => {};

  return (
    <div className="add-chapter">
      <Button
        className="add-chapter"
        disabled={isFetchingList}
        loading={isFetchingList}
        onClick={() =>
          setState(
            update(state, {
              show: { $set: true },
            })
          )
        }
        type="primary"
      >
        <span>{activeRecord ? 'Editar Capítulo' : 'Agregar Nuevo Capítulo'}</span>
      </Button>
      <Modal
        closable={false}
        wrapClassName="common-modal add-pnf-modal"
        visible={show}
        width={700}
        onCancel={() => {
          setState(
            update(state, {
              show: { $set: false },
            })
          );
        }}
        footer={null}
        okText="Guardar"
      >
        <div className="modal-container">
          <div className="modal-header">
            <div />
            <span>Agregar Nuevo Capítulo</span>
            <i
              className="fa fa-times"
              onClick={() => {
                setState(
                  update(state, {
                    show: { $set: false },
                  })
                );
              }}
            />
          </div>
          <div className="form-body">
            <Form form={form} layout="vertical" onFinish={submit} onValuesChange={onChangeForm}>
              {tree && (
                <Form.Item
                  label="Capítulo Padre"
                  name="ID_ITEM_COSTO_SUP"
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Por favor seleccione el capítulo padre',
                    },
                  ]}
                >
                  <TreeSelect
                    showSearch
                    placeholder=""
                    dropdownClassName="caps-dropdown"
                    style={{ width: '100%' }}
                    value={_.get(state, 'selectedChapter.ID_ITEM_COSTO_SUP', state.selectedChapter)}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    allowClear
                    treeNodeFilterProp="primaryLabel"
                    treeNodeLabelProp="primaryLabel"
                    onChange={onChange}
                    onSelect={(_value, obj) => onSelect(obj)}
                    treeDefaultExpandedKeys={tree && tree.length ? tree : []}
                  >
                    {getTreeNodes([
                      {
                        NOMBRE_COSTO_ITEM: 'Sin padre (nodo raíz)',
                        ID_ITEM_COSTO: 0,
                        ES_CUENTA_C0STO: 1,
                      },
                      ...tree,
                    ])}
                  </TreeSelect>
                </Form.Item>
              )}
              <Form.Item
                label="Nombre Capítulo"
                name="NOMBRE_COSTO_ITEM"
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese un nombre de capítulo',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <div className="modal-footer">
                <Button
                  disabled={saving}
                  onClick={() => {
                    setState(
                      update(state, {
                        show: { $set: false },
                      })
                    );
                    onCancel();
                    form.resetFields();
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  loading={saving}
                  disabled={saving || (activeRecord && pristine)}
                  type="primary"
                  htmlType="submit"
                >
                  {activeRecord ? 'Guardar' : 'Agregar'}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

ChapterForm.propTypes = {
  isFetchingList: PropTypes.bool.isRequired,
  activeRecord: PropTypes.object.isRequired,
  addAccountOfCostToTree: PropTypes.func.isRequired,
  updateAccountOfCostInTree: PropTypes.func.isRequired,
  chapters: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  tree: PropTypes.array.isRequired,
};

export default ChapterForm;
