import update from 'immutability-helper';

export const DELETE_AREA = 'DELETE_AREA';
export const DELETE_POSITION = 'DELETE_POSITION';
export const GET_AREAS_AND_POSITIONS = 'GET_AREAS_AND_POSITIONS';
export const SET_ERROR_AREAS_AND_POSITIONS = 'SET_ERROR_AREAS_AND_POSITIONS';
export const SET_AREA = 'SET_AREA';
export const SET_POSITION = 'SET_POSITION';
export const UPDATE_AREA = 'UPDATE_AREA';
export const UPDATE_POSITION = 'UPDATE_POSITION';

const initialState = {
  data: null,
  isFetching: true,
  error: '',
};

const areasAndPositions = (state = initialState, action) => {
  switch (action.type) {
    case GET_AREAS_AND_POSITIONS: {
      const { areasAndPositions: data } = action.payload;
      return update(state, {
        data: { $set: data },
        isFetching: { $set: false },
      });
    }

    case SET_ERROR_AREAS_AND_POSITIONS: {
      const { error } = action.payload;
      return update(state, {
        error: { $set: error },
      });
    }

    case SET_AREA: {
      const { area } = action.payload;
      const { data } = state;

      if (data) {
        return update(state, {
          data: { $push: [area] },
        });
      }

      return update(state, {
        data: { $set: [area] },
      });
    }

    case SET_POSITION: {
      const { position } = action.payload;
      const index = state.data.findIndex((e) => e.ID_ARE === position.ID_ARE);

      return update(state, {
        data: {
          [index]: {
            CARGOS_ASOCIADOS: {
              $push: [position],
            },
          },
        },
      });
    }

    case UPDATE_AREA: {
      const { area } = action.payload;
      const index = state.data.findIndex((e) => e.ID_ARE === area.ID_ARE);

      return update(state, {
        data: {
          [index]: {
            ID_OBR: { $set: area.ID_OBR },
            NOMBRE_ARE: { $set: area.NOMBRE_ARE },
          },
        },
      });
    }

    case DELETE_AREA: {
      const { areaId } = action.payload;
      const index = state.data.findIndex((e) => e.ID_ARE === areaId);

      return update(state, {
        data: {
          $splice: [[index, 1]],
        },
      });
    }

    case UPDATE_POSITION: {
      const {
        oldAreaId,
        position: { CODEXT_CAR, COSTO_CAR, ID_ARE, ID_CAR, NOMBRE_CAR },
      } = action.payload;
      const areaIndex = state.data.findIndex((e) => e.ID_ARE === ID_ARE);

      if (oldAreaId === ID_ARE) {
        const positionIndex = state.data[areaIndex].CARGOS_ASOCIADOS.findIndex(
          (e) => e.ID_CAR === ID_CAR
        );

        return update(state, {
          data: {
            [areaIndex]: {
              CARGOS_ASOCIADOS: {
                [positionIndex]: {
                  CODEXT_CAR: { $set: CODEXT_CAR },
                  COSTO_CAR: { $set: COSTO_CAR },
                  NOMBRE_CAR: { $set: NOMBRE_CAR },
                },
              },
            },
          },
        });
      }

      const oldAreaIndex = state.data.findIndex((e) => e.ID_ARE === oldAreaId);
      const oldPositionIndex = state.data[oldAreaIndex].CARGOS_ASOCIADOS.findIndex(
        (e) => e.ID_CAR === ID_CAR
      );
      const position = update(state.data[oldAreaIndex].CARGOS_ASOCIADOS[oldPositionIndex], {
        CODEXT_CAR: { $set: CODEXT_CAR },
        COSTO_CAR: { $set: COSTO_CAR },
        ID_ARE: { $set: ID_ARE },
        NOMBRE_CAR: { $set: NOMBRE_CAR },
      });

      return update(state, {
        data: {
          [oldAreaIndex]: {
            CARGOS_ASOCIADOS: {
              $splice: [[oldPositionIndex, 1]],
            },
          },
          [areaIndex]: {
            CARGOS_ASOCIADOS: {
              $push: [position],
            },
          },
        },
      });
    }

    case DELETE_POSITION: {
      const { areaId, positionId } = action.payload;
      const areaIndex = state.data.findIndex((e) => e.ID_ARE === areaId);
      const positionIndex = state.data[areaIndex].CARGOS_ASOCIADOS.findIndex(
        (e) => e.ID_CAR === positionId
      );

      return update(state, {
        data: {
          [areaIndex]: {
            CARGOS_ASOCIADOS: {
              $splice: [[positionIndex, 1]],
            },
          },
        },
      });
    }

    default:
      return state;
  }
};
export default areasAndPositions;
