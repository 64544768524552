/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from 'react';
import { message, Modal, Button } from 'antd';
import PropTypes from 'prop-types';
import { TreeList } from '@progress/kendo-react-treelist';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { TdWrapper } from '../columns';
import api, { getHeaders } from '../../../utils/api';

const ValorizationDetail = ({ record, onCancel, work, user, client }) => {
  if (!record) return null;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { t } = useTranslation();

  const getData = useCallback(async () => {
    setLoading(true);
    const requestBody = {
      USUARIO: user,
      EMPRESA: client,
      FECHA_CORTE: '',
      ID_INS: record.ID_INS,
    };
    const response = await api.post('api/Avances/GetAvaProtocolosAct', requestBody, getHeaders());
    setLoading(false);
    if (response && response.status === 200) {
      setData(response.data);
    } else {
      message.error(t('common.requestError'));
    }
  }, [record]);

  useEffect(() => {
    getData();
  }, [record]);

  return (
    <Modal
      title={t('advanceControl.qualityCard.title')}
      visible
      footer={null}
      onCancel={onCancel}
      width={800}
    >
      {loading ? (
        <div className="w-100 d-flex justify-content-center py-5">
          <LoadingOutlined />
        </div>
      ) : (
        <>
          <div className="border p-3 rounded">
            <div className="d-flex flex-column mb'2">{`${t('common.work')}: ${work}`}</div>
            <div className="d-flex flex-column mb'2">{`${t('advanceControl.activityName')}: ${
              record.NOMBRE_INS
            }`}</div>
          </div>
          <TreeList
            className="kendo-table mx-auto"
            style={{
              height: `450px`,
              width: `720px`,
              overflowY: 'auto',
            }}
            tableProps={{
              style: {
                tableLayout: 'fixed',
                width: 700,
              },
            }}
            rowHeight={28}
            scrollable="virtual"
            data={data}
            expandField="expanded"
            subItemsField="children"
            columns={[
              {
                field: 'ID_ACC_RE',
                title: `N° ${t('common.sheet')}`,
                width: 100,
                cell: ({ dataItem }) => {
                  return (
                    <TdWrapper record={dataItem} className="text-align-center">
                      {dataItem.ID_ACC_REG}
                    </TdWrapper>
                  );
                },
              },
              {
                field: 'NOMBRE_ACC',
                title: t('common.protocol'),
                width: 220,
                cell: ({ dataItem }) => {
                  return (
                    <TdWrapper record={dataItem} className="text-align-center">
                      {dataItem.NOMBRE_ACC}
                    </TdWrapper>
                  );
                },
              },
              {
                field: 'NOMBRE_UCO',
                title: t('common.enclosure'),
                width: 100,
                cell: ({ dataItem }) => {
                  return (
                    <TdWrapper record={dataItem} className="text-align-center">
                      {dataItem.NOMBRE_UCO}
                    </TdWrapper>
                  );
                },
              },
              {
                field: 'ESTADO',
                title: t('advanceControl.qualityCard.sheetState'),
                width: 100,
                cell: ({ dataItem }) => {
                  return (
                    <TdWrapper record={dataItem} className="text-align-center">
                      {dataItem.ESTADO}
                    </TdWrapper>
                  );
                },
              },
              {
                field: 'URL',
                title: 'URL',
                width: 100,
                cell: ({ dataItem }) => {
                  return (
                    <TdWrapper record={dataItem} className="text-align-center">
                      <Button
                        style={{ height: 26 }}
                        onClick={() => window.open(dataItem.URL, '_blank')}
                      >
                        CheckList
                      </Button>
                    </TdWrapper>
                  );
                },
              },
            ]}
          />
        </>
      )}
    </Modal>
  );
};

ValorizationDetail.propTypes = {
  record: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  work: PropTypes.object.isRequired,
  user: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
};

export default ValorizationDetail;
