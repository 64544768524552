import update from 'immutability-helper';
import moment from 'moment';
import get from 'lodash/get';

export const PNF_FETCHED = 'PNF_FETCHED';
export const GET_PNF = 'GET_PNF';
export const GET_PNF_ERROR = 'GET_PNF_ERROR';
export const UPDATE_PNF = 'UPDATE_PNF';
export const STATES_FETCHED = 'STATES_FETCHED';
export const PNF_CREATED = 'PNF_CREATED';
export const EDIT_PNF = 'EDIT_PNF';
export const PNF_TYPES_FETCHED = 'PNF_TYPES_FETCHED';
export const GET_PNF_TYPES = 'GET_PNF_TYPES';
export const PNF_TYPES_ERROR = 'PNF_TYPES_ERROR';
export const GET_PNF_LIST = 'GET_PNF_LIST';
export const PNF_LIST_FETCHED = 'PNF_LIST_FETCHED';
export const PNF_LIST_ERROR = 'PNF_LIST_ERROR';
export const EDIT_LEVEL = 'EDIT_LEVEL';
export const EDIT_TYPE = 'EDIT_TYPE';
export const EDIT_LEVELS = 'EDIT_LEVELS';

const pnfInit = {
  data: {},
  isFetching: false,
  error: false,
  currentSelected: 0,
  list: {
    isFetching: false,
    error: false,
    data: {},
  },
  types: {
    isFetching: false,
    error: false,
    data: null,
  },
};

const pnf = (state = pnfInit, action) => {
  switch (action.type) {
    case 'WORKS_FETCHED': {
      let newPaymentState = { ...state };
      action.works.forEach(({ ID_OBR }) => {
        newPaymentState = update(newPaymentState, {
          data: {
            [ID_OBR]: { $set: null },
          },
          list: {
            data: {
              [ID_OBR]: { $set: null },
            },
          },
        });
      });

      return newPaymentState;
    }

    case PNF_FETCHED: {
      return update(state, {
        data: {
          [action.workId]: {
            $set: action.pnf,
          },
        },
        isFetching: { $set: false },
        error: { $set: false },
      });
    }

    case GET_PNF_TYPES: {
      return update(state, {
        types: {
          isFetching: {
            $set: true,
          },
          error: {
            $set: false,
          },
        },
      });
    }

    case PNF_TYPES_FETCHED: {
      return update(state, {
        types: {
          data: {
            $set: action.types,
          },
          isFetching: {
            $set: false,
          },
        },
      });
    }

    case PNF_TYPES_ERROR: {
      return update(state, {
        types: {
          error: {
            $set: true,
          },
        },
      });
    }

    case GET_PNF_LIST: {
      return update(state, {
        list: {
          isFetching: {
            $set: true,
          },
          error: {
            $set: false,
          },
        },
      });
    }

    case PNF_LIST_FETCHED: {
      return update(state, {
        list: {
          data: {
            [action.workId]: {
              $set: action.list,
            },
          },
          isFetching: {
            $set: false,
          },
        },
      });
    }

    case PNF_LIST_ERROR: {
      return update(state, {
        list: {
          error: {
            $set: true,
          },
        },
      });
    }

    case PNF_CREATED: {
      const data = get(state, `list.data[${action.workId}]`, []);
      const listIndex = data.findIndex((e) =>
        moment(action.pnf.FECHA_PNF, 'DD-MM-YYYY').isAfter(moment(e.FECHA_PNF, 'DD-MM-YYYY'))
      );

      const updateToDo =
        listIndex === -1
          ? {
              $push: [action.pnf],
            }
          : {
              $splice: [[listIndex, 0, action.pnf]],
            };

      return update(state, {
        list: {
          data: {
            [action.workId]: updateToDo,
          },
        },
        currentSelected: { $set: listIndex === -1 ? data.length : listIndex },
      });
    }

    case GET_PNF_ERROR:
      return update(state, { error: { $set: true }, isFetching: { $set: false } });
    case GET_PNF:
      return {
        ...state,
        isFetching: true,
        error: false,
      };

    case EDIT_LEVEL: {
      const updates = {};
      Object.keys(action.data).forEach((key) => {
        if (key.includes('COMENTARIO_') || key.includes('REAJUSTE_')) {
          updates[key] = { $set: action.data[key] };
        }
      });
      return update(state, { data: { [action.workId]: updates } });
    }

    case EDIT_LEVELS: {
      return update(state, { data: { [action.workId]: { PNF_NIVEL: { $set: action.data } } } });
    }

    case EDIT_TYPE: {
      const updates = {};
      action.data.forEach((e) => {
        const index = state.data[action.workId].PNF.findIndex(
          (current) => e.ID_ITEM === current.ID_ITEM
        );

        updates[index] = {
          $merge: { COMENTARIO_PNF: e.COMENTARIO_PNF, ID_TIPO_PNF: e.ID_TIPO_PNF },
        };
      });
      return update(state, {
        data: {
          [action.workId]: { PNF: updates },
        },
      });
    }

    case EDIT_PNF: {
      const index = state.list[action.workId].findIndex(
        (e) => e.ID_EP_ENC === action.body.ID_EP_ENC
      );

      const { NOMBRE_ESTADO_EP } = state.states.find(
        (e) => e.ID_ESTADO_EP === action.body.ID_ESTADO_EP
      );

      const dataToMerge = {
        NOMBRE_ESTADO_EP,
        ...action.body,
      };
      return update(state, {
        list: {
          [action.workId]: {
            [index]: {
              $merge: dataToMerge,
            },
          },
        },
      });
    }

    case 'AUTH_LOGOUT':
      return pnfInit;

    default:
      return state;
  }
};

export default pnf;
