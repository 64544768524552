import update from 'immutability-helper';
import moment from 'moment';
import get from 'lodash/get';

export const PRODUCTION_FETCHED = 'PRODUCTION_FETCHED';
export const GET_PRODUCTION = 'GET_PRODUCTION';
export const GET_PRODUCTION_ERROR = 'GET_PRODUCTION_ERROR';
export const UPDATE_PRODUCTION = 'UPDATE_PRODUCTION';
export const GET_ADVANCE_DATES = 'GET_ADVANCE_DATES';
export const ADVANCE_DATES_FETCHED = 'ADVANCE_DATES_FETCHED';
export const ADD_ADVANCE_DATE = 'ADD_ADVANCE_DATE';

export const GET_ADVANCE_DATES_ERROR = 'GET_ADVANCE_DATES_ERROR';
export const RESET_PRODUCTION = 'RESET_PRODUCTION';

const productionInit = {
  data: {},
  isFetching: false,
  error: false,
  advanceDates: {
    data: {},
    isFetching: false,
    error: false,
    lastAddedIndex: 0,
  },
};

const PRODUCTION = (state = productionInit, action) => {
  switch (action.type) {
    case 'WORKS_FETCHED': {
      let newProduction = { ...state };
      action.works.forEach(({ ID_OBR }) => {
        newProduction = update(newProduction, {
          data: {
            [ID_OBR]: { $set: {} },
          },
        });
      });

      return newProduction;
    }
    case PRODUCTION_FETCHED:
      return update(state, {
        data: {
          [action.workId]: {
            [action.advanceDate]: {
              $set: action.production,
            },
          },
        },
        isFetching: { $set: false },
        error: { $set: false },
      });

    case GET_PRODUCTION_ERROR:
      return update(state, { error: { $set: true }, isFetching: { $set: false } });
    case GET_PRODUCTION:
      return {
        ...state,
        isFetching: true,
        error: false,
      };

    case UPDATE_PRODUCTION: {
      return update(state, {
        data: {
          [action.workId]: {
            [action.advanceDateId]: {
              $apply: (productionInfo) => {
                let productionToUpdate = [...productionInfo];
                action.recordsToUpdate.forEach((record) => {
                  const recordToUpdate = { ...record };
                  delete recordToUpdate.ID_ITEM;
                  const index = productionInfo.findIndex(
                    (current) => current.ID_ITEM === record.ID_ITEM
                  );
                  productionToUpdate = update(productionToUpdate, {
                    [index]: { $merge: recordToUpdate },
                  });
                });
                return productionToUpdate;
              },
            },
          },
        },
      });
    }

    case GET_ADVANCE_DATES:
      return update(state, {
        advanceDates: {
          isFetching: { $set: true },
        },
      });

    case ADVANCE_DATES_FETCHED:
      return update(state, {
        advanceDates: {
          data: {
            [action.workId]: {
              $set: action.advanceDates,
            },
          },
          isFetching: { $set: false },
          error: { $set: false },
        },
      });

    case ADD_ADVANCE_DATE: {
      const data = get(state, `advanceDates.data[${action.workId}]`, []);
      const advanceDatesIndex = data.findIndex((e) =>
        moment(action.advanceDate.FECHA_AVA, 'DD-MM-YYYY').isAfter(
          moment(e.FECHA_AVA, 'DD-MM-YYYY')
        )
      );

      const updateToDo =
        advanceDatesIndex === -1
          ? {
              $push: [action.advanceDate],
            }
          : {
              $splice: [[advanceDatesIndex, 0, action.advanceDate]],
            };

      const index = advanceDatesIndex === -1 ? data.length : advanceDatesIndex;

      return update(state, {
        advanceDates: {
          data: {
            [action.workId]: updateToDo,
          },
          lastAddedIndex: { $set: index },
        },
      });
    }

    case GET_ADVANCE_DATES_ERROR:
      return update(state, {
        advanceDates: { error: { $set: true }, isFetching: { $set: false } },
      });

    case 'AUTH_LOGOUT':
      return productionInit;

    case RESET_PRODUCTION: {
      const keys = Object.keys(state.data);
      let newState = { ...state };

      keys.forEach((key) => {
        newState = update(newState, {
          data: {
            [key]: { $set: {} },
          },
        });
      });

      return newState;
    }

    default:
      return state;
  }
};

export default PRODUCTION;
