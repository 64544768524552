import update from 'immutability-helper';

export const BUDGET_FETCHED = 'BUDGET_FETCHED';
export const GET_BUDGET = 'GET_BUDGET';
export const GET_BUDGET_ERROR = 'GET_BUDGET_ERROR';
export const RESET_BUDGET = 'RESET_BUDGET';

export const GET_UNITS = 'GET_UNITS';
export const GET_UNITS_ERROR = 'GET_UNITS_ERROR';
export const UNITS_FETCHED = 'UNITS_FETCHED';

export const GET_INPUTS_TYPES = 'GET_INPUTS_TYPES';
export const GET_INPUTS_TYPES_ERROR = 'GET_INPUTS_TYPES_ERROR';
export const INPUTS_TYPES_FETCHED = 'INPUTS_TYPES_FETCHED';

export const GET_RESOURCES_AND_SUBITEMS = 'GET_RESOURCES_AND_SUBITEMS';
export const GET_RESOURCES_AND_SUBITEMS_ERROR = 'GET_RESOURCES_AND_SUBITEMS_ERROR';
export const RESOURCES_AND_SUBITEMS_FETCHED = 'RESOURCES_AND_SUBITEMS_FETCHED';

export const ADD_RESOURCE_OR_SUBITEM = 'ADD_RESOURCE_OR_SUBITEM';

const commonInitialInfo = {
  data: null,
  isFetching: false,
  error: false,
};
const budgetInit = {
  data: {},
  isFetching: false,
  error: false,
  units: commonInitialInfo,
  inputsTypes: commonInitialInfo,
  resourcesAndSubitems: commonInitialInfo,
};

const budget = (state = budgetInit, action) => {
  switch (action.type) {
    case BUDGET_FETCHED:
      return update(state, {
        data: {
          [action.workId]: {
            $set: action.budget,
          },
        },
        isFetching: { $set: false },
        error: { $set: false },
      });

    case GET_BUDGET_ERROR:
      return update(state, { error: { $set: true }, isFetching: { $set: false } });
    case GET_BUDGET:
      return {
        ...state,
        isFetching: true,
        error: false,
      };

    case RESET_BUDGET:
    case 'AUTH_LOGOUT':
      return budgetInit;

    case GET_UNITS: {
      return update(state, {
        units: {
          isFetching: { $set: true },
          error: { $set: false },
        },
      });
    }

    case GET_UNITS_ERROR: {
      return update(state, {
        units: {
          error: { $set: true },
        },
      });
    }

    case UNITS_FETCHED: {
      return update(state, {
        units: {
          data: { $set: action.units },
          isFetching: { $set: false },
        },
      });
    }

    case GET_INPUTS_TYPES: {
      return update(state, {
        inputsTypes: {
          isFetching: { $set: true },
          error: { $set: false },
        },
      });
    }

    case GET_INPUTS_TYPES_ERROR: {
      return update(state, {
        inputsTypes: {
          error: { $set: true },
        },
      });
    }

    case INPUTS_TYPES_FETCHED: {
      return update(state, {
        inputsTypes: {
          data: { $set: action.inputsTypes },
          isFetching: { $set: false },
        },
      });
    }

    case GET_RESOURCES_AND_SUBITEMS: {
      return update(state, {
        resourcesAndSubitems: {
          isFetching: { $set: true },
          error: { $set: false },
        },
      });
    }

    case GET_RESOURCES_AND_SUBITEMS_ERROR: {
      return update(state, {
        resourcesAndSubitems: {
          error: { $set: true },
        },
      });
    }

    case RESOURCES_AND_SUBITEMS_FETCHED: {
      return update(state, {
        resourcesAndSubitems: {
          data: { $set: action.resourcesAndSubitems },
          isFetching: { $set: false },
        },
      });
    }

    case ADD_RESOURCE_OR_SUBITEM: {
      return update(state, {
        resourcesAndSubitems: {
          data: { $push: [action.element] },
        },
      });
    }

    default:
      return state;
  }
};

export default budget;
