import update from 'immutability-helper';
import get from 'lodash/get';
import moment from 'moment';

export const OVERHEAD_PROJECTION_FETCHED = 'OVERHEAD_PROJECTION_FETCHED';
export const GET_OVERHEAD_PROJECTION = 'GET_OVERHEAD_PROJECTION';
export const GET_OVERHEAD_PROJECTION_ERROR = 'GET_OVERHEAD_PROJECTION_ERROR';

export const ADD_MONTH = 'ADD_MONTH';

const overheadProjectionInit = {
  data: {},
  isFetching: false,
  error: false,
};

const monthsNames = [
  'Ene',
  'Feb',
  'Mar',
  'Abr',
  'May',
  'Jun',
  'Jul',
  'Ago',
  'Sep',
  'Oct',
  'Nov',
  'Dic',
];

const overheadProjection = (state = overheadProjectionInit, action) => {
  switch (action.type) {
    case 'WORKS_FETCHED': {
      let newCas = { ...state };
      action.works.forEach(({ ID_OBR }) => {
        newCas = update(newCas, {
          data: {
            [ID_OBR]: { $set: null },
          },
        });
      });

      return newCas;
    }

    case OVERHEAD_PROJECTION_FETCHED: {
      return update(state, {
        data: {
          [action.workId]: {
            $set: action.overheadProjection,
          },
        },
        isFetching: { $set: false },
        error: { $set: false },
      });
    }

    case GET_OVERHEAD_PROJECTION_ERROR:
      return update(state, { error: { $set: true }, isFetching: { $set: false } });
    case GET_OVERHEAD_PROJECTION:
      return {
        ...state,
        isFetching: true,
        error: false,
      };

    case ADD_MONTH: {
      const months = get(state, ['data', action.workId, 'MESES']);

      const lastMonth = months.length ? months[months.length - 1] : null;

      const newDate = lastMonth
        ? moment(`${lastMonth.MES}-${lastMonth.ANO}`, 'M-YYYY').add(1, 'months').format('M-YYYY')
        : moment().format('M-YYYY');
      const newDateArray = newDate.split('-');
      const monthText = `${monthsNames[Number(newDateArray[0]) - 1]}-${newDateArray[1]}`;
      const newMonth = {
        ANO: Number(newDateArray[1]),
        MES: Number(newDateArray[0]),
        NOMBRE: monthText,
        NOMBRE2: monthText.replace('-', ' '),
        NOMBRE_COL: newDate.replace('-', ''),
      };

      return update(state, {
        data: {
          [action.workId]: {
            MESES: { $push: [newMonth] },
          },
        },
      });
    }

    case 'AUTH_LOGOUT':
      return overheadProjectionInit;

    default:
      return state;
  }
};

export default overheadProjection;
