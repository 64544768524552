/* eslint-disable no-return-assign */

import React, { useEffect, useState, useCallback } from 'react';
import { Row, Col, Button, Modal, message } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import range from 'lodash/range';
import moment from 'moment';
import update from 'immutability-helper';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import qs from 'querystring';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TreeList, filterBy, treeToFlat } from '@progress/kendo-react-treelist';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { useTranslation } from 'react-i18next';
import api, { getHeaders } from '../../utils/api';
import ViewOptions from '../../components/ViewOptions';
import { getChildren, getNodeObjectToUpdate } from '../../utils/budgetAndProductionHelpers';
import ChapterFilter from '../../components/ChapterFilter';
import {
  impactedActivitiesFilterKeys,
  filtersOperators,
  intervalFilterKeys,
  getTableWidth,
  initialFilters,
  advanceControlProptypes,
  schemaLevels,
  getInitialState,
} from './helpers';
import columns from './columns';
import InputWithTopLabel from '../../components/InputWithTopLabel';
import ExtraFilters from './ExtraFilters';
import { setInformationFromAttendanceURL as setInformationFromAttendanceURLAction } from '../../actions/auth';
import SelectWithTopLabel from '../../components/SelectWithTopLabel';
import RowDetail from './RowDetail';
import ContractView from './ContractView';
import Legend from './Legend';
import RestVencInfo from './RestVencInfo';
import PhotosInfo from './PhotosInfo';
import ValorizationInfo from './ValorizationInfo';
import QualityCard from './QualityCard';
import SetValorizationType from './SetValorizationType';
import {
  resetAdvanceControlEdition as resetAdvanceControlEditionAction,
  saveAdvanceControlEdition as saveAdvanceControlEditionAction,
} from '../../actions/advanceControlEdition';
import NewAdvance from '../../components/NewAdvance';

import './index.scss';

const { confirm } = Modal;

const SaveButton = connect(
  (store) => ({
    advanceControlEdition: store.advanceControlEdition,
  }),
  () => ({})
)(({ savingEdition, save, t, advanceControlEdition }) => {
  return (
    <Button
      loading={savingEdition}
      type="primary"
      onClick={() => {
        if (savingEdition || !advanceControlEdition.length) {
          message.warning('No puedes guardar en estos momentos');
        } else {
          const newfieldsWithErrors = advanceControlEdition.filter(
            (e) => e.ID_TIPO_RESTRICCION > 0 && !e.FECHA_REST
          );
          if (newfieldsWithErrors.length) {
            message.warning(
              'Existen filas editadas pendientes por agregar fecha de restriccion',
              5
            );
          } else {
            save();
          }
        }
      }}
    >
      {t('common.save')}
    </Button>
  );
});

const AdvanceControl = ({
  auth,
  location,
  setInformationFromAttendanceURL,
  resetAdvanceControlEdition,
  saveAdvanceControlEdition,
}) => {
  const { foco_client, foco_project, foco_token, foco_username } = qs.parse(
    location.search.replace('?', '')
  );
  const [activeOptions, setActiveOptions] = useState({ option7: true });
  const { t } = useTranslation();
  const [state, setState] = useState(getInitialState(foco_project));
  const [showFilterBar, setShowFilterBar] = useState(false);
  const [activeRow, setActiveRow] = useState(null);
  const [activeRestRow, setActiveRestRow] = useState(null);
  const [activePhotosRow, setActivePhotosRow] = useState(null);
  const [activeValorizationRow, setActiveValorizationRow] = useState(null);
  const [activeQualityCardRow, setActiveQualityCardRow] = useState(null);
  const [activeCurrentContractRow, setActiveCurrentContractRow] = useState(null);
  const [activeMeasuredContractRow, setActiveMeasuredContractRow] = useState(null);
  const [isClosedProjection, setIsClosedProjection] = useState(false);
  const [tree, setTree] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [advanceCreated, setAdvaceCreated] = useState(false);
  const [valorizationAdvance, setValorizationAdvance] = useState({
    data: {},
    isFetching: false,
    selected: null,
  });

  const {
    works,
    gantt,
    dates,
    isFetchingData,
    expandedRowKeys,
    active,
    filter,
    capFilter,
    savingExcel,
    intervalFilters,
    impactedActivitiesFilter,
    lastSavedTree,
    validDates,
    reset,
    filters,
    levelFilter,
    access,
    userId,
    loadingOpenOrCloseAdvance,
    savingEdition,
    fieldsWithErrors,
    indexes,
  } = state;

  useEffect(() => {
    if (!activeOptions.option11 && activeRow) {
      setActiveRow(null);
    }
  }, [activeOptions.option11]);
  let _export;
  const getWorks = useCallback(async () => {
    const requestBody = {
      USUARIO: foco_username,
      EMPRESA: foco_client,
    };
    setState(update(state, { works: { isFetching: { $set: true } } }));
    const worksResponse = await api.post('api/Avances/GetAvaObras', requestBody, getHeaders());
    const accessResponse = await api.post(
      'api/Avances/GetPermisosPagina',
      { ...requestBody, PAGINA: 'PROGRAMA_CONTROLADO' },
      getHeaders()
    );
    let updates = update(state, {
      works: {
        isFetching: { $set: false },
      },
    });
    if (worksResponse && worksResponse.status === 200) {
      updates = update(updates, {
        works: {
          data: { $set: worksResponse.data },
        },
      });
    }

    if (accessResponse && accessResponse.status === 200) {
      const newAccess = accessResponse.data.PERMISOS.reduce(
        (acc, { OPCION, ACCESO }) => ({
          ...acc,
          [OPCION]: ACCESO,
        }),
        {}
      );
      updates = update(updates, {
        access: { $set: newAccess },
        userId: { $set: accessResponse.data.ID_USU },
      });
    }
    setState(updates);
  }, [works, foco_client]);

  const getValorizationAdvance = async () => {
    const requestBody = {
      USUARIO: foco_username,
      EMPRESA: foco_client,
      ID_OBR: works.selected,
    };
    setValorizationAdvance(update(valorizationAdvance, { isFetching: { $set: true } }));

    const response = await api.post('api/Avances/GetAvaValorizacion', requestBody, getHeaders());
    if (response && response.status === 200) {
      setValorizationAdvance(
        update(valorizationAdvance, {
          data: { [works.selected]: { $set: response.data } },
          selected: {
            $set: (response.data.find((e) => e.ESTADO_VAL === 1) || { ID_VAL: null }).ID_VAL,
          },
          isFetching: { $set: false },
        })
      );
    } else {
      setValorizationAdvance(update(valorizationAdvance, { isFetching: { $set: false } }));
    }
  };

  const getDates = useCallback(async () => {
    const requestBody = {
      USUARIO: foco_username,
      EMPRESA: foco_client,
      ID_OBR: works.selected,
      ID_GANT: gantt.selected,
    };
    setState(
      update(state, {
        dates: { isFetching: { $set: true } },
      })
    );

    api.post(`api/Avances/GetAvaFechas`, requestBody, getHeaders()).then((datesResult) => {
      let updates = update(state, {
        dates: { isFetching: { $set: false } },
      });
      if (datesResult.status === 200) {
        updates = update(updates, {
          dates: {
            data: {
              [works.selected]: {
                $set: datesResult.data,
              },
            },
            selected: { $set: get(datesResult, 'data[0].ID_FECHA') },
          },
        });
        setIsClosedProjection(!!get(datesResult, 'data[0].ESTADO_AVA'));
      }

      api
        .post(`api/Avances/GetFechasValidas`, requestBody, getHeaders())
        .then((result) => {
          if (result.status === 200) {
            updates = update(updates, {
              validDates: { $set: result.data },
            });
          }
        })
        .finally(() => {
          setState(updates);
        });
    });
  }, [works, gantt]);

  useEffect(() => {
    if (reset) {
      setTimeout(() => {
        setState(
          update(state, {
            reset: { $set: false },
          })
        );
      }, 500);
    }
  }, [reset]);

  const getGantt = useCallback(async () => {
    const requestBody = {
      USUARIO: foco_username,
      EMPRESA: foco_client,
      ID_OBR: works.selected,
    };
    setState(
      update(state, {
        gantt: { isFetching: { $set: true } },
      })
    );
    api.post(`api/Avances/GetAvaGantt`, requestBody, getHeaders()).then((ganttResult) => {
      let updates = update(state, {
        gantt: { isFetching: { $set: false } },
      });
      if (ganttResult.status === 200) {
        updates = update(updates, {
          gantt: {
            data: {
              [works.selected]: {
                $set: ganttResult.data,
              },
            },
            selected: { $set: (ganttResult.data.find((e) => e.PREDETER_GANT === 1) || {}).ID_GANT },
          },
        });
      }
      setState(updates);
    });
  }, [works, foco_client]);

  const createTreeData = useCallback(
    (treeData) => {
      const treeKey = `${works.selected}_${dates.selected}_${gantt.selected}_${valorizationAdvance.selected}`;
      if (treeData && treeData.length > 0) {
        let treeObject = {};
        const treeIndexes = [];
        treeData = treeData.map((e) => {
          treeIndexes.push(e.ID_INS);
          return { ...e, expanded: true };
        });
        const initialExpandedRowKeys = [];

        treeData.forEach((item) => {
          if (treeObject[item.ID_SUP]) {
            treeObject = update(treeObject, {
              [item.ID_SUP]: {
                $push: [item],
              },
            });
          } else {
            treeObject = update(treeObject, {
              [item.ID_SUP]: {
                $set: [item],
              },
            });
          }
          if (item.ID_NAT === 1) {
            initialExpandedRowKeys.push(item.ID_INS);
          }
        });

        const newTree = [];
        const paths = {
          [treeObject[-1][0].ID_INS]: '0',
        };
        const savePath = (id, path) => {
          if (!paths[id]) {
            paths[id] = path;
          }
        };

        treeObject[-1].forEach((current, i) => {
          newTree.push({
            ...current,
            PATH: `${i}`,
            children: getChildren(current.ID_INS, treeObject, `${i}`, savePath, null, 'ID_INS'),
          });
        });
        setState(
          update(state, {
            expandedRowKeys: {
              $set: initialExpandedRowKeys,
            },
            expanded: {
              $set: initialExpandedRowKeys,
            },

            isFetchingData: { $set: false },

            indexes: { [treeKey]: { $set: treeIndexes } },
            lastSavedTree: {
              [treeKey]: { $set: newTree },
            },
          })
        );

        setTree(
          update(tree, {
            [treeKey]: { $set: newTree },
          })
        );
      } else {
        setState(
          update(state, {
            expandedRowKeys: { $set: [] },
            isFetchingData: { $set: false },
          })
        );

        setTree(
          update(tree, {
            [treeKey]: { $set: [] },
          })
        );
      }
    },
    [expandedRowKeys, dates.selected, gantt.selected, tree, valorizationAdvance.selected]
  );

  const getData = useCallback(async () => {
    const requestBody = {
      EMPRESA: foco_client,
      USUARIO: foco_username,
      ID_OBR: works.selected,
      ID_GANT: gantt.selected,
      FECHA_AVA: (dates.data[works.selected].find((e) => e.ID_FECHA === dates.selected) || {})
        .FECHA_AVA,
      ID_VAL: valorizationAdvance.selected,
    };
    setState(
      update(state, {
        savingEdition: { $set: false },
        isFetchingData: { $set: true },
        filter: { $set: [] },
        filters: { $set: initialFilters },
        active: { $set: null },
      })
    );

    setTree(
      update(tree, {
        [`${works.selected}_${dates.selected}_${gantt.selected}_${valorizationAdvance.selected}`]: {
          $set: [],
        },
      })
    );
    api
      .post(`api/Avances/GetAvaVistaGantt`, requestBody, getHeaders())
      .then((result) => {
        if (result && result.status === 200) {
          const newData = result.data.map((e) => {
            return {
              ...e,
              PARSED_COMIENZO_PROY: new Date(
                `${e.COMIENZO_PROY.split('-').reverse().join('-')}T00:00`
              ),
              PARSED_FIN_REAL: new Date(`${e.FIN_REAL.split('-').reverse().join('-')}T00:00`),
            };
          });
          createTreeData(newData);
        } else {
          setState(update(state, { isFetchingData: { $set: false } }));
        }
      })
      .catch((err) => {
        console.log(err);
        setState(update(state, { isFetchingData: { $set: false } }));
      });
  }, [works.selected, dates.selected, gantt.selected, valorizationAdvance.selected]);

  useEffect(() => {
    if (advanceCreated) {
      getData();
      setTimeout(() => {
        setAdvaceCreated(false);
      }, 200);
    }
  }, [advanceCreated, setAdvaceCreated]);

  const editRow = (fieldsToUpdate) => {
    setTree((prevTree) => {
      const treeKey = `${works.selected}_${dates.selected}_${gantt.selected}_${valorizationAdvance.selected}`;
      let newTree = cloneDeep(prevTree[treeKey]);
      fieldsToUpdate.forEach((element) => {
        const path = element.PATH.split('.');
        const updatedNode = getNodeObjectToUpdate(path, 0, { $merge: element });
        newTree = update(newTree, updatedNode);
      });
      return update(prevTree, {
        [treeKey]: { $set: newTree },
      });
    });
  };

  const save = useCallback(() => {
    const requestBody = {
      USUARIO: foco_username,
      EMPRESA: foco_client,
      FECHA_AVA: get(dates, ['data', works.selected], []).find((e) => e.ID_FECHA === dates.selected)
        .FECHA_AVA,
      ID_GANT: gantt.selected,
    };
    setState(update(state, { savingEdition: { $set: true } }));

    saveAdvanceControlEdition(requestBody)
      .then(() => {
        setIsEditing(false);
        getData();
      })
      .catch(() => {
        setState(update(state, { savingEdition: { $set: false } }));
      });
  }, [tree, works.selected, dates.selected, gantt.selected]);
  useEffect(() => {
    if (foco_token) {
      setInformationFromAttendanceURL(foco_client, foco_project, foco_token, foco_username);
      localStorage.setItem('foco_token', foco_token);
    }
  }, []);

  useEffect(() => {
    if (isEditing) {
      setActiveOptions(
        update(activeOptions, {
          option1: { $set: true },
          option5: { $set: true },
        })
      );
    }
  }, [activeOptions, isEditing]);

  useEffect(() => {
    if (auth.token && auth.user.username) {
      if (!valorizationAdvance.data[works.selected] && !valorizationAdvance.loading) {
        getValorizationAdvance();
      }
      if (foco_client && !works.data && !works.isFetching) {
        getWorks();
      }

      if (works.data && !gantt.data[works.selected] && !gantt.isFetching) {
        getGantt();
      }

      if (works.data && gantt.data[works.selected] && dates.data[works.selected]) {
        setState(
          update(state, {
            gantt: {
              selected: {
                $set: gantt.data[works.selected].find((e) => e.PREDETER_GANT === 1).ID_GANT,
              },
            },
            dates: {
              selected: { $set: get(dates.data[works.selected], 'data[0].ID_FECHA') },
            },
          })
        );
      }

      if (
        works.data &&
        !valorizationAdvance.data[works.selected] &&
        !valorizationAdvance.isFetching
      ) {
        getValorizationAdvance();
      }
    }
  }, [auth, works]);

  useEffect(() => {
    if (gantt.selected) {
      getDates();
    }
  }, [gantt.selected]);

  const onChange = (value, stateKey) => {
    let updates = update(state, { [stateKey]: { selected: { $set: Number(value) } } });
    let newIsClosedProjection = false;

    if (stateKey === 'works') {
      updates = update(updates, {
        gantt: {
          selected: { $set: null },
        },
        dates: {
          selected: { $set: null },
        },
      });
    }
    if (stateKey === 'dates' && value !== null) {
      const date = dates.data[works.selected].find((e) => e.ID_FECHA === value);
      if (date) {
        newIsClosedProjection = !!date.ESTADO_AVA;
      }
    }
    setIsClosedProjection(newIsClosedProjection);
    setState(updates);
  };

  const applyCapFilters = useCallback(
    (selectedOptions) => {
      let updates = [];
      if (selectedOptions.length) {
        updates = selectedOptions.map((e) => ({
          field: 'PATH',
          operator: 'startswith',
          value: e.PATH,
        }));
      }

      setState(update(state, { capFilter: { $set: updates }, active: { $set: null } }));
    },
    [capFilter, active]
  );

  const applyExtraFilters = (selectedOptions, inputFilters, dateFilter) => {
    const newDateFilters = [];
    if (dateFilter.checked && (dateFilter.min || dateFilter.max)) {
      if (dateFilter.min) {
        newDateFilters.push({
          field: 'PARSED_FIN_REAL',
          value: dateFilter.min.toDate(),
          operator: 'lte',
        });
      }
      if (dateFilter.max) {
        newDateFilters.push({
          field: 'PARSED_COMIENZO_PROY',
          value: dateFilter.max.toDate(),
          operator: 'gte',
        });
      }
    }
    const newDateFilter = {
      logic: 'or',
      filters: newDateFilters,
    };
    setState(
      update(state, {
        active: { $set: null },
        filter: {
          $apply: (currentFilters) => {
            let newFilters = cloneDeep(currentFilters);
            Object.keys(selectedOptions).forEach((key) => {
              if (
                !intervalFilterKeys.includes(key) &&
                !impactedActivitiesFilterKeys.includes(key)
              ) {
                const value = selectedOptions[key];
                const filterIndex = newFilters.findIndex(
                  (currentFilter) => currentFilter.field === key
                );
                const newFilter = {
                  field: key,
                  ...filtersOperators[key],
                };
                let updates = {};

                if (value && filterIndex === -1) {
                  updates = { $push: [newFilter] };
                } else if (!value && filterIndex > -1) {
                  updates = {
                    $splice: [[filterIndex, 1]],
                  };
                }

                newFilters = update(newFilters, updates);
              }
            });
            return newFilters;
          },
        },
        intervalFilters: {
          $apply: (currentFilters) => {
            let newFilters = cloneDeep(currentFilters);
            Object.keys(selectedOptions).forEach((key) => {
              if (intervalFilterKeys.includes(key)) {
                const value = selectedOptions[key];
                const filterIndex = newFilters.findIndex(
                  (currentFilter) => currentFilter.field === key
                );

                const isPercentageKey = key.includes('PRC_');
                const min = isPercentageKey ? inputFilters[key].min / 100 : inputFilters[key].min;
                const max = isPercentageKey ? inputFilters[key].max / 100 : inputFilters[key].max;
                const finalKey = key.replace('_INPUTS', '');
                const newFilter = {
                  field: finalKey,
                  logic: 'and',
                  filters: [
                    {
                      field: finalKey,
                      value: min,
                      operator: 'gte',
                    },
                    {
                      field: finalKey,
                      value: max,
                      operator: 'lte',
                    },
                  ],
                };
                let updates = {};

                if (value && filterIndex === -1) {
                  updates = { $push: [newFilter] };
                } else if (!value && filterIndex > -1) {
                  updates = {
                    $splice: [[filterIndex, 1]],
                  };
                }
                newFilters = update(newFilters, updates);
              }
            });
            return newFilters;
          },
        },
        impactedActivitiesFilter: {
          $apply: () => {
            const newFilters = [];
            if (selectedOptions.ID_TIPO_RESTRICCION || selectedOptions.RITMO_EST) {
              newFilters.push({
                logic: 'or',
                filters: [
                  { field: 'ID_TIPO_RESTRICCION', value: 0, operator: 'gt' },
                  { field: 'RITMO_EST', value: 0, operator: 'neq' },
                ],
              });
            }
            return newFilters;
          },
        },
        dateFilter: { $set: newDateFilter },
      })
    );
  };
  const onExpandChange = (event) => {
    const expanded = !event.value;
    const treeData = cloneDeep(
      tree[`${works.selected}_${dates.selected}_${gantt.selected}_${valorizationAdvance.selected}`]
    );
    const updatedNode = getNodeObjectToUpdate(event.dataItem.PATH.split('.'), 0, {
      expanded: { $set: expanded },
    });
    const newTree = update(treeData, updatedNode);

    setTree(
      update(tree, {
        [`${works.selected}_${dates.selected}_${gantt.selected}_${valorizationAdvance.selected}`]: {
          $set: newTree,
        },
      })
    );
  };

  const handleFilterChange = (event) => {
    setState(update(state, { filter: { $set: event.filter } }));
  };

  const treeData =
    tree[`${works.selected}_${dates.selected}_${gantt.selected}_${valorizationAdvance.selected}`] ||
    [];

  const isLoadingTable =
    isFetchingData ||
    works.isFetching ||
    gantt.isFetching ||
    dates.isFetching ||
    savingEdition ||
    loadingOpenOrCloseAdvance;

  const tableColumns = React.useMemo(
    () =>
      columns({
        activeOptions,
        validDates,
        isEditing,
        fieldsWithErrors,
        setActiveRow,
        activeRow,
        setActiveRestRow,
        setActiveValorizationRow,
        setActiveQualityCardRow,
        setActivePhotosRow,
        t,
        setActiveCurrentContractRow,
        setActiveMeasuredContractRow,
      }),
    [
      activeOptions,
      isEditing,
      validDates,
      fieldsWithErrors,
      setActiveRow,
      activeRow,
      setActiveRestRow,
      setActiveValorizationRow,
      setActiveQualityCardRow,
      setActivePhotosRow,
      setActiveCurrentContractRow,
      setActiveMeasuredContractRow,
    ]
  );

  const processData = useCallback(() => {
    return filterBy(
      treeData,
      [
        {
          logic: 'and',
          filters: [
            ...filter,
            ...(capFilter.length ? [{ logic: 'or', filters: capFilter }] : []),
            ...(intervalFilters.length ? intervalFilters : []),
            ...(impactedActivitiesFilter.length ? impactedActivitiesFilter : []),
            ...(state.dateFilter && get(state, 'dateFilter.filters.length', 0)
              ? [state.dateFilter]
              : []),
          ],
        },
      ],
      'children'
    );
  }, [tree, filter, capFilter, intervalFilters, works, dates, gantt, valorizationAdvance]);

  const onChangeFilter = (value, field, type = 'text', operator = 'contains') => {
    const filterIndex = filter.findIndex((currentFilter) => currentFilter.field === field);
    const newFilter = {
      field,
      operator,
      value: type === 'text' ? value : value ? Number(value) : null,
    };

    let updates =
      filterIndex === -1
        ? { $push: [newFilter] }
        : {
            [filterIndex]: {
              $set: newFilter,
            },
          };

    if (!value) {
      updates = {
        $splice: [[filterIndex, 1]],
      };
    }
    setState(
      update(state, {
        filter: updates,
        filters: {
          [field]: { $set: value },
        },
      })
    );
  };

  const updateTreeExpandedRows = (level, data) => {
    level = Number(level);
    let newTreeData = update(data, {});
    if (data[0].NIVEL < level || level === 0) {
      newTreeData = update(data, {
        $apply: (oldData) => {
          const newData = oldData.map((current) => ({
            ...current,
            expanded: true,
            ...(current.children
              ? { children: updateTreeExpandedRows(level, current.children) }
              : {}),
          }));

          return newData;
        },
      });
    } else if (level === data[0].NIVEL) {
      newTreeData = update(data, {
        $apply: (oldData) => {
          const newData = oldData.map((current) => ({
            ...current,
            expanded: false,
          }));
          return newData;
        },
      });
    }

    return newTreeData;
  };

  const onChangeLevel = (newLevel) => {
    const newTreeData = updateTreeExpandedRows(newLevel, treeData);
    setState(
      update(state, {
        tree: {
          [`${works.selected}_${dates.selected}_${gantt.selected}_${valorizationAdvance.selected}`]:
            { $set: newTreeData },
        },
        levelFilter: { $set: newLevel },
      })
    );
  };

  const clearFilters = () => {
    const newTreeData = updateTreeExpandedRows('', treeData);
    setState(
      update(state, {
        filter: { $set: [] },
        capFilter: { $set: [] },
        intervalFilters: { $set: [] },
        impactedActivitiesFilters: { $set: [] },
        dateFilter: { $set: null },
        filters: { $set: initialFilters },
        reset: { $set: true },
        tree: {
          [`${works.selected}_${dates.selected}_${gantt.selected}_${valorizationAdvance.selected}`]:
            { $set: newTreeData },
        },
        levelFilter: { $set: '' },
      })
    );
  };

  const finalTableData = processData();

  useEffect(() => {
    if (savingExcel) {
      setTimeout(() => {
        _export.save(treeToFlat(finalTableData, 'expanded', 'children'), tableColumns);
      }, 500);
    }
  }, [savingExcel]);

  const exportToExcel = () => {
    setState(update(state, { savingExcel: { $set: true } }));
  };

  const openOrCloseAdvance = (confirmed = false) => {
    if (!confirmed) {
      confirm({
        title: `${isClosedProjection ? 'Abrir' : 'Cerrar'} Proyección`,
        icon: <ExclamationCircleOutlined />,
        content: 'Confirma esta acción',
        onOk() {
          openOrCloseAdvance(true);
        },
      });
    } else {
      const requestBody = {
        USUARIO: foco_username,
        EMPRESA: foco_client,
        ID_OBR: works.selected,
        FECHA_AVA: get(dates, ['data', works.selected], []).find(
          (e) => e.ID_FECHA === dates.selected
        ).FECHA_AVA,
        ID_GANT: gantt.selected,
        ID_USU: userId,
      };
      setState(
        update(state, {
          loadingOpenOrCloseAdvance: { $set: true },
        })
      );
      message.loading(`${isClosedProjection ? 'Abriendo' : 'Cerrando'} Proyección`);

      const loadingUpdate = { loadingOpenOrCloseAdvance: { $set: false } };
      api
        .post(
          `api/Avances/${isClosedProjection ? 'SetAbrirPrograma' : 'SetCerrarPrograma'}`,
          requestBody,
          getHeaders()
        )
        .then((res) => {
          if (res.status === 200 && res.data === 'OK') {
            message.success(
              `La proyección se ha ${isClosedProjection ? 'abierto' : 'cerrado'} correctamente`
            );
            const index = dates.data[works.selected].findIndex(
              (e) => e.ID_FECHA === dates.selected
            );
            const updates = {
              dates: {
                data: {
                  [works.selected]: {
                    [index]: {
                      ESTADO_AVA: { $set: isClosedProjection ? 0 : 1 },
                    },
                  },
                },
              },
              ...loadingUpdate,
            };
            setIsClosedProjection(!isClosedProjection);
            getData(updates);
          } else {
            setState(update(state, loadingUpdate));
          }
        })
        .catch(() => {
          setState(update(state, loadingUpdate));
        });
    }
  };

  const disableButtons =
    !works.data ||
    !gantt.data[works.selected] ||
    !dates.data[works.selected] ||
    !valorizationAdvance.data[works.selected] ||
    isFetchingData ||
    works.isFetching ||
    gantt.isFetching ||
    dates.isFetching ||
    valorizationAdvance.isFetching ||
    !valorizationAdvance.selected ||
    !works.selected ||
    !gantt.selected ||
    !dates.selected ||
    savingExcel ||
    loadingOpenOrCloseAdvance ||
    savingEdition;
  const currentSelectedWork =
    works.selected &&
    ((works.data || []).find((e) => e.ID_OBR === works.selected) || {}).NOMABR_OBR;

  const date = (
    get(dates, ['data', works.selected], []).find((e) => e.ID_FECHA === dates.selected) || {}
  ).FECHA_AVA;
  return (
    <div className="advance-control">
      <Row gutter={[30, 30]}>
        <Col className="first-buttons-col mb-4" span={24}>
          <SelectWithTopLabel
            className="top-select"
            keepShowingLabel
            disabled={works.isFetching || isFetchingData || !works.data}
            loading={works.isFetching}
            value={works.selected}
            label={t('advanceControl.selectWork')}
            options={(works.data || []).map(({ ID_OBR, NOMABR_OBR }) => ({
              name: NOMABR_OBR,
              value: ID_OBR,
            }))}
            onChange={(value) => onChange(value, 'works')}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          />
          <SelectWithTopLabel
            className="top-select long"
            keepShowingLabel
            disabled={gantt.isFetching || isFetchingData || !gantt.data[works.selected]}
            loading={gantt.isFetching}
            value={gantt.selected}
            label={t('advanceControl.selectProgram')}
            options={(gantt.data[works.selected] || []).map(({ NOMBRE_GANT, ID_GANT }) => ({
              name: NOMBRE_GANT,
              value: ID_GANT,
            }))}
            onChange={(value) => onChange(value, 'gantt')}
          />
          <div className="select-date-container">
            <SelectWithTopLabel
              className="date-select"
              keepShowingLabel
              disabled={dates.isFetching || isFetchingData || !dates.data[works.selected]}
              loading={dates.isFetching}
              value={dates.selected}
              label={t('advanceControl.controlAt')}
              options={(dates.data[works.selected] || []).map(({ FECHA_AVA, ID_FECHA }) => ({
                name: FECHA_AVA,
                value: ID_FECHA,
              }))}
              onChange={(value, obj) => {
                onChange(value, 'dates', obj);
              }}
            />
            <div className="legend">
              <strong>{isClosedProjection && 'Proyección Cerrada'}</strong>
            </div>
          </div>
          {!!access.CREAR && (
            <NewAdvance
              disabled={!works.selected || !dates.selected}
              requestBody={{
                USUARIO: foco_username,
                EMPRESA: foco_client,
                ID_OBR: works.selected,
              }}
              isAdvanceControl
              updateDates={(newDate) => {
                const index = dates.data[works.selected].findIndex((e) =>
                  moment(newDate.FECHA_AVA, 'DD-MM-YYYY').isAfter(moment(e.FECHA_AVA, 'DD-MM-YYYY'))
                );
                setState(
                  update(state, {
                    dates: {
                      data: {
                        [works.selected]: {
                          $splice: [[index, 0, newDate]],
                        },
                      },
                      selected: { $set: newDate.ID_FECHA },
                    },
                  })
                );
                setAdvaceCreated(true);
              }}
              dates={get(dates, ['data', works.selected], []).map((e) => e.FECHA_AVA)}
              workDate={
                ((works.data || []).find((e) => e.ID_OBR === works.selected) || {}).FECINI_OBR
              }
            />
          )}

          <SelectWithTopLabel
            className="top-select ml-3 mr-0"
            keepShowingLabel
            disabled={valorizationAdvance.isFetching || isFetchingData}
            loading={valorizationAdvance.isFetching}
            value={valorizationAdvance.selected}
            label="Tipo Valorización"
            options={(valorizationAdvance.data[works.selected] || [])
              .filter((e) => e.ESTADO_VAL === 1)
              .map(({ NOMBRE_VAL, ID_VAL }) => ({
                name: NOMBRE_VAL,
                value: ID_VAL,
              }))}
            onChange={(value) => {
              setValorizationAdvance(update(valorizationAdvance, { selected: { $set: value } }));
            }}
          />
          {!!access.EDITAR && (
            <SetValorizationType
              valorizationAdvance={valorizationAdvance}
              client={foco_client}
              user={foco_username}
              getValorizationAdvance={getValorizationAdvance}
              selectedWork={works.selected}
            />
          )}
          {isEditing ? (
            <>
              <Button
                className="ml-auto"
                onClick={() => {
                  if (!savingEdition) {
                    resetAdvanceControlEdition();
                    setIsEditing(false);
                    setState(
                      update(state, {
                        tree: {
                          [`${works.selected}_${dates.selected}_${gantt.selected}_${valorizationAdvance.selected}`]:
                            {
                              $set: lastSavedTree[
                                `${works.selected}_${dates.selected}_${gantt.selected}_${valorizationAdvance.selected}`
                              ],
                            },
                        },
                        fieldsWithErrors: { $set: [] },
                      })
                    );
                  }
                }}
              >
                {t('common.cancel')}
              </Button>
              <SaveButton savingEdition={savingEdition} save={save} t={t} />
            </>
          ) : (
            <>
              <Button
                type="primary"
                className="ml-auto"
                onClick={() => {
                  if (disableButtons) {
                    message.warning(t('advanceControl.cantExecuteAction'));
                  } else {
                    getData();
                  }
                }}
              >
                {t('advanceControl.loadResults')}
              </Button>
              {((isClosedProjection && !!access.ABRIR) ||
                (!isClosedProjection && !!access.CERRAR)) && (
                <Button
                  type="primary"
                  onClick={() => {
                    const cantExecuteAction = disableButtons || !finalTableData?.length;

                    const isDefaultSelected =
                      get(gantt, ['data', works.selected], []).find((e) => e.PREDETER_GANT)
                        .ID_GANT === gantt.selected;
                    if (cantExecuteAction) {
                      message.warning(t('advanceControl.cantExecuteAction'));
                    } else if (!isDefaultSelected && !isClosedProjection) {
                      message.warning(t('advanceControl.cantCloseDefaultAction'));
                    } else {
                      openOrCloseAdvance(false);
                    }
                  }}
                  loading={loadingOpenOrCloseAdvance}
                >
                  {isClosedProjection ? t('common.open') : t('common.close')}
                </Button>
              )}
              {!!access.EDITAR && (
                <Button
                  type="primary"
                  onClick={() => {
                    const isDefaultSelected =
                      get(gantt, ['data', works.selected], []).find((e) => e.PREDETER_GANT)
                        .ID_GANT === gantt.selected;
                    const cantExecuteAction =
                      disableButtons || !finalTableData?.length || isClosedProjection;

                    if (cantExecuteAction) {
                      message.warning(t('advanceControl.cantExecuteAction'));
                    } else if (!isDefaultSelected) {
                      message.warning(t('advanceControl.cantEditDefaultAction'));
                    } else {
                      setIsEditing(true);
                    }
                  }}
                >
                  {t('common.edit')}
                </Button>
              )}
              <Button
                loading={savingExcel}
                type="primary"
                onClick={() => {
                  const cantExecuteAction = disableButtons || !finalTableData?.length;
                  if (cantExecuteAction) {
                    message.warning(t('advanceControl.cantExecuteAction'));
                  } else {
                    exportToExcel();
                  }
                }}
              >
                {t('common.export')}
              </Button>
              <Button onClick={() => setShowFilterBar(!showFilterBar)}>
                <i className="fa fa-filter mr-1" />
                {!showFilterBar ? t('common.filters') : t('common.hideFilters')}
              </Button>
              <ViewOptions
                defaultActiveOptions={activeOptions}
                updateOptions={(opt) => {
                  setActiveOptions(opt);
                  setState(
                    update(state, {
                      active: { $set: null },
                    })
                  );
                }}
                options={[
                  ...range(0, 15).map((c) => t(`advanceControl.viewOptions.filter${c}`)),
                  ...(access.EDITAR_MEDIDO ? [t('advanceControl.viewOptions.filter15')] : []),
                ]}
                activeOptionsProps={activeOptions}
                onClick={() => setState(update(state, { active: { $set: 'view' } }))}
                onClose={() => setState(update(state, { active: { $set: null } }))}
                show={active === 'view'}
              />
              <Legend t={t} />
            </>
          )}
        </Col>
      </Row>
      {showFilterBar && (
        <Row gutter={[30, 30]} className="m-0">
          <Col
            className={`second-buttons-col m-0 ${
              !tree || isLoadingTable || disableButtons ? 'blocked' : ''
            }`}
            span={24}
          >
            <ChapterFilter
              title={t('chapterFilter.title')}
              tree={treeData}
              buttonTitle={t('common.chapter')}
              defaultExpandItemValues={treeData && treeData.length ? [treeData[0].ID_INS] : []}
              onSave={applyCapFilters}
              onClick={() => setState(update(state, { active: { $set: 'chapter' } }))}
              onClose={() => setState(update(state, { active: { $set: null } }))}
              show={active === 'chapter'}
              nameKey="NOMBRE_INS"
              idKey="ID_INS"
              disableButton={isLoadingTable || !treeData.length}
              returnObject
              reset={reset}
            />
            <ExtraFilters
              title={t('advanceControl.advancedFilters.buttonTitle')}
              allowSelectAll={false}
              buttonTitle={t('common.moreFilters')}
              elementTitleKey="TITLE"
              elementKey="ID_INS"
              onSave={applyExtraFilters}
              options={[]}
              showSearch={false}
              onClick={() => setState(update(state, { active: { $set: 'extraFilters' } }))}
              onClose={() => setState(update(state, { active: { $set: null } }))}
              show={active === 'extraFilters'}
              disableButton={isLoadingTable || !treeData.length}
              reset={reset}
            />
            <div className="d-flex search-inputs">
              <InputWithTopLabel
                className="id-filter mr-2"
                onChange={(value) => onChangeFilter(value, 'NUM_FILA', 'number', 'eq')}
                label="ID"
                keepShowingLabel
                maxlength={5}
                disabled={!treeData.length}
                placeholder={null}
                value={filters.NUM_FILA}
              />
              <InputWithTopLabel
                className="name-filter mr-2"
                onChange={(value) => onChangeFilter(value, 'NOMBRE_INS')}
                label={t('advanceControl.taskName')}
                keepShowingLabel
                disabled={!treeData.length}
                placeholder={null}
                value={filters.NOMBRE_INS}
              />
              <div className="d-flex align-items-center mr-2">
                <SelectWithTopLabel
                  className="top-select"
                  defaultValue=""
                  value={levelFilter}
                  keepShowingLabel
                  label={t('advanceControl.schemaLevel')}
                  options={schemaLevels}
                  onChange={onChangeLevel}
                  disabled={!treeData.length}
                />
              </div>
              <Button onClick={clearFilters}>
                <i
                  className={`fa fa-filter ml-1 mr-3 ${
                    isLoadingTable || !treeData.length ? 'disabled' : ''
                  }`}
                />
                {t('common.cleanFilters')}
              </Button>
            </div>
          </Col>
        </Row>
      )}
      <Row gutter={[30, 30]}>
        <Col span={24}>
          <ExcelExport
            ref={(exporter) => (_export = exporter)}
            hierarchy
            onExportComplete={() => setState(update(state, { savingExcel: { $set: false } }))}
          >
            <TreeList
              className={`kendo-table ${isLoadingTable ? 'loading' : ' '}`}
              style={{
                height: `${window.innerHeight - (showFilterBar ? 200 : 98)}px`,
                overflow: 'auto',
                width: `${window.innerWidth - 20}px`,
              }}
              resizable
              tableProps={{
                style: {
                  tableLayout: 'fixed',
                  width: getTableWidth(tableColumns),
                },
              }}
              onFilterChange={handleFilterChange}
              noRecords={t('common.dataEmpty')}
              rowHeight={28}
              scrollable="virtual"
              filter={filter}
              data={finalTableData}
              expandField="expanded"
              subItemsField="children"
              onExpandChange={onExpandChange}
              columns={tableColumns}
            />
          </ExcelExport>
        </Col>
      </Row>
      <RestVencInfo
        work={currentSelectedWork}
        record={activeRestRow}
        client={foco_client}
        user={foco_username}
        onCancel={() => setActiveRestRow(null)}
      />
      <PhotosInfo
        work={currentSelectedWork}
        record={activePhotosRow}
        client={foco_client}
        user={foco_username}
        date={date}
        onCancel={() => setActivePhotosRow(null)}
      />
      <ValorizationInfo
        work={currentSelectedWork}
        record={activeValorizationRow}
        onCancel={() => setActiveValorizationRow(null)}
      />
      <QualityCard
        work={currentSelectedWork}
        record={activeQualityCardRow}
        onCancel={() => setActiveQualityCardRow(null)}
        user={foco_username}
        client={foco_client}
      />
      <ContractView
        work={currentSelectedWork}
        record={activeMeasuredContractRow}
        onCancel={() => setActiveMeasuredContractRow(null)}
        user={foco_username}
        client={foco_client}
        date={date}
        editRow={editRow}
      />
      <ContractView
        work={currentSelectedWork}
        record={activeCurrentContractRow}
        onCancel={() => setActiveCurrentContractRow(null)}
        user={foco_username}
        client={foco_client}
        date={date}
      />
      <RowDetail
        user={foco_username}
        idGant={gantt.selected}
        rowData={activeRow}
        client={foco_client}
        onClose={() => setActiveRow(null)}
        indexes={
          indexes[
            `${works.selected}_${dates.selected}_${gantt.selected}_${valorizationAdvance.selected}`
          ]
        }
      />
    </div>
  );
};

AdvanceControl.propTypes = advanceControlProptypes;

export default connect(
  (store) => ({
    auth: store.auth,
  }),
  (dispatch) => ({
    setInformationFromAttendanceURL: bindActionCreators(
      setInformationFromAttendanceURLAction,
      dispatch
    ),
    resetAdvanceControlEdition: bindActionCreators(resetAdvanceControlEditionAction, dispatch),
    saveAdvanceControlEdition: bindActionCreators(saveAdvanceControlEditionAction, dispatch),
  })
)(AdvanceControl);
