import update from 'immutability-helper';

export const GET_ATTENDANCE = 'GET_ATTENDANCE';
export const GET_ATTENDANCE_TYPES = 'GET_ATTENDANCE_TYPES';
export const SET_ATTENDANCE = 'SET_ATTENDANCE';
export const GET_CLOSED_ATTENDANCE = 'GET_CLOSED_ATTENDANCE';
export const SET_CLOSED_ATTENDANCE = 'SET_CLOSED_ATTENDANCE';
export const SET_ERROR_ATTENDANCE = 'SET_ERROR_ATTENDANCE';
export const SET_MASS_ATTENDANCE = 'SET_MASS_ATTENDANCE';
export const SET_ATTENDANCE_VIEW_TYPE = 'SET_ATTENDANCE_VIEW_TYPE';
export const SET_OPENED_ATTENDANCE = 'SET_OPENED_ATTENDANCE';

const initialState = {
  attendanceTypes: null,
  attendanceViewType: null,
  closedAttendance: null,
  data: null,
  error: '',
  isFetching: true,
};

const attendance = (state = initialState, action) => {
  switch (action.type) {
    case SET_ERROR_ATTENDANCE: {
      const { error } = action.payload;
      return update(state, {
        error: { $set: error },
        isFetching: { $set: false },
      });
    }

    case GET_ATTENDANCE_TYPES: {
      const { attendanceTypes } = action.payload;

      return update(state, {
        attendanceTypes: { $set: attendanceTypes },
      });
    }

    case GET_ATTENDANCE: {
      const { attendance: data } = action.payload;
      return update(state, {
        data: { $set: data },
        isFetching: { $set: false },
      });
    }

    case GET_CLOSED_ATTENDANCE: {
      const { closedAttendance } = action.payload;

      return update(state, {
        closedAttendance: { $set: closedAttendance },
      });
    }
    case SET_ATTENDANCE_VIEW_TYPE: {
      const { attendanceViewType } = action.payload;
      return update(state, {
        attendanceViewType: { $set: attendanceViewType || 1 },
      });
    }
    case SET_CLOSED_ATTENDANCE: {
      const { closedAttendance } = action.payload;

      return update(state, {
        closedAttendance: { $push: [closedAttendance] },
      });
    }

    case SET_ATTENDANCE: {
      const { attendance: newAttendance } = action.payload;
      const index = state.data.findIndex((e) => e.ID_AST === newAttendance.ID_AST);
      const deleteAttendance = newAttendance.HH_TRA === 0 && newAttendance.ID_CNS === 5;

      return update(state, {
        data: {
          ...(index !== -1
            ? {
                ...(deleteAttendance
                  ? { $splice: [[index, 1]] }
                  : { [index]: { $set: newAttendance } }),
              }
            : { $push: [newAttendance] }),
        },
      });
    }

    case SET_MASS_ATTENDANCE: {
      const { attendances } = action.payload;
      return update(state, {
        data: {
          $apply: (data) => {
            let newData = data;
            attendances.forEach((e) => {
              newData = update(newData, {
                $push: [e],
              });
            });

            return newData;
          },
        },
      });
    }

    case SET_OPENED_ATTENDANCE: {
      const { openedAttendance } = action.payload;
      const index = state.closedAttendance.findIndex((e) => e.DIA_CIERRE === openedAttendance);
      return update(state, {
        closedAttendance: { $splice: [[index, 1]] },
      });
    }
    default:
      return state;
  }
};
export default attendance;
