import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { TreeList, filterBy } from '@progress/kendo-react-treelist';
import { Col, Checkbox, message, Row } from 'antd';
import update from 'immutability-helper';
import { LoadingOutlined } from '@ant-design/icons';
import ChapterFilter from '../../../components/ChapterFilter';
import InputWithTopLabel from '../../../components/InputWithTopLabel';
import { getChildren, getNodeObjectToUpdate } from '../../../utils/budgetAndProductionHelpers';
import { getInitialState } from '../helpers';
import api, { getHeaders } from '../../../utils/api';
import { columnsRight } from '../columns';

const RightTable = ({
  record,
  showFilterBar,
  user,
  client,
  date,
  isClosedProjection,
  editLeftTable,
  activeViewOptions,
  canEdit,
}) => {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(getInitialState());
  const [data, setData] = useState([]);
  const [filtersState, setFiltersState] = useState({
    filter: [],
    filters: { NOMBRE_UCO: '', RAIZ: '' },
  });

  const [hideFullAdvanceRows, setHideFullAdvanceRows] = useState(true);
  const { filter, filters } = filtersState;
  const { expandedRowKeys, capFilter, active } = state;

  const createTreeData = useCallback(
    (treeData) => {
      if (treeData && treeData.length > 0) {
        let treeObject = {};
        const treeIndexes = [];
        treeData = treeData.map((e) => {
          treeIndexes.push(e.ID_UCO);
          return { ...e, expanded: true };
        });
        const initialExpandedRowKeys = [];

        treeData.forEach((item) => {
          if (treeObject[item.UCO_SUP]) {
            treeObject = update(treeObject, {
              [item.UCO_SUP]: {
                $push: [item],
              },
            });
          } else {
            treeObject = update(treeObject, {
              [item.UCO_SUP]: {
                $set: [item],
              },
            });
          }
          if (item.ID_PAR_UCO === 0) {
            initialExpandedRowKeys.push(item.ID_UCO);
          }
        });

        const newTree = [];
        const paths = {
          [treeObject[0][0].ID_UCO]: '0',
        };
        const savePath = (id, path) => {
          if (!paths[id]) {
            paths[id] = path;
          }
        };
        treeObject[0].forEach((current, i) => {
          newTree.push({
            ...current,
            PATH: `${i}`,
            children: getChildren(current.ID_UCO, treeObject, `${i}`, savePath, null, 'ID_UCO'),
          });
        });
        setData(newTree);
        setState(
          update(state, {
            expandedRowKeys: {
              $set: initialExpandedRowKeys,
            },
            expanded: {
              $set: initialExpandedRowKeys,
            },
            isFetchingData: { $set: false },
            tree: {
              $set: newTree,
            },
            indexes: { $set: treeIndexes },
          })
        );
      } else {
        setState(
          update(state, {
            expandedRowKeys: { $set: [] },
            isFetchingData: { $set: false },
            tree: {
              $set: [],
            },
          })
        );
      }
    },
    [expandedRowKeys]
  );
  const onExpandChange = (event) => {
    const expanded = !event.value;
    const updatedNode = getNodeObjectToUpdate(event.dataItem.PATH.split('.'), 0, {
      expanded: { $set: expanded },
    });
    const newTree = update(data, updatedNode);

    setData(newTree);
  };

  const editCell = (fields, row) =>
    new Promise((resolve, reject) => {
      const currentRow = fields[0];
      const requestBody = {
        USUARIO: user,
        EMPRESA: client,
        ID_AVA: date,
        ID_PAR_UCO: currentRow.ID_PAR_UCO,
        PRC_AVANCE: currentRow.PRC_AVANCE,
        CANTIDAD_AVANCE: currentRow.CANTIDAD_AVANCE,
      };

      const errorMessage = `Error editando el recinto ${row.NOMBRE_UCO}`;
      api
        .post('api/Avances/SetAvanceRecinto', requestBody, getHeaders())
        .then((res) => {
          if (res.status === 200 && res.data.ID_INS) {
            resolve(res.data);
            message.destroy();
            message.success(`El recinto ${row.NOMBRE_UCO} se ha editado correctamente`);
          }
        })
        .catch((err) => {
          reject();
          console.log('err', err);
          message.error(errorMessage);
        });
    });

  const applyCapFilters = useCallback(
    (selectedOptions) => {
      let updates = [];
      if (selectedOptions.length) {
        updates = selectedOptions.map((e) => ({
          field: 'PATH',
          operator: 'startswith',
          value: `${e.PATH}.`,
        }));
      }

      setState(update(state, { capFilter: { $set: updates }, active: { $set: null } }));
    },
    [capFilter, active]
  );
  const getData = (withLoading = true) => {
    const requestBody = {
      USUARIO: user,
      EMPRESA: client,
      ID_INS: record.ID_INS,
      ID_AVA: date,
    };
    if (withLoading) setLoading(true);
    api
      .post('api/Avances/GetAvaRecintosPartida', requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          const newData = res.data.map((e) => ({
            ...e,
            PRC_AVANCE_100: e.NIVEL !== 3 ? 100 : e.PRC_AVANCE,
          }));
          createTreeData(newData);
        }
      })
      .catch(() => {
        message.error('Error obteniendo la información');
      })
      .finally(() => {
        if (withLoading) setLoading(false);
      });
  };
  useEffect(() => {
    if (record) {
      getData();
    } else {
      setData([]);
    }
  }, [record]);

  const processData = useCallback(() => {
    return filterBy(
      data,
      [
        {
          logic: 'and',
          filters: [
            ...filter,
            ...(capFilter.length ? [{ logic: 'or', filters: capFilter }] : []),
            ...(hideFullAdvanceRows
              ? [{ field: 'PRC_AVANCE_100', value: 100, operator: 'lt' }]
              : []),
          ],
        },
      ],
      'children'
    );
  }, [data, hideFullAdvanceRows, filter, capFilter]);

  const onChangeFilter = (value, field, type = 'text', operator = 'contains') => {
    const filterIndex = filter.findIndex((currentFilter) => currentFilter.field === field);
    const newFilter = {
      field,
      operator,
      value: type === 'text' ? value : value ? Number(value) : null,
    };

    let updates =
      filterIndex === -1
        ? { $push: [newFilter] }
        : {
            [filterIndex]: {
              $set: newFilter,
            },
          };

    if (!value) {
      updates = {
        $splice: [[filterIndex, 1]],
      };
    }
    setFiltersState(
      update(filtersState, {
        filter: updates,
        filters: {
          [field]: { $set: value },
        },
      })
    );
  };

  const tableColumnsRight = columnsRight({
    canEdit: canEdit && !isClosedProjection,
    editCell,
    activeViewOptions,
    editLeftTable,
    activeRow: record,
  });

  const finalTableData = processData();
  return (
    <Col span={12} className="advance-income-col p-0 m-0 second">
      <>
        {showFilterBar && (
          <Row gutter={[30, 30]} className="m-0 mt-2">
            <Col className="second-buttons-col m-0 d-flex align-items-center" span={24}>
              <ChapterFilter
                title="Filtro Recinto"
                tree={data}
                buttonTitle="Recinto"
                defaultExpandItemValues={data && data.length ? [data[0].ID_INS] : []}
                onSave={applyCapFilters}
                onClick={() => setState(update(state, { active: { $set: 'chapter' } }))}
                onClose={() => setState(update(state, { active: { $set: null } }))}
                show={active === 'chapter'}
                nameKey="NOMBRE_UCO"
                idKey="ID_UCO"
                conditional={(e) => e.ID_PAR_UCO === 0}
                /*      disableButton={isLoadingTable || !treeData.length} */
                returnObject
                /*  reset={reset} */
              />
              <InputWithTopLabel
                className="name-filter ml-2"
                onChange={(value) => onChangeFilter(value, 'NOMBRE_UCO')}
                label="Recinto"
                keepShowingLabel
                placeholder={null}
                value={filters.NOMBRE_INS}
                withTimeOut={false}
              />
              <Checkbox
                checked={hideFullAdvanceRows}
                className="ml-auto"
                onChange={(e) => setHideFullAdvanceRows(e.target.checked)}
              >
                Ocultar Recintos al 100%
              </Checkbox>
            </Col>
          </Row>
        )}
        <TreeList
          className={`kendo-table  mx-auto my-0 ${loading ? 'loading' : ' '}`}
          style={{
            height: `${window.innerHeight - (showFilterBar ? 50 : 120)}px`,
            overflow: 'auto',
          }}
          resizable
          tableProps={{
            style: {
              tableLayout: 'fixed',
            },
          }}
          noRecords="No hay datos disponibles"
          rowHeight={28}
          scrollable="virtual"
          data={loading ? [] : finalTableData}
          expandField="expanded"
          subItemsField="children"
          onExpandChange={onExpandChange}
          columns={tableColumnsRight}
        />
      </>
    </Col>
  );
};

RightTable.propTypes = {
  showFilterBar: PropTypes.bool.isRequired,
  record: PropTypes.object.isRequired,
  user: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  isClosedProjection: PropTypes.bool.isRequired,
  editLeftTable: PropTypes.func.isRequired,
  activeViewOptions: PropTypes.object.isRequired,
  canEdit: PropTypes.bool.isRequired,
};

export default React.memo(RightTable);
