import React, { useState, useEffect, useCallback } from 'react';
import { Modal, message } from 'antd';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import api, { getHeaders } from '../../../utils/api';

const RestVencInfo = ({ record, onCancel, work, user, client }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const getData = useCallback(async () => {
    setLoading(true);
    const requestBody = {
      USUARIO: user,
      EMPRESA: client,
      ID_INS: record.ID_INS,
    };
    const response = await api.post('api/Avances/GetAvaRestriccion', requestBody, getHeaders());
    setLoading(false);
    if (response && response.status === 200) {
      setData(response.data[0]);
    } else {
      message.error(t('common.requestError'));
    }
  }, [record]);

  useEffect(() => {
    if (record) getData();
  }, [record]);

  if (!record) return null;

  return (
    <Modal title={t('advanceControl.restVencInfo.title')} visible footer={null} onCancel={onCancel}>
      {loading ? (
        <div className="w-100 d-flex justify-content-center py-5">
          <LoadingOutlined />
        </div>
      ) : (
        data && (
          <div>
            <div className="mb-2">
              <strong>{t('common.work')}:</strong> {work}
            </div>
            <div className="mb-2">
              <strong>{t('common.itemCode')}:</strong> {data.CODIGO_INS}
            </div>
            <div className="mb-2">
              <strong>{t('common.itemName')}:</strong> {record.NOMBRE_INS}
            </div>
            <div className="mb-2">
              <strong>{`${t('advanceControl.restrictionType1')} ${t(
                'advanceControl.restrictionType2'
              )}`}</strong>{' '}
              {data.NOMBRE_TIP_RES}
            </div>{' '}
            <div className="mb-2">
              <strong>Responsable:</strong> {data.NOMBRE_RESP}
            </div>
            <div className="mb-2">
              <strong>{t('common.detail')}:</strong> {data.DETALLE_REST}
            </div>
            <div className="mb-2">
              <strong>{t('advanceControl.restVencInfo.estimatedDate')}</strong> {data.FECHA_EST_LIB}
            </div>
          </div>
        )
      )}
    </Modal>
  );
};

RestVencInfo.propTypes = {
  record: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  work: PropTypes.object.isRequired,
  user: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
};

export default RestVencInfo;
