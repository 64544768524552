import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import { Column, HeaderCell, Cell } from 'rsuite-table';
import { Tooltip, message } from 'antd';
import { useTranslation } from 'react-i18next';
import Input from '../../../components/InputWithTopLabel';
import Table from '../../../components/TableExtend';
import api, { getHeaders } from '../../../utils/api';

import './index.scss';

const resctrictionTypes = {
  0: 'FC',
  1: 'CC',
  2: 'FF',
  3: 'CF',
};

const columns = (activityKey, indexes, t) => [
  <Column className="border-right" flexGrow={1} verticalAlign="middle" align="center" fixed>
    <HeaderCell>{t('common.line')}</HeaderCell>
    <Cell dataKey="LINK">{(record) => indexes.findIndex((e) => e === record.ID_VINC) + 1}</Cell>
  </Column>,
  <Column className="border-right" width={200} verticalAlign="middle" align="center" fixed>
    <HeaderCell>{activityKey}</HeaderCell>
    <Cell dataKey="NOMBRE_VINC">
      {(record) => {
        return (
          <Tooltip
            placement="topLeft"
            destroyTooltipOnHide={{ keepParent: false }}
            title={record.NOMBRE_VINC}
          >
            <span className="value">{record.NOMBRE_VINC}</span>
          </Tooltip>
        );
      }}
    </Cell>
  </Column>,
  <Column className="border-right" flexGrow={1} verticalAlign="middle" align="center" fixed>
    <HeaderCell>{t('common.linkType')}</HeaderCell>
    <Cell dataKey="LINK">
      {(record) => {
        return resctrictionTypes[record.TIPO_VINC];
      }}
    </Cell>
  </Column>,
  <Column className="border-right" flexGrow={1} verticalAlign="middle" align="center" fixed>
    <HeaderCell>{t('advanceControl.rowDetail.delayDays')}</HeaderCell>
    <Cell dataKey="DIAS_VINC">
      {(record) => {
        const value = record.DIAS_VINC;
        const className = value < 0 ? 'negative' : '';
        return <span className={className}>{value}</span>;
      }}
    </Cell>
  </Column>,
  <Column className="border-right" flexGrow={1} verticalAlign="middle" align="center" fixed>
    <HeaderCell>{t('advanceControl.rowDetail.delayHours')}</HeaderCell>
    <Cell dataKey="LINK">
      {(record) => {
        const value = record.HORAS_VINC;
        const className = value < 0 ? 'negative' : '';
        return <span className={className}>{value}</span>;
      }}
    </Cell>
  </Column>,
];

const RowDetail = ({ rowData, onClose, user, client, idGant, indexes }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const { t } = useTranslation();

  const getData = useCallback(async () => {
    setLoading(true);

    const requestBody = {
      USUARIO: user,
      EMPRESA: client,
      ID_INS: rowData.ID_INS,
      ID_GANT: idGant,
    };
    const result = await api.post('api/Avances/GetAvaDetallePartida', requestBody, getHeaders());
    if (result.status === 200) {
      const dataType1 = [];
      const dataType2 = [];
      result.data.forEach((e) => {
        if (e.TIPO !== 1) {
          if (e.TIPO === 2) {
            dataType1.push(e);
          } else {
            dataType2.push(e);
          }
        }
      });
      setData([dataType1, dataType2]);
    } else {
      message.error(t('common.requestError'));
    }
    setLoading(false);
  }, [rowData]);

  useEffect(() => {
    if (rowData) {
      getData(rowData);
    }
  }, [rowData]);

  if (!rowData) return null;
  const { NOMBRE_INS, DURACION, COMIENZO, FIN } = rowData;
  return (
    <div className="row-detail p-4">
      {loading ? (
        <LoadingOutlined />
      ) : (
        <div className="row-detail-content">
          <i className="fas fa-times" onClick={onClose} />
          <div className="row-detail-header d-flex mb-4">
            <Input disabled label={t('common.activity')} value={NOMBRE_INS} keepShowingLabel />
            <Input disabled label={t('common.duration')} value={DURACION} keepShowingLabel />
            <Input disabled label={t('common.beginning')} value={COMIENZO} keepShowingLabel />
            <Input disabled label={t('common.finished')} value={FIN} keepShowingLabel />
          </div>
          <div className="tables-container d-flex">
            <div className="table-wrapper">
              {data[0] && (
                <Table
                  height={180}
                  className="common-table"
                  hover={false}
                  headerHeight={40}
                  rowHeight={32}
                  rowKey="ID_INS"
                  bordered
                  shouldUpdateScroll={false}
                  locale={{
                    emptyMessage: t('common.dataEmpty'),
                    loading: `${t('common.loading')}...`,
                  }}
                  data={data[0]}
                  rowClassName={() => {
                    return '';
                  }}
                >
                  {columns(t('advanceControl.rowDetail.predecessorActivity'), indexes, t)}
                </Table>
              )}
            </div>
            <div className="table-wrapper">
              {data[1] && (
                <Table
                  height={180}
                  hover={false}
                  className="common-table"
                  headerHeight={40}
                  rowHeight={32}
                  rowKey="ID_INS"
                  bordered
                  shouldUpdateScroll={false}
                  locale={{
                    emptyMessage: t('common.dataEmpty'),
                    loading: `${t('common.loading')}...`,
                  }}
                  data={data[1]}
                  rowClassName={() => {
                    return '';
                  }}
                >
                  {columns(t('advanceControl.rowDetail.successorActivity'), indexes, t)}
                </Table>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

RowDetail.propTypes = {
  rowData: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  idGant: PropTypes.number.isRequired,
  indexes: PropTypes.array.isRequired,
};

export default RowDetail;
