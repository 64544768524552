import React from 'react';
import './index.scss';

const BiReport = () => {
  return (
    <div className="report-bi">
      <iframe
        title="iFrame"
        width="100%"
        height="100%"
        src="https://app.powerbi.com/view?r=eyJrIjoiZWFmYTJlMGItZGZmMC00ODUwLWI1NWYtNzdkMGE2NjIzNWI3IiwidCI6ImQxOGM5NmI1LWQ1NmItNGU3MS04OWQ0LTQ4YjhkNGY3NmI5NSIsImMiOjR9"
        frameBorder="0"
        allowFullScreen="true"
      />
    </div>
  );
};
export default BiReport;
