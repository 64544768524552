import update from 'immutability-helper';

export const GET_ITEMS_DATA = 'GET_ITEMS_DATA';
export const GET_MANAGER = 'GET_MANAGER';
export const GET_CONFIGURACION = 'GET_CONFIGURACION';
export const SET_ERROR_ENCLOSURE = 'SET_ERROR_ENCLOSURE';
export const GET_LISTADO_PANEL = 'GET_LISTADO_PANEL';
export const SET_PANEL = 'SET_PANEL';
export const GET_OPTIONS = 'GET_OPTIONS';
export const GET_PROJECT_USR = 'GET_PROJECT_USR';

const initialState = {
  itemsData: null,
  manager: null,
  configuracion: null,
  panel: null,
  salida: null,
  project: null,
  optionsM: null,
  isFetching: true,
  error: '',
};

const panelcontrol = (state = initialState, action) => {
  switch (action.type) {
    case SET_ERROR_ENCLOSURE: {
      const { error } = action.payload;
      return update(state, {
        error: { $set: error },
        isFetching: { $set: false },
      });
    }
    case GET_PROJECT_USR: {
      const { project } = action.payload;
      return update(state, {
        project: { $set: project },
        isFetching: { $set: false },
      });
    }
    case GET_OPTIONS: {
      const { optionsM } = action.payload;
      return update(state, {
        optionsM: { $set: optionsM },
        isFetching: { $set: false },
      });
    }
    case GET_LISTADO_PANEL: {
      const { panel } = action.payload;
      return update(state, {
        panel: { $set: panel },
        isFetching: { $set: false },
      });
    }
    case SET_PANEL: {
      const { salida } = action.payload;
      return update(state, {
        salida: { $set: salida },
        isFetching: { $set: false },
      });
    }
    case GET_ITEMS_DATA: {
      const { itemsData } = action.payload;
      return update(state, {
        itemsData: { $set: itemsData },
        isFetching: { $set: false },
      });
    }
    case GET_MANAGER: {
      const { manager } = action.payload;
      return update(state, {
        manager: { $set: manager },
        isFetching: { $set: false },
      });
    }
    case GET_CONFIGURACION: {
      const { configuracion } = action.payload;
      return update(state, {
        configuracion: { $set: configuracion },
        isFetching: { $set: false },
      });
    }
    default:
      return state;
  }
};
export default panelcontrol;
