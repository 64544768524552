import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Input } from 'antd';
import LinesEllipsis from 'react-lines-ellipsis';
import { setAdvance as setAdvanceAction } from '../../../actions/advanceMatrix';
import './index.scss';

const AdvanceTable = ({
  advanceDate,
  dataTable,
  fixedColumn,
  fixedColumnWidth,
  header,
  heightHeader,
  loading,
  setAdvance,
}) => {
  const [cellToEdit, setCellToEdit] = useState(null);
  const [value, setValue] = useState('');

  const columnWidh = 40;
  const tableRef = useRef(null);
  const fixedColumnRef = useRef(null);
  const headerRef = useRef(null);
  const headerInFixedColumnRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    tableRef.current.addEventListener('scroll', () => {
      fixedColumnRef.current.style.left = `${tableRef.current.scrollLeft}px`;
      headerRef.current.style.top = `${tableRef.current.scrollTop}px`;
      headerInFixedColumnRef.current.style.top = `${tableRef.current.scrollTop}px`;
    });
  }, []);

  useEffect(() => {
    if (cellToEdit) {
      inputRef.current.focus();
      inputRef.current.select();

      setTimeout(() => {
        inputRef.current.select();
      }, 10);

      setValue(cellToEdit.PORCEN_DAV);
    }
  }, [cellToEdit]);

  const setAdvanceInCell = () => {
    if (value.length) {
      setAdvance(advanceDate, cellToEdit.ID_INS, cellToEdit.ID_UCO, value);
    }
    setValue('');
    setCellToEdit(null);
  };

  return (
    <div className="advanceMatrixTable">
      {loading && (
        <div className="spinerContainer" style={{ height: window.innerHeight - 100 }}>
          <div className="spiner">
            <LoadingOutlined />
          </div>
          Cargando...
        </div>
      )}

      <div className="tableContainer" ref={tableRef} style={{ height: window.innerHeight - 100 }}>
        <div
          ref={headerRef}
          style={{
            minHeight: `${heightHeader}px`,
            maxHeight: `${heightHeader}px`,
            marginLeft: fixedColumnWidth,
          }}
          className="headerContainer"
        >
          {header.map((headerTitle) => (
            <div
              style={{ minWidth: `${columnWidh}px` }}
              className="headerText"
              key={`headerTitle-${headerTitle}`}
            >
              <span>{headerTitle}</span>
            </div>
          ))}
        </div>
        <div>
          <div className="columnContainer">
            <div
              ref={fixedColumnRef}
              className="fixedColumn"
              style={{ minWidth: `${fixedColumnWidth}px`, maxWidth: `${fixedColumnWidth}px` }}
            >
              <div
                ref={headerInFixedColumnRef}
                className="headerInFixedColumn"
                style={{
                  height: `${heightHeader}px`,
                  width: `${fixedColumnWidth}px`,
                  backgroundColor: '#fff',
                  zIndex: 3,
                }}
              >
                PARTIDAS
              </div>
              <div style={{ paddingTop: `${heightHeader}px` }} />
              <div className="column" style={{ flexDirection: 'column' }}>
                {fixedColumn.map((cellTitle, index) => (
                  <span
                    className="cellContainer cellInFixedColumn"
                    style={{ minHeight: `${columnWidh}px`, maxHeight: `${columnWidh}px` }}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`cellTitle-${cellTitle}${index}`}
                  >
                    <div style={{ width: '100%', minWidth: '100%', position: 'relative' }}>
                      <LinesEllipsis
                        text={cellTitle}
                        maxLine="1"
                        ellipsis={
                          <span className="dotdotdot" data-tooltip={cellTitle}>
                            ...
                          </span>
                        }
                        trimRight
                        basedOn="letters"
                      />
                    </div>
                  </span>
                ))}
              </div>
            </div>
          </div>
          {dataTable.length === 0 ? (
            <div style={{ textAlign: 'center' }}>No hay datos para mostrar</div>
          ) : (
            <div
              style={{
                display: 'flex',
                paddingLeft: `${fixedColumnWidth}px`,
                paddingTop: `${heightHeader}px`,
                flexDirection: 'column',
              }}
            >
              {dataTable.map((row, index) => (
                <div
                  className="columnContainer"
                  style={{ minHeight: `${columnWidh}px`, maxHeight: `${columnWidh}px` }}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`row-${index}`}
                >
                  <div className="column">
                    {row.cells.map((cell, cellIndex) => (
                      <>
                        {cellToEdit &&
                        cellToEdit.rowIndex === index &&
                        cellToEdit.cellIndex === cellIndex ? (
                          <span
                            style={{
                              minWidth: `${columnWidh}px`,
                              maxWidth: `${columnWidh}px`,
                              minHeight: `${columnWidh}px`,
                              maxHeight: `${columnWidh}px`,
                            }}
                          >
                            <Input
                              style={{ minHeight: `${columnWidh}px` }}
                              className="input"
                              ref={inputRef}
                              value={value}
                              onChange={(e) => {
                                if (
                                  e.target.value.length &&
                                  /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/.test(e.target.value) &&
                                  e.target.value >= 0 &&
                                  e.target.value <= 100
                                ) {
                                  setValue(e.target.value);
                                }
                              }}
                              onBlur={() => setAdvanceInCell()}
                              onPressEnter={() => setAdvanceInCell()}
                            />
                          </span>
                        ) : (
                          <span
                            key={`cell-${row.ID_INS}${cell.ID_UCO}`}
                            className="cellContainer cellInNormalColumn"
                            style={{
                              minWidth: `${columnWidh}px`,
                              maxWidth: `${columnWidh}px`,
                              minHeight: `${columnWidh}px`,
                              maxHeight: `${columnWidh}px`,
                              backgroundColor: cell.CELL_COLOR,
                              cursor: typeof cell.PORCEN_DAV === 'number' ? 'pointer' : 'default',
                              color: cell.TEXT_COLOR,
                            }}
                            onClick={() => {
                              if (typeof cell.PORCEN_DAV === 'number') {
                                setCellToEdit({
                                  ID_INS: row.ID_INS,
                                  cellIndex,
                                  rowIndex: index,
                                  ...cell,
                                });
                              }
                            }}
                          >
                            {typeof cell.PORCEN_DAV === 'number' ? `${cell.PORCEN_DAV}%` : ''}
                          </span>
                        )}
                      </>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

AdvanceTable.propTypes = {
  advanceDate: PropTypes.number.isRequired,
  dataTable: PropTypes.arrayOf(PropTypes.object).isRequired,
  fixedColumn: PropTypes.arrayOf(PropTypes.string).isRequired,
  fixedColumnWidth: PropTypes.number.isRequired,
  header: PropTypes.arrayOf(PropTypes.string).isRequired,
  heightHeader: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  setAdvance: PropTypes.func.isRequired,
};

export default connect(
  () => ({}),
  (dispatch) => ({
    setAdvance: bindActionCreators(setAdvanceAction, dispatch),
  })
)(AdvanceTable);
