import _ from 'lodash';
import { message } from 'antd';
import get from 'lodash/get';
import api, { getHeaders } from '../utils/api';
import {
  GET_ACCOUNT_COST,
  GET_ACCOUNT_COST_ERROR,
  ACCOUNT_COST_FETCHED,
  GET_ACCOUNT_COST_ITEMS,
  GET_ACCOUNT_COST_ITEMS_ERROR,
  ACCOUNT_COST_ITEMS_FETCHED,
  ACCOUNT_COST_CLASSIFICATION_FETCHED,
  GET_ACCOUNT_COST_CLASSIFICATION_ERROR,
  GET_ACCOUNT_COST_CLASSIFICATION,
} from '../reducers/accountOfCost';
import store from '../store';

export const accountOfCostFetched = (accountOfCost, workId) => ({
  type: ACCOUNT_COST_FETCHED,
  accountOfCost,
  workId,
});

export const accountOfCostItemsFetched = (accountOfCostItems) => ({
  type: ACCOUNT_COST_ITEMS_FETCHED,
  accountOfCostItems,
});

export const classificationFetched = (classification) => ({
  type: ACCOUNT_COST_CLASSIFICATION_FETCHED,
  classification,
});

export const getAccountOfCostItems = (ID_OBR) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: GET_ACCOUNT_COST_ITEMS });

    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR,
    };
    api
      .post(`/api/Presupuesto/GetRubroCuentaCosto`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch(accountOfCostItemsFetched(res.data, ID_OBR));
          resolve(res.data);
        } else {
          dispatch({
            type: GET_ACCOUNT_COST_ITEMS_ERROR,
          });
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_ACCOUNT_COST_ITEMS_ERROR,
        });
        reject(err);
      });
  });

export const getClassification = (ID_OBR) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: GET_ACCOUNT_COST_CLASSIFICATION });
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
    };
    api
      .post(`/api/Presupuesto/GetClasificacionGGCuentaCosto`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch(classificationFetched(res.data, ID_OBR));
          resolve(res.data);
        } else {
          dispatch({
            type: GET_ACCOUNT_COST_CLASSIFICATION_ERROR,
          });
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_ACCOUNT_COST_ITEMS_ERROR,
        });
        reject(err);
      });
  });

export const deleteAccountOfCostElement = (ID_ITEM_COSTO, isChapter) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_ITEM_COSTO,
    };

    const resourceTitle = isChapter ? 'Capitulo' : 'Cuenta de Costo';
    const errorMessage = `Error al actualizar ${isChapter ? 'el' : 'la'} ${resourceTitle} `;
    api
      .post(`/api/Presupuesto/DelItemCuentaCosto`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200 && res.data === 'OK') {
          resolve(res.data);
          message.success(`${resourceTitle} actualiza${isChapter ? 'do' : 'da'} correctamente`);
        } else {
          reject(res.data);
          message.error(errorMessage);
        }
      })
      .catch((err) => {
        reject(err);
        message.error(errorMessage);
      });
  });

export const getAccountOfCost = (ID_OBR) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: GET_ACCOUNT_COST });

    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR,
    };
    api
      .post(`/api/Presupuesto/GetCuentaCosto`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch(accountOfCostFetched(res.data, ID_OBR));
          resolve(res.data);
        } else {
          dispatch({
            type: GET_ACCOUNT_COST_ERROR,
          });
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_ACCOUNT_COST_ERROR,
        });
        reject(err);
      });
  });

export const saveAccountOfCost = (body, isEdit) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR: _.get(store.getState(), 'works.selectedWork', ''),
      ...body,
    };

    const isChapter = requestBody.ES_CUENTA_COSTO === 1;
    const resourceTitle = isChapter ? 'Capitulo' : 'Cuenta de Costo';

    const successMessage = `${resourceTitle} ${
      isEdit ? `${isChapter ? 'editado' : 'editada'}` : `${isChapter ? 'agregado' : 'agregada'}`
    } correctamente`;
    const errorMessage = `Error al ${isEdit ? 'editar' : 'crear'} ${
      isChapter ? 'el' : 'la'
    }  ${resourceTitle}`;

    api
      .post(
        `/api/Presupuesto/${isEdit ? 'UpdItemCuentaCosto' : 'SetItemCuentaCosto'}`,
        requestBody,
        getHeaders()
      )
      .then((res) => {
        const isValidResponse =
          res.status === 200 &&
          ((isEdit && res.data === 'OK') || (!isEdit && get(res, 'data.ID_ITEM_COSTO', null)));
        if (isValidResponse) {
          message.success(successMessage);
          resolve(res.data);
        } else {
          message.error(errorMessage);
          reject();
        }
      })
      .catch((err) => {
        message.error(errorMessage);

        reject(err);
      });
  });
