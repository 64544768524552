import _ from 'lodash';
import { message } from 'antd';
import api, { getHeaders } from '../utils/api';
import store from '../store';
import {
  OPTIONS_SCREEN_FETCHED,
  GET_OPTIONS_SCREEN,
  GET_OPTIONS_SCREEN_ERROR,
} from '../reducers/options';

import { getMenu } from './menu';

export const OptionScreenFetched = (options) => ({
  type: OPTIONS_SCREEN_FETCHED,
  options,
});

export const getOptionScreen = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: GET_OPTIONS_SCREEN });

    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
    };
    api
      .post('api/ConfiguracionSuite/GetMenuConfiguracionOrden', requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch(OptionScreenFetched(res.data));
          resolve(res.data);
        } else {
          dispatch({
            type: GET_OPTIONS_SCREEN_ERROR,
          });
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_OPTIONS_SCREEN_ERROR,
        });
        reject(err);
      });
  });

export const saveOptions = (ID_PADRE, ID_MENU, NOMBRE_MENU, ORDEN, CSS_ICON) => (dispatch) =>
  new Promise((resolve) => {
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_PADRE,
      ID_MENU,
      NOMBRE_MENU,
      ORDEN,
      CSS_ICON,
    };

    api
      .post('api/ConfiguracionSuite/SetMenuConfiguracionOrden', requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          getOptionScreen()(dispatch);
          getMenu(localStorage.getItem('foco_client'), false)(dispatch);
          message.success('Información guardada correctamente', 4);
        } else {
          message.success('Error al guardar la información', 4);
        }
        resolve();
      })
      .catch(() => message.error('Error al guardar la información', 4));
  });
