/* eslint-disable no-useless-constructor */
import { Table } from 'rsuite-table';

export default class TableWrapper extends Table {
  componentDidUpdate(prevProps, prevState) {
    const { data, height } = prevProps;

    if (data !== this.props.data) {
      this.calculateRowMaxHeight();
    } else {
      this.updatePosition();
    }

    if (
      data !== this.props.data ||
      height !== this.props.height ||
      prevState.contentHeight !== this.state.contentHeight ||
      prevState.expandedRowKeys !== this.state.expandedRowKeys ||
      prevProps.expandedRowKeys !== this.props.expandedRowKeys
    ) {
      this.calculateTableContextHeight(prevProps);
    }

    this.calculateTableContentWidth(prevProps);
  }
}
