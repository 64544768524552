/* eslint-disable no-restricted-globals */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getValueWithUnit } from '../../../utils/budgetAndProductionHelpers';
import { setAdvanceControlEdition as setAdvanceControlEditionAction } from '../../../actions/advanceControlEdition';

import './index.scss';

const EditableCell = (props) => {
  const {
    editable,
    dataIndex,
    editButtonCallback,
    rowData,
    handleSave,
    unit,
    canShow,
    type,
    validator,
    additionalInputFields,
    objectModifier,
    conditionToEditable,
    className,
    calculateValue,
    isUnitPrefix,
    maxButtonValue,
    editItem,
    decimalsIndex,
    onlyInteger,
    customClass,
    includeRecordFields,
    keepEditable,
    advanceControlEdition,
    ...restProps
  } = props;
  const [editing, setEditing] = useState(keepEditable || false);
  const [focused, setFocused] = useState(false);
  const inputRef = useRef(null);
  const [inputValue, setValue] = useState(rowData[dataIndex]);
  const originalValue = rowData[dataIndex];

  useEffect(() => {
    const updatedValue = (advanceControlEdition.find((e) => e.ID_INS === rowData.ID_INS) || {})[
      dataIndex
    ];
    const hasChanged = updatedValue !== undefined && updatedValue !== inputValue;
    if (hasChanged && advanceControlEdition.length) {
      setValue(updatedValue);
    }
  }, [advanceControlEdition]);

  const toggleEdit = useCallback(() => {
    setEditing(!editing);
    if (!editing) {
      setTimeout(() => {
        if (inputRef && !get(inputRef, 'current.state.focused')) inputRef.current.focus();
      }, 100);
    }
  }, [editing, rowData]);

  const finalDecimalsIndex =
    typeof decimalsIndex === 'function' ? decimalsIndex(rowData) : decimalsIndex;

  const finalOnlyInteger = typeof onlyInteger === 'function' ? onlyInteger(rowData) : onlyInteger;

  const save = (event, isEnterPressed, saveValue = null) => {
    if (event) {
      event.preventDefault();
    }

    if (!keepEditable) {
      toggleEdit();
    } else if (isEnterPressed) {
      const inputs = Array.prototype.slice.call(document.querySelectorAll('input'));
      const index = (inputs.indexOf(document.activeElement) + 1) % inputs.length;
      const input = inputs[index];
      input.focus();
      input.select();
    }
    const value = saveValue || inputValue;
    setFocused(false);
    // eslint-disable-next-line no-restricted-globals
    let isValidType = type === 'number' && !isNaN(value);

    if (type === 'string') {
      isValidType = true;
    }

    let additionalFields = {};
    includeRecordFields.forEach((field) => {
      additionalFields[field] = rowData[field];
    });

    if (value && isValidType && value !== originalValue && validator(value, rowData)) {
      let finalValue = value;

      if (type === 'number') {
        finalValue = Number(value);
      }

      additionalFields = objectModifier(additionalFields, rowData, finalValue);

      if (`${rowData[dataIndex]}` !== `${finalValue}`) {
        const dataToEdit = [
          {
            ID_INS: rowData.ID_INS,
            [dataIndex]: finalValue,
            ...additionalFields,
          },
        ];
        handleSave(dataToEdit, rowData);
      }
    }
  };

  const getChildrenContent = () => {
    let children = getValueWithUnit(
      unit,
      inputValue,
      isUnitPrefix,
      type,
      finalDecimalsIndex,
      finalOnlyInteger
    );

    if (editable && conditionToEditable(rowData)) {
      const handleFocus = (event) => {
        event.target.select();
        setFocused(true);
      };

      const editButton = (
        <i
          className="fa fa-edit"
          onMouseDown={(e) => {
            e.stopPropagation();
            editButtonCallback(rowData[dataIndex], rowData);
          }}
        />
      );

      const maxButton = maxButtonValue ? (
        <button
          className="btn btn-primary max-button"
          type="button"
          onMouseDown={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setValue(maxButtonValue);
            save(e, null, maxButtonValue);
          }}
        >
          Max
        </button>
      ) : null;

      children = editing ? (
        <div className="editable-form-item-wrapper" style={{ position: 'relative' }}>
          <Input
            ref={inputRef}
            value={inputValue}
            onChange={(e) => setValue(e.target.value)}
            onPressEnter={() => save(null, true)}
            onBlur={save}
            style={keepEditable && type === 'number' ? { opacity: focused ? 1 : 0, zIndex: 3 } : {}}
            onFocus={handleFocus}
            {...additionalInputFields(rowData)}
          />
          {keepEditable && !focused && type === 'number' && (
            <div className={`value-mask ${dataIndex}`}>{children}</div>
          )}
          {editButtonCallback ? editButton : null}
          {maxButton}
        </div>
      ) : (
        <div className="editable-cell-value-wrap" onClick={() => toggleEdit()}>
          {children}
          {editButtonCallback ? editButton : null}
          {maxButton}
        </div>
      );
    }

    return children;
  };

  if (!canShow(rowData)) {
    return (
      <td className={className(rowData)} {...restProps}>
        {null}
      </td>
    );
  }

  return (
    <td
      className={`table-editable-cell ${customClass(rowData)} ${
        className(rowData) && conditionToEditable(rowData) ? className(rowData) : ''
      }`}
      {...restProps}
    >
      {getChildrenContent()}
    </td>
  );
};

EditableCell.propTypes = {
  editable: PropTypes.bool.isRequired,
  dataIndex: PropTypes.string.isRequired,
  editButtonCallback: PropTypes.func,
  rowData: PropTypes.object.isRequired,
  handleSave: PropTypes.func.isRequired,
  unit: PropTypes.string,
  canShow: PropTypes.bool,
  type: PropTypes.string,
  validator: PropTypes.func,
  additionalInputFields: PropTypes.func,
  objectModifier: PropTypes.func,
  conditionToEditable: PropTypes.func,
  className: PropTypes.func,
  calculateValue: PropTypes.func,
  isUnitPrefix: PropTypes.bool,
  maxButtonValue: PropTypes.number,
  editItem: PropTypes.func,
  decimalsIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
  onlyInteger: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
  customClass: PropTypes.string,
  keepEditable: PropTypes.bool,
  includeRecordFields: PropTypes.array,
  advanceControlEdition: PropTypes.array.isRequired,
};

EditableCell.defaultProps = {
  editButtonCallback: null,
  unit: '',
  canShow: () => true,
  type: 'number',
  validator: () => true,
  additionalInputFields: () => ({}),
  objectModifier: (obj) => obj,
  conditionToEditable: () => true,
  className: () => '',
  calculateValue: () => '',
  isUnitPrefix: true,
  maxButtonValue: '',
  editItem: () => {},
  decimalsIndex: null,
  onlyInteger: false,
  customClass: () => '',
  keepEditable: false,
  includeRecordFields: [],
};

export default connect(
  (store) => ({
    advanceControlEdition: store.advanceControlEdition,
  }),
  (dispatch) => ({
    handleSave: bindActionCreators(setAdvanceControlEditionAction, dispatch),
  })
)(EditableCell);
