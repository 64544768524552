import React from 'react';

export const columnTable = (nombre) => {
  return [
    {
      title: `GESTIÓN DE ${nombre}`,
      children: [
        {
          field: 'ACCION',
          title: 'Acción',
          width: 180,
        },
        {
          field: 'ESTADO',
          expandable: true,
          title: 'Estado',
          width: 50,
          cell: ({ dataItem }) => {
            return (
              <td className={`${dataItem.ESTADO === 'Pendiente' ? 'red-color' : 'ok-color'}`}>
                {dataItem.ESTADO === 'Pendiente' ? (
                  <span className="color-data">{dataItem.ESTADO}</span>
                ) : (
                  <span className="color-data-ok">{dataItem.ESTADO}</span>
                )}
              </td>
            );
          },
        },
        {
          field: 'COMENTARIO',
          title: 'Comentario',
          width: 180,
        },
        {
          field: 'URL',
          expandable: true,
          title: 'Url',
          width: 60,
          cell: ({ dataItem }) => {
            return (
              <td>
                {dataItem.URL ? (
                  <a href={dataItem.URL} className="button-url">
                    <input type="button" value=" Ver Detalle" />
                  </a>
                ) : (
                  []
                )}
              </td>
            );
          },
        },
      ],
    },
  ];
};

export default columnTable;
