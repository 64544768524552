import update from 'immutability-helper';

export const USERS_FETCHED = 'USERS_FETCHED';
export const GET_USERS = 'GET_USERS';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';

const usersinit = {
  data: null,
  isFetching: false,
  error: false,
};

const users = (state = usersinit, action) => {
  switch (action.type) {
    case USERS_FETCHED: {
      return update(state, {
        data: {
          $set: action.users,
        },
        isFetching: { $set: false },
        error: { $set: false },
      });
    }

    case GET_USERS_ERROR:
      return update(state, { error: { $set: true }, isFetching: { $set: false } });
    case GET_USERS:
      return {
        ...state,
        isFetching: true,
        error: false,
      };

    case 'AUTH_LOGOUT':
      return usersinit;

    default:
      return state;
  }
};

export default users;
