import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import moment from 'moment';
import qs from 'querystring';
import { CSVLink } from 'react-csv';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LoadingOutlined } from '@ant-design/icons';

import {
  getSupervisors as getSupervisorsAction,
  getWorkersAttendance as getWorkersAttendanceAction,
  updateMassSupervisor as updateMassSupervisorAction,
} from '../../actions/workers';
import { getAreasAndPositionsAttendance as getAreasAndPositionsAction } from '../../actions/areasAndPositions';
import {
  getAttendance as getAttendanceAction,
  getAttendanceTypes as getAttendanceTypesAction,
  getClosedAttendance as getClosedAttendanceAction,
  setAttendance as setAttendanceAction,
  setClosedAttendance as setClosedAttendanceAction,
  setMassAttendance as setMassAttendanceAction,
  setOpenedAttendance as setOpenedAttendanceAction,
} from '../../actions/attendance';

import { getMenu as getMenuAction } from '../../actions/menu';
import { getCommunes as getCommunesAction } from '../../actions/communes';

import { setInformationFromAttendanceURL as setInformationFromAttendanceURLAction } from '../../actions/auth';
import { getSchedule as getScheduleAction } from '../../actions/schedule';
import {
  getWorks as getWorksAction,
  setSelectedWork as setSelectedWorkAction,
} from '../../actions/works';
import { getDay, getDaysInMonth, getFormattedRut, normalizeString } from '../../utils';
import { validateDayValue, validateHourValue } from '../../utils/attendanceHelpers';
import Header from './Header';
import Table from './TableMigration';
import './index.scss';

const getWorkersData = (attendance, daysInMonth, filters, selectedWork, workers, viewOptions) => {
  const workersData = [];
  if (
    attendance.attendanceTypes &&
    attendance.data &&
    workers.data &&
    workers.supervisors &&
    daysInMonth.length
  ) {
    const workersWithAttendance = {};
    const hoursTotalByDay = {};
    const workersTotalByDay = {};
    const attendanceTypesMap = new Map(
      attendance.attendanceTypes.map((e) => [e.ID_CNS, e.ABREVIA_CNS])
    );
    const supervisorsName = new Map(workers.supervisors.map((e) => [e.ID_SUP, e.NOMBRE_SUP]));
    attendance.data.forEach((e) => {
      const date = e.FECHA.replaceAll('-', '/');

      if (workersWithAttendance[e.ID_TRA]) {
        workersWithAttendance[e.ID_TRA] = {
          ...workersWithAttendance[e.ID_TRA],
          [date]:
            e.ID_CNS === 5
              ? attendance.attendanceViewType === 1
                ? e.HH_TRA
                : Math.round((e.HH_TRA * 10) / 9) / 10
              : attendanceTypesMap.get(e.ID_CNS),
        };
      } else {
        workersWithAttendance[e.ID_TRA] = {
          [date]:
            e.ID_CNS === 5
              ? attendance.attendanceViewType === 1
                ? e.HH_TRA
                : Math.round((e.HH_TRA * 10) / 9) / 10
              : attendanceTypesMap.get(e.ID_CNS),
        };
      }
    });

    workers.data.forEach((worker) => {
      if (
        worker.ID_OBR === selectedWork &&
        normalizeString(worker.NOMBRE_ARE).includes(normalizeString(filters.area)) &&
        normalizeString(worker.NOMBRE_CAR).includes(normalizeString(filters.position)) &&
        (normalizeString(`${worker.NOMBRE_TRA} ${worker.APELLIDO_TRA}`).includes(
          normalizeString(filters.name)
        ) ||
          `${worker.RUT_TRA}-${worker.DV_TRA}`.includes(filters.name.replaceAll('.', ''))) &&
        (filters.supervisorName === '' ||
          (workers.supervisors.find((s) => s.ID_SUP === worker.ID_SUP) !== undefined &&
            normalizeString(
              workers.supervisors.find((s) => s.ID_SUP === worker.ID_SUP).NOMBRE_SUP
            ).includes(normalizeString(filters.supervisorName))) ||
          (normalizeString(filters.supervisorName) === normalizeString('sin definir') &&
            worker.ID_SUP === 0))
      ) {
        let newWorker = {
          endDate: worker.FECFIN_TRA.replaceAll('-', '/'),
          position: worker.NOMBRE_CAR,
          department: worker.NOMBRE_ABREV,
          rut: getFormattedRut(`${worker.RUT_TRA}${worker.DV_TRA}`),
          startDate: worker.FECING_TRA.replaceAll('-', '/'),
          supervisorName: supervisorsName.get(worker.ID_SUP) || 'Sin Definir',
          workerId: worker.ID_TRA,
          selected: false,
          workerName: `${worker.NOMBRE_TRA} ${worker.APELLIDO_TRA}`,
          fileNumber: worker.NUMFICHA_TRA,
        };
        const workerWithAttendance = workersWithAttendance[worker.ID_TRA] || {};

        daysInMonth.forEach((day) => {
          const date = moment(day).format('DD/MM/YYYY');
          newWorker = update(newWorker, {
            [date]: {
              $set: workerWithAttendance[date] || '',
            },
          });

          if (typeof workerWithAttendance[date] === 'number') {
            const value = workerWithAttendance[date];

            if (hoursTotalByDay[date]) {
              hoursTotalByDay[date] += value;
              workersTotalByDay[date] += 1;
            } else {
              hoursTotalByDay[date] = value;
              workersTotalByDay[date] = 1;
            }
          }
        });

        workersData.push(newWorker);
      }
    });
    // workersData.unshift({ workerName: '' });

    if (viewOptions[8].value === true) {
      workersData.unshift({ ...workersTotalByDay, workerName: 'Trabajadores Presentes' });
    }
    if (viewOptions[7].value === true) {
      if (attendance.attendanceViewType === 1) {
        workersData.unshift({ ...hoursTotalByDay, workerName: 'Total Horas Trabajadas' });
      } else {
        workersData.unshift({
          ...hoursTotalByDay,
          workerName: 'Total Jornadas Trabajadas',
        });
      }
    }
    workersData.unshift({ workerName: 'Estado Del Día' });
  }

  return workersData;
};

const viewOptionsDefault = [
  { name: 'Mostrar Rut', value: false },
  { name: 'Mostrar Área', value: false },
  { name: 'Mostrar Cargo', value: true },
  { name: 'Mostrar Supervisor', value: true },
  { name: 'Mostrar Inicio Contrato', value: false },
  { name: 'Mostrar Término Contrato', value: false },
  { name: 'Mostrar N° de Ficha', value: false },
  { name: 'Total Horas Trabajadas', value: false },
  { name: 'Trabajadores Presentes', value: true },
];
const getInitialState = () => ({
  isEditing: false,
});
const AttendanceControl = ({
  areasAndPositions,
  attendance,
  auth,
  getAreasAndPositionsAttendance,
  getAttendance,
  getAttendanceTypes,
  getClosedAttendance,
  getSchedule,
  getSupervisors,
  getWorkersAttendance,
  getMenu,
  getCommunes,
  getWorks,
  location,
  schedule,
  selectedWork,
  setSelectedWork,
  setAttendance,
  setClosedAttendance,
  setInformationFromAttendanceURL,
  setMassAttendance,
  setOpenedAttendance,
  updateMassSupervisor,
  workers,
  works,
  history,
  menu,
  communes,
}) => {
  const [filters, setFilters] = useState({ area: '', position: '', name: '', supervisorName: '' });
  const [filtersT, setFiltersT] = useState({
    area: '',
    position: '',
    name: '',
    supervisorName: '',
  });
  const [month, setMonth] = useState(moment().month());
  const [year, setYear] = useState(moment().year());
  const [viewOptions, setViewOptions] = useState(viewOptionsDefault);
  const [infoToExport, setInfoToExport] = useState({ data: [], headers: [] });
  const [loading, setLoading] = useState(false);
  // const [savingExcel, setsavingExcel] = useState(false);
  const [daysInMonth, setDaysInMonth] = useState([]);
  const [editSupervisorState, setEditSupervisorState] = useState(false);
  const [massSupervisorUpdate, setMassSupervisorUpdate] = useState([]);
  const csvLinkRef = useRef();
  const [state, setState] = useState(getInitialState());
  const { savingExcel } = state;
  const { foco_client, foco_project, foco_token, foco_username } = qs.parse(
    location.search.replace('?', '')
  );

  // getWorkersData(attendance, daysInMonth, filters, selectedWork, workers, viewOptions);

  useEffect(() => {
    setDaysInMonth(getDaysInMonth(month, year));
  }, [month, year]);

  useEffect(() => {
    if (foco_token) {
      setInformationFromAttendanceURL(foco_client, foco_project, foco_token, foco_username);
      localStorage.setItem('foco_token', foco_token);
    }
  }, []);
  useEffect(() => {
    if (auth.token && auth.user.username && selectedWork) {
      if (!workers.data) {
        getWorkersAttendance(month + 1, year);
      }

      if (!areasAndPositions.data) {
        getAreasAndPositionsAttendance();
      }

      if (!attendance.attendanceTypes) {
        getAttendanceTypes();
      }

      if (!workers.supervisors) {
        getSupervisors();
      }

      if (foco_client) {
        getWorks(foco_client);
      }
    }
  }, [auth]);

  useEffect(() => {
    setLoading(true);
    getClosedAttendance(month + 1, year);
    getAttendance(month + 1, year)
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
    getSchedule();
  }, [month, selectedWork, year]);

  const handleFilters = (filter, value) => {
    setFiltersT(
      update(filtersT, {
        [filter]: { $set: value },
      })
    );
  };
  const handleFiltersButton = () => {
    setFilters(
      update(filters, {
        area: { $set: filtersT.area },
        position: { $set: filtersT.position },
        name: { $set: filtersT.name },
        supervisorName: { $set: filtersT.supervisorName },
      })
    );
  };
  const handleAttendance = (dates, type, value, id = null) => {
    const attendanceTypes = new Map(
      attendance.attendanceTypes.map((e) => [e.ABREVIA_CNS, e.ID_CNS])
    );

    const ID_CNS =
      attendance.attendanceViewType === 1
        ? validateHourValue(value)
          ? attendanceTypes.get('9')
          : attendanceTypes.get(value)
        : validateDayValue(value)
        ? attendanceTypes.get('9')
        : attendanceTypes.get(value);

    if (type === 'user') {
      const updateAttendance = !attendance.data.some(
        (e) =>
          e.ID_TRA === id &&
          e.FECHA === dates[0].replaceAll('/', '-') &&
          e.ID_CNS === ID_CNS &&
          (ID_CNS === 5
            ? attendance.attendanceViewType === 1
              ? e.HH_TRA === Number(value)
              : e.HH_TRA === Number(value * 9)
            : true)
      );
      if (updateAttendance) {
        const worker = workers.data.find((e) => e.ID_TRA === id);

        const newAttendance = {
          ID_AST: 0,
          ID_TRA: id,
          FECHA: dates[0].replaceAll('/', '-'),
          ID_CNS,
          HH_TRA:
            ID_CNS === attendanceTypes.get('9')
              ? attendance.attendanceViewType === 1
                ? Number(value)
                : Number(value * 9)
              : 0,
          ID_SUP: worker.ID_SUP,
        };

        setAttendance(newAttendance);
      }
    } else if (type === 'multiple') {
      setLoading(true);

      const massAttendance = {
        ID_OBR: selectedWork,
        FECHA_INICIAL: dates[0].replaceAll('/', '-'),
        FECHA_FINAL: dates[1].replaceAll('/', '-'),
        ID_CNS,
        HH_TRA:
          ID_CNS === attendanceTypes.get('9')
            ? attendance.attendanceViewType === 1
              ? Number(value)
              : Number(value * 9)
            : 0,
      };
      setMassAttendance(massAttendance)
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    }
  };

  const handleMassEditSupervisor = (typeSave) => {
    setEditSupervisorState(!editSupervisorState);
    if (typeSave && massSupervisorUpdate.length !== 0) {
      updateMassSupervisor(massSupervisorUpdate);
    } else {
      setMassSupervisorUpdate([]);
    }
  };
  const handleMassSupervisorUpdate = (idWorker, idSupervisor) => {
    const findExists = massSupervisorUpdate.find(
      (updateSupervisor) => updateSupervisor.ID_TRA === idWorker
    );
    if (findExists) {
      setMassSupervisorUpdate(
        massSupervisorUpdate.map((updateSupervisor) => {
          if (updateSupervisor.ID_TRA === idWorker) {
            updateSupervisor.ID_SUP = idSupervisor;
          }
          return updateSupervisor;
        })
      );
    } else {
      setMassSupervisorUpdate([
        ...massSupervisorUpdate,
        { ID_TRA: idWorker, ID_SUP: idSupervisor },
      ]);
    }
  };
  const handleExportData = () => {
    const viewOptionsSet = new Set(viewOptions.filter((e) => e.value).map((e) => e.name));
    const header = [{ label: 'Nombre Trabajador', key: 'workerName' }];

    if (viewOptionsSet.has('Mostrar Rut')) {
      header.push({ label: 'Rut', key: 'rut' });
    }
    if (viewOptionsSet.has('Mostrar Área')) {
      header.push({ label: 'Área', key: 'department' });
    }
    if (viewOptionsSet.has('Mostrar Cargo')) {
      header.push({ label: 'Cargo', key: 'position' });
    }

    if (viewOptionsSet.has('Supervisor')) {
      header.push({ label: 'Supervisor', key: 'supervisor' });
    }
    if (viewOptionsSet.has('Mostrar Inicio Contrato')) {
      header.push({ label: 'Fecha Inicio', key: 'startDate' });
    }
    if (viewOptionsSet.has('Mostrar Término Contrato')) {
      header.push({ label: 'Fecha Término', key: 'endDate' });
    }

    if (viewOptionsSet.has('Mostrar N° de Ficha')) {
      header.push({ label: 'N° de Ficha', key: 'fileNumber' });
    }

    if (viewOptionsSet.has('Total Horas Trabajadas')) {
      header.push({ label: 'N° de Ficha', key: 'fileNumber' });
    }
    if (viewOptionsSet.has('Mostrar N° de Ficha')) {
      header.push({ label: 'N° de Ficha', key: 'fileNumber' });
    }

    daysInMonth.forEach((day) => {
      header.push({
        label: `${getDay(day)}/${day.getDate()}`,
        key: moment(day).format('DD/MM/YYYY'),
      });
    });

    const workersData = getWorkersData(
      attendance,
      daysInMonth,
      filters,
      selectedWork,
      workers,
      viewOptions
    );

    setInfoToExport({ data: workersData, header });
    setTimeout(() => csvLinkRef.current.link.click(), 500);
  };

  const dataTable = getWorkersData(
    attendance,
    daysInMonth,
    filters,
    selectedWork,
    workers,
    viewOptions
  );
  const closedAttendance = attendance.closedAttendance
    ? attendance.closedAttendance.map((e) => e.DIA_CIERRE.replaceAll('-', '/'))
    : [];

  const handleClosedAttendance = (day) => {
    setLoading(true);

    setClosedAttendance(day).finally(() => setLoading(false));
  };
  const handleOpenedAttendance = (day) => {
    setLoading(true);

    setOpenedAttendance(day).finally(() => setLoading(false));
  };
  const viewOptionsSet = new Set(viewOptions.filter((e) => e.value).map((e) => e.name));
  return (
    <div className={`${foco_token ? 'paddingInUrl' : ''}`}>
      {!workers.data ||
      !workers.supervisors ||
      !areasAndPositions.data ||
      !attendance.attendanceTypes ||
      !attendance.data ||
      !attendance.closedAttendance ||
      !works.data ||
      !schedule.data ? (
        <div className="spin">
          <LoadingOutlined />
        </div>
      ) : (
        <>
          <Header
            attendanceTypes={attendance.attendanceTypes}
            attendanceViewType={attendance.attendanceViewType}
            editSupervisorState={editSupervisorState}
            closedAttendance={closedAttendance}
            handleAttendance={handleAttendance}
            handleExportData={handleExportData}
            handleFilters={handleFilters}
            handleFiltersButton={handleFiltersButton}
            handleMassEditSupervisor={handleMassEditSupervisor}
            filters={filtersT}
            month={month}
            setMonth={setMonth}
            setViewOptions={setViewOptions}
            setYear={setYear}
            supervisors={workers.supervisors.filter((e) => e.ID_OBR === selectedWork)}
            viewOptions={viewOptions}
            viewOptionsSet={viewOptionsSet}
            year={year}
            getMenu={getMenu}
            getCommunes={getCommunes}
            menu={menu}
            communes={communes}
            history={history}
            selectedWork={selectedWork}
            works={works}
            setSelectedWork={setSelectedWork}
            dataTable={dataTable}
            daysInMonth={daysInMonth}
            savingExcel={savingExcel}
            setState={setState}
            state={state}
          />

          <Table
            isTree
            attendanceTypes={attendance.attendanceTypes}
            attendanceViewType={attendance.attendanceViewType}
            closedAttendance={closedAttendance}
            dataTable={dataTable}
            daysInMonth={daysInMonth}
            editSupervisorState={editSupervisorState}
            handleAttendance={handleAttendance}
            handleClosedAttendance={handleClosedAttendance}
            loading={loading}
            month={month}
            savingExcel={savingExcel}
            nameWork={
              works.data.find((obr) => parseInt(obr.ID_OBR, 10) === parseInt(selectedWork, 10))
                .NOMBRE_OBR
            }
            supervisors={workers.supervisors.filter((e) => e.ID_OBR === selectedWork)}
            handleMassSupervisorUpdate={handleMassSupervisorUpdate}
            handleOpenedAttendance={handleOpenedAttendance}
            viewOptions={viewOptions}
            massSupervisorUpdate={massSupervisorUpdate}
            workers={workers.data}
            year={year}
            setState={setState}
            state={state}
          />
          <CSVLink
            headers={infoToExport.header}
            data={infoToExport.data}
            filename="Control de Asistencia.csv"
            className="hidden"
            ref={csvLinkRef}
            target="_blank"
          />
        </>
      )}
    </div>
  );
};

AttendanceControl.propTypes = {
  areasAndPositions: PropTypes.shape({
    data: PropTypes.array,
  }).isRequired,
  attendance: PropTypes.shape({
    attendanceTypes: PropTypes.array,
    attendanceViewType: PropTypes.number,
    closedAttendance: PropTypes.array,
    data: PropTypes.array,
  }).isRequired,
  auth: PropTypes.shape({
    user: PropTypes.object,
    token: PropTypes.string,
  }).isRequired,
  menu: PropTypes.shape({
    data: PropTypes.array,
    isFetching: PropTypes.bool,
  }).isRequired,
  communes: PropTypes.shape({
    data: PropTypes.array,
    isFetching: PropTypes.bool,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
  getAreasAndPositionsAttendance: PropTypes.func.isRequired,
  getAttendance: PropTypes.func.isRequired,
  getAttendanceTypes: PropTypes.func.isRequired,
  getClosedAttendance: PropTypes.func.isRequired,
  getSchedule: PropTypes.func.isRequired,
  getSupervisors: PropTypes.func.isRequired,
  getWorkersAttendance: PropTypes.func.isRequired,
  getWorks: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  schedule: PropTypes.shape({ data: PropTypes.array }).isRequired,
  setSelectedWork: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  setsavingExcel: PropTypes.func.isRequired,
  selectedWork: PropTypes.number.isRequired,
  setAttendance: PropTypes.func.isRequired,
  setClosedAttendance: PropTypes.func.isRequired,
  setInformationFromAttendanceURL: PropTypes.func.isRequired,
  setMassAttendance: PropTypes.func.isRequired,
  setOpenedAttendance: PropTypes.func.isRequired,
  updateMassSupervisor: PropTypes.func.isRequired,
  workers: PropTypes.shape({
    data: PropTypes.array,
    supervisors: PropTypes.array,
  }).isRequired,
  works: PropTypes.shape({ data: PropTypes.array }).isRequired,
  getCommunes: PropTypes.func.isRequired,
  getMenu: PropTypes.func.isRequired,
};

export default connect(
  (store) => ({
    areasAndPositions: store.areasAndPositions,
    attendance: store.attendance,
    auth: store.auth,
    schedule: store.schedule,
    selectedWork: store.works.selectedWork,
    workers: store.workers,
    works: store.works,
    menu: store.menu,
    communes: store.communes,
  }),
  (dispatch) => ({
    getMenu: bindActionCreators(getMenuAction, dispatch),
    getCommunes: bindActionCreators(getCommunesAction, dispatch),
    getAreasAndPositionsAttendance: bindActionCreators(getAreasAndPositionsAction, dispatch),
    getAttendance: bindActionCreators(getAttendanceAction, dispatch),
    getAttendanceTypes: bindActionCreators(getAttendanceTypesAction, dispatch),
    getClosedAttendance: bindActionCreators(getClosedAttendanceAction, dispatch),
    getSchedule: bindActionCreators(getScheduleAction, dispatch),
    getSupervisors: bindActionCreators(getSupervisorsAction, dispatch),
    getWorkersAttendance: bindActionCreators(getWorkersAttendanceAction, dispatch),
    getWorks: bindActionCreators(getWorksAction, dispatch),
    updateMassSupervisor: bindActionCreators(updateMassSupervisorAction, dispatch),
    setAttendance: bindActionCreators(setAttendanceAction, dispatch),
    setClosedAttendance: bindActionCreators(setClosedAttendanceAction, dispatch),
    setInformationFromAttendanceURL: bindActionCreators(
      setInformationFromAttendanceURLAction,
      dispatch
    ),
    setSelectedWork: bindActionCreators(setSelectedWorkAction, dispatch),
    setMassAttendance: bindActionCreators(setMassAttendanceAction, dispatch),
    setOpenedAttendance: bindActionCreators(setOpenedAttendanceAction, dispatch),
  })
)(AttendanceControl);
