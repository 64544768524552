/* eslint-disable no-restricted-globals */
import update from 'immutability-helper';
import _ from 'lodash';
import React from 'react';
import { getFormattedNumber } from '.';

const activeEditionsTitles = {
  set_sale_price: 'Modo Edición Precio Venta',
  account_of_cost: 'Modo Edición Cuenta de Costo',
  updated_budget: 'Modo Edición FORECAST',
  current_budget: 'Modo Edición PPTO. Vigente',
};

const defaultFilteredData = { item: [], accountOfCost: [], resource: [], variation: [] };
const variationFilterOptions = [
  {
    ID_ITEM: 1,
    TITLE: 'Sólo partidas con variación entre ptto. vigente y base',
  },
  {
    ID_ITEM: 2,
    TITLE: 'Sólo partidas con variación entre FORECAST y ptto. vigente',
  },
  {
    ID_ITEM: 3,
    TITLE: 'Partidas con % de éxito diferente al 100% ',
  },
];

const groupObjectInArray = (info, key = 'ID_ITEM') => {
  const result = {};

  info.forEach((current) => {
    if (current.COMENTARIO_PPTO_ACTUALIZADO) {
      current.COMENTARIO = current.COMENTARIO_PPTO_ACTUALIZADO;
      delete current.COMENTARIO_PPTO_ACTUALIZADO;
    } else if (current.COMENTARIO_PPTO_VIGENTE) {
      current.COMENTARIO = current.COMENTARIO_PPTO_VIGENTE;
      delete current.COMENTARIO_PPTO_VIGENTE;
    }

    if (result[current[key]]) {
      result[current[key]] = {
        ...result[current[key]],
        ...current,
      };
    } else {
      result[current[key]] = current;
    }
  });

  return Object.keys(result).map((currentKey) => result[currentKey]);
};

export const getNodeObjectToUpdate = (pathArray, index, updates, parentUpdates = null) => {
  const result = {
    [Number(pathArray[index])]: {
      ...(pathArray[index + 1]
        ? {
            children: getNodeObjectToUpdate(pathArray, index + 1, updates, parentUpdates),
            ...(parentUpdates
              ? {
                  $merge: parentUpdates,
                }
              : {}),
          }
        : updates),
    },
  };

  return result;
};

const groupNodesInArray = (info) => {
  const result = {};

  info.forEach((current) => {
    if (!result[`${current.ID_ITEM}-${current.ID_ITEM_SUP}`]) {
      result[`${current.ID_ITEM}-${current.ID_ITEM_SUP}`] = current;
    }
  });

  return Object.keys(result).map((currentKey) => result[currentKey]);
};

const getChildren = (
  parentId,
  tree,
  path,
  savePath,
  saveParent = null,
  childrenKey = 'ID_ITEM',
  includeParentIdNat = false,
  ID_SUP = 0,
  key = 'ID_NAT',
  parentIdentifier = 1,
  childrenIdentifier = 3,
  canNotHaveChildrens = false
) => {
  return tree[parentId].map((e, i) => {
    const newPath = `${path}.${i}`;
    savePath(e[childrenKey], newPath);

    const element = {
      ...e,
      PATH: newPath,
      ...(e[key] === 1 ? { expanded: true } : {}),
      ...(tree[e[childrenKey]]
        ? {
            children: getChildren(
              e[childrenKey],
              tree,
              newPath,
              savePath,
              saveParent,
              childrenKey,
              includeParentIdNat,
              e[key],
              key,
              parentIdentifier,
              childrenIdentifier,
              canNotHaveChildrens
            ),
          }
        : {}),
      ...(includeParentIdNat ? { [`${key}_SUP`]: ID_SUP } : {}),
    };
    if (
      saveParent &&
      element[key] === parentIdentifier &&
      (canNotHaveChildrens || _.get(element, ['children', 0, key], null) === childrenIdentifier)
    ) {
      saveParent(element);
    }
    return element;
  });
};

const changeItemFilters = (value, isChecked, { callback, filters, options }) => {
  const selectAllKey = 'Seleccionar Todos';
  if (value === selectAllKey) {
    if (isChecked) {
      callback([]);
    } else {
      callback([selectAllKey, ...options]);
    }
  } else if (isChecked) {
    const index = filters.findIndex((current) => current && current.ID_ITEM === value.ID_ITEM);
    const elementsToRemove = [[index, 1]];

    if (filters.indexOf(selectAllKey) !== -1) {
      elementsToRemove.push([0, 1]);
    }

    callback(update(filters, { $splice: elementsToRemove }));
  } else {
    callback(update(filters, { $push: [value] }));
  }
};
const getTreeList = (items, id = 0, key = 'ID_ITEM', parentKey = 'ID_ITEM_SUP') =>
  items
    .filter((item) => `${item[parentKey]}` === `${id}`)
    .map((item) => ({
      ...item,
      ...(getTreeList(items, item[key], key, parentKey).length > 0
        ? { children: getTreeList(items, item[key], key, parentKey) }
        : {}),
    }));

const getValueWithUnit = (
  unit,
  value,
  isPrefix,
  type,
  roundDecimals = null,
  onlyInteger,
  tooltip
) => {
  let newValue = value;
  if (type === 'string' || value === '') return value;
  newValue = getFormattedNumber(String(value), roundDecimals, onlyInteger);

  if (isPrefix) {
    return (
      <span
        title={tooltip || `${unit}${getFormattedNumber(String(value), roundDecimals, onlyInteger)}`}
      >{`${unit}${newValue}`}</span>
    );
  }

  return (
    <span
      title={tooltip || `${getFormattedNumber(String(value), roundDecimals, onlyInteger)}${unit}`}
    >{`${newValue}${unit}`}</span>
  );
};

const getDecimalValueWithUnit = (value, withUnit) => {
  return `${withUnit ? '$' : ''}${getFormattedNumber(String(value))}`;
};

const hiddeCellRender = (
  value,
  record,
  withFormat = false,
  withUnit = false,
  decimalsIndex = null
) => {
  if (record.ID_NAT === 1) {
    return null;
  }

  const formattedValue = `${withUnit ? '$' : ''}${getFormattedNumber(
    String(value),
    decimalsIndex
  )}`;
  return (
    <span title={getDecimalValueWithUnit(value, withUnit)}>
      {(typeof value === 'number' || !isNaN(value)) && withFormat ? formattedValue : value}
    </span>
  );
};
export {
  activeEditionsTitles,
  defaultFilteredData,
  variationFilterOptions,
  groupObjectInArray,
  groupNodesInArray,
  getChildren,
  changeItemFilters,
  getTreeList,
  getValueWithUnit,
  hiddeCellRender,
};
