/* eslint-disable import/no-cycle */
import React, { useState, useEffect } from 'react';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import { Modal, Button, Input, Form, Select, InputNumber } from 'antd';
import ResourcesTable from '../ResourcesTable';
import { commonPropTypes } from '../../../../utils';

import '../index.scss';
import './index.scss';

const NewSubItem = ({ units, inputsTypes, createSubitem, resourcesData }) => {
  const [state, setState] = useState({
    show: false,
    resourceCount: 1,
    resources: [],
    resourcesError: false,
    saving: false,
  });

  const { data: unitsData, isFetching: isFetchingUnits } = units;

  const [form] = Form.useForm();

  const { show, resources = [], resourcesError } = state;

  useEffect(() => {
    const total =
      resources.reduce(
        (acc, current) => acc + current.PRECIO_ITEM * current.FACTOR_MOD * current.CANTIDAD_MOD,
        0
      ) || 0;

    const price = total.toFixed();
    form.setFieldsValue({
      PRECIO_ITEM: price,
    });

    if (resourcesError) {
      setState(
        update(state, {
          resourcesError: { $set: false },
        })
      );
    }
  }, [resources]);

  const submit = (values) => {
    createSubitem(
      {
        ...values,
        PRECIO_ITEM: Number(values.PRECIO_ITEM),
        ID_ITEM: 0,
        FACTOR_MOD: 1,
        CANTIDAD_MOD: 0,
        ITEM_RECURSO: resources.map((e) => ({ ...e, selected: undefined })),
        ID_NAT: 5,
      },
      true
    ).then(() =>
      setState(
        update(state, {
          show: { $set: false },
        })
      )
    );
  };

  useEffect(() => {
    if (!show) {
      form.resetFields();
      setState(
        update(state, {
          resources: { $set: [] },
        })
      );
    }
  }, [show]);

  return (
    <div className="add-resource">
      <Button
        onClick={() =>
          setState(
            update(state, {
              show: { $set: true },
            })
          )
        }
        type="primary"
        className="new-item-button"
      >
        <i className="fa fa-plus" /> &nbsp;Crear Subpartida
      </Button>
      <Modal
        closable={false}
        wrapClassName="item-modal"
        visible={show}
        width={900}
        onCancel={() => {
          setState(
            update(state, {
              show: { $set: false },
            })
          );
        }}
        footer={null}
        okText="Guardar"
      >
        <div className="modal-container">
          <div className="modal-header">
            <div />
            <span>Selección de Subpartida - Nuevo</span>
            <i
              className="fa fa-times"
              onClick={() => {
                setState(
                  update(state, {
                    show: { $set: false },
                  })
                );
              }}
            />
          </div>
          <div className="form-body">
            <Form form={form} layout="vertical" onFinish={submit}>
              <Form.Item
                label="Código Subpartida"
                name="CODIGO_ITEM"
                required
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese un codigo de item',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Nombre Subpartida"
                name="NOMBRE_ITEM"
                required
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese un nombre de subpartida',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Unidad"
                name="ID_UNI"
                required
                rules={[
                  {
                    required: true,
                    message: 'Por favor seleccione una unidad',
                  },
                ]}
              >
                <Select
                  loading={isFetchingUnits}
                  disabled={isFetchingUnits}
                  optionFilterProp="children"
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {(unitsData || []).map(({ NOMABR_UNI, ID_UNI, NOMBRE_UNI }) => (
                    <Select.Option value={ID_UNI} key={ID_UNI}>
                      {`${NOMBRE_UNI} (${NOMABR_UNI})`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <ResourcesTable
                canAddSubItems={false}
                onUpdateResources={(data) => {
                  setState(update(state, { resources: { $set: data } }));
                }}
                resources={resources}
                inputsTypes={inputsTypes}
                resourcesData={resourcesData}
                units={units}
                unitsData={unitsData}
                resourcesError={resourcesError}
              />
              <Form.Item
                label="Precio Unitario"
                formatter={(value) => `$ ${value}`}
                name="PRECIO_ITEM"
                required
              >
                <InputNumber className="input-with-unit" disabled />
              </Form.Item>
              <div className="modal-footer">
                <Button
                  onClick={() =>
                    setState(
                      update(state, {
                        show: { $set: false },
                      })
                    )
                  }
                >
                  Cancelar
                </Button>
                <Button
                  onClick={() => {
                    if (!resources.length) {
                      setState(
                        update(state, {
                          resourcesError: { $set: true },
                        })
                      );
                    }
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  Agregar
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

NewSubItem.propTypes = {
  units: commonPropTypes.isRequired,
  createSubitem: PropTypes.func.isRequired,
  resourcesData: PropTypes.array.isRequired,
  inputsTypes: PropTypes.array.isRequired,
};

export default NewSubItem;
