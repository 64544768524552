import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import * as FileSaver from 'file-saver';
import ExcelJS from 'exceljs';
import moment from 'moment';
import { Row, Col } from 'antd';
import 'moment/locale/es';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import update from 'immutability-helper';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import Table from '../../components/TableExtend';
import { getCasGG as getCasGGAction } from '../../actions/casGG';
import columnsGG from './columnsGG';
import { getChildren } from '../../utils/budgetAndProductionHelpers';
import { getInputsTypes as getInputsTypesAction } from '../../actions/budget';
import { getAccountOfCost as getAccountOfCostAction } from '../../actions/accountOfCost';

import './index.scss';

moment.locale('es');

const TableGG = ({
  selectedWork,
  casGG,
  getCasGG,
  getInputsTypes,
  inputsTypes,
  accountOfCostInfo,
  getAccountOfCost,
  activeOption,
  casDate,
  tree,
  expandedRowKeysGg,
  setExpandeRowKeysGg,
}) => {
  const [familiesOptions, setFamiliesOptions] = useState(null);

  console.log('$$$$$$$', {
    selectedWork,
    casGG,
    getCasGG,
    getInputsTypes,
    inputsTypes,
    accountOfCostInfo,
    getAccountOfCost,
    activeOption,
    casDate,
    tree,
    expandedRowKeysGg,
    setExpandeRowKeysGg,
  });

  const { isFetching } = casGG;

  const { data: accountOfCostData, isFetching: isFetchingAccountOfCost } = accountOfCostInfo;

  useEffect(() => {
    if (!accountOfCostData[selectedWork] && !isFetchingAccountOfCost) {
      getAccountOfCost(selectedWork);
    }
    if (!isFetching) {
      getCasGG(casDate || moment().format('DD-MM-YYYY'), selectedWork, activeOption + 1);
    }
  }, [selectedWork]);

  useEffect(() => {
    if (!inputsTypes.data && !inputsTypes.isFetching) {
      getInputsTypes();
    } else if (!familiesOptions && inputsTypes.data) {
      setFamiliesOptions(inputsTypes.data);
    }
  }, [inputsTypes]);

  const changeExpandedRows = (expanded, ID_CC) => {
    if (expanded) {
      setExpandeRowKeysGg(
        update(expandedRowKeysGg, {
          $push: [ID_CC],
        })
      );
    } else {
      const index = expandedRowKeysGg.indexOf(ID_CC);

      if (index >= 0) {
        setExpandeRowKeysGg(
          update(expandedRowKeysGg, {
            $splice: [[index, 1]],
          })
        );
      }
    }
  };

  return (
    <div className="">
      <Row gutter={[30, 30]}>
        <Col className="p-0" span={24}>
          {isFetching ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '70vh',
              }}
            >
              Cargando...
            </div>
          ) : (
            <Table
              className="common-table"
              height={window.innerHeight - 225}
              hover={false}
              rowKey="ID_CC"
              bordered
              headerHeight={75}
              rowHeight={28}
              virtualized
              shouldUpdateScroll={false}
              isTree
              locale={{
                emptyMessage: 'No hay datos para mostrar',
                loading: 'Cargando...',
              }}
              loading={isFetching}
              data={tree || []}
              rowClassName={(record) => {
                if (record) {
                  let type = 'level';
                  if (record.TIPO === 1) {
                    type = 'green-row';
                  } else {
                    type = 'second-level';
                  }
                  if (!get(record, 'children.length')) {
                    type += ' without-children';
                  }
                  return type;
                }
                return '';
              }}
              renderTreeToggle={(_icon, _row, expanded) => {
                if (expanded) {
                  return <CaretUpOutlined />;
                }
                return <CaretDownOutlined />;
              }}
              expandedRowKeys={expandedRowKeysGg}
              onExpandChange={(expanded, rowData) => changeExpandedRows(expanded, rowData.ID_CC)}
            >
              {columnsGG(activeOption)}
            </Table>
          )}
        </Col>
      </Row>
    </div>
  );
};
TableGG.propTypes = {
  selectedWork: PropTypes.number.isRequired,
  casGG: PropTypes.object.isRequired,
  getCasGG: PropTypes.func.isRequired,
  getInputsTypes: PropTypes.func.isRequired,
  inputsTypes: PropTypes.object.isRequired,
  getAccountOfCost: PropTypes.func.isRequired,
  accountOfCostInfo: PropTypes.object.isRequired,
  activeOption: PropTypes.func.isRequired,
  casDate: PropTypes.func.isRequired,
  tree: PropTypes.array.isRequired,
  expandedRowKeysGg: PropTypes.array.isRequired,
  setExpandeRowKeysGg: PropTypes.func.isRequired,
};

export default connect(
  ({ production, works, casGG, accountOfCost, budget: { inputsTypes } }) => ({
    production,
    selectedWork: works.selectedWork,
    casGG,
    accountOfCostInfo: accountOfCost,
    inputsTypes,
  }),
  (dispatch) => ({
    getCasGG: bindActionCreators(getCasGGAction, dispatch),
    getInputsTypes: bindActionCreators(getInputsTypesAction, dispatch),
    getAccountOfCost: bindActionCreators(getAccountOfCostAction, dispatch),
  })
)(TableGG);
