import React from 'react';
import PropTypes from 'prop-types';
import { TreeList } from '@progress/kendo-react-treelist';
import { Col } from 'antd';
import { columnTable } from '../columns';

const ManagementTable = ({ data, isLoadingTable, title }) => {
  return (
    <Col span={24} className="p-0 m-0 second">
      <TreeList
        className={`kendo-table-management mx-auto my-0  ${isLoadingTable ? 'loading' : ' '}`}
        style={{
          height: `385px`,
          minHeight: '62vh',
          overflow: 'auto',
        }}
        resizable
        tableProps={{
          style: {
            tableLayout: 'fixed',
          },
        }}
        noRecords="No hay datos disponibles"
        rowHeight={28}
        scrollable="virtual"
        data={data}
        expandField="expanded"
        subItemsField="children"
        columns={columnTable(title)}
      />
    </Col>
  );
};

ManagementTable.propTypes = {
  data: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isLoadingTable: PropTypes.string.isRequired,
};

export default ManagementTable;
