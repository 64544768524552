import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import './index.scss';

let searchTimeout = null;

const InputWithTopLabel = ({
  className,
  icon,
  label,
  maxLength,
  onChange,
  keepShowingLabel,
  disabled,
  valueProp,
  withTimeOut,
  wrapperStyle,
  ...rest
}) => {
  const [value, setValue] = useState('');
  const [showLabel, toggleLabel] = useState(value.length > 0);

  useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);

  const changeSearch = (inputValue) => {
    setValue(inputValue);
    if (withTimeOut) {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }

      searchTimeout = setTimeout(() => {
        onChange(inputValue);
      }, 300);
    } else {
      onChange(inputValue);
    }
  };

  return (
    <div className={`inputContainer ${className}`}>
      {(showLabel || keepShowingLabel) && <div className="inputName">{label}</div>}
      <Input
        placeholder={showLabel ? '' : label}
        suffix={icon}
        value={value}
        onChange={(e) => changeSearch(e.target.value)}
        allowClear
        disabled={disabled}
        maxLength={maxLength}
        onFocus={() => toggleLabel(true)}
        onBlur={() => {
          if (value.length === 0) {
            toggleLabel(false);
          }
        }}
        {...rest}
      />
    </div>
  );
};

InputWithTopLabel.defaultProps = {
  className: '',
  icon: null,
  maxLength: null,
  keepShowingLabel: false,
  disabled: false,
  valueProp: '',
  withTimeOut: true,
  wrapperStyle: {},
};

InputWithTopLabel.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.element,
  label: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  keepShowingLabel: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  valueProp: PropTypes.string,
  withTimeOut: PropTypes.bool,
  wrapperStyle: PropTypes.object,
};

export default InputWithTopLabel;
