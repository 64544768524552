import React from 'react';
import { Column, HeaderCell, Cell, ColumnGroup } from 'rsuite-table';
import get from 'lodash/get';
import { getFormattedNumber } from '../../utils';
import EditableCell from '../../components/EditableCell/Production';

import './index.scss';

export const getTotals = (record, key = 'MONTO_PROD_ANT', priceKey = null) => {
  let total = record.TIPO === 4 ? record[key] * (priceKey ? record[priceKey] : 1) : 0;
  if (record.TIPO === 1) {
    total = record.children.reduce((acc, childrenRecord) => {
      return acc + childrenRecord[key] * (priceKey ? childrenRecord[priceKey] : 1);
    }, 0);
  }
  return total;
};

const getMonthsQtyValues = (record) => {
  const valuesKeys = {};

  record.MESES_ITEM.forEach((e) => {
    valuesKeys[`FLUJO_${e.NOMBRE_COL}`] = e.CANTIDAD;
  });

  Object.keys(record).forEach((key) => {
    if (key.includes('FLUJO_')) {
      valuesKeys[key] = record[key];
    }
  });

  return valuesKeys;
};

const reduceQtyMonths = (record) => {
  const valuesKeys = getMonthsQtyValues(record);

  return Object.keys(valuesKeys).reduce((acc, key) => {
    return acc + valuesKeys[key];
  }, 0);
};

export const getTotalPerMonth = (record, key) => {
  let total =
    record.TIPO === 4
      ? record.FACTOR_PROY * record.PRECIO_PROY * (getMonthsQtyValues(record)[key] || 0)
      : 0;

  const childrenLength = get(record, 'children.length', 0);
  if (record.TIPO !== 4 && childrenLength) {
    total = record.children.reduce((acc, childrenRecord) => {
      return acc + getTotalPerMonth(childrenRecord, key);
    }, 0);
  }
  return total;
};

export const getTotalsMonth = (
  record,
  key = 'FACTOR_PROY',
  priceKey = 'PRECIO_PROY',
  extraFactor = 1
) => {
  let total =
    record.TIPO === 4 ? record[key] * record[priceKey] * extraFactor * reduceQtyMonths(record) : 0;
  const childrenLength = get(record, 'children.length', 0);
  if (record.TIPO !== 4 && childrenLength) {
    total = record.children.reduce((acc, childrenRecord) => {
      return acc + getTotalsMonth(childrenRecord, key, priceKey, extraFactor);
    }, 0);
  }
  return total;
};

const columns = (activeOptions, activeEdition, handleSave, helpers) => {
  const canEditProjection = activeEdition === 'editProjection';
  const columnsResult = [
    ...(canEditProjection
      ? [
          <Column resizable fixed verticalAlign="middle" align="center" width={90}>
            <HeaderCell />
            <Cell dataKey="CODIGO_CC">
              {(record) => {
                if (!record.ID_CC_SUP || record.TIPO !== 3) return null;

                return (
                  <i className="fa fa-plus plus-cell" onClick={() => helpers.addRow(record)} />
                );
              }}
            </Cell>
          </Column>,
        ]
      : []),

    <Column resizable fixed verticalAlign="middle" align="center" width={90}>
      <HeaderCell>Código Item</HeaderCell>
      <EditableCell
        keepEditable
        dataIndex="CODIGO_ITEM"
        editable={canEditProjection}
        handleSave={handleSave}
        includeRecordFields={[
          'ID_TIPO_PNF',
          'PATH',
          'ID_CC',
          'MESES_ITEM',
          'ID_CC_SUP',
          'CODIGO_CC',
        ]}
        type="string"
        conditionToEditable={(record) => record.TIPO === 4}
        customClass="border-right"
        className={(record) => record.TIPO === 4 && canEditProjection && 'editable'}
      />
    </Column>,
    <Column resizable fixed treeCol verticalAlign="middle" align="left" width={300}>
      <HeaderCell>Ítem</HeaderCell>
      <EditableCell
        keepEditable
        dataIndex="ITEM"
        editable={canEditProjection}
        handleSave={handleSave}
        includeRecordFields={[
          'ID_TIPO_PNF',
          'PATH',
          'ID_CC',
          'MESES_ITEM',
          'ID_CC_SUP',
          'CODIGO_CC',
        ]}
        type="string"
        conditionToEditable={(record) => record.TIPO === 4}
        customClass="border-right"
        className={(record) => record.TIPO === 4 && canEditProjection && 'editable editable-item'}
      />
    </Column>,

    ...(activeOptions.option1
      ? [
          <Column resizable fixed verticalAlign="middle" align="left" width={150}>
            <HeaderCell>Descripción</HeaderCell>
            <EditableCell
              keepEditable
              dataIndex="DESCRIPCION_ITEM"
              editable={canEditProjection}
              handleSave={handleSave}
              includeRecordFields={[
                'ID_TIPO_PNF',
                'PATH',
                'ID_CC',
                'MESES_ITEM',
                'ID_CC_SUP',
                'CODIGO_CC',
              ]}
              type="string"
              conditionToEditable={(record) => record.TIPO === 4}
              customClass="border-right"
              className={(record) => record.TIPO === 4 && canEditProjection && 'editable'}
            />
          </Column>,
        ]
      : []),

    <Column
      resizable
      fixed
      verticalAlign="middle"
      align="center"
      width={70}
      className="border-right"
    >
      <HeaderCell>Ud</HeaderCell>
      <Cell dataKey="UM">
        {(record) => {
          if (!record.ID_CC_SUP) return null;

          return record.UM;
        }}
      </Cell>
    </Column>,

    ...(activeOptions.option2
      ? [
          <ColumnGroup align="center" header="Ppto.de GG">
            {[
              <Column resizable verticalAlign="middle" width={90}>
                <HeaderCell>Cantidad</HeaderCell>
                <Cell className="align-right">
                  {(record) => {
                    if (!record.ID_CC_SUP) return null;

                    return getFormattedNumber(record.CANTIDAD_BASE, 1);
                  }}
                </Cell>
              </Column>,
              <Column resizable verticalAlign="middle" align="center" width={140}>
                <HeaderCell>Total</HeaderCell>
                <Cell dataKey="TOTAL_VENTA" className="align-right border-right">
                  {(record) => {
                    const total = getTotals(record, 'CANTIDAD_BASE', 'PRECIO_ITEM');
                    return (
                      <span title={`$${getFormattedNumber(total)}`}>{`${getFormattedNumber(
                        total,
                        null,
                        true,
                        '$'
                      )}`}</span>
                    );
                  }}
                </Cell>
              </Column>,
            ]}
          </ColumnGroup>,
        ]
      : []),
    ...(activeOptions.option3
      ? [
          <ColumnGroup align="center" header="Avance de GG">
            {[
              <Column resizable verticalAlign="middle" width={90}>
                <HeaderCell>Cantidad</HeaderCell>
                <Cell className="align-right">
                  {(record) => {
                    if (!record.ID_CC_SUP) return null;

                    return getFormattedNumber(record.CANT_AVA, 1);
                  }}
                </Cell>
              </Column>,

              <Column resizable verticalAlign="middle" align="center" width={140}>
                <HeaderCell>Total</HeaderCell>
                <Cell dataKey="TOTAL_VENTA" className="align-right border-right">
                  {(record) => {
                    const total = getTotals(record, 'CANT_AVA', 'PRECIO_ITEM');

                    return (
                      <span title={`$${getFormattedNumber(total)}`}>{`${getFormattedNumber(
                        total,
                        null,
                        true,
                        '$'
                      )}`}</span>
                    );
                  }}
                </Cell>
              </Column>,
            ]}
          </ColumnGroup>,
        ]
      : []),
    ...(activeOptions.option4
      ? [
          <Column resizable verticalAlign="middle" align="center" width={140}>
            <HeaderCell>Costo Acumulado</HeaderCell>
            <Cell dataKey="MONTO_COSTO_ACUM" className="align-right border-right-ext">
              {(record) => {
                if (record.TIPO === 4) return null;
                return (
                  <span
                    title={`$${getFormattedNumber(record.MONTO_COSTO_ACUM)}`}
                  >{`${getFormattedNumber(record.MONTO_COSTO_ACUM, null, true, '$')}`}</span>
                );
              }}
            </Cell>
          </Column>,
        ]
      : []),
    ...(activeOptions.option5
      ? [
          <Column resizable verticalAlign="middle" align="center" width={160}>
            <HeaderCell>Desviación Acumulada </HeaderCell>
            <Cell dataKey="RESULTADO_ACUM_ANT" className="align-right border-right-ext">
              {(record) => {
                if (record.TIPO === 4) return null;
                const total =
                  getTotals(record, 'CANT_AVA', 'PRECIO_ITEM') - record.MONTO_COSTO_ACUM;
                const className = total >= 0 ? 'positive' : 'negative';
                return (
                  <span
                    className={className}
                    title={`$${getFormattedNumber(total)}`}
                  >{`${getFormattedNumber(total, null, true, '$')}`}</span>
                );
              }}
            </Cell>
          </Column>,
        ]
      : []),
    <ColumnGroup align="center" header="PU Proyección">
      {[
        <Column resizable verticalAlign="middle" width={90} className="border-right">
          <HeaderCell>Factor</HeaderCell>
          <EditableCell
            keepEditable
            dataIndex="FACTOR_PROY"
            editable={canEditProjection}
            handleSave={handleSave}
            includeRecordFields={['ID_TIPO_PNF', 'MESES_ITEM', 'ID_CC', 'ID_CC_SUP', 'CODIGO_CC']}
            type="number"
            additionalInputFields={() => ({
              type: 'number',
              min: 1,
            })}
            canShow={(record) => record.TIPO === 4}
            conditionToEditable={(record) => record.TIPO === 4}
            customClass="border-right"
            className={() => `align-right ${canEditProjection && 'editable'}`}
          />
        </Column>,
        <Column resizable verticalAlign="middle" align="center" width={140}>
          <HeaderCell>Tarifado/Liquido</HeaderCell>
          <EditableCell
            keepEditable
            dataIndex="PRECIO_ITEM"
            editable={canEditProjection}
            onlyInteger
            additionalInputFields={() => ({
              type: 'number',
              min: 0,
            })}
            unit="$"
            handleSave={handleSave}
            includeRecordFields={[
              'ID_TIPO_PNF',
              'MESES_ITEM',
              'ID_CC',
              'ID_CC_SUP',
              'CODIGO_CC',
              'CODIGO_ITEM',
            ]}
            type="number"
            canShow={(record) => record.TIPO === 4}
            conditionToEditable={(record) => record.TIPO === 4}
            className={() => `align-right ${canEditProjection && 'editable'}`}
          />
        </Column>,
        <Column resizable verticalAlign="middle" align="center" width={130}>
          <HeaderCell>PU Base</HeaderCell>
          <EditableCell
            keepEditable
            dataIndex="PRECIO_PROY"
            editable={canEditProjection}
            additionalInputFields={() => ({
              type: 'number',
              min: 0,
            })}
            unit="$"
            handleSave={handleSave}
            includeRecordFields={['ID_TIPO_PNF', 'MESES_ITEM', 'ID_CC', 'ID_CC_SUP', 'CODIGO_CC']}
            type="number"
            canShow={(record) => record.TIPO === 4}
            conditionToEditable={(record) => record.TIPO === 4}
            customClass="border-right"
            className={() => ` align-right ${canEditProjection && 'editable'}`}
          />
        </Column>,
      ]}
    </ColumnGroup>,
    ...(activeOptions.option0 && helpers.months.length
      ? [
          <ColumnGroup align="center" header="Flujo (Cantidad)">
            {helpers.months.map((current) => (
              <Column resizable verticalAlign="middle" width={85}>
                <HeaderCell>{current.NOMBRE}</HeaderCell>
                <EditableCell
                  keepEditable
                  dataIndex={`FLUJO_${current.NOMBRE_COL}`}
                  editable={canEditProjection}
                  decimalsIndex={(record) => (record.TIPO === 4 ? 1 : 0)}
                  onlyInteger={(record) => record.TIPO !== 4}
                  calculateValue={(record) => {
                    if (record.TIPO === 4) {
                      const { CANTIDAD } = record.MESES_ITEM.find(
                        (e) => e.NOMBRE === current.NOMBRE
                      ) || {
                        CANTIDAD: '',
                      };
                      return CANTIDAD;
                    }

                    const total = getTotalPerMonth(record, `FLUJO_${current.NOMBRE_COL}`);

                    return total;
                  }}
                  additionalInputFields={() => ({
                    type: 'number',
                    min: 0,
                  })}
                  conditionToEditable={(record) => record.TIPO === 4}
                  handleSave={handleSave}
                  includeRecordFields={[
                    'ID_TIPO_PNF',
                    'MESES_ITEM',
                    'ID_CC',
                    'ID_CC_SUP',
                    'CODIGO_CC',
                  ]}
                  type="number"
                  customClass="align-right border-left"
                  className={(record) =>
                    `${record.TIPO !== 4 ? 'with-unit' : ''} ${canEditProjection ? 'editable' : ''}`
                  }
                />
              </Column>
            ))}
          </ColumnGroup>,
        ]
      : []),
    <ColumnGroup align="center" header="Estimado Remanente">
      {[
        <Column resizable verticalAlign="middle" width={90}>
          <HeaderCell>Cantidad</HeaderCell>
          <Cell className="align-right border-left">
            {(record) => {
              if (record.TIPO === 4) {
                return getFormattedNumber(reduceQtyMonths(record), null, 2);
              }

              return null;
            }}
          </Cell>
        </Column>,
        <Column resizable verticalAlign="middle" align="center" width={140}>
          <HeaderCell>Total</HeaderCell>
          <Cell dataKey="TOTAL_VENTA" className="align-right border-right">
            {(record) => {
              const total = getTotalsMonth(record);

              return (
                <span title={`$${getFormattedNumber(total)}`}>{`${getFormattedNumber(
                  total,
                  null,
                  true,
                  '$'
                )}`}</span>
              );
            }}
          </Cell>
        </Column>,
      ]}
    </ColumnGroup>,
    <ColumnGroup align="center" header="Total Proyecto">
      {[
        <Column resizable verticalAlign="middle" align="center" width={140}>
          <HeaderCell>Total</HeaderCell>
          <Cell dataKey="TOTAL_VENTA" className="align-right border-right">
            {(record) => {
              if (record.TIPO === 4) return null;

              const total = record.MONTO_COSTO_ACUM + getTotalsMonth(record);

              return (
                <span title={`$${getFormattedNumber(total)}`}>{`${getFormattedNumber(
                  total,
                  null,
                  true,
                  '$'
                )}`}</span>
              );
            }}
          </Cell>
        </Column>,
      ]}
    </ColumnGroup>,
    <ColumnGroup align="center" header="Desviación a Término">
      {[
        <Column resizable verticalAlign="middle" align="center" width={160}>
          <HeaderCell>Total</HeaderCell>
          <Cell dataKey="RESULTADO_ACUM_ANT" className="align-right">
            {(record) => {
              const totalProject = record.MONTO_COSTO_ACUM + getTotalsMonth(record);
              const total = getTotals(record, 'CANTIDAD_BASE', 'PRECIO_ITEM') - totalProject;

              const className = total >= 0 ? 'positive' : 'negative';
              return (
                <span className={className} title={getFormattedNumber(total, null, null, '$')}>
                  {getFormattedNumber(total, null, true, '$')}
                </span>
              );
            }}
          </Cell>
        </Column>,
      ]}
    </ColumnGroup>,
  ];
  return columnsResult;
};

export default columns;
