import _ from 'lodash';
import { message } from 'antd';
import api, { getHeaders } from '../utils/api';
import {
  PNF_FETCHED,
  GET_PNF,
  GET_PNF_ERROR,
  PNF_CREATED,
  EDIT_PNF,
  GET_PNF_TYPES,
  PNF_TYPES_FETCHED,
  GET_PNF_LIST,
  PNF_LIST_FETCHED,
  PNF_LIST_ERROR,
  EDIT_LEVEL,
  EDIT_TYPE,
  EDIT_LEVELS,
} from '../reducers/pnf';
import store from '../store';

export const pnfFetched = (pnf, workId, attr) => ({
  type: PNF_FETCHED,
  pnf,
  workId,
  attr,
});

export const pnfCreated = (pnf, workId) => ({
  type: PNF_CREATED,
  pnf,
  workId,
});

export const editPnfSuccess = (body, workId) => ({
  type: EDIT_PNF,
  body,
  workId,
});

export const pnfTypesFetched = (types) => ({
  type: PNF_TYPES_FETCHED,
  types,
});

export const pnfListFetched = (list, workId) => ({
  type: PNF_LIST_FETCHED,
  list,
  workId,
});

export const getPnfTypes = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: GET_PNF_TYPES });

    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
    };

    api
      .post('/api/CostoPnf/GetTipos', requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch(pnfTypesFetched(res.data));
          resolve(res.data);
        } else {
          dispatch({
            type: GET_PNF_ERROR,
          });
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_PNF_ERROR,
        });
        reject(err);
      });
  });

export const getPnfList = (ID_OBR) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: GET_PNF_LIST });

    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR,
    };
    api
      .post('/api/CostoPnf/GetCostoPnfEnc', requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch(pnfListFetched(res.data, ID_OBR));
          resolve(res.data);
        } else {
          dispatch({
            type: PNF_LIST_ERROR,
          });
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: PNF_LIST_ERROR,
        });
        reject(err);
      });
  });

export const getPnf =
  (ID_PNF_ENC, ID_OBR, filters = null) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: GET_PNF });

      const requestBody = {
        USUARIO: localStorage.getItem('foco_username'),
        EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
        ID_OBR,
        ID_PNF_ENC,
        ...(filters || {
          CAPITULOS: [],
          PARTIDAS: [],
        }),
      };
      api
        .post('/api/CostoPnf/GetCostoPnf', requestBody, getHeaders())
        .then((res) => {
          if (res.status === 200) {
            dispatch(pnfFetched(res.data, ID_OBR));
            resolve(res.data);
          } else {
            dispatch({
              type: GET_PNF_ERROR,
            });
            reject(res.data);
          }
        })
        .catch((err) => {
          dispatch({
            type: GET_PNF_ERROR,
          });
          reject(err);
        });
    });

export const createPnf = (body) => (dispatch) =>
  new Promise((resolve, reject) => {
    const ID_OBR = _.get(store.getState(), 'works.selectedWork', '');
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR,
      ...body,
    };
    api
      .post('/api/CostoPnf/SetCostoPnfEnc', requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          message.success('PNF agregado correctamente');
          dispatch(pnfCreated({ ...res.data, ...body }, ID_OBR));
          resolve(res.data.ID_EP_ENC);
        } else {
          message.error('Hubo un error al crear el PNF ');
          reject();
        }
      })
      .catch((err) => {
        console.log('err', err);
        message.error('Hubo un error al crear el PNF ');
        reject(err);
      });
  });

export const closePnf = (ID_PNF_ENC) => (dispatch) =>
  new Promise((resolve, reject) => {
    const ID_OBR = _.get(store.getState(), 'works.selectedWork', '');
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR,
      ID_PNF_ENC,
    };
    api
      .post('/api/CostoPnf/SetPnfCerrar', requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200 && res.data.ESTADO_PNF === 1) {
          message.success('PNF Cerrado correctamente');
          getPnfList(ID_OBR)(dispatch);
          resolve();
        } else {
          message.error('Ocurrio un error al cerrar el PNF ');
          reject();
        }
      })
      .catch((err) => {
        console.log('err', err);
        message.error('Ocurrio un error al cerrar el PNF ');
        reject(err);
      });
  });

export const openPnf = (ID_PNF_ENC) => (dispatch) =>
  new Promise((resolve, reject) => {
    const ID_OBR = _.get(store.getState(), 'works.selectedWork', '');
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR,
      ID_PNF_ENC,
    };
    api
      .post('/api/CostoPnf/SetPnfAbrir', requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200 && res.data.ESTADO_PNF === 0) {
          message.success('PNF Abierto correctamente');
          getPnfList(ID_OBR)(dispatch);
          resolve();
        } else {
          message.error('Ocurrio un error al abrir el PNF ');
          reject();
        }
      })
      .catch((err) => {
        console.log('err', err);
        message.error('Ocurrio un error al abrir el PNF ');
        reject(err);
      });
  });

export const deletePnf = (ID_PNF_ENC) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_PNF_ENC,
    };
    debugger;
    api
      .post('/api/CostoPnf/DelPnf', requestBody, getHeaders())
      .then((res) => {
        debugger;
        if (res.status === 200 && res.data === 'OK') {
          message.success('PNF Eliminado correctamente');
          resolve();
        } else {
          message.error('Ocurrio un error al eliminar el PNF ');
          reject();
        }
      })
      .catch((err) => {
        console.log('err', err);
        message.error('Ocurrio un error al eliminar el PNF ');
        reject(err);
      });
  });

const editSuccessMessage = 'Valores editados correctamente';
const editErrorMessage = 'Ocurrió un error al editar los valores';

export const editPnfCells = (data, ID_PNF_ENC) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_PNF_ENC,
      ...data,
    };
    const ID_OBR = _.get(store.getState(), 'works.selectedWork', '');

    api
      .post('/api/CostoPNF/SetTipoPnf', requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data);
          if (Object.keys(data).some((e) => e.includes('COMENTARIO') || e.includes('REAJUSTE'))) {
            dispatch({ type: EDIT_LEVEL, data, workId: ID_OBR });
          }
          if (data.ITEM_TIPO_PNF.length) {
            dispatch({ type: EDIT_TYPE, data: data.ITEM_TIPO_PNF, workId: ID_OBR });
          }

          if (data.PNF_NIVEL) {
            dispatch({ type: EDIT_LEVELS, data: res.data, workId: ID_OBR });
          }

          message.success(editSuccessMessage, 8);
        } else {
          reject(res.data);
          message.error(editErrorMessage, 8);
        }
      })
      .catch((err) => {
        console.log('errre', err);
        message.error(editErrorMessage, 8);
        reject(err);
      });
  });
