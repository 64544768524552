import _ from 'lodash';
import { message } from 'antd';
import api, { getHeaders } from '../utils/api';
import {
  GET_ATTENDANCE,
  GET_ATTENDANCE_TYPES,
  GET_CLOSED_ATTENDANCE,
  SET_ATTENDANCE,
  SET_CLOSED_ATTENDANCE,
  SET_ERROR_ATTENDANCE,
  SET_MASS_ATTENDANCE,
  SET_ATTENDANCE_VIEW_TYPE,
  SET_OPENED_ATTENDANCE,
} from '../reducers/attendance';
import store from '../store';

export const setError = (error = '') => ({
  type: SET_ERROR_ATTENDANCE,
  payload: { error },
});

export const getAttendanceTypes = () => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
    };
    const errorMessage = 'No se cargaron éxitosamente los tipos de asistencia.';
    api
      .post(`/api/Productividad/GetCausalAsistencia`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: GET_ATTENDANCE_TYPES, payload: { attendanceTypes: res.data } });
          resolve(res.data);
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });

export const getAttendance = (month, year) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ANHO: year,
      MES: month,
      ID_OBR: _.get(store.getState(), 'works.selectedWork', ''),
    };
    const errorMessage = 'No se cargaron éxitosamente las asistencias.';
    api
      .post(`/api/Productividad/GetAsistencia`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: GET_ATTENDANCE, payload: { attendance: res.data.ASISTENCIA } });
          dispatch({
            type: SET_ATTENDANCE_VIEW_TYPE,
            payload: { attendanceViewType: res.data.VISTA_ASISTENCIA },
          });
          resolve(res.data);
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })

      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });

export const getClosedAttendance = (month, year) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ANHO: year,
      MES: month,
      ID_OBR: _.get(store.getState(), 'works.selectedWork', ''),
    };
    const errorMessage = 'No se cargaron éxitosamente los asistencias cerradas.';
    api
      .post(`/api/Productividad/GetCierreAsistencia`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: GET_CLOSED_ATTENDANCE, payload: { closedAttendance: res.data } });
          resolve(res.data);
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });

export const setClosedAttendance = (day) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      DIA_CIERRE: day,
      ID_OBR: _.get(store.getState(), 'works.selectedWork', ''),
    };
    const errorMessage = 'No se pudo cerrar la asistencia.';
    api
      .post(`/api/Productividad/SetCierreAsistencia`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: SET_CLOSED_ATTENDANCE, payload: { closedAttendance: res.data } });
          resolve(res.data);
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });
export const setOpenedAttendance = (day) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      DIA_CIERRE: day,
      ID_OBR: _.get(store.getState(), 'works.selectedWork', ''),
    };
    const errorMessage = 'No se pudo abrir la asistencia.';
    api
      .post(`/api/Productividad/SetAbrirAsistencia`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          const { DIA_CIERRE } = res.data;
          dispatch({ type: SET_OPENED_ATTENDANCE, payload: { openedAttendance: DIA_CIERRE } });
          resolve(res.data);
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });

export const setAttendance = (attendance) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ...attendance,
    };

    const errorMessage = 'No se pudo guardar la nueva asistencia.';
    api
      .post(`/api/Productividad/SetAsistencia`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: SET_ATTENDANCE, payload: { attendance: res.data } });
          resolve(res.data);
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });

export const setMassAttendance = (massAttendance) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ...massAttendance,
    };
    const errorMessage = 'No se pudo guardar la nueva asistencia masiva.';
    api
      .post(`/api/Productividad/SetAsistenciaMasiva`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: SET_MASS_ATTENDANCE, payload: { attendances: res.data } });
          resolve(res.data);
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });
