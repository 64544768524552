/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, message, Tooltip } from 'antd';
import moment from 'moment';
import api, { getHeaders } from '../../utils/api';
import DatePickerWithTopLabel from '../DatePickerWithTopLabel';

import './index.scss';

const NewAdvance = ({ dates, updateDates, workDate, disabled, requestBody, isAdvanceControl }) => {
  const [date, setDate] = useState(null);
  const [show, setShow] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (show) {
      let valid = false;
      let defaultDate = moment();
      while (!valid) {
        if (dates.includes(defaultDate.format('DD-MM-YYYY'))) {
          defaultDate = defaultDate.add(1, 'days');
        } else {
          setDate(defaultDate);
          valid = true;
        }
      }
    }
  }, [show]);

  const getDisabledDates = (current) => {
    return (
      moment(workDate, 'DD-MM-YYYY').add(1, 'days').isAfter(current) ||
      dates.includes(current.format('DD-MM-YYYY'))
    );
  };

  const save = async () => {
    setSaving(true);
    const newDate = date.format('DD-MM-YYYY');
    const response = await api.post(
      'api/Avances/SetAvaFechaAvance',
      { ...requestBody, FECHA_AVA: newDate },
      getHeaders()
    );
    if (response.status === 200) {
      updateDates({
        ESTADO_AVA: 0,
        FECHA_AVA: newDate,
        ...(isAdvanceControl
          ? { ID_FECHA: response.data.ID_AVA }
          : { ID_AVA: response.data.ID_CONS }),
      });
      setShow(false);
      setDate(null);
      message.success('Avance creado correctamente, cargando datos de avance...');
    } else {
      message.error('Hubo un error al crear el avance');
    }
    setSaving(false);
  };
  return (
    <>
      <Tooltip
        placement="topLeft"
        destroyTooltipOnHide={{ keepParent: false }}
        title="Crear nuevo avance"
      >
        <Button disabled={disabled} onClick={() => setShow(true)}>
          <i className="fa fa-file" />
        </Button>
      </Tooltip>
      <Modal
        title="Indique Fecha de Avance"
        visible={show}
        footer={null}
        className="new-advance-modal"
        onCancel={() => setShow(false)}
      >
        <div className="date-picker-wrapper">
          <DatePickerWithTopLabel
            label="Fecha de Avance"
            placeholder="DD-MM-YYYY"
            value={date}
            onChange={setDate}
            format="DD-MM-YYYY"
            keepShowingLabel
            disabledDate={getDisabledDates}
            allowClear={false}
          />
        </div>
        <div className="footer-buttons">
          <Button
            disabled={saving}
            type="button"
            onClick={() => {
              setDate(null);
              setShow(false);
            }}
          >
            Cancelar
          </Button>
          <Button onClick={save} loading={saving} type="primary">
            Crear
          </Button>
        </div>
      </Modal>
    </>
  );
};

NewAdvance.propTypes = {
  dates: PropTypes.array.isRequired,
  workDate: PropTypes.string.isRequired,
  updateDates: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  requestBody: PropTypes.object.isRequired,
  isAdvanceControl: PropTypes.bool.isRequired,
};

export default NewAdvance;
