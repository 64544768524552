import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import qs from 'querystring';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LoadingOutlined } from '@ant-design/icons';
import _ from 'lodash';
import update from 'immutability-helper';
import Header from './Header';
import Table from './Table';
import { setInformationFromPaymentURL as setInformationFromPaymentURLAction } from '../../actions/auth';
import {
  getChapters as getChaptersAction,
  getEnclosure as getEnclosureAction,
  getEnclosuresAndItems as getEnclosuresAndItemsAction,
  getEnclosureTree as getEnclosureTreeAction,
  getEnclosureTypes as getEnclosureTypesAction,
  getItems as getItemsAction,
} from '../../actions/enclosuresAndItems';
import './index.scss';

const optionsToMoreFilters = [
  { name: 'Solo Actividades con avance en el periodo' },
  { name: 'Solo Recintos con avance en el periodo' },
  { name: 'Solo Registros ajustados por tasador' },
];

const PaymentStatus = ({
  auth,
  enclosuresAndItemsInRedux,
  getChapters,
  getEnclosure,
  getEnclosuresAndItems,
  getEnclosureTree,
  getEnclosureTypes,
  getItems,
  location,
  payments,
  setInformationFromPaymentURL,
}) => {
  const [dataTable, setDataTable] = useState([]);
  const [itemsToShow, setItemsToShow] = useState([]);
  const [enclosureToShow, setEnclosureToShow] = useState([]);
  const [enclosureTypesToShow, setEnclosureTypesToShow] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([0, 1]);
  const [maxHeaderLength, setMaxHeaderLength] = useState(0);
  const [loading, setLoading] = useState(false);
  const [headerTable, setHeaderTable] = useState([]);
  const [fixedColumn, setFixedColumn] = useState([]);
  const [fixedColumnWidth, setFixedColumnWidth] = useState(0);
  const [chaptersToShow, setChaptersToShow] = useState([]);
  const {
    chapters,
    enclosures,
    enclosuresAndItems,
    enclosureTree,
    enclosureTypes,
    items,
  } = enclosuresAndItemsInRedux;
  const { foco_client, foco_payment_id, foco_token, foco_username } = qs.parse(
    location.search.replace('?', '')
  );
  const selectedFiltersSet = new Set(selectedFilters);

  useEffect(() => {
    if (foco_token) {
      setInformationFromPaymentURL(foco_client, foco_payment_id, foco_token, foco_username);
      localStorage.setItem('foco_token', foco_token);
    }
  }, []);

  useEffect(() => {
    if (auth.token && auth.user.username && payments.selectedPayment) {
      if (!enclosures) {
        getEnclosure();
      }

      if (!items) {
        getItems();
      }

      if (!enclosuresAndItems) {
        getEnclosuresAndItems();
      }

      if (!enclosureTypes) {
        getEnclosureTypes();
      }

      if (!enclosureTree) {
        getEnclosureTree();
      }

      if (!chapters) {
        getChapters();
      }
    }
  }, [auth, payments]);

  const handleSelectedFilters = (selectedOptions) => {
    const selectedOptionsSet = new Set(selectedOptions);
    let getEnclosureInformation = false;
    let getItemsInformation = false;

    if (
      (selectedFiltersSet.has(1) && !selectedOptionsSet.has(1)) ||
      (!selectedFiltersSet.has(1) && selectedOptionsSet.has(1))
    ) {
      getEnclosureInformation = true;
    }

    if (
      (selectedFiltersSet.has(0) && !selectedOptionsSet.has(0)) ||
      (!selectedFiltersSet.has(0) && selectedOptionsSet.has(0))
    ) {
      getItemsInformation = true;
    }

    if (getEnclosureInformation && !getItemsInformation) {
      setLoading(true);
      setItemsToShow([]);
      setEnclosureToShow([]);
      setEnclosureTypesToShow([]);
      setChaptersToShow([]);

      getEnclosure(selectedOptionsSet.has(1))
        .then(() => {
          getEnclosureTree(selectedOptionsSet.has(1), selectedOptionsSet.has(0))
            .then(() => {
              setLoading(false);
            })
            .catch(() => {
              setLoading(false);
            });
        })
        .catch(() => {
          setLoading(false);
        });
    }

    if (!getEnclosureInformation && getItemsInformation) {
      setLoading(true);
      setItemsToShow([]);
      setEnclosureToShow([]);
      setEnclosureTypesToShow([]);
      setChaptersToShow([]);

      getItems(selectedOptionsSet.has(0))
        .then(() => {
          getEnclosureTree(selectedOptionsSet.has(1), selectedOptionsSet.has(0))
            .then(() => {
              setLoading(false);
            })
            .catch(() => {
              setLoading(false);
            });
        })
        .catch(() => {
          setLoading(false);
        });
    }

    if (getEnclosureInformation && getItemsInformation) {
      setLoading(true);
      setItemsToShow([]);
      setEnclosureToShow([]);
      setEnclosureTypesToShow([]);
      setChaptersToShow([]);

      getItems(selectedOptionsSet.has(0))
        .then(() => {
          getEnclosure(selectedOptionsSet.has(1))
            .then(() => {
              getEnclosureTree(selectedOptionsSet.has(1), selectedOptionsSet.has(0))
                .then(() => {
                  setLoading(false);
                })
                .catch(() => {
                  setLoading(false);
                });
            })
            .catch(() => {
              setLoading(false);
            });
        })
        .catch(() => {
          setLoading(false);
        });
    }

    setSelectedFilters(selectedOptions);
  };

  useEffect(() => {
    if (enclosures && items && enclosuresAndItems && !loading) {
      const enclosuresAndItemsMap = new Map(
        enclosuresAndItems.map((e) => [
          `${e.ID_UCO}_${e.ID_INS_EP}`,
          {
            PRC_AVA_ACUM: _.parseInt(e.PRC_AVA_ACUM),
            PRC_AVA_ACUM_APROB: _.parseInt(e.PRC_AVA_ACUM_APROB),
          },
        ])
      );

      let newDataTable = [];
      let newFixedColumn = [];
      let widthColumn = 0;
      const newHeaderTable = [];
      let heightHeader = 0;
      const enclosuresMap = new Map(enclosures.map((e) => [e.ID_UCO, e.NOMBRE_UCO]));

      items.forEach((item) => {
        let newRow = { ...item, cells: [] };

        newFixedColumn.push(item.NOMBRE_INS_EP);
        widthColumn =
          item.NOMBRE_INS_EP.length > widthColumn ? item.NOMBRE_INS_EP.length : widthColumn;

        enclosures.forEach((encl) => {
          const value = enclosuresAndItemsMap.get(`${encl.ID_UCO}_${item.ID_INS_EP}`)
            ? enclosuresAndItemsMap.get(`${encl.ID_UCO}_${item.ID_INS_EP}`).PRC_AVA_ACUM_APROB
            : '';

          newRow = update(newRow, {
            cells: {
              $push: [
                {
                  // eslint-disable-next-line no-nested-ternary
                  CELL_COLOR: value ? (value === 100 ? '#6aa84f' : '#ffffff') : '#f7f3f8',
                  ID_UCO: encl.ID_UCO,
                  NOMBRE_UCO: encl.NOMBRE_UCO,
                  PRC_AVA_ACUM: enclosuresAndItemsMap.has(`${encl.ID_UCO}_${item.ID_INS_EP}`)
                    ? enclosuresAndItemsMap.get(`${encl.ID_UCO}_${item.ID_INS_EP}`).PRC_AVA_ACUM
                    : '',
                  PRC_AVA_ACUM_APROB: value,
                  TEXT_COLOR:
                    typeof value === 'number' &&
                    value !==
                      enclosuresAndItemsMap.get(`${encl.ID_UCO}_${item.ID_INS_EP}`).PRC_AVA_ACUM
                      ? 'red'
                      : '#000',
                },
              ],
            },
          });
        });

        newDataTable.push(newRow);
      });

      if (
        enclosureToShow.length !== 0 ||
        enclosureTypesToShow.length !== 0 ||
        itemsToShow.length !== 0 ||
        selectedFiltersSet.has(2) ||
        chaptersToShow.length !== 0
      ) {
        const filteredData = [];
        const filteredColumn = [];
        let enclosuresToFilter = new Set();
        const enclosureTypesToFilter = [];
        let itemsToShowSet = new Set();

        if (enclosureTypesToShow.length !== 0) {
          const enclosureTypesToShowSet = new Set(enclosureTypesToShow);

          enclosures.forEach((e) => {
            if (enclosureTypesToShowSet.has(e.ID_TUC)) {
              enclosureTypesToFilter.push(e.ID_UCO);
            }
          });
        }

        if (enclosureToShow.length !== 0 && enclosureTypesToFilter.length === 0) {
          enclosuresToFilter = new Set(enclosureToShow);
        }

        if (enclosureToShow.length === 0 && enclosureTypesToFilter.length !== 0) {
          enclosuresToFilter = new Set(enclosureTypesToFilter);
        }

        if (enclosureToShow.length !== 0 && enclosureTypesToFilter.length !== 0) {
          enclosuresToFilter = new Set(_.intersection(enclosureToShow, enclosureTypesToFilter));
        }

        if (itemsToShow.length !== 0 && chaptersToShow.length === 0) {
          itemsToShowSet = new Set(itemsToShow);
        }

        if (itemsToShow.length === 0 && chaptersToShow.length !== 0) {
          itemsToShowSet = new Set(chaptersToShow);
        }

        if (itemsToShow.length !== 0 && chaptersToShow.length !== 0) {
          itemsToShowSet = new Set(_.intersection(itemsToShow, chaptersToShow));
        }

        const enclosureToShowSet = new Set(enclosuresToFilter);

        const newItems = [];
        const newEnclosures = new Set();

        for (let i = 0; i < newDataTable.length; i++) {
          let addItem = false;
          const item = newDataTable[i];

          for (let j = 0; j < item.cells.length; j++) {
            const cell = item.cells[j];

            if (
              (itemsToShowSet.size === 0 ? true : itemsToShowSet.has(item.ID_INS_EP)) &&
              (enclosureToShowSet.size === 0
                ? true
                : enclosureToShowSet.has(cell.ID_UCO) &&
                  typeof cell.PRC_AVA_ACUM_APROB === 'number') &&
              (!selectedFiltersSet.has(2)
                ? true
                : cell.PRC_AVA_ACUM_APROB && cell.PRC_AVA_ACUM_APROB !== cell.PRC_AVA_ACUM)
            ) {
              addItem = true;
              if (cell.PRC_AVA_ACUM_APROB) {
                newEnclosures.add(cell.ID_UCO);
              }
            }
          }

          if (addItem) {
            newItems.push(item);
          }
        }

        newItems.forEach((item) => {
          filteredData.push(
            update(item, {
              cells: {
                $apply: (cells) => cells.filter((e) => newEnclosures.has(e.ID_UCO)),
              },
            })
          );
          filteredColumn.push(item.NOMBRE_INS_EP);
          widthColumn =
            item.NOMBRE_INS_EP.length > widthColumn ? item.NOMBRE_INS_EP.length : widthColumn;
        });

        newDataTable = filteredData;
        newFixedColumn = filteredColumn;
      }

      if (newDataTable.length) {
        newDataTable[0].cells.forEach((e) => {
          newHeaderTable.push(enclosuresMap.get(e.ID_UCO));
          heightHeader =
            enclosuresMap.get(e.ID_UCO).length > heightHeader
              ? enclosuresMap.get(e.ID_UCO).length
              : heightHeader;
        });
      }

      setDataTable(newDataTable);
      setHeaderTable(newHeaderTable);
      setFixedColumn(newFixedColumn);
      setFixedColumnWidth(widthColumn);
      setMaxHeaderLength(heightHeader);
    }
  }, [
    chaptersToShow,
    loading,
    enclosureToShow,
    enclosureTypesToShow,
    enclosures,
    enclosuresAndItems,
    items,
    itemsToShow,
    selectedFilters,
  ]);

  return (
    <div className="paymentStatus">
      {!enclosures ||
      !items ||
      !enclosuresAndItems ||
      !enclosureTree ||
      !enclosureTypes ||
      !chapters ? (
        <div className="spin">
          <LoadingOutlined />
        </div>
      ) : (
        <>
          <Header
            chapters={chapters}
            chaptersToShow={chaptersToShow}
            enclosureTree={enclosureTree}
            enclosureToShow={enclosureToShow}
            enclosureTypes={enclosureTypes}
            items={items}
            loading={loading}
            optionsToMoreFilters={optionsToMoreFilters.map((e, i) => ({
              ...e,
              value: selectedFiltersSet.has(i),
            }))}
            setChaptersToShow={setChaptersToShow}
            setEnclosureToShow={setEnclosureToShow}
            setEnclosureTypesToShow={setEnclosureTypesToShow}
            setItemsToShow={setItemsToShow}
            setSelectedFilters={handleSelectedFilters}
          />
          <Table
            dataTable={dataTable}
            fixedColumn={fixedColumn}
            fixedColumnWidth={fixedColumnWidth * 9}
            header={headerTable}
            heightHeader={maxHeaderLength * 9}
            loading={loading}
          />
        </>
      )}
    </div>
  );
};

PaymentStatus.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.object,
    token: PropTypes.string,
  }).isRequired,
  enclosuresAndItemsInRedux: PropTypes.shape({
    chapters: PropTypes.array,
    enclosures: PropTypes.array,
    enclosuresAndItems: PropTypes.array,
    enclosureTree: PropTypes.array,
    enclosureTypes: PropTypes.array,
    items: PropTypes.array,
  }).isRequired,
  getChapters: PropTypes.func.isRequired,
  getEnclosure: PropTypes.func.isRequired,
  getEnclosuresAndItems: PropTypes.func.isRequired,
  getEnclosureTree: PropTypes.func.isRequired,
  getEnclosureTypes: PropTypes.func.isRequired,
  getItems: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  payments: PropTypes.shape({
    selectedPayment: PropTypes.number,
  }).isRequired,
  setInformationFromPaymentURL: PropTypes.func.isRequired,
};

export default connect(
  (store) => ({
    auth: store.auth,
    enclosuresAndItemsInRedux: store.enclosuresAndItems,
    payments: store.payments,
  }),
  (dispatch) => ({
    getChapters: bindActionCreators(getChaptersAction, dispatch),
    getEnclosure: bindActionCreators(getEnclosureAction, dispatch),
    getEnclosuresAndItems: bindActionCreators(getEnclosuresAndItemsAction, dispatch),
    getEnclosureTree: bindActionCreators(getEnclosureTreeAction, dispatch),
    getEnclosureTypes: bindActionCreators(getEnclosureTypesAction, dispatch),
    getItems: bindActionCreators(getItemsAction, dispatch),
    setInformationFromPaymentURL: bindActionCreators(setInformationFromPaymentURLAction, dispatch),
  })
)(PaymentStatus);
