import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import update from 'immutability-helper';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Column, HeaderCell, Cell } from 'rsuite-table';
import Table from '../../components/TableExtend';

import UpdateJobCost from './UpdateJobCost';
import { getJobCost as getJobCostAction } from '../../actions/jobCost';
import InputWithTopLabel from '../../components/InputWithTopLabel';
import { getFormattedNumber, SearchIcon } from '../../utils';

import './index.scss';

let searchTimeout = null;

const getCell = (key, name, width = 150, fixed = false) => {
  return (
    <Column
      resizable
      fixed={fixed}
      width={width}
      verticalAlign="middle"
      align="center"
      className="border-right"
    >
      <HeaderCell>{name}</HeaderCell>
      <Cell wordWrap>
        {(record) => {
          return record[key];
        }}
      </Cell>
    </Column>
  );
};

const JobCost = ({ jobCost, getJobCost, works, auth, access }) => {
  const { selectedWork } = works;
  const { CODIGO_ERP } = works.data.find((e) => `${e.ID_OBR}` === `${selectedWork}`) || {};
  const [state, setState] = useState({
    data: null,
    totalGlaa: null,
    initialTotalGlaa: null,
    active: null,
    globjFilter: '',
    glsubFilter: '',
  });
  const { isFetching, data: jobCostData } = jobCost;
  const { data, totalGlaa, initialTotalGlaa, globjFilter, glsubFilter } = state;

  useEffect(() => {
    if (!isFetching && !jobCostData[selectedWork]) {
      getJobCost();
    } else if (jobCostData[selectedWork] && !isFetching) {
      const totalGlaaValue = jobCostData[selectedWork].COSTO_API_CONPAX.reduce(
        (acc, { GLAA }) => acc + Number(GLAA),
        0
      );
      setState(
        update(state, {
          data: { $set: jobCostData[selectedWork].COSTO_API_CONPAX },
          totalGlaa: { $set: totalGlaaValue },
          initialTotalGlaa: { $set: totalGlaaValue },
        })
      );
    }
  }, [jobCost, selectedWork]);

  useEffect(() => {
    if (jobCostData[selectedWork]) {
      if (globjFilter || glsubFilter) {
        const newData = jobCostData[selectedWork].COSTO_API_CONPAX.filter(
          (record) =>
            String(record.GLOBJ).includes(String(globjFilter)) &&
            String(record.GLSUB).includes(String(glsubFilter))
        );
        const totalGlaaValue = newData.reduce((acc, { GLAA }) => acc + Number(GLAA), 0);
        setState(update(state, { totalGlaa: { $set: totalGlaaValue }, data: { $set: newData } }));
      } else if (!globjFilter && !glsubFilter) {
        setState(
          update(state, {
            totalGlaa: { $set: initialTotalGlaa },
            data: { $set: jobCostData[selectedWork].COSTO_API_CONPAX },
          })
        );
      }
    }
  }, [globjFilter, glsubFilter]);

  const handleSearch = (value, key) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    searchTimeout = setTimeout(() => {
      setState(update(state, { [`${key}Filter`]: { $set: value } }));
    }, 300);
  };

  return (
    <div className="jobCost">
      <Row gutter={[30, 30]}>
        <Col className="top-col" span={24}>
          <InputWithTopLabel
            label="Última Actualización"
            keepShowingLabel
            className="input-info"
            disabled
            valueProp={get(jobCostData, `[${selectedWork}].FECHA`, '...Cargando')}
          />
          <InputWithTopLabel
            label="Usuario"
            keepShowingLabel
            className="input-info"
            disabled
            valueProp={get(auth, `user.client`, '...Cargando')}
          />
          <InputWithTopLabel
            label="Monto total "
            keepShowingLabel
            className="input-info"
            disabled
            valueProp={totalGlaa ? getFormattedNumber(totalGlaa, 2, null, '$') : '...Cargando'}
          />
          {!!access.JOBCOST_ACTUALIZAR && (
            <UpdateJobCost
              jobCostData={get(jobCostData, `[${selectedWork}]`, null)}
              erpCode={CODIGO_ERP}
              isFetching={isFetching}
            />
          )}
          <InputWithTopLabel
            icon={<SearchIcon />}
            onChange={(value) => handleSearch(value, 'globj')}
            label="Buscar GLOBJ"
            disabled={!data}
            className="jobcost-search"
          />
          <InputWithTopLabel
            icon={<SearchIcon />}
            onChange={(value) => handleSearch(value, 'glsub')}
            label="Buscar GLSUB"
            disabled={!data}
            className="jobcost-search"
          />
        </Col>
      </Row>
      <Table
        className="common-table mt-3 pt-0"
        height={window.innerHeight - 166}
        hover={false}
        rowKey="ID"
        wordWrap
        bordered
        headerHeight={50}
        loading={isFetching}
        rowHeight={38}
        virtualized
        shouldUpdateScroll={false}
        locale={{
          emptyMessage: 'No hay datos para mostrar',
          loading: 'Cargando...',
        }}
        data={data || []}
      >
        {getCell('GLOBJ', 'GLOBJ', 70, true)}
        {getCell('GLSUB', 'GLSUB', 70, true)}
        {getCell('ID', 'ID', 90, true)}
        {getCell('TCARGA', 'TCarga', 120)}
        {getCell('TREPORTE', 'TReporte', 120)}
        {getCell('TCLASIFICACION', 'TClasifcacion', 120)}
        {getCell('TPARTIDA', 'TPartida')}
        {getCell('GLDCT', 'GLDCT', 70)}
        {getCell('TGLDCT', 'TGLDCT')}
        {getCell('GLDOC', 'GLDOC', 90)}
        {getCell('GLDICJ', 'GLDICJ')}
        {getCell('GLDGJ', 'GLDGJ')}
        {getCell('GLICU', 'GLICU', 80)}
        {getCell('GLICUT', 'GLICUT', 60)}
        {getCell('TGLICUT', 'TGLICUT')}
        {getCell('GLMCU', 'GLMCU', 90)}

        {getCell('GLSBL', 'GLSBL', 100)}
        {getCell('TGLSBL', 'TGLSBL')}
        {getCell('GLPN', 'GLPN', 70)}
        {getCell('GLFY', 'GLFY', 70)}
        {getCell('FYPN', 'FYPN', 70)}
        {getCell('GLAA', 'GLAA', 70)}
        {getCell('GLAN8', 'GLAN8', 70)}
        {getCell('GLEXA', 'GLEXA')}
        {getCell('GLEXR', 'GLEXR')}
        {getCell('GLUSER', 'GLUSER', 90)}
        {getCell('KEY', 'KEY', 200)}
      </Table>
    </div>
  );
};

JobCost.propTypes = {
  jobCost: PropTypes.object.isRequired,
  getJobCost: PropTypes.func.isRequired,
  works: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  access: PropTypes.object.isRequired,
};

export default connect(
  ({ jobCost, works, auth }) => ({
    jobCost,
    works,
    auth,
  }),
  (dispatch) => ({
    getJobCost: bindActionCreators(getJobCostAction, dispatch),
  })
)(JobCost);
