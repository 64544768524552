import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { v4 as uuidv4 } from 'uuid';
import * as FileSaver from 'file-saver';
import ExcelJS from 'exceljs';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { bindActionCreators } from 'redux';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Column, HeaderCell, Cell } from 'rsuite-table';
import moment from 'moment';
import Table from '../../../components/TableExtend';
import EditableCell from '../../../components/EditableCell/Production';
import { getPaymentStates as getPaymentStatesAction } from '../../../actions/paymentState';

import { getFormattedNumber } from '../../../utils';

import './index.scss';

const Levels = ({
  handleSave,
  pnfData,
  itemsMap,
  canEdit,
  isFetching,
  typesData,
  savingEdition,
  paymentState,
  getPaymentStates,
  selectedWork,
  levels,
  callDownload,
  pdfDownloaded,
}) => {
  const { isFetching: isFetchingPaymentState } = paymentState;
  const [state, setState] = useState({ data: [], levelsData: [] });
  const { data } = state;
  const getRestOfTotals = () => {
    let total = {};
    typesData.forEach((e, i) => {
      total[e.ID_TIPO_PNF] = {
        ID_TIPO_PNF: e.ID_TIPO_PNF,
        MONTO_BASE_PNF: 0,
        NOMBRE_TIPO_PNF: e.NOMBRE_TIPO_PNF,
        REAJUSTE_PNF: _.get(pnfData, `REAJUSTE_PNF_TIPO${i + 2}`),
        COMENTARIO_PNF: _.get(pnfData, `COMENTARIO_PNF_TIPO${i + 2}`),
        ...(i > 0 ? { PLUS: true } : {}),
      };

      _.chain(levels)
        .filter((current) => current.PNF_NIVEL === i + 2)
        .forEach((current, index) => {
          total[`${e.ID_TIPO_PNF}.${index + 1}`] = {
            ID_PNF_ENC_NIVEL: current.ID_PNF_ENC_NIVEL,
            ID_TIPO_PNF: current.NOMBRE_PNF_NIVEL,
            NOMBRE_TIPO_PNF: current.NOMBRE_PNF_NIVEL,
            MONTO_BASE_PNF: current.MONTO_BASE_PNF,
            REAJUSTE_PNF: current.REAJUSTE_PNF,
            COMENTARIO_PNF: current.COMENTARIO_PNF,
            PNF_NIVEL: current.PNF_NIVEL,
            PNF_ORDEN: current.PNF_ORDEN,
          };
        })
        .value();
    });
    total = Object.keys(itemsMap).reduce((acc, key) => {
      const { ID_TIPO_PNF, CANT_TOTAL, PRECIO_ITEM_VENTA } = itemsMap[key];
      const result = { ...acc };

      if (ID_TIPO_PNF !== 0) {
        result[ID_TIPO_PNF] = {
          ...result[ID_TIPO_PNF],
          MONTO_BASE_PNF: result[ID_TIPO_PNF].MONTO_BASE_PNF + CANT_TOTAL * PRECIO_ITEM_VENTA,
        };
      }
      return result;
    }, total);
    const result = Object.keys(total)
      .sort((a, b) => a - b)
      .map((key) => total[key]);
    return result;
  };

  const save = (values) => {
    if (values[0].ID_PNF_ENC_NIVEL >= 0) {
      const current = _.get(values, '[0]');

      const index = data.findIndex(
        (e) =>
          (current.ID_PNF_ENC_NIVEL > 0 && e.ID_PNF_ENC_NIVEL === current.ID_PNF_ENC_NIVEL) ||
          (current.ID_PNF_ENC_NIVEL === 0 && e.ID === current.ID)
      );

      setState(update(state, { data: { [index]: { $merge: current } } }));
    } else {
      const { COMENTARIO_PNF, ID_TIPO_PNF, REAJUSTE_PNF } = values[0];
      const index = data.findIndex((e) => e.ID_TIPO_PNF === ID_TIPO_PNF);

      const updates = { COMENTARIO_PNF, REAJUSTE_PNF };
      setState(update(state, { data: { [index]: { $merge: updates } } }));
    }

    handleSave(values);
  };

  useEffect(() => {
    if (!_.get(paymentState, `list[${selectedWork}]`, null) && !isFetchingPaymentState) {
      getPaymentStates(selectedWork);
    }
    if (
      !_.isEmpty(itemsMap) &&
      typesData &&
      pnfData &&
      _.get(paymentState, `list[${selectedWork}]`, null) &&
      !isFetchingPaymentState
    ) {
      const date = moment(pnfData.FECHA_AVA, 'DD-MM-YYYY');
      let mostRecentInvoicedDate = null;

      const paymentStateList = _.get(paymentState, `list[${selectedWork}]`, []);
      paymentStateList.forEach((e) => {
        if (
          e.NOMBRE_ESTADO_EP === 'FACTURADO' &&
          (!mostRecentInvoicedDate ||
            moment(e.FECHA_EP, 'DD-MM-YYYY').diff(date) <
              moment(e.FECHA_EP, 'DD-MM-YYYY').diff(moment(mostRecentInvoicedDate), 'DD-MM-YYYY'))
        ) {
          mostRecentInvoicedDate = e.FECHA_EP;
        }
      });

      const children = _.get(pnfData, 'EP_NO_FACTURADOS', []).map((e) => ({
        NOMBRE_TIPO_PNF: `EP N° ${e.NUMERO_EP}`,
        MONTO_BASE_PNF: e.MONTO_PERIODO,
        REAJUSTE_PNF: e.REAJUSTE_EP,
        COMENTARIO_PNF: e.NOMBRE_ESTADO_EP,
        ID_TIPO_PNF: `EP-${e.NUMERO_EP}`,
      }));

      const newData = [
        {
          NOMBRE_TIPO_PNF: '1- Estados de Pago No Facturados',
          MONTO_BASE_PNF: _.get(pnfData, 'MONTO_TIPO1'),
          REAJUSTE_PNF: children.reduce((acc, { REAJUSTE_PNF }) => {
            return acc + REAJUSTE_PNF;
          }, 0),
          COMENTARIO_PNF: _.get(pnfData, 'COMENTARIO_PNF_TIPO1'),
          ID_TIPO_PNF: 1,
          ...(children && children.length ? { children } : {}),
        },
        ...getRestOfTotals(),
        { DESCRIPTION: 'total_row' },
      ];
      setState(
        update(state, {
          data: {
            $set: newData,
          },
          levelsData: {
            $set: levels,
          },
        })
      );
    }
  }, [typesData, itemsMap, paymentState, pnfData, canEdit]);

  const addNewSubLevel = (record) => {
    const newRecordIndex = data.findIndex((e) => e.ID_TIPO_PNF === record.ID_TIPO_PNF + 1);
    const newIndex = data.filter((e) => e.ID_TIPO_PNF === record.ID_TIPO_PNF).length;
    const newRegister = {
      ID_TIPO_PNF: record.ID_TIPO_PNF,
      MONTO_BASE_PNF: 0,
      NOMBRE_TIPO_PNF: `${record.ID_TIPO_PNF}.${newIndex}`,
      REAJUSTE_PNF: 0,
      COMENTARIO_PNF: '',
      ID_PNF_ENC_NIVEL: 0,
      ID: uuidv4(),
      PNF_ORDEN: newIndex + 1,
      PNF_NIVEL: record.ID_TIPO_PNF,
    };

    setState(
      update(state, {
        data: {
          $splice: [[newRecordIndex, 0, newRegister]],
        },
      })
    );

    handleSave([newRegister]);
  };

  const tableColumns = [
    <Column resizable width={50} verticalAlign="middle" align="left" fixed>
      <HeaderCell />
      <Cell dataKey="ACTION">
        {(record) => {
          if (!record.PLUS || !canEdit) return null;
          return <i className="fa fa-plus add-sub-level " onClick={() => addNewSubLevel(record)} />;
        }}
      </Cell>
    </Column>,
    <Column resizable treeCol width={400} verticalAlign="middle" align="left" fixed>
      <HeaderCell>Tipo</HeaderCell>
      <EditableCell
        dataIndex="NOMBRE_TIPO_PNF"
        includeRecordFields={[
          'ID_TIPO_PNF',
          'REAJUSTE_PNF',
          'ID_PNF_ENC_NIVEL',
          'ID_TIPO_PNF',
          'PNF_NIVEL',
          'PNF_ORDEN',
          'MONTO_BASE_PNF',
          'REAJUSTE_PNF',
          'COMENTARIO_PNF',
          'ID',
        ]}
        editable={canEdit}
        handleSave={save}
        type="string"
        canShow={(record) => !record.DESCRIPTION}
        conditionToEditable={(record) => canEdit && record.ID_PNF_ENC_NIVEL >= 0}
        className={() => canEdit && 'editable name-type-pnf'}
      />
    </Column>,
    <Column resizable width={150} verticalAlign="middle" align="center" fixed>
      <HeaderCell>Monto Base</HeaderCell>
      <EditableCell
        dataIndex="MONTO_BASE_PNF"
        includeRecordFields={[
          'ID_TIPO_PNF',
          'ID_PNF_ENC_NIVEL',
          'ID_TIPO_PNF',
          'PNF_NIVEL',
          'PNF_ORDEN',
          'NOMBRE_TIPO_PNF',
          'REAJUSTE_PNF',
          'ID',
          'COMENTARIO_PNF',
        ]}
        editable={canEdit}
        unit="$"
        onlyInteger
        calculateValue={(record) => {
          if (record.DESCRIPTION === 'total_row') {
            const total = data.reduce((acc, current) => {
              return acc + (current.MONTO_BASE_PNF ? current.MONTO_BASE_PNF : 0);
            }, 0);
            return total;
          }
          return record.MONTO_BASE_PNF;
        }}
        additionalInputFields={() => {
          return {
            type: 'number',
            min: 0,
          };
        }}
        handleSave={save}
        type="number"
        conditionToEditable={(record) => canEdit && record.ID_PNF_ENC_NIVEL >= 0}
        className={() => canEdit && 'editable'}
      />
    </Column>,
    <Column resizable width={100} verticalAlign="middle" align="center" fixed>
      <HeaderCell>Reajuste</HeaderCell>
      <EditableCell
        dataIndex="REAJUSTE_PNF"
        includeRecordFields={[
          'ID_TIPO_PNF',
          'ID_PNF_ENC_NIVEL',
          'ID_TIPO_PNF',
          'PNF_NIVEL',
          'PNF_ORDEN',
          'NOMBRE_TIPO_PNF',
          'MONTO_BASE_PNF',
          'ID',
          'COMENTARIO_PNF',
        ]}
        editable={canEdit}
        unit="$"
        decimalsIndex={(record) => {
          if (record.ID_NAT === 3) {
            return 1;
          }
          if (record.ID_NAT === 4) {
            return 3;
          }
          return null;
        }}
        calculateValue={(record) => {
          if (record.DESCRIPTION === 'total_row') {
            const total = data.reduce((acc, current) => {
              return acc + (current.REAJUSTE_PNF ? current.REAJUSTE_PNF : 0);
            }, 0);
            return total;
          }
          return record.REAJUSTE_PNF;
        }}
        additionalInputFields={() => {
          return {
            type: 'number',
            min: 0,
          };
        }}
        handleSave={save}
        type="number"
        conditionToEditable={(record) =>
          canEdit && [2, 3, 4].some((e) => String(e)[0] === String(record.ID_TIPO_PNF)[0])
        }
        className={() => canEdit && 'editable'}
      />
    </Column>,
    <Column resizable width={170} verticalAlign="middle" align="center" fixed>
      <HeaderCell>Total</HeaderCell>
      <Cell>
        {(record) => {
          let total = record.MONTO_BASE_PNF + record.REAJUSTE_PNF;

          if (record.DESCRIPTION === 'total_row') {
            total = data.reduce((acc, current) => {
              const sum = (current.MONTO_BASE_PNF || 0) + (current.REAJUSTE_PNF || 0);
              return acc + sum;
            }, 0);
            return getFormattedNumber(total, 0, true, '$');
          }

          return getFormattedNumber(total, 0, true, '$');
        }}
      </Cell>
    </Column>,
    <Column resizable width={200} verticalAlign="middle" align="center" fixed>
      <HeaderCell>Comentario</HeaderCell>
      <EditableCell
        dataIndex="COMENTARIO_PNF"
        includeRecordFields={[
          'ID_TIPO_PNF',
          'REAJUSTE_PNF',
          'ID_PNF_ENC_NIVEL',
          'ID_TIPO_PNF',
          'PNF_NIVEL',
          'PNF_ORDEN',
          'NOMBRE_TIPO_PNF',
          'MONTO_BASE_PNF',
          'ID',
        ]}
        editable={canEdit}
        handleSave={save}
        type="string"
        canShow={(record) => !record.DESCRIPTION}
        conditionToEditable={(record) => canEdit && !record.NOMBRE_TIPO_PNF.includes('EP N° ')}
        className={() => canEdit && 'editable'}
      />
    </Column>,
  ];

  const addRowsToWorksheet = (worksheet, tree) => {
    worksheet.properties.defaultColWidth = 100;
    tree.forEach((current) => {
      worksheet.addRow(
        current.DESCRIPTION !== 'total_row'
          ? {
              TIPO: `${current._parent ? '\t' : ''}${current.NOMBRE_TIPO_PNF}`,
              MONTO_BASE: current.MONTO_BASE_PNF,
              REAJUSTE: current.REAJUSTE_PNF,
              TOTAL: current.MONTO_BASE_PNF + current.REAJUSTE_PNF,
              COMENTARIO: current.COMENTARIO_PNF,
            }
          : tree.reduce(
              (acc, curr) => {
                const currentReadjustment = curr.REAJUSTE_PNF || 0;
                const currentBaseAmount = curr.MONTO_BASE_PNF || 0;
                return {
                  MONTO_BASE: acc.MONTO_BASE + currentBaseAmount,
                  REAJUSTE: acc.REAJUSTE + currentReadjustment,
                  TOTAL: acc.TOTAL + currentBaseAmount + currentReadjustment,
                };
              },
              {
                MONTO_BASE: 0,
                REAJUSTE: 0,
                TOTAL: 0,
              }
            )
      );
      let color = '00CCCCCC';
      if (current._parent) {
        color = '00FFFFFF';
      }

      worksheet.lastRow.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: color },
      };

      worksheet.lastRow.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };

      if (current.children && current.children.length) {
        addRowsToWorksheet(worksheet, current.children);
      }
    });
  };

  const exportToCSV = () => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'Conpax';
    workbook.lastModifiedBy = 'Conpax';
    const currentDate = new Date();
    workbook.created = currentDate;
    workbook.modified = currentDate;

    const worksheet = workbook.addWorksheet('sheet', {
      pageSetup: { paperSize: 9, orientation: 'landscape' },
    });

    tableColumns.forEach((column, columnIndex) => {
      const columnWidth = column.width || 10; // Ancho predeterminado si no se especifica
      worksheet.getColumn(columnIndex + 1).width = columnWidth;
    });
    worksheet.columns = [
      { header: 'Tipo', key: 'TIPO', width: 20 },
      { header: 'Monto Base', key: 'MONTO_BASE', width: 40 },
      { header: 'Reajuste', key: 'REAJUSTE', width: 25 },
      { header: 'Total', key: 'TOTAL', width: 25 },
      { header: 'Comentario', key: 'COMENTARIO', width: 25 },
    ];

    addRowsToWorksheet(worksheet, data, typesData);
    workbook.xlsx.writeBuffer().then(function (buffer) {
      FileSaver.saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'pnf.xlsx');
      pdfDownloaded();
    });
  };

  useEffect(() => {
    if (callDownload) {
      exportToCSV();
    }
  }, [callDownload]);

  return (
    <div className="levels">
      <Table
        className="common-table"
        height={window.innerHeight - 300}
        hover={false}
        rowKey="NOMBRE_TIPO_PNF"
        wordWrap
        bordered
        headerHeight={75}
        isTree
        loading={isFetching || savingEdition || isFetchingPaymentState}
        rowHeight={38}
        virtualized
        shouldUpdateScroll={false}
        locale={{
          emptyMessage: 'No hay datos para mostrar',
          loading: 'Cargando...',
        }}
        data={data || []}
        rowClassName={(record) => {
          if (record && record.DESCRIPTION === 'total_row') return 'total';
          return '';
        }}
        renderTreeToggle={(_icon, _row, expanded) => {
          if (expanded) {
            return <CaretUpOutlined />;
          }
          return <CaretDownOutlined />;
        }}
      >
        ¨{tableColumns}
      </Table>
    </div>
  );
};

Levels.propTypes = {
  handleSave: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  typesData: PropTypes.array.isRequired,
  itemsMap: PropTypes.object.isRequired,
  pnfData: PropTypes.object.isRequired,
  selectedWork: PropTypes.number.isRequired,
  savingEdition: PropTypes.bool.isRequired,
  getPaymentStates: PropTypes.func.isRequired,
  paymentState: PropTypes.object.isRequired,
  levels: PropTypes.array.isRequired,
  callDownload: PropTypes.bool.isRequired,
  pdfDownloaded: PropTypes.func.isRequired,
};
export default connect(
  ({ works, paymentState }) => ({
    selectedWork: works.selectedWork,
    paymentState,
  }),
  (dispatch) => ({
    getPaymentStates: bindActionCreators(getPaymentStatesAction, dispatch),
  })
)(Levels);
