import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import InputWithTopLabel from '../../components/InputWithTopLabel';
import { setSummaryCas as setSummaryCasAction } from '../../actions/SummaryCas';

const ModalSummary = ({ dataSummary, selectedWork, setSummaryCas, casDate, activeOption }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [inputValue, setInputValue] = useState({
    currentUtility: '',
    forecastUtility: '',
    currentProforma: '',
    forecastProform: '',
    readjustment: '',
    utility: '',
    percentage: '',
    anotherSales: '',
    moBonus: '',
    anotherSalesAccumulated: '',
    moBonusAccumulated: '',
  });

  const {
    currentUtility,
    forecastUtility,
    currentProforma,
    forecastProform,
    readjustment,
    utility,
    percentage,
    anotherSales,
    moBonus,
    anotherSalesAccumulated,
    moBonusAccumulated,
  } = inputValue;

  useEffect(() => {
    if (dataSummary && dataSummary[selectedWork]) {
      const {
        PORC_AVANCE_FISICO,
        UTILIDAD_VIGENTE,
        UTILIDAD_FORECAST,
        PROFORMA_VIGENTE,
        PROFORMA_FORECAST,
        REAJUSTE_ACUMULADO,
        UTILIDAD_ACUMULADA,
        OTRAS_VENTAS_ACUMULADO,
        BONOS_MO_ACUMULADO,
        OTRAS_VENTAS,
        BONOS_MO,
      } = dataSummary[selectedWork];
      setInputValue({
        currentUtility: UTILIDAD_VIGENTE,
        forecastUtility: UTILIDAD_FORECAST,
        currentProforma: PROFORMA_VIGENTE,
        forecastProform: PROFORMA_FORECAST,
        percentage: PORC_AVANCE_FISICO,
        readjustment: REAJUSTE_ACUMULADO,
        utility: UTILIDAD_ACUMULADA,
        anotherSales: OTRAS_VENTAS,
        moBonus: BONOS_MO,
        anotherSalesAccumulated: OTRAS_VENTAS_ACUMULADO,
        moBonusAccumulated: BONOS_MO_ACUMULADO,
      });
    }
  }, [dataSummary]);

  const handleInputChange = (value, key) => {
    setInputValue({ ...inputValue, [key]: Number(value) });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setSummaryCas(
      casDate.format('DD/MM/YYYY'),
      activeOption + 1,
      currentUtility,
      forecastUtility,
      currentProforma,
      forecastProform,
      percentage,
      readjustment,
      utility,
      anotherSales,
      moBonus,
      anotherSalesAccumulated,
      moBonusAccumulated
    );
    setIsModalVisible(false);
    setInputValue(inputValue);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    const {
      PORC_AVANCE_FISICO,
      UTILIDAD_VIGENTE,
      UTILIDAD_FORECAST,
      PROFORMA_VIGENTE,
      PROFORMA_FORECAST,
      REAJUSTE_ACUMULADO,
      UTILIDAD_ACUMULADA,
      OTRAS_VENTAS_ACUMULADO,
      BONOS_MO_ACUMULADO,
      OTRAS_VENTAS,
      BONOS_MO,
    } = dataSummary[selectedWork];
    setInputValue({
      currentUtility: UTILIDAD_VIGENTE,
      forecastUtility: UTILIDAD_FORECAST,
      currentProforma: PROFORMA_VIGENTE,
      forecastProform: PROFORMA_FORECAST,
      percentage: PORC_AVANCE_FISICO,
      readjustment: REAJUSTE_ACUMULADO,
      utility: UTILIDAD_ACUMULADA,
      anotherSales: OTRAS_VENTAS,
      moBonus: BONOS_MO,
      anotherSalesAccumulated: OTRAS_VENTAS_ACUMULADO,
      moBonusAccumulated: BONOS_MO_ACUMULADO,
    });
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        <i className="fas fa-cog" />
      </Button>
      <Modal
        title={
          dataSummary[selectedWork]
            ? `Editar datos del proyecto ${dataSummary[selectedWork].OBRA}`
            : null
        }
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <div>
          <div>
            <InputWithTopLabel
              withTimeOut={false}
              keepShowingLabel
              type="number"
              label="Utilidad Vigente"
              name="currentUtility"
              value={currentUtility}
              onChange={(value) => handleInputChange(value, 'currentUtility')}
            />
          </div>
          <div className="mt-4">
            <InputWithTopLabel
              withTimeOut={false}
              keepShowingLabel
              type="number"
              label="Utilidad Forecast"
              name="forecastUtility"
              value={forecastUtility}
              onChange={(value) => handleInputChange(value, 'forecastUtility')}
            />
          </div>
          <div className="mt-4">
            <InputWithTopLabel
              withTimeOut={false}
              keepShowingLabel
              type="number"
              label="Proforma Vigente"
              name="currentProforma"
              value={currentProforma}
              onChange={(value) => handleInputChange(value, 'currentProforma')}
            />
          </div>
          <div className="mt-4">
            <InputWithTopLabel
              withTimeOut={false}
              keepShowingLabel
              type="number"
              label="Proforma Forecast"
              name="forecastProform"
              value={forecastProform}
              onChange={(value) => handleInputChange(value, 'forecastProform')}
            />
          </div>
          <div className="mt-4">
            <InputWithTopLabel
              withTimeOut={false}
              keepShowingLabel
              type="number"
              label="Otras ventas"
              value={anotherSales}
              min={0}
              onChange={(value) => handleInputChange(value, 'anotherSales')}
            />
          </div>
          <div className="mt-4">
            <InputWithTopLabel
              withTimeOut={false}
              keepShowingLabel
              type="number"
              label="Bonos MO"
              min={0}
              value={moBonus}
              onChange={(value) => handleInputChange(value, 'moBonus')}
            />
          </div>
          <div className="title-report">
            <span>Parametros Informe Cas</span> <br /> Fecha:&nbsp;{casDate.format('DD/MM/YYYY')}
          </div>
          <div className="mt-4">
            <InputWithTopLabel
              withTimeOut={false}
              keepShowingLabel
              type="number"
              label="Reajuste Acumulado"
              value={readjustment}
              onChange={(value) => handleInputChange(value, 'readjustment')}
            />
          </div>
          <div className="mt-4">
            <InputWithTopLabel
              withTimeOut={false}
              keepShowingLabel
              type="number"
              label="Utilidad Acumulada"
              value={utility}
              onChange={(value) => handleInputChange(value, 'utility')}
            />
          </div>
          <div className="mt-4">
            <InputWithTopLabel
              withTimeOut={false}
              keepShowingLabel
              type="number"
              label="% de Avance Físico"
              min={0}
              max={100}
              value={percentage}
              onChange={(value) => {
                if (value && (Number(value) > 100 || Number(value) < 0)) {
                  value = percentage;
                }
                handleInputChange(value, 'percentage');
              }}
            />
          </div>
          <div className="mt-4">
            <InputWithTopLabel
              withTimeOut={false}
              keepShowingLabel
              type="number"
              label="Otras Ventas Acumulado"
              value={anotherSalesAccumulated}
              min={0}
              onChange={(value) => handleInputChange(value, 'anotherSalesAccumulated')}
            />
          </div>
          <div className="mt-4">
            <InputWithTopLabel
              withTimeOut={false}
              keepShowingLabel
              type="number"
              label="Bonos MO Acumulado"
              value={moBonusAccumulated}
              min={0}
              onChange={(value) => handleInputChange(value, 'moBonusAccumulated')}
            />
          </div>
          <div className="button-modal">
            <Button onClick={handleCancel} className="mr-2">
              Cancelar
            </Button>
            <Button type="primary" onClick={handleOk}>
              Aceptar Cambios
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

ModalSummary.propTypes = {
  selectedWork: PropTypes.number.isRequired,
  dataSummary: PropTypes.object.isRequired,
  setSummaryCas: PropTypes.func.isRequired,
  casDate: PropTypes.func.isRequired,
  activeOption: PropTypes.func.isRequired,
};

export default connect(
  ({ works, summaryCas }) => ({
    selectedWork: works.selectedWork,
    summaryCas,
  }),
  (dispatch) => ({
    setSummaryCas: bindActionCreators(setSummaryCasAction, dispatch),
  })
)(ModalSummary);
