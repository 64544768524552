import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const userLang = navigator.language || navigator.userLanguage;
const defaultLocale = userLang.substr(0, 2);

i18n.use(initReactI18next).init({
  fallbackLng: defaultLocale,
  interpolaration: {
    escapeValue: false,
  },
  lng: 'es',
  resources: {
    en: {
      translation: {
        advanceControl: {
          advancedFilters: {
            buttonTitle: 'Aditional Filters',
            controlledProgrammingFilters: {
              filter1: 'Overdue Activities',
              filter2: 'Consider Items with % of programed advance between',
              filter3: 'Consider Items with % of real advance between',
              filter4: 'Consider Items with projected advance between',
              title: 'Filters Programming Controlled',
            },
            generalFilters: {
              filter1: 'Only tasks without budget links',
              filter2: 'Hidde tasks at 100%',
              filter3: 'Hide Milestones',
              filter4: 'Only tasks with diff betwee Foco Quality and Foco Advance',
              filter5: 'Only activities with resctricion',
              filter6: 'Only activities without link with budget',
              filter7: 'Only Activities with expired restriction',
              title: 'General Filters',
            },
            lineBaseFilters: {
              filter1: 'Critical Route Base Line',
              filter2: 'Slack between',
              title: 'Base Line Filters',
            },
            title: 'Advanced Filters',
          },
          advanceDiff1: 'Advance',
          advanceDiff2: 'Diff',
          advancedQuantity1: 'Advanced',
          advancedQuantity2: 'Quantity',
          deviationAccordingToBaseLine1: 'Deviation according',
          deviationAccordingToBaseLine2: ' to Base Line',
          baseLineProgramming: 'Base Line Programming',
          cantCloseDefaultAction: "Can't close and program that is not a project default Program",
          cantEditDefaultAction: "Can't edit program that is not a project default Program",
          cantExecuteAction: "You can't execute that action in this moment",
          dontHavePermissionsToExecuteAction: "You don't have permission to execute this action",
          controlAt: 'Control At',
          controlledProgramming: 'Controlled Programming',
          edt: 'EDT',
          elapsedDuration1: 'Elapsed',
          elapsedDuration2: 'Duration',
          loadResults: 'Load Results',
          predecessor: 'Predecessor',
          realAccordingToQuality: 'Real According To Quality',
          realEnd: 'End Real',
          realStart1: 'Start',
          realStart2: 'Real',
          remainDuration1: 'Remain',
          remainDuration2: 'Duration',
          schemaLevel: 'Schema Level',
          selectProgram: 'Select Program',
          selectWork: 'Select Work',
          showDetail1: 'Show',
          showDetail2: 'Detail',
          startBalance1: 'Start',
          startBalance2: 'Balance',
          successor: 'Successor',
          taskName: 'Task Name',
          totalDuration: 'Total Duration',
          totalSlack1: 'Total',
          totalSlack2: 'Slack',
          totalSlackAccordingProjected1: 'Total Slack',
          totalSlackAccordingProjected2: 'According Projected',
          valorization: 'Valorization',
          verticalAdvanceLinks1: 'Vertical',
          verticalAdvanceLinks2: 'Advance Links',
          restrictionType1: 'Restriction',
          restrictionType2: 'Type',
          dateRestricion1: 'Restriction',
          dateRestricion2: 'Date',
          activityName: 'Activity Name',
          viewOptions: {
            filter0: 'Show Base Quantity',
            filter1: 'Show advanced Quantity',
            filter2: 'Show Total Slack',
            filter3: 'Show UM',
            filter4: 'Show Elapsed Duration',
            filter5: 'Show Remain Duration',
            filter6: 'Show Total Slack Based on Projected',
            filter7: 'Deviation with base line',
            filter8: 'Show Calendar',
            filter9: 'Show Vertical advance links',
            filter10: 'Show Subtasks',
            filter11: 'Show Details',
            filter12: 'Show Predecessor',
            filter13: 'Show Successor',
            filter14: 'Show advance based on quality cards',
            filter15: 'Show Quantity analysis',
          },
          restrictionTypes: {
            0: 'As soon as possible',
            1: 'Must start on',
            2: 'Must end on',
            3: 'Do not start before',
            4: 'Do not finish before',
          },
          iconsInfo: {
            0: 'Activity part of the critical path baseline',
            1: 'Activity part of the critical path of the controlled program',
            2: 'Program milestone activity',
            3: 'Activity with current restriction',
            4: 'Activity with expired restriction in force',
          },
          legends: {
            title: 'Legend',
            textColor: 'text color',
            cellColor: 'cell color',
            icon: 'icon',
            items: {
              0: 'Chapter or Folder',
              1: 'Editable cell',
              2: 'Activity with restriction expired',
              3: 'Activity with current restriction',
              4: 'Activity part of the critical baseline route',
              5: 'Activity part of the critical path of the controlled program',
              6: 'Program milestone activity',
              7: 'Impacted Activity',
              8: 'Images Evidence',
            },
          },
          qualityCard: {
            title: 'Quality Sheet',
            sheetState: 'Sheet state',
          },
          restVencInfo: {
            title: 'Restriction Data',
            estimatedDate: 'Estimated Restriction Release Date',
          },
          valorizationInfo: {
            title: 'Valorization Info',
          },
          rowDetail: {
            predecessorActivity: 'Predecessor Activity',
            successorActivity: 'Successor Activity',
            delayDays: 'Delay (days)',
            delayHours: 'Delay (hours)',
          },
        },
        common: {
          address: 'Address',
          admin: 'Admin',
          view: 'View',
          advance: 'Advance',
          beginning: 'Beginning',
          finished: 'Finished',
          apply: 'Apply',
          avancedFilters: 'Advanced Filters',
          baseQuantity: 'Base Quantity',
          calendar: 'Calendar',
          cancel: 'Cancel',
          chapter: 'Chapter',
          cleanFilters: 'Clean Filters',
          hideFilters: 'Hide Filters',
          close: 'Close',
          commune: 'Commune',
          company: 'Company',
          dataEmpty: 'Data Empty',
          deviation: 'Deviation',
          duration: 'Duration',
          edit: 'Edit',
          end: 'End',
          export: 'Export',
          filters: 'Filters',
          id: 'Id',
          information: 'Information',
          language: 'Language',
          loading: 'Loading',
          manager: 'Manager',
          moreFilters: 'More Filters',
          open: 'Open',
          program: 'Program',
          project: 'Project',
          qty: 'Qty',
          quantity: 'Quantity',
          real: 'Real',
          save: 'Save',
          search: 'Search',
          showAll: 'Show All',
          start: 'Start',
          state: 'State',
          subtasks: 'Subtasks',
          um: 'UM',
          type: 'Type',
          meaning: 'Meaning',
          work: 'Work',
          sheet: 'Sheet',
          protocol: 'Prococol',
          enclosure: 'Enclosure',
          requestError: 'An error occurred while fetching data',
          itemCode: 'Item Code',
          itemName: 'Item Name',
          detail: 'Detalle',
          sheets: 'sheets',
          activity: 'Activity',
          line: 'Line',
          linkType: 'Link Type',
        },
        language: 'Language',
        project: {
          admin: 'Admin',
          advance: 'Advance',
          contractAmount: 'Contract Amount',
          contractEnd: 'Contract End',
          contractStart: 'Contract Start',
          contractType: 'Contract Type',
          erpCode: 'ERP Code',
          manager: 'Manager',
          name: 'Project Name',
          new: 'New Project',
          retention: 'Retention',
        },
        title: 'Welcome',
      },
    },
    es: {
      translation: {
        advanceControl: {
          advancedFilters: {
            buttonTitle: 'Filtros Adicionales',
            controlledProgrammingFilters: {
              filter1: 'Actividades Atrasadas',
              filter2: 'Considerar partidas con % de avance prog. entre',
              filter3: 'Considerar partidas con % de avance real entre',
              filter4: 'Considerar partidas con avance proy. entre',
              title: 'Filtros Programa Controlado',
            },
            generalFilters: {
              filter1: 'Solor Act. sin vinculo con ppto.',
              filter2: 'Ocultar Actividades al 100%',
              filter3: 'Ocultar hitos',
              filter4: 'Solo Act. con diferencia entre FocoCalidad y FocoAvance',
              filter5: 'Solo Actividades con Restricción',
              filter6: 'Solo Act. sin vinculo con ppto.',
              filter7: 'Solo Actividades con Restricción Vencida',
              title: 'Filtros Generales',
            },
            lineBaseFilters: {
              filter1: 'Ruta Crítica Línea Base',
              filter2: 'Holgura entre',
              title: 'Filtros Linea Base',
            },
            title: 'Filtros Avanzados',
          },
          advanceDiff1: 'Dif.',
          advanceDiff2: 'Avance',
          advancedQuantity1: 'Cantidad',
          advancedQuantity2: 'Avanzada',
          deviationAccordingToBaseLine1: 'Desviación con ',
          deviationAccordingToBaseLine2: 'respecto a la linea base',
          baseLineProgramming: 'Programación Línea Base',
          cantCloseDefaultAction:
            'No se puede cerrar un programa que no es el predeterminado del proyecto.',
          cantEditDefaultAction:
            'No se puede editar un programa que no es el predeterminado del proyecto.',
          cantExecuteAction: 'No puedes ejecutar esta acción en este momento',
          dontHavePermissionsToExecuteAction: 'No tienes permisos para ejecutar esta acción',
          controlAt: 'Control al',
          controlledProgramming: 'Programación Controlada',
          edt: 'EDT',
          elapsedDuration1: 'Duración',
          elapsedDuration2: 'transcurrida',
          loadResults: 'Cargar resultados',
          predecessor: 'Predecesor',
          realAccordingToQuality: 'Real según calidad',
          realEnd: 'Fin Real',
          realStart1: 'Comienzo',
          realStart2: 'Real',
          remainDuration1: 'Duración',
          remainDuration2: 'Restante',
          schemaLevel: 'Nivel de Esquema',
          selectProgram: 'Seleccionar Programa',
          selectWork: 'Seleccionar Obra',
          showDetail1: 'Mostrar',
          showDetail2: 'Detalle',
          startBalance1: 'Comienzo',
          startBalance2: 'Saldo',
          successor: 'Sucesor',
          taskName: 'Nombre Tarea',
          totalDuration: 'Duración Total',
          totalSlack1: 'Holgura',
          totalSlack2: 'Total',
          restrictionType1: 'Tipo',
          restrictionType2: 'Restricción',
          dateRestricion1: 'Fecha',
          dateRestricion2: 'Restricción',
          totalSlackAccordingProjected1: 'Holgura Total',
          totalSlackAccordingProjected2: 'Según proy.',
          valorization: 'Valorización',
          verticalAdvanceLinks1: 'Vínculos Vertical',
          verticalAdvanceLinks2: 'de Avance',
          activityName: 'Nombre de la Actividad',
          viewOptions: {
            filter0: 'Mostrar Cantidad Base',
            filter1: 'Mostrar Cantidad Avanzada',
            filter2: 'Mostrar Holgura Total',
            filter3: 'Mostrar UM',
            filter4: 'Mostrar Duración Transcurrida',
            filter5: 'Mostrar Duración Restante',
            filter6: 'Mostrar Holgura Total según Proy.',
            filter7: 'Desviación con respecto a línea base',
            filter8: 'Mostrar Calendario',
            filter9: 'Mostrar Vinculos Vertical de Avance',
            filter10: 'Mostrar Subtareas',
            filter11: 'Mostrar Detalles',
            filter12: 'Mostrar Predecesoras',
            filter13: 'Mostrar Sucesoras',
            filter14: 'Mostrar Avance Según Fichas de Calidad',
            filter15: 'Mostrar Analisis de Cantidades',
          },
          restrictionTypes: {
            0: 'Lo antes posible',
            1: 'Debe comenzar el',
            2: 'Debe finalizar el',
            3: 'No comenzar antes del',
            4: 'No finalizar antes del',
          },
          iconsInfo: {
            0: 'Actividad parte de la ruta critica linea base',
            1: 'Actividad parte de la ruta critica del programa controlado',
            2: 'Actividad hito del programa',
            3: 'Actividad con restricción vigente',
            4: 'Actividad con restricción vencida vigente',
          },
          legends: {
            title: 'Leyenda',
            textColor: 'color de texto',
            cellColor: 'color de Celda',
            icon: 'icono',
            items: {
              0: 'Capítulo o Carpeta',
              1: 'Celda editable',
              2: 'Actividad con restricción vencida',
              3: 'Actividad con restricción vigente',
              4: 'Actividad parte de la ruta critica linea base',
              5: 'Actividad parte de la ruta critica del programa controlado',
              6: 'Actividad hito del programa',
              7: 'Actividad Impactada',
              8: 'Evidencia Fotográfica',
            },
          },
          qualityCard: {
            title: 'Ficha de Calidad',
            sheetState: 'Estado Ficha',
          },
          restVencInfo: {
            title: 'Datos de Restricción',
            estimatedDate: 'Fecha Estimada de Liberación de la Restricción',
          },
          valorizationInfo: {
            title: 'Datos de Valorización',
          },
          rowDetail: {
            predecessorActivity: 'Actividad Predecesora',
            successorActivity: 'Actividad Sucesora',
            delayDays: 'Retraso (días)',
            delayHours: 'Retraso (horas)',
          },
        },
        chapterFilter: {
          title: 'Filtro Capítulos',
        },
        common: {
          address: 'Dirección',
          advance: 'Avance',
          view: 'Vista',
          apply: 'Aplicar',
          avancedFilters: 'Filtros Avanzados',
          baseQuantity: 'Cantidad Base',
          calendar: 'Calendario',
          cancel: 'Cancelar',
          chapter: 'Capítulo',
          cleanFilters: 'Limpiar Filtros',
          hideFilters: 'Ocultar Filtros',
          close: 'Cerrar',
          company: 'Empresa',
          dataEmpty: 'No hay datos disponibles',
          deviation: 'Desviación',
          duration: 'Duración',
          edit: 'Editar',
          end: 'Fin',
          export: 'Exportar',
          filters: 'Filtros',
          id: 'Id',
          information: 'Información',
          language: 'Idioma',
          loading: 'loading',
          moreFilters: 'Más Filtros',
          open: 'Abrir',
          program: 'Programa',
          project: 'Proyecto',
          qty: 'Cant.',
          quantity: 'Cantidad',
          real: 'Real',
          save: 'Guardar',
          search: 'Buscar',
          showAll: 'Mostrar Todo',
          start: 'Comienzo',
          beginning: 'Inicio',
          finished: 'Término',
          state: 'Estado',
          subtasks: 'Subtareas',
          um: 'UM',
          type: 'Tipo',
          meaning: 'Significado',
          work: 'Obra',
          sheet: 'Ficha',
          protocol: 'Prococolo',
          enclosure: 'recinto',
          requestError: 'Ocurrio un error al obtener la información',
          itemCode: 'Código de Partida',
          itemName: 'Nombre de la Partida',
          detail: 'Detalle',
          sheets: 'Fichas',
          activity: 'Actividad',
          line: 'Línea',
          linkType: 'Tipo Vínculo',
        },
        language: 'Idioma',
        project: {
          admin: 'Administrador',
          advance: 'Anticipo',
          contractAmount: 'Monto Contrato',
          contractEnd: 'Termino Contractual',
          contractStart: 'Inicio Contractual',
          contractType: 'Tipo Contrato',
          erpCode: 'Código Erp',
          manager: 'Gerente',
          name: 'Nombre Proyecto',
          new: 'Nuevo Proyecto',
          retention: 'Retención',
        },
        title: 'Bienvenido',
      },
    },
  },
});
