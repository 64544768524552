import update from 'immutability-helper';

export const SET_ADVANCE_CONTROL_FIELDS = 'SET_ADVANCE_CONTROL_FIELDS';
export const RESET_ADVANCE_CONTROL_FIELDS = 'RESET_ADVANCE_CONTROL_FIELDS';

const defaultState = [];

const advanceControlEdition = (state = defaultState, action) => {
  switch (action.type) {
    case SET_ADVANCE_CONTROL_FIELDS: {
      const { fieldsToUpdate, record } = action.payload;
      return update(state, {
        $apply: (fields) => {
          let newFields = [...fields];

          fieldsToUpdate.forEach((current) => {
            const currentIndex = newFields.findIndex((e) => e.ID_INS === current.ID_INS);
            const newInfo = {
              Q_AVA: record.Q_AVA,
              ID_TIPO_RESTRICCION: record.ID_TIPO_RESTRICCION,
              PRC_AVA_AUX: record.PRC_AVA_AUX,
              FECHA_REST: record.FECHA_REST,
              COMIENZO_EST: record.COMIENZO_EST,
            };
            if (currentIndex !== -1) {
              newFields = update(newFields, {
                [currentIndex]: {
                  $set: {
                    ...newInfo,
                    ...newFields[currentIndex],
                    ...current,
                  },
                },
              });
            } else {
              newFields = update(newFields, {
                $push: [{ ...newInfo, ...current }],
              });
            }
          });

          return newFields;
        },
      });
    }

    case RESET_ADVANCE_CONTROL_FIELDS: {
      return [];
    }

    default:
      return state;
  }
};

export default advanceControlEdition;
