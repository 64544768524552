import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Form, Input, Carousel, Switch } from 'antd';
import { LeftOutlined, LoadingOutlined, RightOutlined } from '@ant-design/icons';
import Map from '../Map';
import './index.scss';

const ItemDetails = ({ getItemEvidence, itemDetails, onClose, setAdvanceInItem, title }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [latAndLng, setLatAndLng] = useState({ lat: 0, lng: 0 });
  const [currenEvidence, setCurrenEvidence] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showEvidence, setShowEvidence] = useState(false);

  const [form] = Form.useForm();
  const carouselRef = useRef(null);

  useEffect(() => {
    if (itemDetails) {
      if (itemDetails.EVIDENCIA) {
        setLoading(false);

        if (itemDetails.EVIDENCIA.length === 0) {
          form.setFieldsValue({ evidence: '' });
          form.setFieldsValue({ date: '' });
          form.setFieldsValue({ height: '' });
        } else {
          form.setFieldsValue({ evidence: itemDetails.EVIDENCIA[currenEvidence].NOMBRE_USUARIO });
          form.setFieldsValue({ date: itemDetails.EVIDENCIA[currenEvidence].FECHA });
          form.setFieldsValue({ height: itemDetails.EVIDENCIA[currenEvidence].ALTURA });

          setLatAndLng({
            lat: itemDetails.EVIDENCIA[currenEvidence].LATITUD,
            lng: itemDetails.EVIDENCIA[currenEvidence].LONGITUD,
          });
        }
      }
    } else {
      form.resetFields();
    }
  }, [currenEvidence, itemDetails]);

  const submit = (values) => {
    const { accumulatedAdvance, comment } = values;

    setIsSaving(true);
    setAdvanceInItem(accumulatedAdvance, comment, itemDetails.ID_UCO, itemDetails.ID_INS_EP)
      .then(() => {
        onClose();
        setIsSaving(false);
      })
      .catch(() => {
        setIsSaving(false);
      });
  };

  const changeImage = (direction) => {
    if (direction === 'prev') {
      carouselRef.current.prev();
    } else {
      carouselRef.current.next();
    }
  };

  return (
    <Modal
      width={850}
      visible
      onOk={() => onClose()}
      onCancel={() => onClose()}
      footer={null}
      wrapClassName="modalItemDetails"
      title={title}
    >
      {(!itemDetails || loading) && (
        <div className="spinner">
          <LoadingOutlined />
        </div>
      )}
      {itemDetails && (
        <div className="itemDetails">
          <Form layout="vertical" form={form} onFinish={submit}>
            <div className="formRow">
              <Form.Item
                name="accumulatedAdvance1"
                label="% Acumulado Presentado"
                initialValue={itemDetails.PRC_AVA_ACUM}
                className="percentage-form-item"
              >
                <Input
                  disabled
                  prefix={<div className="prefix-label">%</div>}
                  className="with-prefix"
                />
              </Form.Item>
              <Form.Item
                name="accumulatedAdvance2"
                label="% Aprobado Anterior EP"
                initialValue={itemDetails.PRC_AVA_ACUM_APROB_ANT}
                className="percentage-form-item"
              >
                <Input
                  disabled
                  prefix={<div className="prefix-label">%</div>}
                  className="with-prefix"
                />
              </Form.Item>
              <Form.Item
                name="accumulatedAdvance"
                label="% Acumulado Aprobado"
                initialValue={itemDetails.PRC_AVA_ACUM_APROB}
                className="percentage-form-item editableInput"
                rules={[
                  {
                    required: true,
                    message: `Por favor ingrese un avance entre ${itemDetails.PRC_AVA_ACUM_APROB_ANT} y 100`,
                  },
                  {
                    validator: async (obj, value) => {
                      if (value && !/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/.test(value)) {
                        return Promise.reject(
                          new Error(
                            `Por favor ingrese un avance entre ${itemDetails.PRC_AVA_ACUM_APROB_ANT} y 100`
                          )
                        );
                      }

                      if (
                        value &&
                        (Number(value) < itemDetails.PRC_AVA_ACUM_APROB_ANT || Number(value) > 100)
                      ) {
                        return Promise.reject(
                          new Error(
                            `Por favor ingrese un avance entre ${itemDetails.PRC_AVA_ACUM_APROB_ANT} y 100`
                          )
                        );
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  autoFocus
                  prefix={<div className="prefix-label">%</div>}
                  className="with-prefix"
                />
              </Form.Item>
            </div>
            <div className="formRow">
              <Form.Item
                name="comment"
                label="Comentario"
                initialValue={itemDetails.COMENTARIO_APROB}
                style={{ width: '100%' }}
              >
                <Input.TextArea
                  style={{ backgroundColor: 'rgb(255, 255, 196)', textAlign: 'initial' }}
                  maxLength={500}
                  autoSize={{ minRows: 2, maxRows: 2 }}
                />
              </Form.Item>
            </div>
            <div className="switchContainer">
              <Switch
                onChange={(e) => {
                  if (e && !itemDetails.EVIDENCIA) {
                    getItemEvidence();
                    setLoading(true);
                  }

                  setShowEvidence(e);
                }}
              />
              <span>Mostrar Evidencia Fotográfica</span>
            </div>
            {itemDetails.EVIDENCIA && showEvidence && (
              <div className="carouselAndCoordinates">
                <div className="carouselContainer">
                  {itemDetails.EVIDENCIA.length === 0 ? (
                    <div className="withoutEvidence">Sin Evidencia</div>
                  ) : (
                    <>
                      <LeftOutlined className="arrow" onClick={() => changeImage('prev')} />
                      <Carousel ref={carouselRef} afterChange={(e) => setCurrenEvidence(e)}>
                        {itemDetails.EVIDENCIA.map((e) => (
                          <img
                            src={`data:image/jpeg;base64,${e.BASE64}`}
                            alt="image1"
                            width="550px"
                            key={`evidence-${e.id}`}
                          />
                        ))}
                      </Carousel>
                      <RightOutlined
                        className="arrow arrowRight"
                        onClick={() => changeImage('next')}
                      />
                    </>
                  )}
                </div>
                <div className="coordinatesContainer">
                  <div className="title">INFORMACIÓN DE LA EVIDENCIA</div>
                  <Form.Item name="evidence" label="Evidencia Capturada Por:">
                    <Input disabled />
                  </Form.Item>
                  <Form.Item name="date" label="Fecha:">
                    <Input disabled />
                  </Form.Item>
                  <Form.Item name="height" label="Altura:">
                    <Input disabled />
                  </Form.Item>
                  {itemDetails.EVIDENCIA.length !== 0 && (
                    <Map defaultCenter={latAndLng} defaultZoom={15} />
                  )}
                </div>
              </div>
            )}
            <div className="buttons">
              <Form.Item>
                <Button disabled={isSaving} onClick={() => onClose()}>
                  Cancelar
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  className="orangeButton"
                  loading={isSaving}
                  type="primary"
                  htmlType="submit"
                  disabled={isSaving}
                >
                  Aceptar
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      )}
    </Modal>
  );
};

ItemDetails.defaultProps = {
  itemDetails: null,
};

ItemDetails.propTypes = {
  getItemEvidence: PropTypes.func.isRequired,
  itemDetails: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  setAdvanceInItem: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default ItemDetails;
