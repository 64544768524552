import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button, Tree } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { saveProfile as saveProfileAction } from '../../../actions/Profile';
import { commonPropTypes, normalizeString } from '../../../utils';
import './index.scss';

const ProfileForm = ({
  configTree,
  setShowFormModal,
  profileData,
  activeRecord,
  saveProfile,
  onClose,
}) => {
  const [access, setAccess] = useState([]);
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    saveProfile(values.NEW_NOMBRE_PERFIL, access.join(';'), activeRecord?.ID_PERFIL_SUITE).then(
      () => {
        onClose();
        setShowFormModal(false);
        setAccess([]);
        form.resetFields();
      }
    );
  };

  const getTreeList = (items, id = 0, key, parentKey) =>
    items &&
    items
      .filter((item) => `${item[parentKey]}` === `${id}`)
      .map((item) => ({
        ...item,
        title: item.NOMBRE_MENU,
        key: item[key],
        ...(getTreeList(items, item[key], key, parentKey).length > 0
          ? { children: getTreeList(items, item[key], key, parentKey) }
          : {}),
      }));

  useEffect(() => {
    if (activeRecord) {
      form.setFieldsValue({ NEW_NOMBRE_PERFIL: activeRecord.NOMBRE_PERFIL });
      setAccess(activeRecord.ACCESOS.split(';').filter((item) => item.includes('-')));
    } else {
      setAccess([]);
      form.resetFields();
    }
  }, [activeRecord]);

  const onCheck = (checkedKeysValue) => {
    setAccess(checkedKeysValue);
  };

  const onCancel = () => {
    if (!activeRecord) {
      onClose();
      setShowFormModal(false);
      setAccess([]);
      form.resetFields();
    } else {
      onClose();
      setShowFormModal(false);
    }
  };

  return (
    <div className="profile-form">
      <div className="containerForm d-flex">
        <Form
          labelCol={{ span: 20 }}
          wrapperCol={{ span: 20 }}
          initialValues={{
            requiredMark: 'optional',
          }}
          requiredMark="optional"
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          scrollToFirstError
          className=" d-flex"
        >
          <div style={{ width: ' 270px' }}>
            <Form.Item
              label="Nombre del Perfil"
              name="NEW_NOMBRE_PERFIL"
              rules={[
                {
                  required: true,
                  message: 'Por Favor ingrese nombre de perfil!',
                },
                {
                  validator: async (obj, value) => {
                    if (
                      profileData.find(({ NOMBRE_PERFIL, ID_PERFIL_SUITE }) => {
                        const areEquals = normalizeString(NOMBRE_PERFIL) === normalizeString(value);

                        return (
                          (!activeRecord && areEquals) ||
                          (activeRecord &&
                            ID_PERFIL_SUITE !== activeRecord.ID_PERFIL_SUITE &&
                            areEquals)
                        );
                      })
                    ) {
                      return Promise.reject(new Error('Ya existe un Perfil con este Nombre'));
                    }

                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Tree
              checkedKeys={access}
              checkable
              onCheck={onCheck}
              treeData={getTreeList(configTree, 0, 'ID_MENU', 'ID_PADRE')}
            />
          </div>
          <div className="containerButton ">
            <Button onClick={onCancel}>Cancelar</Button>
            <Button type="primary" htmlType="submit">
              Guardar
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

ProfileForm.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  saveProfile: PropTypes.func.isRequired,
  activeRecord: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  profileData: commonPropTypes.isRequired,
  configTree: commonPropTypes.isRequired,
  setShowFormModal: PropTypes.string.isRequired,
};

export default connect(
  (store) => ({
    profile: store.profile,
  }),
  (dispatch) => ({
    saveProfile: bindActionCreators(saveProfileAction, dispatch),
  })
)(ProfileForm);
