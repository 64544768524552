import update from 'immutability-helper';

export const SET_ERROR_ENCLOSURE = 'SET_ERROR_ENCLOSURE';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';

const initialState = {
  request: null,
  isFetching: true,
  error: '',
};

const help = (state = initialState, action) => {
  switch (action.type) {
    case SET_ERROR_ENCLOSURE: {
      const { error } = action.payload;
      return update(state, {
        error: { $set: error },
        isFetching: { $set: false },
      });
    }
    case SET_NOTIFICATION: {
      const { request } = action.payload;
      return update(state, {
        request: { $set: request },
        isFetching: { $set: false },
      });
    }
    default:
      return state;
  }
};
export default help;
