/* eslint-disable react/prop-types */
import React from 'react';
import { Tooltip, Button, Select, Switch } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import truncate from 'lodash/truncate';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFormattedNumber } from '../../utils';
import DoubleLineCell from '../../components/DoubleLineCell';
import EditableCell from './EditableCell';
import DatePickerEditableCell from './DatePickerEditableCell';
import { setAdvanceControlEdition as setAdvanceControlEditionAction } from '../../actions/advanceControlEdition';

const { Option } = Select;

export const selectOptions = [
  {
    ID: 0,
  },
  {
    ID: 1,
  },
  {
    ID: 2,
  },
  {
    ID: 3,
  },
  {
    ID: 4,
  },
];

const getClassName = (element) => {
  let newClassName = '';
  if (element.ID_NAT === 1) {
    newClassName = 'cap-level';
  }

  if (element.NUM_REST > 0) {
    newClassName = 'negative';
  }

  /*   if (element.NUM_FILA === 1) {
    newClassName += ' k-grid-row-sticky';
  } */

  return newClassName;
};

export const TdWrapper = ({ className, children, record, ...rest }) => {
  return (
    <td className={`${className} ${getClassName(record)}`} {...rest}>
      {children}
    </td>
  );
};

const getIcons = (row, callbacks, t) => {
  if (row.ID_NAT === 1) return null;
  const icons = [];

  const {
    NUM_REST_VENC,
    NUM_REST,
    HITO,
    RUTA_LINEA_BASE,
    RUTA_CONTROL,
    ID_TIPO_RESTRICCION,
    FOTOS,
  } = row;
  const resctrictionName = `${t(
    `advanceControl.restrictionTypes.${
      (selectOptions.find((e) => e.ID === ID_TIPO_RESTRICCION) || {}).ID
    }`
  )} ${row.FECHA_REST}`;

  if (RUTA_LINEA_BASE === 1) {
    icons.push(
      <i
        title={t('advanceControl.iconsInfo.0')}
        className="fas fa-exclamation-circle info-icons gray"
      />
    );
  }
  if (RUTA_CONTROL === 1) {
    icons.push(
      <i
        title={t('advanceControl.iconsInfo.1')}
        className="fas fa-exclamation-circle info-icons blue"
      />
    );
  }

  if (HITO === 1) {
    icons.push(<div title={t('advanceControl.iconsInfo.2')} className="info-icons diamond" />);
  }
  if (NUM_REST_VENC === 0 && NUM_REST > 0) {
    icons.push(
      <i
        style={{ cursor: 'pointer' }}
        title={t('advanceControl.iconsInfo.3')}
        className="fas fa-solid fa-registered info-icons blue"
        onClick={() => callbacks.setActiveRestRow(row)}
      />
    );
  }

  if (NUM_REST_VENC > 0) {
    icons.push(
      <i
        style={{ cursor: 'pointer' }}
        title={t('advanceControl.iconsInfo.4')}
        className="fas fa-solid fa-registered info-icons red"
        onClick={() => callbacks.setActiveRestRow(row)}
      />
    );
  }

  if (FOTOS > 0) {
    icons.push(
      <i
        style={{ cursor: 'pointer' }}
        title="Evidencia Fotográfica"
        className="fas fa-solid fa-camera info-icons"
        onClick={() => callbacks.setActivePhotosRow(row)}
      />
    );
  }

  if (ID_TIPO_RESTRICCION > 0) {
    icons.push(
      <i title={resctrictionName} className="fas fa-solid fa-calendar info-icons yellow" />
    );
  }
  return <div className="d-flex">{icons}</div>;
};

const SelectCell = connect(
  (store) => ({
    advanceControlEdition: store.advanceControlEdition,
  }),
  (dispatch) => ({
    editCell: bindActionCreators(setAdvanceControlEditionAction, dispatch),
  })
)(({ dataItem, editCell, t, fieldsWithErrors, validDates }) => {
  const [value, setValue] = React.useState(dataItem.ID_TIPO_RESTRICCION || 0);

  const { ID_INS, PATH } = dataItem;

  if (dataItem.ID_NAT !== 3) {
    const td = <td className={getClassName(dataItem)} />;
    return (
      <>
        {td}
        {td}
      </>
    );
  }
  return (
    <>
      <TdWrapper
        record={dataItem}
        className={`text-align-center select-cell ${getClassName(dataItem)}`}
      >
        <Select
          isSearchable
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
            editCell(
              [
                {
                  ID_INS,
                  ID_TIPO_RESTRICCION: newValue,
                  PATH,
                  ...(newValue === 0 ? { COMIENZO_EST: '' } : {}),
                },
              ],
              dataItem
            );
          }}
        >
          {selectOptions.map((e) => (
            <Option value={e.ID}>{t(`advanceControl.restrictionTypes.${e.ID}`)}</Option>
          ))}
        </Select>
      </TdWrapper>
      <DatePickerEditableCell
        validDates={validDates}
        className={`text-align-center ${dataItem.RUTA_CONTROL === 1 ? 'blue-cell' : ''} ${
          fieldsWithErrors.includes(dataItem.ID_INS) ? 'with-error' : ''
        }`}
        record={dataItem}
        editable={(record) => record.ID_NAT === 3 && value > 0}
        dataKey="FECHA_REST"
        selectValue={value}
      />
    </>
  );
});

export default ({
  activeOptions,
  isEditing,
  validDates,
  fieldsWithErrors,
  setActiveRow,
  activeRow,
  setActiveRestRow,
  setActiveValorizationRow,
  setActiveQualityCardRow,
  setActivePhotosRow,
  setActiveCurrentContractRow,
  setActiveMeasuredContractRow,
  t,
}) => {
  return [
    {
      field: 'ICONS',
      title: t('common.information'),
      cell: ({ dataItem }) => {
        return (
          <td className="k-border-right">
            {getIcons(dataItem, { setActiveRestRow, setActivePhotosRow }, t)}
          </td>
        );
      },
    },
    ...(activeOptions.option11
      ? [
          {
            field: '',
            title: (
              <DoubleLineCell
                firstValue={t('advanceControl.showDetail1')}
                secondValue={t('advanceControl.showDetail2')}
              />
            ),
            width: 80,
            locked: true,
            cell: (props) => {
              const { dataItem, className } = props;

              const isActive = activeRow && activeRow.ID_INS === dataItem.ID_INS;
              return (
                <TdWrapper
                  record={dataItem}
                  {...props}
                  className={`${className} k-border-right text-align-center`}
                >
                  {dataItem.ID_NAT === 3 ? (
                    <Switch
                      className="toggle"
                      checked={isActive}
                      onChange={() => {
                        setActiveRow(
                          (activeRow && activeRow.ID_INS !== dataItem.ID_INS) || !activeRow
                            ? dataItem
                            : null
                        );
                      }}
                    />
                  ) : null}
                </TdWrapper>
              );
            },
          },
        ]
      : []),

    {
      field: 'NUM_FILA',
      title: 'Id',
      width: 60,
      locked: true,
      cell: (props) => {
        const { dataItem, className } = props;
        return (
          <TdWrapper
            record={dataItem}
            {...props}
            className={`${className} k-border-right text-align-center`}
          >
            {dataItem.NUM_FILA}
          </TdWrapper>
        );
      },
    },
    {
      field: 'CODIGO_INS',
      title: 'EDT',
      width: 90,
      locked: true,
      cell: (props) => {
        const { dataItem, className } = props;
        return (
          <TdWrapper record={dataItem} {...props} className={`${className} k-border-right`}>
            {dataItem.CODIGO_INS}
          </TdWrapper>
        );
      },
    },
    {
      field: 'NOMBRE_INS',
      expandable: true,
      title: t('advanceControl.taskName'),
      width: 350,
      locked: true,
      cell: (props) => {
        const { dataItem, expanded, onExpandChange, level, className } = props;

        return (
          <TdWrapper
            record={dataItem}
            {...props}
            className={`${className} k-border-right text-align-center`}
          >
            <div
              role="button"
              tabIndex={-1}
              onClick={(e) => onExpandChange(e, dataItem, level)}
              className={`d-flex expandable-cell padding-${level.length}`}
            >
              {dataItem.children && (
                <div>{expanded ? <CaretUpOutlined /> : <CaretDownOutlined />}</div>
              )}

              <Tooltip
                placement="topLeft"
                destroyTooltipOnHide={{ keepParent: false }}
                title={dataItem.NOMBRE_INS}
              >
                <div className="name-ins text-align-left"> {dataItem.NOMBRE_INS}</div>
              </Tooltip>
            </div>
          </TdWrapper>
        );
      },
    },
    ...(activeOptions.option15
      ? [
          {
            field: 'UM_EST',
            title: 'UM',
            width: 70,
            cell: ({ dataItem }) => {
              if (dataItem.ID_NAT !== 3) return <td className="k-border-right" />;
              return (
                <TdWrapper record={dataItem} className="k-border-right text-align-center">
                  {dataItem.UM_EST}
                </TdWrapper>
              );
            },
          },
          {
            field: 'PU',
            title: 'PU',
            width: 70,
            cell: ({ dataItem }) => {
              if (dataItem.ID_NAT !== 3) return <td className="k-border-right" />;
              return (
                <TdWrapper record={dataItem} className="text-align-right k-border-right">
                  <span title={getFormattedNumber(dataItem.PU)}>
                    {getFormattedNumber(dataItem.PU, 1)}
                  </span>
                </TdWrapper>
              );
            },
          },
          {
            title: 'Contrato Adjudicado',
            children: [
              {
                field: 'CANT_INI',
                title: t('common.quantity'),
                width: 90,
                cell: ({ dataItem }) => {
                  if (dataItem.ID_NAT !== 3) return <td />;
                  return (
                    <TdWrapper record={dataItem} className="text-align-right">
                      <span title={getFormattedNumber(dataItem.CANT_INI)}>
                        {getFormattedNumber(dataItem.CANT_INI, 1)}
                      </span>
                    </TdWrapper>
                  );
                },
              },
              {
                field: 'TOT_INI',
                title: 'Total',
                width: 70,
                cell: ({ dataItem }) => {
                  return (
                    <TdWrapper record={dataItem} className="text-align-right k-border-right">
                      <span title={getFormattedNumber(dataItem.TOT_INI)}>
                        {getFormattedNumber(dataItem.TOT_INI, 1)}
                      </span>
                    </TdWrapper>
                  );
                },
              },
            ],
          },
          {
            title: 'Contrato Vigente',
            children: [
              {
                field: 'CANT_OFICIAL',
                title: t('common.quantity'),
                width: 100,
                cell: ({ dataItem }) => {
                  if (dataItem.ID_NAT !== 3) return <td />;
                  return (
                    <TdWrapper record={dataItem} className="text-align-right">
                      <span title={getFormattedNumber(dataItem.CANT_OFICIAL)}>
                        {getFormattedNumber(dataItem.CANT_OFICIAL, 1)}
                      </span>
                    </TdWrapper>
                  );
                },
              },
              {
                field: 'TOT_OFICIAL',
                title: 'Total',
                width: 70,
                cell: ({ dataItem }) => {
                  return (
                    <TdWrapper record={dataItem} className="text-align-right k-border-right">
                      <span title={getFormattedNumber(dataItem.TOT_OFICIAL)}>
                        {getFormattedNumber(dataItem.TOT_OFICIAL, 1)}
                      </span>
                    </TdWrapper>
                  );
                },
              },
            ],
          },
          {
            title: 'Contrato Medido',
            children: [
              {
                field: 'CANT_MEDIDA',
                title: t('common.quantity'),
                width: 120,
                cell: ({ dataItem }) => {
                  if (dataItem.ID_NAT !== 3) return <td />;
                  return (
                    <TdWrapper
                      record={dataItem}
                      className="text-align-right d-flex align-items-center"
                    >
                      <span title={getFormattedNumber(dataItem.CANT_MEDIDA)}>
                        {getFormattedNumber(dataItem.CANT_MEDIDA, 1)}
                      </span>
                      <Button
                        className="edit-button ml-auto"
                        onClick={() => setActiveMeasuredContractRow(dataItem)}
                        type="default"
                      >
                        Editar
                      </Button>
                    </TdWrapper>
                  );
                },
              },
              {
                field: 'TOT_MEDIDO',
                title: 'Total',
                width: 70,
                cell: ({ dataItem }) => {
                  return (
                    <TdWrapper record={dataItem} className="text-align-right k-border-right">
                      <span title={getFormattedNumber(dataItem.TOT_MEDIDO)}>
                        {getFormattedNumber(dataItem.TOT_MEDIDO, 1)}
                      </span>
                    </TdWrapper>
                  );
                },
              },
            ],
          },
          {
            title: 'Variación (Medido vs Oficial)',
            children: [
              {
                field: 'CANT_MEDIDA',
                title: t('common.quantity'),
                width: 100,
                cell: ({ dataItem }) => {
                  const total = dataItem.CANT_MEDIDA - dataItem.CANT_OFICIAL;

                  if (dataItem.ID_NAT !== 3) return <td />;
                  return (
                    <TdWrapper record={dataItem} className="text-align-right">
                      <span title={getFormattedNumber(total)}>{getFormattedNumber(total, 1)}</span>
                    </TdWrapper>
                  );
                },
              },
              {
                field: 'TOT_MEDIDO',
                title: 'Total',
                width: 90,
                cell: ({ dataItem }) => {
                  const total = dataItem.TOT_MEDIDO - dataItem.TOT_OFICIAL;

                  return (
                    <TdWrapper record={dataItem} className="text-align-right k-border-right">
                      <span title={getFormattedNumber(total)}>{getFormattedNumber(total, 1)}</span>
                    </TdWrapper>
                  );
                },
              },
            ],
          },
        ]
      : []),
    ...(activeOptions.option12
      ? [
          {
            field: 'PREDECESORA',
            title: t('advanceControl.predecessor'),
            width: 150,
            cell: (props) => {
              const { dataItem, className } = props;
              return (
                <TdWrapper record={dataItem} {...props} className={`${className} k-border-right`}>
                  <Tooltip
                    placement="topLeft"
                    destroyTooltipOnHide={{ keepParent: false }}
                    title={dataItem.PREDECESORA}
                  >
                    {truncate(dataItem.PREDECESORA, { length: 25 })}
                  </Tooltip>
                </TdWrapper>
              );
            },
          },
        ]
      : []),
    ...(activeOptions.option13
      ? [
          {
            field: 'SUCESORA',
            title: t('advanceControl.successor'),
            width: 150,
            cell: (props) => {
              const { dataItem, className } = props;
              return (
                <TdWrapper record={dataItem} {...props} className={`${className} k-border-right`}>
                  <Tooltip
                    placement="topLeft"
                    destroyTooltipOnHide={{ keepParent: false }}
                    title={dataItem.SUCESORA}
                  >
                    {truncate(dataItem.SUCESORA, { length: 25 })}
                  </Tooltip>
                </TdWrapper>
              );
            },
          },
        ]
      : []),
    ...(activeOptions.option0
      ? [
          {
            title: t('common.baseQuantity'),
            children: [
              {
                field: 'Q_PPTO',
                title: t('common.quantity'),
                width: 90,
                cell: ({ dataItem }) => {
                  return (
                    <TdWrapper record={dataItem} className="text-align-right">
                      <span title={getFormattedNumber(dataItem.Q_PPTO)}>
                        {getFormattedNumber(dataItem.Q_PPTO, 1)}
                      </span>
                    </TdWrapper>
                  );
                },
              },
              {
                field: 'UM_PPTO',
                title: 'UM',
                width: 70,
                cell: ({ dataItem }) => {
                  if (dataItem.ID_NAT !== 3) return <td className="k-border-right" />;

                  return (
                    <TdWrapper record={dataItem} className="k-border-right text-align-center">
                      {dataItem.UM_PPTO}
                    </TdWrapper>
                  );
                },
              },
            ],
          },
        ]
      : []),
    {
      title: t('advanceControl.baseLineProgramming'),
      children: [
        {
          field: 'DURACION',
          title: t('common.duration'),
          width: 90,
          cell: ({ dataItem }) => {
            return (
              <TdWrapper
                record={dataItem}
                className={`text-align-right ${dataItem.RUTA_LINEA_BASE === 1 ? 'gray-cell' : ''} `}
              >
                <span title={getFormattedNumber(dataItem.DURACION)}>
                  {getFormattedNumber(dataItem.DURACION, 1)}
                </span>
              </TdWrapper>
            );
          },
        },
        {
          field: 'COMIENZO',
          title: t('common.start'),
          width: 90,
          cell: ({ dataItem }) => {
            return (
              <TdWrapper
                record={dataItem}
                className={`text-align-center ${
                  dataItem.RUTA_LINEA_BASE === 1 ? 'gray-cell' : ''
                } `}
              >
                {dataItem.COMIENZO}
              </TdWrapper>
            );
          },
        },
        {
          field: 'FIN',
          title: t('common.end'),
          width: 90,
          cell: ({ dataItem }) => {
            return (
              <TdWrapper
                record={dataItem}
                className={`text-align-center ${dataItem.RUTA_LINEA_BASE === 1 ? 'gray-cell' : ''}
                  ${!activeOptions.option1 ? 'k-border-right' : ''}
               `}
              >
                {dataItem.FIN}
              </TdWrapper>
            );
          },
        },
        ...(activeOptions.option2
          ? [
              {
                field: 'HOLGURA_TOT',
                title: (
                  <DoubleLineCell
                    firstValue={t('advanceControl.totalSlack1')}
                    secondValue={t('advanceControl.totalSlack2')}
                  />
                ),
                width: 90,
                cell: ({ dataItem }) => {
                  if (dataItem.ID_NAT === 1)
                    return (
                      <td
                        className={
                          !activeOptions.option14 && !activeOptions.option3 ? 'k-border-right' : ''
                        }
                      />
                    );
                  return (
                    <TdWrapper
                      record={dataItem}
                      className={`text-align-right ${
                        !activeOptions.option14 && !activeOptions.option3 ? 'k-border-right' : ''
                      }`}
                    >
                      <span title={getFormattedNumber(dataItem.HOLGURA_TOT)}>
                        {getFormattedNumber(dataItem.HOLGURA_TOT, 1)}
                      </span>
                    </TdWrapper>
                  );
                },
              },
            ]
          : []),
      ],
    },
    {
      title: t('common.advance'),
      children: [
        {
          field: 'PRC_PROG',
          title: t('common.program'),
          width: 100,
          cell: ({ dataItem }) => {
            const value = dataItem.PRC_PROG * 100;
            return (
              <TdWrapper record={dataItem} className="text-align-right">
                <span title={`${getFormattedNumber(value)}%`}>
                  {`${getFormattedNumber(value, 1)}%`}
                </span>
              </TdWrapper>
            );
          },
        },
        {
          field: 'PRC_AVA_AUX',
          title: t('common.real'),
          width: 140,
          cell: (props) => {
            const { dataItem } = props;
            return (
              <EditableCell
                keepEditable
                rowData={dataItem}
                dataIndex="PRC_AVA_AUX"
                maxButtonValue={100}
                editable={isEditing}
                decimalsIndex={1}
                additionalInputFields={() => ({
                  type: 'number',
                  min: 0,
                  max: 100,
                })}
                unit="%"
                objectModifier={(obj, record, value) => {
                  obj.Q_AVA = (value * record.Q_PPTO) / 100;
                  if (value > 100) {
                    obj.PRC_AVA_AUX = 100;
                    obj.Q_AVA = record.Q_PPTO;
                  }
                  return obj;
                }}
                isUnitPrefix={false}
                customClass={(record) => `text-align-center  ${getClassName(record)}`}
                conditionToEditable={(record) => record.ID_NAT === 3 && record.EDITA_PRC_AVA}
                type="number"
                className={(record) => `${getClassName(record)} ${isEditing && 'editable'}`}
              />
            );
          },
        },
        {
          field: 'PRC_DESV',
          title: t('common.deviation'),
          width: 100,
          cell: ({ dataItem }) => {
            const value = dataItem.PRC_DESV * 100;

            let valueToCompare = value.toFixed(2);
            if (valueToCompare === 0) valueToCompare = 0;
            const className = valueToCompare < 0 ? 'negative' : 'positive';
            return (
              <TdWrapper
                record={dataItem}
                className={`text-align-right ${
                  !activeOptions.option14 && !activeOptions.option3 && !activeOptions.option2
                    ? 'k-border-right'
                    : ''
                }`}
              >
                <span className={className} title={`${value}%`}>
                  {`${getFormattedNumber(valueToCompare, 1)}%`}
                </span>
              </TdWrapper>
            );
          },
        },

        ...(activeOptions.option14
          ? [
              {
                title: t('advanceControl.realAccordingToQuality'),
                children: [
                  {
                    field: 'PRC_AVA_QA',
                    headerClassName: 'd-none',
                    title: '__',
                    width: 90,
                    cell: ({ dataItem }) => {
                      return (
                        <TdWrapper
                          record={dataItem}
                          className={`text-align-right ${dataItem.AVA_QA > 0 ? 'orange-cell' : ''}`}
                        >
                          <span title={`${getFormattedNumber(dataItem.PRC_AVA_QA * 100)}%`}>
                            {`${getFormattedNumber(dataItem.PRC_AVA_QA * 100, 1)}%`}
                          </span>
                        </TdWrapper>
                      );
                    },
                  },
                  {
                    field: 'PRC_AVA_QA_BTN',
                    headerClassName: 'd-none',
                    title: '__',
                    width: 80,
                    cell: ({ dataItem }) => {
                      if (dataItem.AVA_QA <= 0) return <td />;
                      return (
                        <td>
                          <Button
                            className="card-button"
                            onClick={() => setActiveQualityCardRow(dataItem)}
                            type="default"
                          >
                            {t('common.sheets')}
                          </Button>
                        </td>
                      );
                    },
                  },
                ],
              },
              {
                field: 'PRC_DESV_QA',
                title: (
                  <DoubleLineCell
                    firstValue={t('advanceControl.advanceDiff1')}
                    secondValue={t('advanceControl.advanceDiff2')}
                  />
                ),
                width: 80,
                cell: ({ dataItem }) => {
                  if (dataItem.PRC_DESV_QA === 0)
                    return <td className={!activeOptions.option3 ? 'k-border-right' : ''} />;

                  const className = dataItem.PRC_DESV_QA < 0 ? 'negative' : 'positive';
                  return (
                    <TdWrapper
                      record={dataItem}
                      className={`text-align-right ${
                        !activeOptions.option3 ? 'k-border-right' : ''
                      }`}
                    >
                      <span
                        className={className}
                        title={`${getFormattedNumber(dataItem.PRC_DESV_QA * 100)}%`}
                      >
                        {`${getFormattedNumber(dataItem.PRC_DESV_QA * 100, 1)}%`}
                      </span>
                    </TdWrapper>
                  );
                },
              },
            ]
          : []),
        ...(activeOptions.option3
          ? [
              {
                field: 'UM',
                title: 'UM',
                width: 60,
                cell: ({ dataItem }) => {
                  return (
                    <TdWrapper record={dataItem} className="k-border-right text-align-center">
                      {dataItem.UM}
                    </TdWrapper>
                  );
                },
              },
            ]
          : []),
        ...(activeOptions.option1
          ? [
              {
                field: 'Q_AVA',
                title: (
                  <DoubleLineCell
                    firstValue={t('advanceControl.advancedQuantity1')}
                    secondValue={t('advanceControl.advancedQuantity2')}
                  />
                ),
                width: 90,
                cell: (props) => {
                  const { dataItem } = props;
                  return (
                    <EditableCell
                      keepEditable
                      rowData={dataItem}
                      dataIndex="Q_AVA"
                      editable={isEditing}
                      decimalsIndex={1}
                      canShow={(record) => record.ID_NAT === 3}
                      conditionToEditable={(record) => record.ID_NAT === 3 && record.EDITA_PRC_AVA}
                      additionalInputFields={(record) => ({
                        type: 'number',
                        min: 0,
                        max: record.Q_PPTO,
                      })}
                      objectModifier={(obj, record, value) => {
                        obj.PRC_AVA_AUX = (value * 100) / record.Q_PPTO;
                        const maxValue = record.Q_PPTO;
                        if (value > maxValue) {
                          obj.Q_AVA = record.Q_PPTO;
                          obj.PRC_AVA_AUX = 100;
                        }
                        return obj;
                      }}
                      isUnitPrefix={false}
                      customClass={(record) =>
                        `text-align-right k-border-right ${getClassName(record)}`
                      }
                      type="number"
                      {...props}
                      className={() =>
                        `text-align-right ${dataItem.RUTA_LINEA_BASE === 1 ? 'gray-cell' : ''}
                         ${isEditing && 'editable'}`
                      }
                    />
                  );
                },
              },
            ]
          : []),
      ],
    },

    {
      title: t('advanceControl.controlledProgramming'),
      children: [
        {
          field: 'DURACION_TOT',
          title: <DoubleLineCell firstValue="Duración" secondValue="Total" />,
          width: 90,
          cell: ({ dataItem }) => {
            return (
              <TdWrapper
                record={dataItem}
                className={`text-align-right ${dataItem.RUTA_CONTROL === 1 ? 'blue-cell' : ''} `}
              >
                <span title={getFormattedNumber(dataItem.DURACION_TOT)}>
                  {getFormattedNumber(dataItem.DURACION_TOT, 0)}
                </span>
              </TdWrapper>
            );
          },
        },
        ...(activeOptions.option4
          ? [
              {
                field: 'DURACION_REAL',
                title: (
                  <DoubleLineCell
                    firstValue={t('advanceControl.elapsedDuration1')}
                    secondValue={t('advanceControl.elapsedDuration2')}
                  />
                ),
                width: 103,
                cell: ({ dataItem }) => {
                  return (
                    <TdWrapper record={dataItem} className="text-align-right">
                      <span title={getFormattedNumber(dataItem.DURACION_REAL)}>
                        {getFormattedNumber(dataItem.DURACION_REAL, 0)}
                      </span>
                    </TdWrapper>
                  );
                },
              },
            ]
          : []),
        ...(activeOptions.option5
          ? [
              {
                field: 'DURACION_REST',
                title: (
                  <DoubleLineCell
                    firstValue={t('advanceControl.remainDuration1')}
                    secondValue={t('advanceControl.remainDuration2')}
                  />
                ),
                width: 90,
                cell: (props) => {
                  const { dataItem } = props;
                  return (
                    <EditableCell
                      keepEditable
                      rowData={dataItem}
                      dataIndex="DURACION_REST"
                      editable={isEditing}
                      decimalsIndex={1}
                      additionalInputFields={() => ({
                        type: 'number',
                        min: 0,
                      })}
                      canShow={(record) => {
                        return record.DURACION_REST !== undefined && record.PRC_AVA_AUX !== 100;
                      }}
                      customClass={(record) =>
                        `text-align-right ${
                          dataItem.RITMO_EST !== 0 ? 'editable' : ''
                        } ${getClassName(record)}`
                      }
                      objectModifier={(obj) => {
                        obj.RITMO_EST = 1;

                        return obj;
                      }}
                      conditionToEditable={(record) =>
                        record.ID_NAT === 3 && record.PRC_AVA_AUX !== 100
                      }
                      type="number"
                      className={(record) => `${getClassName(record)} ${isEditing && 'editable'}`}
                    />
                  );
                },
              },
            ]
          : []),
        {
          field: 'COMIENZO_PROY',
          title: t('common.start'),
          width: 90,
          cell: ({ dataItem }) => {
            return (
              <TdWrapper
                record={dataItem}
                className={`text-align-center ${dataItem.RUTA_CONTROL === 1 ? 'blue-cell' : ''} `}
              >
                {dataItem.COMIENZO_PROY}
              </TdWrapper>
            );
          },
        },
        {
          field: 'COMIENZO_SALDO',
          title: (
            <DoubleLineCell
              firstValue={t('advanceControl.startBalance1')}
              secondValue={t('advanceControl.startBalance2')}
            />
          ),
          width: 90,
          cell: ({ dataItem }) => {
            return (
              <TdWrapper record={dataItem} className="text-align-center">
                {dataItem.COMIENZO_SALDO}
              </TdWrapper>
            );
          },
        },
        ...(isEditing
          ? [
              {
                field: 'COMIENZO_REAL',
                title: (
                  <DoubleLineCell
                    firstValue={t('advanceControl.realStart1')}
                    secondValue={t('advanceControl.realStart2')}
                  />
                ),
                width: 90,
                cell: ({ dataItem }) => {
                  return (
                    <DatePickerEditableCell
                      validDates={validDates}
                      className={`text-align-center ${
                        dataItem.RUTA_CONTROL === 1 ? 'blue-cell' : ''
                      } `}
                      record={dataItem}
                      editable={(record) => record.ID_NAT === 3}
                      dataKey="COMIENZO_REAL"
                    />
                  );
                },
              },
            ]
          : []),
        {
          field: 'FIN_REAL',
          title: t('advanceControl.realEnd'),
          width: 90,
          cell: ({ dataItem }) => {
            return (
              <DatePickerEditableCell
                validDates={validDates}
                className={`text-align-center ${dataItem.RUTA_CONTROL === 1 ? 'blue-cell' : ''} `}
                record={dataItem}
                editable={(record) => isEditing && record.ID_NAT === 3}
                dataKey="FIN_REAL"
              />
            );
          },
        },

        ...(isEditing
          ? [
              {
                field: 'ID_TIPO_RESTRICCION',
                title: (
                  <DoubleLineCell
                    firstValue={t('advanceControl.restrictionType1')}
                    secondValue={t('advanceControl.restrictionType2')}
                  />
                ),
                width: 160,
                cell: (props) => (
                  <SelectCell
                    {...props}
                    validDates={validDates}
                    fieldsWithErrors={fieldsWithErrors}
                    t={t}
                  />
                ),
              },
              {
                field: 'FECHA_REST',
                title: (
                  <DoubleLineCell
                    firstValue={t('advanceControl.dateRestricion1')}
                    secondValue={t('advanceControl.dateRestricion2')}
                  />
                ),
                width: 100,
                cell: () => {
                  return null;
                },
              },
            ]
          : []),
        ...(activeOptions.option6
          ? [
              {
                field: 'HOLGURA_TOT_PROY',
                title: (
                  <DoubleLineCell
                    firstValue={t('advanceControl.totalSlackAccordingProjected1')}
                    secondValue={t('advanceControl.totalSlackAccordingProjected2')}
                  />
                ),
                width: 110,
                cell: ({ dataItem: record }) => {
                  if (
                    record.HOLGURA_TOT_PROY === undefined ||
                    record.ID_NAT === 1 ||
                    (record.ID_NAT === 3 && record.PRC_AVA === 1)
                  )
                    return <td />;

                  const className = record.HOLGURA_TOT_PROY < 0 ? 'negative' : 'positive';
                  return (
                    <TdWrapper
                      record={record}
                      className={`text-align-right ${
                        record.RUTA_CONTROL === 1 ? 'blue-cell' : ''
                      } ${!activeOptions.option7 ? 'k-border-right' : ''}`}
                    >
                      <span
                        className={className}
                        title={getFormattedNumber(record.HOLGURA_TOT_PROY)}
                      >
                        {getFormattedNumber(record.HOLGURA_TOT_PROY, 1)}
                      </span>
                    </TdWrapper>
                  );
                },
              },
            ]
          : []),
        ...(activeOptions.option7
          ? [
              {
                field: 'DESV_LINEA_BASE',
                title: (
                  <DoubleLineCell
                    firstValue={t('advanceControl.deviationAccordingToBaseLine1')}
                    secondValue={t('advanceControl.deviationAccordingToBaseLine2')}
                  />
                ),
                width: 165,
                cell: ({ dataItem: record }) => {
                  if (record.DESV_LINEA_BASE === undefined) return <td />;

                  const className = record.DESV_LINEA_BASE < 0 ? 'negative' : 'positive';
                  return (
                    <TdWrapper record={record} className="text-align-right k-border-right">
                      <span
                        className={className}
                        title={getFormattedNumber(record.DESV_LINEA_BASE)}
                      >
                        {getFormattedNumber(record.DESV_LINEA_BASE, 1)}
                      </span>
                    </TdWrapper>
                  );
                },
              },
            ]
          : []),
      ],
    },
    ...(activeOptions.option8
      ? [
          {
            field: 'CALENDARIO',
            title: t('common.calendar'),
            width: 95,
            cell: ({ dataItem: record }) => {
              return (
                <TdWrapper record={record} className="text-align-center k-border-right">
                  <Tooltip
                    placement="topLeft"
                    destroyTooltipOnHide={{ keepParent: false }}
                    title={record.CALENDARIO}
                  >
                    <span className="value"> {record.CALENDARIO}</span>
                  </Tooltip>
                </TdWrapper>
              );
            },
          },
        ]
      : []),
    ...(activeOptions.option9
      ? [
          {
            title: `${t('advanceControl.verticalAdvanceLinks1')} ${t(
              'advanceControl.verticalAdvanceLinks2'
            )}`,
            children: [
              {
                field: 'Cant.',
                title: 'Cant.',
                cell: ({ dataItem: record }) => {
                  if (record.ID_NAT === 1) return <td />;
                  return (
                    <TdWrapper record={record} className="text-align-right">
                      <span title={getFormattedNumber(record.NUM_VINC)}>
                        {getFormattedNumber(record.NUM_VINC, 1)}
                      </span>
                    </TdWrapper>
                  );
                },
                width: 90,
              },
              {
                field: 'Valorización',
                title: t('advanceControl.valorization'),
                cell: ({ dataItem: record }) => {
                  if (record.ID_NAT === 1) return <td className="k-border-right" />;
                  return (
                    <TdWrapper record={record} className="text-align-right k-border-right">
                      <span title={getFormattedNumber(record.TOT_VINC, null, false)}>
                        {getFormattedNumber(record.TOT_VINC, 1, false)}
                      </span>
                      <i
                        className="fa fa-search ml-2"
                        onClick={() => setActiveValorizationRow(record)}
                      />
                    </TdWrapper>
                  );
                },
                width: 120,
              },
            ],
          },
        ]
      : []),
    ...(activeOptions.option10
      ? [
          {
            field: 'NUMB_SUBT',
            title: t('common.subtasks'),
            width: 90,
            cell: ({ dataItem: record }) => {
              if (record.NUM_SUBT === undefined) return <td />;
              return (
                <TdWrapper record={record} className="text-align-right">
                  <span title={getFormattedNumber(record.NUM_SUBT)}>
                    {getFormattedNumber(record.NUM_SUBT, 1)}
                  </span>
                </TdWrapper>
              );
            },
          },
        ]
      : []),
  ];
};
