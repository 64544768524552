import _ from 'lodash';
import { message } from 'antd';
import update from 'immutability-helper';
import api, { getHeaders } from '../utils/api';

import {
  BUDGET_FETCHED,
  GET_BUDGET,
  GET_BUDGET_ERROR,
  RESET_BUDGET,
  GET_UNITS,
  GET_UNITS_ERROR,
  UNITS_FETCHED,
  INPUTS_TYPES_FETCHED,
  GET_INPUTS_TYPES,
  GET_INPUTS_TYPES_ERROR,
  RESOURCES_AND_SUBITEMS_FETCHED,
  GET_RESOURCES_AND_SUBITEMS_ERROR,
  GET_RESOURCES_AND_SUBITEMS,
  ADD_RESOURCE_OR_SUBITEM,
} from '../reducers/budget';
import store from '../store';

export const budgetFetched = (budget, workId) => ({
  type: BUDGET_FETCHED,
  budget,
  workId,
});

export const unitsFetched = (units) => ({
  type: UNITS_FETCHED,
  units,
});

export const inputsTypesFetched = (inputsTypes) => ({
  type: INPUTS_TYPES_FETCHED,
  inputsTypes,
});

export const resourcesAndSubitemsFetched = (resourcesAndSubitems) => ({
  type: RESOURCES_AND_SUBITEMS_FETCHED,
  resourcesAndSubitems,
});

export const addResourceOrSubitem = (element) => ({
  type: ADD_RESOURCE_OR_SUBITEM,
  element,
});

export const resetBudget = () => ({ type: RESET_BUDGET });

export const getBudget =
  (ID_OBR, filters = null) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: GET_BUDGET });

      const requestBody = {
        USUARIO: localStorage.getItem('foco_username'),
        EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
        ID_OBR,
        ...(filters || { VARIACION_VIGENTE: 0, VARIACION_ACTUALIZADO: 0, EXITO_DISTINTO_100: 0 }),
      };
      api
        .post('/api/Presupuesto/GetControlPresupuestoFiltrado', requestBody, getHeaders())
        .then((res) => {
          if (res.status === 200) {
            dispatch(budgetFetched(res.data, ID_OBR));
            resolve(res.data);
          } else {
            dispatch({
              type: GET_BUDGET_ERROR,
            });
            reject(res.data);
          }
        })
        .catch((err) => {
          dispatch({
            type: GET_BUDGET_ERROR,
          });
          reject(err);
        });
    });

const editSuccessMessage = 'Celdas editadas correctamente';
const editErrorMessage = 'Ocurrió un error al editar las celdas';

export const editBudgetCells = (data, action = 'updated_budget') =>
  new Promise((resolve, reject) => {
    let parameters = {
      url: 'SetCantidadMod',
      field: 'ITEM_CANTIDAD_MOD',
    };

    if (action === 'current_budget') {
      parameters = {
        url: 'SetCantidadVigente',
        field: 'ITEM_CANTIDAD_VIGENTE',
      };
    } else if (action === 'set_sale_price') {
      parameters = {
        url: 'SetPrecioVenta',
        field: 'PRECIO_VENTA',
      };
    } else if (action === 'account_of_cost') {
      parameters = {
        url: 'SetCuentaCosto',
        field: 'ITEM_CUENTA_COSTO',
      };
    }

    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      [parameters.field]: data,
    };
    api
      .post(`/api/Presupuesto/${parameters.url}`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200 && res.data === 'OK') {
          resolve(res.data);
          message.success(editSuccessMessage, 8);
        } else {
          reject(res.data);
          message.error(editErrorMessage, 8);
        }
      })
      .catch((err) => {
        message.error(editErrorMessage, 8);
        reject(err);
      });
  });

export const saveForecast = (selectedWork, NOMBRE_FORECAST) => (dispatch) =>
  new Promise((resolve) => {
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR: selectedWork,
      NOMBRE_FORECAST,
    };

    api
      .post(`api/Presupuesto/GuardarForecast`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          getBudget()(dispatch);

          message.success('Forecast guardado correctamente', 4);
        } else {
          message.success('Error guardando Forecast', 4);
        }
        resolve();
      })
      .catch(() => message.error('Error guardando Forecast', 4));
  });

export const getUnits = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: GET_UNITS });

    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
    };
    api
      .post(`/api/Presupuesto/GetUnidades`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          const newUnits = res.data.sort((a, b) => {
            if (a.NOMBRE_UNI.toUpperCase() < b.NOMBRE_UNI.toUpperCase()) {
              return -1;
            }
            if (a.NOMBRE_UNI.toUpperCase() > b.NOMBRE_UNI.toUpperCase()) {
              return 1;
            }

            return 0;
          });

          dispatch(unitsFetched(newUnits));
          resolve(newUnits);
        } else {
          dispatch({
            type: GET_UNITS_ERROR,
          });
          reject();
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_UNITS_ERROR,
        });
        reject(err);
      });
  });

export const getInputsTypes = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: GET_INPUTS_TYPES });

    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
    };
    api
      .post(`/api/Presupuesto/GetTipoInsumos`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch(inputsTypesFetched(res.data));
          resolve(res.data);
        } else {
          dispatch({
            type: GET_INPUTS_TYPES_ERROR,
          });
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_INPUTS_TYPES_ERROR,
        });
        reject(err);
      });
  });

export const addNewItem = (body) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR: _.get(store.getState(), 'works.selectedWork', ''),
      ...body,
    };

    const isCreateItem = body.ID_ITEM === 0;
    api
      .post(
        `/api/Presupuesto/${isCreateItem ? 'SetPartida' : 'UpdPartida'}`,
        requestBody,
        getHeaders()
      )
      .then((res) => {
        if (res.status === 200 && _.get(res, 'data.ID_ITEM', 0) !== 0) {
          resolve(res.data);
          message.success(`Partida ${isCreateItem ? 'creada' : 'editada'} correctamente`, 8);
        } else {
          reject(res.data);
          message.error(`Hubo un error al ${isCreateItem ? 'crear' : 'editar'} la partida`, 8);
        }
      })
      .catch((err) => {
        message.error(`Hubo un error al ${isCreateItem ? 'crear' : 'editar'} la partida`, 8);
        reject(err);
      });
  });

export const addNewChapter = (body) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR: _.get(store.getState(), 'works.selectedWork', ''),
      ...body,
    };
    api
      .post('/api/Presupuesto/SetCapitulo', requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200 && _.get(res, 'data.ID_ITEM', 0) !== 0) {
          resolve(res.data);
          message.success('Capítulo creado correctamente', 8);
        } else {
          reject(res.data);
          message.error('Hubo un error al crear la capítulo', 8);
        }
      })
      .catch((err) => {
        message.error('Hubo un error al crear la capítulo', 8);
        reject(err);
      });
  });

export const editChapter = (body) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ...body,
    };
    api
      .post('/api/Presupuesto/UpdCapitulo', requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200 && _.get(res, 'data.ID_ITEM', 0) !== 0) {
          resolve(res.data);
          message.success('Capítulo editado correctamente', 8);
        } else {
          reject(res.data);
          message.error('Hubo un error al editar el capítulo', 8);
        }
      })
      .catch((err) => {
        message.error('Hubo un error al editar el capítulo', 8);
        reject(err);
      });
  });

export const getResourcesAndSubitems = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: GET_RESOURCES_AND_SUBITEMS });
    const ID_OBR = _.get(store.getState(), 'works.selectedWork', '');
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR,
    };
    api
      .post('/api/Presupuesto/GetRecursosSubPartidas', requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch(resourcesAndSubitemsFetched(res.data, ID_OBR));
          resolve(res.data);
        } else {
          dispatch({
            type: GET_RESOURCES_AND_SUBITEMS_ERROR,
          });
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_RESOURCES_AND_SUBITEMS_ERROR,
        });
        reject(err);
      });
  });

const createResourceOrSubitemErrorMessage = (isSubitem) =>
  `Hubo un error al guardar ${isSubitem ? 'la partida' : 'el recurso'}`;

export const createResourceOrSubitem =
  (data, isSubitem = false) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      const ID_OBR = _.get(store.getState(), 'works.selectedWork', '');
      const requestBody = {
        USUARIO: localStorage.getItem('foco_username'),
        EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
        ID_OBR,
        ...data,
      };
      api
        .post(
          `/api/Presupuesto/${isSubitem ? 'SetSubpartidaRecurso' : 'SetRecurso'}`,
          requestBody,
          getHeaders()
        )
        .then((res) => {
          if (res.status === 200 && res.data.ID_ITEM) {
            let responseElement = update(res.data, {
              $unset: ['USUARIO', 'EMPRESA', 'ID_OBR'],
              $merge: { ID_NAT: isSubitem ? 5 : 4 },
            });

            if (isSubitem) {
              responseElement = update(responseElement, {
                children: { $set: responseElement.ITEM_RECURSO },
              });
              responseElement = update(responseElement, {
                $unset: ['ITEM_RECURSO'],
              });
            }

            dispatch(addResourceOrSubitem(responseElement));
            resolve(res.data);
            message.success(
              isSubitem ? 'Partida creada correctamente' : 'Recurso creado correctamente'
            );
          } else {
            createResourceOrSubitemErrorMessage(isSubitem);
            reject(res.data);
          }
        })
        .catch((err) => {
          createResourceOrSubitemErrorMessage(isSubitem);
          reject(err);
        });
    });

export const deleteBudget = (ID_ITEM) => (dispatch) =>
  new Promise((resolve, reject) => {
    const ID_OBR = _.get(store.getState(), 'works.selectedWork', '');
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),

      ID_ITEM,
    };

    api
      .post(`api/Presupuesto/DelItem`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          message.success('Capitulo eliminado correctamente', 4);
          getBudget(ID_OBR)(dispatch);
          resolve();
        } else {
          message.error('Error al eliminar el perfil', 4);
          reject();
        }
      })
      .catch(() => {
        message.error('Error al eliminar el perfil', 4);
        reject();
      });
  });
