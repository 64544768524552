import api, { getHeaders } from '../utils/api';

import { COMMUNES_FETCHED, GET_COMMUNES, GET_COMMUNES_ERROR } from '../reducers/communes';

export const setCommunes = (communes) => ({
  type: COMMUNES_FETCHED,
  communes,
});

export const error = () => ({
  type: GET_COMMUNES_ERROR,
});

export const getCommunes = (client) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: GET_COMMUNES });
    const requestBody = {
      usuario: localStorage.getItem('foco_username'),
      empresa: client,
    };
    api
      .post(`/api/ParametrosGenerales/GetComunas`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch(setCommunes(res.data));
          resolve(res.data);
        } else {
          dispatch(error());
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(error());
        reject(err);
      });
  });
