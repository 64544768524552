import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const FormBox = ({ title, children, className }) => {
  return (
    <div className={`form-box ${className}`}>
      <div className="box-title">
        <span>{title}</span>
      </div>
      {children}
    </div>
  );
};

FormBox.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
};

FormBox.defaultProps = {
  className: '',
};

export default FormBox;
