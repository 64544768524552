import React from 'react';
import { Column, HeaderCell, Cell, ColumnGroup } from 'rsuite-table';
import DoubleLineCell from '../../components/DoubleLineCell';
import { getFormattedNumber } from '../../utils';

import './index.scss';

export const getTotals = (record, key = 'MONTO_PROD_ANT', priceKey = null) => {
  let total = record.TIPO !== 1 ? record[key] * (priceKey ? record[priceKey] : 1) : 0;
  if (record.TIPO === 1) {
    total = record.children.reduce((acc, childrenRecord) => {
      return acc + childrenRecord[key] * (priceKey ? childrenRecord[priceKey] : 1);
    }, 0);
  }
  return total;
};

const columns = () => {
  const columnsResult = [
    <Column
      resizable
      verticalAlign="middle"
      align="left"
      width={250}
      className="border-right align-left-summary"
    >
      <HeaderCell id="summary">Categoría de Cuenta</HeaderCell>
      <Cell dataKey="NOMBRE" />
    </Column>,

    <ColumnGroup align="center" header="Presupuesto">
      {[
        <Column
          resizable
          verticalAlign="middle"
          align="center"
          width={150}
          className="align-right border-right"
        >
          <HeaderCell id="summary-green">
            <DoubleLineCell firstValue="Vigente (Base + Modif." secondValue=" aprobadas)" />
          </HeaderCell>
          <Cell dataKey="PPTO_VIGENTE" className="align-right border-right">
            {(record) => {
              const value = record.PPTO_VIGENTE;
              return (
                <span
                  title={`${getFormattedNumber(value, null, false, '$')}`}
                >{`${getFormattedNumber(value, null, true, '$')}`}</span>
              );
            }}
          </Cell>
        </Column>,
        <Column resizable verticalAlign="middle" align="center" width={150}>
          <HeaderCell>
            <DoubleLineCell
              firstValue="Forecast (presupuesto"
              secondValue="proyectado a término)"
            />
          </HeaderCell>
          <Cell dataKey="PPTO_FORECAST" className="align-right border-right">
            {(record) => {
              const value = record.PPTO_FORECAST;
              return (
                <span
                  title={`${getFormattedNumber(value, null, false, '$')}`}
                >{`${getFormattedNumber(value, null, true, '$')}`}</span>
              );
            }}
          </Cell>
        </Column>,
        <Column resizable verticalAlign="middle" align="center" width={125}>
          <HeaderCell id="summary-green">
            <DoubleLineCell firstValue="Aumento o" secondValue="Disminución" className="mr-4" />
          </HeaderCell>

          <Cell dataKey="PPTO_AUMENTO" className="align-right border-right">
            {(record) => {
              const value = record.PPTO_AUMENTO;
              return (
                <span
                  title={`${getFormattedNumber(value, null, false, '$')}`}
                >{`${getFormattedNumber(value, null, true, '$')}`}</span>
              );
            }}
          </Cell>
        </Column>,
      ]}
    </ColumnGroup>,
    <ColumnGroup align="center" header="Presupuesto Para Gastar">
      {[
        <Column resizable verticalAlign="middle" align="center" width={125}>
          <HeaderCell id="summary-orange">Parcial</HeaderCell>

          <Cell dataKey="PROD_PARCIAL" className="align-right border-right">
            {(record) => {
              const value = record.PROD_PARCIAL;
              return (
                <span
                  title={`${getFormattedNumber(value, null, false, '$')}`}
                >{`${getFormattedNumber(value, null, true, '$')}`}</span>
              );
            }}
          </Cell>
        </Column>,
        <Column resizable verticalAlign="middle" align="center" width={125}>
          <HeaderCell>Acumulado</HeaderCell>
          <Cell dataKey="PROD_ACUM" className="align-right border-right">
            {(record) => {
              const value = record.PROD_ACUM;
              return (
                <span
                  title={`${getFormattedNumber(value, null, false, '$')}`}
                >{`${getFormattedNumber(value, null, true, '$')}`}</span>
              );
            }}
          </Cell>
        </Column>,
      ]}
    </ColumnGroup>,
    <ColumnGroup align="center" header="Costos">
      {[
        <Column resizable verticalAlign="middle" align="center" width={125}>
          <HeaderCell id="summary-orange">Parcial</HeaderCell>

          <Cell dataKey="COSTO_PARCIAL" className="align-right border-right">
            {(record) => {
              const value = record.COSTO_PARCIAL;
              return (
                <span
                  title={`${getFormattedNumber(value, null, false, '$')}`}
                >{`${getFormattedNumber(value, null, true, '$')}`}</span>
              );
            }}
          </Cell>
        </Column>,
        <Column resizable verticalAlign="middle" align="center" width={125}>
          <HeaderCell>Acumulado</HeaderCell>
          <Cell dataKey="COSTO_ACUM" className="align-right border-right">
            {(record) => {
              const total = record.COSTO_ACUM;
              return (
                <span
                  title={`${getFormattedNumber(total, null, false, '$')}`}
                >{`${getFormattedNumber(total, null, true, '$')}`}</span>
              );
            }}
          </Cell>
        </Column>,
      ]}
    </ColumnGroup>,
    <ColumnGroup
      align="center"
      header={<DoubleLineCell firstValue="Ahorro o Perdida" secondValue="Segun Avance" />}
    >
      {[
        <Column resizable verticalAlign="middle" width={127}>
          <HeaderCell id="summary-orange">Parcial</HeaderCell>

          <Cell dataKey="DESV_FECHA_PARCIAL" className="align-right border-right">
            {(record) => {
              const total = record.DESV_FECHA_PARCIAL;
              return (
                <span
                  title={`${getFormattedNumber(total, null, false, '$')}`}
                >{`${getFormattedNumber(total, null, true, '$')}`}</span>
              );
            }}
          </Cell>
        </Column>,
        <Column resizable verticalAlign="middle" width={127}>
          <HeaderCell>Acumulado</HeaderCell>

          <Cell dataKey="DESV_FECHA_ACUM" className="align-right border-right">
            {(record) => {
              const total = record.DESV_FECHA_ACUM;
              return (
                <span
                  title={`${getFormattedNumber(total, null, false, '$')}`}
                >{`${getFormattedNumber(total, null, true, '$')}`}</span>
              );
            }}
          </Cell>
        </Column>,
      ]}
    </ColumnGroup>,
    <ColumnGroup align="center" header="Proyección de Resultado">
      {[
        <Column resizable verticalAlign="middle" align="center" width={127}>
          <HeaderCell id="summary-blue">
            <DoubleLineCell firstValue="Estimado costo" secondValue="del Saldo de Obra" />
          </HeaderCell>

          <Cell dataKey="PROY_TERM_SALDO" className="align-right">
            {(record) => {
              const total = record.PROY_TERM_SALDO;
              return (
                <span
                  title={`${getFormattedNumber(total, null, false, '$')}`}
                >{`${getFormattedNumber(total, null, true, '$')}`}</span>
              );
            }}
          </Cell>
        </Column>,
        <Column resizable verticalAlign="middle" align="center" width={127}>
          <HeaderCell>
            <DoubleLineCell firstValue="Total Costo" secondValue="a Término" />
          </HeaderCell>
          <Cell dataKey="PROY_TERM_TOTAL" className="align-right">
            {(record) => {
              const total = record.PROY_TERM_TOTAL;
              return (
                <span
                  title={`${getFormattedNumber(total, null, false, '$')}`}
                >{`${getFormattedNumber(total, null, true, '$')}`}</span>
              );
            }}
          </Cell>
        </Column>,
        <Column resizable verticalAlign="middle" align="center" width={127}>
          <HeaderCell id="summary-light-blue ">
            <DoubleLineCell firstValue="Ahorro o Perdida" secondValue="(Variación)" />
          </HeaderCell>

          <Cell dataKey="PROY_TERM_DESV" className="align-right">
            {(record) => {
              const total = record.PROY_TERM_DESV;
              return (
                <span
                  title={`${getFormattedNumber(total, null, false, '$')}`}
                >{`${getFormattedNumber(total, null, true, '$')}`}</span>
              );
            }}
          </Cell>
        </Column>,
      ]}
    </ColumnGroup>,
  ];
  return columnsResult;
};

export default columns;
