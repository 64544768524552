/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';

const advanceControlProptypes = {
  auth: PropTypes.shape({
    user: PropTypes.object,
    token: PropTypes.string,
  }).isRequired,
  location: PropTypes.object.isRequired,
  setInformationFromAttendanceURL: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

const getTableWidth = (tableColumns) =>
  tableColumns.reduce(
    (acc, current) =>
      acc + (current.children ? getTableWidth(current.children) : current.width || 100),
    0
  );

const getInitialState = (project) => ({
  works: {
    data: null,
    isFetching: false,
    selected: Number(project),
  },
  gantt: {
    data: {},
    isFetching: false,
  },
  dates: {
    data: {},
    isFetching: false,
  },
});

const getIcons = (dataConfirm) =>
  dataConfirm.some((e) => e.ESTADO === 'Pendiente') ? (
    <i className="fas fa-exclamation-circle warning " />
  ) : (
    <i className="fas fa-check check-color" />
  );

export { getTableWidth, advanceControlProptypes, getInitialState, getIcons };
