import { AUTH_LOGOUT } from './auth';

export const MENU_FETCHED = 'MENU_FETCHED';
export const GET_MENU = 'GET_MENU';
export const GET_MENU_ERROR = 'GET_MENU_ERROR';

const menuInit = {
  data: null,
  isFetching: false,
  error: false,
};

const menu = (menuInfo = menuInit, action) => {
  switch (action.type) {
    case MENU_FETCHED:
      return {
        data: action.menu,
        isFetching: false,
        error: false,
      };
    case GET_MENU_ERROR:
      return {
        data: null,
        isFetching: false,
        error: true,
      };
    case GET_MENU:
      return {
        ...menuInit,
        isFetching: true,
        error: false,
      };
    case AUTH_LOGOUT:
      return menuInit;

    default:
      return menuInfo;
  }
};

export default menu;
