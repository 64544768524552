import update from 'immutability-helper';

export const JOB_COST_FETCHED = 'JOB_COST_FETCHED';
export const GET_JOB_COST = 'GET_JOB_COST';
export const GET_JOB_COST_ERROR = 'GET_JOB_COST_ERROR';

const jobCostInit = {
  data: {},
  isFetching: false,
  error: false,
};

const jobCost = (state = jobCostInit, action) => {
  switch (action.type) {
    case 'WORKS_FETCHED': {
      let newCas = { ...state };
      action.works.forEach(({ ID_OBR }) => {
        newCas = update(newCas, {
          data: {
            [ID_OBR]: { $set: null },
          },
        });
      });

      return newCas;
    }

    case JOB_COST_FETCHED: {
      return update(state, {
        data: {
          [action.workId]: {
            $set: action.jobCost,
          },
        },
        isFetching: { $set: false },
        error: { $set: false },
      });
    }

    case GET_JOB_COST_ERROR:
      return update(state, { error: { $set: true }, isFetching: { $set: false } });
    case GET_JOB_COST:
      return {
        ...state,
        isFetching: true,
        error: false,
      };

    case 'AUTH_LOGOUT':
      return jobCostInit;

    default:
      return state;
  }
};

export default jobCost;
