import _ from 'lodash';
import { message } from 'antd';
import api, { getHeaders } from '../utils/api';
import {
  BASE_BUDGET_OP_FETCHED,
  GET_BASE_BUDGET_OP,
  GET_BASE_BUDGET_OP_ERROR,
  BASE_BUDGET_ADD_MONTH,
  GET_INPUTS_TYPES_GG,
  INPUTS_TYPES_GG_FETCHED,
  GET_INPUTS_TYPES_GG_ERROR,
} from '../reducers/baseBudgetOP';
import store from '../store';

export const baseBudgetOPFetched = (baseBudgetOP, workId, attr) => ({
  type: BASE_BUDGET_OP_FETCHED,
  baseBudgetOP,
  workId,
  attr,
});

export const addMonth = (workId) => (dispatch) =>
  dispatch({
    type: BASE_BUDGET_ADD_MONTH,
    workId,
  });

export const inputsTypesFetched = (inputsTypes) => ({
  type: INPUTS_TYPES_GG_FETCHED,
  inputsTypes,
});

export const getBaseBudgetOP = (ID_OBR) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: GET_BASE_BUDGET_OP });

    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR,
      ID_PPTO_TIPO: 2,
    };
    api
      .post('/api/GastosGenerales/GetPresupuestoGG', requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch(baseBudgetOPFetched(res.data, ID_OBR));
          resolve(res.data);
        } else {
          dispatch({
            type: GET_BASE_BUDGET_OP_ERROR,
          });
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_BASE_BUDGET_OP_ERROR,
        });
        reject(err);
      });
  });

export const setBaseBudgetOP = (body, ID_TIPO) => (dispatch) =>
  new Promise((resolve, reject) => {
    const url = ID_TIPO === 2 ? 'SetPresupuestoGG' : 'SetTraspasarGG';
    const ID_OBR = _.get(store.getState(), 'works.selectedWork', '');
    const errorMessage = 'Error al editar las celdas';
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR,
      ID_PPTO_TIPO: 2,
      ...body,
    };
    api
      .post(`api/GastosGenerales/${url}`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          message.success(
            ID_TIPO === 2
              ? 'Celdas editadas correctamente'
              : 'informacion traspasada correctamente',
            8
          );
          if (ID_TIPO === 2) {
            dispatch(baseBudgetOPFetched(res.data, ID_OBR));
          } else {
            getBaseBudgetOP(ID_OBR)(dispatch);
          }
          resolve(res.data);
        } else {
          message.error(errorMessage);
          reject(res.data);
        }
      })
      .catch((err) => {
        message.error(errorMessage);
        reject(err);
      });
  });

export const getInputsTypes = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: GET_INPUTS_TYPES_GG });

    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_PPTO_TIPO: 2,
    };
    api
      .post(`/api/Presupuesto/GetTipoInsumosGG`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch(inputsTypesFetched(res.data));
          resolve(res.data);
        } else {
          dispatch({
            type: GET_INPUTS_TYPES_GG_ERROR,
          });
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_INPUTS_TYPES_GG_ERROR,
        });
        reject(err);
      });
  });

export const deleteRowBudgetOP = (ID_ITEM) => (dispatch) =>
  new Promise((resolve, reject) => {
    const ID_OBR = _.get(store.getState(), 'works.selectedWork', '');
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_ITEM,
    };
    api
      .post(`api/Presupuesto/DelItem`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          message.success('Fila eliminada correctamente', 4);
          getBaseBudgetOP(ID_OBR)(dispatch);
          resolve();
        } else {
          message.error('Error al eliminar el Fila', 4);
          reject();
        }
      })
      .catch(() => {
        message.error('Error al eliminar el Fila', 4);
        reject();
      });
  });
