import React, { useEffect, useState } from 'react';
import 'devextreme/data/odata/store';
import { Row, Col, Button, Tabs, Form, Input, Switch, Select, message, Modal } from 'antd';
import PropTypes from 'prop-types';
import qs from 'querystring';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { TreeList, Editing, Column, Scrolling } from 'devextreme-react/tree-list';
import { setInformationFromPaymentURL as setInformationFromPaymentURLAction } from '../../actions/auth';
import {
  getPanelList as getPanelListAction,
  setPanel as setPanelAction,
  getUser as getUsrAction,
  getGmailAccount as getGmailAccountAction,
  getProjectUsr as getProjectUsrAction,
  setGmailAccount as setGmailAccountAction,
  setUnlink as setUnlinkAction,
  setPassword as setPasswordAction,
  setUser as setUserAction,
} from '../../actions/configuration';
import './index.scss';
import 'devextreme/excel_exporter';
import logoAvance from '../../images/login/logoAvance.png';
import logoProductividad from '../../images/login/LogoProductividad.png';
import logoCalidad from '../../images/login/LogoCalidad.png';
import logoCostos from '../../images/login/logoCosto.png';
import logoPrevencion from '../../images/login/logoPrevencion.png';
import showPwdImg from '../../images/login/view.png';
import hidePwdImg from '../../images/login/hide.png';

const Configuration = ({
  auth,
  ItemsInRedux,
  getPanelList,
  setPanel,
  getUser,
  getGmailAccount,
  setGmailAccount,
  setUnlink,
  setPassword,
  getProjectUsr,
  setUser,
  location,
  setInformationFromPaymentURL,
}) => {
  const { isPanel, isUser, Account, project } = ItemsInRedux;
  const { TabPane } = Tabs;
  const [form] = Form.useForm();
  const { foco_client, foco_payment_id, foco_token, foco_username } = qs.parse(
    location.search.replace('?', '')
  );
  const [pwd, setPwd] = useState('');
  const [Newpwd, setNewPwd] = useState('');
  const [Reppwd, setRepPwd] = useState('');
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealNewPwd, setIsRevealNewPwd] = useState(false);
  const [isRevealRepPwd, setIsRevealRepPwd] = useState(false);
  const [showEvidence, setShowEvidence] = useState(false);
  const [savingEdition, setSavingEdition] = useState(false);
  useEffect(() => {
    if (foco_token) {
      setInformationFromPaymentURL(foco_client, foco_payment_id, foco_token, foco_username);
      localStorage.setItem('foco_token', foco_token);
    }
  });
  const handleSave = (col, valor, id_columna, id_fila) => {
    if (id_fila === 0) {
      (async () => {
        await setPanel(col, valor, id_columna, id_fila);
        await getPanelList();
      })();
    } else {
      (async () => {
        await setPanel(col, valor, id_columna, id_fila);
        await getPanelList();
      })();
    }
  };

  useEffect(() => {
    if (auth.token && auth.user.username) {
      (async () => {
        await getPanelList();
        await getUser();
        await getGmailAccount();
        await getProjectUsr();
      })();
    }
  }, [auth]);
  const renderSwitch = (cellData) => {
    return (
      <div>
        <Switch
          className="switch"
          width={100}
          disabled={cellData.data.ACTIVO}
          dataSource={cellData.data.VISIBLE}
          checked={cellData.data.VISIBLE}
          onChange={(checked) => {
            handleSave('VISIBLE', checked, cellData.data.ID_COLUMNA, cellData.data.ID_FILA);
          }}
        />
      </div>
    );
  };

  const onCellPrepared = (e) => {
    if (e.rowType === 'data') {
      e.cellElement.style.color = e.data.ID_ARBOL_SUP === -1 ? '#1890ff' : 'black';
      e.cellElement.style.background = e.data.ID_ARBOL_SUP === -1 ? '#f2f2f5' : 'white';
      e.cellElement.style.fontweight = e.data.ID_ARBOL_SUP === -1 ? 'bold' : '100';
    }
  };

  const getHeight = () => {
    return window.innerHeight - 140;
  };

  const onRowUpdating = (e) => {
    let col = '';
    let valor = '';
    let id_columna = '';
    let id_fila = '';
    id_columna = e.oldData.ID_COLUMNA;
    id_fila = e.oldData.ID_FILA;
    if (e.newData.VISIBLE !== undefined) {
      valor = e.newData.VISIBLE;
      col = 'VISIBLE';
    }
    if (e.newData.TOOLTIPS !== undefined) {
      valor = e.newData.TOOLTIPS;
      col = 'TOOLTIPS';
    }
    if (e.newData.TEXTO !== undefined) {
      valor = e.newData.TEXTO;
      col = 'TEXTO_FILA';
    }
    if (e.newData.DESCRIPCION !== undefined) {
      valor = e.newData.DESCRIPCION;
      col = 'DESCRIPCION';
    }

    handleSave(col, valor, id_columna, id_fila);
  };

  const Desvincular = () => {
    setUnlink();
    (async () => {
      await getPanelList();
      await getUser();
      await getGmailAccount();
    })();
  };
  const responseGoogle = (response) => {
    const profile = response.getBasicProfile();
    (async () => {
      await setGmailAccount(
        profile.getEmail(),
        profile.getFamilyName(),
        profile.getName(),
        profile.getId(),
        profile.getImageUrl(),
        1
      );
      await getPanelList();
      await getUser();
      await getGmailAccount();
    })();
  };
  const [isDeleting, setIsDeleting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const inputProps = {
    readOnly: true,
    autoFocus: true,
  };
  const renderCell = (e) => {
    if (e.data.TEXTO.includes('FOCO AVANCE')) {
      return (
        <span style={{ color: 'black', fontSize: '12px' }}>
          <img id="Image1" src={logoAvance} alt="FOCO AVANCE" width="20" height="20" />{' '}
          {e.data.TEXTO}
        </span>
      );
    }
    if (e.data.TEXTO.includes('FOCO PRODUCTIVIDAD')) {
      return (
        <span style={{ color: 'black', fontSize: '12px' }}>
          {' '}
          <img
            id="Image1"
            src={logoProductividad}
            alt="FOCO PRODUCTIVIDAD"
            width="20"
            height="20"
          />
          {e.data.TEXTO}
        </span>
      );
    }
    if (e.data.TEXTO.includes('FOCO PREVENCIÓN')) {
      return (
        <span style={{ color: 'black', fontSize: '12px' }}>
          <img id="Image1" src={logoPrevencion} alt="FOCO COSTOS" width="20" height="20" />{' '}
          {e.data.TEXTO}
        </span>
      );
    }
    if (e.data.TEXTO.includes('FOCO COSTOS')) {
      return (
        <span style={{ color: 'black', fontSize: '12px' }}>
          <img id="Image1" src={logoCostos} alt="FOCO COSTOS" width="20" height="20" />{' '}
          {e.data.TEXTO}
        </span>
      );
    }
    if (e.data.TEXTO.includes('FOCO CALIDAD')) {
      return (
        <span style={{ color: 'black', fontSize: '12px' }}>
          <img id="Image1" src={logoCalidad} alt="FOCO CALIDAD" width="20" height="20" />{' '}
          {e.data.TEXTO}
        </span>
      );
    }
    return <span style={{ color: 'black' }}>{e.data.TEXTO}</span>;
  };
  const onEditorPreparing = (e) => {
    if (e.row.data.ACTIVO === 1) {
      e.editorOptions.disabled = true;
    }
  };
  const submit = (values) => {
    setSavingEdition(true);

    setUser(
      values.FullNameField,
      values.PhoneField,
      values.MailField,
      values.ProjectField,
      isUser.ID_USU_MASTER,
      values.CountryField
    )
      .then(() => {
        setShowEvidence(false);
        setSavingEdition(false);
        if (!showEvidence) {
          message.success('Datos de actualizados correctamente.-');
        }
        if (showEvidence) {
          setPassword(values.newKeyField, isUser.ID_USU_MASTER, values.currentKeyField)
            .then((a) => {
              if (!a.EXITO) {
                message.error(a.MENSAJE);
                setSavingEdition(false);
                setShowEvidence(false);
              } else {
                message.success(
                  'Clave actualizada correctamente, cierre su sesión y vuelva a ingresar.-'
                );
                setShowEvidence(false);
                setSavingEdition(false);
                setSavingEdition(false);
              }
            })
            .catch(() => {
              setShowEvidence(false);
              setSavingEdition(false);
            });
        }
      })
      .catch(() => {
        message.error('Error al actualizar los datos del usuario');
        setIsDeleting(false);
        setSavingEdition(false);
        setShowEvidence(false);
      });
  };
  return (
    <div className="pnf">
      {!project ? (
        <div className="spin">
          <LoadingOutlined />
        </div>
      ) : (
        <>
          <Modal
            title="Advertencia"
            visible={showModal}
            okText="Continuar"
            footer={[
              <Button key="back" onClick={() => setShowModal(false)}>
                Cancelar
              </Button>,
              <Button
                key="submit"
                disabled={isDeleting}
                loading={isDeleting}
                type="primary"
                onClick={() => {
                  setShowEvidence(false);
                  setShowModal(false);
                  form.setFieldsValue({ newKeyField: '' });
                  form.setFieldsValue({ currentKeyField: '' });
                  form.setFieldsValue({ repeatNewKeyField: '' });
                }}
              >
                Continuar
              </Button>,
            ]}
          >
            <p>No se actualizara la contraseña si desactiva esta opción:</p>
            <p>esta seguro de continuar?</p>
          </Modal>
          <Row gutter={[30, 30]}>
            <Col className="production-col mt-3 pt-0" span={24}>
              <Tabs defaultActiveKey="1">
                <TabPane tab="Información de mi cuenta" key="1">
                  <Form
                    initialValues={{
                      requiredMark: 'optional',
                    }}
                    requiredMark="optional"
                    scrollToFirstError
                    form={form}
                    layout="vertical"
                    onFinish={submit}
                  >
                    <Form.Item
                      name="Name"
                      label="Usuario"
                      required
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingrese el Usuario',
                        },
                        {
                          validator: async (obj, value) => {
                            if (value.length && value.trim().length === 0) {
                              return Promise.reject(
                                new Error('No se permiten solo espacios vacios')
                              );
                            }

                            return Promise.resolve();
                          },
                        },
                      ]}
                      initialValue={isUser.NOMBRE_USU || ''}
                    >
                      <Input maxLength={300} disabled />
                    </Form.Item>
                    <Form.Item
                      name="FullNameField"
                      label="Nombre"
                      required
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingrese el nombre del Usuario',
                        },
                        {
                          validator: async (obj, value) => {
                            if (!/^[a-zA-Z\u00C0-\u024F ]*$/.test(value)) {
                              return Promise.reject(
                                new Error('No se permiten números o caracteres especiales')
                              );
                            }

                            if (value.length && value.trim().length === 0) {
                              return Promise.reject(
                                new Error('No se permiten solo espacios vacios')
                              );
                            }

                            return Promise.resolve();
                          },
                        },
                      ]}
                      initialValue={isUser.NOMCOM_USU || ''}
                    >
                      <Input maxLength={300} />
                    </Form.Item>
                    <Form.Item
                      name="MailField"
                      label="Email"
                      required
                      tooltip={{
                        title: 'Favor ingrese un mail con el formato xxx@gmail.com.-',
                        icon: <InfoCircleOutlined />,
                      }}
                      rules={[
                        {
                          type: 'email',
                          required: true,
                          message: 'Por favor ingrese un email válido',
                        },
                        {
                          validator: async (obj, value) => {
                            if (value.length && value.trim().length === 0) {
                              return Promise.reject(
                                new Error('No se permiten solo espacios vacios')
                              );
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                      initialValue={isUser.EMAIL_USU || ''}
                    >
                      <Input maxLength={300} />
                    </Form.Item>
                    <Form.Item
                      name="ProjectField"
                      label="Obra Predeterminada"
                      required
                      rules={[
                        {
                          required: true,
                          message: 'Por favor seleccione un cargo',
                        },
                      ]}
                      initialValue={isUser.ID_ObrPred || ''}
                    >
                      <Select placeholder="Seleccionar...">
                        {project.map((opt) => (
                          <Select.Option value={opt.ID} key={`optionInNewWorkerPosition${opt.ID}`}>
                            {opt.Nombre}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Row>
                      <Col span={3}>
                        <Form.Item
                          name="CountryField"
                          label="Pais"
                          placeholder=""
                          initialValue={
                            isUser.PAIS_CELULAR === '' ? 'cl' : isUser.PAIS_CELULAR || ''
                          }
                        >
                          <PhoneInput
                            onlyCountries={['cl', 'es', 'mx', 'pe', 'co']}
                            maxLength={100}
                            country="cl"
                            placeholder=""
                            inputProps={inputProps}
                            value={isUser.PAIS_CELULAR === '' ? 'cl' : isUser.PAIS_CELULAR}
                            // onChange={phone => this.setState({ phone })}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={20} offset={1}>
                        <Form.Item
                          name="PhoneField"
                          label="Celular"
                          initialValue={isUser.CELULAR || ''}
                          rules={[
                            {
                              validator: async (obj, value) => {
                                if (value.length > 0 && !Number(value)) {
                                  return Promise.reject(
                                    new Error('No se permiten letras o caracteres especiales')
                                  );
                                }

                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Input maxLength={300} value={isUser.CELULAR || ''} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <div className="switchContainer">
                      <Switch
                        name="StateKey"
                        checked={showEvidence}
                        onChange={(e) => {
                          if (
                            !e &&
                            form.getFieldValue('newKeyField') &&
                            form.getFieldValue('newKeyField') &&
                            form.getFieldValue('currentKeyField') &&
                            form.getFieldValue('currentKeyField')
                          ) {
                            setShowModal(true);
                          } else {
                            setShowEvidence(e);
                          }
                        }}
                      />
                      <span> Modificar Contraseña (opcional)</span>
                    </div>
                    <Row>
                      <Col span={23}>
                        <Form.Item
                          tooltip={{
                            title: 'Debe ingresar la clave que actualmente utiliza.-',
                            icon: <InfoCircleOutlined />,
                          }}
                          name="currentKeyField"
                          label="Contraseña Actual"
                          style={showEvidence !== true ? { display: 'none' } : { display: 'block' }}
                          rules={[
                            {
                              message: 'Por favor ingrese su Contraseña',
                            },
                            {
                              validator: async (obj, value) => {
                                if (showEvidence) {
                                  if (value) {
                                    if (value.length && value.trim().length === 0) {
                                      return Promise.reject(
                                        new Error('No se permiten solo espacios vacios')
                                      );
                                    }
                                  } else {
                                    return Promise.reject(
                                      new Error('No se permiten solo espacios vacios')
                                    );
                                  }
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Input
                            placeholder="Contraseña Actual"
                            type={isRevealPwd ? 'text' : 'password'}
                            value={pwd}
                            onChange={(e) => setPwd(e.target.value)}
                          />

                          {/* <Image
                        title={isRevealPwd ? 'Ocultar' : 'Ver'}
                        src={isRevealPwd ? hidePwdImg : showPwdImg}
                        onClick={() => setIsRevealPwd((prevState) => !prevState)}
                      /> */}
                        </Form.Item>
                      </Col>
                      <Col offset={1}>
                        <span
                          style={{ color: 'black', fontSize: '12px', marginLeft: '-40px' }}
                          onClick={() => setIsRevealPwd((prevState) => !prevState)}
                        >
                          <img
                            id="Image1"
                            src={isRevealPwd ? hidePwdImg : showPwdImg}
                            alt="Contraseña Actual"
                            width="20"
                            height="20"
                            style={
                              showEvidence !== true ? { display: 'none' } : { display: 'inline' }
                            }
                          />{' '}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={23}>
                        <Form.Item
                          tooltip={{
                            title: 'Debe ingresar la contraseña nueva.-',
                            icon: <InfoCircleOutlined />,
                          }}
                          name="newKeyField"
                          label="Contraseña Nueva"
                          style={showEvidence !== true ? { display: 'none' } : { display: 'block' }}
                          rules={[
                            {
                              message: 'Por favor ingrese su Contraseña',
                            },
                            {
                              validator: async (obj, value) => {
                                if (showEvidence) {
                                  if (value) {
                                    if (value.length && value.trim().length === 0) {
                                      return Promise.reject(
                                        new Error('No se permiten solo espacios vacios')
                                      );
                                    }
                                  } else {
                                    return Promise.reject(
                                      new Error('No se permiten solo espacios vacios')
                                    );
                                  }
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Input
                            maxLength={300}
                            placeholder="Nueva Contraseña"
                            type={isRevealNewPwd ? 'text' : 'password'}
                            value={Newpwd}
                            onChange={(e) => setNewPwd(e.target.value)}
                          />
                        </Form.Item>
                      </Col>
                      <Col offset={1}>
                        <span
                          style={{ color: 'black', fontSize: '12px', marginLeft: '-40px' }}
                          onClick={() => setIsRevealNewPwd((prevState) => !prevState)}
                        >
                          <img
                            style={
                              showEvidence !== true ? { display: 'none' } : { display: 'inline' }
                            }
                            id="Image1"
                            src={isRevealNewPwd ? hidePwdImg : showPwdImg}
                            alt="Nueva Contraseña"
                            width="20"
                            height="20"
                          />{' '}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={23}>
                        <Form.Item
                          tooltip={{
                            title: 'Debe repetir la contraseña nueva.-',
                            icon: <InfoCircleOutlined />,
                          }}
                          name="repeatNewKeyField"
                          label="Repita su Contraseña"
                          style={showEvidence !== true ? { display: 'none' } : { display: 'block' }}
                          rules={[
                            {
                              message: 'Por favor ingrese su Contraseña',
                            },
                            {
                              validator: async (obj, value) => {
                                if (showEvidence) {
                                  if (value) {
                                    if (value.length && value.trim().length === 0) {
                                      return Promise.reject(
                                        new Error('No se permiten solo espacios vacios')
                                      );
                                    }
                                  } else {
                                    return Promise.reject(
                                      new Error('No se permiten solo espacios vacios')
                                    );
                                  }
                                  const newKey = form.getFieldValue('newKeyField');
                                  if (newKey !== value) {
                                    return Promise.reject(
                                      new Error('La nueva contraseña no son iguales')
                                    );
                                  }
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Input
                            maxLength={300}
                            placeholder="Repetir Nueva Contraseña"
                            type={isRevealRepPwd ? 'text' : 'password'}
                            value={Reppwd}
                            onChange={(e) => setRepPwd(e.target.value)}
                          />
                        </Form.Item>
                      </Col>
                      <Col offset={1}>
                        <span
                          style={{ color: 'black', fontSize: '12px', marginLeft: '-40px' }}
                          onClick={() => setIsRevealRepPwd((prevState) => !prevState)}
                        >
                          <img
                            style={
                              showEvidence !== true ? { display: 'none' } : { display: 'inline' }
                            }
                            id="Image1"
                            src={isRevealRepPwd ? hidePwdImg : showPwdImg}
                            alt="Repetir Nueva Contraseña"
                            width="20"
                            height="20"
                          />{' '}
                        </span>
                      </Col>
                    </Row>
                    {isUser.ACTIVACION_GMAIL === '1' ? (
                      <div className="form-box">
                        <div className="box-title">
                          <span>
                            Puedes utilizar una cuenta de Google para iniciar sesión en nuestra
                            plataforma: Actualmente tu cuenta se encuentra vinculada con la cuenta
                            Google <b>{Account.EMAIL_GMAIL || ''} </b>Si quieres desvincular tu
                            cuenta Google{' '}
                            <Link onClick={Desvincular}>
                              <b>pincha acá </b>
                            </Link>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="form-box">
                        <div className="box-title">
                          <span>
                            Puedes utilizar una cuenta de Google para iniciar sesión en nuestra
                            plataforma, para poder asociar tu cuenta presiona el siguiente enlace :
                          </span>
                        </div>
                        <div id="SinCuenta" className="row-cuenta-vinculado">
                          <GoogleLogin
                            clientId="1059507265687-lb765jeg5esnn4dgenr3k35l6b7haisf.apps.googleusercontent.com"
                            buttonText="Vincula tu cuenta de Google"
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            theme="dark"
                            cookiePolicy="single_host_origin"
                          />
                        </div>
                      </div>
                    )}

                    <div className="modal-footer">
                      <Button
                        disabled={savingEdition}
                        loading={savingEdition}
                        type="primary"
                        htmlType="submit"
                      >
                        Actualizar mis datos
                      </Button>
                    </div>
                  </Form>
                </TabPane>
                <TabPane tab="Configuración cuadro de mando" key="2">
                  <TreeList
                    id="treelist"
                    dataSource={isPanel}
                    keyExpr="ID_ARBOL"
                    parentIdExpr="ID_ARBOL_SUP"
                    rootValue={-1}
                    defaultExpandedRowKeys={[1]}
                    showRowLines="true"
                    showBorders="true"
                    columnAutoWidth="true"
                    width="100%"
                    wordWrapEnabled="true"
                    height={getHeight}
                    onRowUpdating={onRowUpdating}
                    onCellPrepared={onCellPrepared}
                    onEditorPreparing={onEditorPreparing}
                  >
                    <Scrolling mode="virtual" />
                    <Editing mode="cell" allowUpdating="true" startEditAction="click" />
                    <Column
                      dataField="TEXTO"
                      caption="ETIQUETA"
                      width="230"
                      cellRender={renderCell}
                    />
                    <Column
                      dataField="VISIBLE"
                      alignment="center"
                      width="130"
                      dataType="boolean"
                      cellRender={renderSwitch}
                      editCellRender={renderSwitch}
                    />
                    <Column dataField="TOOLTIPS" caption="TOOLTIPS" />
                    <Column dataField="DESCRIPCION" caption="DESCRIPCIÓN" />
                    <Column dataField="ID_FILA" caption="ID_FILA" visible={false} />
                    <Column dataField="ID_COLUMNA" visible={false} caption="ID_COLUMNA" />
                  </TreeList>
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};
Configuration.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.object,
    token: PropTypes.string,
  }).isRequired,
  ItemsInRedux: PropTypes.shape({
    isPanel: PropTypes.array,
    salida: PropTypes.array,
    isUser: PropTypes.array,
    Account: PropTypes.array,
    answer: PropTypes.array,
    unlink: PropTypes.array,
    key: PropTypes.array,
    project: PropTypes.array,
    request: PropTypes.array,
  }).isRequired,
  getPanelList: PropTypes.func.isRequired,
  setPanel: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  getGmailAccount: PropTypes.func.isRequired,
  setGmailAccount: PropTypes.func.isRequired,
  setUnlink: PropTypes.func.isRequired,
  setPassword: PropTypes.func.isRequired,
  getProjectUsr: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  setInformationFromPaymentURL: PropTypes.func.isRequired,
};

export default connect(
  (store) => ({
    auth: store.auth,
    ItemsInRedux: store.Configuration,
  }),
  (dispatch) => ({
    setInformationFromPaymentURL: bindActionCreators(setInformationFromPaymentURLAction, dispatch),
    getPanelList: bindActionCreators(getPanelListAction, dispatch),
    setPanel: bindActionCreators(setPanelAction, dispatch),
    getUser: bindActionCreators(getUsrAction, dispatch),
    getGmailAccount: bindActionCreators(getGmailAccountAction, dispatch),
    setGmailAccount: bindActionCreators(setGmailAccountAction, dispatch),
    setUnlink: bindActionCreators(setUnlinkAction, dispatch),
    setPassword: bindActionCreators(setPasswordAction, dispatch),
    getProjectUsr: bindActionCreators(getProjectUsrAction, dispatch),
    setUser: bindActionCreators(setUserAction, dispatch),
  })
)(Configuration);
