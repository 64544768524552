import React, { useState, useRef, useEffect } from 'react';
import { Input } from 'antd';
import { Cell } from 'rsuite-table';
import PropTypes from 'prop-types';

import './index.scss';

const EditableCell = ({ rowData, dataKey, onSave, min, max, ...restOfProps }) => {
  const [state, setState] = useState({ value: rowData[dataKey], editing: false });

  const inputRef = useRef(null);

  useEffect(() => {
    if (state.editing) {
      inputRef.current.focus();
    }
  }, [state.editing]);

  const toggleEdit = () => {
    setState({ ...state, editing: !state.editing });
  };

  const save = () => {
    onSave(rowData, dataKey, state.value);
    toggleEdit();
  };
  return (
    <div className="create-item-editable-cell">
      <Cell {...restOfProps} className={state.editing ? 'table-content-editing' : ''}>
        {state.editing ? (
          <Input
            ref={inputRef}
            type="number"
            min={min}
            max={max}
            value={state.value}
            onPressEnter={save}
            onBlur={save}
            onFocus={(e) => e.target.select()}
            defaultValue={rowData[dataKey]}
            onChange={(e) => {
              setState({ ...state, value: e.target.value });
            }}
          />
        ) : (
          <div className="editable-cell-value" onClick={toggleEdit}>
            {rowData[dataKey]}
          </div>
        )}
      </Cell>
    </div>
  );
};

EditableCell.propTypes = {
  rowData: PropTypes.object.isRequired,
  dataKey: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
};

EditableCell.defaultProps = {
  min: 1,
  max: 10000000000000000000,
};

export default EditableCell;
