import _ from 'lodash';
import api, { getHeaders } from '../utils/api';
import { CAS_FETCHED, GET_CAS, GET_CAS_ERROR } from '../reducers/cas';
import store from '../store';

export const casFetched = (cas, workId, attr) => ({
  type: CAS_FETCHED,
  cas,
  workId,
  attr,
});

export const getCas = (FECHA_CORTE, ID_OBR, filters = null) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: GET_CAS });

    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR,
      FECHA_CORTE,
      ...(filters || {
        CAPITULOS: [],
        PARTIDAS: [],
      }),
    };
    api
      .post('/api/CostoInformeCas/GetInformeCas', requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch(casFetched(res.data, ID_OBR));
          resolve(res.data);
        } else {
          dispatch({
            type: GET_CAS_ERROR,
          });
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_CAS_ERROR,
        });
        reject(err);
      });
  });
