import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import moment from 'moment';
import { Row, Col } from 'antd';
import 'moment/locale/es';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import update from 'immutability-helper';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import Table from '../../components/TableExtend';
import { getCasCd as getCasCdAction } from '../../actions/casCd';
import columns from './columns';
import { getNodeObjectToUpdate } from '../../utils/budgetAndProductionHelpers';
import { getInputsTypes as getInputsTypesAction } from '../../actions/budget';
import { getAccountOfCost as getAccountOfCostAction } from '../../actions/accountOfCost';

import './index.scss';

moment.locale('es');

const TableCD = ({
  accountOfCostInfo,
  activeOption,
  cancelClicked,
  casCd,
  casDate,
  expandedRowKeysCd,
  getAccountOfCost,
  getCasCd,
  getInputsTypes,
  inputsTypes,
  isEditingCasCd,
  lastSavedTreeCd,
  selectedWork,
  setCancelClicked,
  setExpandeRowKeysCd,
  setFieldsToSave,
  setTree,
  tree,
}) => {
  const [familiesOptions, setFamiliesOptions] = useState(null);

  useEffect(() => {
    if (cancelClicked) {
      setTree(lastSavedTreeCd);
      setCancelClicked(false);
    }
  }, [cancelClicked]);

  const editCell = (id, row) => {
    const fieldToUpdate = {
      ...row,
      PATH: undefined,
      ID_CC_SUP: row.ID_CC_SUP,
    };
    setFieldsToSave((prev) => {
      const index = prev.findIndex((e) => e.ID_CC === row.ID_CC);

      if (index !== -1) {
        return update(prev, {
          [index]: { $merge: fieldToUpdate },
        });
      }
      return update(prev, {
        $push: [fieldToUpdate],
      });
    });
    const path = row.PATH.split('.');
    const updatedNode = getNodeObjectToUpdate(path, 0, { $merge: row });
    setTree(update(tree, updatedNode));
  };
  const { isFetching } = casCd;

  const { data: accountOfCostData, isFetching: isFetchingAccountOfCost } = accountOfCostInfo;

  useEffect(() => {
    if (!accountOfCostData[selectedWork] && !isFetchingAccountOfCost) {
      getAccountOfCost(selectedWork);
    }
    if (!isFetching) {
      getCasCd(casDate || moment().format('DD-MM-YYYY'), selectedWork, activeOption + 1);
    }
  }, [selectedWork, activeOption]);

  useEffect(() => {
    if (!inputsTypes.data && !inputsTypes.isFetching) {
      getInputsTypes();
    } else if (!familiesOptions && inputsTypes.data) {
      setFamiliesOptions(inputsTypes.data);
    }
  }, [inputsTypes]);

  const changeExpandedRows = (expanded, ID_CC) => {
    if (expanded) {
      setExpandeRowKeysCd(
        update(expandedRowKeysCd, {
          $push: [ID_CC],
        })
      );
    } else {
      const index = expandedRowKeysCd.indexOf(ID_CC);

      if (index >= 0) {
        setExpandeRowKeysCd(
          update(expandedRowKeysCd, {
            $splice: [[index, 1]],
          })
        );
      }
    }
  };

  return (
    <div>
      <Row gutter={[30, 30]}>
        <Col className="p-0" span={24}>
          {isFetching ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '70vh',
              }}
            >
              Cargando...
            </div>
          ) : (
            <Table
              className="common-table"
              height={window.innerHeight - 225}
              hover={false}
              rowKey="ID_CC"
              bordered
              headerHeight={75}
              rowHeight={28}
              virtualized
              shouldUpdateScroll={false}
              isTree
              locale={{
                emptyMessage: 'No hay datos para mostrar',
                loading: 'Cargando...',
              }}
              loading={isFetching}
              data={tree || []}
              rowClassName={(record) => {
                if (record) {
                  let type = 'level';
                  if (record.TIPO === 1) {
                    type = 'green-row';
                  } else {
                    type = 'second-level';
                  }
                  if (!get(record, 'children.length')) {
                    type += ' without-children';
                  }
                  return type;
                }
                return '';
              }}
              renderTreeToggle={(_icon, _row, expanded) => {
                if (expanded) {
                  return <CaretUpOutlined />;
                }
                return <CaretDownOutlined />;
              }}
              expandedRowKeys={expandedRowKeysCd}
              onExpandChange={(expanded, rowData) => changeExpandedRows(expanded, rowData.ID_CC)}
            >
              {columns(activeOption, isEditingCasCd, editCell)}
            </Table>
          )}
        </Col>
      </Row>
    </div>
  );
};
TableCD.propTypes = {
  accountOfCostInfo: PropTypes.object.isRequired,
  activeOption: PropTypes.func.isRequired,
  cancelClicked: PropTypes.bool.isRequired,
  casCd: PropTypes.object.isRequired,
  casDate: PropTypes.func.isRequired,
  expandedRowKeysCd: PropTypes.array.isRequired,
  getAccountOfCost: PropTypes.func.isRequired,
  getCasCd: PropTypes.func.isRequired,
  getInputsTypes: PropTypes.func.isRequired,
  inputsTypes: PropTypes.object.isRequired,
  isEditingCasCd: PropTypes.bool.isRequired,
  lastSavedTreeCd: PropTypes.array.isRequired,
  selectedWork: PropTypes.number.isRequired,
  setCancelClicked: PropTypes.func.isRequired,
  setExpandeRowKeysCd: PropTypes.func.isRequired,
  setFieldsToSave: PropTypes.func.isRequired,
  setTree: PropTypes.func.isRequired,
  tree: PropTypes.array.isRequired,
};

export default connect(
  ({ production, works, casCd, accountOfCost, budget: { inputsTypes } }) => ({
    production,
    selectedWork: works.selectedWork,
    casCd,
    accountOfCostInfo: accountOfCost,
    inputsTypes,
  }),
  (dispatch) => ({
    getCasCd: bindActionCreators(getCasCdAction, dispatch),
    getInputsTypes: bindActionCreators(getInputsTypesAction, dispatch),
    getAccountOfCost: bindActionCreators(getAccountOfCostAction, dispatch),
  })
)(TableCD);
