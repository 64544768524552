import React from 'react';
import { Column, HeaderCell, Cell, ColumnGroup } from 'rsuite-table';
import { getFormattedNumber } from '../../utils';
import EditableCell from '../../components/EditableCell/Production';
import DoubleLineCell from '../../components/DoubleLineCell';
import './index.scss';

const columns = (activeEdition, handleSave, activeOptions) => {
  const canEditRate = activeEdition === 'editProjection';
  const columnsResult = [
    <Column
      resizable
      fixed
      verticalAlign="middle"
      align="center"
      className={!activeOptions.option3 ? 'border-right' : ''}
    >
      <HeaderCell>Naturaleza</HeaderCell>
      <Cell dataKey="NOMABR_TIN">
        {(record) => {
          return <span>{record.NOMABR_TIN}</span>;
        }}
      </Cell>
    </Column>,
    ...(activeOptions.option2
      ? [
          <Column
            resizable
            fixed
            verticalAlign="middle"
            align="center"
            width={130}
            className="border-right"
          >
            <HeaderCell>
              <DoubleLineCell firstValue="Segmentación" secondValue=" Recursos" />
            </HeaderCell>
            <Cell dataKey="NOMBRE_CLASIF_ITEM">
              {(record) => {
                return (
                  <span
                    title={`${getFormattedNumber(record.NOMBRE_CLASIF_ITEM)}`}
                  >{`${getFormattedNumber(record.NOMBRE_CLASIF_ITEM, null, true)}`}</span>
                );
              }}
            </Cell>
          </Column>,
        ]
      : []),
    <Column resizable fixed verticalAlign="middle" align="center" width={120}>
      <HeaderCell>
        <DoubleLineCell firstValue="Código" secondValue=" Recurso" />
      </HeaderCell>
      <EditableCell
        keepEditable
        dataIndex="CODIGO_ITEM"
        editable={activeEdition}
        handleSave={handleSave}
        includeRecordFields={['ID_TIPO_PNF', 'PATH', 'ID_CC', 'MESES_ITEM', 'ID_CC_SUP', 'ITEM']}
        type="string"
        conditionToEditable={(record) => record.TIPO === 4}
        customClass="border-right"
        className={() => activeEdition && 'editable'}
      />
    </Column>,
    <Column resizable fixed treeCol verticalAlign="middle" align="left" width={250}>
      <HeaderCell>
        <DoubleLineCell firstValue="Descripción" secondValue=" Recurso" />
      </HeaderCell>
      <EditableCell
        keepEditable
        dataIndex="NOMBRE_ITEM"
        editable={activeEdition}
        handleSave={handleSave}
        includeRecordFields={['NOMBRE_ITEM']}
        type="string"
        conditionToEditable={(record) => record.TIPO === 4}
        className={() => activeEdition && 'editable editable-item'}
        customClass="border-right"
      />
    </Column>,
    <Column resizable verticalAlign="middle" align="center" width={100} className="border-right">
      <HeaderCell>Ud</HeaderCell>
      <Cell dataKey="NOMABR_UNI">
        {(record) => {
          return <span>{record.NOMABR_UNI}</span>;
        }}
      </Cell>
    </Column>,
    <Column resizable verticalAlign="middle" align="center" width={100}>
      <HeaderCell>
        <DoubleLineCell firstValue="Tarifa" secondValue=" Estudio" />
      </HeaderCell>
      <Cell dataKey="PRECIO_ITEM" className="align-right border-right">
        {(record) => {
          return (
            <span title={`$${getFormattedNumber(record.PRECIO_ITEM)}`}>{`${getFormattedNumber(
              record.PRECIO_ITEM,
              null,
              true,
              '$'
            )}`}</span>
          );
        }}
      </Cell>
    </Column>,
    ...(activeOptions.option0
      ? [
          <ColumnGroup align="center" header="Ppto Forecast">
            <Column resizable verticalAlign="middle" align="center" width={100}>
              <HeaderCell>Cantidad</HeaderCell>
              <Cell dataKey="CANT_MOD" className="align-right">
                {(record) => {
                  if (record.ID_NAT === 2) return null;
                  const decimal = record.ID_NAT === 1 ? 1 : 1;
                  return (
                    <span title={`$${getFormattedNumber(record.CANT_MOD)}`}>
                      {getFormattedNumber(record.CANT_MOD, decimal)}
                    </span>
                  );
                }}
              </Cell>
            </Column>

            <Column
              resizable
              verticalAlign="middle"
              align="center"
              width={100}
              className="border-right"
            >
              <HeaderCell>Total $</HeaderCell>
              <Cell dataKey="MONTO_MOD" className="align-right">
                {(record) => {
                  return (
                    <span title={`${getFormattedNumber(record.MONTO_MOD)}`}>{`${getFormattedNumber(
                      record.MONTO_MOD,
                      null,
                      true
                    )}`}</span>
                  );
                }}
              </Cell>
            </Column>
          </ColumnGroup>,
        ]
      : []),

    ...(activeOptions.option1
      ? [
          <ColumnGroup align="center" header="Ppto Vigente">
            <Column resizable verticalAlign="middle" align="center" width={100}>
              <HeaderCell>Cantidad</HeaderCell>
              <Cell dataKey="CANT_VIGENTE" className="align-right">
                {(record) => {
                  if (record.ID_NAT === 2) return null;
                  const decimal = record.ID_NAT === 1 ? 1 : 1;
                  return (
                    <span title={`$${getFormattedNumber(record.CANT_VIGENTE)}`}>
                      {getFormattedNumber(record.CANT_VIGENTE, decimal)}
                    </span>
                  );
                }}
              </Cell>
            </Column>

            <Column resizable verticalAlign="middle" align="center" width={100}>
              <HeaderCell>Total $</HeaderCell>
              <Cell dataKey="MONTO_VIGENTE" className="align-right border-right">
                {(record) => {
                  return (
                    <span
                      title={`${getFormattedNumber(record.MONTO_VIGENTE)}`}
                    >{`${getFormattedNumber(record.MONTO_VIGENTE, null, true)}`}</span>
                  );
                }}
              </Cell>
            </Column>
          </ColumnGroup>,
        ]
      : []),

    <Column resizable verticalAlign="middle" align="left" width={110}>
      <HeaderCell>
        <DoubleLineCell firstValue="Tarifa" secondValue=" Proyectada" />
      </HeaderCell>
      <EditableCell
        keepEditable
        dataIndex="PRECIO_ITEM_PROY"
        editable={canEditRate}
        handleSave={handleSave}
        onlyInteger
        unit="$"
        type="number"
        customClass="align-right border-right"
        className={(record) => {
          let className = activeEdition ? 'editable' : '';
          const tarifaBase = record.PRECIO_ITEM;
          let valueColor = record.PRECIO_ITEM_PROY < tarifaBase ? ' positive' : ' negative';
          if (record.PRECIO_ITEM_PROY === tarifaBase) {
            valueColor = '';
          }
          className += valueColor;
          return className;
        }}
      />
    </Column>,

    <ColumnGroup align="center" header="Estimado Remanente">
      <Column resizable verticalAlign="middle" align="center" width={100}>
        <HeaderCell>Cantidad</HeaderCell>
        <Cell dataKey="" className="align-right">
          {(record) => {
            if (record.ID_NAT === 2) return null;
            const decimal = record.ID_NAT === 1 ? 1 : 1;
            return (
              <span title={`$${getFormattedNumber(record.CANT_VIGENTE)}`}>
                {getFormattedNumber(record.CANT_VIGENTE, decimal)}
              </span>
            );
          }}
        </Cell>
      </Column>

      <Column resizable verticalAlign="middle" align="center" width={100}>
        <HeaderCell>Total $</HeaderCell>
        <Cell dataKey="MONTO_VIGENTE" className="align-right border-right">
          {(record) => {
            return (
              <span title={`${getFormattedNumber(record.MONTO_VIGENTE)}`}>{`${getFormattedNumber(
                record.MONTO_VIGENTE,
                null,
                true
              )}`}</span>
            );
          }}
        </Cell>
      </Column>
    </ColumnGroup>,
  ];
  return columnsResult;
};

export default columns;
