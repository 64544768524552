import update from 'immutability-helper';
import { AUTH_LOGOUT } from './auth';

export const PROJECTS_FETCHED = 'PROJECTS_FETCHED';
export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_PROJECTS_ERROR = 'GET_PROJECTS_ERROR';
export const ADD_NEW_PROJECT = 'ADD_NEW_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const EDIT_PROJECT = 'EDIT_PROJECT';
export const INACTIVE_OR_CLOSE_PROJECT = 'INACTIVE_OR_CLOSE_PROJECT';

const projectsInit = {
  data: null,
  isFetching: true,
  error: false,
};

const projects = (projectsInfo = projectsInit, action) => {
  switch (action.type) {
    case PROJECTS_FETCHED:
      return {
        data: action.projects,
        isFetching: false,
        error: false,
      };
    case GET_PROJECTS_ERROR:
      return {
        data: null,
        isFetching: false,
        error: true,
      };
    case GET_PROJECTS:
      return {
        ...projectsInit,
        isFetching: true,
        error: false,
      };

    case AUTH_LOGOUT:
      return projectsInit;

    case ADD_NEW_PROJECT:
      return update(projectsInfo, {
        data: { $push: [action.project] },
      });

    case EDIT_PROJECT: {
      const projectIndex = projectsInfo.data.findIndex(
        (project) => project.ID_OBR === action.project.ID_OBR
      );

      return update(projectsInfo, {
        data: {
          [projectIndex]: {
            $set: action.project,
          },
        },
      });
    }

    case INACTIVE_OR_CLOSE_PROJECT: {
      const projectIndex = projectsInfo.data.findIndex(
        (project) => project.ID_OBR === action.project.ID_OBR
      );

      if (action.project.code === '0') {
        return update(projectsInfo, {
          data: {
            $splice: [[projectIndex, 1]],
          },
        });
      }

      return update(projectsInfo, {
        data: {
          [projectIndex]: {
            VIGENTE_OBR: {
              $set: action.project.code,
            },
          },
        },
      });
    }

    default:
      return projectsInfo;
  }
};

export default projects;
