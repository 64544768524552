import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LoadingOutlined } from '@ant-design/icons';
import {
  getCollationsType as getCollationsTypeAction,
  getSchedule as getScheduleAction,
} from '../../actions/schedule';
import { getCalendars as getCalendarsAction } from '../../actions/calendars';
import { getWorkers as getWorkersAction } from '../../actions/workers';
import Header from './Header';
import List from './List';
import { normalizeString } from '../../utils';

import './index.scss';

const Schedule = ({
  calendars,
  getCalendars,
  getCollationsType,
  getSchedule,
  getWorkers,
  schedule,
  selectedWork,
  workers,
}) => {
  const [filter, setFilter] = useState('');

  useEffect(() => {
    if (!schedule.collationsType) {
      getCollationsType();
    }
    if (!workers.data) {
      getWorkers();
    }
    getSchedule();
    getCalendars(selectedWork);
  }, [selectedWork]);

  const filteredSchedule = schedule.data
    ? schedule.data.filter(
        (e) =>
          normalizeString(e.NOMBRE_JORNADA).includes(normalizeString(filter)) ||
          normalizeString(e.NOMBRE_CALENDARIO).includes(normalizeString(filter))
      )
    : [];

  return (
    <>
      {!schedule.collationsType || !schedule.data || !calendars.data || !workers.data ? (
        <div className="spin">
          <LoadingOutlined />
        </div>
      ) : (
        <>
          <Header filter={filter} setFilter={setFilter} />
          <List
            collationsType={schedule.collationsType}
            schedules={filteredSchedule}
            workers={workers.data}
          />
        </>
      )}
    </>
  );
};

Schedule.propTypes = {
  calendars: PropTypes.shape({ data: PropTypes.array }).isRequired,
  getCalendars: PropTypes.func.isRequired,
  getCollationsType: PropTypes.func.isRequired,
  getSchedule: PropTypes.func.isRequired,
  getWorkers: PropTypes.func.isRequired,
  schedule: PropTypes.shape({
    collationsType: PropTypes.array,
    data: PropTypes.array,
  }).isRequired,
  selectedWork: PropTypes.number.isRequired,
  workers: PropTypes.shape({ data: PropTypes.array }).isRequired,
};

export default connect(
  (store) => ({
    calendars: store.calendars,
    getWorkers: PropTypes.func.isRequired,
    schedule: store.schedule,
    selectedWork: store.works.selectedWork,
    workers: store.workers,
  }),
  (dispatch) => ({
    getCalendars: bindActionCreators(getCalendarsAction, dispatch),
    getCollationsType: bindActionCreators(getCollationsTypeAction, dispatch),
    getSchedule: bindActionCreators(getScheduleAction, dispatch),
    getWorkers: bindActionCreators(getWorkersAction, dispatch),
  })
)(Schedule);
