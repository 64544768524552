import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LoadingOutlined } from '@ant-design/icons';
import { getAreasAndPositionsAttendance as getAreasAndPositionsAction } from '../../actions/areasAndPositions';
import {
  getSupervisors as getSupervisorsAction,
  getWorkers as getWorkersAction,
} from '../../actions/workers';
import { getSchedule as getScheduleAction } from '../../actions/schedule';
import { normalizeString } from '../../utils';
import Header from './Header';
import List from './List';
import './index.scss';

const Workers = ({
  areasAndPositions,
  getAreasAndPositionsAttendance,
  getSchedule,
  getSupervisors,
  getWorkers,
  schedule,
  selectedWork,
  workers,
  works,
}) => {
  const [filters, setFilters] = useState({
    area: '',
    name: '',
    position: '',
    rut: '',
    supervisorName: '',
  });

  useEffect(() => {
    if (!areasAndPositions.data) {
      getAreasAndPositionsAttendance();
    }

    if (!workers.data) {
      getWorkers();
    }

    if (!workers.supervisors) {
      getSupervisors();
    }
  }, []);

  useEffect(() => {
    getSchedule();
  }, [selectedWork]);

  const handleFilters = (filter, value) =>
    setFilters(
      update(filters, {
        [filter]: { $set: value },
      })
    );
  let filteredWorkers = [];

  if (areasAndPositions.data && workers.data && works) {
    filteredWorkers = workers.data.filter(
      (e) =>
        e.ID_OBR === selectedWork &&
        `${e.RUT_TRA}-${e.DV_TRA}`.includes(filters.rut.replaceAll('.', '')) &&
        normalizeString(e.NOMBRE_CAR).includes(normalizeString(filters.position)) &&
        normalizeString(e.NOMBRE_ARE).includes(normalizeString(filters.area)) &&
        normalizeString(`${e.NOMBRE_TRA} ${e.APELLIDO_TRA}`).includes(
          normalizeString(filters.name)
        ) &&
        workers.supervisors
          .filter((s) =>
            normalizeString(s.NOMBRE_SUP).includes(normalizeString(filters.supervisorName))
          )
          .map((s) => s.ID_SUP)
          .includes(e.ID_SUP)
    );
  }

  return (
    <>
      {!areasAndPositions.data || !workers.data || !workers.supervisors || !schedule.data ? (
        <div className="spin">
          <LoadingOutlined />
        </div>
      ) : (
        <>
          <Header
            handleFilters={handleFilters}
            filters={filters}
            supervisors={workers.supervisors.filter((e) => e.ID_OBR === selectedWork)}
          />
          <List
            supervisors={workers.supervisors.filter((e) => e.ID_OBR === selectedWork)}
            workers={filteredWorkers}
          />
        </>
      )}
    </>
  );
};

Workers.propTypes = {
  areasAndPositions: PropTypes.shape({
    data: PropTypes.array,
    isFetching: PropTypes.bool,
  }).isRequired,
  getAreasAndPositionsAttendance: PropTypes.func.isRequired,
  getSchedule: PropTypes.func.isRequired,
  getSupervisors: PropTypes.func.isRequired,
  getWorkers: PropTypes.func.isRequired,
  schedule: PropTypes.shape({ data: PropTypes.array }).isRequired,
  selectedWork: PropTypes.number.isRequired,
  workers: PropTypes.shape({
    data: PropTypes.array,
    isFetching: PropTypes.bool,
    supervisors: PropTypes.array,
  }).isRequired,
  works: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default connect(
  (store) => ({
    areasAndPositions: store.areasAndPositions,
    schedule: store.schedule,
    selectedWork: store.works.selectedWork,
    workers: store.workers,
    works: store.works.data,
  }),
  (dispatch) => ({
    getAreasAndPositionsAttendance: bindActionCreators(getAreasAndPositionsAction, dispatch),
    getSchedule: bindActionCreators(getScheduleAction, dispatch),
    getSupervisors: bindActionCreators(getSupervisorsAction, dispatch),
    getWorkers: bindActionCreators(getWorkersAction, dispatch),
  })
)(Workers);
