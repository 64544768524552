import update from 'immutability-helper';
import moment from 'moment';
import get from 'lodash/get';

export const PAYMENT_STATE_FETCHED = 'PAYMENT_STATE_FETCHED';
export const GET_PAYMENT_STATE = 'GET_PAYMENT_STATE';
export const GET_PAYMENT_STATE_ERROR = 'GET_PAYMENT_STATE_ERROR';
export const UPDATE_PAYMENT_STATE = 'UPDATE_PAYMENT_STATE';
export const STATES_FETCHED = 'STATES_FETCHED';
export const PAYMENT_STATE_CREATED = 'PAYMENT_STATE_CREATED';
export const EDIT_PAYMENT_STATE_RESUME = 'EDIT_PAYMENT_STATE_RESUME';
export const EDIT_PAYMENT_STATE = 'EDIT_PAYMENT_STATE';
export const EDIT_PAYMENT_STATE_TOTALS = 'EDIT_PAYMENT_STATE_TOTALS';

const paymentStateInit = {
  data: {},
  isFetching: false,
  error: false,
  list: {},
  states: null,
};

const paymentState = (state = paymentStateInit, action) => {
  switch (action.type) {
    case 'WORKS_FETCHED': {
      let newPaymentState = { ...state };
      action.works.forEach(({ ID_OBR }) => {
        newPaymentState = update(newPaymentState, {
          data: {
            [ID_OBR]: { $set: null },
          },
          list: {
            [ID_OBR]: { $set: null },
          },
        });
      });

      return newPaymentState;
    }

    case PAYMENT_STATE_FETCHED: {
      return update(state, {
        [action.attr]: {
          [action.workId]: {
            $set: action.paymentState,
          },
        },
        isFetching: { $set: false },
        error: { $set: false },
      });
    }

    case STATES_FETCHED: {
      return update(state, {
        states: {
          $set: action.states,
        },
        isFetching: { $set: false },
        error: { $set: false },
      });
    }

    case PAYMENT_STATE_CREATED: {
      const data = get(state, `list[${action.workId}]`, []);
      const listIndex = data.findIndex((e) =>
        moment(action.paymentState.FECHA_EP, 'DD-MM-YYYY').isAfter(moment(e.FECHA_EP, 'DD-MM-YYYY'))
      );

      const updateToDo =
        listIndex === -1
          ? {
              $push: [action.paymentState],
            }
          : {
              $splice: [[listIndex, 0, action.paymentState]],
            };

      return update(state, {
        list: {
          [action.workId]: updateToDo,
        },
      });
    }

    case GET_PAYMENT_STATE_ERROR:
      return update(state, { error: { $set: true }, isFetching: { $set: false } });
    case GET_PAYMENT_STATE:
      return {
        ...state,
        isFetching: true,
        error: false,
      };

    case EDIT_PAYMENT_STATE_RESUME: {
      const index = state.list[action.workId].findIndex((e) => e.ID_EP_ENC === action.id);
      return update(state, {
        list: {
          [action.workId]: {
            [index]: {
              $merge: action.parameters,
            },
          },
        },
      });
    }

    case EDIT_PAYMENT_STATE: {
      const index = state.list[action.workId].findIndex(
        (e) => e.ID_EP_ENC === action.body.ID_EP_ENC
      );

      const { NOMBRE_ESTADO_EP } = state.states.find(
        (e) => e.ID_ESTADO_EP === action.body.ID_ESTADO_EP
      );

      const dataToMerge = {
        NOMBRE_ESTADO_EP,
        ...action.body,
      };
      return update(state, {
        list: {
          [action.workId]: {
            [index]: {
              $merge: dataToMerge,
            },
          },
        },
      });
    }

    case EDIT_PAYMENT_STATE_TOTALS: {
      const index = state.list[action.workId].findIndex(
        (e) => e.ID_EP_ENC === action.body.ID_EP_ENC
      );

      return update(state, {
        list: {
          [action.workId]: {
            [index]: {
              $merge: action.body,
            },
          },
        },
      });
    }

    case 'AUTH_LOGOUT':
      return paymentStateInit;

    default:
      return state;
  }
};

export default paymentState;
