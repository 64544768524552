import React, { useState, useEffect } from 'react';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import { Button, Row, Col, Card, Checkbox, InputNumber, DatePicker, Modal } from 'antd';

import './index.scss';

const checkBoxKeys = [
  { key: 'NUM_VINC', title: 'Solor Act. sin vinculo con ppto.' },
  { key: 'PRC_AVA', title: 'Ocultar Actividades al 100%' },
  { key: 'HITO', title: 'Ocultar hitos' },
  { key: 'PRC_DESV_QA', title: 'Solo Act. con diferencia entre FocoCalidad y FocoAvance' },
  { key: 'NUM_REST', title: 'Solo Actividades con Restricción' },
  { key: 'NUM_REST_VENC', title: 'Solo Actividades con Restricción Vencida' },
  { key: 'ID_TIPO_RESTRICCION', title: 'Solo Actividades con Impactos' },
];

const initialFilters = {
  RUTA_LINEA_BASE: false,
  RUTA_CONTROL: false,
  NUM_VINC: false,
  PRC_AVA: false,
  HITO: false,
  PRC_DESV_QA: false,
  NUM_REST: false,
  NUM_REST_VENC: false,
  PRC_DESV: false,
};

const initialInputFilters = {
  HOLGURA_TOT: {
    min: null,
    max: null,
  },
  HOLGURA_TOT_PROY: {
    min: null,
    max: null,
  },
  PRC_AVA_INPUTS: {
    min: null,
    max: null,
  },
  PRC_PROG: {
    min: null,
    max: null,
  },
};

const initialDateFilter = {
  min: null,
  max: null,
};

const ListFilter = ({
  onClick,
  onClose,
  show,
  popupMode,
  onSave,
  classNameToPrimaryButton,
  classNameToFirstButton,
  disableButton,
  reset,
}) => {
  const [filters, setFilters] = useState(initialFilters);
  const [lastSavedFilters, setLastSavedFilters] = useState(initialFilters);
  const [inputFilters, setInputFilters] = useState(initialInputFilters);
  const [lastSavedInputFilters, setlastSavedInputFilters] = useState(initialInputFilters);
  const [dateFilter, setDateFilter] = useState(initialDateFilter);
  const [lastSavedDateFilter, setlastSavedDateFilter] = useState(initialDateFilter);

  const onChangeCheckbox = (key, checked) => {
    setFilters(
      update(filters, {
        [key]: { $set: checked },
        ...(key === 'ID_TIPO_RESTRICCION'
          ? {
              RITMO_EST: { $set: checked },
            }
          : {}),
      })
    );
  };

  const onInputChange = (key, subkey, value) => {
    setInputFilters(
      update(inputFilters, {
        [key]: {
          [subkey]: { $set: value },
        },
      })
    );
  };

  useEffect(() => {
    if (reset) {
      setFilters(initialFilters);
      setLastSavedFilters(initialFilters);
      setInputFilters(initialInputFilters);
      setlastSavedInputFilters(initialInputFilters);
      setDateFilter(initialDateFilter);
      setlastSavedDateFilter(initialDateFilter);
    }
  }, [reset]);

  const onDatePickerChange = (key, value) => {
    setDateFilter(
      update(dateFilter, {
        [key]: { $set: value },
      })
    );
  };

  return (
    <div className="advanced-filter">
      <Button
        className={`main-button ${classNameToPrimaryButton} ${classNameToFirstButton}`}
        onClick={() => onClick()}
        type="default"
        disabled={disableButton}
      >
        Filtros Avanzados
      </Button>
      <Modal
        title="Filtros Avanzados"
        className="advance-filters-modal"
        visible={show && !disableButton}
        width={600}
        onCancel={() => {
          onClose();
          setFilters(lastSavedFilters);
          setInputFilters(lastSavedInputFilters);
          setDateFilter(lastSavedDateFilter);
        }}
        footer={null}
      >
        <div className={`options-container ${!popupMode ? 'not-popup' : ''}`}>
          <div className="view-options-section">
            <Card size="small" title="Filtros Generales">
              <Row className="px-4 py-2 mt-2">
                {checkBoxKeys.map(({ key, title }) => (
                  <Col md={12} className="my-2">
                    <Checkbox
                      checked={filters[key]}
                      onChange={(e) => onChangeCheckbox(key, e.target.checked)}
                    >
                      {title}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Card>
            <Card className="mt-3" size="small" title="FILTROS LINEA BASE">
              <Row className="px-4 py-2 mt-2">
                <Col md={24} className="my-2">
                  <Checkbox
                    checked={filters.RUTA_LINEA_BASE}
                    onChange={(e) => onChangeCheckbox('RUTA_LINEA_BASE', e.target.checked)}
                  >
                    Ruta Crítica Línea Base
                  </Checkbox>
                </Col>
                <Col md={8} className="my-2">
                  <Checkbox
                    checked={filters.HOLGURA_TOT}
                    onChange={(e) => onChangeCheckbox('HOLGURA_TOT', e.target.checked)}
                  >
                    Holgura entre
                  </Checkbox>
                </Col>
                <Col md={14} className="my-2 d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <InputNumber
                      value={inputFilters.HOLGURA_TOT.min}
                      disabled={!filters.HOLGURA_TOT}
                      onChange={(e) => onInputChange('HOLGURA_TOT', 'min', e)}
                      className="mr-2"
                    />{' '}
                    y{' '}
                    <InputNumber
                      value={inputFilters.HOLGURA_TOT.max}
                      disabled={!filters.HOLGURA_TOT || inputFilters.HOLGURA_TOT.min === null}
                      onChange={(e) => onInputChange('HOLGURA_TOT', 'max', e)}
                      className="ml-2"
                    />
                  </div>
                </Col>
              </Row>
            </Card>
            <Card className="mt-3" size="small" title="FILTROS PROGRAMA CONTROLADO">
              <Row className="px-4 py-2 mt-2">
                <Col md={12} className="my-2">
                  <Checkbox
                    value={inputFilters.HOLGURA_TOT.max}
                    checked={filters.RUTA_CONTROL}
                    onChange={(e) => onChangeCheckbox('RUTA_CONTROL', e.target.checked)}
                  >
                    Ruta Crítica Progr. Controlada
                  </Checkbox>
                </Col>
                <Col md={12} className="my-2">
                  <Checkbox
                    checked={filters.PRC_DESV}
                    onChange={(e) => onChangeCheckbox('PRC_DESV', e.target.checked)}
                  >
                    Actividades Atrasadas
                  </Checkbox>
                </Col>
                <Col md={12} className="my-2">
                  <Checkbox
                    checked={filters.HOLGURA_TOT_PROY}
                    onChange={(e) => onChangeCheckbox('HOLGURA_TOT_PROY', e.target.checked)}
                  >
                    Holguras proyectadas entre:
                  </Checkbox>
                </Col>
                <Col md={12} className="my-2 d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <InputNumber
                      value={inputFilters.HOLGURA_TOT_PROY.min}
                      disabled={!filters.HOLGURA_TOT_PROY}
                      onChange={(e) => onInputChange('HOLGURA_TOT_PROY', 'min', e)}
                      className="mr-2"
                    />{' '}
                    y{' '}
                    <InputNumber
                      value={inputFilters.HOLGURA_TOT_PROY.max}
                      disabled={
                        !filters.HOLGURA_TOT_PROY || inputFilters.HOLGURA_TOT_PROY.min === null
                      }
                      onChange={(e) => onInputChange('HOLGURA_TOT_PROY', 'max', e)}
                      className="ml-2"
                    />
                  </div>
                </Col>
                <Col md={12} className="my-2">
                  <Checkbox
                    checked={dateFilter.checked}
                    onChange={(e) => {
                      setDateFilter(
                        update(dateFilter, {
                          checked: { $set: e.target.checked },
                        })
                      );
                    }}
                  >
                    Considerar partidas con % de avance prog. entre
                  </Checkbox>
                </Col>
                <Col md={12} className="my-2 d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <DatePicker
                      disabled={!dateFilter.checked}
                      value={dateFilter.min}
                      placeholder="dd-mm-aaaa"
                      format="DD-MM-YYYY"
                      className="mr-2"
                      onChange={(date) => onDatePickerChange('min', date)}
                    />{' '}
                    y{' '}
                    <DatePicker
                      disabled={!dateFilter.checked}
                      value={dateFilter.max}
                      placeholder="dd-mm-aaaa"
                      format="DD-MM-YYYY"
                      className="mr-2"
                      onChange={(date) => onDatePickerChange('max', date)}
                    />
                  </div>
                </Col>
                <Col md={12} className="my-2">
                  <Checkbox
                    checked={filters.PRC_AVA_INPUTS}
                    onChange={(e) => onChangeCheckbox('PRC_AVA_INPUTS', e.target.checked)}
                  >
                    Considerar partidas con % de avance real entre
                  </Checkbox>
                </Col>
                <Col md={12} className="my-2 d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <InputNumber
                      value={inputFilters.PRC_AVA_INPUTS.min}
                      disabled={!filters.PRC_AVA_INPUTS}
                      onChange={(e) => onInputChange('PRC_AVA_INPUTS', 'min', e)}
                      className="mr-2"
                    />{' '}
                    y{' '}
                    <InputNumber
                      value={inputFilters.PRC_AVA_INPUTS.max}
                      disabled={!filters.PRC_AVA_INPUTS || inputFilters.PRC_AVA_INPUTS.min === null}
                      onChange={(e) => onInputChange('PRC_AVA_INPUTS', 'max', e)}
                      className="ml-2"
                    />
                  </div>
                </Col>
                <Col md={12} className="my-2">
                  <Checkbox
                    checked={filters.PRC_PROG}
                    onChange={(e) => onChangeCheckbox('PRC_PROG', e.target.checked)}
                  >
                    Considerar partidas con avance proy. entre
                  </Checkbox>
                </Col>
                <Col md={12} className="my-2 d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <InputNumber
                      value={inputFilters.PRC_PROG.min}
                      disabled={!filters.PRC_PROG}
                      onChange={(e) => onInputChange('PRC_PROG', 'min', e)}
                      className="mr-2"
                    />{' '}
                    y{' '}
                    <InputNumber
                      value={inputFilters.PRC_PROG.max}
                      disabled={!filters.PRC_PROG || inputFilters.PRC_PROG.min === null}
                      onChange={(e) => onInputChange('PRC_PROG', 'max', e)}
                      className="ml-2"
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </div>
          <div className="bottom-buttons mt-4">
            <Button
              type="default"
              onClick={() => {
                onClose();
                setFilters(lastSavedFilters);
                setInputFilters(lastSavedInputFilters);
                setDateFilter(lastSavedDateFilter);
              }}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => {
                onSave(filters, inputFilters, dateFilter);
                setLastSavedFilters(filters);
                setlastSavedInputFilters(inputFilters);
                setlastSavedDateFilter(dateFilter);
              }}
              type="primary"
              className={classNameToPrimaryButton}
            >
              Aplicar
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

ListFilter.propTypes = {
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  popupMode: PropTypes.bool,
  onSave: PropTypes.func,
  classNameToPrimaryButton: PropTypes.string,
  classNameToFirstButton: PropTypes.string,
  disableButton: PropTypes.bool,
  reset: PropTypes.bool.isRequired,
};

ListFilter.defaultProps = {
  popupMode: true,
  onSave: () => {},
  classNameToPrimaryButton: '',
  classNameToFirstButton: '',
  disableButton: false,
};

export default ListFilter;
