/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, message, Tooltip, Input, Checkbox } from 'antd';
import update from 'immutability-helper';
import { TreeList } from '@progress/kendo-react-treelist';
import api, { getHeaders } from '../../../utils/api';

import './index.scss';

const EditableCell = ({ name, save }) => {
  const [value, setValue] = useState(name);

  return (
    <td className="editable-name-cell k-border-right">
      <Input
        value={value}
        onBlur={() => save(value)}
        onPressEnter={() => save(value)}
        onChange={(e) => setValue(e.target.value)}
      />
    </td>
  );
};

const SetValorizationInfo = ({
  valorizationAdvance,
  client,
  user,
  getValorizationAdvance,
  selectedWork,
}) => {
  const [show, setShow] = useState(false);
  const [saving, setSaving] = useState(false);
  const [fieldsToSave, setFieldsToSave] = useState([]);

  const updateDataToSave = (id, value, key) => {
    const index = fieldsToSave.findIndex((item) => item.ID_VAL === id);
    if (index !== -1) {
      setFieldsToSave(update(fieldsToSave, { [index]: { [key]: { $set: value } } }));
    } else {
      setFieldsToSave(
        update(fieldsToSave, {
          $push: [{ [key]: value, ID_VAL: id }],
        })
      );
    }
  };

  const save = async () => {
    const isAllDisabled =
      fieldsToSave.every((e) => e.ESTADO_VAL === 2) &&
      valorizationAdvance.data[selectedWork].every(
        (e) => e.ESTADO_VAL === 2 || fieldsToSave.find((field) => field.ID_VAL === e.ID_VAL)
      );
    if (isAllDisabled) {
      message.warning('Debe dejar activo al menos un tipo de valorización');
    } else {
      setSaving(true);
      const requestBody = {
        USUARIO: user,
        EMPRESA: client,
        EDICION: fieldsToSave,
        ID_OBR: selectedWork,
      };
      const response = await api.post('api/Avances/SetAvaValorizacion', requestBody, getHeaders());
      if (response.status === 200) {
        message.success('Información actualizada correctamente');
        getValorizationAdvance();
        setFieldsToSave([]);
        setTimeout(() => setShow(false), 200);
      } else {
        message.error('Ocurrió un error al actualizar la información');
      }
      setSaving(false);
    }
  };

  return (
    <>
      <Tooltip
        placement="topLeft"
        destroyTooltipOnHide={{ keepParent: false }}
        title="Editar Tipo de Valorización"
      >
        <Button
          disabled={valorizationAdvance.isFetching}
          onClick={() => {
            setShow(true);
          }}
        >
          <i className="fa fa-cog" />
        </Button>
      </Tooltip>
      <Modal
        title="Editar Tipo de Valorización"
        visible={show}
        footer={null}
        className="new-advance-modal"
        onCancel={() => setShow(false)}
        width={600}
      >
        <div className="set-valorization-type">
          <TreeList
            className={`kendo-table ${saving || valorizationAdvance.isFetching ? 'loading' : ' '}`}
            style={{
              height: `300px`,
              overflow: 'auto',
              width: `560px`,
            }}
            resizable
            tableProps={{
              style: {
                tableLayout: 'fixed',
                width: 545,
              },
            }}
            noRecords="No data available"
            rowHeight={28}
            data={valorizationAdvance.data[selectedWork]}
            expandField="expanded"
            subItemsField="children"
            onExpandChange={() => {}}
            columns={[
              {
                field: 'NOMBRE_VAL',
                title: 'Activo',
                width: 80,
                cell: ({ dataItem }) => {
                  const editableCellValue =
                    (fieldsToSave.find((e) => e.ID_VAL === dataItem.ID_VAL) || {}).ESTADO_VAL ||
                    dataItem.ESTADO_VAL;
                  return (
                    <td className="k-border-right text-align-center">
                      <Checkbox
                        checked={editableCellValue === 1}
                        onChange={(e) =>
                          updateDataToSave(dataItem.ID_VAL, e.target.checked ? 1 : 2, 'ESTADO_VAL')
                        }
                      />
                    </td>
                  );
                },
              },
              {
                field: 'NOMBRE_VAL',
                title: 'Nombre',
                cell: ({ dataItem }) => {
                  const editableCellValue =
                    (fieldsToSave.find((e) => e.ID_VAL === dataItem.ID_VAL) || {}).NOMBRE_VAL ||
                    dataItem.NOMBRE_VAL;
                  return (
                    <EditableCell
                      save={(value) => updateDataToSave(dataItem.ID_VAL, value, 'NOMBRE_VAL')}
                      name={editableCellValue}
                    />
                  );
                },
              },
              {
                field: 'DESCRIPCION_VAL',
                title: 'Descripción',
                cell: ({ dataItem }) => {
                  return <td className="k-border-right">{dataItem.DESCRIPCION_VAL}</td>;
                },
              },
            ]}
          />
        </div>
        <div className="footer-buttons">
          <Button
            disabled={saving}
            type="button"
            onClick={() => {
              setShow(false);
              setFieldsToSave([]);
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => (fieldsToSave.length ? save() : setShow(false))}
            loading={saving}
            type="primary"
          >
            Guardar
          </Button>
        </div>
      </Modal>
    </>
  );
};

SetValorizationInfo.propTypes = {
  getValorizationAdvance: PropTypes.func.isRequired,
  valorizationAdvance: PropTypes.object.isRequired,
  client: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired,
  selectedWork: PropTypes.number.isRequired,
};

export default SetValorizationInfo;
