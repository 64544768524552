import React, { useState } from 'react';
import { Button, DatePicker, Form, Input, Modal, Select } from 'antd';
import { connect } from 'react-redux';
import { validarRUT } from 'validar-rut';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { getFormattedRut, normalizeStringToApi } from '../../../utils';
import {
  setWorker as setWorkerAction,
  updateWorker as updateWorkerAction,
} from '../../../actions/workers';
import './index.scss';

const NewWorker = ({
  areasAndPositions,
  infoToEdit,
  onClose,
  schedule,
  selectedWork,
  setWorker,
  supervisors,
  title,
  updateWorker,
  workers,
  works,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [selectedArea, setSelectedArea] = useState(infoToEdit.ID_ARE || null);
  const [selectedProject, setSelectedProject] = useState(selectedWork);
  const [form] = Form.useForm();

  const areas = areasAndPositions
    .filter((e) => e.ID_OBR === selectedProject)
    .map((e) => ({ ID_ARE: e.ID_ARE, NOMBRE_ARE: e.NOMBRE_ARE, positions: e.CARGOS_ASOCIADOS }));

  const submit = (values) => {
    const {
      area,
      endDate,
      file,
      position,
      project,
      rut,
      schedule: ID_JORNADA,
      startDate,
      supervisor,
      workerFirstName,
      workerLastName,
    } = values;
    setIsSaving(true);
    const onlyNumbersInRUT = rut.replaceAll('.', '').replaceAll('-', '');
    const areaInfo = areas.find((e) => e.ID_ARE === area);

    const data = {
      ...(endDate && { FECFIN_TRA: moment(endDate).format('DD/MM/YYYY') }),
      ...(startDate && { FECING_TRA: moment(startDate).format('DD/MM/YYYY') }),
      APELLIDO_TRA: normalizeStringToApi(workerLastName) || '',
      DV_TRA: onlyNumbersInRUT.slice(onlyNumbersInRUT.length - 1, onlyNumbersInRUT.length),
      ID_ARE: area,
      ID_CAR: position,
      ID_JORNADA: ID_JORNADA || 0,
      ID_OBR: Number(project),
      ID_SUP: supervisor || 0,
      ID_TRA: infoToEdit.ID_TRA || 0,
      NOMBRE_ARE: areaInfo.NOMBRE_ARE,
      // NOMBRE_CAR: areaInfo.positions.find((e) => e.ID_CAR === position).NOMBRE_CAR,
      NOMBRE_OBR: works.find((e) => Number(e.ID_OBR) === selectedProject).NOMBRE_OBR,
      NOMBRE_TRA: normalizeStringToApi(workerFirstName),
      NUMFICHA_TRA: file || '',
      RUT_TRA: onlyNumbersInRUT.slice(0, -1),
      SUELDO_TRA: 0,
    };

    if (infoToEdit.ID_TRA) {
      updateWorker(data)
        .then(() => {
          onClose();
          setIsSaving(false);
        })
        .catch(() => {
          setIsSaving(false);
        });
    } else {
      setWorker(data)
        .then(() => {
          onClose();
          setIsSaving(false);
        })
        .catch(() => {
          setIsSaving(false);
        });
    }
  };

  const isWrongRut = (value) =>
    Boolean(
      workers &&
        workers.some(
          (e) =>
            `${e.RUT_TRA}${e.DV_TRA}` === value.replaceAll('.', '').replaceAll('-', '') &&
            e.ID_OBR === Number(form.getFieldValue('project'))
        )
    );

  return (
    <div className="areaContainer">
      <Modal
        title={title}
        visible
        onOk={() => onClose()}
        onCancel={() => onClose()}
        width={400}
        footer={null}
        wrapClassName="modalContainer"
      >
        <Form layout="vertical" form={form} onFinish={submit}>
          <Form.Item
            label="RUT"
            name="rut"
            rules={[
              {
                required: true,
                message: 'Por favor ingrese un RUT valido',
              },
              {
                validator: async (obj, value) => {
                  if (value && !validarRUT(value)) {
                    return Promise.reject(new Error('Por favor ingrese un RUT válido'));
                  }
                  if (!infoToEdit.RUT_TRA && isWrongRut(value)) {
                    return Promise.reject(new Error('Ya existe un trabajador con este RUT'));
                  }
                  return Promise.resolve();
                },
              },
            ]}
            initialValue={
              infoToEdit.RUT_TRA ? getFormattedRut(`${infoToEdit.RUT_TRA}${infoToEdit.DV_TRA}`) : ''
            }
          >
            <Input
              disabled={Boolean(infoToEdit.RUT_TRA)}
              placeholder="12345678-5"
              onBlur={() => {
                const rut = form.getFieldValue('rut');
                if (rut && validarRUT(rut) && !isWrongRut(rut)) {
                  form.setFieldsValue({ rut: getFormattedRut(rut) });
                }
              }}
            />
          </Form.Item>

          <Form.Item
            name="workerFirstName"
            label="Nombre Trabajador"
            rules={[
              {
                required: true,
                message: 'Por favor ingrese el nombre del trabajador',
              },
              {
                validator: async (obj, value) => {
                  if (!/^[a-zA-Z\u00C0-\u024F s,]*$/.test(value)) {
                    return Promise.reject(
                      new Error('No se permiten números o caracteres especiales')
                    );
                  }

                  if (value.length && value.trim().length === 0) {
                    return Promise.reject(new Error('No se permiten solo espacios vacios'));
                  }

                  return Promise.resolve();
                },
              },
            ]}
            initialValue={infoToEdit.NOMBRE_TRA || ''}
          >
            <Input maxLength={300} />
          </Form.Item>

          <Form.Item
            name="workerLastName"
            label="Apellido Trabajador"
            rules={[
              {
                required: false,
              },
              {
                validator: async (obj, value) => {
                  if (!/^[a-zA-Z\u00C0-\u024F ]*$/.test(value)) {
                    return Promise.reject(
                      new Error('No se permiten números o caracteres especiales')
                    );
                  }
                  if (value.length && value.trim().length === 0) {
                    return Promise.reject(new Error('No se permiten solo espacios vacios'));
                  }

                  return Promise.resolve();
                },
              },
            ]}
            initialValue={infoToEdit.APELLIDO_TRA || ''}
          >
            <Input maxLength={300} />
          </Form.Item>

          <Form.Item
            name="project"
            label="Proyecto"
            rules={[
              {
                required: true,
                message: 'Por favor seleccione un proyecto',
              },
            ]}
            initialValue={String(selectedProject)}
          >
            <Select
              placeholder="Seleccionar..."
              onChange={(e) => {
                setSelectedProject(Number(e));
                setSelectedArea(null);
                form.setFieldsValue({ area: null, position: null });
              }}
            >
              {works.map((opt) => (
                <Select.Option value={opt.ID_OBR} key={`optionInNewWorker${opt.ID_OBR}`}>
                  {opt.NOMBRE_OBR}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="area"
            label="Área"
            rules={[
              {
                required: true,
                message: 'Por favor seleccione un área',
              },
            ]}
            initialValue={infoToEdit.ID_ARE || ''}
          >
            <Select
              placeholder="Seleccionar..."
              onChange={(e) => {
                setSelectedArea(e);
                form.setFieldsValue({ position: null });
              }}
            >
              {selectedProject &&
                areas.map((opt) => (
                  <Select.Option value={opt.ID_ARE} key={`optionInNewWorker${opt.ID_ARE}`}>
                    {opt.NOMBRE_ARE}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="position"
            label="Cargo"
            rules={[
              {
                required: true,
                message: 'Por favor seleccione un cargo',
              },
            ]}
            initialValue={infoToEdit.ID_CAR || ''}
          >
            <Select placeholder="Seleccionar..." disabled={!selectedArea}>
              {selectedProject &&
                selectedArea &&
                areas
                  .find((e) => e.ID_ARE === selectedArea)
                  .positions.map((opt) => (
                    <Select.Option
                      value={opt.ID_CAR}
                      key={`optionInNewWorkerPosition${opt.ID_CAR}`}
                    >
                      {opt.NOMBRE_CAR}
                    </Select.Option>
                  ))}
            </Select>
          </Form.Item>

          <Form.Item name="file" label="N° Ficha" initialValue={infoToEdit.NUMFICHA_TRA || ''}>
            <Input maxLength={30} />
          </Form.Item>

          <div className="dates">
            <Form.Item
              label="Inicio Contrato"
              name="startDate"
              rules={[
                {
                  validator: async (obj, value) => {
                    const startDate = moment(value).format('YYYY-MM-DD');
                    const endDate = form.getFieldValue('endDate');
                    const formattedEndDate = endDate && moment(endDate).format('YYYY-MM-DD');

                    if (endDate && formattedEndDate < startDate) {
                      return Promise.reject(
                        new Error('La fecha de inicio no puede ser mayor a la fecha de fin')
                      );
                    }

                    return Promise.resolve();
                  },
                },
              ]}
              initialValue={
                infoToEdit.FECING_TRA ? moment(infoToEdit.FECING_TRA, 'DD/MM/YYYY') : ''
              }
            >
              <DatePicker placeholder="dd/mm/aaaa" format="DD/MM/YYYY" />
            </Form.Item>

            <Form.Item
              label="Fin Contrato"
              name="endDate"
              rules={[
                {
                  validator: async (obj, value) => {
                    const endDate = moment(value).format('YYYY-MM-DD');
                    const startDate = form.getFieldValue('startDate');
                    const formattedStartDate = startDate && moment(startDate).format('YYYY-MM-DD');

                    if (startDate && formattedStartDate > endDate) {
                      return Promise.reject(
                        new Error('La fecha de fin no puede ser menor a la fecha de inicio')
                      );
                    }

                    return Promise.resolve();
                  },
                },
              ]}
              initialValue={
                infoToEdit.FECFIN_TRA ? moment(infoToEdit.FECFIN_TRA, 'DD/MM/YYYY') : ''
              }
            >
              <DatePicker placeholder="dd/mm/aaaa" format="DD/MM/YYYY" />
            </Form.Item>
          </div>

          <Form.Item name="supervisor" label="Supervisor" initialValue={infoToEdit.ID_SUP || ''}>
            <Select placeholder="Seleccionar...">
              {supervisors.map((supervisor) => (
                <Select.Option value={supervisor.ID_SUP} key={`supervisor-${supervisor.ID_SUP}`}>
                  {supervisor.NOMBRE_SUP}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {/* <Form.Item
            name="schedule"
            label="Jornada"
            initialValue={infoToEdit.ID_JORNADA || ''}
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Select placeholder="Seleccionar...">
              {schedule.map((e) => (
                <Select.Option value={e.ID_JORNADA} key={`supervisor-${e.ID_JORNADA}`}>
                  {e.NOMBRE_JORNADA}
                </Select.Option>
              ))}
            </Select>
          </Form.Item> */}

          <div className="buttons">
            <Form.Item>
              <Button disabled={isSaving} onClick={() => onClose()}>
                Cancelar
              </Button>
            </Form.Item>
            <Form.Item>
              <Button disabled={isSaving} loading={isSaving} type="primary" htmlType="submit">
                Aceptar
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

NewWorker.defaultProps = {
  infoToEdit: {},
  supervisors: [],
  title: 'Nuevo Trabajador',
};

NewWorker.propTypes = {
  areasAndPositions: PropTypes.array.isRequired,
  infoToEdit: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  schedule: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedWork: PropTypes.number.isRequired,
  setWorker: PropTypes.func.isRequired,
  supervisors: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  updateWorker: PropTypes.func.isRequired,
  workers: PropTypes.arrayOf(PropTypes.object).isRequired,
  works: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default connect(
  (store) => ({
    areasAndPositions: store.areasAndPositions.data,
    schedule: store.schedule.data,
    selectedWork: store.works.selectedWork,
    workers: store.workers.data,
    works: store.works.data,
  }),
  (dispatch) => ({
    setWorker: bindActionCreators(setWorkerAction, dispatch),
    updateWorker: bindActionCreators(updateWorkerAction, dispatch),
  })
)(NewWorker);
