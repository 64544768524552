import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { logout as logoutAction } from '../../actions/auth';

import './index.scss';

const Logout = ({ logout, history }) => {
  useEffect(() => {
    logout();
    setTimeout(() => history.push('/login'), 2000);
  }, []);

  return (
    <div className="logout">
      <div className="spinner-border" role="status" />
    </div>
  );
};

Logout.propTypes = {
  logout: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default connect(
  (store) => ({
    clients: store.clients,
  }),
  (dispatch) => ({
    logout: bindActionCreators(logoutAction, dispatch),
  })
)(Logout);
