import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Radio } from 'antd';

import './index.scss';

const ViewOption = ({ updateOption, onClick, onClose, options, show, activeOptionProp }) => {
  const [activeOption, setActiveOption] = useState(0);
  const [lastSavedOption, setLastSavedOption] = useState(0);

  useEffect(() => {
    setActiveOption(activeOptionProp);
    setLastSavedOption(activeOptionProp);
  }, [activeOptionProp]);

  useEffect(() => {
    setActiveOption(lastSavedOption);
  }, [show]);

  return (
    <div className="view-options">
      <Button onClick={() => onClick()} type="Default">
        <i className="far fa-eye mr-2" /> Vista
      </Button>
      {show && (
        <div className="options-container">
          <div className="options-header">
            <div />
            <span>Opciones de vista</span>
            <i className="fa fa-times" onClick={() => onClose()} />
          </div>
          <div className="options">
            <Radio.Group onChange={(e) => setActiveOption(e.target.value)} value={activeOption}>
              {options.map((key, index) => (
                <Radio value={index}>{key}</Radio>
              ))}
            </Radio.Group>
          </div>
          <div className="buttons">
            <Button
              type="default"
              onClick={() => {
                onClose();
                setActiveOption(lastSavedOption);
              }}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => {
                updateOption(activeOption);
                setLastSavedOption(activeOption);
                onClose();
              }}
              type="primary"
            >
              Aplicar
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

ViewOption.propTypes = {
  updateOption: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  activeOptionProp: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
};

export default ViewOption;
