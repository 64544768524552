/* eslint-disable no-restricted-globals */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Cell } from 'rsuite-table';
import { Input, Form, Button, Modal } from 'antd';
import { getValueWithUnit } from '../../utils/budgetAndProductionHelpers';
import './index.scss';

let modalResult = null;
const { confirm: confirmModal } = Modal;

const EditableCell = ({
  editable,
  dataIndex,
  editButtonCallback,
  rowData,
  handleSave,
  includeRecordFields,
  unit,
  canShow,
  type,
  validator,
  additionalInputFields,
  objectModifier,
  conditionToEditable,
  className,
  calculateValue,
  isUnitPrefix,
  maxButtonValue,
  editItem,
  decimalsIndex,
  onlyInteger,
  customClass,
  keepEditable,
  confirm,
  conditionToConfirm,
  ...restProps
}) => {
  const [editing, setEditing] = useState(keepEditable || false);
  const [focused, setFocused] = useState(false);

  const inputRef = useRef(null);
  const [form] = Form.useForm();
  const [edited, setEdited] = useState(false);

  const calculatedValue = calculateValue(rowData);
  const originalValue = rowData[dataIndex] || calculatedValue;
  useEffect(() => {
    if (keepEditable && editable) {
      form.setFieldsValue({
        [dataIndex]: originalValue,
      });
    }
  }, [keepEditable, editable]);

  useEffect(() => {
    if (!editable) {
      setEdited(false);
    }
  }, [editable]);

  const toggleEdit = useCallback(() => {
    setEditing(!editing);

    if (!editing) {
      setTimeout(() => {
        if (inputRef && !get(inputRef, 'current.state.focused')) inputRef.current.focus();
      }, 100);
    }

    form.setFieldsValue({
      [dataIndex]: originalValue,
    });
  }, [editing, rowData]);

  const finalDecimalsIndex =
    typeof decimalsIndex === 'function' ? decimalsIndex(rowData) : decimalsIndex;

  const finalOnlyInteger = typeof onlyInteger === 'function' ? onlyInteger(rowData) : onlyInteger;

  const save = (event, isEnterPressed, needsConfirm = !!confirm) => {
    if (event) {
      event.preventDefault();
    }
    const values = form.getFieldsValue();
    const value = values[dataIndex];

    if (needsConfirm && conditionToConfirm(value, rowData)) {
      if (modalResult) modalResult.destroy();
      modalResult = confirmModal({
        ...confirm,
        onOk: () => {
          save(event, isEnterPressed, false);
        },
        onCancel: () => {
          form.setFieldsValue({
            [dataIndex]: originalValue,
          });
          setEditing(false);
        },
      });
    } else {
      if (!keepEditable) {
        toggleEdit();
      } else if (isEnterPressed) {
        const inputs = Array.prototype.slice.call(document.querySelectorAll('input'));
        const index = (inputs.indexOf(document.activeElement) + 1) % inputs.length;
        const input = inputs[index];
        input.focus();
        input.select();
      }
      setFocused(false);
      // eslint-disable-next-line no-restricted-globals
      let isValidType = type === 'number' && !isNaN(value);

      if (type === 'string') {
        isValidType = true;
      }

      let additionalFields = {};

      includeRecordFields.forEach((field) => {
        additionalFields[field] = rowData[field];
      });

      if (
        (value || (!value && type !== 'number')) &&
        isValidType &&
        value !== originalValue &&
        validator(value, rowData)
      ) {
        let finalValue = value;

        if (type === 'number') {
          finalValue = Number(value);
        }

        setEdited(true);
        additionalFields = objectModifier(additionalFields, rowData, finalValue);

        const dataToEdit = [
          {
            ID_ITEM: rowData.ID_ITEM,
            [dataIndex]: finalValue,
            PATH: rowData.PATH,
            ...additionalFields,
          },
        ];

        const editedItem = editItem(rowData, finalValue);

        if (editedItem) dataToEdit.push(editedItem);

        handleSave(dataToEdit);
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      save(null, true);
    }
  };

  const getChildrenContent = () => {
    const cellValue = rowData[dataIndex] !== undefined ? rowData[dataIndex] : calculatedValue;
    let children = getValueWithUnit(
      unit,
      cellValue,
      isUnitPrefix,
      type,
      finalDecimalsIndex,
      finalOnlyInteger
    );

    const valueToShow = getValueWithUnit(
      unit,
      cellValue,
      isUnitPrefix,
      type,
      finalDecimalsIndex,
      finalOnlyInteger
    );

    if (editable && conditionToEditable(rowData)) {
      const handleFocus = (event) => {
        event.target.select();
        setFocused(true);
      };

      const editButton = (
        <i
          className="fa fa-edit"
          onMouseDown={(e) => {
            e.stopPropagation();
            editButtonCallback(rowData[dataIndex], rowData);
          }}
        />
      );

      const maxButton = (
        <Button
          type="default"
          className="max-button"
          onMouseDown={(e) => {
            e.stopPropagation();
            form.setFieldsValue({
              [dataIndex]: maxButtonValue(rowData),
            });
            save();
          }}
        >
          MAX
        </Button>
      );

      children = editing ? (
        <div className="editable-form-item-wrapper" style={{ position: 'relative' }}>
          <Form form={form} component={false}>
            <Form.Item className="editable-form-item" name={dataIndex}>
              <Input
                ref={inputRef}
                onBlur={save}
                style={
                  keepEditable && type === 'number' ? { opacity: focused ? 1 : 0, zIndex: 3 } : {}
                }
                onFocus={handleFocus}
                onKeyDown={handleKeyDown}
                className={`${keepEditable ? 'editable' : ''} ${
                  keepEditable && edited ? 'edited' : ''
                }`}
                {...additionalInputFields(rowData)}
              />
            </Form.Item>
          </Form>
          {keepEditable && !focused && type === 'number' && (
            <div className="value-mask">{valueToShow}</div>
          )}
          {editButtonCallback ? editButton : null}
          {maxButtonValue ? maxButton : null}
        </div>
      ) : (
        <div className="editable-cell-value-wrap" onClick={() => toggleEdit()}>
          {!isNaN(children) ? valueToShow : children}
          {editButtonCallback ? editButton : null}
        </div>
      );
    } else {
      children = valueToShow;
    }

    return children;
  };

  if (!canShow(rowData)) {
    return <Cell dataKey={dataIndex}>{null}</Cell>;
  }

  return (
    <Cell
      className={`table-editable-cell ${customClass} ${
        className(rowData) && conditionToEditable(rowData) ? className(rowData) : ''
      } ${edited ? 'edited' : ''}`}
      {...restProps}
      rowData={rowData}
    >
      {getChildrenContent()}
    </Cell>
  );
};

EditableCell.propTypes = {
  editable: PropTypes.bool.isRequired,
  dataIndex: PropTypes.string.isRequired,
  editButtonCallback: PropTypes.func,
  rowData: PropTypes.object.isRequired,
  handleSave: PropTypes.func.isRequired,
  includeRecordFields: PropTypes.array,
  unit: PropTypes.string,
  canShow: PropTypes.bool,
  type: PropTypes.string,
  validator: PropTypes.func,
  additionalInputFields: PropTypes.func,
  objectModifier: PropTypes.func,
  conditionToEditable: PropTypes.func,
  className: PropTypes.func,
  calculateValue: PropTypes.func,
  isUnitPrefix: PropTypes.bool,
  maxButtonValue: PropTypes.number,
  editItem: PropTypes.func,
  decimalsIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
  onlyInteger: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
  customClass: PropTypes.string,
  keepEditable: PropTypes.bool,
  confirm: PropTypes.object,
  conditionToConfirm: PropTypes.func,
};

EditableCell.defaultProps = {
  editButtonCallback: null,
  includeRecordFields: [],
  unit: '',
  canShow: () => true,
  type: 'number',
  validator: () => true,
  additionalInputFields: () => ({}),
  objectModifier: (obj) => obj,
  conditionToEditable: () => true,
  className: () => '',
  calculateValue: () => '',
  isUnitPrefix: true,
  maxButtonValue: '',
  editItem: () => {},
  decimalsIndex: null,
  onlyInteger: false,
  customClass: '',
  keepEditable: false,
  confirm: null,
  conditionToConfirm: () => false,
};

export default EditableCell;
