import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Column, HeaderCell, Cell } from 'rsuite-table';
import Table from '../../components/TableExtend';
import { SearchIcon, normalizeString } from '../../utils';
import { getUsers as getUsersAction } from '../../actions/users';
import { getProfile as getProfileAction } from '../../actions/Profile';
import { getProjects as getProjectsAction } from '../../actions/projects';

import InputWithTopLabel from '../../components/InputWithTopLabel';
import EditModal from './EditModal';

import './index.scss';

const getCell = (key, name, width = 150) => {
  return (
    <Column
      resizable
      width={width}
      verticalAlign="middle"
      align="center"
      sortable
      className="border-right"
    >
      <HeaderCell>{name}</HeaderCell>
      <Cell wordWrap dataKey={key}>
        {(record) => {
          return record[key];
        }}
      </Cell>
    </Column>
  );
};

const Users = ({ users, getUsers, getProfile, profile, projects, getProjects, access }) => {
  const [state, setState] = useState({
    data: null,
    active: null,
    activeRecord: null,
  });
  const { isFetching, data: usersData } = users;
  const { activeRecord } = state;

  const [sortState, setSortState] = useState({
    dataSort: usersData || [],
  });

  const { dataSort } = sortState;

  const getData = () => {
    const { dataSort: dataSortGet, sortColumn, sortType } = sortState;

    if (sortColumn && sortType) {
      return dataSortGet.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if (typeof x === 'string') {
          x = x.charCodeAt();
        }
        if (typeof y === 'string') {
          y = y.charCodeAt();
        }
        if (sortType === 'asc') {
          return x - y;
        }
        return y - x;
      });
    }

    return dataSortGet;
  };

  const handleSortColumn = (sortColumn, sortType) => {
    setTimeout(() => {
      setSortState({
        ...sortState,
        sortColumn,
        sortType,
        loading: false,
      });
    }, 500);
  };

  useEffect(() => {
    if (!isFetching && !usersData) {
      getUsers();
    } else if (usersData && !isFetching) {
      setSortState(
        update(sortState, {
          dataSort: { $set: usersData },
        })
      );
    }
  }, [users]);

  useEffect(() => {
    if (!profile.data) {
      getProfile();
    }
    if (!projects.data) {
      getProjects();
    }
  }, []);

  const handleSearch = (value) => {
    if (value !== '') {
      const newData = dataSort.filter((record) => {
        return [
          'nombre',
          'ap_mat',
          'ap_pat',
          'nom_usuario',
          'perfil',
          'rut',
          'CANT_PROYECTOS',
          'ULTIMO_ACCESO',
          'CANT_PERFILES',
          'PAGINAS_ACTIVAS',
        ].some((item) => {
          let val = record[item];

          val = normalizeString(`${val}`);
          return val.indexOf(normalizeString(value)) !== -1;
        });
      });
      setSortState(update(sortState, { dataSort: { $set: newData } }));
    } else if (value === '') {
      setSortState(update(sortState, { dataSort: { $set: usersData } }));
    }
  };

  return (
    <div className="users">
      <Row gutter={[30, 30]}>
        <Col className="top-col" span={6}>
          <InputWithTopLabel
            icon={<SearchIcon />}
            onChange={(value) => handleSearch(value, 'globj')}
            label="Buscar usuario"
            className="jobcost-search"
          />
        </Col>
      </Row>
      <Table
        sortColumn={sortState.sortColumn}
        sortType={sortState.sortType}
        onSortColumn={handleSortColumn}
        className="common-table mt-3 pt-0"
        height={window.innerHeight - 300}
        hover={false}
        rowKey="ID"
        wordWrap
        bordered
        headerHeight={50}
        loading={isFetching}
        rowHeight={38}
        virtualized
        shouldUpdateScroll={false}
        locale={{
          emptyMessage: 'No hay datos para mostrar',
          loading: 'Cargando...',
        }}
        data={getData()}
      >
        {!!access.CONTROL_USUARIOS_EDITAR && (
          <Column
            resizable
            width={60}
            verticalAlign="middle"
            align="center"
            className="border-right"
          >
            <HeaderCell />
            <Cell wordWrap>
              {(record) => {
                return (
                  <i
                    className="fas fa-edit"
                    onClick={() => {
                      setState(update(state, { activeRecord: { $set: record } }));
                    }}
                  />
                );
              }}
            </Cell>
          </Column>
        )}
        {getCell('nombre', 'Nombre', 150, true)}

        <Column
          resizable
          width={200}
          verticalAlign="middle"
          align="center"
          sortable
          className="border-right"
        >
          <HeaderCell>Apellido</HeaderCell>
          <Cell wordWrap dataKey="ap_pat">
            {(record) => {
              return `${record.ap_pat} ${record.ap_mat}`;
            }}
          </Cell>
        </Column>
        {getCell('nom_usuario', 'Email', 200, true)}
        <Column
          resizable
          width={120}
          verticalAlign="middle"
          align="center"
          className="border-right"
        >
          <HeaderCell>Acceso CAS Online</HeaderCell>
          <Cell wordWrap>
            {(record) => {
              return record.ACCESO_CAS ? 'Si' : 'No';
            }}
          </Cell>
        </Column>
        {getCell('CANT_PROYECTOS', 'Proyectos', 100, true)}
        {getCell('CANT_PERFILES', 'Perfiles', 80, true)}
        {getCell('PAGINAS_ACTIVAS', 'Pag. Activas', 120, true)}
        {getCell('ULTIMO_ACCESO', 'Último Acceso', 180, true)}
      </Table>
      <EditModal
        activeRecord={activeRecord}
        onCancel={() => setState(update(state, { activeRecord: { $set: null } }))}
        profile={profile}
        projects={projects}
      />
    </div>
  );
};

Users.propTypes = {
  users: PropTypes.object.isRequired,
  getUsers: PropTypes.func.isRequired,
  getProfile: PropTypes.func.isRequired,
  getProjects: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  projects: PropTypes.object.isRequired,
  access: PropTypes.bool.isRequired,
};

export default connect(
  ({ users, profile, projects }) => ({
    users,
    profile,
    projects,
  }),
  (dispatch) => ({
    getUsers: bindActionCreators(getUsersAction, dispatch),
    getProfile: bindActionCreators(getProfileAction, dispatch),
    getProjects: bindActionCreators(getProjectsAction, dispatch),
  })
)(Users);
