import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import moment from 'moment';
import { Row, Col } from 'antd';
import * as FileSaver from 'file-saver';
import ExcelJS from 'exceljs';
import 'moment/locale/es';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import update from 'immutability-helper';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import Table from '../../components/TableExtend';
import { getSummaryCas as getSummaryCasAction } from '../../actions/SummaryCas';
import columnsSummaryCas from './columnsSummaryCas';
import { getInputsTypes as getInputsTypesAction } from '../../actions/budget';

import './index.scss';

moment.locale('es');

const SummaryCas = ({
  selectedWork,
  summaryCas,
  getSummaryCas,
  getInputsTypes,
  inputsTypes,
  activeOption,
  casDate,
}) => {
  const [tree, setTree] = useState(null);
  const [expandedRowKeys, setExpandeRowKeys] = useState([]);
  const [familiesOptions, setFamiliesOptions] = useState(null);

  const { data, isFetching } = summaryCas;

  useEffect(() => {
    getSummaryCas(casDate, selectedWork, activeOption + 1);
  }, [selectedWork, activeOption, casDate]);

  useEffect(() => {
    if (data[selectedWork]) {
      const treeData = data[selectedWork].RESUMEN_CAS;
      setTree(treeData);
    }
  }, [data]);

  useEffect(() => {
    if (!inputsTypes.data && !inputsTypes.isFetching) {
      getInputsTypes();
    } else if (!familiesOptions && inputsTypes.data) {
      setFamiliesOptions(inputsTypes.data);
    }
  }, [inputsTypes]);

  const changeExpandedRows = (expanded, ID_CC) => {
    if (expanded) {
      setExpandeRowKeys(
        update(expandedRowKeys, {
          $push: [ID_CC],
        })
      );
    } else {
      const index = expandedRowKeys.indexOf(ID_CC);

      if (index >= 0) {
        setExpandeRowKeys(
          update(expandedRowKeys, {
            $splice: [[index, 1]],
          })
        );
      }
    }
  };

  return (
    <div className="">
      <Row gutter={[30, 30]}>
        <Col className="p-0" span={24}>
          {isFetching ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '70vh',
              }}
            >
              Cargando...
            </div>
          ) : (
            <Table
              className="common-table column-color"
              height={window.innerHeight - 225}
              hover={false}
              rowKey="ID_FILA"
              bordered
              headerHeight={75}
              rowHeight={28}
              virtualized
              shouldUpdateScroll={false}
              isTree
              locale={{
                emptyMessage: 'No hay datos para mostrar',
                loading: 'Cargando...',
              }}
              loading={isFetching}
              data={tree || []}
              rowClassName={(record) => {
                if (record) {
                  let type = 'level';
                  if (record.TIPO === 1) {
                    type = 'green-row';
                  } else {
                    type = 'second-level';
                  }
                  if (!get(record, 'children.length')) {
                    type += ' without-children';
                  }
                  if (record.BOLD === 1) {
                    type = 'bold';
                  }
                  return type;
                }
                return '';
              }}
              renderTreeToggle={(_icon, _row, expanded) => {
                if (expanded) {
                  return <CaretUpOutlined />;
                }
                return <CaretDownOutlined />;
              }}
              expandedRowKeys={expandedRowKeys}
              onExpandChange={(expanded, rowData) => changeExpandedRows(expanded, rowData.ID_CC)}
            >
              {columnsSummaryCas(activeOption)}
            </Table>
          )}
        </Col>
      </Row>
    </div>
  );
};
SummaryCas.propTypes = {
  selectedWork: PropTypes.number.isRequired,
  summaryCas: PropTypes.object.isRequired,
  getSummaryCas: PropTypes.func.isRequired,
  getInputsTypes: PropTypes.func.isRequired,
  inputsTypes: PropTypes.object.isRequired,
  activeOption: PropTypes.func.isRequired,
  casDate: PropTypes.func.isRequired,
};

export default connect(
  ({ production, works, summaryCas, accountOfCost, budget: { inputsTypes } }) => ({
    production,
    selectedWork: works.selectedWork,
    summaryCas,
    accountOfCostInfo: accountOfCost,
    inputsTypes,
  }),
  (dispatch) => ({
    getSummaryCas: bindActionCreators(getSummaryCasAction, dispatch),
    getInputsTypes: bindActionCreators(getInputsTypesAction, dispatch),
  })
)(SummaryCas);
