import React, { useState } from 'react';
import update from 'immutability-helper';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Modal, Button, DatePicker, Form } from 'antd';
import { createPnf as createPnfAction } from '../../../actions/pnf';

import './index.scss';

const AddPNF = ({ isFetchingList, listData, createPnf }) => {
  const [state, setState] = useState({
    show: false,
    saving: false,
    advanceDates: [],
  });

  const [form] = Form.useForm();

  const { show, saving } = state;
  const submit = (values) => {
    setState(update(state, { saving: { $set: true } }));
    const requestBody = {
      FECHA_PNF: moment(values.FECHA_PNF).format('DD-MM-YYYY'),
      ID_AVA_ENC: values.ID_AVA_ENC || 0,
    };
    const stateUpdates = { saving: { $set: false }, show: { $set: false } };
    createPnf(requestBody)
      .then(() => {
        setState(update(state, stateUpdates));
        form.resetFields();
      })
      .catch(() => {
        setState(update(state, stateUpdates));
      });
  };

  return (
    <div className="add-pnf">
      <Button
        className="add-pnf-button"
        disabled={isFetchingList}
        loading={isFetchingList}
        onClick={() =>
          setState(
            update(state, {
              show: { $set: true },
            })
          )
        }
        type="primary"
      >
        <i className="fa fa-plus" /> &nbsp;Nuevo PNF
      </Button>
      <Modal
        closable={false}
        wrapClassName="common-modal add-pnf-modal"
        visible={show}
        width={700}
        onCancel={() => {
          setState(
            update(state, {
              show: { $set: false },
            })
          );
        }}
        footer={null}
        okText="Guardar"
      >
        <div className="modal-container">
          <div className="modal-header">
            <div />
            <span>Nuevo PNF</span>
            <i
              className="fa fa-times"
              onClick={() => {
                setState(
                  update(state, {
                    show: { $set: false },
                  })
                );
              }}
            />
          </div>
          <div className="form-body">
            <Form form={form} layout="vertical" onFinish={submit}>
              <Form.Item
                label="Ingresar Fecha de PNF"
                name="FECHA_PNF"
                required
                initialValue={moment()}
                rules={[
                  {
                    required: true,
                    message: 'Por favor seleccione una fecha',
                  },
                  {
                    validator: async (_obj, value) => {
                      if (value) {
                        const isRepeatedDate = listData.some(
                          (e) => e.FECHA_PNF === value.format('DD-MM-YYYY')
                        );
                        if (isRepeatedDate) {
                          return Promise.reject(
                            new Error('Ya se esta usando esta fecha en otro PNF')
                          );
                        }
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <DatePicker placeholder="dd-mm-aaaa" format="DD-MM-YYYY" />
              </Form.Item>
              <div className="modal-footer">
                <Button
                  disabled={saving}
                  onClick={() => {
                    setState(
                      update(state, {
                        show: { $set: false },
                      })
                    );
                    form.resetFields();
                  }}
                >
                  Cancelar
                </Button>
                <Button loading={saving} disabled={saving} type="primary" htmlType="submit">
                  Agregar
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

AddPNF.propTypes = {
  isFetchingList: PropTypes.bool.isRequired,
  listData: PropTypes.array.isRequired,
  createPnf: PropTypes.func.isRequired,
};

export default connect(
  ({ contractCurrency, production, works }) => ({
    contractCurrency,
    production,
    selectedWork: works.selectedWork,
  }),
  (dispatch) => ({
    createPnf: bindActionCreators(createPnfAction, dispatch),
  })
)(AddPNF);
