import update from 'immutability-helper';

export const RATE_FETCHED = 'RATE_FETCHED';
export const GET_RATE = 'GET_RATE';
export const GET_RATE_ERROR = 'GET_RATE_ERROR';
export const RATE_MONTH = 'RATE_MONTH';
export const GET_INPUTS_TYPES_RATE = 'GET_INPUTS_TYPES_RATE';
export const GET_INPUTS_TYPES_RATE_ERROR = 'GET_INPUTS_TYPES_RATE_ERROR';
export const INPUTS_TYPES_RATE_FETCHED = 'INPUTS_TYPES_RATE_FETCHED';

const rateInit = {
  data: {},
  isFetching: false,
  error: false,
};

const rate = (state = rateInit, action) => {
  switch (action.type) {
    case 'WORKS_FETCHED': {
      let newCas = { ...state };
      action.works.forEach(({ ID_OBR }) => {
        newCas = update(newCas, {
          data: {
            [ID_OBR]: { $set: null },
          },
        });
      });

      return newCas;
    }

    case RATE_FETCHED: {
      return update(state, {
        data: {
          [action.workId]: {
            $set: action.rate,
          },
        },
        isFetching: { $set: false },
        error: { $set: false },
      });
    }

    case GET_RATE_ERROR:
      return update(state, { error: { $set: true }, isFetching: { $set: false } });

    case GET_RATE:
      return {
        ...state,
        isFetching: true,
        error: false,
      };

    case 'AUTH_LOGOUT':
      return rateInit;

    default:
      return state;
  }
};

export default rate;
