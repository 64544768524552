import React, { useState, useEffect, useCallback } from 'react';
import { Modal, message } from 'antd';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import api, { getHeaders } from '../../../utils/api';
import './index.scss';

const PhotosInfo = ({ record, onCancel, user, client, date }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const getData = useCallback(async () => {
    setLoading(true);
    const requestBody = {
      USUARIO: user,
      EMPRESA: client,
      FECHA_AVA: date,
      ID_INS: record.ID_INS,
    };
    const response = await api.post('api/Avances/GetAvaFotosPartida', requestBody, getHeaders());
    setLoading(false);
    if (response && response.status === 200) {
      setData(response.data);
    } else {
      message.error(t('common.requestError'));
    }
  }, [record]);

  useEffect(() => {
    if (record) {
      getData();
      setCurrentImageIndex(0);
    }
  }, [record]);

  if (!record) return null;

  return (
    <Modal
      visible
      footer={null}
      onCancel={onCancel}
      className="image-zoom-modal"
      wrapClassName="image-zoom-modal-wrap"
    >
      {loading ? (
        <div className="w-100 d-flex align-items-center justify-content-center py-5">
          <LoadingOutlined className="loader" />
        </div>
      ) : (
        <>
          {data && (
            <>
              <img src={`data:image/jpeg;base64,${data[currentImageIndex].IMAGEN}`} alt="" />
              {currentImageIndex > 0 && (
                <i
                  className="fas fa-chevron-left"
                  onClick={() => setCurrentImageIndex(currentImageIndex - 1)}
                />
              )}
              {currentImageIndex < data.length - 1 && (
                <i
                  className="fas fa-chevron-right"
                  onClick={() => setCurrentImageIndex(currentImageIndex + 1)}
                />
              )}

              <div className="info">
                <span className="mb-1">Fecha: {data[currentImageIndex].FECHA}</span>
                <span className="mb-2">Usuario: {data[currentImageIndex].NOMBRE_USU}</span>
              </div>
            </>
          )}
        </>
      )}
    </Modal>
  );
};

PhotosInfo.propTypes = {
  record: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  user: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

export default PhotosInfo;
