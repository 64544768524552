/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Table, Form } from 'antd';
import InputWithTopLabel from '../InputWithTopLabel';
import { SearchIcon, normalizeString, getFormattedRut } from '../../utils';

import './index.scss';

let searchTimeout = null;

const TableView = ({
  columnsInfo,
  editableCell,
  dataSource,
  linkButton,
  title,
  extraTools,
  loading,
  showSearch = true,
}) => {
  const [form] = Form.useForm();
  const [data, setData] = useState(dataSource);
  const [filterText, setFilterText] = useState('');

  useEffect(() => {
    setData(dataSource);
  }, [dataSource]);

  const handleSearch = (value) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    searchTimeout = setTimeout(() => {
      if (value !== '') {
        const newData = dataSource.filter((record) => {
          return columnsInfo.some((item) => {
            const key = item.dataIndex;
            let val = record[key];
            if (key.includes('RUT')) {
              val = getFormattedRut(val);
              value = value.replace(/\./g, '');
              val = val.replace(/\./g, '');
            }
            val = normalizeString(`${val}`);
            return val.indexOf(normalizeString(value)) !== -1;
          });
        });
        setData(newData);
      } else if (value === '') {
        setData(dataSource);
      }
    }, 400);
  };
  const [editingKey, setEditingKey] = useState('');

  const isEditing = (record) => record.key === editingKey;

  const cancel = () => {
    setEditingKey('');
  };

  const mergedColumns = columnsInfo.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <div className="table-wrapper">
      <div className="table-head-tools">
        <div className="left-tools">
          <span className="title">{title}</span>
          {extraTools}
        </div>
        {linkButton}
        {showSearch && (
          <div className="search">
            <InputWithTopLabel
              icon={<SearchIcon />}
              onChange={(value) => {
                setFilterText(value);
                handleSearch(value);
              }}
              label="Buscar"
              value={filterText}
            />
          </div>
        )}
      </div>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: editableCell,
            },
          }}
          bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
          }}
          loading={loading}
        />
      </Form>
    </div>
  );
};

export default TableView;
