import _ from 'lodash';
import { message } from 'antd';
import api, { getHeaders } from '../utils/api';
import {
  DELETE_SCHEDULE,
  GET_SCHEDULE,
  SET_COLLATIONS_TYPE,
  SET_ERROR_SCHEDULE,
  SET_INITIAL_VALUES_IN_SCHEDULE,
  SET_SCHEDULE,
  UPDATE_SCHEDULE,
} from '../reducers/schedule';
import store from '../store';

export const setError = (error = '') => ({
  type: SET_ERROR_SCHEDULE,
  payload: { error },
});

export const setInitialValuesInSchedule = () => ({
  type: SET_INITIAL_VALUES_IN_SCHEDULE,
  payload: {},
});

export const getCollationsType = () => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
    };
    const errorMessage = 'No se cargaron éxitosamente las colaciones.';
    api
      .post(`/api/Productividad/GetHorarioTipoColacion`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: SET_COLLATIONS_TYPE, payload: { data: res.data } });
          resolve(res.data);
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });

export const getSchedule = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(setInitialValuesInSchedule());

    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR: _.get(store.getState(), 'works.selectedWork', ''),
    };
    const errorMessage = 'No se cargaron éxitosamente las jornadas.';
    api
      .post(`/api/Productividad/GetJornadas`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: GET_SCHEDULE, payload: { data: res.data } });
          resolve(res.data);
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });

export const setSchedule = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ...data,
    };
    const errorMessage = 'No se pudo guardar la nueva jornada';
    api
      .post(`/api/Productividad/SetJornada`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          const { ID_JORNADA } = res.data;
          if (ID_JORNADA === 0) {
            dispatch(setError(errorMessage));
            message.error(errorMessage, 4);
            reject(res.data);
          } else {
            const newSchedule = { ...data, EMPRESA: null, ID_JORNADA, USUARIO: null };

            dispatch({ type: SET_SCHEDULE, payload: { newSchedule } });
            message.success('Jornada agregada correctamente', 4);
            resolve(res.data);
          }
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });

export const deleteSchedule = (scheduleId) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_JORNADA: scheduleId,
      USUARIO: localStorage.getItem('foco_username'),
    };
    const errorMessage = 'No se pudo eliminar la jornada.';
    api
      .post(`/api/Productividad/DelJornada`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          const { ID_JORNADA } = res.data;
          if (ID_JORNADA === 0) {
            dispatch(setError(errorMessage));
            message.error(errorMessage, 4);
            reject(res.data);
          } else {
            dispatch({ type: DELETE_SCHEDULE, payload: { scheduleId } });
            message.success('Jornada eliminada correctamente', 4);
            resolve(res.data);
          }
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });

export const updateSchedule = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ...data,
    };
    const errorMessage = 'No se pudo actualizar la jornada.';
    api
      .post(`/api/Productividad/UpdJornada`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          const { ID_JORNADA } = res.data;
          if (ID_JORNADA === 0) {
            dispatch(setError(errorMessage));
            message.error(errorMessage, 4);
            reject(res.data);
          } else {
            dispatch({ type: UPDATE_SCHEDULE, payload: { data } });
            message.success('Jornada actualizada correctamente', 4);
            resolve(res.data);
          }
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });
