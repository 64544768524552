import _ from 'lodash';
import { message } from 'antd';
import api, { getHeaders } from '../utils/api';
import store from '../store';
import { GET_RATE, GET_RATE_ERROR, RATE_FETCHED } from '../reducers/rate';

export const baseRateFetched = (rate, workId, attr) => ({
  type: RATE_FETCHED,
  rate,
  workId,
  attr,
});

export const getRate = (ID_OBR) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: GET_RATE });

    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR,
    };
    api
      .post('api/Presupuesto/GetTarifado', requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch(baseRateFetched(res.data, ID_OBR));

          resolve(res.data);
        } else {
          dispatch({
            type: GET_RATE_ERROR,
          });
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_RATE_ERROR,
        });
        reject(err);
      });
  });

export const setRate = (body) =>
  new Promise((resolve, reject) => {
    const ID_OBR = _.get(store.getState(), 'works.selectedWork', '');
    const errorMessage = 'Error al editar las celdas';
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR,
      ...body,
    };
    api
      .post('api/Presupuesto/SetTarifado', requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          message.success('Celdas editadas correctamente', 8);

          resolve(res.data);
        } else {
          message.error(errorMessage);
          reject(res.data);
        }
      })
      .catch((err) => {
        message.error(errorMessage);
        reject(err);
      });
  });
