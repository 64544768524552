/* eslint-disable no-restricted-globals */
import _ from 'lodash';
import { message } from 'antd';

import api, { getHeaders } from '../utils/api';
import store from '../store';

import {
  COMPANIES_FETCHED,
  GET_COMPANIES,
  GET_COMPANIES_ERROR,
  ADD_NEW_COMPANY,
  DELETE_COMPANY,
  EDIT_COMPANY,
} from '../reducers/companies';

const getMessages = (action) => ({
  errorMessage: `Error al ${action === 'create' ? 'crear' : 'editar'} la empresa`,
  successMessage: `Empresa ${action === 'create' ? 'agregada' : 'editada'} correctamente`,
  saveImageErrorMessage:
    'Error subiendo imagen de la empresa por favor edite la empresa y vuelva a subir la imagen',
});

export const addNewCompany = (company) => ({
  type: ADD_NEW_COMPANY,
  company,
});

export const setCompanies = (companies) => ({
  type: COMPANIES_FETCHED,
  companies,
});

export const editCompany = (company) => ({
  type: EDIT_COMPANY,
  company,
});

export const removeCompany = (ID_EMP) => ({
  type: DELETE_COMPANY,
  ID_EMP,
});

export const error = () => ({
  type: GET_COMPANIES_ERROR,
});

export const getCompanies = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: GET_COMPANIES });
    const requestBody = {
      usuario: localStorage.getItem('foco_username'),
      ip: '1.1.1.1',
      dispositivo: 'web',
      empresa: _.get(store.getState(), 'auth.user.client', ''),
    };
    api
      .post(`/api/ParametrosGenerales/GetEmpresas`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch(setCompanies(res.data));
          resolve(res.data);
        } else {
          dispatch(error());
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(error());
        reject(err);
      });
  });

export const saveCompanyLogo = (idEmpresa, base64Logo) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_EMP: idEmpresa,
      LOGO_EMP: base64Logo,
    };
    api
      .post(`/api/ParametrosGenerales/SetLogo`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200 && res.data === 'OK') {
          resolve(res);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });

export const saveCompany = (companyData, action = 'create', logo, isLogoEdited) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ...companyData,
    };
    const { errorMessage, successMessage, saveImageErrorMessage } = getMessages(action);
    api
      .post(
        `/api/ParametrosGenerales/${action === 'create' ? 'SetEmpresa' : 'UpdEmpresa'}`,
        requestBody,
        getHeaders()
      )
      .then((res) => {
        if (res.status === 200 && !_.isEmpty(res.data)) {
          const { ID_EMP } = res.data;
          if (action === 'create') {
            if (logo) {
              saveCompanyLogo(ID_EMP, logo)
                .then((response) => {
                  if (response.data === 'OK') {
                    dispatch(
                      addNewCompany({
                        ...companyData,
                        ID_EMP,
                        EMPRESA_ASOCIADA: res.data.EMPRESA_ASOCIADA || [],
                      })
                    );
                    message.success(successMessage, 4);
                  } else {
                    message.success(saveImageErrorMessage, 4);
                  }
                  resolve();
                })
                .catch(() => message.error(saveImageErrorMessage, 4));
            } else {
              dispatch(
                addNewCompany({
                  ...companyData,
                  ID_EMP,
                  EMPRESA_ASOCIADA: res.data.EMPRESA_ASOCIADA || [],
                })
              );
              message.success(successMessage, 4);
              resolve();
            }
          } else if (action === 'edit') {
            const dataToEdit = {
              ...companyData,
              EMPRESA_ASOCIADA: res.data.EMPRESA_ASOCIADA || [],
            };

            if (isLogoEdited) {
              saveCompanyLogo(companyData.ID_EMP, logo)
                .then((response) => {
                  if (response.data === 'OK') {
                    dispatch(editCompany(dataToEdit));
                    message.success(successMessage, 4);
                  } else {
                    message.success(saveImageErrorMessage, 4);
                  }
                  resolve();
                })
                .catch(() => message.error(saveImageErrorMessage, 4));
            } else {
              dispatch(editCompany(dataToEdit));
              message.success(successMessage, 4);
              resolve();
            }
          }
        } else {
          message.error(errorMessage, 4);
          reject();
        }
      })
      .catch(() => {
        message.error(errorMessage, 4);
        reject();
      });
  });

export const deleteCompany = (ID_EMP) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_EMP,
    };

    api
      .post(`/api/ParametrosGenerales/DelEmpresa`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200 && res.data === 'OK') {
          message.success('Empresa eliminada correctamente', 4);
          dispatch(removeCompany(ID_EMP));
          resolve();
        } else {
          message.error('Error al eliminar la empresa', 4);
          reject();
        }
      })
      .catch(() => {
        message.error('Error al eliminar la empresa', 4);
        reject();
      });
  });

export const getCompanyLogo = (idEmpresa) => {
  const requestBody = {
    USUARIO: localStorage.getItem('foco_username'),
    EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
    ID_EMP: idEmpresa,
  };
  return api.post(`/api/ParametrosGenerales/GetLogo`, requestBody, getHeaders());
};
