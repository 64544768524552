import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import InputWithTopLabel from '../../../components/InputWithTopLabel';
import NewCalendar from '../NewCalendar';
import { SearchIcon } from '../../../utils';
import './index.scss';

const NewButton = ({ className, form, name }) => {
  const [showForm, toggleForm] = useState(false);

  return (
    <div className={`buttonContainer ${className}`}>
      <Button type="primary" onClick={() => toggleForm(!showForm)}>
        <i className="fa fa-plus" />
        {name}
      </Button>
      {showForm && form === 'NewCalendar' && (
        <NewCalendar onClose={() => toggleForm(false)} title="Nuevo Calendario" />
      )}
    </div>
  );
};

NewButton.defaultProps = {
  className: '',
};

NewButton.propTypes = {
  className: PropTypes.string,
  form: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

const Header = ({ handleFilters, filters }) => {
  return (
    <div className="calendarHeader">
      <div className="filters">
        <InputWithTopLabel
          icon={<SearchIcon />}
          onChange={(value) => handleFilters('name', value)}
          label="Filtrar"
          value={filters.name}
        />
      </div>
      <div className="buttons">
        <NewButton name="Nuevo Calendario" form="NewCalendar" />
      </div>
    </div>
  );
};

Header.propTypes = {
  handleFilters: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
};

export default Header;
