import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import routes from './indexRoutes';
import { setSession as setSessionAction } from '../actions/auth';
import history from '../utils/history';
import './index.scss';
/* Add here urls that need set token with query search parameter */
const exceptionsUrls = [
  '/login',
  '/attendance-control',
  '/advance-control',
  '/advance-income',
  '/payment-status',
  '/advance-matrix',
  '/panel-control',
  '/configuration',
  '/help',
  '/user-control',
  '/details-control',
  '/management',
];
const App = ({ auth, setSession }) => {
  const isLoginOrUrlWithToken = useMemo(
    () => exceptionsUrls.some((path) => window.location.href.includes(path)),
    [window.location]
  );
  const hasLocalStorageInfo =
    localStorage.getItem('foco_token') &&
    localStorage.getItem('foco_username') &&
    localStorage.getItem('foco_client');
  if (!hasLocalStorageInfo && !isLoginOrUrlWithToken) {
    history.push('/login');
  }
  useEffect(() => {
    if (hasLocalStorageInfo && !auth.isLogged && !isLoginOrUrlWithToken) {
      setSession(
        {
          username: localStorage.getItem('foco_username'),
          client: localStorage.getItem('foco_client'),
        },
        localStorage.getItem('foco_token')
      );
    }
  }, []);
  return (
    <div>
      <Router history={history}>
        <Switch>
          <Redirect from="/" to="/home/welcome" exact />
          {routes.map(({ path, Component }) => (
            <Route
              exact={path !== '/home'}
              path={path}
              render={(props) => <Component {...props} />}
            />
          ))}
        </Switch>
      </Router>
    </div>
  );
};
App.propTypes = {
  auth: PropTypes.shape({
    isLogged: PropTypes.bool,
    isFetching: PropTypes.bool,
  }).isRequired,
  setSession: PropTypes.func.isRequired,
};
export default connect(
  (store) => ({
    auth: store.auth,
  }),
  (dispatch) => ({
    setSession: bindActionCreators(setSessionAction, dispatch),
  })
)(App);
