import { message } from 'antd';
import axios from 'axios';
import _ from 'lodash';
import history from './history';

const { REACT_APP_API_URL } = process.env;

const api = axios.create({
  baseURL: REACT_APP_API_URL,
});

export const getHeaders = (isJson = true, withToken = true) => {
  const bearer = `Bearer ${localStorage.getItem('foco_token')}`;
  return {
    headers: {
      'Content-Type': isJson
        ? 'application/json; charset=utf-8'
        : 'application/x-www-form-urlencoded',
      ...(withToken ? { authorization: bearer } : {}),
    },
  };
};

api.interceptors.request.use((config) => {
  return config;
});
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const status = _.get(error, 'response.status', null);
    if (!status) {
      message.error('No hay conexión con el servidor');
    }

    if (status === 500) {
      message.error('Error interno del servidor', 10);
    }

    if (status === 401) {
      history.push('/logout');
    }
    return Promise.reject(error);
  }
);
export default api;
