import _ from 'lodash';
import { message } from 'antd';
import api, { getHeaders } from '../utils/api';
import {
  CLEAN_ITEM_DETAILS,
  GET_CHAPTERS,
  GET_ENCLOSURE,
  GET_ENCLOSURE_TREE,
  GET_ENCLOSURE_TYPES,
  GET_ENCLOSURES_AND_ITEMS,
  GET_ITEMS,
  SET_ADVANCE_IN_ITEM,
  SET_ERROR_ENCLOSURE,
  SET_ITEM_DETAILS,
  SET_ITEM_EVIDENCE,
} from '../reducers/enclosuresAndItems';
import store from '../store';

export const setError = (error = '') => ({
  type: SET_ERROR_ENCLOSURE,
  payload: { error },
});

export const getEnclosure = (withAdvance = true) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_EP: _.get(store.getState(), 'payments.selectedPayment', ''),
      RECINTOS_CON_AVANCE: withAdvance ? 1 : 0,
    };
    const errorMessage = 'No se cargaron éxitosamente los recintos.';
    api
      .post(`/api/EstadoPago/GetRecintos`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: GET_ENCLOSURE, payload: { enclosures: res.data } });
          resolve(res.data);
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });

export const getItems = (withAdvance = true) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_EP: _.get(store.getState(), 'payments.selectedPayment', ''),
      PARTIDAS_CON_AVANCE: withAdvance ? 1 : 0,
    };
    const errorMessage = 'No se cargaron éxitosamente las partidas.';
    api
      .post(`/api/EstadoPago/GetPartidas`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: GET_ITEMS, payload: { items: res.data } });
          resolve(res.data);
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });

export const getEnclosuresAndItems = (enclosureWithAdvance = true, itemsWithAdvance = true) => (
  dispatch
) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_EP: _.get(store.getState(), 'payments.selectedPayment', ''),
      RECINTOS_CON_AVANCE: enclosureWithAdvance ? 1 : 0,
      PARTIDAS_CON_AVANCE: itemsWithAdvance ? 1 : 0,
    };
    const errorMessage = 'No se cargaron éxitosamente las partidas y los recintos.';
    api
      .post(`/api/EstadoPago/GetRecintosPartidas`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: GET_ENCLOSURES_AND_ITEMS, payload: { enclosuresAndItems: res.data } });
          resolve(res.data);
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });

export const getEnclosureTypes = () => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_EP: _.get(store.getState(), 'payments.selectedPayment', ''),
    };
    const errorMessage = 'No se cargaron éxitosamente los tipos de recintos.';
    api
      .post(`/api/EstadoPago/GetRecintosTipos`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: GET_ENCLOSURE_TYPES, payload: { enclosureTypes: res.data } });
          resolve(res.data);
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });

export const getEnclosureTree = () => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_EP: _.get(store.getState(), 'payments.selectedPayment', ''),
    };
    const errorMessage = 'No se cargo éxitosamente el árbol de recintos.';
    api
      .post(`/api/EstadoPago/GetRecintosArbol`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: GET_ENCLOSURE_TREE, payload: { enclosureTree: res.data } });
          resolve(res.data);
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });

export const getItemDetails = (enclosureId, itemId) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: CLEAN_ITEM_DETAILS, payload: {} });

    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_EP: _.get(store.getState(), 'payments.selectedPayment', ''),
      ID_INS_EP: itemId,
      ID_UCO: enclosureId,
    };
    const errorMessage = 'No se cargo éxitosamente la información.';
    api
      .post(`/api/EstadoPago/GetDetalleEP`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: SET_ITEM_DETAILS, payload: { details: res.data } });
          resolve(res.data);
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });

export const setAdvanceInItem = (advance, comment, enclosureId, itemId) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_EP: _.get(store.getState(), 'payments.selectedPayment', ''),
      ID_INS_EP: itemId,
      ID_UCO: enclosureId,
      COMENTARIO_APROB: comment,
      PRC_AVA_ACUM_APROB: advance,
    };
    const errorMessage = 'No se pudo guardar la información de la partida.';
    api
      .post(`/api/EstadoPago/SetDetalleEP`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: SET_ADVANCE_IN_ITEM, payload: { advance, enclosureId, itemId } });
          message.success('Partida actualizada correctamente', 4);
          resolve(res.data);
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });

export const getItemEvidence = (enclosureId, itemId) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_EP: _.get(store.getState(), 'payments.selectedPayment', ''),
      ID_INS_EP: itemId,
      ID_UCO: enclosureId,
    };
    const errorMessage = 'No se cargo éxitosamente la evidencia.';
    api
      .post(`/api/EstadoPago/GetDetalleEvidenciaEP`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: SET_ITEM_EVIDENCE, payload: { evidence: res.data } });
          resolve(res.data);
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });

export const getChapters = () => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_EP: _.get(store.getState(), 'payments.selectedPayment', ''),
    };
    const errorMessage = 'No se cargaron éxitosamente los capítulos.';
    api
      .post(`/api/EstadoPago/GetPartidasArbol`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: GET_CHAPTERS, payload: { chapters: res.data } });
          resolve(res.data);
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });
