import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Modal } from 'antd';
import Table from '../../../components/Table';
import DoubleLineCell from '../../../components/DoubleLineCell';
import { deleteWorker as deleteWorkerAction } from '../../../actions/workers';
import { getFormattedRut } from '../../../utils';
import NewWorker from '../NewWorker';

import './index.scss';

const WorkersList = ({ deleteWorker, supervisors, workers }) => {
  const [infoToEdit, setInfoToEdit] = useState(null);
  const [infoToDelete, setInfoToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const columns = [
    {
      title: '',
      dataIndex: 'operation',
      width: '100px',
      render: (_, record) => {
        return (
          <div className="icons">
            <i className="fa fa-edit edit-icon" onClick={() => setInfoToEdit(record)} />
            <i
              className="fa fa-trash"
              onClick={() =>
                setInfoToDelete({
                  workerId: record.ID_TRA,
                  workerName: `${record.NOMBRE_TRA} ${record.APELLIDO_TRA}`,
                })
              }
            />
          </div>
        );
      },
    },
    {
      title: 'Rut',
      render: (_, record) => {
        return getFormattedRut(`${record.RUT_TRA}${record.DV_TRA}`);
      },
    },
    {
      title: 'Nombre',
      render: (_, record) => `${record.NOMBRE_TRA} ${record.APELLIDO_TRA}`,
    },
    {
      title: 'Proyecto',
      render: (_, record) => record.NOMBRE_OBR,
    },
    {
      title: 'Área',
      render: (_, record) => record.NOMBRE_ARE,
    },
    {
      title: 'Cargo',
      render: (_, record) => record.NOMBRE_CAR,
    },
    {
      title: 'Costo',
      dataIndex: 'SUELDO_TRA',
    },
    {
      title: <DoubleLineCell firstValue="Fecha Inicio /" secondValue="Fecha Fin" />,
      dataIndex: 'dates',
      render: (_, record) => (
        <DoubleLineCell
          firstValue={record.FECING_TRA.replaceAll('-', '/')}
          secondValue={record.FECFIN_TRA.replaceAll('-', '/')}
        />
      ),
    },
    {
      title: 'N° Ficha',
      dataIndex: 'NUMFICHA_TRA',
    },
  ];

  const handleDelete = () => {
    setIsDeleting(true);

    deleteWorker(infoToDelete.workerId)
      .then(() => {
        setIsDeleting(false);
        setInfoToDelete(null);
      })
      .catch(() => {
        setIsDeleting(false);
        setInfoToDelete(null);
      });
  };

  return (
    <div className="workersList">
      <Table
        loading={false}
        dataSource={workers}
        columnsInfo={columns}
        rowClassName="editable-row"
        showSearch={false}
      />
      <Modal
        title="Eliminando trabajador"
        visible={infoToDelete}
        onOk={() => setInfoToDelete(null)}
        onCancel={() => setInfoToDelete(null)}
        footer={[
          <Button key="back" disabled={isDeleting} onClick={() => setInfoToDelete(null)}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            disabled={isDeleting}
            loading={isDeleting}
            type="primary"
            onClick={() => handleDelete()}
          >
            Eliminar
          </Button>,
        ]}
      >
        <p style={{ textAlign: 'center' }}>{`¿Confirma que desea eliminar a ${
          infoToDelete && infoToDelete.workerName
        }?`}</p>
      </Modal>
      {infoToEdit && (
        <NewWorker
          infoToEdit={infoToEdit}
          onClose={() => setInfoToEdit(null)}
          title="Editar Trabajador"
          supervisors={supervisors}
        />
      )}
    </div>
  );
};

WorkersList.propTypes = {
  deleteWorker: PropTypes.func.isRequired,
  supervisors: PropTypes.arrayOf(PropTypes.object).isRequired,
  workers: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default connect(
  () => ({}),
  (dispatch) => ({
    deleteWorker: bindActionCreators(deleteWorkerAction, dispatch),
  })
)(WorkersList);
