import React, { useEffect, useState, useCallback } from 'react';
import { Row, Col, Menu, Button, message } from 'antd';
import update from 'immutability-helper';
import qs from 'querystring';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reject } from 'lodash';
import api, { getHeaders } from '../../utils/api';
import { advanceControlProptypes, getInitialState, getIcons } from './helpers';
import { setInformationFromAttendanceURL as setInformationFromAttendanceURLAction } from '../../actions/auth';
import SelectWithTopLabel from '../../components/SelectWithTopLabel';
import './index.scss';
import ManagementTable from './ManagementTable';
import NewProjectModal from './NewProjectModal';
import workerHome from '../../images/svg/maps_home_work_black_24dp.svg';
import folder from '../../images/svg/folder_shared_black_24dp.svg';
import personSearch from '../../images/svg/person_search_black_24dp.svg';

const Management = ({ auth, location, setInformationFromAttendanceURL }) => {
  const { foco_client, foco_project, foco_token, foco_username } = qs.parse(
    location.search.replace('?', '')
  );
  const [state, setState] = useState(getInitialState(foco_project));
  const [access, setAccess] = useState(true);
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [cities, setcities] = useState([]);
  const [projects, setProject] = useState();
  const [projectToEdit, setProjectToEdit] = useState('');
  const [openKeys, setOpenKeys] = useState({
    dataActive: [],
    titleItems: '',
  });

  const { dataActive, titleItems } = openKeys;

  const { works, isFetchingData } = state;

  const getWorks = useCallback(async () => {
    const requestBody = {
      USUARIO: foco_username,
      EMPRESA: foco_client,
    };
    setState(update(state, { works: { isFetching: { $set: true } } }));
    const worksResponse = await api.post('api/Proyecto/GetObras', requestBody, getHeaders());
    const accessResponse = await api.post(
      'api/Avances/GetPermisosPagina',
      { ...requestBody, PAGINA: 'PROGRAMA_CONTROLADO' },
      getHeaders()
    );
    let updates = update(state, {
      works: {
        isFetching: { $set: false },
      },
    });
    if (worksResponse && worksResponse.status === 200) {
      updates = update(updates, {
        works: {
          data: { $set: worksResponse.data },
        },
      });
    }

    if (accessResponse && accessResponse.status === 200) {
      const newAccess = accessResponse.data.PERMISOS.reduce(
        (acc, { OPCION, ACCESO }) => ({
          ...acc,
          [OPCION]: ACCESO,
        }),
        {}
      );
      updates = update(updates, {
        access: { $set: newAccess },
        userId: { $set: accessResponse.data.ID_USU },
      });
    }
    setState(updates);
  }, [works, foco_client]);

  const getCities = useCallback(async (updates = {}) => {
    const requestBody = {
      USUARIO: foco_username,
      EMPRESA: foco_client,
    };

    setState(
      update(state, {
        isFetchingData: { $set: true },
        filter: { $set: [] },
        active: { $set: null },
        ...updates,
      })
    );
    api
      .post('api/Proyecto/GetCiudadesProyecto', requestBody, getHeaders())
      .then((result) => {
        if (result && result.status === 200) {
          setcities(result);
        }
      })
      .catch(() => {
        message.error(errorMessage, 4);
        reject();
      })
      .finally(() => setState(update(state, { isFetchingData: { $set: false } })));
  });

  const getProjects = useCallback(async (updates = {}) => {
    const requestBody = {
      USUARIO: foco_username,
      EMPRESA: foco_client,
      ID_OBR: works.selected,
    };

    setState(
      update(state, {
        isFetchingData: { $set: true },
        filter: { $set: [] },
        active: { $set: null },
        ...updates,
      })
    );
    api
      .post('api/Proyecto/GetProyecto', requestBody, getHeaders())
      .then((result) => {
        if (result && result.status === 200) {
          setProject(result);
        }
      })
      .catch(() => {
        message.error(errorMessage, 4);
        reject();
      })
      .finally(() => setState(update(state, { isFetchingData: { $set: false } })));
  });

  const getData = useCallback(
    async (updates = {}) => {
      const requestBody = {
        EMPRESA: foco_client,
        USUARIO: foco_username,
        ID_OBR: works.selected,
      };

      setState(
        update(state, {
          isFetchingData: { $set: true },
          filter: { $set: [] },
          active: { $set: null },
          ...updates,
        })
      );
      api
        .post('api/Proyecto/GetEstatusProyecto', requestBody, getHeaders())
        .then((result) => {
          if (result && result.status === 200) {
            const type = [];
            const type1 = [];
            const type2 = [];
            const type3 = [];
            result.data.forEach((e) => {
              if (e.MODULO === 'PROTOCOLOS') {
                type.push(e);
              } else if (e.MODULO === 'RECEPCIONES') {
                type1.push(e);
              } else if (e.MODULO === 'NC') {
                type2.push(e);
              } else if (e.MODULO === 'DOCUMENTOS') {
                type3.push(e);
              }
            });
            setData(type);
            setData1(type1);
            setData2(type2);
            setData3(type3);
            setState(update(state, { isFetchingData: { $set: false } }));
          } else {
            setState(update(state, { isFetchingData: { $set: false } }));
          }
        })
        .catch(() => setState(update(state, { isFetchingData: { $set: false } })));
    },
    [works.selected]
  );

  useEffect(() => {
    if (foco_token) {
      setInformationFromAttendanceURL(foco_client, foco_project, foco_token, foco_username);
      localStorage.setItem('foco_token', foco_token);
    }
  }, []);

  useEffect(() => {
    if (auth.token && auth.user.username) {
      if (foco_client && !works.data && !works.isFetching) {
        getWorks();
      }
    }
  }, [auth, works]);

  useEffect(() => {
    getProjects();
    getData();
    getCities();
  }, [works.selected]);

  const onChange = (value, stateKey) => {
    let updates = update(state, { [stateKey]: { selected: { $set: Number(value) } } });

    if (stateKey === 'works') {
      updates = update(updates, {
        dates: {
          selected: { $set: null },
          state: { $set: null },
        },
      });
    }

    setState(updates);
  };

  const isLoadingTable = isFetchingData || works.isFetching;

  const onOpenChange = (keys) => {
    const { key } = keys;
    if (key === 'PROTOCOLOS') {
      setOpenKeys({ dataActive: data, titleItems: key });
    } else if (key === 'RECEPCIONES') {
      setOpenKeys({ dataActive: data1, titleItems: key });
    } else if (key === 'NO CONFORMIDADES') {
      setOpenKeys({ dataActive: data2, titleItems: key });
    } else if (key === 'DOCUMENTOS') {
      setOpenKeys({ dataActive: data3, titleItems: key });
    }
  };

  useEffect(() => {
    if (data && data.length) {
      if (!openKeys.dataActive.length) {
        setOpenKeys({ dataActive: data, titleItems: 'PROTOCOLOS' });
      } else {
        onOpenChange(openKeys.titleItems);
      }
    }
  }, [data]);

  return (
    <div className="management p-3">
      <div className="container-menu">
        <div>
          <Row gutter={[30, 30]}>
            <Col className="first-buttons-col mb-4" span={24}>
              <SelectWithTopLabel
                className="top-select "
                keepShowingLabel
                disabled={works.isFetching || !works.data}
                loading={works.isFetching}
                value={works.selected}
                label="Seleccionar Proyecto"
                options={(works.data || []).map(({ ID_OBR, NOMBRE_OBR }) => ({
                  name: NOMBRE_OBR,
                  value: Number(ID_OBR),
                }))}
                onChange={(value) => onChange(value, 'works')}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
              <Button type="Default" onClick={() => setProjectToEdit('edit')}>
                <i className="fas fa-edit mr-1" />
                Editar Proyecto
              </Button>
              <Button type="Default" onClick={() => setProjectToEdit('create')}>
                <i className="fa fa-plus mr-1" />
                Nuevo Proyecto
              </Button>

              <NewProjectModal
                projectToEdit={projectToEdit}
                setProjectToEdit={setProjectToEdit}
                cities={cities}
                foco_client={foco_client}
                foco_username={foco_username}
                projects={projects}
                getProjects={getProjects}
                getWorks={getWorks}
                works={works}
              />
            </Col>
          </Row>
        </div>
        <div className="menu-center">
          <div>
            <Row gutter={[30, 30]}>
              <Menu className="container-options ml-4" onClick={onOpenChange}>
                <Menu.Item
                  key="PROTOCOLOS"
                  className={`${dataActive === data ? 'ant-menu-item-selected' : ''}`}
                >
                  <>
                    <i className="fas fa-check-circle  mr-2 ml-1 color-icon-menu" />
                    <span>Protocolos</span>
                    {data.length ? <span className="float-right">{getIcons(data)}</span> : ''}
                  </>
                </Menu.Item>
                <Menu.Item
                  key="RECEPCIONES"
                  className={`${dataActive === data1 ? 'ant-menu-item-selected' : ''}`}
                >
                  <>
                    <img src={workerHome} alt="" className="iconfonts " />
                    <span>Recepciones</span>
                    {data1.length ? <span className="float-right">{getIcons(data1)}</span> : ''}
                  </>
                </Menu.Item>
                <Menu.Item
                  key="NO CONFORMIDADES"
                  className={`${dataActive === data2 ? 'ant-menu-item-selected' : ''}`}
                >
                  <>
                    <img src={personSearch} alt="" className="iconfonts " />

                    <span>No Conformidades</span>
                    {data2.length ? <span className="float-right">{getIcons(data2)}</span> : ''}
                  </>
                </Menu.Item>
                <Menu.Item
                  key="DOCUMENTOS"
                  className={`${dataActive === data3 ? 'ant-menu-item-selected' : ''}`}
                >
                  <>
                    <img src={folder} alt="" className="iconfonts " />
                    <span>Documentos</span>
                    {data3.length ? <span className="float-right">{getIcons(data3)}</span> : ''}
                  </>
                </Menu.Item>
              </Menu>
            </Row>
          </div>
          <div className="containertable">
            <Row gutter={[30, 30]}>
              <Col span={24}>
                <ManagementTable
                  data={dataActive}
                  isLoadingTable={isLoadingTable}
                  title={titleItems}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

Management.propTypes = advanceControlProptypes;

export default connect(
  (store) => ({
    auth: store.auth,
  }),
  (dispatch) => ({
    setInformationFromAttendanceURL: bindActionCreators(
      setInformationFromAttendanceURLAction,
      dispatch
    ),
  })
)(Management);
