import React, { useEffect, useState } from 'react';
import moment from 'moment';
import 'devextreme/data/odata/store';
import DataGrid, {
  Column,
  Sorting,
  Button,
  Paging,
  Scrolling,
  LoadPanel,
} from 'devextreme-react/data-grid';
import { Form, DatePicker, Select, Row, Col, Tooltip, Modal } from 'antd';
import PropTypes from 'prop-types';
import qs from 'querystring';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LoadingOutlined } from '@ant-design/icons';
import TreeView from 'devextreme-react/tree-view';
import Sparkline, { Size, Tooltip as RechartsTooltip } from 'devextreme-react/sparkline';
import { setInformationFromPaymentURL as setInformationFromPaymentURLAction } from '../../actions/auth';
import {
  getItemsData as getItemsDataAction,
  getManager as getManagerAction,
  getConfiguration as getConfigurationAction,
  getOptions as getOptionsAction,
  getProjectUsr as getProjectUsrAction,
} from '../../actions/panelcontrol';
import './index.scss';
import logoAvance from '../../images/login/logoAvance.png';
import logoProductividad from '../../images/login/LogoProductividad.png';
import logoCalidad from '../../images/login/LogoCalidad.png';
import logoCostos from '../../images/login/logoCosto.png';
import logoPrevencion from '../../images/login/logoPrevencion.png';
import logoPlanner from '../../images/login/logoPlanner.png';

const PanelControl = ({
  auth,
  ItemsInRedux,
  getItemsData,
  getManager,
  getConfiguration,
  getOptions,
  getProjectUsr,
  location,
  setInformationFromPaymentURL,
}) => {
  const { itemsData, manager, configuracion, optionsM, project } = ItemsInRedux;
  const [show, setShow] = useState(false);
  const [showProject, setShowProject] = useState(0);
  const [showTextProject, setShowTextProject] = useState('');
  const { foco_client, foco_payment_id, foco_token, foco_username } = qs.parse(
    location.search.replace('?', '')
  );

  const [form] = Form.useForm();
  useEffect(() => {
    if (foco_token) {
      setInformationFromPaymentURL(foco_client, foco_payment_id, foco_token, foco_username);
      localStorage.setItem('foco_token', foco_token);
    }
  }, []);

  useEffect(() => {
    if (auth.token && auth.user.username) {
      (async () => {
        form.setFieldsValue({ lasEstado: 1 });
        form.setFieldsValue({ lastManager: 'TODOS' });
        form.setFieldsValue({ lastProject: 'TODOS' });
        await getConfiguration();
        await getManager();
        await getProjectUsr();
        const now = moment();
        await getItemsData(moment(now).format('DD-MM-YYYY'), '1', 'TODOS', 'TODOS');
      })();
    }
  }, [auth]);

  const onValuesChange = () => {
    if (form.getFieldValue('productionDate') != null) {
      getConfiguration();
      getItemsData(
        form.getFieldValue('productionDate'),
        Number(form.getFieldValue('lasEstado')),
        form.getFieldValue('lastManager'),
        form.getFieldValue('lastProject')
      );
    }
  };

  const options = [
    { label: 'En ejecución', value: 1, className: 'custom-class' },
    { label: 'Finalizados', value: 2, className: 'awesome-class' },
    { label: 'Todos', value: 3, className: 'awesome-class' },
  ];

  function formatNumber(n) {
    n = String(n).replace('.', ',');
    const exp = /(\d)(?=(\d{3})+(?!\d))/g;
    const rep = '$1.';
    return n.toString().replace(exp, rep);
  }

  const EspecialCell = (cellData, filas) => {
    return (
      <div>
        {filas.map((item) =>
          item.VISIBLE_FILA === 0 ||
          cellData.data[item.DATA_FIELD] === 0 ||
          cellData.data[item.DATA_FIELD] === '0' ||
          cellData.data[item.DATA_FIELD] === '' ||
          cellData.data[item.DATA_FIELD] === '01-01-1900' ? (
            <div>{}</div>
          ) : (
            <div>
              <div>
                <span
                  style={{
                    color: 'gray',
                    fontWeight: `${item.TEXTO_FILA_BOLD}`,
                    cursor: 'pointer',
                  }}
                >
                  <Tooltip
                    placement="topLeft"
                    destroyTooltipOnHide={{ keepParent: false }}
                    title={item.DESCRIPCION}
                  >
                    <i className="far fa-info-circle" />
                    <span className="value"> {item.TEXTO_FILA}</span>
                  </Tooltip>
                </span>
              </div>
              {item.ES_GRAFICO === 0 ? (
                <div>
                  <div
                    className={
                      cellData.data[item.DATA_FIELD] > 0 && item.DATETYPE === 'number'
                        ? 'inc'
                        : 'desc'
                    }
                  >
                    <span style={{ color: 'gray', fontWeight: `${item.VALUE_FIELD_BOLD}` }}>
                      {item.UNIDAD_FIELD === 'MM$'
                        ? cellData.data[item.DATA_FIELD] / 1000
                        : cellData.data[item.DATA_FIELD]}{' '}
                      {item.UNIDAD_FIELD === '' ? '' : item.UNIDAD_FIELD}
                    </span>
                  </div>
                </div>
              ) : (
                <Sparkline
                  dataSource={cellData.data[item.VALUE_FIELD]}
                  argumentField={item.ARGUMENT_FIELD}
                  valueField={item.VALUE_FIELD}
                  type="line"
                  showMinMax="true"
                  showMin="true"
                  maxColor="#f00"
                  minColor="#2ab71b"
                  pointSize="10"
                >
                  <Size width="130" height="30" />
                  <RechartsTooltip enabled="true" />
                </Sparkline>
              )}
            </div>
          )
        )}
      </div>
    );
  };

  const GenericoCell = (cellData, filas) => {
    return (
      <div>
        {filas.map((item) =>
          item.VISIBLE_FILA === 0 ? (
            <div>{}</div>
          ) : (
            <div className="flexbox-container">
              <div>
                <span
                  style={{
                    color: 'gray',
                    fontWeight: `${item.TEXTO_FILA_BOLD}`,
                    cursor: 'pointer',
                  }}
                >
                  <Tooltip
                    placement="topLeft"
                    destroyTooltipOnHide={{ keepParent: false }}
                    title={item.DESCRIPCION}
                  >
                    <i className="far fa-info-circle" />
                    <span className="value"> {item.TEXTO_FILA}</span>
                  </Tooltip>
                </span>
              </div>
              {item.ES_GRAFICO === 0 ? (
                <div>
                  <div
                    className={
                      cellData.data[item.DATA_FIELD] > 0 && item.DATETYPE === 'number'
                        ? 'inc'
                        : 'dec'
                    }
                  >
                    <span
                      title={item.TOOLTIPS}
                      style={{
                        color:
                          item.DATETYPE === 'blue'
                            ? cellData.data[item.DATA_FIELD] > 0
                              ? 'blue'
                              : 'red'
                            : item.DATETYPE === 'red'
                            ? 'red'
                            : item.DATETYPE === 'number' && item.INDICADOR === 1
                            ? cellData.data[item.DATA_FIELD] > 0
                              ? 'blue'
                              : 'red'
                            : '#666',
                        fontWeight: `${item.VALUE_FIELD_BOLD}`,
                      }}
                    >
                      <div
                        className={
                          item.DATETYPE === 'number' && item.INDICADOR === 1
                            ? 'diff'
                            : 'current-value'
                        }
                      />
                      <div>
                        {item.DATETYPE === 'number'
                          ? item.UNIDAD_FIELD === '$'
                            ? item.UNIDAD_FIELD + formatNumber(cellData.data[item.DATA_FIELD])
                            : item.UNIDAD_FIELD === 'MM$'
                            ? formatNumber(cellData.data[item.DATA_FIELD] / 1000)
                            : formatNumber(cellData.data[item.DATA_FIELD])
                          : cellData.data[item.DATA_FIELD] !== '01-01-1900'
                          ? cellData.data[item.DATA_FIELD]
                          : ''}{' '}
                        {item.UNIDAD_FIELD === '$' ? '' : item.UNIDAD_FIELD}
                      </div>
                    </span>
                  </div>
                </div>
              ) : (
                <Sparkline
                  dataSource={cellData.data[item.VALUE_FIELD]}
                  argumentField={item.ARGUMENT_FIELD}
                  valueField={item.VALUE_FIELD}
                  type="line"
                  showMinMax="true"
                  minColor="#f00"
                  maxColor="#2ab71b"
                  pointSize="6"
                  className="sparkline"
                >
                  <Size width="110" height="30" />
                  <RechartsTooltip
                    enabled="true"
                    color="#d3d3d3"
                    dashStyle="solid"
                    opacity="1"
                    zIndex="1000"
                  />
                </Sparkline>
              )}
            </div>
          )
        )}
      </div>
    );
  };

  const TableLogo = (render) => {
    switch (render) {
      case 'FOCO AVANCE':
        return logoAvance;
      case 'FOCO PRODUCTIVIDAD':
        return logoProductividad;
      case 'FOCO PLANNER':
        return logoPlanner;
      case 'FOCO PREVENCIÓN':
        return logoPrevencion;
      case 'FOCO PREVENCION':
        return logoPrevencion;
      case 'FOCO COSTOS':
        return logoCostos;
      case 'FOCO CALIDAD':
        return logoCalidad;
      default:
        return <span style={{ color: 'black' }}>{render}</span>;
    }
  };
  const renderHeader = (e) => {
    if (e.column.caption.includes('FOCO')) {
      return (
        <span style={{ color: 'black', fontSize: '14px' }}>
          <img
            id="Image1"
            src={TableLogo(e.column.caption)}
            alt={e.column.caption}
            width="20"
            height="20"
          />{' '}
          {e.column.caption}
        </span>
      );
    }
    return <span style={{ color: 'black', fontSize: '12px' }}>{e.column.caption}</span>;
  };
  const TableFunctions = (render, xfilas) => {
    switch (render) {
      case 'GenericoCell':
        return (e) => GenericoCell(e, xfilas);
      case 'EspecialCell':
        return (e) => EspecialCell(e, xfilas);
      default:
        return render;
    }
  };
  const TableVisible = (render) => {
    switch (render) {
      case '1':
        return true;
      case '0':
        return false;
      default:
        return render;
    }
  };

  const selectItem = (e) => {
    if (e.itemData.url !== '#') {
      if (e.itemData.url !== null && e.itemData.es_qa === '0') {
        window.open(
          `${'https://focoweb.focoenobra.cl:1066/Sistema/FrmRedireccionador.aspx?token='}` +
            `${window.btoa(
              unescape(
                encodeURIComponent(
                  `&usr=${foco_username}&emp=${foco_client}&page=${e.itemData.url}&foco_project=${showProject}`
                )
              )
            )}`,
          '_blank'
        );
      } else {
        window.open(
          `${'https://focoweb.focoenobra.cl:1080/pages/seguridad/FrmRedireccionador.aspx?token='}` +
            `${window.btoa(
              unescape(
                encodeURIComponent(
                  `&usr=${foco_username}&emp=${foco_client}&page=${e.itemData.url}&foco_project=${showProject}&rec=3`
                )
              )
            )}`,
          '_blank'
        );
      }
    }
  };

  const getHeight = () => {
    return window.innerHeight - 120;
  };
  const renderTreeViewItem = (item) => {
    if (item.icon !== null) {
      if (item.icon.includes('assets2')) {
        return (
          <span style={{ color: 'black', fontSize: '14px' }}>
            <img id="Image1" src={TableLogo(item.text)} alt={item.text} width="20" height="20" />{' '}
            {item.text}
          </span>
        );
      }
    }
    return (
      <span style={{ color: 'black', fontSize: '14px' }}>
        <i className={item.icon} /> {item.text}
      </span>
    );
  };
  const resArr = [];
  return (
    <div className="paymentStatus">
      {optionsM && (
        <Modal
          wrapClassName="advance-modal"
          visible={show}
          onCancel={() => {
            setShow(false);
          }}
        >
          <div className="advance-container">
            <div className="advance-header">
              <span>MENU FOCOENOBRA ({showTextProject})</span>
            </div>
            <div />
            <div className="advance-body">
              <TreeView
                id="treeview"
                items={optionsM}
                height="300"
                width="100%"
                searchMode="contains"
                searchEnabled
                onItemClick={selectItem}
                itemRender={renderTreeViewItem}
              />
              <div className="advance-footer">
                <Button onClick={() => setShow(false)}>Cancelar</Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {!itemsData ? (
        <div className="spin">
          <LoadingOutlined />
        </div>
      ) : (
        <>
          <div className="stat-panel">
            <div className="stat-cell padding-sm bg-Default">
              <Form onValuesChange={onValuesChange} form={form} layout="vertical">
                <Row>
                  <Col span={4}>
                    <Form.Item
                      initialValue={moment()}
                      rules={[
                        {
                          required: true,
                          message: 'Por favor seleccione una fecha',
                        },
                      ]}
                      label="Fecha de Corte"
                      name="productionDate"
                      required
                    >
                      <DatePicker placeholder="dd-mm-aaaa" format="DD-MM-YYYY" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Estado de Proyecto" name="lasEstado" required>
                      <Select options={options} placeholder="Estado" optionFilterProp="children" />
                    </Form.Item>
                  </Col>
                  <Col span={6} offset={1}>
                    <Form.Item label="Gerente Proyecto" name="lastManager" required>
                      <Select
                        options={manager.map(({ ID_GERENTE, NOMBRE_GERENTE }) => ({
                          name: `${ID_GERENTE} (${ID_GERENTE})`,
                          value: NOMBRE_GERENTE,
                        }))}
                        placeholder="Gerente"
                        optionFilterProp="children"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6} offset={1}>
                    <Form.Item label="Proyecto" name="lastProject" required>
                      <Select
                        options={project.map(({ ID_OBR, Nombre_Obr }) => ({
                          name: `${ID_OBR} (${ID_OBR})`,
                          value: Nombre_Obr,
                        }))}
                        placeholder="Proyecto"
                        optionFilterProp="children"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
          <DataGrid
            id="gridContainer"
            dataSource={itemsData}
            showBorders="true"
            width="100%"
            height={getHeight}
            allowColumnReordering="true"
            allowColumnResizing="true"
            columnAutoWidth="true"
            rowAlternationEnabled="false"
            keyExpr="ID_OBR"
          >
            <Scrolling useNative="true" />
            <LoadPanel enabled="true" />
            <Paging enabled="true" />
            <Sorting mode="none" />
            <Column type="buttons" width="40" fixed="true" fixedPosition="left">
              <Button
                hint="Ver Menu Focoenobra"
                icon="showpanel"
                onClick={(e) => {
                  (async () => {
                    setShowTextProject(e.row.data.NOM_ABR_OBR);
                    await getOptions(e.row.data.ID_OBR);
                  })();

                  setShowProject(e.row.data.ID_OBR);
                  setShow(true);
                }}
              />
            </Column>
            <Column
              dataField="NOM_ABR_OBR"
              caption=""
              fixed="true"
              fixedPosition="left"
              alignment="center"
              visible={false}
            />
            {configuracion.forEach(function (value) {
              if (
                value.ES_HIJO === '0' &&
                resArr.findIndex((x) => x.ID_COLUMNA === value.ID_COLUMNA) === -1
              )
                resArr.push(value);
            })}
            {resArr.map((fbb) =>
              fbb.FIXED === 'false' ? (
                <Column
                  width="360"
                  cssClass="base"
                  alignment="center"
                  visible={TableVisible(fbb.VISIBLE_COL)}
                  caption={fbb.TEXTO_COLUMNA}
                  dataField={fbb.DATA_FIELD}
                  cellRender={TableFunctions(fbb.CELL_RENDER_COLUMNA, fbb.FILAS)}
                  headerCellRender={renderHeader}
                >
                  {configuracion
                    .filter((x) => `${x.ES_HIJO}` === `${fbb.ID_COLUMNA}` && x.ES_HIJO !== 0)
                    .map((item) =>
                      item.ES_HIJO === fbb.ID_COLUMNA && item.CELL_RENDER_COLUMNA === '' ? (
                        <Column
                          width="260"
                          cssClass="base"
                          alignment="center"
                          caption={item.TEXTO_COLUMNA}
                          dataField={item.DATA_FIELD}
                          visible={TableVisible(item.VISIBLE_COL)}
                        />
                      ) : (
                        <Column
                          width="260"
                          cssClass="base"
                          alignment="center"
                          visible={TableVisible(item.VISIBLE_COL)}
                          caption={item.TEXTO_COLUMNA}
                          dataField={item.DATA_FIELD}
                          cellRender={TableFunctions(item.CELL_RENDER_COLUMNA, item.FILAS)}
                        />
                      )
                    )}
                </Column>
              ) : (
                <Column
                  width="360"
                  cssClass="base"
                  alignment="center"
                  visible={TableVisible(fbb.VISIBLE_COL)}
                  caption={fbb.TEXTO_COLUMNA}
                  dataField={fbb.DATA_FIELD}
                  fixed="true"
                  fixedPosition="left"
                  cellRender={TableFunctions(fbb.CELL_RENDER_COLUMNA, fbb.FILAS)}
                />
              )
            )}
            ;
          </DataGrid>
        </>
      )}
    </div>
  );
};
PanelControl.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.object,
    token: PropTypes.string,
  }).isRequired,
  ItemsInRedux: PropTypes.shape({
    itemsData: PropTypes.array,
    manager: PropTypes.array,
    configuracion: PropTypes.array,
    salida: PropTypes.array,
    optionsM: PropTypes.array,
    project: PropTypes.array,
  }).isRequired,
  getItemsData: PropTypes.func.isRequired,
  getManager: PropTypes.func.isRequired,
  getConfiguration: PropTypes.func.isRequired,
  getOptions: PropTypes.func.isRequired,
  getProjectUsr: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  setInformationFromPaymentURL: PropTypes.func.isRequired,
};

export default connect(
  (store) => ({
    auth: store.auth,
    ItemsInRedux: store.ControlPanel,
  }),
  (dispatch) => ({
    setInformationFromPaymentURL: bindActionCreators(setInformationFromPaymentURLAction, dispatch),
    getItemsData: bindActionCreators(getItemsDataAction, dispatch),
    getManager: bindActionCreators(getManagerAction, dispatch),
    getConfiguration: bindActionCreators(getConfigurationAction, dispatch),
    getOptions: bindActionCreators(getOptionsAction, dispatch),
    getProjectUsr: bindActionCreators(getProjectUsrAction, dispatch),
  })
)(PanelControl);
