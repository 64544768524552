/* eslint-disable import/no-cycle */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import _ from 'lodash';
import { Modal, Button, Checkbox } from 'antd';
import { Column, HeaderCell, Cell } from 'rsuite-table';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Input from '../../../../components/InputWithTopLabel';
import Table from '../../../../components/TableExtend';
import Select from '../../../../components/SelectWithTopLabel';
import { commonPropTypes, normalizeString } from '../../../../utils';
import NewResource from '../NewResource';
import NewSubItem from '../NewSubItem';
import {
  getResourcesAndSubitems as getResourcesAndSubitemsAction,
  createResourceOrSubitem as createResourceOrSubitemAction,
} from '../../../../actions/budget';

import '../index.scss';
import './index.scss';

const { confirm } = Modal;

let searchTimeout = null;

const sortResources = (a, b) => {
  if (a.NOMBRE_TIN > b.NOMBRE_TIN) {
    return 1;
  }
  if (a.NOMBRE_TIN < b.NOMBRE_TIN) {
    return -1;
  }
  return 0;
};

const CheckCell = ({ rowData, onChange, ...props }) => {
  return (
    <Cell {...props}>
      <Checkbox checked={rowData.selected} onChange={(e) => onChange(rowData, e.target.checked)} />
    </Cell>
  );
};

CheckCell.propTypes = {
  rowData: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

const ResourceSelector = ({
  inputsTypes,
  units,
  addResources,
  canAddSubItems,
  resourcesAndSubitems,
  getResourcesAndSubitems,
  createResourceOrSubitem,
}) => {
  const [state, setState] = useState({
    show: false,
    inputsTypesNames: {},
    initialTree: null,
    selectedResources: [],
    resources: null,
    initialResources: null,
    filterValues: { family: '', resource: '' },
  });

  const { data: resourcesAndSubitemsData, isFetching: isFetchingResourcesAndSubitems } =
    resourcesAndSubitems;

  const { show, selectedResources, filterValues, inputsTypesNames, initialResources } = state;

  useEffect(() => {
    if (inputsTypes.data && resourcesAndSubitemsData) {
      const names = {};
      inputsTypes.data.forEach((e) => {
        names[e.ID_TIN] = e.NOMABR_TIN;
      });

      const resources = resourcesAndSubitemsData.map((e) => ({
        ...e,
        NOMBRE_TIN: `${names[e.ID_TIN] || 'Subpartida'}`,
      }));

      resources.sort(sortResources);
      setState(
        update(state, {
          inputsTypesNames: { $set: names },
          resources: { $set: resources },
          initialResources: { $set: resources },
        })
      );
    }
  }, [inputsTypes, resourcesAndSubitemsData]);

  useEffect(() => {
    if (show && !resourcesAndSubitemsData && !isFetchingResourcesAndSubitems) {
      getResourcesAndSubitems();
    }
  }, [show]);

  const filterByFamily = (value) =>
    initialResources.filter((resource) => {
      return (
        (!value || normalizeString(resource.NOMBRE_TIN) === normalizeString(value)) &&
        (!filterValues.resource ||
          normalizeString(resource.NOMBRE_ITEM).includes(normalizeString(filterValues.resource)))
      );
    });

  const filterByResource = (value) =>
    initialResources.filter((resource) => {
      return (
        (!value || normalizeString(resource.NOMBRE_ITEM).includes(normalizeString(value))) &&
        (!filterValues.family ||
          normalizeString(resource.NOMBRE_TIN) === normalizeString(filterValues.family))
      );
    });

  const save = () => {
    addResources(state.selectedResources);
    const newInitialResources = state.resources.map((e) => ({ ...e, selected: false }));
    setState(
      update(state, {
        show: { $set: false },
        filterValues: { $set: {} },
        selectedResources: { $set: [] },
        resources: { $set: newInitialResources },
        initialResources: { $set: newInitialResources },
      })
    );
  };

  const { data: inputsTypesData, isFetching: isFetchingInputsTypes } = inputsTypes;

  const onChangeSelected = (record, selected) => {
    const resourceIndex = state.resources.findIndex((e) => e.ID_ITEM === record.ID_ITEM);

    let currentSelectedResources = [...state.selectedResources];
    if (selected) {
      currentSelectedResources = update(currentSelectedResources, {
        $push: [{ ...record, FACTOR_MOD: 1, CANTIDAD_MOD: 0 }],
      });
    } else {
      const selectedResourceIndex = currentSelectedResources.findIndex(
        (e) => e.ID_ITEM === record.ID_ITEM
      );
      currentSelectedResources = update(currentSelectedResources, {
        $splice: [[selectedResourceIndex, 1]],
      });
    }
    setState(
      update(state, {
        resources: {
          [resourceIndex]: {
            selected: {
              $set: selected,
            },
          },
        },
        selectedResources: { $set: currentSelectedResources },
      })
    );
  };
  const onChangeSearch = (e, key) => {
    let newResources;

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    searchTimeout = setTimeout(() => {
      if (key === 'family') {
        newResources = filterByFamily(inputsTypesNames[e]);
      } else {
        newResources = filterByResource(_.get(e, 'target.value', e));
      }
      setState((prev) =>
        update(prev, {
          resources: { $set: newResources },
          filterValues: {
            [key]: { $set: _.get(e, 'target.value', e) },
          },
        })
      );
    }, 300);
  };

  const removeResource = (ID_ITEM) => {
    const index = state.resources.findIndex((e) => e.ID_ITEM === ID_ITEM);

    if (index > -1) {
      const newResources = update(state.resources, {
        $splice: [[index, 1]],
      });
      const selectedIndex = state.selectedResources.findIndex((e) => e.ID_ITEM === ID_ITEM);

      setState(
        update(state, {
          initialResources: { $set: newResources },
          resources: { $set: newResources },
          filterValues: { $set: {} },
          ...(selectedIndex !== -1 ? { selectedResources: { $splice: [[selectedIndex, 1]] } } : {}),
        })
      );
    }
  };

  return (
    <div className="add-resource">
      <button
        type="button"
        onClick={() =>
          setState(
            update(state, {
              show: {
                $set: true,
              },
            })
          )
        }
      >
        {`Agregar Recursos ${canAddSubItems ? '/ Subpartidas' : ''} `}{' '}
        <i className="fa fa-plus-circle" />
      </button>
      <Modal
        closable={false}
        wrapClassName="item-modal"
        visible={show}
        width={900}
        onCancel={() => {
          setState(
            update(state, {
              show: { $set: false },
              filterValues: { $set: {} },
              selectedResources: { $set: [] },
              resources: { $set: state.initialResources },
            })
          );
        }}
        footer={null}
        okText="Guardar"
      >
        <div className="modal-container">
          <div className="modal-header">
            <div />
            <span>Seleccionar Recursos</span>
            <i
              className="fa fa-times"
              onClick={() => {
                setState(
                  update(state, {
                    show: { $set: false },
                  })
                );
              }}
            />
          </div>
          <div className="form-body">
            <div className="resource-search">
              <Select
                allowClear
                label="Naturaleza"
                value={state.filterValues.family}
                keepShowingLabel
                loading={isFetchingInputsTypes}
                disabled={isFetchingInputsTypes}
                optionFilterProp="children"
                showSearch
                options={(inputsTypesData || []).map(({ ID_TIN, NOMBRE_TIN, NOMABR_TIN }) => ({
                  name: `${NOMBRE_TIN} (${NOMABR_TIN})`,
                  value: ID_TIN,
                }))}
                onChange={(e) => onChangeSearch(e, 'family')}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
              <Input
                label="Recurso/Subpartida"
                keepShowingLabel
                onChange={(e) => onChangeSearch(e, 'resource')}
              />
              <NewResource
                units={units}
                inputsTypesData={inputsTypesData || []}
                inputsTypesNames={state.inputsTypesNames}
                createResource={createResourceOrSubitem}
              />
              {canAddSubItems && (
                <NewSubItem
                  units={units}
                  inputsTypes={inputsTypes}
                  inputsTypesData={inputsTypesData || []}
                  inputsTypesNames={state.inputsTypesNames}
                  resourcesData={resourcesAndSubitemsData}
                  createSubitem={createResourceOrSubitem}
                />
              )}
            </div>
            <div className="resources-list">
              <Table
                rowKey="ID_ITEM"
                bordered
                rowHeight={28}
                height={500}
                virtualized
                locale={{
                  emptyMessage: 'No hay datos para mostrar',
                }}
                loading={isFetchingResourcesAndSubitems}
                rowClassName={(record) => {
                  if (record && record.selected) return 'selected';
                  return '';
                }}
                renderTreeToggle={(_icon, _row, expanded) => {
                  if (expanded) {
                    return <CaretUpOutlined />;
                  }
                  return <CaretDownOutlined />;
                }}
                data={state.resources || []}
              >
                <Column verticalAlign="middle" align="center" width={40}>
                  <HeaderCell />
                  <CheckCell onChange={onChangeSelected} />
                </Column>
                <Column verticalAlign="middle" align="left" width={80}>
                  <HeaderCell>Naturaleza</HeaderCell>
                  <Cell dataKey="NOMBRE_TIN" />
                </Column>
                <Column treeCol verticalAlign="middle" align="left" width={200}>
                  <HeaderCell>Nombre Item</HeaderCell>
                  <Cell dataKey="NOMBRE_ITEM" />
                </Column>
                <Column verticalAlign="middle" align="left">
                  <HeaderCell>PU CD</HeaderCell>
                  <Cell dataKey="PRECIO_ITEM">
                    {({ PRECIO_ITEM }) => `$${new Intl.NumberFormat().format(PRECIO_ITEM)}`}
                  </Cell>
                </Column>
                <Column verticalAlign="middle" align="left" width={60}>
                  <HeaderCell>Ud</HeaderCell>
                  <Cell dataKey="NOMABR_UNI">{(record) => record.NOMABR_UNI}</Cell>
                </Column>
                <Column verticalAlign="middle" align="center" width={60}>
                  <HeaderCell />
                  <Cell dataKey="DELETE">
                    {(record) => {
                      if (!_.isString(record.ID_ITEM)) return null;

                      return (
                        <i
                          className="fa fa-trash remove-resource-icon"
                          onClick={() => {
                            confirm({
                              title: `Esta a punto de eliminar ${
                                record.NOMBRE_TIN === 'Subpartida' ? 'la partida' : 'el recurso'
                              }  ${record.NOMBRE_ITEM} ?`,
                              content: 'Confirma esta acción',
                              onOk() {
                                removeResource(record.ID_ITEM);
                              },
                            });
                          }}
                        />
                      );
                    }}
                  </Cell>
                </Column>
              </Table>
            </div>
            <div className="modal-footer">
              <Button
                onClick={() =>
                  setState(
                    update(state, {
                      show: { $set: false },
                      filterValues: { $set: {} },
                      selectedResources: { $set: [] },
                      resources: { $set: state.initialResources },
                    })
                  )
                }
              >
                Cancelar
              </Button>
              <Button type="primary" onClick={save} disabled={!selectedResources.length}>
                Agregar
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

ResourceSelector.propTypes = {
  inputsTypes: commonPropTypes.isRequired,
  units: commonPropTypes.isRequired,
  addResources: PropTypes.func.isRequired,
  canAddSubItems: PropTypes.bool.isRequired,
  resourcesAndSubitems: PropTypes.object.isRequired,
  getResourcesAndSubitems: PropTypes.func.isRequired,
  createResourceOrSubitem: PropTypes.func.isRequired,
};

export default connect(
  ({ budget: { resourcesAndSubitems } }) => ({
    resourcesAndSubitems,
  }),
  (dispatch) => ({
    getResourcesAndSubitems: bindActionCreators(getResourcesAndSubitemsAction, dispatch),
    createResourceOrSubitem: bindActionCreators(createResourceOrSubitemAction, dispatch),
  })
)(ResourceSelector);
