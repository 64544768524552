import update from 'immutability-helper';

export const CLEAN_ITEM_DETAILS = 'CLEAN_ITEM_DETAILS';
export const GET_ENCLOSURE = 'GET_ENCLOSURE';
export const SET_ERROR_ENCLOSURE = 'SET_ERROR_ENCLOSURE';
export const SET_ADVANCE_IN_ITEM = 'SET_ADVANCE_IN_ITEM';
export const SET_ITEM_DETAILS = 'SET_ITEM_DETAILS';
export const SET_ITEM_EVIDENCE = 'SET_ITEM_EVIDENCE';

export const GET_ITEMS = 'GET_ITEMS';
export const SET_ERROR_ITEMS = 'SET_ERROR_ITEMS';
export const GET_ENCLOSURES_AND_ITEMS = 'GET_ENCLOSURES_AND_ITEMS';
export const GET_ENCLOSURE_TYPES = 'GET_ENCLOSURE_TYPES';
export const GET_ENCLOSURE_TREE = 'GET_ENCLOSURE_TREE';
export const GET_CHAPTERS = 'GET_CHAPTERS';

const initialState = {
  chapters: null,
  enclosures: null,
  enclosuresAndItems: null,
  enclosureTree: null,
  enclosureTypes: null,
  isFetching: true,
  itemDetails: null,
  items: null,
  error: '',
};

const enclosuresAndItems = (state = initialState, action) => {
  switch (action.type) {
    case SET_ERROR_ENCLOSURE: {
      const { error } = action.payload;
      return update(state, {
        error: { $set: error },
        isFetching: { $set: false },
      });
    }

    case GET_ENCLOSURE: {
      const { enclosures } = action.payload;
      return update(state, {
        enclosures: { $set: enclosures },
        isFetching: { $set: false },
      });
    }

    case GET_ITEMS: {
      const { items } = action.payload;
      return update(state, {
        items: { $set: items },
        isFetching: { $set: false },
      });
    }

    case GET_ENCLOSURES_AND_ITEMS: {
      const { enclosuresAndItems: data } = action.payload;
      return update(state, {
        enclosuresAndItems: { $set: data },
        isFetching: { $set: false },
      });
    }

    case GET_ENCLOSURE_TYPES: {
      const { enclosureTypes } = action.payload;
      return update(state, {
        enclosureTypes: { $set: enclosureTypes },
        isFetching: { $set: false },
      });
    }

    case GET_ENCLOSURE_TREE: {
      const { enclosureTree } = action.payload;
      return update(state, {
        enclosureTree: { $set: enclosureTree },
        isFetching: { $set: false },
      });
    }

    case CLEAN_ITEM_DETAILS: {
      return update(state, {
        itemDetails: { $set: null },
      });
    }

    case SET_ITEM_DETAILS: {
      const { details } = action.payload;

      return update(state, {
        itemDetails: { $set: details },
      });
    }

    case SET_ADVANCE_IN_ITEM: {
      const { advance, enclosureId, itemId } = action.payload;
      const index = state.enclosuresAndItems.findIndex(
        (e) => e.ID_UCO === enclosureId && e.ID_INS_EP === itemId
      );

      return update(state, {
        enclosuresAndItems: {
          [index]: {
            PRC_AVA_ACUM_APROB: { $set: advance },
          },
        },
      });
    }

    case SET_ITEM_EVIDENCE: {
      const { evidence } = action.payload;

      return update(state, {
        itemDetails: {
          EVIDENCIA: { $set: evidence },
        },
      });
    }

    case GET_CHAPTERS: {
      const { chapters } = action.payload;

      return update(state, {
        chapters: { $set: chapters },
      });
    }

    default:
      return state;
  }
};
export default enclosuresAndItems;
