import React, { useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import './index.scss';

const legends = (t) => {
  const textColorT = t('advanceControl.legends.textColor');
  const cellColorT = t('advanceControl.legends.cellColor');
  const iconT = t('advanceControl.legends.icon');

  return [
    {
      type: textColorT,
      content: <div className="legend-color" style={{ background: 'green' }} />,
      description: t('advanceControl.legends.items.0'),
    },
    {
      type: cellColorT,
      content: <div className="legend-color" style={{ background: '#ffffc4' }} />,
      description: t('advanceControl.legends.items.1'),
    },
    {
      type: iconT,
      content: <i className="fas fa-solid fa-registered  info-icons red" />,
      description: t('advanceControl.legends.items.2'),
    },
    {
      type: iconT,
      content: <i className="fas fa-solid fa-registered  info-icons blue" />,
      description: t('advanceControl.legends.items.3'),
    },
    {
      type: iconT,
      content: <i className="fas fa-exclamation-circle info-icons gray" />,
      description: t('advanceControl.legends.items.4'),
    },
    {
      type: iconT,
      content: <i className="fas fa-exclamation-circle info-icons blue" />,
      description: t('advanceControl.legends.items.5'),
    },
    {
      type: iconT,
      content: (
        <div className="d-flex justify-content-center">
          <div className="info-icons diamond">
            <div />
          </div>
        </div>
      ),
      description: t('advanceControl.legends.items.6'),
    },
    {
      type: iconT,
      content: <i className="fas fa-calendar info-icons yellow" />,
      description: t('advanceControl.legends.items.7'),
    },
    {
      type: iconT,
      content: <i className="fas fa-camera info-icons" />,
      description: t('advanceControl.legends.items.8'),
    },
  ];
};

const Legend = () => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  return (
    <div className="legend ml-2">
      <InfoCircleOutlined className="ml-2 legendButton" onClick={() => setShow(true)} />
      {show && (
        <div className="legend-container">
          <div className="legend-header">
            <div />
            <span>{t('advanceControl.legends.title')}</span>
            <i className="fa fa-times" onClick={() => setShow(false)} />
          </div>
          <div className="legend-content">
            <div className="d-flex justify-content-between legend-content mb-3 w-100 border-bottom pb-2">
              <div>
                <strong>Tipo</strong>
              </div>
              <div />
              <div>
                <strong>Significado</strong>
              </div>
            </div>
            {legends(t).map(({ type, content, description }) => {
              return (
                <div className="d-flex justify-content-between legend-content mb-2 w-100">
                  <div className="legend-color-text">{type}</div>
                  <div>{content}</div>
                  <div className="legend-description">{description}</div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Legend;
