import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Column, HeaderCell, Cell, ColumnGroup } from 'rsuite-table';

import DoubleLineCell from '../../components/DoubleLineCell';
import EditableCell from '../../components/EditableCell/Production';
import { hiddeCellRender } from '../../utils/budgetAndProductionHelpers';
import { getFormattedNumber } from '../../utils';

import './index.scss';

export const getTotals = (
  record,
  key = 'CANTIDAD_MOD',
  ID_NAT = 4,
  priceKey = 'PRECIO_ITEM',
  extraCondition = () => false
) => {
  let total =
    record.ID_NAT === ID_NAT || extraCondition(record) ? record[key] * record[priceKey] : 0;
  if (record.ID_NAT !== ID_NAT && record.children && record.children.length) {
    total = record.children.reduce((acc, childrenRecord) => {
      return acc + getTotals(childrenRecord, key, ID_NAT, priceKey, extraCondition);
    }, 0);
  }
  return total;
};

const SwitchCell = ({ rowData, handleSave, isEditableProduction, helpers, ...rest }) => {
  const { ID_NAT, TIPO_AVANCE, ID_ITEM, PATH } = rowData;
  if (ID_NAT !== 3) return <Cell {...rest} />;
  return (
    <Cell
      width={75}
      fixed
      dataKey="ADVANCE"
      className={`switch-cell border-right ${
        isEditableProduction && ID_NAT === 3 ? 'editable' : ''
      }`}
      {...rest}
    >
      <Switch
        className="advance-switch"
        checked={!!TIPO_AVANCE}
        disabled={!isEditableProduction}
        onChange={(checked) => {
          const item = {
            TIPO_AVANCE: checked ? 1 : 0,
            PATH,
            ID_ITEM_SUP: rowData.ID_ITEM_SUP,
            ID_ITEM: rowData.ID_ITEM,
            COMENTARIO_AVA: rowData.COMENTARIO_AVA || '',
            ID_NAT: rowData.ID_NAT,
          };

          const elementsToUpdate = [];
          if (checked) {
            if (rowData.ORIGINAL_TIPO_AVANCE === 0 || rowData.ORIGINAL_TIPO_AVANCE === undefined) {
              rowData.children.forEach((resource) => {
                const fraction = rowData.CANT_AVA / rowData.CANTIDAD_MOD;
                const resourceInfo = {
                  CANT_AVA: fraction * resource.CANTIDAD_MOD,
                  TIPO_AVANCE: 0,
                  ID_ITEM_SUP: resource.ID_ITEM_SUP,
                  PATH: resource.PATH,
                  ID_ITEM: resource.ID_ITEM,
                  COMENTARIO_AVA: resource.COMENTARIO_AVA || '',
                  ID_NAT: resource.ID_NAT,
                  PORC_AVA: fraction * 100,
                  TOTAL_AVA: fraction * resource.CANTIDAD_MOD * resource.PRECIO_ITEM,
                };

                elementsToUpdate.push(resourceInfo);
              });
              item.CANT_AVA = rowData.CANT_AVA;
            } else if (rowData.ORIGINAL_TIPO_AVANCE === 1) {
              const parentTotal = rowData.children.reduce((acc, current) => {
                const info = {
                  CANT_AVA: current.CANT_AVA,
                  TIPO_AVANCE: 0,
                  ID_ITEM_SUP: current.ID_ITEM_SUP,
                  PATH: current.PATH,
                  ID_ITEM: current.ID_ITEM,
                  COMENTARIO_AVA: current.COMENTARIO_AVA || '',
                  ID_NAT: current.ID_NAT,
                };

                elementsToUpdate.push(info);
                return acc + current.CANT_AVA * current.PRECIO_ITEM;
              }, 0);
              const quantity = rowData.PRECIO_ITEM !== 0 ? parentTotal / rowData.PRECIO_ITEM : 0;
              const percentage =
                rowData.PRECIO_ITEM !== 0 && rowData.CANTIDAD_MOD !== 0
                  ? (parentTotal * 100) / (rowData.PRECIO_ITEM * rowData.CANTIDAD_MOD)
                  : 0;
              item.CANT_AVA = quantity;
              item.TOTAL_AVA = parentTotal;
              item.PORC_AVA = percentage;
            }
          } else {
            item.CANT_AVA = rowData.CANT_AVA;
          }

          elementsToUpdate.push(item);

          if (rowData.ORIGINAL_TIPO_AVANCE === undefined) {
            item.ORIGINAL_TIPO_AVANCE = rowData.TIPO_AVANCE;
          }

          handleSave(elementsToUpdate);
          if (!checked) {
            helpers.changeExpandedRows(false, ID_ITEM);
          }
        }}
      />
    </Cell>
  );
};

SwitchCell.propTypes = {
  rowData: PropTypes.object.isRequired,
  handleSave: PropTypes.func.isRequired,
  helpers: PropTypes.shape({
    changeExpandedRows: PropTypes.func,
  }).isRequired,
  isEditableProduction: PropTypes.bool.isRequired,
};

const columns = (activeOptions, activeEdition, handleSave, helpers, fieldsToSave) => {
  const isEditableProduction = activeEdition === 'editProduction';
  const columnsResult = [
    <Column
      resizable
      className="border-right"
      fixed
      verticalAlign="middle"
      align="center"
      width={75}
    >
      <HeaderCell>
        <DoubleLineCell className="centered" firstValue="Avance por" secondValue="Recurso" />
      </HeaderCell>
      <SwitchCell
        handleSave={handleSave}
        helpers={helpers}
        isEditableProduction={isEditableProduction}
      />
    </Column>,
    <Column
      resizable
      className="border-right "
      fixed
      verticalAlign="middle"
      align="center"
      width={90}
    >
      <HeaderCell>Ítem </HeaderCell>
      <Cell dataKey="CODIGO_ITEM" />
    </Column>,
    <Column
      resizable
      className="border-right"
      fixed
      treeCol
      verticalAlign="middle"
      align="left"
      width={400}
    >
      <HeaderCell>Descripción </HeaderCell>
      <Cell dataKey="NOMBRE_ITEM" />
    </Column>,

    ...(activeOptions.option3
      ? [
          <Column
            resizable
            className="border-right "
            verticalAlign="middle"
            align="right"
            width={120}
          >
            <HeaderCell>PU CD</HeaderCell>
            <Cell dataKey="PRECIO_ITEM" className="align-right">
              {(record) =>
                record.ID_NAT === 1
                  ? null
                  : getFormattedNumber(record.PRECIO_ITEM, record, true, '$', 0)
              }
            </Cell>
          </Column>,
        ]
      : []),
    <Column resizable className="border-right" verticalAlign="middle" align="center" width={70}>
      <HeaderCell>Ud</HeaderCell>
      <Cell dataKey="NOMABR_UNI">{(record) => hiddeCellRender(record.NOMABR_UNI, record)}</Cell>
    </Column>,
    <Column resizable className="border-right" verticalAlign="middle" align="right" width={130}>
      <HeaderCell>PU Venta</HeaderCell>
      <Cell dataKey="PRECIO_ITEM_VENTA" className="align-right">
        {(record) =>
          record.ID_NAT === 1
            ? null
            : getFormattedNumber(record.PRECIO_ITEM_VENTA, record, true, '$', 0)
        }
      </Cell>
    </Column>,
    <ColumnGroup resizable align="center" header="Forecast">
      {[
        <Column resizable verticalAlign="right" width={90}>
          <HeaderCell>Cantidad</HeaderCell>
          <Cell dataKey="CANTIDAD_MOD_" className="align-right">
            {(record) => {
              if (record.ID_NAT === 1) return null;
              const decimal = record.ID_NAT === 3 ? 1 : 3;
              return (
                <span title={`${getFormattedNumber(record.CANTIDAD_MOD)}`}>
                  {getFormattedNumber(record.CANTIDAD_MOD, decimal)}
                </span>
              );
            }}
          </Cell>
        </Column>,
        ...(activeOptions.option3
          ? [
              <Column
                resizable
                className={(!activeOptions.option2 ? 'border-right-ext' : '', 'align-right')}
                verticalAlign="middle"
                align="center"
                width={140}
              >
                <HeaderCell>Total CD</HeaderCell>
                <Cell dataKey="TOTAL_MOD" className="align-right">
                  {({ CANTIDAD_MOD, PRECIO_ITEM }) => {
                    const total = CANTIDAD_MOD * PRECIO_ITEM;
                    return (
                      <span title={`$${getFormattedNumber(total)}`}>{`$${getFormattedNumber(
                        total,
                        null,
                        true
                      )}`}</span>
                    );
                  }}
                </Cell>
              </Column>,
            ]
          : []),
        ...(activeOptions.option2
          ? [
              <Column
                resizable
                className="border-right align-right"
                verticalAlign="middle"
                align="center"
                width={140}
              >
                <HeaderCell>Total Venta</HeaderCell>
                <Cell dataKey="TOTAL_VENTA" className="align-right">
                  {({ CANTIDAD_MOD, PRECIO_ITEM_VENTA }) => {
                    const total = CANTIDAD_MOD * PRECIO_ITEM_VENTA;
                    return (
                      <span title={`$${getFormattedNumber(total)}`}>{`$${getFormattedNumber(
                        total,
                        null,
                        true
                      )}`}</span>
                    );
                  }}
                </Cell>
              </Column>,
            ]
          : []),
      ]}
    </ColumnGroup>,
    <ColumnGroup
      align="center"
      header={
        helpers.selectedAdvanceDate ? (
          <DoubleLineCell
            firstValue="Producción Acum."
            secondValue={` al ${helpers.selectedAdvanceDate || ''}`}
          />
        ) : (
          'No existe Producción Acum.'
        )
      }
    >
      {[
        <Column verticalAlign="middle" width={isEditableProduction ? 140 : 80}>
          <HeaderCell>%</HeaderCell>
          <EditableCell
            dataIndex="PORC_AVA"
            editable={isEditableProduction}
            unit="%"
            decimalsIndex={0}
            isUnitPrefix={false}
            includeRecordFields={['ID_ITEM_SUP', 'ID_NAT']}
            validator={(value) => value >= 0 && value <= 100}
            maxButtonValue={() => 100}
            additionalInputFields={() => ({
              type: 'number',
              min: 0,
              max: 100,
            })}
            calculateValue={(record) => {
              if (record.ID_NAT !== 1) {
                const totalAcc = record.PRECIO_ITEM * record.CANT_AVA;
                const totalMod = record.PRECIO_ITEM * record.CANTIDAD_MOD;

                if (totalAcc === 0 || totalMod === 0) return 0;
                return (totalAcc * 100) / totalMod;
              }

              const totalAcc = getTotals(record, 'CANT_AVA', 3);
              const totalMod = record.CANTIDAD_MOD * record.PRECIO_ITEM;
              if (totalAcc === 0 || totalMod === 0) return 0;

              return (totalAcc * 100) / totalMod;
            }}
            handleSave={handleSave}
            objectModifier={(obj, record, value) => {
              const quantity = record.CANTIDAD_MOD;
              const cant = (value * quantity) / 100;
              obj.CANT_AVA = (value * record.CANTIDAD_MOD) / 100;
              obj.TOTAL_AVA = cant * record.PRECIO_ITEM;
              obj.COMENTARIO_AVA = record.COMENTARIO_AVA || '';

              if ((record.ID_NAT === 4 && record.ID_NAT_SUP === 3) || record.ID_NAT === 5) {
                obj.PERCENTAGE_MODIFIED = true;
              }

              return obj;
            }}
            editItem={(record, value) => {
              if ((record.ID_NAT === 4 && record.ID_NAT_SUP === 3) || record.ID_NAT === 5) {
                const pathArray = record.PATH.split('.').map((e) => Number(e));

                const parent = helpers.getItemNode(pathArray, 0);
                const total = parent.children.reduce((acc, current) => {
                  const currentTotal =
                    current.ID_ITEM === record.ID_ITEM
                      ? (value * current.PRECIO_ITEM * current.CANTIDAD_MOD) / 100
                      : current.CANT_AVA * current.PRECIO_ITEM;
                  return acc + currentTotal;
                }, 0);

                const parentQuantity = total / parent.PRECIO_ITEM;
                const item = {
                  ID_ITEM: parent.ID_ITEM,
                  PATH: parent.PATH,
                  CANT_AVA: parentQuantity,
                  ID_ITEM_SUP: parent.ID_ITEM_SUP,
                  TOTAL_AVA: total,
                  PORC_AVA: (total * 100) / (parent.PRECIO_ITEM * parent.CANTIDAD_MOD),
                  TIPO_AVANCE: 1,
                  COMENTARIO_AVA: parent.COMENTARIO_AVA,
                  ID_NAT: parent.ID_NAT,
                };
                return item;
              }
              return null;
            }}
            conditionToEditable={(record) =>
              (record.ID_NAT === 4 && record.ID_NAT_SUP === 3) ||
              record.ID_NAT === 5 ||
              (!record.TIPO_AVANCE && record.ID_NAT === 3)
            }
            className={() => `${isEditableProduction ? 'editable' : ''}`}
          />
        </Column>,
        <Column resizable verticalAlign="middle" align="center" width={140}>
          <HeaderCell>Cantidad</HeaderCell>
          <EditableCell
            dataIndex="CANT_AVA"
            decimalsIndex={(record) => {
              if (record.ID_NAT === 3) {
                return 1;
              }
              if (record.ID_NAT === 4) {
                return 3;
              }
              return null;
            }}
            calculateValue={(record) => {
              if (record.ID_NAT === 3) {
                if (record.TIPO_AVANCE === 1) {
                  const totalAcc = getTotals(
                    record,
                    'CANT_AVA',
                    4,
                    'PRECIO_ITEM',
                    (e) => e.ID_NAT === 5
                  );

                  if (record.PRECIO_ITEM === 0) return 0;
                  const result = (totalAcc * record.CANTIDAD_MOD) / record.PRECIO_ITEM;
                  return result;
                }
                return record.CANT_AVA;
              }

              if ((record.ID_NAT === 4 && record.ID_NAT_SUP === 3) || record.ID_NAT === 5) {
                return record.CANT_AVA;
              }

              return null;
            }}
            maxButtonValue={({ CANTIDAD_MOD }) => {
              return CANTIDAD_MOD;
            }}
            canShow={(record) => record.ID_NAT !== 1}
            editable={isEditableProduction}
            includeRecordFields={['ID_ITEM_SUP', 'ID_NAT']}
            handleSave={handleSave}
            validator={(value, record) => {
              return value >= 0 && (record.ID_NAT === 3 || value <= record.CANTIDAD_MOD);
            }}
            confirm={{
              title: 'La cantidad ingresada supera la cantidad forecast',
              icon: <ExclamationCircleOutlined />,
              content: 'Desea actualizar la cantidad?',
              okText: 'Aceptar',
              cancelText: 'Cancelar',
            }}
            conditionToConfirm={(value, record) =>
              record.ID_NAT === 3 && value > record.CANTIDAD_MOD
            }
            additionalInputFields={(record) => {
              return {
                type: 'number',
                min: 0,
                ...(record.ID_NAT !== 3 ? { max: record.CANTIDAD_MOD } : {}),
              };
            }}
            objectModifier={(obj, record, value) => {
              obj.PORC_AVA = (value * 100) / record.CANTIDAD_MOD;

              if (value > record.CANTIDAD_MOD) {
                obj.PORC_AVA = 100;
              }
              obj.TOTAL_AVA = value * record.PRECIO_ITEM;
              obj.COMENTARIO_AVA = record.COMENTARIO_AVA || '';
              if ((record.ID_NAT === 4 && record.ID_NAT_SUP === 3) || record.ID_NAT === 5) {
                obj.PERCENTAGE_MODIFIED = true;
              }

              if (record.ID_NAT === 3 && value > record.CANTIDAD_MOD) {
                obj.CANTIDAD_MOD = value;
              }

              return obj;
            }}
            editItem={(record, value) => {
              if ((record.ID_NAT === 4 && record.ID_NAT_SUP === 3) || record.ID_NAT === 5) {
                const pathArray = record.PATH.split('.').map((e) => Number(e));

                const parent = helpers.getItemNode(pathArray, 0);
                const total = parent.children.reduce((acc, current) => {
                  const currentTotal =
                    current.ID_ITEM === record.ID_ITEM
                      ? value * current.PRECIO_ITEM
                      : current.CANT_AVA * current.PRECIO_ITEM;
                  return acc + currentTotal;
                }, 0);

                const parentQuantity = parent.PRECIO_ITEM !== 0 ? total / parent.PRECIO_ITEM : 0;
                const info = {
                  ID_ITEM: parent.ID_ITEM,
                  PATH: parent.PATH,
                  CANT_AVA: parentQuantity,
                  ID_ITEM_SUP: parent.ID_ITEM_SUP,
                  TOTAL_AVA: total,
                  PORC_AVA:
                    parent.PRECIO_ITEM !== 0 && parent.CANTIDAD_MOD !== 0
                      ? (total * 100) / (parent.PRECIO_ITEM * parent.CANTIDAD_MOD)
                      : 0,
                  TIPO_AVANCE: 1,
                  COMENTARIO_AVA: parent.COMENTARIO_AVA,
                  ID_NAT: parent.ID_NAT,
                };
                return info;
              }
              return null;
            }}
            conditionToEditable={(record) =>
              (record.ID_NAT === 4 && record.ID_NAT_SUP === 3) ||
              record.ID_NAT === 5 ||
              (!record.TIPO_AVANCE && record.ID_NAT === 3)
            }
            customClass="align-right"
            className={() => `align-right ${isEditableProduction ? 'editable' : ''}`}
          />
        </Column>,
        ...(activeOptions.option3
          ? [
              <Column
                resizable
                className={() => `align-right ${!activeOptions.option2 ? 'border-right-ext' : ''}`}
                verticalAlign="middle"
                align="center"
                width={140}
              >
                <HeaderCell>Total CD</HeaderCell>
                <EditableCell
                  onlyInteger
                  dataIndex="TOTAL_AVA"
                  editable={isEditableProduction}
                  validator={(value, record) => {
                    const quantity = record.CANTIDAD_MOD;
                    return value >= 0 && value <= quantity * record.PRECIO_ITEM;
                  }}
                  maxButtonValue={({ CANTIDAD_MOD, PRECIO_ITEM }) => {
                    const quantity = CANTIDAD_MOD;
                    return quantity * PRECIO_ITEM;
                  }}
                  includeRecordFields={['ID_ITEM_SUP', 'ID_NAT']}
                  additionalInputFields={(record) => {
                    return {
                      type: 'number',
                      min: 0,
                      max: record.CANTIDAD_MOD * record.PRECIO_ITEM,
                    };
                  }}
                  unit="$"
                  calculateValue={(record) => {
                    if (record.ID_NAT === 3) {
                      if (record.TIPO_AVANCE === 1) {
                        const totalAcc = getTotals(
                          record,
                          'CANT_AVA',
                          4,
                          'PRECIO_ITEM',
                          (e) => e.ID_NAT === 5
                        );
                        return totalAcc;
                      }
                      return record.CANT_AVA * record.PRECIO_ITEM;
                    }

                    if (record.ID_NAT === 1) {
                      return getTotals(record, 'CANT_AVA', 3);
                    }

                    return record.CANT_AVA * record.PRECIO_ITEM;
                  }}
                  objectModifier={(obj, record, value) => {
                    const cant = Number(value) / record.PRECIO_ITEM;
                    const updatedQuantity = record.CANTIDAD_MOD;
                    obj.CANT_AVA = cant;
                    obj.PORC_AVA = (cant * 100) / updatedQuantity;
                    obj.COMENTARIO_AVA = record.COMENTARIO_AVA || '';
                    if ((record.ID_NAT === 4 && record.ID_NAT_SUP === 3) || record.ID_NAT === 5) {
                      obj.PERCENTAGE_MODIFIED = true;
                    }

                    return obj;
                  }}
                  editItem={(record, value) => {
                    if ((record.ID_NAT === 4 && record.ID_NAT_SUP === 3) || record.ID_NAT === 5) {
                      const pathArray = record.PATH.split('.').map((e) => Number(e));

                      const parent = helpers.getItemNode(pathArray, 0);
                      const total = parent.children.reduce((acc, current) => {
                        const currentTotal =
                          current.ID_ITEM === record.ID_ITEM
                            ? value
                            : current.CANT_AVA * current.PRECIO_ITEM;
                        return acc + currentTotal;
                      }, 0);

                      const parentQuantity = total / parent.PRECIO_ITEM;
                      const info = {
                        ID_ITEM: parent.ID_ITEM,
                        PATH: parent.PATH,
                        CANT_AVA: parentQuantity,
                        TOTAL_AVA: total,
                        PORC_AVA: (total * 100) / (parent.PRECIO_ITEM * parent.CANTIDAD_MOD),
                        ID_ITEM_SUP: parent.ID_ITEM_SUP,
                        TIPO_AVANCE: 1,
                        COMENTARIO_AVA: parent.COMENTARIO_AVA,
                        ID_NAT: parent.ID_NAT,
                      };
                      return info;
                    }
                    return null;
                  }}
                  handleSave={handleSave}
                  conditionToEditable={(record) =>
                    (record.ID_NAT === 4 && record.ID_NAT_SUP === 3) ||
                    record.ID_NAT === 5 ||
                    (!record.TIPO_AVANCE && record.ID_NAT === 3)
                  }
                  customClass="align-right"
                  className={() => `align-righ ${isEditableProduction ? 'editable' : ''}`}
                />
              </Column>,
            ]
          : []),
        ...(activeOptions.option2
          ? [
              <Column
                resizable
                className="border-right-ext  align-right"
                verticalAlign="middle"
                align="center"
                width={140}
              >
                <HeaderCell>Total Venta</HeaderCell>
                <Cell dataKey="TOTAL_VENTA_AVA" className="align-right">
                  {(record) => {
                    let total = record.CANT_AVA * record.PRECIO_ITEM_VENTA;
                    if (record.ID_NAT === 3 && record.TIPO_AVANCE === 1) {
                      total = getTotals(
                        record,
                        'CANT_AVA',
                        4,
                        'PRECIO_ITEM_VENTA',
                        (e) => e.ID_NAT === 5
                      );
                    }

                    if (record.ID_NAT === 1) {
                      total = getTotals(record, 'CANT_AVA', 3, 'PRECIO_ITEM_VENTA');
                    }

                    return (
                      <span title={`$${getFormattedNumber(total)}`}>{`$${getFormattedNumber(
                        total,
                        null,
                        true
                      )}`}</span>
                    );
                  }}
                </Cell>
              </Column>,
            ]
          : []),
      ]}
    </ColumnGroup>,
    ...(activeOptions.option1
      ? [
          <ColumnGroup
            align="center"
            header={
              helpers.selectedPreviousAdvanceDate ? (
                <DoubleLineCell
                  firstValue="Producción Acum. Anterior al"
                  secondValue={helpers.selectedPreviousAdvanceDate}
                />
              ) : (
                'No existe Producción Anterior'
              )
            }
          >
            {[
              <Column resizable verticalAlign="middle" width={80}>
                <HeaderCell>%</HeaderCell>
                <Cell dataKey="PROD_PORCENTAJE">
                  {(record) => {
                    const totalPrevAcc = getTotals(record, 'CANT_AVA_ANT', 3);
                    const totalMod = getTotals(record, 'CANTIDAD_MOD', 3);
                    if (totalPrevAcc === 0) {
                      return <span title="0%">0%</span>;
                    }
                    const total = (totalPrevAcc * 100) / totalMod;
                    return (
                      <span title={`${getFormattedNumber(total)}%`}>{`${getFormattedNumber(
                        total,
                        0
                      )}%`}</span>
                    );
                  }}
                </Cell>
              </Column>,
              <Column resizable verticalAlign="middle" align="center" width={140}>
                <HeaderCell>Cantidad</HeaderCell>
                <Cell dataKey="CANT_AVA_ANT" className="align-right">
                  {(record) => {
                    if (record.ID_NAT === 1) return null;
                    const decimal = record.ID_NAT === 3 ? 1 : 3;
                    return (
                      <span title={`$${getFormattedNumber(record.CANT_AVA_ANT)}`}>
                        {getFormattedNumber(record.CANT_AVA_ANT, decimal)}
                      </span>
                    );
                  }}
                </Cell>
              </Column>,
              ...(activeOptions.option3
                ? [
                    <Column
                      resizable
                      className={`align-right ${!activeOptions.option2 ? 'border-right-ext' : ''}`}
                      verticalAlign="middle"
                      align="center"
                      width={140}
                    >
                      <HeaderCell>Total CD</HeaderCell>
                      <Cell dataKey="TOTAL_ACUM_ANT" className="align-right">
                        {(record) => {
                          const total = getTotals(record, 'CANT_AVA_ANT', 3);
                          return (
                            <span title={`$${getFormattedNumber(total)}`}>{`$${getFormattedNumber(
                              total,
                              null,
                              true
                            )}`}</span>
                          );
                        }}
                      </Cell>
                    </Column>,
                  ]
                : []),
              ...(activeOptions.option2
                ? [
                    <Column
                      resizable
                      className="border-right-ext align-right"
                      verticalAlign="middle"
                      align="center"
                      width={140}
                    >
                      <HeaderCell>Total Venta</HeaderCell>
                      <Cell dataKey="TOTAL_VENTA_AVA_ANT" className="align-right">
                        {(record) => {
                          let total = record.CANT_AVA_ANT * record.PRECIO_ITEM_VENTA;
                          if (record.ID_NAT === 3 && record.TIPO_AVANCE === 1) {
                            total = getTotals(
                              record,
                              'CANT_AVA_ANT',
                              4,
                              'PRECIO_ITEM_VENTA',
                              (e) => e.ID_NAT === 5
                            );
                          }

                          if (record.ID_NAT === 1) {
                            total = getTotals(record, 'CANT_AVA_ANT', 3, 'PRECIO_ITEM_VENTA');
                          }

                          return (
                            <span title={`$${getFormattedNumber(total)}`}>{`$${getFormattedNumber(
                              total,
                              null,
                              true
                            )}`}</span>
                          );
                        }}
                      </Cell>
                    </Column>,
                  ]
                : []),
            ]}
          </ColumnGroup>,
        ]
      : []),

    ...(activeOptions.option0
      ? [
          <ColumnGroup align="center" header="Producción Parcial">
            {[
              <Column resizable verticalAlign="middle" width={80}>
                <HeaderCell>%</HeaderCell>
                <Cell dataKey="PORCENTAJE_AVA">
                  {(record) => {
                    let totalAcc = record.PRECIO_ITEM * record.CANT_AVA;
                    const totalMod = record.PRECIO_ITEM * record.CANTIDAD_MOD;
                    let totalAccAnt = record.PRECIO_ITEM * record.CANT_AVA_ANT;

                    if (record.ID_NAT === 1) {
                      totalAcc = getTotals(record, 'CANT_AVA', 3);
                      totalAccAnt = getTotals(record, 'CANT_AVA_ANT', 3);
                      console.log('##', totalAcc, totalAccAnt, totalMod);
                    }

                    const totalPercAcc = totalAcc && totalMod ? (totalAcc * 100) / totalMod : 0;
                    const totalPercAccAnt =
                      totalAccAnt && totalMod ? (totalAccAnt * 100) / totalMod : 0;

                    const total = totalPercAcc - totalPercAccAnt;

                    return (
                      <span title={`${getFormattedNumber(total)}%`}>{`${getFormattedNumber(
                        total,
                        0
                      )}%`}</span>
                    );
                  }}
                </Cell>
              </Column>,
              <Column resizable verticalAlign="middle" align="center" width={140}>
                <HeaderCell>Cantidad</HeaderCell>
                <Cell dataKey="CANTIDAD" className="align-right">
                  {(record) => {
                    if (record.ID_NAT === 1) return null;
                    let result;
                    if (record.ID_NAT === 3) {
                      if (record.TIPO_AVANCE === 1) {
                        const totalAcc = getTotals(
                          record,
                          'CANT_AVA',
                          4,
                          'PRECIO_ITEM',
                          (e) => e.ID_NAT === 5
                        );
                        const totalAccAnt = getTotals(
                          record,
                          'CANT_AVA_ANT',
                          4,
                          'PRECIO_ITEM',
                          (e) => e.ID_NAT === 5
                        );

                        const totalCantAva = (totalAcc * record.CANTIDAD_MOD) / record.PRECIO_ITEM;
                        const totalCantAvaAnt =
                          (totalAccAnt * record.CANTIDAD_MOD) / record.PRECIO_ITEM;
                        result = totalCantAva - totalCantAvaAnt;
                      }
                      result = record.CANT_AVA - record.CANT_AVA_ANT;
                    }

                    if (record.ID_NAT === 4 || record.ID_NAT === 5) {
                      result = record.CANT_AVA - record.CANT_AVA_ANT;
                    }
                    const decimal = record.ID_NAT === 3 ? 1 : 3;
                    return (
                      <span title={`${getFormattedNumber(result)}`}>{`${getFormattedNumber(
                        result,
                        decimal
                      )}`}</span>
                    );
                  }}
                </Cell>
              </Column>,
              ...(activeOptions.option3
                ? [
                    <Column resizable verticalAlign="middle" align="center" width={140}>
                      <HeaderCell>Total CD</HeaderCell>
                      <Cell dataKey="PROD_TOTAL_PARCIAL" className="align-right">
                        {(record) => {
                          let result;
                          if (record.ID_NAT === 3) {
                            if (record.TIPO_AVANCE === 1) {
                              const totalAcc = getTotals(
                                record,
                                'CANT_AVA',
                                4,
                                'PRECIO_ITEM',
                                (e) => e.ID_NAT === 5
                              );
                              const totalAccAnt = getTotals(
                                record,
                                'CANT_AVA_ANT',
                                4,
                                'PRECIO_ITEM',
                                (e) => e.ID_NAT === 5
                              );
                              result = (totalAcc - totalAccAnt) * record.CANTIDAD_MOD;
                            }
                            result = (record.CANT_AVA - record.CANT_AVA_ANT) * record.PRECIO_ITEM;
                          } else if (
                            (record.ID_NAT === 4 && record.ID_NAT_SUP === 3) ||
                            record.ID_NAT === 5
                          ) {
                            result = (record.CANT_AVA - record.CANT_AVA_ANT) * record.PRECIO_ITEM;
                          } else {
                            const totalAcc = getTotals(record, 'CANT_AVA', 3);
                            const totalAccAnt = getTotals(record, 'CANT_AVA_ANT', 3);
                            result = (totalAcc - totalAccAnt) * record.CANTIDAD_MOD;
                          }

                          return (
                            <div
                              title={getFormattedNumber(result, null, false, '$')}
                              className={result < 0 ? 'negative' : ''}
                            >
                              {getFormattedNumber(result, null, true, '$')}
                            </div>
                          );
                        }}
                      </Cell>
                    </Column>,
                  ]
                : []),
              ...(activeOptions.option2
                ? [
                    <Column resizable verticalAlign="middle" align="center" width={140}>
                      <HeaderCell>Total Venta</HeaderCell>
                      <Cell dataKey="PROD_TOTAL_PARCIAL_VENTA" className="align-right">
                        {(record) => {
                          let result;
                          if (record.ID_NAT === 3) {
                            if (record.TIPO_AVANCE === 1) {
                              const totalAcc = getTotals(
                                record,
                                'CANT_AVA',
                                4,
                                'PRECIO_ITEM_VENTA',
                                (e) => e.ID_NAT === 5
                              );
                              const totalAccAnt = getTotals(
                                record,
                                'CANT_AVA_ANT',
                                4,
                                'PRECIO_ITEM_VENTA',
                                (e) => e.ID_NAT === 5
                              );
                              result = (totalAcc - totalAccAnt) * record.CANTIDAD_MOD;
                            }
                            result =
                              (record.CANT_AVA - record.CANT_AVA_ANT) * record.PRECIO_ITEM_VENTA;
                          } else if (
                            (record.ID_NAT === 4 && record.ID_NAT_SUP === 3) ||
                            record.ID_NAT === 5
                          ) {
                            result =
                              (record.CANT_AVA - record.CANT_AVA_ANT) * record.PRECIO_ITEM_VENTA;
                          } else {
                            const totalAcc = getTotals(record, 'CANT_AVA', 3, 'PRECIO_ITEM_VENTA');
                            const totalAccAnt = getTotals(
                              record,
                              'CANT_AVA_ANT',
                              3,
                              'PRECIO_ITEM_VENTA'
                            );
                            result = (totalAcc - totalAccAnt) * record.CANTIDAD_MOD;
                          }
                          return (
                            <span title={`$${getFormattedNumber(result)}`}>{`$${getFormattedNumber(
                              result,
                              null,
                              true
                            )}`}</span>
                          );
                        }}
                      </Cell>
                    </Column>,
                  ]
                : []),
            ]}
          </ColumnGroup>,
        ]
      : []),
    ...(isEditableProduction
      ? [
          <Column resizable verticalAlign="middle" width={90}>
            <HeaderCell align="center">
              <DoubleLineCell firstValue="Motivo" secondValue="Comentario" />
            </HeaderCell>
            <EditableCell
              dataIndex="COMENTARIO_AVA"
              editable
              handleSave={handleSave}
              type="string"
              canShow={(record) => record.ID_NAT !== 1}
              conditionToEditable={(record) =>
                record.ID_NAT !== 1 &&
                fieldsToSave.find(({ ID_ITEM }) => record.ID_ITEM === ID_ITEM)
              }
              className={() => 'editable'}
            />
          </Column>,
        ]
      : []),
  ];
  return columnsResult;
};

export default columns;
