import update from 'immutability-helper';
import get from 'lodash/get';
import moment from 'moment';

export const BASE_TENDER_FETCHED = 'BASE_TENDER_FETCHED';
export const GET_BASE_TENDER = 'GET_BASE_TENDER';
export const GET_BASE_TENDER_ERROR = 'GET_BASE_TENDER_ERROR';

export const BASE_TENDER_ADD_MONTH = 'BASE_TENDER_ADD_MONTH';
export const GET_INPUTS_TYPES_GG_TENDER = 'GET_INPUTS_TYPES_GG_TENDER';
export const GET_INPUTS_TYPES_GG_TENDER_ERROR = 'GET_INPUTS_TYPES_GG_TENDER_ERROR';
export const INPUTS_TYPES_GG_FETCHED_TENDER = 'INPUTS_TYPES_GG_FETCHED_TENDER';

const baseTenderInit = {
  data: {},
  isFetching: false,
  error: false,
  inputsTypes: { data: null, isFetching: false, error: false },
};

const monthsNames = [
  'Ene',
  'Feb',
  'Mar',
  'Abr',
  'May',
  'Jun',
  'Jul',
  'Ago',
  'Sep',
  'Oct',
  'Nov',
  'Dic',
];

const baseTender = (state = baseTenderInit, action) => {
  switch (action.type) {
    case 'WORKS_FETCHED': {
      let newCas = { ...state };
      action.works.forEach(({ ID_OBR }) => {
        newCas = update(newCas, {
          data: {
            [ID_OBR]: { $set: null },
          },
        });
      });

      return newCas;
    }

    case BASE_TENDER_FETCHED: {
      return update(state, {
        data: {
          [action.workId]: {
            $set: action.baseTender,
          },
        },
        isFetching: { $set: false },
        error: { $set: false },
      });
    }

    case GET_BASE_TENDER_ERROR:
      return update(state, { error: { $set: true }, isFetching: { $set: false } });
    case GET_BASE_TENDER:
      return {
        ...state,
        isFetching: true,
        error: false,
      };

    case BASE_TENDER_ADD_MONTH: {
      const months = get(state, ['data', action.workId, 'MESES']);

      const lastMonth = months.length ? months[months.length - 1] : null;

      const newDate = lastMonth
        ? moment(`${lastMonth.MES}-${lastMonth.ANO}`, 'M-YYYY').add(1, 'months').format('M-YYYY')
        : moment().format('M-YYYY');
      const newDateArray = newDate.split('-');
      const monthText = `${monthsNames[Number(newDateArray[0]) - 1]}-${newDateArray[1]}`;
      const newMonth = {
        ANO: Number(newDateArray[1]),
        MES: Number(newDateArray[0]),
        NOMBRE: monthText,
        NOMBRE2: monthText.replace('-', ' '),
        NOMBRE_COL: newDate.replace('-', ''),
      };

      return update(state, {
        data: {
          [action.workId]: {
            MESES: { $push: [newMonth] },
          },
        },
      });
    }

    case GET_INPUTS_TYPES_GG_TENDER: {
      return update(state, {
        inputsTypes: {
          isFetching: { $set: true },
          error: { $set: false },
        },
      });
    }

    case GET_INPUTS_TYPES_GG_TENDER_ERROR: {
      return update(state, {
        inputsTypes: {
          error: { $set: true },
        },
      });
    }

    case INPUTS_TYPES_GG_FETCHED_TENDER: {
      return update(state, {
        inputsTypes: {
          data: { $set: action.inputsTypes },
          isFetching: { $set: false },
        },
      });
    }

    case 'AUTH_LOGOUT':
      return baseTenderInit;

    default:
      return state;
  }
};

export default baseTender;
