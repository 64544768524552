import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Form, Input, Button } from 'antd';
import PropTypes from 'prop-types';
import { saveForecast as saveForecastAction } from '../../../actions/budget';

const Modalforecast = ({
  isModalVisibleForecast,
  setIsModalVisibleForecast,
  selectedWork,
  saveForecast,
}) => {
  const [form] = Form.useForm();

  const handleOk = () => {
    setIsModalVisibleForecast(false);
  };

  const handleCancel = () => {
    setIsModalVisibleForecast(false);
    form.resetFields();
  };

  const onFinish = (values) => {
    saveForecast(selectedWork, values.NOMBRE_FORECAST);
    form.resetFields();
    setIsModalVisibleForecast(false);
  };

  return (
    <Modal
      title="Guardar Forecast"
      visible={isModalVisibleForecast}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      <p>
        <strong>Se procederá a guardar una versión del Forecast actual.</strong>
      </p>
      <Form
        form={form}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          label="Nombre"
          name="NOMBRE_FORECAST"
          rules={[
            {
              required: true,
              message: 'Por Favor Ingrese un Nombre!',
            },
            {
              validator: async (obj, value) => {
                if (value < 0) {
                  return Promise.reject(new Error('Por Favor Ingrese un Nombre!'));
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
        <div className="buttonModalforecast">
          <Button onClick={handleCancel}>cancelar</Button>
          <Button type="primary" htmlType="submit">
            Guardar
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
Modalforecast.propTypes = {
  isModalVisibleForecast: PropTypes.object.isRequired,
  setIsModalVisibleForecast: PropTypes.object.isRequired,
  selectedWork: PropTypes.number.isRequired,
  saveForecast: PropTypes.func.isRequired,
};
export default connect(
  ({ budget, works, accountOfCost, production }) => ({
    budget,
    selectedWork: works.selectedWork,
    accountOfCostInfo: accountOfCost,
    production,
  }),
  (dispatch) => ({
    saveForecast: bindActionCreators(saveForecastAction, dispatch),
  })
)(Modalforecast);
