import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getItemDetails as getItemDetailsAction,
  getItemEvidence as getItemEvidenceAction,
  setAdvanceInItem as setAdvanceInItemAction,
} from '../../../actions/enclosuresAndItems';
import ItemDetails from '../ItemDetails';
import './index.scss';

const PaymentTable = ({
  dataTable,
  fixedColumn,
  fixedColumnWidth,
  getItemDetails,
  getItemEvidence,
  header,
  heightHeader,
  itemDetails,
  loading,
  setAdvanceInItem,
}) => {
  const [showModal, setShowModal] = useState(null);

  const columnWidh = 40;
  const tableRef = useRef(null);
  const fixedColumnRef = useRef(null);
  const headerRef = useRef(null);
  const headerInFixedColumnRef = useRef(null);

  useEffect(() => {
    tableRef.current.addEventListener('scroll', () => {
      fixedColumnRef.current.style.left = `${tableRef.current.scrollLeft}px`;
      headerRef.current.style.top = `${tableRef.current.scrollTop}px`;
      headerInFixedColumnRef.current.style.top = `${tableRef.current.scrollTop}px`;
    });
  }, []);

  return (
    <>
      {loading && (
        <div className="spinerContainer" style={{ height: window.innerHeight - 100 }}>
          <div className="spiner">
            <LoadingOutlined />
          </div>
          Cargando...
        </div>
      )}

      <div className="tableContainer" ref={tableRef} style={{ height: window.innerHeight - 100 }}>
        <div
          ref={headerRef}
          style={{
            minHeight: `${heightHeader}px`,
            maxHeight: `${heightHeader}px`,
            marginLeft: fixedColumnWidth,
          }}
          className="headerContainer"
        >
          {header.map((headerTitle) => (
            <div
              style={{ minWidth: `${columnWidh}px` }}
              className="headerText"
              key={`headerTitle-${headerTitle}`}
            >
              <span>{headerTitle}</span>
            </div>
          ))}
        </div>
        <div>
          <div className="columnContainer">
            <div
              ref={fixedColumnRef}
              className="fixedColumn"
              style={{ minWidth: `${fixedColumnWidth}px`, maxWidth: `${fixedColumnWidth}px` }}
            >
              <div
                ref={headerInFixedColumnRef}
                className="headerInFixedColumn"
                style={{ height: `${heightHeader}px`, width: `${fixedColumnWidth}px` }}
              >
                PARTIDAS
              </div>
              <div style={{ paddingTop: `${heightHeader}px` }} />
              <div className="column" style={{ flexDirection: 'column' }}>
                {fixedColumn.map((cellTitle, index) => (
                  <span
                    className="cellContainer cellInFixedColumn"
                    style={{ minHeight: `${columnWidh}px`, maxHeight: `${columnWidh}px` }}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`cellTitle-${cellTitle}${index}`}
                  >
                    {cellTitle}
                  </span>
                ))}
              </div>
            </div>
          </div>
          {dataTable.length === 0 ? (
            <div style={{ textAlign: 'center' }}>No hay datos para mostrar</div>
          ) : (
            <div
              style={{
                display: 'flex',
                paddingLeft: `${fixedColumnWidth}px`,
                paddingTop: `${heightHeader}px`,
                flexDirection: 'column',
              }}
            >
              {dataTable.map((row, index) => (
                <div
                  className="columnContainer"
                  style={{ minHeight: `${columnWidh}px`, maxHeight: `${columnWidh}px` }}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`row-${index}`}
                >
                  <div className="column">
                    {row.cells.map((cell) => (
                      <>
                        <span
                          key={`cell-${row.ID_INS_EP}${cell.ID_UCO}`}
                          className="cellContainer cellInNormalColumn"
                          style={{
                            minWidth: `${columnWidh}px`,
                            maxWidth: `${columnWidh}px`,
                            minHeight: `${columnWidh}px`,
                            maxHeight: `${columnWidh}px`,
                            backgroundColor: cell.CELL_COLOR,
                            cursor:
                              typeof cell.PRC_AVA_ACUM_APROB === 'number' ? 'pointer' : 'default',
                            color: cell.TEXT_COLOR,
                          }}
                          onClick={() => {
                            if (typeof cell.PRC_AVA_ACUM_APROB === 'number') {
                              getItemDetails(cell.ID_UCO, row.ID_INS_EP);
                              setShowModal({
                                COMENTARIO_APROB: null,
                                EVIDENCIA: null,
                                ID_INS_EP: row.ID_INS_EP,
                                ID_UCO: cell.ID_UCO,
                                NOMBRE_INS_EP: row.NOMBRE_INS_EP,
                                NOMBRE_UCO: cell.NOMBRE_UCO,
                                PRC_AVA_ACUM: cell.PRC_AVA_ACUM,
                                PRC_AVA_ACUM_APROB: cell.PRC_AVA_ACUM_APROB,
                                PRC_AVA_ACUM_APROB_ANT: null,
                              });
                            }
                          }}
                        >
                          {typeof cell.PRC_AVA_ACUM_APROB === 'number'
                            ? `${cell.PRC_AVA_ACUM_APROB}%`
                            : ''}
                        </span>
                      </>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {showModal && (
        <ItemDetails
          getItemEvidence={() => getItemEvidence(showModal.ID_UCO, showModal.ID_INS_EP)}
          itemDetails={itemDetails ? { ...showModal, ...itemDetails } : null}
          onClose={() => setShowModal(null)}
          setAdvanceInItem={setAdvanceInItem}
          title={`${showModal.NOMBRE_INS_EP}-${showModal.NOMBRE_UCO}`}
        />
      )}
    </>
  );
};

PaymentTable.defaultProps = {
  itemDetails: {},
};

PaymentTable.propTypes = {
  dataTable: PropTypes.arrayOf(PropTypes.object).isRequired,
  fixedColumn: PropTypes.arrayOf(PropTypes.string).isRequired,
  fixedColumnWidth: PropTypes.number.isRequired,
  getItemDetails: PropTypes.func.isRequired,
  getItemEvidence: PropTypes.func.isRequired,
  header: PropTypes.arrayOf(PropTypes.string).isRequired,
  heightHeader: PropTypes.number.isRequired,
  itemDetails: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  setAdvanceInItem: PropTypes.func.isRequired,
};

export default connect(
  (store) => ({
    itemDetails: store.enclosuresAndItems.itemDetails,
  }),
  (dispatch) => ({
    getItemDetails: bindActionCreators(getItemDetailsAction, dispatch),
    getItemEvidence: bindActionCreators(getItemEvidenceAction, dispatch),
    setAdvanceInItem: bindActionCreators(setAdvanceInItemAction, dispatch),
  })
)(PaymentTable);
