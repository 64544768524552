import update from 'immutability-helper';

export const CAS_FETCHED = 'CAS_FETCHED';
export const GET_CAS = 'GET_CAS';
export const GET_CAS_ERROR = 'GET_CAS_ERROR';

const casInit = {
  data: {},
  isFetching: false,
  error: false,
};

const cas = (state = casInit, action) => {
  switch (action.type) {
    case 'WORKS_FETCHED': {
      let newCas = { ...state };
      action.works.forEach(({ ID_OBR }) => {
        newCas = update(newCas, {
          data: {
            [ID_OBR]: { $set: null },
          },
        });
      });

      return newCas;
    }

    case CAS_FETCHED: {
      return update(state, {
        data: {
          [action.workId]: {
            $set: action.cas,
          },
        },
        isFetching: { $set: false },
        error: { $set: false },
      });
    }

    case GET_CAS_ERROR:
      return update(state, { error: { $set: true }, isFetching: { $set: false } });
    case GET_CAS:
      return {
        ...state,
        isFetching: true,
        error: false,
      };

    case 'AUTH_LOGOUT':
      return casInit;

    default:
      return state;
  }
};

export default cas;
