/* eslint-disable */
import React, { useEffect, useState, useCallback } from 'react';
import { Row, Col, Button, Modal, message, Checkbox } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import update from 'immutability-helper';
import qs from 'querystring';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TreeList, filterBy } from '@progress/kendo-react-treelist';
import api, { getHeaders } from '../../utils/api';
import ViewOptions from '../../components/ViewOptions';
import { getChildren, getNodeObjectToUpdate } from '../../utils/budgetAndProductionHelpers';
import ChapterFilter from '../../components/ChapterFilter';
import { initialFilters, advanceControlProptypes, getInitialState } from './helpers';
import { columnsLeft } from './columns';
import InputWithTopLabel from '../../components/InputWithTopLabel';
import { setInformationFromAttendanceURL as setInformationFromAttendanceURLAction } from '../../actions/auth';
import SelectWithTopLabel from '../../components/SelectWithTopLabel';
import NewAdvance from '../../components/NewAdvance';
import RightTable from './RightTable';
import { resetAdvanceIncomeEdition as resetAdvanceIncomeEditionAction } from '../../actions/advanceIncomeEdition';

import './index.scss';

const AdvanceIncome = ({
  auth,
  location,
  setInformationFromAttendanceURL,
  resetAdvanceIncomeEdition,
}) => {
  const { foco_client, foco_project, foco_token, foco_username } = qs.parse(
    location.search.replace('?', '')
  );
  const [activeOptions, setActiveOptions] = useState({ option7: true });
  const [activeViewOptions, setActiveViewOptions] = useState({ option0: true });
  const [showViewOptions, setShowViewOptions] = useState(false);
  const [state, setState] = useState(getInitialState(foco_project));
  const [showFilterBar, setShowFilterBar] = useState(false);
  const [activeRow, setActiveRow] = useState(null);
  const [hideFullAdvanceRows, setHideFullAdvanceRows] = useState(false);
  const [isClosedProjection, setIsClosedProjection] = useState(false);
  const [tree, setTree] = useState({});
  const {
    works,
    dates,
    isFetchingData,
    expandedRowKeys,
    active,
    filter,
    capFilter,
    savingExcel,
    intervalFilters,
    isEditing,
    fieldsToSave,
    access,
    reset,
    filters,
    savingEdition,
  } = state;
  let _export;
  const getWorks = useCallback(async () => {
    const requestBody = {
      USUARIO: foco_username,
      EMPRESA: foco_client,
    };
    setState(update(state, { works: { isFetching: { $set: true } } }));
    const worksResponse = await api.post('api/Avances/GetAvaObras', requestBody, getHeaders());
    const accessResponse = await api.post(
      'api/Avances/GetPermisosPagina',
      { ...requestBody, PAGINA: 'INGRESO_AVANCE' },
      getHeaders()
    );
    let updates = update(state, {
      works: {
        isFetching: { $set: false },
      },
    });
    if (worksResponse && worksResponse.status === 200) {
      updates = update(updates, {
        works: {
          data: { $set: worksResponse.data },
        },
      });
    }

    if (accessResponse && accessResponse.status === 200) {
      const newAccess = accessResponse.data.PERMISOS.reduce(
        (acc, { OPCION, ACCESO }) => ({
          ...acc,
          [OPCION]: ACCESO,
        }),
        {}
      );
      updates = update(updates, {
        access: { $set: newAccess },
        userId: { $set: accessResponse.data.ID_USU },
      });
    }
    setState(updates);
  }, [works, foco_client]);

  const getDates = useCallback(async () => {
    const requestBody = {
      USUARIO: foco_username,
      EMPRESA: foco_client,
      ID_OBR: works.selected,
    };
    setState(
      update(state, {
        dates: { isFetching: { $set: true } },
      })
    );

    api
      .post(`api/Avances/GetAvaFechaConstatacion`, requestBody, getHeaders())
      .then((datesResult) => {
        let updates = update(state, {
          dates: { isFetching: { $set: false } },
        });
        if (datesResult.status === 200) {
          updates = update(updates, {
            dates: {
              data: {
                [works.selected]: {
                  $set: datesResult.data,
                },
              },
              selected: { $set: get(datesResult, 'data[0].ID_AVA') },
            },
          });
          setIsClosedProjection(!!get(datesResult, 'data[0].ESTADO_AVA'));
        }

        api
          .post(`api/Avances/GetFechasValidas`, requestBody, getHeaders())
          .then((result) => {
            if (result.status === 200) {
              updates = update(updates, {
                validDates: { $set: result.data },
              });
            }
          })
          .finally(() => {
            setState(updates);
          });
      });
  }, [works]);

  useEffect(() => {
    if (reset) {
      setTimeout(() => {
        setState(
          update(state, {
            reset: { $set: false },
          })
        );
      }, 500);
    }
  }, [reset]);

  const createTreeData = useCallback(
    (treeData) => {
      const treeKey = `${works.selected}_${dates.selected}`;
      if (treeData && treeData.length > 0) {
        let treeObject = {};
        const treeIndexes = [];
        treeData = treeData.map((e) => {
          treeIndexes.push(e.ID_INS);
          return { ...e, expanded: true };
        });
        const initialExpandedRowKeys = [];

        treeData.forEach((item) => {
          if (treeObject[item.ID_SUP]) {
            treeObject = update(treeObject, {
              [item.ID_SUP]: {
                $push: [item],
              },
            });
          } else {
            treeObject = update(treeObject, {
              [item.ID_SUP]: {
                $set: [item],
              },
            });
          }
          if (item.ID_NAT === 1) {
            initialExpandedRowKeys.push(item.ID_INS);
          }
        });

        const newTree = [];
        const paths = {
          [treeObject[0][0].ID_INS]: '0',
        };
        const savePath = (id, path) => {
          if (!paths[id]) {
            paths[id] = path;
          }
        };
        treeObject[0].forEach((current, i) => {
          newTree.push({
            ...current,
            PATH: `${i}`,
            children: getChildren(current.ID_INS, treeObject, `${i}`, savePath, null, 'ID_INS'),
          });
        });

        setTree(
          update(tree, {
            [treeKey]: { $set: newTree },
          })
        );

        setState(
          update(state, {
            expandedRowKeys: {
              $set: initialExpandedRowKeys,
            },
            expanded: {
              $set: initialExpandedRowKeys,
            },

            isFetchingData: { $set: false },

            indexes: { [treeKey]: { $set: treeIndexes } },
          })
        );
        setTree(
          update(tree, {
            [treeKey]: { $set: newTree },
          })
        );
      } else {
        setState(
          update(state, {
            expandedRowKeys: { $set: [] },
            isFetchingData: { $set: false },
          })
        );
        setTree(
          update(tree, {
            [treeKey]: { $set: [] },
          })
        );
      }
    },
    [expandedRowKeys, dates.selected]
  );

  useEffect(() => {
    if (activeRow) {
      resetAdvanceIncomeEdition();
    }
  }, [activeRow]);

  const getData = useCallback(
    async (updates = {}) => {
      const requestBody = {
        EMPRESA: foco_client,
        USUARIO: foco_username,
        ID_OBR: works.selected,
        ID_AVA: dates.selected,
      };

      setState(
        update(state, {
          isFetchingData: { $set: true },
          filter: { $set: [] },
          filters: { $set: initialFilters },
          active: { $set: null },
          ...updates,
        })
      );
      api
        .post('api/Avances/GetAvaPartidasCriticas', requestBody, getHeaders())
        .then((result) => {
          if (result && result.status === 200) {
            createTreeData(result.data);
          } else {
            setState(update(state, { isFetchingData: { $set: false } }));
          }
        })
        .catch(() => setState(update(state, { isFetchingData: { $set: false } })));
    },
    [works.selected, dates.selected]
  );

  const save = useCallback(() => {
    const newfieldsWithErrors = fieldsToSave.filter(
      (e) => e.ID_TIPO_RESTRICCION > 0 && !e.COMIENZO_EST
    );
    if (newfieldsWithErrors.length) {
      message.error('Existen filas editadas pendientes por agregar fecha de restriccion', 5);
      setState(
        update(state, {
          fieldsWithErrors: { $set: newfieldsWithErrors.map((e) => e.ID_INS) },
        })
      );
    } else {
      const requestBody = {
        USUARIO: foco_username,
        EMPRESA: foco_client,
        ID_AVA: dates.selected,
        EDICION: fieldsToSave.map((e) => ({ DURACION_REST: -1, ...e, PATH: undefined })),
      };
      const errorMessage = 'Ocurrio un error al editar la proyección';
      setState(update(state, { savingEdition: { $set: true } }));
      api
        .post('api/Avances/SetAvaVistaGantt', requestBody, getHeaders())
        .then((res) => {
          let updates = {
            savingEdition: { $set: false },
          };
          if (res.status === 200 && res.data === 'OK') {
            message.success('La proyección se ha editado correctamente');
            updates = {
              ...updates,
              isEditing: { $set: false },
              fieldsToSave: { $set: [] },
              fieldsWithErrors: { $set: [] },
            };
            getData(updates);
          } else {
            setState(update(state, updates));
            message.error(errorMessage);
          }
        })
        .catch(() => {
          message.error(errorMessage);
          setState(update(state, { savingEdition: { $set: true } }));
        });
    }
  }, [fieldsToSave, tree, works.selected, dates.selected]);

  useEffect(() => {
    if (foco_token) {
      setInformationFromAttendanceURL(foco_client, foco_project, foco_token, foco_username);
      localStorage.setItem('foco_token', foco_token);
    }
  }, []);

  useEffect(() => {
    if (isEditing) {
      setActiveOptions(
        update(activeOptions, {
          option1: { $set: true },
          option5: { $set: true },
        })
      );
    }
  }, [activeOptions, isEditing]);

  useEffect(() => {
    if (auth.token && auth.user.username) {
      if (foco_client && !works.data && !works.isFetching) {
        getWorks();
      }

      if (works.data && !dates.data[works.selected] && !dates.isFetching) {
        getDates();
      } else if (works.data && dates.data[works.selected]) {
        setState(
          update(state, {
            dates: { selected: { $set: get(dates, ['data', works.selected, 0, 'ID_AVA'], null) } },
          })
        );
      }
    }
  }, [auth, works, dates]);

  useEffect(() => {
    if (dates.selected && !get(tree, ['data', `${works.selected}_${dates.selected}`])) {
      getData();
    }
  }, [dates.selected]);

  const editCell = (fieldsToUpdate) => {
    setTree((prev) => {
      const treeKey = `${works.selected}_${dates.selected}`;
      const element = fieldsToUpdate[0];
      const path = element.PATH.split('.');
      const updatedNode = getNodeObjectToUpdate(path, 0, { $merge: element });
      const newTree = update(prev[treeKey], updatedNode);
      return update(tree, {
        [treeKey]: { $set: newTree },
      });
    });
  };

  const onChange = (value, stateKey) => {
    let updates = update(state, { [stateKey]: { selected: { $set: Number(value) } } });
    let newIsClosedProjection = false;

    if (stateKey === 'works') {
      updates = update(updates, {
        dates: {
          selected: { $set: null },
          state: { $set: null },
        },
      });
    }
    if (stateKey === 'dates' && value !== null) {
      const date = dates.data[works.selected].find((e) => e.ID_AVA === value);
      if (date) {
        newIsClosedProjection = !!date.ESTADO_AVA;
      }
    }
    setActiveRow(null);
    setIsClosedProjection(newIsClosedProjection);

    setState(updates);
  };

  const applyCapFilters = useCallback(
    (selectedOptions) => {
      let updates = [];
      if (selectedOptions.length) {
        updates = selectedOptions.map((e) => ({
          field: 'PATH',
          operator: 'startswith',
          value: `${e.PATH}.`,
        }));
      }

      setState(update(state, { capFilter: { $set: updates }, active: { $set: null } }));
    },
    [capFilter, active]
  );

  const onExpandChange = (event) => {
    const expanded = !event.value;
    const treeData = cloneDeep(tree[`${works.selected}_${dates.selected}`]);
    const updatedNode = getNodeObjectToUpdate(event.dataItem.PATH.split('.'), 0, {
      expanded: { $set: expanded },
    });
    const newTree = update(treeData, updatedNode);

    setTree(
      update(tree, {
        [`${works.selected}_${dates.selected}`]: {
          $set: newTree,
        },
      })
    );
  };

  const handleFilterChange = (event) => {
    setState(update(state, { filter: { $set: event.filter } }));
  };
  const treeData = tree[`${works.selected}_${dates.selected}`] || [];
  const isLoadingTable = isFetchingData || works.isFetching || dates.isFetching || savingEdition;
  const tableColumnsLeft = React.useMemo(
    () =>
      columnsLeft({
        setActiveRow,
        activeRow,
      }),
    [setActiveRow, activeRow]
  );

  const processData = useCallback(() => {
    return filterBy(
      treeData,
      [
        {
          logic: 'and',
          filters: [
            ...filter,
            ...(capFilter.length ? [{ logic: 'or', filters: capFilter }] : []),
            ...(intervalFilters.length ? intervalFilters : []),
            ...(hideFullAdvanceRows ? [{ field: 'PRC_DAV', value: 100, operator: 'lt' }] : []),
          ],
        },
      ],
      'children'
    );
  }, [tree, filter, capFilter, intervalFilters, works, dates, hideFullAdvanceRows]);

  const onChangeFilter = (value, field, type = 'text', operator = 'contains') => {
    const filterIndex = filter.findIndex((currentFilter) => currentFilter.field === field);
    const newFilter = {
      field,
      operator,
      value: type === 'text' ? value : value ? Number(value) : null,
    };

    let updates =
      filterIndex === -1
        ? { $push: [newFilter] }
        : {
            [filterIndex]: {
              $set: newFilter,
            },
          };

    if (!value) {
      updates = {
        $splice: [[filterIndex, 1]],
      };
    }
    setState(
      update(state, {
        filter: updates,
        filters: {
          [field]: { $set: value },
        },
      })
    );
  };

  const updateTreeExpandedRows = (level, data) => {
    level = Number(level);
    let newTreeData = update(data, {});
    if (data[0].NIVEL < level || level === 0) {
      newTreeData = update(data, {
        $apply: (oldData) => {
          const newData = oldData.map((current) => ({
            ...current,
            expanded: true,
            ...(current.children
              ? { children: updateTreeExpandedRows(level, current.children) }
              : {}),
          }));

          return newData;
        },
      });
    } else if (level === data[0].NIVEL) {
      newTreeData = update(data, {
        $apply: (oldData) => {
          const newData = oldData.map((current) => ({
            ...current,
            expanded: false,
          }));
          return newData;
        },
      });
    }

    return newTreeData;
  };

  const clearFilters = () => {
    const newTreeData = updateTreeExpandedRows('', treeData);
    setState(
      update(state, {
        filter: { $set: [] },
        capFilter: { $set: [] },
        intervalFilters: { $set: [] },
        dateFilter: { $set: null },
        filters: { $set: initialFilters },
        reset: { $set: true },
        levelFilter: { $set: '' },
      })
    );
  };

  const finalTableData = processData();

  const disableButtons =
    !works.data ||
    !dates.data[works.selected] ||
    isFetchingData ||
    works.isFetching ||
    dates.isFetching ||
    !works.selected ||
    !dates.selected ||
    savingExcel ||
    savingEdition;

  return (
    <div className="advance-income p-3">
      <Row gutter={[30, 30]} className="m-0">
        <Col className="first-buttons-col mb-4" span={24}>
          <SelectWithTopLabel
            className="top-select"
            keepShowingLabel
            disabled={works.isFetching || isFetchingData || !works.data}
            loading={works.isFetching}
            value={works.selected}
            label="Selecciona Obra"
            options={(works.data || []).map(({ ID_OBR, NOMABR_OBR }) => ({
              name: NOMABR_OBR,
              value: ID_OBR,
            }))}
            onChange={(value) => onChange(value, 'works')}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          />

          <div className="select-date-container">
            <SelectWithTopLabel
              className="date-select"
              keepShowingLabel
              disabled={dates.isFetching || isFetchingData || !dates.data[works.selected]}
              loading={dates.isFetching}
              value={dates.selected}
              label="Avance al"
              options={(dates.data[works.selected] || []).map(({ FECHA_AVA, ID_AVA }) => ({
                name: FECHA_AVA,
                value: ID_AVA,
              }))}
              onChange={(value, obj) => {
                onChange(value, 'dates', obj);
              }}
            />
          </div>
          {access.EDITAR && (
            <NewAdvance
              disabled={!works.selected || !dates.selected}
              requestBody={{
                USUARIO: foco_username,
                EMPRESA: foco_client,
                ID_OBR: works.selected,
              }}
              updateDates={(newDate) => {
                const index = dates.data[works.selected].findIndex((e) =>
                  moment(newDate.FECHA_AVA, 'DD-MM-YYYY').isAfter(moment(e.FECHA_AVA, 'DD-MM-YYYY'))
                );
                setState(
                  update(state, {
                    dates: {
                      data: {
                        [works.selected]: {
                          $splice: [[index, 0, newDate]],
                        },
                      },
                      selected: { $set: newDate.ID_AVA },
                    },
                  })
                );
                setIsClosedProjection(false);
              }}
              dates={get(dates, ['data', works.selected], []).map((e) => e.FECHA_AVA)}
              workDate={
                ((works.data || []).find((e) => e.ID_OBR === works.selected) || {}).FECINI_OBR
              }
            />
          )}
          <div className="legend ml-3">
            <strong>{isClosedProjection && 'Avance Cerrado'}</strong>
          </div>
          <Button className="ml-auto" onClick={() => setShowFilterBar(!showFilterBar)}>
            <i className="fa fa-filter mr-1" />
            {!showFilterBar ? 'Filtros' : 'Ocultar Filtros'}
          </Button>
          <div className="ml-1 view-options-wrapper">
            <ViewOptions
              defaultActiveOptions={activeViewOptions}
              updateOptions={setActiveViewOptions}
              options={['Mostrar Porcentaje de Avance', 'Mostrar Cantidad de Avance']}
              activeOptionsProps={activeViewOptions}
              onClick={() => setShowViewOptions(true)}
              onClose={() => setShowViewOptions(false)}
              show={showViewOptions}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[30, 30]} className="height-100 m-0">
        <Col span={12} className="advance-income-col p-0 m-0 first">
          {showFilterBar && (
            <Row gutter={[30, 30]} className="m-0 mt-2">
              <Col
                className={`second-buttons-col m-0 d-flex align-items-center ${
                  !tree || isLoadingTable || disableButtons ? 'blocked' : ''
                }`}
                span={24}
              >
                <ChapterFilter
                  title="Filtro Capítulos"
                  tree={treeData}
                  buttonTitle="Capítulo"
                  defaultExpandItemValues={treeData && treeData.length ? [treeData[0].ID_INS] : []}
                  onSave={applyCapFilters}
                  onClick={() => setState(update(state, { active: { $set: 'chapter' } }))}
                  onClose={() => setState(update(state, { active: { $set: null } }))}
                  show={active === 'chapter'}
                  nameKey="NOMBRE_INS"
                  idKey="ID_INS"
                  disableButton={isLoadingTable || !treeData.length}
                  returnObject
                  reset={reset}
                />
                <InputWithTopLabel
                  className="name-filter ml-2"
                  onChange={(value) => onChangeFilter(value, 'NOMBRE_INS')}
                  label="Nombre Partida"
                  keepShowingLabel
                  withTimeOut={false}
                  disabled={!treeData.length}
                  placeholder={null}
                  value={filters.NOMBRE_INS}
                />

                <div className="checkboxes flex-row flex-wrap ml-3">
                  <Checkbox
                    checked={hideFullAdvanceRows}
                    onChange={(e) => setHideFullAdvanceRows(e.target.checked)}
                  >
                    Ocultar Actividades con Avance al 100%
                  </Checkbox>
                </div>
              </Col>
            </Row>
          )}
          <TreeList
            className={`kendo-table mx-auto my-0 ${isLoadingTable ? 'loading' : ' '}`}
            style={{
              height: `${window.innerHeight - (showFilterBar ? 50 : 120)}px`,
              overflow: 'auto',
            }}
            resizable
            tableProps={{
              style: {
                tableLayout: 'fixed',
              },
            }}
            onFilterChange={handleFilterChange}
            noRecords={isLoadingTable ? '' : 'No hay datos disponibles'}
            rowHeight={28}
            scrollable="virtual"
            filter={filter}
            data={finalTableData}
            expandField="expanded"
            subItemsField="children"
            onExpandChange={onExpandChange}
            columns={tableColumnsLeft}
          />
        </Col>
        <RightTable
          record={activeRow}
          showFilterBar={showFilterBar}
          user={foco_username}
          client={foco_client}
          date={get(dates, 'selected', null)}
          isClosedProjection={isClosedProjection}
          editLeftTable={editCell}
          activeViewOptions={activeViewOptions}
          canEdit={access.EDITAR}
        />
      </Row>
    </div>
  );
};

AdvanceIncome.propTypes = advanceControlProptypes;

export default connect(
  (store) => ({
    auth: store.auth,
  }),
  (dispatch) => ({
    setInformationFromAttendanceURL: bindActionCreators(
      setInformationFromAttendanceURLAction,
      dispatch
    ),
    resetAdvanceIncomeEdition: bindActionCreators(resetAdvanceIncomeEditionAction, dispatch),
  })
)(AdvanceIncome);
