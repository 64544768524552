import React from 'react';
import { Column, HeaderCell, Cell } from 'rsuite-table';
import { getFormattedNumber } from '../../utils';

import './index.scss';

export const getTotals = (record, key = 'MONTO_PROD_ANT', priceKey = null) => {
  let total = record.TIPO !== 1 ? record[key] * (priceKey ? record[priceKey] : 1) : 0;
  if (record.TIPO === 1) {
    total = record.children.reduce((acc, childrenRecord) => {
      return acc + childrenRecord[key] * (priceKey ? childrenRecord[priceKey] : 1);
    }, 0);
  }
  return total;
};

const columns = (helpers) => {
  const columnsResult = [
    <Column
      resizable
      fixed
      verticalAlign="middle"
      align="center"
      width={90}
      className="border-right"
    >
      <HeaderCell />
      <Cell dataKey="CODIGO_CC">
        {(record) => {
          return (
            <div className="edit-cell">
              <i
                className="fa fa-pencil"
                onClick={() =>
                  record.ES_CUENTA_COSTO === 3
                    ? helpers.setActiveAccountOfCost(record)
                    : helpers.setActiveChapter(record)
                }
              />
              <i className="fa fa-trash" onClick={() => helpers.setActiveToDelete(record)} />
            </div>
          );
        }}
      </Cell>
    </Column>,
    <Column
      resizable
      fixed
      verticalAlign="middle"
      align="center"
      width={90}
      className="border-right "
    >
      <HeaderCell>Código Ítem</HeaderCell>
      <Cell dataKey="CODIGO_COSTO_ITEM">
        {(record) => {
          if (record.ES_CUENTA_COSTO === 1) return null;

          return record.CODIGO_COSTO_ITEM;
        }}
      </Cell>
    </Column>,
    <Column
      resizable
      fixed
      treeCol
      verticalAlign="middle"
      align="left"
      width={300}
      className="border-right"
    >
      <HeaderCell>Ítem</HeaderCell>
      <Cell dataKey="NOMBRE_COSTO_ITEM" />
    </Column>,
    <Column
      resizable
      fixed
      verticalAlign="middle"
      align="center"
      width={70}
      className="border-right"
    >
      <HeaderCell>Ud</HeaderCell>
      <Cell dataKey="NOMABR_UNI">
        {(record) => {
          if (record.ES_CUENTA_COSTO === 1) return null;

          return record.NOMABR_UNI;
        }}
      </Cell>
    </Column>,
    <Column resizable align="center" verticalAlign="middle" width={120}>
      <HeaderCell>Cantidad Vigente</HeaderCell>

      <Cell className="align-right border-right">
        {(record) => {
          if (record.ES_CUENTA_COSTO === 1) return null;

          return getFormattedNumber(record.CANTIDAD_VIGENTE, 1);
        }}
      </Cell>
    </Column>,
    <Column resizable align="center" verticalAlign="middle" width={140}>
      <HeaderCell>Cantidad Forecast</HeaderCell>
      <Cell className="align-right border-right">
        {(record) => {
          if (record.ES_CUENTA_COSTO === 1) return null;

          return getFormattedNumber(record.CANTIDAD_MOD, 1);
        }}
      </Cell>
    </Column>,
    <Column resizable align="center" verticalAlign="middle" width={160}>
      <HeaderCell>Clasificación GG</HeaderCell>
      <Cell className="align-center border-right">
        {(record) => {
          if (record.ES_CUENTA_COSTO === 1) return null;
          return record.NOM_CLASIF_CC;
        }}
      </Cell>
    </Column>,
  ];
  return columnsResult;
};

export default columns;
