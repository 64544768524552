import { combineReducers } from 'redux';

import auth from './auth';
import clients from './clients';
import menu from './menu';
import works from './works';
import companies from './companies';
import communes from './communes';
import projects from './projects';
import budget from './budget';
import production from './production';
import projectConfig from './projectConfig';
import areasAndPositions from './areasAndPositions';
import accountOfCost from './accountOfCost';
import workers from './workers';
import schedule from './schedule';
import calendars from './calendars';
import attendance from './attendance';
import enclosuresAndItems from './enclosuresAndItems';
import payments from './payments';
import advanceMatrix from './advanceMatrix';
import paymentState from './paymentState';
import pnf from './pnf';
import cas from './cas';
import jobCost from './jobCost';
import costCenter from './costCenter';
import overheadProjection from './overheadProjection';
import baseOverheadProjectionV2 from './overheadProjectionV2';
import ControlPanel from './panelcontrol';
import Configuration from './configuration';
import baseBudgetOP from './baseBudgetOP';
import UserControl from './usercontrol';
import casCd from './casCd';
import rate from './rate';
import baseTender from './TenderGG';
import profile from './Profile';
import users from './users';
import options from './options';
import casGG from './casGG';
import summaryCas from './SummaryCas';
import advanceControlEdition from './advanceControlEdition';
import advanceIncomeEdition from './advanceIncomeEdition';

const { contractCurrency, contractType, parameterConfig } = projectConfig;

export default combineReducers({
  ...projectConfig,
  accountOfCost,
  advanceControlEdition,
  advanceIncomeEdition,
  advanceMatrix,
  areasAndPositions,
  attendance,
  auth,
  baseBudgetOP,
  baseOverheadProjectionV2,
  baseTender,
  budget,
  calendars,
  cas,
  casCd,
  casGG,
  clients,
  communes,
  companies,
  Configuration,
  contractCurrency,
  contractType,
  ControlPanel,
  costCenter,
  enclosuresAndItems,
  jobCost,
  menu,
  options,
  overheadProjection,
  parameterConfig,
  payments,
  paymentState,
  pnf,
  production,
  profile,
  projects,
  rate,
  schedule,
  summaryCas,
  UserControl,
  users,
  workers,
  works,
});
