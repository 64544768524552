/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Row, Col, Button, Tabs, Modal } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import update from 'immutability-helper';
import { CaretDownOutlined, CaretUpOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import * as FileSaver from 'file-saver';
import ExcelJS from 'exceljs';
import SelectWithTopLabel from '../../components/SelectWithTopLabel';
import Table from '../../components/TableExtend';
import ViewOptions from '../../components/ViewOptions';
import ListFilter from '../../components/ListFilter';
import ChapterFilter from '../../components/ChapterFilter';
import Levels from './Levels';
import {
  getPnf as getPnfAction,
  getPnfList as getPnfListAction,
  getPnfTypes as getPnfTypesAction,
  editPnfCells as editPnfCellsAction,
  closePnf as closePnfCellsAction,
  openPnf as openPnfCellsAction,
  deletePnf,
} from '../../actions/pnf';

import columns, { getTotals } from './columns';
import {
  getChildren,
  changeItemFilters,
  getNodeObjectToUpdate,
} from '../../utils/budgetAndProductionHelpers';

import './index.scss';
import AddPNF from './AddPNF';

const { TabPane } = Tabs;

const defaultFilters = {
  items: [],
  chapters: [],
};

const addRowsToWorksheet = (worksheet, tree, typesData) => {
  worksheet.properties.defaultColWidth = 100;
  tree.forEach((current) => {
    worksheet.addRow({
      CODIGO_ITEM: current.CODIGO_ITEM,
      NOMBRE_ITEM: current.NOMBRE_ITEM,
      PRECIO_ITEM_VENTA: current.PRECIO_ITEM_VENTA,
      NOMABR_UNI: current.NOMABR_UNI,
      CANTIDAD_VIGENTE: current.CANTIDAD_VIGENTE,
      TOTAL_VENTA_VIGENTE: current.CANTIDAD_VIGENTE * current.PRECIO_ITEM_VENTA,
      CANTIDAD_MOD: current.ID_NAT === 1 ? '' : current.CANTIDAD_MOD,
      TOTAL_VENTA_MOD: current.CANTIDAD_MOD * current.PRECIO_ITEM_VENTA,
      CANT_AVA: current.ID_NAT === 1 ? '' : current.CANT_AVA,
      TOTAL_VENTA_AVA: getTotals(current, 'CANT_AVA'),
      CANT_EP: current.ID_NAT === 1 ? '' : current.CANT_EP,
      TOTAL_VENTA_EP: getTotals(current, 'CANT_EP'),
      CANT_TOTAL: current.ID_NAT === 1 ? '' : current.CANT_TOTAL,
      TOTAL_VENTA_PNF: getTotals(current, 'CANT_TOTAL'),
      TIPO_PNF: ((typesData || []).find((e) => e.ID_TIPO_PNF === current.ID_TIPO_PNF) || {})
        .NOMBRE_TIPO_PNF,
      COMENTARIO_PNF: current.COMENTARIO_PNF,
    });
    let color = '00FFFFFF';
    if (current.ID_NAT === 3) {
      color = '00F2F2F2';
    } else if (current.ID_NAT === 1) {
      color = '00CCCCCC';
    }

    worksheet.lastRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: color },
    };

    worksheet.lastRow.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };

    if (current.children && current.children.length) {
      addRowsToWorksheet(worksheet, current.children, typesData);
    }
  });
  worksheet.getColumn('CODIGO_ITEM').width = 50;
};

const PNF = ({
  selectedWork,
  pnf,
  getPnf,
  getPnfList,
  getPnfTypes,
  editPnfCells,
  works,
  access,
  closePnf,
  openPnf,
}) => {
  const [active, setActive] = useState(null);
  const [tree, setTree] = useState(null);
  const [lastSavedTree, setLastSavedTree] = useState(null);
  const [expandedRowKeys, setExpandeRowKeys] = useState([]);
  const [activeEdition, setActiveEdition] = useState(null);
  const [fieldsToSave, setFieldsToSave] = useState([]);
  const [savingEdition, setSavingEdition] = useState(false);
  const [itemFilters, setItemFilters] = useState([]);
  const [items, setItems] = useState([]);
  const [itemsMap, setItemsMap] = useState({});
  const [filters, setFilters] = useState(defaultFilters);
  const [currentPnfId, setCurrentPnfId] = useState(null);
  const [typesDataObj, setTypesDataObj] = useState(null);
  const [levelsEdition, setLevelsEdition] = useState([]);
  const [subLevelsEdition, setSublevelsEdition] = useState([]);
  const [showFilterBar, setShowFilterBar] = useState(false);
  const [activeTab, setActiveTab] = useState('2');
  const [callDownloadPnf, setCallDownloadPnf] = useState(false);

  const {
    data,
    list: { data: listData, isFetching: isFetchingList },
    types: { data: typesData, isFetching: isFetchingTypes },
    isFetching,
    currentSelected,
  } = pnf;

  const [activeOptions, setActiveOptions] = useState({ option0: true });

  useEffect(() => {
    if (!typesData && !isFetchingTypes) {
      getPnfTypes();
    } else if (typesData && typesData.length && !typesDataObj) {
      const typesObj = {};

      typesData.forEach((e) => {
        typesObj[e.ID_TIPO_PNF] = e;
      });
      setTypesDataObj(typesObj);
    }
  }, [typesData]);

  const exportToCSV = () => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'Conpax';
    workbook.lastModifiedBy = 'Conpax';
    const currentDate = new Date();
    workbook.created = currentDate;
    workbook.modified = currentDate;

    const worksheet = workbook.addWorksheet('sheet', {
      pageSetup: { paperSize: 9, orientation: 'landscape' },
    });

    const dateEp = _.get(data, 'FECHA_EP', ' ...');
    worksheet.columns = [
      { header: 'Item', key: 'CODIGO_ITEM', width: 20 },
      { header: 'Descripcion', key: 'NOMBRE_ITEM', width: 40 },
      { header: 'PU Venta', key: 'PRECIO_ITEM_VENTA', width: 25 },
      { header: 'Ud', key: 'NOMABR_UNI', width: 25 },
      { header: 'PPTO. Vigente -> Cantidad', key: 'CANTIDAD_VIGENTE', width: 25 },
      { header: 'PPTO. Vigente -> Total Venta', key: 'TOTAL_VENTA_VIGENTE', width: 25 },
      { header: 'PPTO. FORECAST -> Cantidad', key: 'CANTIDAD_MOD', width: 25 },
      { header: 'PPTO. FORECAST -> Total Venta', key: 'TOTAL_VENTA_MOD', width: 25 },
      {
        header: `Producción Acum. al ${dateEp}} -> Cantidad`,
        key: 'CANT_AVA',
        width: 25,
      },
      {
        header: `Producción Acum. al ${dateEp}} -> Total Venta`,
        key: 'TOTAL_VENTA_AVA',
        width: 25,
      },
      {
        header: `Estado de pago Acum. al ${dateEp}} -> Cantidad`,
        key: 'CANT_EP',
        width: 25,
      },
      {
        header: `Estado de pago Acum. al ${dateEp}} -> Total Venta`,
        key: 'TOTAL_VENTA_EP',
        width: 25,
      },
      {
        header: 'PNF nivel 2,3,4 -> Cantidad no Cobrada',
        key: 'CANT_TOTAL',
        width: 25,
      },
      {
        header: 'PNF nivel 2,3,4 -> Total Venta',
        key: 'TOTAL_VENTA_PNF',
        width: 25,
      },
      {
        header: 'Tipo',
        key: 'TIPO_PNF',
        width: 45,
      },
      {
        header: 'Comentario',
        key: 'COMENTARIO_PNF',
        width: 35,
      },
    ];

    addRowsToWorksheet(worksheet, tree, typesData);
    workbook.xlsx.writeBuffer().then(function (buffer) {
      FileSaver.saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        'respaldo_pnf.xlsx'
      );
    });
  };

  const createTreeData = (treeData) => {
    if (treeData && treeData.length > 0) {
      let treeObject = {};
      const treeDataItems = [];
      const itemsObj = {};
      const initialExpandedRowKeys = [];
      treeData.forEach((item) => {
        if (treeObject[item.ID_ITEM_SUP]) {
          treeObject = update(treeObject, {
            [item.ID_ITEM_SUP]: {
              $push: [item],
            },
          });
        } else {
          treeObject = update(treeObject, {
            [item.ID_ITEM_SUP]: {
              $set: [item],
            },
          });
        }

        if (item.ID_NAT === 1) {
          initialExpandedRowKeys.push(item.ID_ITEM);
        }

        if (item.ID_NAT === 3) {
          treeDataItems.push(item);
          itemsObj[item.ID_ITEM] = item;
        }
      });

      const newTree = [];
      const paths = {
        [treeObject[0][0].ID_ITEM]: '0',
      };
      const savePath = (id, path) => {
        if (!paths[id]) {
          paths[id] = path;
        }
      };

      treeObject[0].forEach((current, i) => {
        newTree.push({
          ...current,
          PATH: `${i}`,
          children: getChildren(current.ID_ITEM, treeObject, `${i}`, savePath),
        });
      });
      if (!items.length) {
        setItems(treeDataItems);
      }

      setItemsMap(itemsObj);

      setTree(newTree);
      setLastSavedTree(newTree);
      setExpandeRowKeys(initialExpandedRowKeys);
    }
  };

  useEffect(() => {
    if (!listData[selectedWork]) {
      setCurrentPnfId(null);
      getPnfList(selectedWork);
    } else if (listData[selectedWork] && listData[selectedWork][currentSelected]) {
      setCurrentPnfId(listData[selectedWork][currentSelected].ID_PNF_ENC);
    } else if (listData[selectedWork] && listData[selectedWork].length === 0) {
      setTree([]);
      setLastSavedTree([]);
      setExpandeRowKeys([]);
    }
  }, [listData, currentSelected, selectedWork]);

  useEffect(() => {
    if (currentPnfId !== null && !isFetching) {
      getPnf(currentPnfId, selectedWork);
      setFilters({});
    }
  }, [currentPnfId]);

  useEffect(() => {
    if (data[selectedWork]) {
      const treeData = data[selectedWork].PNF;

      createTreeData(treeData);
    }
  }, [data, selectedWork]);

  const getCommonProps = (key) => ({
    onClick: () => setActive(key),
    onClose: () => setActive(null),
    show: active === key,
  });

  const editCell = (fieldsToUpdate) => {
    setFieldsToSave(
      update(fieldsToSave, {
        $apply: (fields) => {
          let newFields = [...fields];

          fieldsToUpdate.forEach((current) => {
            const currentIndex = newFields.findIndex((e) => e.ID_ITEM === current.ID_ITEM);

            if (currentIndex !== -1) {
              newFields = update(newFields, {
                [currentIndex]: { $merge: current },
              });
            } else {
              newFields = update(newFields, {
                $push: [current],
              });
            }

            if (current.ID_NAT === 3) {
              newFields = update(newFields, {
                $apply: (childrens) =>
                  childrens.filter(
                    (currentChildren) => currentChildren.ID_ITEM_SUP !== current.ID_ITEM
                  ),
              });
            }
          });

          return newFields;
        },
      })
    );

    let newTree = [...tree];

    fieldsToUpdate.forEach((element) => {
      if (element.TIPO_AVANCE && !expandedRowKeys.includes(element.ID_ITEM)) {
        setExpandeRowKeys(
          update(expandedRowKeys, {
            $push: [element.ID_ITEM],
          })
        );
      }
      const path = element.PATH.split('.');
      const updatedNode = getNodeObjectToUpdate(path, 0, { $merge: element });

      newTree = update(newTree, updatedNode);
    });

    setTree(newTree);
  };

  const saveEdition = () => {
    if (fieldsToSave.length || levelsEdition.length || subLevelsEdition.length) {
      const info = get(data, `[${selectedWork}]`, {});
      const requestBody = {
        REAJUSTE_PNF_TIPO2: info.REAJUSTE_PNF_TIPO2,
        REAJUSTE_PNF_TIPO3: info.REAJUSTE_PNF_TIPO3,
        REAJUSTE_PNF_TIPO4: info.REAJUSTE_PNF_TIPO4,
        ITEM_TIPO_PNF: fieldsToSave.map(({ ID_ITEM, COMENTARIO_PNF = '', ID_TIPO_PNF }) => ({
          ID_ITEM,
          COMENTARIO_PNF,
          ID_TIPO_PNF,
        })),
      };

      levelsEdition.forEach((e) => {
        requestBody[`COMENTARIO_PNF_TIPO${e.ID_TIPO_PNF}`] = e.COMENTARIO_PNF;
        requestBody[`REAJUSTE_PNF_TIPO${e.ID_TIPO_PNF}`] = e.REAJUSTE_PNF;
      });

      if (subLevelsEdition.length) {
        requestBody.PNF_NIVEL = subLevelsEdition.map((e) => ({
          ID_PNF_ENC_NIVEL: e.ID_PNF_ENC_NIVEL,
          PNF_NIVEL: e.PNF_NIVEL,
          PNF_ORDEN: e.PNF_ORDEN,
          NOMBRE_PNF_NIVEL: e.NOMBRE_TIPO_PNF,
          MONTO_BASE_PNF: e.MONTO_BASE_PNF,
          COMENTARIO_PNF: e.COMENTARIO_PNF,
          REAJUSTE_PNF: e.REAJUSTE_PNF,
        }));
      }
      setSavingEdition(true);
      editPnfCells(requestBody, currentPnfId)
        .then(() => {
          let newItemsMap = { ...itemsMap };
          fieldsToSave.forEach(({ ID_ITEM, ID_TIPO_PNF }) => {
            if (ID_TIPO_PNF !== newItemsMap[ID_ITEM].ID_TIPO_PNF) {
              newItemsMap = update(newItemsMap, {
                [ID_ITEM]: { ID_TIPO_PNF: { $set: ID_TIPO_PNF } },
              });
            }
          });
          setSublevelsEdition([]);
          setItemsMap(newItemsMap);
          setSavingEdition(false);
          setActiveEdition(null);
          setFieldsToSave([]);
          setLevelsEdition([]);
        })
        .catch(() => setSavingEdition(false));
    } else {
      setActiveEdition(null);
      setLastSavedTree(tree);
    }
  };

  const activeEditMode = (key, optionKey) => {
    setActiveEdition(key);
    if (optionKey) {
      setActiveOptions({ ...activeOptions, [optionKey]: true });
    }
  };

  useEffect(() => {
    if (tree) {
      const { items: itemsFilters = [], chapters = [] } = filters;
      const toFilter = {
        PARTIDAS: itemsFilters.map((id) => ({ ID_ITEM: id })),
        CAPITULOS: chapters.map((id) => ({ ID_ITEM: id })),
      };
      getPnf(currentPnfId, selectedWork, toFilter);
    }
  }, [filters]);

  const applyFilters = (filterType, selectedOptions) => {
    if (filterType === 'item') {
      setFilters({ ...filters, items: selectedOptions });
    } else if (filterType === 'chapters') {
      setFilters({ ...filters, chapters: selectedOptions });
    }
    setActive(null);
  };

  const changeExpandedRows = (expanded, ID_ITEM) => {
    if (expanded) {
      setExpandeRowKeys(
        update(expandedRowKeys, {
          $push: [ID_ITEM],
        })
      );
    } else {
      const index = expandedRowKeys.indexOf(ID_ITEM);

      if (index >= 0) {
        setExpandeRowKeys(
          update(expandedRowKeys, {
            $splice: [[index, 1]],
          })
        );
      }
    }
  };

  const saveLevelsEdition = (values) => {
    if (get(values, '[0].ID_PNF_ENC_NIVEL') >= 0) {
      const current = get(values, '[0]');
      const index = subLevelsEdition.findIndex(
        (e) =>
          (current.ID_PNF_ENC_NIVEL > 0 && e.ID_PNF_ENC_NIVEL === current.ID_PNF_ENC_NIVEL) ||
          (current.ID_PNF_ENC_NIVEL === 0 && e.ID === current.ID)
      );

      let updates;

      if (index === -1) {
        updates = { $push: [current] };
      } else {
        updates = { [index]: { $merge: current } };
      }

      setSublevelsEdition(update(subLevelsEdition, updates));
    } else {
      const { COMENTARIO_PNF, ID_TIPO_PNF, REAJUSTE_PNF } = values[0];
      const index = levelsEdition.findIndex((e) => e.ID_TIPO_PNF === ID_TIPO_PNF);

      let updates;

      if (index === -1) {
        updates = { $push: [{ ID_TIPO_PNF, COMENTARIO_PNF, REAJUSTE_PNF }] };
      } else {
        updates = { [index]: { $merge: { COMENTARIO_PNF, REAJUSTE_PNF } } };
      }

      setLevelsEdition(update(levelsEdition, updates));
    }
  };

  const filterData = (dataToFilter) =>
    dataToFilter.filter((current) => {
      if (current.children) {
        current.children = filterData(current.children);
      }
      return (
        (current.ID_NAT === 1 && get(current, 'children.length')) ||
        (current.ID_NAT === 3 && current.CANT_TOTAL !== 0 && current.PRECIO_ITEM_VENTA !== 0)
      );
    });

  const getParameters = () => {
    const workName = works.data.find((e) => e.ID_OBR === `${selectedWork}`).NOMABR_OBR;
    const date = listData[selectedWork].find((e) => e.ID_PNF_ENC === currentPnfId).FECHA_PNF;
    const IDPNF = listData[selectedWork].find((e) => e.ID_PNF_ENC === currentPnfId).ID_PNF_ENC;
    return { workName, date, IDPNF };
  };

  const close = () => {
    const { workName, date, IDPNF } = getParameters();
    Modal.confirm({
      title: 'Esta seguro que desea cerrar la PNF?',
      icon: <ExclamationCircleOutlined />,
      content: `Se procedera a cerrar la PNF para el proyecto ${workName} con fecha de corte el ${date}, esto guardara el respaldo de toda la información asociada a la PNF y no se podra modificar`,
      okText: 'Cerrar PNF',
      cancelText: 'Cancelar',
      onOk: () => {
        closePnf(IDPNF);
      },
    });
  };

  const open = () => {
    const { workName, date, IDPNF } = getParameters();
    Modal.confirm({
      title: 'Esta seguro que desea abrir la PNF?',
      icon: <ExclamationCircleOutlined />,
      content: `Se procedera a abrir la PNF para el proyecto ${workName} con fecha de corte el ${date}`,
      okText: 'Abrir PNF',
      cancelText: 'Cancelar',
      onOk: () => {
        openPnf(IDPNF);
      },
    });
  };

  const confirmDeletePnf = () => {
    const { workName, date, IDPNF } = getParameters();
    Modal.confirm({
      title: 'Esta seguro que desea Eliminar la PNF?',
      icon: <ExclamationCircleOutlined />,
      content: `Se procedera a eliminar la PNF para el proyecto ${workName} con fecha de corte el ${date}`,
      okText: 'Eliminar PNF',
      cancelText: 'Cancelar',
      onOk: () => deletePnf(IDPNF).then(() => getPnfList(selectedWork)),
    });
  };

  const result = activeOptions.option2 ? filterData(_.cloneDeep(tree)) : tree;
  const estadoPNF =
    listData &&
    listData[selectedWork] &&
    listData[selectedWork].find((e) => e.ID_PNF_ENC === currentPnfId);

  const isDisabledEditButton =
    isFetchingList || isFetching || (estadoPNF && estadoPNF.ESTADO_PNF === 1);

  return (
    <div className="pnf">
      <Row gutter={[30, 30]}>
        <Col className="first-buttons-col" span={24}>
          {activeEdition ? (
            <div className="edit-buttons">
              <h4>Modo Ajuste PNF</h4>
              <div className="control-buttons">
                <Button
                  onClick={() => {
                    setActiveEdition(null);
                    setTree(lastSavedTree);
                    setFieldsToSave([]);
                    setLevelsEdition([]);
                    setSublevelsEdition([]);
                  }}
                  disabled={savingEdition}
                >
                  Cancelar
                </Button>
                <Button
                  disabled={
                    savingEdition ||
                    (fieldsToSave.length === 0 &&
                      levelsEdition.length === 0 &&
                      subLevelsEdition.length === 0)
                  }
                  type="primary"
                  onClick={saveEdition}
                >
                  Guardar
                </Button>
              </div>
            </div>
          ) : (
            <>
              <SelectWithTopLabel
                className="pnf-date"
                loading={isFetchingList}
                disabled={isFetchingList || isFetching}
                keepShowingLabel
                value={currentPnfId}
                label="Fecha PNF al"
                options={(listData[selectedWork] || []).map(({ FECHA_PNF, ID_PNF_ENC }) => ({
                  name: FECHA_PNF,
                  value: ID_PNF_ENC,
                }))}
                onChange={(value) => {
                  setCurrentPnfId(value);
                }}
              />

              <>
                {!!access.CONTROL_PNF_CREAR && (
                  <AddPNF isFetchingList={isFetchingList} listData={listData[selectedWork] || []} />
                )}
                {!!access.CONTROL_PNF_AJUSTAR && (
                  <Button
                    type="primary"
                    disabled={isDisabledEditButton}
                    onClick={() => {
                      activeEditMode('editPNF');
                    }}
                  >
                    Ajustar PNF
                  </Button>
                )}
                {!!access.CONTROL_PNF_CERRAR && (
                  <Button type="primary" disabled={isDisabledEditButton} onClick={close}>
                    Cerrar PNF
                  </Button>
                )}
                {!!access.CONTROL_PNF_ABRIR && (
                  <Button
                    type="primary"
                    disabled={
                      isFetchingList || isFetching || (estadoPNF && estadoPNF.ESTADO_PNF === 0)
                    }
                    onClick={open}
                  >
                    Abrir PNF
                  </Button>
                )}
                {!!access.CONTROL_PNF_ELIMINAR && (
                  <Button type="primary" disabled={isDisabledEditButton} onClick={confirmDeletePnf}>
                    Eliminar PNF
                  </Button>
                )}
              </>
              <Button
                disabled={isFetchingList || isFetchingTypes || isFetching || savingEdition}
                type="primary"
                onClick={() => {
                  if (activeTab === '2') {
                    exportToCSV();
                  } else {
                    setCallDownloadPnf(true);
                  }
                }}
              >
                Exportar
              </Button>
              <Button type="Default" onClick={() => setShowFilterBar(!showFilterBar)}>
                <i className="fa fa-filter mr-1" />
                {!showFilterBar ? 'Filtros' : 'Ocultar Filtros'}
              </Button>
              <div className="viewoptions-pnf">
                <ViewOptions
                  defaultActiveOptions={activeOptions}
                  updateOptions={(opt) => setActiveOptions(opt)}
                  activeOptionsProps={activeOptions}
                  options={['Mostrar Cantidad', 'Mostrar Total Venta', 'Ocultar PNF Total 0']}
                  {...getCommonProps('view')}
                />
              </div>
            </>
          )}
        </Col>
      </Row>
      {showFilterBar && (
        <Row gutter={[30, 30]} className="mb-0">
          <Col className={`second-buttons-col ${!tree ? 'blocked' : ''} mb-1`} span={24}>
            <ChapterFilter
              title="Filtro Capítulos"
              tree={lastSavedTree || []}
              buttonTitle="Capítulo"
              defaultExpandItemValues={
                lastSavedTree && lastSavedTree.length ? [lastSavedTree[0].ID_ITEM] : []
              }
              onSave={(selectedOptions) => applyFilters('chapters', selectedOptions)}
              {...getCommonProps('chapters')}
            />
            <ListFilter
              allowSelectAll
              title="Filtro Partidas"
              subtitle="Lista de Partidas"
              onChange={(value, isChecked) =>
                changeItemFilters(value, isChecked, {
                  callback: setItemFilters,
                  filters: itemFilters,
                  options: items,
                })
              }
              buttonTitle="Partida"
              elementKey="ID_ITEM"
              elementTitleKey="NOMBRE_ITEM"
              onSave={(selectedOptions) => applyFilters('item', selectedOptions)}
              options={items}
              {...getCommonProps('item')}
            />
          </Col>
        </Row>
      )}
      <Row gutter={[30, 30]}>
        <Col className="p-0" span={24}>
          <Tabs activeKey={activeTab} onChange={setActiveTab}>
            <TabPane tab="PNF" key="1">
              <Levels
                pnfData={data[selectedWork]}
                isFetching={isFetching || isFetchingTypes}
                typesData={typesData}
                items={items}
                itemsMap={itemsMap}
                handleSave={saveLevelsEdition}
                savingEdition={savingEdition}
                canEdit={!!activeEdition}
                levels={get(data, `[${selectedWork}].PNF_NIVEL`)}
                callDownload={callDownloadPnf}
                pdfDownloaded={() => setCallDownloadPnf(false)}
              />
            </TabPane>
            <TabPane tab="Respaldo PNF" key="2">
              <Table
                className="common-table"
                height={window.innerHeight - (showFilterBar ? 295 : 235)}
                hover={false}
                rowKey="ID_ITEM"
                bordered
                headerHeight={75}
                rowHeight={28}
                virtualized
                shouldUpdateScroll={false}
                isTree
                locale={{
                  emptyMessage: 'No hay datos para mostrar',
                  loading: savingEdition ? 'Guardando...' : 'Cargando...',
                }}
                loading={isFetchingList || isFetchingTypes || isFetching || savingEdition}
                data={result || []}
                rowClassName={(record) => {
                  let className = '';

                  if (record) {
                    const { ID_NAT } = record;
                    if (ID_NAT === 1) {
                      className += 'green-row';
                    } else if (ID_NAT === 3) {
                      className += ' second-level';
                    }
                  }
                  return className;
                }}
                renderTreeToggle={(_icon, _row, expanded) => {
                  if (expanded) {
                    return <CaretUpOutlined />;
                  }
                  return <CaretDownOutlined />;
                }}
                expandedRowKeys={expandedRowKeys}
                onExpandChange={(expanded, rowData) =>
                  changeExpandedRows(expanded, rowData.ID_ITEM)
                }
              >
                {columns(activeOptions, activeEdition, editCell, {
                  changeExpandedRows,
                  typesData,
                  isFetchingTypes,
                  typesDataObj,
                  data: data[selectedWork] || {},
                })}
              </Table>
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

PNF.propTypes = {
  selectedWork: PropTypes.number.isRequired,
  pnf: PropTypes.object.isRequired,
  getPnf: PropTypes.func.isRequired,
  getPnfList: PropTypes.func.isRequired,
  getPnfTypes: PropTypes.func.isRequired,
  editPnfCells: PropTypes.func.isRequired,
  closePnf: PropTypes.func.isRequired,
  openPnf: PropTypes.func.isRequired,
  access: PropTypes.object.isRequired,
};

export default connect(
  ({ production, works, pnf }) => ({
    production,
    selectedWork: works.selectedWork,
    pnf,
    works,
  }),
  (dispatch) => ({
    getPnf: bindActionCreators(getPnfAction, dispatch),
    getPnfList: bindActionCreators(getPnfListAction, dispatch),
    getPnfTypes: bindActionCreators(getPnfTypesAction, dispatch),
    editPnfCells: bindActionCreators(editPnfCellsAction, dispatch),
    closePnf: bindActionCreators(closePnfCellsAction, dispatch),
    openPnf: bindActionCreators(openPnfCellsAction, dispatch),
  })
)(PNF);
