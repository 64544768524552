import _ from 'lodash';
import { message } from 'antd';
import api, { getHeaders } from '../utils/api';
import { SET_ERROR_ENCLOSURE, GET_LISTADO_PANEL, GET_USER } from '../reducers/usercontrol';
import store from '../store';

export const setError = (error = '') => ({
  type: SET_ERROR_ENCLOSURE,
  payload: { error },
  result: { error },
});
export const getPanelList = () => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
    };
    const errorMessage = 'No se cargaron éxitosamente la configuracion.';
    api
      .post(`api/Usuario/ListarUsuarios`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: GET_LISTADO_PANEL, payload: { isPanel: res.data } });
          resolve(res.data);
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });
export const getUser = (IdUsuario) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_USU: IdUsuario,
    };
    const errorMessage = 'No se cargaron éxitosamente los Datos Personales.';
    api
      .post(`api/Usuario/DetalleUsuario`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: GET_USER, payload: { isUser: res.data } });
          resolve(res.data);
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });
