import _ from 'lodash';
import { message } from 'antd';
import api, { getHeaders } from '../utils/api';
import { SET_ERROR_ENCLOSURE, SET_NOTIFICATION } from '../reducers/help';
import store from '../store';

export const setError = (error = '') => ({
  type: SET_ERROR_ENCLOSURE,
  payload: { error },
  result: { error },
});
export const setNotification = (tdmessage) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      MENSAJE: tdmessage,
    };
    const errorMessage = 'No se guardo el mensaje.';
    api
      .post(`api/PanelControl/setSoporte`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: SET_NOTIFICATION, payload: { request: res.data } });
          resolve(res.data);
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });
