import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LoadingOutlined } from '@ant-design/icons';
import { getAreasAndPositionsAttendance as getAreasAndPositionsAction } from '../../actions/areasAndPositions';
import { getWorkers as getWorkersAction } from '../../actions/workers';
import { normalizeString } from '../../utils';
import Header from './Header';
import List from './List';
import './index.scss';

const AreasAndPositions = ({
  areasAndPositions: { data, isFetching },
  getAreasAndPositionsAttendance,
  getWorkers,
  selectedWork,
  workers,
}) => {
  const [filters, setFilters] = useState({ area: '', position: '' });

  useEffect(() => {
    if (!data) {
      getAreasAndPositionsAttendance();
    }
    if (!workers.data) {
      getWorkers();
    }
  }, []);

  const handleFilters = (filter, value) =>
    setFilters(
      update(filters, {
        [filter]: { $set: value },
      })
    );

  const filterByArea = data
    ? data.filter(
        (e) =>
          normalizeString(e.NOMBRE_ARE).includes(normalizeString(filters.area)) &&
          Number(e.ID_OBR) === selectedWork
      )
    : [];

  const filteredTree = filters.position.length
    ? filterByArea
        .filter((e) => {
          const { CARGOS_ASOCIADOS } = e;
          if (
            CARGOS_ASOCIADOS.some((position) =>
              normalizeString(position.NOMBRE_CAR).includes(normalizeString(filters.position))
            )
          ) {
            return true;
          }
          return false;
        })
        .map((e) =>
          update(e, {
            CARGOS_ASOCIADOS: {
              $set: e.CARGOS_ASOCIADOS.filter((position) =>
                normalizeString(position.NOMBRE_CAR).includes(normalizeString(filters.position))
              ),
            },
          })
        )
    : filterByArea;

  return (
    <>
      {isFetching || !workers.data ? (
        <div className="spin">
          <LoadingOutlined />
        </div>
      ) : (
        <>
          <Header handleFilters={handleFilters} filters={filters} />
          <List treeData={filteredTree} workers={workers.data} />
        </>
      )}
    </>
  );
};

AreasAndPositions.propTypes = {
  areasAndPositions: PropTypes.shape({
    data: PropTypes.array,
    isFetching: PropTypes.bool,
  }).isRequired,
  getAreasAndPositionsAttendance: PropTypes.func.isRequired,
  getWorkers: PropTypes.func.isRequired,
  selectedWork: PropTypes.number.isRequired,
  workers: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default connect(
  (store) => ({
    areasAndPositions: store.areasAndPositions,
    selectedWork: store.works.selectedWork,
    workers: store.workers,
  }),
  (dispatch) => ({
    getAreasAndPositionsAttendance: bindActionCreators(getAreasAndPositionsAction, dispatch),
    getWorkers: bindActionCreators(getWorkersAction, dispatch),
  })
)(AreasAndPositions);
