import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import NewWorker from '../NewWorker';
import InputWithTopLabel from '../../../components/InputWithTopLabel';
import { SearchIcon } from '../../../utils';
import './index.scss';

const NewButton = ({ className, form, name, supervisors }) => {
  const [showForm, toggleForm] = useState(false);

  return (
    <div className={`buttonContainer ${className}`}>
      <Button type="primary" onClick={() => toggleForm(!showForm)}>
        <i className="fa fa-plus" />
        {name}
      </Button>
      {showForm && form === 'NewWorker' && (
        <NewWorker onClose={() => toggleForm(false)} supervisors={supervisors} />
      )}
    </div>
  );
};

NewButton.defaultProps = {
  className: '',
  supervisors: [],
};

NewButton.propTypes = {
  className: PropTypes.string,
  form: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  supervisors: PropTypes.arrayOf(PropTypes.object),
};

const Header = ({ handleFilters, filters, supervisors }) => {
  return (
    <div className="workersHeader">
      <div className="filters">
        <InputWithTopLabel
          className="marginRight"
          icon={<SearchIcon />}
          onChange={(value) => handleFilters('area', value)}
          label="Área"
          value={filters.area}
        />
        <InputWithTopLabel
          className="marginRight"
          icon={<SearchIcon />}
          onChange={(value) => handleFilters('position', value)}
          label="Cargo"
          value={filters.position}
        />
        <InputWithTopLabel
          className="marginRight"
          icon={<SearchIcon />}
          onChange={(value) => handleFilters('name', value)}
          label="Nombre"
          value={filters.name}
        />
        <InputWithTopLabel
          icon={<SearchIcon />}
          onChange={(value) => handleFilters('rut', value)}
          label="Rut"
          value={filters.rut}
        />
        <InputWithTopLabel
          icon={<SearchIcon />}
          onChange={(value) => handleFilters('supervisorName', value)}
          label="Supervisor"
          value={filters.supervisorName}
        />
      </div>
      <div className="buttons">
        <NewButton name="Nuevo Trabajador" form="NewWorker" supervisors={supervisors} />
      </div>
    </div>
  );
};

Header.propTypes = {
  handleFilters: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  supervisors: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Header;
