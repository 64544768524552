import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import NewWorkingDay from '../NewWorkingDay';
import InputWithTopLabel from '../../../components/InputWithTopLabel';
import { SearchIcon } from '../../../utils';

import './index.scss';

const NewButton = ({ className, form, name }) => {
  const [showForm, toggleForm] = useState(false);

  return (
    <div className={`buttonContainer ${className}`}>
      <Button type="primary" onClick={() => toggleForm(!showForm)}>
        <i className="fa fa-plus" />
        {name}
      </Button>
      {showForm && form === 'NewWorkingDay' && (
        <NewWorkingDay onClose={() => toggleForm(false)} title="Nueva Jornada" />
      )}
    </div>
  );
};

NewButton.defaultProps = {
  className: '',
};

NewButton.propTypes = {
  className: PropTypes.string,
  form: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

const Header = ({ filter, setFilter }) => {
  return (
    <div className="scheduleHeader">
      <div className="filters">
        <InputWithTopLabel
          className="marginRight"
          icon={<SearchIcon />}
          onChange={(value) => setFilter(value)}
          label="Filtrar"
          value={filter}
        />
      </div>
      <div className="buttons">
        <NewButton name="Nueva Jornada" form="NewWorkingDay" />
      </div>
    </div>
  );
};

Header.propTypes = {
  filter: PropTypes.string.isRequired,
  setFilter: PropTypes.func.isRequired,
};

export default Header;
