import genericReducer from '../../utils';

export const PARAMETER_CONFIG_FETCHED = 'PARAMETER_CONFIG_FETCHED';
export const GET_PARAMETER_CONFIG = 'GET_PARAMETER_CONFIG';
export const GET_PARAMETER_CONFIG_ERROR = 'GET_PARAMETER_CONFIG_ERROR';

const parameterConfig = (parameterConfigInfo, action) =>
  genericReducer(parameterConfigInfo, action, 'PARAMETER_CONFIG', 'parameterConfig');

export default parameterConfig;
