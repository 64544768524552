import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import { login as loginAction, loginWithQs as loginWithQsAction } from '../../actions/auth';

import logo from '../../images/login/logo-foco.png';
import suiteImg from '../../images/login/suite-img.png';

import './index.scss';

const initialForm = {
  user: '',
  password: '',
};

const Login = ({ auth, login, history, location, loginWithQs }) => {
  const [form, setForm] = useState(initialForm);
  const { token, isLogged, isFetching, error } = auth;

  useEffect(() => {
    if (isLogged && !isFetching && token) {
      history.push('/clients');
    }
  }, [auth]);

  useEffect(() => {
    const { token: qsToken } = qs.parse(location.search.replace('?', ''));
    if (get(qsToken, 'length')) {
      const finalQsToken = qsToken.replace(new RegExp(' ', 'g'), '+');
      loginWithQs(finalQsToken);
    }
  }, []);

  const submit = () => {
    login(form.user.toLowerCase(), form.password.toLowerCase());
  };

  const onChangeField = (key, value) => {
    setForm({
      ...form,
      [key]: value,
    });
  };

  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      submit();
    }
  };

  const getInput = (key, type, placeholder) => (
    <div className="row" key={key}>
      <div className="col-xs-12 col-md-10">
        <div className="form-group">
          <input
            name="fname"
            value={form[key]}
            type={type}
            className="form-control input-lg"
            maxLength="50"
            placeholder={placeholder}
            onChange={(e) => onChangeField(key, e.target.value)}
            onKeyDown={onKeyDown}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className="login">
      <div className="margin-bottom-0 form-content">
        <div className="form-group m-b-10">
          <img id="Image1" src={logo} alt="" />
        </div>
        <div className="form-title m-b-10">
          <span>EXPERTOS EN APPS PARA LA CONSTRUCCIÓN</span>
          <hr className="divider" />
        </div>
        <span className="instructions">INGRESA TUS DATOS</span>
        <br />
        <div className="form">
          {getInput('user', 'text', 'Usuario')}
          {getInput('password', 'password', 'Contraseña')}
          <button
            onClick={() => (form.user.length && form.password.length ? submit() : null)}
            type="button"
            className="btn btn-amarillo"
            disabled={isFetching}
          >
            INGRESAR {isFetching && <div className="spinner-border" role="status" />}
          </button>
          <div className="recover-password-link">
            <Link to="/recover-password">Activar o Recuperar Contraseña</Link>
          </div>
          {error && (
            <p className="error-message">El nombre de usuario o contraseña no es válido.</p>
          )}
        </div>
      </div>
      <div className="bottom-content">
        <p>SI AÚN NO ESTÁS TRABAJANDO CON NOSOTROS,</p>
        <p className="m-b-10">CONTÁCTANOS Y CONOCE LOS BENEFICIOS DE HACERLO.</p>
        <div className="m-b-10">
          <button type="button">SUITE FOCO EN OBRA</button>
        </div>
        <img src={suiteImg} alt="" />
      </div>
    </div>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  loginWithQs: PropTypes.func.isRequired,
  auth: PropTypes.shape({
    isLogged: PropTypes.bool,
    token: PropTypes.string,
    isFetching: PropTypes.bool,
    error: PropTypes.bool,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  location: PropTypes.object.isRequired,
};

Login.defaultProps = {
  auth: {},
};
export default connect(
  (store) => ({
    auth: store.auth,
  }),
  (dispatch) => ({
    login: bindActionCreators(loginAction, dispatch),
    loginWithQs: bindActionCreators(loginWithQsAction, dispatch),
  })
)(Login);
