import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _, { get } from 'lodash';
import qs from 'querystring';
import { Row, Col, Button, message, Upload } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import update from 'immutability-helper';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import ExcelJS from 'exceljs';

import * as FileSaver from 'file-saver';
import Table from '../../../components/TableExtend';
import ViewOptions from '../../../components/ViewOptions';
import ListFilter from '../../../components/ListFilter';
import ChapterFilter from '../../../components/ChapterFilter';
import {
  editPaymentStateCells as editPaymentStateCellsAction,
  getPaymentState as getPaymentStateAction,
  getPaymentStates as getPaymentStatesAction,
  editTotals as editTotalsAction,
} from '../../../actions/paymentState';
import { getAdvanceDates as getAdvanceDatesAction } from '../../../actions/production';
import ResumeBox from './ResumeBox';
import LeftTools from './LeftTools';

import columns, { getTotals } from './columns';
import {
  getChildren,
  changeItemFilters,
  getNodeObjectToUpdate,
} from '../../../utils/budgetAndProductionHelpers';

import { commonPropTypes, getFormattedNumber } from '../../../utils';

import './index.scss';

const addRowsToWorksheet = (worksheet, tree) => {
  worksheet.properties.defaultColWidth = 50;

  tree.forEach((current) => {
    const totalAcc = getTotals(current, 'CANT_EP');
    const totalMod = current.CANTIDAD_VIGENTE * current.PRECIO_ITEM_VENTA;
    const totalMo = getTotals(current, 'CANTIDAD_VIGENTE');
    const totalPrevAcc = getTotals(current, 'CANT_EP_ANT');
    const totalPrevAc = getTotals(current, 'CANT_AVA');
    const currenTotal = current.CANTIDAD_VIGENTE * current.PRECIO_ITEM_VENTA;
    const total = (totalPrevAc * 100) / totalMo;
    const totalVenta = getTotals(current, 'CANT_AVA');
    const TOTAL_VENTA = current.CANTIDAD_VIGENTE * current.PRECIO_ITEM_VENTA;
    const TOTAL_EP = getTotals(current, 'CANT_EP');
    const PORC_EP = (totalAcc * 100) / totalMod;
    const PROD_PORCENTAJ = (totalPrevAcc * 100) / currenTotal;
    const TOTAL_ACUM_AN = getTotals(current, 'CANT_AVA');
    const TOTAL_EP_AN = getTotals(current, 'CANT_EP_ANT');

    const currentTotal = current.CANTIDAD_VIGENTE * current.PRECIO_ITEM_VENTA;
    const total1 = totalAcc === 0 || currentTotal === 0 ? 0 : (totalAcc * 100) / currentTotal;
    const total2 =
      totalPrevAcc === 0 || currentTotal === 0 ? 0 : `${(totalPrevAcc * 100) / currentTotal}`;

    const totalParcial = total1 - total2;

    const prevTotal = getTotals(current, 'CANT_EP_ANT');
    const currentTotalPagoPacial = getTotals(current, 'CANT_EP');
    const totalPagoPacial = currentTotalPagoPacial - prevTotal;
    worksheet.addRow({
      CODIGO_ITEM: current.CODIGO_ITEM,
      NOMBRE_ITEM: current.NOMBRE_ITEM,
      PRECIO_ITEM_VENTA: current.PRECIO_ITEM_VENTA,
      moneda: '$',
      NOMABR_UNI: current.NOMABR_UNI,
      CANTIDAD_VIGENTE: current.CANTIDAD_VIGENTE,
      CANTIDAD_VIGENT: current.CANTIDAD_VIGENTE,
      TOTAL_VENTA: getFormattedNumber(TOTAL_VENTA, null),
      TOTAL_MOD: current.CANTIDAD_VIGENTE * current.PRECIO_ITEM_VENTA,
      PROD_PORCENTA: getFormattedNumber(total, 0),
      CANT_AVA: current.CANT_AVA,
      TOTAL_ACUM_ANT: getFormattedNumber(totalVenta, null),
      PORC_EP: getFormattedNumber(PORC_EP, 0),
      CANT_EP: current.CANT_EP,
      TOTAL_EP: getFormattedNumber(TOTAL_EP, null),
      PROD_PORCENTAJ: getFormattedNumber(PROD_PORCENTAJ, 0),
      CANT_AV: current.CANT_AVA,
      TOTAL_ACUM_AN: getFormattedNumber(TOTAL_ACUM_AN, null),
      PORC_E: getFormattedNumber(totalParcial, 0),
      CANT_E: current.CANT_EP - current.CANT_EP_ANT,
      TOTAL_EP_ANT: getFormattedNumber(totalPagoPacial, 0),
      ID_ITEM: current.ID_ITEM,
      ID_ITEM_SUP: current.ID_ITEM_SUP,
      ID_NAT: current.ID_NAT,

      CANT_EP_ANT: current.CANT_EP_ANT,
      TOTAL_EP_AN: getFormattedNumber(TOTAL_EP_AN, null),
    });
    let color = '00FFFFFF';
    if (current.ID_NAT === 3) {
      color = '00F2F2F2';
    } else if (current.ID_NAT === 1) {
      color = '00CCCCCC';
    }
    if (current.PORC_EP) {
      color = '#e6c81e';
    }

    worksheet.getColumn(13).fill = {
      type: 'pattern',
      pattern: 'darkTrellis',
      fgColor: { argb: 'FFFFFF00' },
      bgColor: { argb: '#eeeec3' },
    };
    worksheet.getColumn(14).fill = {
      type: 'pattern',
      pattern: 'darkTrellis',
      fgColor: { argb: 'FFFFFF00' },
      bgColor: { argb: '#eeeec3' },
    };

    worksheet.getColumn(1).alignment = { vertical: 'top', horizontal: 'left' };
    worksheet.getColumn(2).alignment = { vertical: 'top', horizontal: 'left' };
    worksheet.getColumn(3).alignment = { vertical: 'top', horizontal: 'right' };
    worksheet.getColumn(4).alignment = { vertical: 'top', horizontal: 'right' };
    worksheet.getColumn(5).alignment = { vertical: 'top', horizontal: 'center' };
    worksheet.getColumn(6).alignment = { vertical: 'top', horizontal: 'right' };
    worksheet.getColumn(7).alignment = { vertical: 'top', horizontal: 'right' };
    worksheet.getColumn(8).alignment = { vertical: 'top', horizontal: 'right' };
    worksheet.getColumn(9).alignment = { vertical: 'top', horizontal: 'right' };
    worksheet.getColumn(10).alignment = { vertical: 'top', horizontal: 'right' };
    worksheet.getColumn(11).alignment = { vertical: 'top', horizontal: 'right' };
    worksheet.getColumn(12).alignment = { vertical: 'top', horizontal: 'right' };
    worksheet.getColumn(13).alignment = { vertical: 'top', horizontal: 'right' };
    worksheet.getColumn(14).alignment = { vertical: 'top', horizontal: 'right' };
    worksheet.getColumn(15).alignment = { vertical: 'top', horizontal: 'right' };
    worksheet.getColumn(16).alignment = { vertical: 'top', horizontal: 'right' };
    worksheet.getColumn(17).alignment = { vertical: 'top', horizontal: 'right' };
    worksheet.getColumn(18).alignment = { vertical: 'top', horizontal: 'right' };
    worksheet.getColumn(19).alignment = { vertical: 'top', horizontal: 'right' };
    worksheet.getColumn(20).alignment = { vertical: 'top', horizontal: 'right' };
    worksheet.getColumn(21).alignment = { vertical: 'top', horizontal: 'right' };
    worksheet.getColumn(22).alignment = { vertical: 'top', horizontal: 'right' };
    worksheet.getColumn(23).alignment = { vertical: 'top', horizontal: 'right' };
    worksheet.getColumn(24).alignment = { vertical: 'top', horizontal: 'right' };

    worksheet.lastRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: color },
    };

    worksheet.lastRow.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };

    worksheet.getRow(1).font = {
      color: { argb: 'd4cbcb' },
    };
    worksheet.getRow(1).fill = {
      color: { argb: 'ffffff' },
    };

    if (current.children && current.children.length) {
      addRowsToWorksheet(worksheet, current.children);
    }
  });
};

const PaymentState = ({
  match,
  paymentState,
  getPaymentState,
  getPaymentStates,
  production,
  getAdvanceDates,
  editPaymentStateCells,
  editTotals,
  menu,
  location,
  access,
}) => {
  const [active, setActive] = useState(null);
  const [tree, setTree] = useState(null);
  const [lastSavedTree, setLastSavedTree] = useState(null);
  const [expandedRowKeys, setExpandeRowKeys] = useState([]);
  const [activeEdition, setActiveEdition] = useState(null);
  const [fieldsToSave, setFieldsToSave] = useState([]);
  const [savingEdition, setSavingEdition] = useState(false);
  const [itemFilters, setItemFilters] = useState([]);
  const [itemFilterOptions, setItemFilterOptions] = useState([]);
  const [filters, setFilters] = useState({
    accountOfCost: [],
    items: [],
    variation: { currenT: false, updated: false },
  });

  let { project: selectedWork = '0' } = qs.parse(location.search.replace('?', ''));

  selectedWork = Number(selectedWork);

  const [showFilterBar, setShowFilterBar] = useState(false);

  const [role, setRole] = useState('');
  useEffect(() => {
    if (get(menu, 'data') && !role) {
      const newRole = menu.data.find((e) => e.URL_MENU === '/payment_states');
      if (newRole) setRole(newRole.TIPO_ACCESO);
    }
  }, [menu]);

  const [resumeBoxValues, setResumeBoxValues] = useState({});

  const { discount, readjustment } = resumeBoxValues;

  const [currentAdvanceDate, setCurrentAdvanceDate] = useState();
  const [paymentStateInfo, setPaymentStateInfo] = useState({});
  const [paymentStatePreviousDate, setPaymentStatePreviousDate] = useState(null);

  const {
    advanceDates: { isFetching: isFetchingAdvanceDates, data: advanceDatesData = [] },
  } = production;

  const { data, list, isFetching } = paymentState;

  const [activeOptions, setActiveOptions] = useState({});

  const exportToExcelProduction = () => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'Foco en Obra';
    workbook.lastModifiedBy = 'Foco en Obra';
    workbook.created = new Date();
    workbook.modified = new Date();

    const worksheet = workbook.addWorksheet('sheet', {
      pageSetup: { paperSize: 9, orientation: 'landscape' },
    });

    const rowValues = [];

    rowValues[1] = 'Item';
    rowValues[2] = 'Descripcion';
    rowValues[3] = 'PU Venta';
    rowValues[4] = 'Moneda';
    rowValues[5] = 'Ud';
    rowValues[6] = 'Contrato Vigente -> Cantidad';
    rowValues[7] = 'Contrato Vigente -> Total Venta';
    rowValues[8] = 'Contrato Actualizado -> Cantidad';
    rowValues[9] = 'Contrato Actualizado -> Total CD';
    rowValues[10] = `Producción Acum. al ${currentAdvanceDate} -> %`;
    rowValues[11] = `Producción Acum. al ${currentAdvanceDate} -> Cantidad`;
    rowValues[12] = `Producción Acum. al ${currentAdvanceDate} -> Total Venta`;
    rowValues[13] = `Estado de Pago Acum al ${paymentStateInfo.FECHA_EP} -> %`;
    rowValues[14] = `Estado de Pago Acum al ${paymentStateInfo.FECHA_EP} -> Cantidad`;
    rowValues[15] = `Estado de Pago Acum al ${paymentStateInfo.FECHA_EP} -> Total Venta`;
    rowValues[16] = `Estado de Pago Acum al ${paymentStatePreviousDate} -> %`;
    rowValues[17] = `Estado de Pago Parcial ${paymentStatePreviousDate} -> %`;
    rowValues[18] = `Estado de Pago Parcial ${paymentStatePreviousDate} -> Cantidad`;
    rowValues[19] = 'Estado de Pago Parcial -> %';
    rowValues[20] = 'Estado de Pago Acum al  -> Cantidad';
    rowValues[21] = 'Estado de Pago Acum al  -> Total Venta';
    rowValues[22] = 'ID_ITEM';
    rowValues[23] = 'ID_ITEM_SUP';
    rowValues[24] = 'ID_NAT';

    worksheet.insertRow(2, rowValues);

    const dateEp = _.get(advanceDatesData, `${selectedWork}[0].FECHA_AVA`, '...');

    console.log('dateEp', dateEp);

    worksheet.columns = [
      { header: 'CODIGO_ITEM', key: 'CODIGO_ITEM', width: 10 },
      { header: 'NOMBRE_ITEM', key: 'NOMBRE_ITEM', width: 40 },
      { header: 'PRECIO_ITEM_VENTA', key: 'PRECIO_ITEM_VENTA', width: 20 },
      { header: 'Moneda', key: 'Moneda', width: 10 },
      { header: 'NOMABR_UNI', key: 'NOMABR_UNI', width: 10 },
      {
        header: 'CANTIDAD_VIGENTE',
        key: 'CANTIDAD_VIGENTE',
        width: 28,
      },
      { header: 'TOTAL_VENTA', key: 'TOTAL_VENTA', width: 30 },
      {
        header: 'CANTIDAD_VIGENT',
        key: 'CANTIDAD_VIGENT',
        width: 30,
      },
      { header: 'TOTAL_MOD', key: 'TOTAL_MOD', width: 30 },
      {
        header: `PROD_PORCENTA`,
        key: 'PROD_PORCENTA',
        width: 35,
      },
      {
        header: `CANT_AVA`,
        key: 'CANT_AVA',
        width: 42,
      },
      {
        header: `TOTAL_ACUM_ANT`,
        key: 'TOTAL_ACUM_ANT',
        width: 40,
      },

      {
        header: `PORC_EP`,
        key: 'PORC_EP',
        width: 40,
      },
      {
        header: `CANT_EP`,
        key: 'CANT_EP',
        width: 42,
      },
      {
        header: `TOTAL_EP`,
        key: 'TOTAL_EP',
        width: 45,
      },
      {
        header: `PROD_PORCENTAJ`,
        key: 'PROD_PORCENTAJ',
        width: 35,
      },
      {
        header: `CANT_EP_ANT`,
        key: 'CANT_EP_ANT',
        width: 35,
      },
      {
        header: `TOTAL_EP_AN`,
        key: 'TOTAL_EP_AN',
        width: 45,
      },
      { header: 'PORC_E', key: 'PORC_E', width: 30 },
      { header: 'CANT_E', key: 'CANT_E', width: 30 },
      {
        header: 'TOTAL_EP_ANT',
        key: 'TOTAL_EP_ANT',
        width: 30,
      },
      { header: 'ID_ITEM', key: 'ID_ITEM', width: 10 },
      { header: 'ID_ITEM_SUP', key: 'ID_ITEM_SUP', width: 10 },
      { header: 'ID_NAT', key: 'ID_NAT', width: 10 },
    ];
    addRowsToWorksheet(worksheet, tree);
    workbook.xlsx.writeBuffer().then(function (buffer) {
      FileSaver.saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'test.xlsx');
    });
  };

  const createTreeData = (treeData) => {
    if (treeData && treeData.length > 0) {
      let treeObject = {};
      const treeDataItemsOptions = [];
      const initialExpandedRowKeys = [];

      treeData.forEach((item) => {
        if (treeObject[item.ID_ITEM_SUP]) {
          treeObject = update(treeObject, {
            [item.ID_ITEM_SUP]: {
              $push: [item],
            },
          });
        } else {
          treeObject = update(treeObject, {
            [item.ID_ITEM_SUP]: {
              $set: [item],
            },
          });
        }

        if (item.ID_NAT === 1) {
          initialExpandedRowKeys.push(item.ID_ITEM);
        }

        if (item.ID_NAT === 3) {
          treeDataItemsOptions.push(item);
        }
      });

      const newTree = [];
      const paths = {
        [treeObject[0][0].ID_ITEM]: '0',
      };
      const savePath = (id, path) => {
        if (!paths[id]) {
          paths[id] = path;
        }
      };

      treeObject[0].forEach((current, i) => {
        newTree.push({
          ...current,
          PATH: `${i}`,
          children: getChildren(current.ID_ITEM, treeObject, `${i}`, savePath),
        });
      });
      if (!itemFilterOptions.length) {
        setItemFilterOptions(treeDataItemsOptions);
      }

      setTree(newTree);
      setLastSavedTree(newTree);
      setExpandeRowKeys(initialExpandedRowKeys);
    }
  };

  useEffect(() => {
    let info = paymentStateInfo;

    if (!tree && !isFetching) {
      getPaymentState(selectedWork, get(match, 'params.id'));
    }
    if (!list[selectedWork]) {
      getPaymentStates(selectedWork);
    } else {
      const infoIndex = list[selectedWork].findIndex(
        (e) => String(e.ID_EP_ENC) === get(match, 'params.id')
      );
      setPaymentStateInfo(list[selectedWork][infoIndex]);
      info = list[selectedWork][infoIndex];
      const previousDate = list[selectedWork].find((e, i) => i > infoIndex && e.ID_ESTADO_EP !== 2);

      if (previousDate) {
        setPaymentStatePreviousDate(previousDate.FECHA_EP);
      }
    }

    if (!advanceDatesData[selectedWork]) {
      getAdvanceDates(selectedWork);
    }

    if (!currentAdvanceDate && advanceDatesData[selectedWork] && list[selectedWork]) {
      const advanceDate = advanceDatesData[selectedWork].find(
        ({ ID_AVA_ENC }) => info.ID_AVA_ENC === ID_AVA_ENC
      );
      if (advanceDate) setCurrentAdvanceDate(advanceDate.FECHA_AVA);
    }
  }, [list, advanceDatesData, selectedWork]);

  useEffect(() => {
    const treeData = data[selectedWork];
    createTreeData(treeData);
  }, [data]);

  const getCommonProps = (key) => ({
    onClick: () => setActive(key),
    onClose: () => setActive(null),
    show: active === key,
  });

  const editCell = (fieldsToUpdate) => {
    setFieldsToSave(
      update(fieldsToSave, {
        $apply: (fields) => {
          let newFields = [...fields];

          fieldsToUpdate.forEach((current) => {
            const currentIndex = newFields.findIndex((e) => e.ID_ITEM === current.ID_ITEM);

            if (currentIndex !== -1) {
              newFields = update(newFields, {
                [currentIndex]: { $merge: current },
              });
            } else {
              newFields = update(newFields, {
                $push: [current],
              });
            }

            if (current.ID_NAT === 3) {
              newFields = update(newFields, {
                $apply: (childrens) =>
                  childrens.filter(
                    (currentChildren) => currentChildren.ID_ITEM_SUP !== current.ID_ITEM
                  ),
              });
            }
          });

          return newFields;
        },
      })
    );

    let newTree = [...tree];

    fieldsToUpdate.forEach((element) => {
      if (element.TIPO_AVANCE && !expandedRowKeys.includes(element.ID_ITEM)) {
        setExpandeRowKeys(
          update(expandedRowKeys, {
            $push: [element.ID_ITEM],
          })
        );
      }
      const path = element.PATH.split('.');
      const updatedNode = getNodeObjectToUpdate(path, 0, { $merge: element });

      newTree = update(newTree, updatedNode);
    });

    setTree(newTree);
  };

  const changeTotals = () => {
    const record = tree[0];
    const MONTO_ACUM = getTotals(record, 'CANT_EP');

    const currentTotal = record.CANTIDAD_VIGENTE * record.PRECIO_ITEM_VENTA;
    let PRC_ACUM = null;
    if (MONTO_ACUM === 0 || currentTotal === 0) PRC_ACUM = 0;
    PRC_ACUM = (MONTO_ACUM * 100) / currentTotal;
    const prevTotal = getTotals(record, 'CANT_EP_ANT');
    const MONTO_PERIODO = MONTO_ACUM - prevTotal;
    const { ID_EP_ENC } = paymentStateInfo;
    const total1 = MONTO_ACUM === 0 || currentTotal === 0 ? 0 : (MONTO_ACUM * 100) / currentTotal;
    const total2 = prevTotal === 0 || currentTotal === 0 ? 0 : (prevTotal * 100) / currentTotal;
    const PRC_PERIODO = total1 - total2;

    editTotals({ MONTO_ACUM, PRC_ACUM, ID_EP_ENC, MONTO_PERIODO, PRC_PERIODO });
  };

  const saveEdition = () => {
    if (fieldsToSave.length || discount !== null || readjustment !== null) {
      const requestBody = {
        ITEM_CANTIDAD_EP: fieldsToSave.map(({ ID_ITEM, COMENTARIO_EP, CANT_EP }) => ({
          ID_ITEM,
          COMENTARIO_EP,
          CANT_EP,
        })),
        DESCUENTO_EP: discount !== undefined ? discount : paymentStateInfo.DESCUENTO_EP,
        REAJUSTE_EP: readjustment !== undefined ? readjustment : paymentStateInfo.REAJUSTE_EP,
      };
      const discountHasChanged = discount !== null && discount !== paymentStateInfo.DESCUENTO_EP;
      const readjustmentHasChanged =
        readjustment !== null && readjustment !== paymentStateInfo.REAJUSTE_EP;

      setSavingEdition(true);
      editPaymentStateCells(
        requestBody,
        paymentStateInfo.ID_EP_ENC,
        discountHasChanged || readjustmentHasChanged,
        selectedWork
      )
        .then(() => {
          changeTotals();
          setSavingEdition(false);
          setActiveEdition(null);
          setFieldsToSave([]);
          setResumeBoxValues({});
        })
        .catch(() => setSavingEdition(false));
    } else {
      setActiveEdition(null);
      setLastSavedTree(tree);
      setResumeBoxValues({});
    }
  };

  const activeEditMode = (key, optionKey) => {
    setActiveEdition(key);
    if (optionKey) {
      setActiveOptions({ ...activeOptions, [optionKey]: true });
    }
  };

  useEffect(() => {
    if (tree) {
      const { items: itemsFilters = [], chapters = [] } = filters;
      const toFilter = {
        PARTIDAS: itemsFilters.map((id) => ({ ID_ITEM: id })),
        CAPITULOS: chapters.map((id) => ({ ID_ITEM: id })),
      };

      getPaymentState(selectedWork, get(match, 'params.id'), toFilter);
    }
  }, [filters]);

  const applyFilters = (filterType, selectedOptions) => {
    if (filterType === 'item') {
      setFilters({ ...filters, items: selectedOptions });
    } else if (filterType === 'chapters') {
      setFilters({ ...filters, chapters: selectedOptions });
    }
    setActive(null);
  };

  const changeExpandedRows = (expanded, ID_ITEM) => {
    if (expanded) {
      setExpandeRowKeys(
        update(expandedRowKeys, {
          $push: [ID_ITEM],
        })
      );
    } else {
      const index = expandedRowKeys.indexOf(ID_ITEM);

      if (index >= 0) {
        setExpandeRowKeys(
          update(expandedRowKeys, {
            $splice: [[index, 1]],
          })
        );
      }
    }
  };

  // eslint-disable-next-line no-unused-vars
  const readExcel = (event) => {
    const workbook = new ExcelJS.Workbook();
    const { target } = event;
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }

    const arryBuffer = new Response(target.files[0]).arrayBuffer();
    arryBuffer.then((dataExcel) => {
      workbook.xlsx.load(dataExcel).then(() => {
        const worksheet = workbook.getWorksheet(1);

        const objectKeys = worksheet._rows[0]._cells;

        const newData = worksheet._rows.map((e) =>
          e._cells.reduce(
            (acc, current, index) => ({
              ...acc,
              [objectKeys[index]]: current.value,
            }),
            {}
          )
        );
        const newDataExcel = newData.splice(1);
        createTreeData(newDataExcel);
      });
    });
  };

  // eslint-disable-next-line no-unused-vars
  const props = {
    name: 'file',
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <div className="payment-state-management">
      <Row gutter={[30, 30]}>
        <Col className="first-buttons-col" span={24}>
          {activeEdition ? (
            <div className="edit-buttons">
              <h4>Modo Ajuste EP</h4>
              <div className="control-buttons">
                <Button
                  onClick={() => {
                    setActiveEdition(null);
                    setTree(lastSavedTree);
                    setFieldsToSave([]);
                  }}
                  disabled={savingEdition}
                >
                  Cancelar
                </Button>
                <Button
                  disabled={savingEdition || (fieldsToSave.length === 0 && discount === null)}
                  type="primary"
                  onClick={saveEdition}
                >
                  Guardar
                </Button>
              </div>
            </div>
          ) : (
            <div className="d-flex align-items-end justify-content-between w-100">
              <LeftTools info={paymentStateInfo} access={access} selectedWork={selectedWork} />
              <div className="d-flex align-items-end">
                {!!access.CONTROL_EP_AJUSTAR && (
                  <Button
                    disabled={
                      !paymentStateInfo ||
                      paymentStateInfo.ID_ESTADO_EP === 3 ||
                      paymentStateInfo.ID_ESTADO_EP === 2
                    }
                    type="primary"
                    onClick={() => {
                      activeEditMode('editPaymentState');
                    }}
                  >
                    Ajustar Estado de Pago
                  </Button>
                )}
                <Button type="primary" onClick={exportToExcelProduction}>
                  Exportar
                </Button>
                <Button type="Default" onClick={() => setShowFilterBar(!showFilterBar)}>
                  <i className="fa fa-filter mr-1" />
                  {!showFilterBar ? 'Filtros' : 'Ocultar Filtros'}
                </Button>
                <div className="viewoptions-payment">
                  <ViewOptions
                    defaultActiveOptions={activeOptions}
                    updateOptions={(opt) => setActiveOptions(opt)}
                    activeOptionsProps={activeOptions}
                    options={['Mostrar Contrato Actualizado', 'Mostrar Producción']}
                    {...getCommonProps('view')}
                  />
                </div>
              </div>
            </div>
          )}
        </Col>
      </Row>
      {showFilterBar && (
        <Row gutter={[30, 30]}>
          <Col className={`second-buttons-col ${!tree ? 'blocked' : ''}`} span={24}>
            <ChapterFilter
              title="Filtro Capítulos"
              tree={lastSavedTree || []}
              buttonTitle="Capítulo"
              defaultExpandItemValues={
                lastSavedTree && lastSavedTree.length ? [lastSavedTree[0].ID_ITEM] : []
              }
              onSave={(selectedOptions) => applyFilters('chapters', selectedOptions)}
              {...getCommonProps('chapters')}
            />
            <ListFilter
              allowSelectAll
              title="Filtro Partidas"
              subtitle="Lista de Partidas"
              onChange={(value, isChecked) =>
                changeItemFilters(value, isChecked, {
                  callback: setItemFilters,
                  filters: itemFilters,
                  options: itemFilterOptions,
                })
              }
              buttonTitle="Partida"
              elementKey="ID_ITEM"
              elementTitleKey="NOMBRE_ITEM"
              onSave={(selectedOptions) => applyFilters('item', selectedOptions)}
              options={itemFilterOptions}
              {...getCommonProps('item')}
            />
            <ListFilter
              title="Filtros Adicionales"
              allowSelectAll={false}
              buttonTitle="Más filtros"
              elementTitleKey="TITLE"
              elementKey="ID"
              onSave={(selectedOptions) => applyFilters('activity', selectedOptions)}
              options={[
                {
                  ID: 1,
                  TITLE: 'filtro 1',
                },
              ]}
              showSearch={false}
              {...getCommonProps('variation')}
            />
          </Col>
        </Row>
      )}
      <Row gutter={[30, 30]}>
        <Col className="production-col mt-3 p-0" span={24}>
          <Table
            className="common-table"
            height={window.innerHeight - (showFilterBar ? 292 : 210)}
            hover={false}
            rowKey="ID_ITEM"
            bordered
            headerHeight={75}
            rowHeight={28}
            virtualized
            shouldUpdateScroll={false}
            isTree
            locale={{
              emptyMessage: 'No hay datos para mostrar',
              loading: savingEdition ? 'Guardando...' : 'Cargando...',
            }}
            loading={!tree || isFetching || savingEdition || isFetchingAdvanceDates}
            data={tree || []}
            rowClassName={(record) => {
              if (record) {
                let type = 'first-level';
                const { ID_NAT } = record;
                if (ID_NAT === 1) {
                  type = 'green-row';
                }
                if (ID_NAT === 3) {
                  type = 'second-level';
                }
                return type;
              }
              return '';
            }}
            renderTreeToggle={(icon, row, expanded) => {
              if (expanded) {
                return <CaretUpOutlined />;
              }
              return <CaretDownOutlined />;
            }}
            expandedRowKeys={expandedRowKeys}
            onExpandChange={(expanded, rowData) =>
              changeExpandedRows(expanded, rowData.ID_ITEM, activeEdition === 'editProduction')
            }
          >
            {columns(activeOptions, activeEdition, editCell, {
              changeExpandedRows,
              paymentStateDate: paymentStateInfo.FECHA_EP,
              advanceDate: currentAdvanceDate,
              paymentStatePreviousDate,
            })}
          </Table>
        </Col>
      </Row>
      <Row gutter={[30, 30]}>
        <Col className="production-col mt-3 pt-0" span={24}>
          <ResumeBox
            paymentStateInfo={paymentStateInfo}
            tree={tree}
            saving={savingEdition}
            loading={!tree}
            isEditing={!!activeEdition}
            onChangeValues={(value, key) =>
              setResumeBoxValues({ ...resumeBoxValues, [key]: value })
            }
          />
        </Col>
      </Row>
    </div>
  );
};

PaymentState.propTypes = {
  works: PropTypes.object.isRequired,
  accountOfCostInfo: commonPropTypes.isRequired,
  production: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  paymentState: PropTypes.object.isRequired,
  getPaymentState: PropTypes.func.isRequired,
  getPaymentStates: PropTypes.func.isRequired,
  getAdvanceDates: PropTypes.func.isRequired,
  editPaymentStateCells: PropTypes.func.isRequired,
  editTotals: PropTypes.func.isRequired,
  menu: PropTypes.array.isRequired,
  access: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(
  ({ production, works, accountOfCost, paymentState, menu }) => ({
    production,
    accountOfCostInfo: accountOfCost,
    paymentState,
    menu,
  }),
  (dispatch) => ({
    getPaymentState: bindActionCreators(getPaymentStateAction, dispatch),
    getPaymentStates: bindActionCreators(getPaymentStatesAction, dispatch),
    getAdvanceDates: bindActionCreators(getAdvanceDatesAction, dispatch),
    editPaymentStateCells: bindActionCreators(editPaymentStateCellsAction, dispatch),
    editTotals: bindActionCreators(editTotalsAction, dispatch),
  })
)(PaymentState);
