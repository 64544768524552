import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import './index.scss';

const DatePickerWithTopLabel = ({ className, label, keepShowingLabel, ...restOfProps }) => {
  return (
    <div className={`datePickerContainer ${className}`}>
      {(typeof value === 'number' || keepShowingLabel) && (
        <div className="datePickerLabel">{label}</div>
      )}
      <DatePicker {...restOfProps} />
    </div>
  );
};

DatePickerWithTopLabel.defaultProps = {
  className: '',
  keepShowingLabel: false,
};

DatePickerWithTopLabel.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  keepShowingLabel: PropTypes.bool,
};

export default DatePickerWithTopLabel;
