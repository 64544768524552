import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { Modal, Button, Form, DatePicker, Select } from 'antd';
import { connect } from 'react-redux';

import { createNewAdvanceDate as createNewAdvanceDateAction } from '../../../actions/production';

import './index.scss';

const NewAdvance = ({ advanceDates, createNewAdvanceDate, selectedWork }) => {
  const [show, setShow] = useState(false);
  const [saving, setSaving] = useState(false);
  const [formattedAdvanceDates, setFormattedAdvanceDates] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    const currentDate = moment();
    if (advanceDates) {
      const previousDate = advanceDates.find(
        ({ FECHA_AVA }) =>
          currentDate.isAfter(moment(FECHA_AVA, 'DD-MM-YYYY')) &&
          currentDate.format('DD-MM-YYYY') !== FECHA_AVA
      );

      setFormattedAdvanceDates(
        advanceDates.map((e) => moment(e.FECHA_AVA, 'DD-MM-YYYY').format('MM-YYYY'))
      );
      form.setFieldsValue({ lastAdvance: previousDate ? previousDate.ID_AVA_ENC : null });
    }
  }, [advanceDates]);

  const submit = () => {
    const date = moment(form.getFieldValue('productionDate')).format('DD-MM-YYYY');
    setSaving(true);
    createNewAdvanceDate(selectedWork, date)
      .then(() => {
        form.setFieldsValue({
          productionDate: moment(form.getFieldValue('productionDate')).add(1, 'days'),
        });
        setSaving(false);
        setShow(false);
      })
      .catch(() => setSaving(false));
  };

  const onValuesChange = (changedValues) => {
    const { productionDate } = changedValues;

    if (productionDate) {
      const previousDate = advanceDates.find(
        ({ FECHA_AVA }) =>
          productionDate.isAfter(moment(FECHA_AVA, 'DD-MM-YYYY')) &&
          productionDate.format('DD-MM-YYYY') !== FECHA_AVA
      );

      form.setFieldsValue({ lastAdvance: previousDate ? previousDate.ID_AVA_ENC : null });
    }
  };
  const disabledDate = (current) => {
    return formattedAdvanceDates.includes(current.format('MM-YYYY'));
  };
  return (
    <div className="advance">
      <Button
        disabled={!advanceDates}
        onClick={() => setShow(true)}
        type="primary"
        className="new-advance-button"
      >
        <i className="fa fa-plus" /> Nuevo Avance
      </Button>
      {advanceDates && (
        <Modal
          closable={false}
          wrapClassName="advance-modal"
          visible={show}
          onCancel={() => {
            setShow(false);
          }}
          footer={null}
          okText="Guardar"
        >
          <div className="advance-container">
            <div className="advance-header">
              <div />
              <span>Nuevo Avance</span>
              <i
                className="fa fa-times"
                onClick={() => {
                  setShow(false);
                }}
              />
            </div>
            <div className="advance-body">
              <Form onValuesChange={onValuesChange} form={form} layout="vertical" onFinish={submit}>
                <Form.Item
                  initialValue={moment()}
                  rules={[
                    {
                      required: true,
                      message: 'Por favor seleccione una fecha',
                    },
                  ]}
                  label="Ingresar fecha de producción al"
                  name="productionDate"
                  required
                >
                  <DatePicker disabledDate={disabledDate} picker="month" format="MMMM, YYYY" />
                </Form.Item>
                <Form.Item label="Precargar avance al" name="lastAdvance" required>
                  <Select
                    disabled
                    options={advanceDates.map(({ FECHA_AVA, ID_AVA_ENC }) => ({
                      label: FECHA_AVA,
                      value: ID_AVA_ENC,
                    }))}
                  />
                </Form.Item>
                <div className="advance-footer">
                  <Button disabled={saving} onClick={() => setShow(false)}>
                    Cancelar
                  </Button>
                  <Button type="primary" htmlType="submit" disabled={saving} loading={saving}>
                    Generar Nuevo Avance
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

NewAdvance.propTypes = {
  advanceDates: PropTypes.array.isRequired,
  createNewAdvanceDate: PropTypes.func.isRequired,
  selectedWork: PropTypes.number.isRequired,
};

export default connect(
  ({ production, works, accountOfCost }) => ({
    production,
    selectedWork: works.selectedWork,
    accountOfCostInfo: accountOfCost,
  }),
  (dispatch) => ({
    createNewAdvanceDate: bindActionCreators(createNewAdvanceDateAction, dispatch),
  })
)(NewAdvance);
