import { message } from 'antd';
import api, { getHeaders } from '../utils/api';
import store from '../store';
import {
  SET_ADVANCE_INCOME_FIELDS,
  RESET_ADVANCE_INCOME_FIELDS,
} from '../reducers/advanceIncomeEdition';

export const setAdvanceIncomeEdition = (fieldsToUpdate, record) => (dispatch) =>
  dispatch({
    type: SET_ADVANCE_INCOME_FIELDS,
    payload: { fieldsToUpdate, record },
  });

export const resetAdvanceIncomeEdition = () => (dispatch) =>
  dispatch({
    type: RESET_ADVANCE_INCOME_FIELDS,
  });

export const saveAdvanceControlEdition = (body) => (dispatch) =>
  new Promise((resolve, reject) => {
    const errorMessage = 'Error al editar las celdas';
    const requestBody = {
      ...body,
      EDICION: store
        .getState()
        .advanceControlEdition.map((e) => ({ ...e, DURACION_REST: -1, PATH: undefined })),
    };
    api
      .post('api/Avances/SetAvaVistaGantt', requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200 && res.data === 'OK') {
          message.success('La proyección se ha editado correctamente', 5);
          dispatch({
            type: RESET_ADVANCE_INCOME_FIELDS,
          });
          resolve();
        } else {
          reject();
          message.error(errorMessage);
        }
      })
      .catch((err) => {
        console.log(err);
        message.error(errorMessage);
        reject();
      });
  });
