import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Select } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getProjects as getProjectsAction,
  inactiveOrCloseProject as inactiveOrCloseProjectAction,
} from '../../../actions/projects';
import { commonSelectProps, commonPropTypes } from '../../../utils';
import { getCompanies as getCompaniesAction } from '../../../actions/companies';
import Table from '../../../components/Table';
import DoubleLineCell from '../../../components/DoubleLineCell';
import ProjectForm from '../Form';

import './index.scss';

const { Option } = Select;

const Projects = ({
  getProjects,
  projects,
  inactiveOrCloseProject,
  companies,
  getCompanies,
  role,
  access,
  t,
}) => {
  const [showInactiveProjectModal, setShowInactiveProjectModal] = useState(false);
  const [showCloseProjectModal, setShowCloseProjectModal] = useState(false);

  const [showFormModal, setShowFormModal] = useState(false);
  const [activeRecord, setActiveRecord] = useState(false);
  const [isDeleting, setIsChangingStatus] = useState(false);
  const [selectFilter, setStateFilter] = useState({ company: null, state: null });

  const { isFetching: loadingProjects, data: projectsData } = projects;
  const { isFetching: loadingCompanies, data: companiesData } = companies;

  useEffect(() => {
    if (!projectsData) {
      getProjects();
    }

    if (!companiesData) {
      getCompanies();
    }
  }, []);

  const setProjectStatus = (code) => {
    setIsChangingStatus(true);
    inactiveOrCloseProject(activeRecord.ID_OBR, code)
      .then(() => {
        setIsChangingStatus(false);
        setShowInactiveProjectModal(false);
        setShowCloseProjectModal(false);
        setActiveRecord(null);
      })
      .catch(() => {
        setIsChangingStatus(false);
        setShowInactiveProjectModal(false);
        setShowCloseProjectModal(false);
        setActiveRecord(null);
      });
  };

  const hideModal = () => {
    setShowFormModal(false);
    setActiveRecord(null);
  };

  const columns = [
    {
      title: '',
      dataIndex: 'operation',
      width: '100px',
      render: (_, record) => {
        return (
          <div className="edit-icons">
            <i
              className="fa fa-edit edit-icon"
              onClick={() => {
                setShowFormModal(true);
                setActiveRecord(record);
              }}
            />
            {record.VIGENTE_OBR === '1' && role === 'editor' && (
              <>
                <i
                  className="fa fa-lock"
                  onClick={() => {
                    setShowCloseProjectModal(true);
                    setActiveRecord(record);
                  }}
                />
                <i
                  className="fa fa-minus-circle"
                  onClick={() => {
                    setShowInactiveProjectModal(true);
                    setActiveRecord(record);
                  }}
                />
              </>
            )}
          </div>
        );
      },
    },
    {
      title: (
        <DoubleLineCell firstValue={`${t('project.name')} /`} secondValue={t('common.company')} />
      ),
      dataIndex: 'NOMABR_OBR',
      render: (value, { NOMBRE_EMP }) => (
        <DoubleLineCell firstValue={value} secondValue={NOMBRE_EMP} />
      ),
    },

    {
      title: t('project.erpCode'),
      dataIndex: 'CODIGO_ERP',
      render: (value) => value,
    },
    {
      title: (
        <DoubleLineCell firstValue={`${t('common.address')} /`} secondValue={t('common.commune')} />
      ),
      dataIndex: 'DIRECCION_OBR',
      render: (value, { NOMBRE_COMUNA }) => (
        <DoubleLineCell firstValue={value} secondValue={NOMBRE_COMUNA} />
      ),
    },
    {
      title: (
        <DoubleLineCell firstValue={`${t('common.admin')} /`} secondValue={t('common.manager')} />
      ),
      dataIndex: 'DIRECTOR_OBR',
      render: (value, { GERENTE_PROY }) => (
        <DoubleLineCell firstValue={value} secondValue={GERENTE_PROY} />
      ),
    },
    {
      title: (
        <DoubleLineCell
          firstValue={`${t('project.contractStart')} /`}
          secondValue={t('project.contractEnd')}
        />
      ),
      dataIndex: 'FECINICONT',
      render: (value, { FECFINCONT }) => (
        <DoubleLineCell firstValue={value} secondValue={FECFINCONT} />
      ),
    },
    {
      title: (
        <DoubleLineCell
          firstValue={`${t('project.contractType')} /`}
          secondValue={t('project.contractAmount')}
        />
      ),
      dataIndex: 'NOMBRE_TIPO_CONTRATO',
      render: (value, { MONTO_CONTRATO }) => (
        <DoubleLineCell firstValue={value} secondValue={MONTO_CONTRATO} />
      ),
    },
    {
      title: (
        <DoubleLineCell
          firstValue={`${t('project.advance')} /`}
          secondValue={t('project.retention')}
        />
      ),
      dataIndex: 'ANTICIPO_PRC',
      render: (value, { RETENCION_PRC }) => (
        <DoubleLineCell firstValue={value} secondValue={RETENCION_PRC} />
      ),
    },
    {
      title: t('common.state'),
      dataIndex: 'VIGENTE_OBR',
      render: (value) => {
        let text = '';
        if (value === '1') text = 'VIGENTE';
        if (value === '2') text = 'CERRADO';
        return text;
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return col;
  });

  const finalProjectData =
    selectFilter.company || selectFilter.state
      ? projectsData.filter(({ VIGENTE_OBR, ID_EMP }) => {
          const companyCondition = selectFilter.company ? ID_EMP === selectFilter.company : true;
          const stateCondition = selectFilter.state ? VIGENTE_OBR === selectFilter.state : true;
          return companyCondition && stateCondition;
        })
      : projectsData;
  return (
    <div className="project-list">
      <Modal
        title={`${activeRecord ? 'Editando' : 'Nuevo'} Proyecto`}
        visible={showFormModal}
        onOk={hideModal}
        onCancel={hideModal}
        footer={null}
        maskClosable={false}
        wrapClassName="project-form-modal"
        closable={false}
      >
        <ProjectForm role={role} editData={activeRecord} onCancel={hideModal} />
      </Modal>
      {activeRecord && (
        <>
          <Modal
            title="Proyecto"
            visible={showCloseProjectModal}
            maskStyle={{ opacity: 0.3 }}
            closable={false}
            onOk={() => setShowCloseProjectModal(false)}
            onCancel={() => setShowCloseProjectModal(false)}
            style={{ top: '10px' }}
            footer={[
              <Button
                key="back"
                disabled={isDeleting}
                onClick={() => setShowCloseProjectModal(false)}
              >
                Cancelar
              </Button>,
              <Button
                key="submit"
                disabled={isDeleting}
                loading={isDeleting}
                type="primary"
                onClick={() => setProjectStatus('2')}
              >
                Confirmar
              </Button>,
            ]}
          >
            <p>Estás a punto de cerrar el proyecto {activeRecord.NOMABR_OBR}</p>
            <p style={{ textAlign: 'center' }}>Confirma esta acción</p>
          </Modal>
          <Modal
            title="Proyecto"
            visible={showInactiveProjectModal}
            maskStyle={{ opacity: 0.3 }}
            closable={false}
            onOk={() => setShowInactiveProjectModal(false)}
            onCancel={() => setShowInactiveProjectModal(false)}
            footer={[
              <Button
                key="back"
                disabled={isDeleting}
                onClick={() => setShowInactiveProjectModal(false)}
              >
                Cancelar
              </Button>,
              <Button
                key="submit"
                disabled={isDeleting}
                loading={isDeleting}
                type="primary"
                onClick={() => setProjectStatus('0')}
              >
                Confirmar
              </Button>,
            ]}
          >
            <p>Estás a punto de dejar inactivo el proyecto {activeRecord.NOMABR_OBR}</p>
            <p style={{ textAlign: 'center' }}>Confirma esta acción</p>
          </Modal>
        </>
      )}
      <Table
        loading={loadingProjects}
        t={t}
        extraTools={
          <>
            <Select
              style={{ width: 200, marginRight: '10px' }}
              placeholder={t('common.company')}
              loading={loadingCompanies}
              disabled={loadingCompanies}
              onChange={(value) => setStateFilter({ ...selectFilter, company: value })}
              {...commonSelectProps}
            >
              <Option value={null}>Todas las Empresas</Option>
              {companies.data &&
                companies.data.map(({ NOMBRE_EMP, ID_EMP }) => (
                  <Option value={ID_EMP}>{NOMBRE_EMP}</Option>
                ))}
            </Select>
            <Select
              style={{ width: 200 }}
              placeholder={t('common.state')}
              optionFilterProp="children"
              onChange={(value) => setStateFilter({ ...selectFilter, state: value })}
            >
              <Option value={null}>Todos los Estados</Option>
              <Option value="1">Vigente</Option>
              <Option value="2">Cerrado</Option>
            </Select>
          </>
        }
        linkButton={
          access.PROYECTO_CREAR ? (
            <Button type="primary" onClick={() => setShowFormModal(true)}>
              <i className="fa fa-plus" />
              {t('project.new')}
            </Button>
          ) : null
        }
        dataSource={finalProjectData}
        columnsInfo={mergedColumns}
        rowClassName="editable-row"
      />
    </div>
  );
};

Projects.propTypes = {
  projects: commonPropTypes.isRequired,
  getProjects: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  getCompanies: PropTypes.func.isRequired,
  companies: commonPropTypes.isRequired,
  inactiveOrCloseProject: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  access: PropTypes.object.isRequired,
  t: PropTypes.object.isRequired,
};

export default connect(
  (store) => ({
    projects: store.projects,
    companies: store.companies,
  }),
  (dispatch) => ({
    getProjects: bindActionCreators(getProjectsAction, dispatch),
    getCompanies: bindActionCreators(getCompaniesAction, dispatch),
    inactiveOrCloseProject: bindActionCreators(inactiveOrCloseProjectAction, dispatch),
  })
)(Projects);
