import _ from 'lodash';
import api, { getHeaders } from '../utils/api';
import store from '../store';
import { CAS_CD_FETCHED, GET_CAS_CD, GET_CAS_CD_ERROR } from '../reducers/casCd';

export const casCdFetched = (casCd, workId, attr) => ({
  type: CAS_CD_FETCHED,
  casCd,
  workId,
  attr,
});

export const getCasCd = (FECHA_CORTE, ID_OBR, TIPO_CONTRATO) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: GET_CAS_CD });

    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR,
      FECHA_CORTE,
      TIPO_CONTRATO,
    };
    api
      .post('/api/CostoInformeCas/GetInformeCasCD', requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch(casCdFetched(res.data, ID_OBR));
          resolve(res.data);
        } else {
          dispatch({
            type: GET_CAS_CD_ERROR,
          });
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_CAS_CD_ERROR,
        });
        reject(err);
      });
  });

export const editCasCd = (FECHA_CORTE, ID_OBR, EDICION) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR,
      FECHA_CORTE,
      EDICION,
    };
    api
      .post('/api/CostoInformeCas/SetAjustarCostoUnitarioSaldo', requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          resolve();
        } else {
          reject();
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
