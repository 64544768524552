import api, { getHeaders } from '../utils/api';

import { MENU_FETCHED, GET_MENU, GET_MENU_ERROR } from '../reducers/menu';

export const setMenu = (menu) => ({
  type: MENU_FETCHED,
  menu,
});

export const error = () => ({
  type: GET_MENU_ERROR,
});

export const getMenu = (client, withLoader = true) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (withLoader) dispatch({ type: GET_MENU, client });
    if (!localStorage.getItem('foco_client')) {
      localStorage.setItem('foco_client', client);
    }
    const requestBody = {
      usuario: localStorage.getItem('foco_username'),
      empresa: client,
      ip: '1.1.1.1',
      dispositivo: 'web',
    };
    api
      .post(`/api/Acceso/GetMenuSuiteV2`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch(setMenu(res.data));
          resolve(res.data);
        } else {
          dispatch(error());
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(error());
        reject(err);
      });
  });
