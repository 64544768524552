import update from 'immutability-helper';
import { AUTH_LOGOUT } from './auth';

export const COMPANIES_FETCHED = 'COMPANIES_FETCHED';
export const GET_COMPANIES = 'GET_COMPANIES';
export const GET_COMPANIES_ERROR = 'GET_COMPANIES_ERROR';
export const ADD_NEW_COMPANY = 'ADD_NEW_COMPANY';
export const DELETE_COMPANY = 'DELETE_COMPANY';
export const EDIT_COMPANY = 'EDIT_COMPANY';

const companiesInit = {
  data: null,
  isFetching: true,
  error: false,
};

const companies = (companiesInfo = companiesInit, action) => {
  switch (action.type) {
    case COMPANIES_FETCHED:
      return {
        data: action.companies,
        isFetching: false,
        error: false,
      };
    case GET_COMPANIES_ERROR:
      return {
        data: null,
        isFetching: false,
        error: true,
      };
    case GET_COMPANIES:
      return {
        ...companiesInit,
        isFetching: true,
        error: false,
      };

    case AUTH_LOGOUT:
      return companiesInit;

    case ADD_NEW_COMPANY:
      return {
        ...companiesInfo,
        data: [...companiesInfo.data, action.company],
      };

    case DELETE_COMPANY:
      return {
        ...companiesInfo,
        data: companiesInfo.data.filter(({ ID_EMP }) => ID_EMP !== action.ID_EMP),
      };

    case EDIT_COMPANY: {
      const companyIndex = companiesInfo.data.findIndex(
        (company) => company.ID_EMP === action.company.ID_EMP
      );

      return update(companiesInfo, {
        data: {
          [companyIndex]: {
            $set: action.company,
          },
        },
      });
    }

    default:
      return companiesInfo;
  }
};

export default companies;
