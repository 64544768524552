import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Row, Col, Button } from 'antd';
import 'moment/locale/es';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import update from 'immutability-helper';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import Table from '../../components/TableExtend';
import { getRate as getRateAction, setRate } from '../../actions/rate';
import columns from './columns';
import './index.scss';
import { SearchIcon, normalizeString } from '../../utils';
import InputWithTopLabel from '../../components/InputWithTopLabel';
import ViewOptions from '../../components/ViewOptions';

const columnsKeys = ['ID_ITEM', 'CODIGO_ITEM', 'NOMBRE_ITEM', 'TOTAL_VENTA', 'PRECIO_ITEM_PROY'];

const RateScreen = ({ selectedWork, rate, getRate, access }) => {
  const [list, setList] = useState(null);
  const [expandedRowKeys, setExpandeRowKeys] = useState([]);
  const [activeEdition, setActiveEdition] = useState(null);
  const [fieldsToSave, setFieldsToSave] = useState([]);
  const [savingEdition, setSavingEdition] = useState(false);
  const [lastSavedList, setLastSavedList] = useState(null);
  const [active, setActive] = useState(null);
  const [activeOptions, setActiveOptions] = useState({
    option0: false,
    option1: false,
    option2: false,
  });

  const { data, isFetching } = rate;

  const getCommonProps = (key) => ({
    onClick: () => setActive(key),
    onClose: () => setActive(null),
    show: active === key,
  });

  const handleSearch = (value) => {
    if (value !== '') {
      const newData = list.filter((record) => {
        return columnsKeys.some((item) => {
          let val = record[item];

          val = normalizeString(`${val}`);
          return val.indexOf(normalizeString(value)) !== -1;
        });
      });
      setList(newData);
    } else if (value === '') {
      setList(data[selectedWork]);
    }
  };

  useEffect(() => {
    if (!data[selectedWork] && !isFetching) {
      getRate(selectedWork);
    } else if (data[selectedWork] && !isFetching) {
      const listData = get(data, [selectedWork]);
      setList(listData);
      setLastSavedList(listData);
    }
  }, [selectedWork]);

  useEffect(() => {
    if (data[selectedWork]) {
      const listData = get(data, [selectedWork]);
      setList(listData);
      setLastSavedList(listData);
    }
  }, [data]);

  const changeExpandedRows = (expanded, ID_ITEM) => {
    if (expanded) {
      setExpandeRowKeys(
        update(expandedRowKeys, {
          $push: [ID_ITEM],
        })
      );
    } else {
      const index = expandedRowKeys.indexOf(ID_ITEM);

      if (index >= 0) {
        setExpandeRowKeys(
          update(expandedRowKeys, {
            $splice: [[index, 1]],
          })
        );
      }
    }
  };

  const saveEdition = () => {
    if (fieldsToSave.length) {
      const newItems = fieldsToSave.map((fieldToSave) => {
        const newFieldToSave = {
          ID_ITEM: fieldToSave.ID_ITEM,
          PRECIO_ITEM_PROY: fieldToSave.PRECIO_ITEM_PROY,
        };
        return newFieldToSave;
      });
      setSavingEdition(true);
      setRate({
        ITEM_TARIFADO: newItems,
      })
        .then(() => {
          setSavingEdition(false);
          setActiveEdition(null);
          setFieldsToSave([]);
          setLastSavedList(list);
        })
        .catch(() => setSavingEdition(false));
    } else {
      setActiveEdition(null);
      setLastSavedList(lastSavedList);
    }
  };

  const editCell = (fieldsToUpdate) => {
    setFieldsToSave(
      update(fieldsToSave, {
        $apply: (fields) => {
          let newFields = [...fields];

          fieldsToUpdate.forEach((current) => {
            const currentIndex = newFields.findIndex((e) => e.ID_ITEM === current.ID_ITEM);

            if (currentIndex !== -1) {
              newFields = update(newFields, {
                [currentIndex]: { $merge: current },
              });
            } else {
              newFields = update(newFields, {
                $push: [current],
              });
            }
          });

          return newFields;
        },
      })
    );

    let newList = [...list];

    fieldsToUpdate.forEach((element) => {
      const indexCurrent = newList.findIndex((e) => e.ID_ITEM === element.ID_ITEM);

      newList = update(newList, {
        [indexCurrent]: { $merge: element },
      });
    });
    setList(newList);
  };

  return (
    <div className="baseRate">
      <Row gutter={[30, 30]}>
        <Col className="first-buttons-col d-flex" span={24}>
          <div className="input-search">
            <InputWithTopLabel
              icon={<SearchIcon />}
              onChange={(value) => handleSearch(value)}
              label="Buscar"
            />
          </div>

          <>
            {activeEdition ? (
              <div className="">
                <Button
                  onClick={() => {
                    setActiveEdition(false);
                    setList(lastSavedList);
                    setFieldsToSave([]);
                  }}
                  disabled={savingEdition}
                >
                  Cancelar
                </Button>
                <Button
                  disabled={savingEdition || !fieldsToSave.length}
                  type="primary"
                  onClick={saveEdition}
                >
                  Guardar
                </Button>
              </div>
            ) : (
              <>
                {!!access.CONTROL_RECURSOS_AJUSTAR_PROYECTADO && (
                  <Button
                    type="primary"
                    disabled={isFetching}
                    onClick={() => {
                      setActiveEdition('editProjection');
                    }}
                  >
                    Ajustar Proyectado
                  </Button>
                )}
                <div className="view-options-rate">
                  <ViewOptions
                    defaultActiveOptions={activeOptions}
                    updateOptions={(opt) => setActiveOptions(opt)}
                    activeOptionsProps={activeOptions}
                    options={[
                      'Mostrar Forecast ',
                      'Mostrar Contrato Vigente',
                      'Segmentación de Recursos',
                    ]}
                    {...getCommonProps('view')}
                  />
                </div>
              </>
            )}
          </>
        </Col>
      </Row>

      <Row gutter={[30, 30]}>
        <Col className="production-col mt-3 pt-0" span={24}>
          <Table
            className="common-table"
            height={window.innerHeight - 180}
            hover={false}
            rowKey="ID_ITEM"
            bordered
            headerHeight={75}
            rowHeight={28}
            virtualized
            shouldUpdateScroll={false}
            isTree
            locale={{
              emptyMessage: 'No hay datos para mostrar',
              loading: 'Cargando...',
            }}
            loading={isFetching || savingEdition}
            data={list || []}
            rowClassName={(record) => {
              if (record) {
                let type = 'level';
                if (record.TIPO === 1) {
                  type = 'first-level';
                } else if (record.TIPO === 3) {
                  type = 'second-level account-cost-level';
                }
                return type;
              }
              return '';
            }}
            renderTreeToggle={(_icon, _row, expanded) => {
              if (expanded) {
                return <CaretUpOutlined />;
              }
              return <CaretDownOutlined />;
            }}
            expandedRowKeys={expandedRowKeys}
            onExpandChange={(expanded, rowData) => {
              changeExpandedRows(expanded, rowData.ID_ITEM);
            }}
          >
            {columns(activeEdition, editCell, activeOptions)}
          </Table>
        </Col>
      </Row>
    </div>
  );
};

RateScreen.propTypes = {
  selectedWork: PropTypes.number.isRequired,
  rate: PropTypes.object.isRequired,
  getRate: PropTypes.func.isRequired,
  access: PropTypes.object.isRequired,
};

export default connect(
  ({ works, rate }) => ({
    selectedWork: works.selectedWork,
    rate,

    inputsTypes: rate.inputsTypes,
  }),
  (dispatch) => ({
    getRate: bindActionCreators(getRateAction, dispatch),
  })
)(RateScreen);
