import React, { useState } from 'react';
import { Input, Select, Form, Modal, Button } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
  savePosition as savePositionAction,
  updatePosition as updatePositionAction,
} from '../../../actions/areasAndPositions';
import { getFormattedNumber, normalizeString, normalizeStringToApi } from '../../../utils';
import './index.scss';

const erpRegex = /^[0-9]*$/;
const priceRegex = /^\$\d+([0-9]+([,][0-9]*)?|[,][0-9]+)$/;

const NewPosition = ({
  areasAndPositions,
  areaId,
  oldCompanyPrice,
  oldErp,
  oldName,
  onClose,
  positionId,
  savePosition,
  selectedWork,
  title,
  updatePosition,
  works,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [form] = Form.useForm();

  const submit = (values) => {
    setIsSaving(true);
    const { area, companyPrice, erp, position } = values;

    if (areaId) {
      const positionData = {
        CODEXT_CAR: erp,
        COSTO_CAR: companyPrice.replace('$', '').replaceAll('.', '').replace(',', '.'),
        ID_ARE: area,
        ID_CAR: positionId,
        NOMBRE_CAR: normalizeStringToApi(position),
      };

      updatePosition(positionData, areaId)
        .then(() => {
          onClose();
          setIsSaving(false);
        })
        .catch(() => {
          setIsSaving(false);
        });
    } else {
      const positionData = {
        CODEXT_CAR: erp,
        COSTO_CAR: companyPrice.replace('$', '').replaceAll('.', '').replace(',', '.'),
        ID_ARE: area,
        ID_CAR: '',
        NOMBRE_CAR: normalizeStringToApi(position),
      };

      savePosition(positionData)
        .then(() => {
          onClose();
          setIsSaving(false);
        })
        .catch(() => {
          setIsSaving(false);
        });
    }
  };

  const areas = areasAndPositions
    .filter((e) => e.ID_OBR === selectedWork)
    .map((e) => ({ ID_ARE: e.ID_ARE, NOMBRE_ARE: e.NOMBRE_ARE }));

  return (
    <Modal
      title={title}
      visible
      onOk={() => onClose()}
      onCancel={() => onClose()}
      width={400}
      footer={null}
      wrapClassName="positionContainer"
    >
      <Form layout="vertical" form={form} onFinish={submit}>
        <Form.Item name="project" label="Proyecto" initialValue={String(selectedWork)}>
          <Select disabled>
            {works.map((opt) => (
              <Select.Option value={opt.ID_OBR} key={`optionInNewArea${opt.ID_OBR}`}>
                {opt.NOMBRE_OBR}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="area"
          label="Área"
          rules={[
            {
              required: true,
              message: 'Por favor seleccione un área',
            },
          ]}
          initialValue={areaId}
        >
          <Select placeholder="Seleccionar...">
            {areas.map((opt) => (
              <Select.Option value={opt.ID_ARE} key={`optionInNewPosition${opt.ID_ARE}`}>
                {opt.NOMBRE_ARE}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="position"
          label="Nombre Cargo"
          initialValue={oldName || ''}
          rules={[
            {
              required: true,
              message: 'Por favor ingrese un cargo',
            },
            {
              validator: async (obj, value) => {
                if (!areaId || oldName !== value) {
                  if (
                    form.getFieldValue('area') &&
                    areasAndPositions
                      .find((e) => e.ID_ARE === form.getFieldValue('area'))
                      .CARGOS_ASOCIADOS.some(
                        (e) => normalizeString(e.NOMBRE_CAR) === normalizeString(value)
                      )
                  ) {
                    return Promise.reject(new Error('Ya existe un cargo con este nombre'));
                  }

                  if (!/^[a-zA-Z\u00C0-\u024F0-9 ]*$/.test(value)) {
                    return Promise.reject(new Error('No se permiten caracteres especiales'));
                  }

                  if (value.length && value.trim().length === 0) {
                    return Promise.reject(new Error('No se permiten solo espacios vacios'));
                  }
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input maxLength={200} />
        </Form.Item>
        <Form.Item
          name="companyPrice"
          label="Precio Empresa (opcional)"
          initialValue={oldCompanyPrice ? `$${getFormattedNumber(oldCompanyPrice)}` : ''}
          rules={[
            {
              validator: async (obj, value) => {
                const newValue = value.replaceAll('.', '');
                if (newValue.length > 0 && !priceRegex.test(newValue)) {
                  return Promise.reject(new Error('Por favor ingrese un precio de empresa válido'));
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            onChange={(e) => {
              form.setFieldsValue({
                companyPrice:
                  e.target.value[0] === '$'
                    ? e.target.value.replaceAll('.', '')
                    : `$${e.target.value.replaceAll('.', '')}`,
              });
            }}
            onBlur={() => {
              const price = form.getFieldValue('companyPrice');
              if (priceRegex.test(price)) {
                form.setFieldsValue({
                  companyPrice: `$${getFormattedNumber(price.replace('$', '').replace(',', '.'))}`,
                });
              }
            }}
            onFocus={() => {
              const price = form.getFieldValue('companyPrice').replaceAll('.', '');
              if (priceRegex.test(price)) {
                form.setFieldsValue({ companyPrice: price });
              }
            }}
          />
        </Form.Item>
        <Form.Item
          name="erp"
          label="Código ERP Externo (opcional)"
          initialValue={oldErp || ''}
          rules={[
            {
              pattern: erpRegex,
              message: 'Por favor ingrese un código ERP válido',
            },
          ]}
        >
          <Input maxLength={200} />
        </Form.Item>
        <div className="buttons">
          <Form.Item>
            <Button disabled={isSaving} onClick={() => onClose()}>
              Cancelar
            </Button>
          </Form.Item>
          <Form.Item>
            <Button disabled={isSaving} loading={isSaving} type="primary" htmlType="submit">
              Aceptar
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

NewPosition.defaultProps = {
  areaId: null,
  oldCompanyPrice: null,
  oldErp: '',
  oldName: '',
  positionId: null,
  title: 'Nuevo Cargo',
};

NewPosition.propTypes = {
  areaId: PropTypes.number,
  areasAndPositions: PropTypes.array.isRequired,
  oldCompanyPrice: PropTypes.string,
  oldErp: PropTypes.string,
  oldName: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  positionId: PropTypes.number,
  savePosition: PropTypes.func.isRequired,
  selectedWork: PropTypes.number.isRequired,
  title: PropTypes.string,
  updatePosition: PropTypes.func.isRequired,
  works: PropTypes.array.isRequired,
};

export default connect(
  (store) => ({
    areasAndPositions: store.areasAndPositions.data,
    selectedWork: store.works.selectedWork,
    works: store.works.data,
  }),
  (dispatch) => ({
    savePosition: bindActionCreators(savePositionAction, dispatch),
    updatePosition: bindActionCreators(updatePositionAction, dispatch),
  })
)(NewPosition);
