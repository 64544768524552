import qs from 'querystring';
import axios from 'axios';
import get from 'lodash/get';
import { message } from 'antd';
// eslint-disable-next-line import/no-cycle
import api, { getHeaders } from '../utils/api';
import {
  AUTH_ERROR,
  AUTH_LOGGED,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  SET_INFORMATION_FROM_ADVANCE_URL,
  SET_INFORMATION_FROM_ATTENDANCE_URL,
  SET_INFORMATION_FROM_PAYMENT_URL,
} from '../reducers/auth';

export const setSession = (user, token) => ({
  type: AUTH_LOGGED,
  user,
  token,
});

export const logout = () => {
  localStorage.removeItem('foco_token');
  localStorage.removeItem('foco_username');
  localStorage.removeItem('foco_client');
  return {
    type: AUTH_LOGOUT,
  };
};

export const error = () => ({
  type: AUTH_ERROR,
});

export const setInformationFromAttendanceURL = (client, project, token, username) => (dispatch) =>
  dispatch({
    type: SET_INFORMATION_FROM_ATTENDANCE_URL,
    payload: { client, project, token, username },
  });

export const setInformationFromPaymentURL = (client, paymentId, token, username) => (dispatch) =>
  dispatch({
    type: SET_INFORMATION_FROM_PAYMENT_URL,
    payload: { client, paymentId, token, username },
  });

export const setInformationFromAdvanceUrl = (client, token, username, work) => (dispatch) =>
  dispatch({
    type: SET_INFORMATION_FROM_ADVANCE_URL,
    payload: { client, token, username, work },
  });

export const login = (username, password) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: AUTH_LOGIN });

    const requestBody = {
      grant_type: 'password',
      username,
      password,
    };

    api
      .post(`/Autentificacion`, qs.stringify(requestBody), getHeaders(false, false))
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem('foco_token', res.data.access_token);
          localStorage.setItem('foco_username', username);
          dispatch(setSession({ username }, res.data.access_token));
          resolve(res.data);
        } else {
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(error());
        reject(err);
      });
  });

export const loginWithQs = (TOKEN_CONPAX) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: AUTH_LOGIN });
    const requestBody = {
      TOKEN_CONPAX,
    };
    api
      .post(`/api/CostoApiConpax/GetTokenEmail`, requestBody, getHeaders(true, false))
      .then((res) => {
        if (res.status === 200 && get(res, 'data.TOKEN_FOCO.length')) {
          message.success('Token validado correctamente');
          message.loading('Validando usuario en conpax');
          axios
            .get('https://cas.conpax.cl/api/v1/accesos?token=Foco.2k21')
            .then((response) => {
              if (response.status === 201) {
                const { TOKEN_FOCO, USUARIO, EMAIL } = res.data;
                const isConpaxUser = response.data.some((e) => e.nom_usuario === EMAIL);
                if (isConpaxUser) {
                  localStorage.setItem('foco_token', TOKEN_FOCO);
                  localStorage.setItem('foco_username', USUARIO);
                  dispatch(setSession({ username: USUARIO }, TOKEN_FOCO));
                  resolve();
                } else {
                  message.error('Este usuario no existe en conpax');
                  reject();
                  dispatch(error());
                }
              }
            })
            .catch(() => {
              message.error('Error al validar el usuario con la api de conpax');
            });
        } else {
          message.error('Error al validar el token de usuario');
          reject();
          dispatch(error());
        }
      })
      .catch((err) => {
        message.error('Error al validar el token de usuario');
        dispatch(error());
        reject(err);
      });
  });
