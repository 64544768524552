import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  Table,
  TimePicker,
} from 'antd';
import moment from 'moment';
import DoubleLineCell from '../../../components/DoubleLineCell';
import { getMinutesFromTwoHours, normalizeString, normalizeStringToApi } from '../../../utils';
import {
  setSchedule as setScheduleAction,
  updateSchedule as updateScheduleAction,
} from '../../../actions/schedule';

import './index.scss';

const timeFormatted = (time) => {
  const newTime = time.split(':');
  return `${newTime[0]}:${newTime[1]}`;
};

const days = [
  { name: 'Lunes', value: 1 },
  { name: 'Martes', value: 2 },
  { name: 'Miércoles', value: 3 },
  { name: 'Jueves', value: 4 },
  { name: 'Viernes', value: 5 },
  { name: 'Sábado', value: 6 },
  { name: 'Domingo', value: 0 },
];
const daysIndex = new Map(days.map((e, i) => [e.name, i]));

const EditableCell = ({ children, defaultValue, editable, inputType, onChange, selectOptions }) => {
  let inputNode = <span />;
  if (inputType === 'inputNumber') {
    inputNode = (
      <InputNumber value={defaultValue} onChange={(e) => Number.isInteger(e) && onChange(e)} />
    );
  } else if (inputType === 'timePicker') {
    inputNode = (
      <TimePicker
        value={moment(defaultValue, 'HH:mm')}
        format="HH:mm"
        onChange={(e) => onChange(moment(e).format('HH:mm'))}
        showNow={false}
        allowClear={false}
      />
    );
  } else if (inputType === 'select') {
    inputNode = (
      <Select onChange={(e) => onChange(e)} value={defaultValue}>
        {selectOptions.map((opt) => (
          <Select.Option value={opt.value} key={`collationType${opt.value}`}>
            {opt.name}
          </Select.Option>
        ))}
      </Select>
    );
  }

  return (
    <>
      {editable && <td className={`editableInput `}>{inputNode}</td>}
      {!editable && <td className="notEditableInput">{children}</td>}
    </>
  );
};

EditableCell.defaultProps = {
  defaultValue: null,
  editable: false,
  inputType: '',
  onChange: () => {},
  selectOptions: [],
};

EditableCell.propTypes = {
  children: PropTypes.array.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  editable: PropTypes.bool,
  inputType: PropTypes.string,
  onChange: PropTypes.func,
  selectOptions: PropTypes.array,
};

const NewWorkingDay = ({
  calendars,
  collationsType,
  infoToEdit,
  onClose,
  schedule,
  setSchedule,
  selectedWork,
  title,
  updateSchedule,
}) => {
  const [dataTable, setDataTable] = useState([]);
  const [daysToEdit, setDaysToEdit] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [form] = Form.useForm();
  const daysToEditSet = new Set(daysToEdit);

  const defaultInformation = {
    DURACION_COLACION: '0',
    ENTRADA_JORNADA: '08:00',
    ID_TIPO_COLACION: 3,
    INICIO_COLACION: '17:50',
    MARGEN_ENTRADA: '10',
    MARGEN_SALIDA: '10',
    SALIDA_JORNADA: '18:00',
    TERMINO_COLACION: '18:00',
  };

  useEffect(() => {
    if (infoToEdit.ID_JORNADA) {
      const initialState = [];
      const newDaysToEdit = [];

      const workingDayMap = new Map(infoToEdit.JORNADA_DIA.map((e) => [e.DIA_JORNADA, e]));

      days.forEach((day) => {
        const { name, value } = day;
        if (workingDayMap.has(value)) {
          newDaysToEdit.push(name);

          const {
            DURACION_COLACION,
            ENTRADA_JORNADA,
            ID_TIPO_COLACION,
            INICIO_COLACION,
            MARGEN_ENTRADA,
            MARGEN_SALIDA,
            SALIDA_JORNADA,
            TERMINO_COLACION,
          } = workingDayMap.get(value);

          initialState.push({
            DIA: name,
            DURACION_COLACION,
            ENTRADA_JORNADA: timeFormatted(ENTRADA_JORNADA),
            ID_TIPO_COLACION,
            INICIO_COLACION: timeFormatted(INICIO_COLACION),
            MARGEN_ENTRADA,
            MARGEN_SALIDA,
            SALIDA_JORNADA: timeFormatted(SALIDA_JORNADA),
            TERMINO_COLACION: timeFormatted(TERMINO_COLACION),
          });
        } else {
          initialState.push({
            ...defaultInformation,
            DIA: name,
          });
        }
      });

      setDataTable(initialState);
      setDaysToEdit(newDaysToEdit);
    } else {
      setDataTable(days.map((day) => ({ ...defaultInformation, DIA: day.name })));
    }
  }, []);

  const handleClickInCheckbox = (day) => {
    const index = daysToEdit.findIndex((e) => e === day);

    setDaysToEdit(
      update(daysToEdit, {
        ...(index !== -1 ? { $splice: [[index, 1]] } : { $push: [day] }),
      })
    );
  };

  const handleEditInfo = (day, key, value) => {
    let valueToSave = value;
    let newDuration = null;

    const setDefaultValueInCollations = key === 'ID_TIPO_COLACION' && value !== 1;
    const setDefaultValueInDuration = key === 'ID_TIPO_COLACION' && value !== 2;

    const showErrorInStartSchedule =
      key === 'ENTRADA_JORNADA' && value >= dataTable[daysIndex.get(day)].SALIDA_JORNADA;
    const showErrorInEndSchedule =
      key === 'SALIDA_JORNADA' && value <= dataTable[daysIndex.get(day)].ENTRADA_JORNADA;
    const showErrorInStartMargin = key === 'MARGEN_ENTRADA' && value < 0;
    const showErrorInEndMargin = key === 'MARGEN_SALIDA' && value < 0;
    const showErrorInStartCollation =
      key === 'INICIO_COLACION' && value >= dataTable[daysIndex.get(day)].TERMINO_COLACION;
    const showErrorInEndCollation =
      key === 'TERMINO_COLACION' && value <= dataTable[daysIndex.get(day)].INICIO_COLACION;
    const showErrorInDuration = key === 'DURACION_COLACION' && value < 0;
    const showErrorStartCollationGreaterStartSchedule =
      key === 'INICIO_COLACION' && value < dataTable[daysIndex.get(day)].ENTRADA_JORNADA;
    const showErrorStartCollationLessEndSchedule =
      key === 'INICIO_COLACION' && value > dataTable[daysIndex.get(day)].SALIDA_JORNADA;
    const showErrorEndCollationGreaterStartSchedule =
      key === 'TERMINO_COLACION' && value < dataTable[daysIndex.get(day)].ENTRADA_JORNADA;
    const showErrorEndCollationLessEndSchedule =
      key === 'TERMINO_COLACION' && value > dataTable[daysIndex.get(day)].SALIDA_JORNADA;

    if (showErrorInStartSchedule) {
      message.error('La hora de entrada debe ser menor a la hora de salida', 4);
      valueToSave = dataTable[daysIndex.get(day)].ENTRADA_JORNADA;
    } else if (showErrorInEndSchedule) {
      message.error('La hora de salida debe ser mayor a la hora de entrada', 4);
      valueToSave = dataTable[daysIndex.get(day)].SALIDA_JORNADA;
    } else if (showErrorInStartMargin) {
      message.error('El margen de entrada debe ser un valor positivo', 4);
      valueToSave = dataTable[daysIndex.get(day)].MARGEN_ENTRADA;
    } else if (showErrorInEndMargin) {
      message.error('El margen de salida debe ser un valor positivo', 4);
      valueToSave = dataTable[daysIndex.get(day)].MARGEN_SALIDA;
    } else if (showErrorInStartCollation) {
      message.error(
        'La hora de inicio de colación debe ser menor a la hora de término de colación',
        4
      );
      valueToSave = dataTable[daysIndex.get(day)].INICIO_COLACION;
    } else if (showErrorInEndCollation) {
      message.error(
        'La hora de término de colación debe ser mayor a la hora de inicio de colación',
        4
      );
      valueToSave = dataTable[daysIndex.get(day)].TERMINO_COLACION;
    } else if (showErrorInDuration) {
      message.error('La duración debe ser un valor positivo', 4);
      valueToSave = dataTable[daysIndex.get(day)].DURACION_COLACION;
    } else if (showErrorStartCollationGreaterStartSchedule) {
      message.error('La hora de inicio de colación debe ser mayor a la hora de entrada', 4);
      valueToSave = dataTable[daysIndex.get(day)].INICIO_COLACION;
    } else if (showErrorStartCollationLessEndSchedule) {
      message.error('La hora de inicio de colación debe ser menor a la hora de salida', 4);
      valueToSave = dataTable[daysIndex.get(day)].INICIO_COLACION;
    } else if (showErrorEndCollationGreaterStartSchedule) {
      message.error('La hora de término de colación debe ser mayor a la hora de entrada', 4);
      valueToSave = dataTable[daysIndex.get(day)].TERMINO_COLACION;
    } else if (showErrorEndCollationLessEndSchedule) {
      message.error('La hora de término de colación debe ser menor a la hora de salida', 4);
      valueToSave = dataTable[daysIndex.get(day)].TERMINO_COLACION;
    } else if (
      key === 'INICIO_COLACION' &&
      dataTable[daysIndex.get(day)].TERMINO_COLACION !== '00:00'
    ) {
      newDuration = getMinutesFromTwoHours(dataTable[daysIndex.get(day)].TERMINO_COLACION, value);
    } else if (
      key === 'TERMINO_COLACION' &&
      dataTable[daysIndex.get(day)].INICIO_COLACION !== '00:00'
    ) {
      newDuration = getMinutesFromTwoHours(value, dataTable[daysIndex.get(day)].INICIO_COLACION);
    }

    setDataTable(
      update(dataTable, {
        [daysIndex.get(day)]: {
          [key]: { $set: valueToSave },
          ...(setDefaultValueInCollations
            ? {
                INICIO_COLACION: { $set: defaultInformation.INICIO_COLACION },
                TERMINO_COLACION: { $set: defaultInformation.TERMINO_COLACION },
              }
            : {}),
          ...(setDefaultValueInDuration
            ? { DURACION_COLACION: { $set: defaultInformation.DURACION_COLACION } }
            : {}),
          ...(newDuration ? { DURACION_COLACION: { $set: newDuration } } : {}),
        },
      })
    );
  };

  const submit = (values) => {
    let error = '';
    const { calendar, workingDayName } = values;
    const JORNADA_DIA = [];
    const daysMap = new Map(days.map((e) => [e.name, e.value]));

    for (let i = 0; i < dataTable.length && !error; i++) {
      const {
        DIA,
        DURACION_COLACION,
        ENTRADA_JORNADA,
        ID_TIPO_COLACION,
        INICIO_COLACION,
        MARGEN_ENTRADA,
        MARGEN_SALIDA,
        SALIDA_JORNADA,
        TERMINO_COLACION,
      } = dataTable[i];

      if (daysToEditSet.has(DIA)) {
        if (ID_TIPO_COLACION === 1) {
          if (INICIO_COLACION < ENTRADA_JORNADA) {
            error = 'La hora de inicio de colación debe ser mayor a la hora de entrada';
          } else if (INICIO_COLACION > SALIDA_JORNADA) {
            error = 'La hora de inicio de colación debe ser menor a la hora de salida';
          } else if (TERMINO_COLACION < ENTRADA_JORNADA) {
            error = 'La hora de término de colación debe ser mayor a la hora de entrada';
          } else if (TERMINO_COLACION > SALIDA_JORNADA) {
            error = 'La hora de término de colación debe ser menor a la hora de salida';
          }
        } else if (ID_TIPO_COLACION === 2) {
          if (DURACION_COLACION === '0') {
            error = 'La duración de la colación no puede ser 0';
          }
        }

        if (!error) {
          JORNADA_DIA.push({
            DIA_JORNADA: daysMap.get(DIA),
            DURACION_COLACION: Number(DURACION_COLACION),
            ENTRADA_JORNADA: `${timeFormatted(ENTRADA_JORNADA)}:00`,
            ID_JORNADA: infoToEdit.ID_JORNADA || 0,
            ID_JORNADA_DIA: 0,
            ID_TIPO_COLACION,
            INICIO_COLACION: `${timeFormatted(INICIO_COLACION)}:00`,
            MARGEN_ENTRADA: Number(MARGEN_ENTRADA),
            MARGEN_SALIDA: Number(MARGEN_SALIDA),
            NOMBRE_TIPO_COLACION: '',
            SALIDA_JORNADA: `${timeFormatted(SALIDA_JORNADA)}:00`,
            TERMINO_COLACION: `${timeFormatted(TERMINO_COLACION)}:00`,
          });
        }
      }
    }

    if (!error) {
      setIsSaving(true);

      const data = {
        ID_CALENDARIO: calendar,
        ID_JORNADA: infoToEdit.ID_JORNADA || 0,
        ID_OBR: selectedWork,
        JORNADA_DIA,
        NOMBRE_CALENDARIO: calendars.find((e) => e.ID_CALENDARIO === calendar).NOMBRE_CALENDARIO,
        NOMBRE_JORNADA: normalizeStringToApi(workingDayName),
        TRABAJADOS: 0,
      };

      if (infoToEdit.ID_JORNADA) {
        updateSchedule(data)
          .then(() => {
            onClose();
            setIsSaving(false);
          })
          .catch(() => {
            setIsSaving(false);
          });
      } else {
        setSchedule(data)
          .then(() => {
            onClose();
            setIsSaving(false);
          })
          .catch(() => {
            setIsSaving(false);
          });
      }
    } else {
      message.error(error, 4);
    }
  };

  const newCollationsType = collationsType.map((e) => ({
    name: `${e.NOMBRE_TIPO_COLACION.charAt(0)}${e.NOMBRE_TIPO_COLACION.slice(1).toLowerCase()}`,
    value: e.ID_TIPO_COLACION,
  }));

  const columns = [
    {
      title: 'Día',
      render: (_, record) => {
        return (
          <div className="dayContainer">
            <Checkbox
              onChange={(e) => handleClickInCheckbox(e.target.value)}
              value={record.DIA}
              checked={daysToEditSet.has(record.DIA)}
            >
              {record.DIA}
            </Checkbox>
          </div>
        );
      },
    },
    {
      title: <DoubleLineCell firstValue="Entrada" secondValue="(HH/MM)" />,
      dataIndex: 'ENTRADA_JORNADA',
      onCell: (record) => {
        return {
          defaultValue: record.ENTRADA_JORNADA,
          editable: daysToEditSet.has(record.DIA),
          inputType: 'timePicker',
          onChange: (value) => handleEditInfo(record.DIA, 'ENTRADA_JORNADA', value),
        };
      },
    },
    {
      title: <DoubleLineCell firstValue="Salida" secondValue="(HH/MM)" />,
      dataIndex: 'SALIDA_JORNADA',
      onCell: (record) => {
        return {
          defaultValue: record.SALIDA_JORNADA,
          editable: daysToEditSet.has(record.DIA),
          inputType: 'timePicker',
          onChange: (value) => handleEditInfo(record.DIA, 'SALIDA_JORNADA', value),
        };
      },
    },
    {
      title: <DoubleLineCell firstValue="Margen Entrada" secondValue="(minutos)" />,
      dataIndex: 'MARGEN_ENTRADA',
      onCell: (record) => {
        return {
          defaultValue: record.MARGEN_ENTRADA,
          editable: daysToEditSet.has(record.DIA),
          inputType: 'inputNumber',
          onChange: (value) => handleEditInfo(record.DIA, 'MARGEN_ENTRADA', value),
        };
      },
    },
    {
      title: <DoubleLineCell firstValue="Margen Salida" secondValue="(minutos)" />,
      dataIndex: 'MARGEN_SALIDA',
      onCell: (record) => {
        return {
          defaultValue: record.MARGEN_SALIDA,
          editable: daysToEditSet.has(record.DIA),
          inputType: 'inputNumber',
          onChange: (value) => handleEditInfo(record.DIA, 'MARGEN_SALIDA', value),
        };
      },
    },
    {
      title: <DoubleLineCell firstValue="Tipo" secondValue="Colación" />,
      dataIndex: 'ID_TIPO_COLACION',
      render: (_, record) =>
        newCollationsType.find((e) => e.value === record.ID_TIPO_COLACION).name,
      onCell: (record) => {
        return {
          defaultValue: record.ID_TIPO_COLACION,
          editable: daysToEditSet.has(record.DIA),
          inputType: 'select',
          onChange: (value) => handleEditInfo(record.DIA, 'ID_TIPO_COLACION', value),
          selectOptions: newCollationsType,
        };
      },
    },
    {
      title: <DoubleLineCell firstValue="Duración" secondValue="Colación" />,
      dataIndex: 'DURACION_COLACION',
      onCell: (record) => {
        return {
          defaultValue: record.DURACION_COLACION,
          editable:
            daysToEditSet.has(record.DIA) &&
            dataTable[daysIndex.get(record.DIA)].ID_TIPO_COLACION === 2,
          inputType: 'inputNumber',
          onChange: (value) => handleEditInfo(record.DIA, 'DURACION_COLACION', value),
          selectOptions: newCollationsType,
        };
      },
    },
    {
      title: <DoubleLineCell firstValue="Inicio Colación" secondValue="(HH/MM)" />,
      dataIndex: 'INICIO_COLACION',
      onCell: (record) => {
        return {
          defaultValue: record.INICIO_COLACION,
          editable:
            daysToEditSet.has(record.DIA) &&
            dataTable[daysIndex.get(record.DIA)].ID_TIPO_COLACION === 1,
          inputType: 'timePicker',
          onChange: (value) => handleEditInfo(record.DIA, 'INICIO_COLACION', value),
          selectOptions: newCollationsType,
        };
      },
    },
    {
      title: <DoubleLineCell firstValue="Término Colación" secondValue="(HH/MM)" />,
      dataIndex: 'TERMINO_COLACION',
      onCell: (record) => {
        return {
          defaultValue: dataTable[daysIndex.get(record.DIA)].TERMINO_COLACION,
          editable:
            daysToEditSet.has(record.DIA) &&
            dataTable[daysIndex.get(record.DIA)].ID_TIPO_COLACION === 1,
          inputType: 'timePicker',
          onChange: (value) => handleEditInfo(record.DIA, 'TERMINO_COLACION', value),
          selectOptions: newCollationsType,
        };
      },
    },
  ];

  return (
    <Modal
      title={title}
      visible
      onCancel={() => onClose()}
      footer={null}
      width={1200}
      wrapClassName="newWorkingDay"
    >
      <Form layout="horizontal" form={form} onFinish={submit}>
        <div className="formContainer">
          <Form.Item
            name="workingDayName"
            label="Nombre Jornada"
            initialValue={infoToEdit.NOMBRE_JORNADA || ''}
            rules={[
              {
                required: true,
                message: 'Por favor ingrese un nombre de jornada',
              },
              {
                validator: async (obj, value) => {
                  if (
                    (!infoToEdit.NOMBRE_JORNADA || infoToEdit.NOMBRE_JORNADA !== value) &&
                    schedule.some(
                      (e) => normalizeString(e.NOMBRE_JORNADA) === normalizeString(value)
                    )
                  ) {
                    return Promise.reject(new Error('Ya existe una jornada con este nombre'));
                  }

                  if (value.length && value.trim().length === 0) {
                    return Promise.reject(new Error('No se permiten solo espacios vacios'));
                  }

                  if (!/^[a-zA-Z\u00C0-\u024F0-9 ]*$/.test(value)) {
                    return Promise.reject(new Error('No se permiten caracteres especiales'));
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input maxLength={200} />
          </Form.Item>

          <Form.Item
            name="calendar"
            label="Calendario a utilizar"
            rules={[
              {
                required: true,
                message: 'Por favor seleccione un calendario',
              },
            ]}
            initialValue={infoToEdit.ID_CALENDARIO || ''}
          >
            <Select placeholder="Seleccionar...">
              {calendars.map((opt) => (
                <Select.Option
                  value={opt.ID_CALENDARIO}
                  key={`optionInCalendar${opt.ID_CALENDARIO}`}
                >
                  {opt.NOMBRE_CALENDARIO}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={dataTable}
          columns={columns}
          rowClassName="editable-row"
          pagination={false}
        />
        <div className="buttons">
          <Form.Item>
            <Button disabled={isSaving} onClick={() => onClose()}>
              Cancelar
            </Button>
          </Form.Item>
          <Form.Item>
            <Button disabled={isSaving} loading={isSaving} type="primary" htmlType="submit">
              Aceptar
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

NewWorkingDay.defaultProps = {
  infoToEdit: {},
};

NewWorkingDay.propTypes = {
  calendars: PropTypes.arrayOf(PropTypes.object).isRequired,
  collationsType: PropTypes.arrayOf(PropTypes.object).isRequired,
  infoToEdit: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  schedule: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedWork: PropTypes.number.isRequired,
  setSchedule: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  updateSchedule: PropTypes.func.isRequired,
};

export default connect(
  (store) => ({
    calendars: store.calendars.data,
    collationsType: store.schedule.collationsType,
    schedule: store.schedule.data,
    selectedWork: store.works.selectedWork,
  }),
  (dispatch) => ({
    setSchedule: bindActionCreators(setScheduleAction, dispatch),
    updateSchedule: bindActionCreators(updateScheduleAction, dispatch),
  })
)(NewWorkingDay);
