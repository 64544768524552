import genericReducer from '../../utils';

export const CONTRACT_TYPE_FETCHED = 'CONTRACT_TYPE_FETCHED';
export const GET_CONTRACT_TYPE = 'GET_CONTRACT_TYPE';
export const GET_CONTRACT_TYPE_ERROR = 'GET_CONTRACT_TYPE_ERROR';

const contractType = (contractTypeInfo, action) =>
  genericReducer(contractTypeInfo, action, 'CONTRACT_TYPE', 'contractType');

export default contractType;
