import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import ListFilter from '../../../components/ListFilter';
import TreeFilter from '../../../components/TreeFilter';
import SelectWithTopLabel from '../../../components/SelectWithTopLabel';
import {
  getChaptersTree,
  getEnclosureTree,
  getSelectedChapters,
  getSelectedEnClosure,
} from '../utils';
import './index.scss';

const Header = ({
  advanceDate,
  advanceDates,
  enclosureInTreeToShow,
  enclosuresTree,
  enclosuresTypes,
  enclosureTypesToShow,
  items,
  itemsToShow,
  itemsTree,
  itemsTreeToShow,
  loading,
  setAdvanceDate,
  setEnclosureInTreeToShow,
  setEnclosureTypesToShow,
  setItemsToShow,
  setItemsTreeToShow,
  setLoadAdvance,
}) => {
  const [active, setActive] = useState(null);

  const tree = getEnclosureTree(enclosuresTree);
  const chaptersTree = getChaptersTree(itemsTree);

  return (
    <div className="advanceMatrixHeader">
      <div className="filters">
        <div className="marginRight">
          <SelectWithTopLabel
            className="marginRight"
            label="Avance al"
            onChange={setAdvanceDate}
            options={advanceDates.map((e) => ({ name: e.FECHA_AVA, value: e.ID_AVA }))}
            value={advanceDate}
            width={120}
            keepShowingLabel
            disabled={loading}
          />
        </div>

        <div className="marginRight">
          <TreeFilter
            buttonTitle="Capítulo"
            defaultExpandItemValues={[chaptersTree[0].ID_INS]}
            defaultValue={itemsTreeToShow}
            onClick={() => setActive('chapters')}
            onClose={() => setActive(null)}
            show={active === 'chapters'}
            title="Filtro Capítulo"
            tree={chaptersTree}
            onSave={(selectedOptions) => {
              setActive(null);
              setItemsTreeToShow(getSelectedChapters(itemsTree, selectedOptions));
            }}
            classNameToPrimaryButton={`orangeButton ${loading ? 'disabledButton' : ''}`}
            classNameToFirstButton={itemsTreeToShow.length === 0 ? 'withoutFilter' : ''}
          />
        </div>

        <div className="marginRight">
          <ListFilter
            allowSelectAll
            title="Filtro Partidas"
            subtitle="Lista de Partidas"
            buttonTitle="Partida"
            elementKey="ID_INS"
            elementTitleKey="NOMBRE_INS"
            onSave={(selectedOptions) => {
              setActive(null);
              setItemsToShow(selectedOptions);
            }}
            options={items}
            onClick={() => setActive('item')}
            onClose={() => setActive(null)}
            show={active === 'item'}
            classNameToPrimaryButton={`orangeButton ${loading ? 'disabledButton' : ''}`}
            classNameToFirstButton={itemsToShow.length === 0 ? 'withoutFilter' : ''}
          />
        </div>

        <div className="marginRight">
          <ListFilter
            allowSelectAll
            title="Filtro Tipos de Recinto"
            subtitle="Lista de Tipos de Recinto"
            buttonTitle="Tipos de Recinto"
            elementKey="ID_TUC"
            elementTitleKey="NOMBRE_TUC"
            onSave={(selectedOptions) => {
              setActive(null);
              setEnclosureTypesToShow(selectedOptions);
            }}
            options={enclosuresTypes}
            onClick={() => setActive('enclosureTypes')}
            onClose={() => setActive(null)}
            show={active === 'enclosureTypes'}
            classNameToPrimaryButton={`orangeButton ${loading ? 'disabledButton' : ''}`}
            classNameToFirstButton={enclosureTypesToShow.length === 0 ? 'withoutFilter' : ''}
          />
        </div>

        <div className="marginRight">
          <TreeFilter
            buttonTitle="Recinto"
            defaultExpandItemValues={[tree[0].ID_UCO]}
            defaultValue={enclosureInTreeToShow}
            onClick={() => setActive('enclosure')}
            onClose={() => setActive(null)}
            show={active === 'enclosure'}
            title="Filtro Recinto"
            tree={tree}
            onSave={(selectedOptions) => {
              setActive(null);
              setEnclosureInTreeToShow(getSelectedEnClosure(enclosuresTree, selectedOptions));
            }}
            classNameToPrimaryButton={`orangeButton ${loading ? 'disabledButton' : ''}`}
            classNameToFirstButton={enclosureInTreeToShow.length === 0 ? 'withoutFilter' : ''}
          />
        </div>
      </div>
      <div className="buttons">
        <Button
          className={`orangeButton ${loading ? 'disabledButton' : ''}`}
          onClick={() => {
            if (advanceDate) {
              setLoadAdvance(true);
            }
          }}
          type="default"
        >
          Cargar Matriz de Avance
        </Button>
      </div>
    </div>
  );
};

Header.propTypes = {
  advanceDate: PropTypes.string.isRequired,
  advanceDates: PropTypes.arrayOf(PropTypes.object).isRequired,
  enclosureInTreeToShow: PropTypes.arrayOf(PropTypes.number).isRequired,
  enclosuresTree: PropTypes.arrayOf(PropTypes.object).isRequired,
  enclosuresTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  enclosureTypesToShow: PropTypes.arrayOf(PropTypes.number).isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  itemsToShow: PropTypes.arrayOf(PropTypes.number).isRequired,
  itemsTree: PropTypes.arrayOf(PropTypes.object).isRequired,
  itemsTreeToShow: PropTypes.arrayOf(PropTypes.number).isRequired,
  loading: PropTypes.bool.isRequired,
  setAdvanceDate: PropTypes.func.isRequired,
  setEnclosureInTreeToShow: PropTypes.func.isRequired,
  setEnclosureTypesToShow: PropTypes.func.isRequired,
  setItemsToShow: PropTypes.func.isRequired,
  setItemsTreeToShow: PropTypes.func.isRequired,
  setLoadAdvance: PropTypes.func.isRequired,
};

export default Header;
