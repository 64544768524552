/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { message, Select } from 'antd';
import { Link, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import { login as loginAction } from '../../actions/auth';
import {
  setSelectedWork as setSelectedWorkAction,
  getWorks as getWorksAction,
} from '../../actions/works';
import { getMenu as getMenuAction } from '../../actions/menu';
import { getCommunes as getCommunesAction } from '../../actions/communes';
import routes from './routes';
import SelectWithTopLabel from '../../components/SelectWithTopLabel';

import './index.scss';
import 'rsuite-table/dist/css/rsuite-table.css';
import 'rsuite/dist/styles/rsuite-default.css';

const getHeadTitle = (history, menu) => {
  const currentLocationName = history.location.pathname.replace('/home', '');
  if (currentLocationName === '/welcome') return 'Bienvenido';

  const menuElement =
    (menu.find((e) => currentLocationName === e.URL_MENU) || {}).NOMBRE_MENU || '';

  return menuElement;
};

const Home = ({
  history,
  menu,
  setSelectedWork,
  works,
  communes,
  getMenu,
  getWorks,
  getCommunes,
}) => {
  const [menuTree, setMenuTree] = useState([]);
  const [showLogoutMenu, toggleLogoutMenu] = useState(false);
  const [menuRoutesRoles, setMenuRoutesRoles] = useState({});
  const [menuRoutesAccess, setMenuRoutesAccess] = useState({});
  const { t, i18n } = useTranslation();
  const toggleButton = useRef(null);
  const isFetching = menu.isFetching || works.isFetching || communes.isFetching;
  const dataEmpty = !menu.data || !works.data || !communes.data;
  useEffect(() => {
    const client = localStorage.getItem('foco_client');
    if (!menu.data && !works.data && !communes.data && client) {
      getMenu(client);
      getWorks(client);
      getCommunes(client);
    }
  }, []);

  const getMenuTree = (items, id = '0') =>
    items
      .filter((item) => item.ID_PADRE === id)
      .map((item) => ({ ...item, children: getMenuTree(items, item.ID_MENU) }));

  useEffect(() => {
    if (menu.data) {
      setMenuTree(getMenuTree(menu.data));
      const routesRoles = {};
      const routesAccess = {};

      menu.data.forEach((e) => {
        if (e.ID_PADRE !== 0) {
          routesRoles[e.URL_MENU] = e.TIPO_ACCESO;
          routesAccess[e.URL_MENU] = e.ACCESOS;
        }
      });
      setMenuRoutesRoles(routesRoles);
      setMenuRoutesAccess(routesAccess);
    }

    if (menu.data && works.data && communes.data) {
      setTimeout(() => {
        if (toggleButton && toggleButton.current) {
          toggleButton.current.click();
        }
        if (document.getElementById('nav')) {
          document.getElementById('nav').className += ' px-nav-collapse';
        }
      }, 50);
    }
  }, [menu, works, communes]);

  if (isFetching || dataEmpty) {
    return (
      <div className="spinner-wrapper">
        <div className="spinner-border" role="status" />
      </div>
    );
  }

  const getMenuUi = (menuList, firstLevel = true) => {
    if (!menuList.length) return null;
    return (
      <ul className={firstLevel ? 'px-nav-content' : 'px-nav-dropdown-menu animated fadeInLeft'}>
        {menuList.map(({ children: menuChildren, NOMBRE_MENU, CSS_ICON, URL_MENU }) => {
          let content = (
            <>
              <i className={`fa-w-14 fa ${CSS_ICON} ${!firstLevel ? 'sandybrown' : ''}`} />
              <span className="px-nav-label">{NOMBRE_MENU}</span>
            </>
          );

          if (URL_MENU !== '#') {
            content = <Link to={`/home${URL_MENU}`}>{content}</Link>;
          } else {
            content = <a href="#">{content}</a>;
          }
          return (
            <li className={`px-nav-item ${menuChildren.length ? 'px-nav-dropdown' : ''}`}>
              {content}
              {menuChildren.length ? getMenuUi(menuChildren, false) : null}
            </li>
          );
        })}
      </ul>
    );
  };
  return (
    <div className="home">
      <nav className="px-nav px-nav-left px-nav-animate px-nav-collapse" id="nav">
        <button
          ref={toggleButton}
          type="button"
          className="px-nav-toggle"
          data-toggle="px-nav"
          style={{ pointerEvents: 'none' }}
        >
          <span className="px-nav-toggle-arrow" />
          <span className="navbar-toggle-icon" />
          <span className="px-nav-toggle-label font-size-11">HIDE MENU</span>
        </button>
        {getMenuUi(menuTree)}
      </nav>
      <nav className="navbar px-navbar">
        <ul className="nav navbar-nav right-navbar-nav">
          <div>
            <div className="select-container">
              {!!routes.find(
                ({ path, showWorksSelect }) => path === history.location.pathname && showWorksSelect
              ) &&
                works.data && (
                  <>
                    <span>{t('common.project')}</span>
                    <Select
                      onChange={(value) => setSelectedWork(Number(value))}
                      defaultValue={works.selectedWork ? String(works.selectedWork) : undefined}
                      disabled={!works.data || !works.data.length}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {works.data.map((work) => (
                        <Select.Option value={work.ID_OBR} key={`work${work.ID_OBR}`}>
                          {work.NOMABR_OBR}
                        </Select.Option>
                      ))}
                    </Select>
                  </>
                )}
            </div>
            <div className="left-menu">
              <h4>{getHeadTitle(history, menu.data)}</h4>
            </div>
            <div className="logout-menu">
              {!!routes.find(
                ({ path, showLanguageSelector }) =>
                  path === history.location.pathname && showLanguageSelector
              ) && (
                <SelectWithTopLabel
                  className="language-selector"
                  keepShowingLabel
                  value={i18n.language}
                  label="Idioma"
                  options={[
                    { name: 'English - EN', value: 'en' },
                    { name: 'Español - ES', value: 'es' },
                  ].map(({ value, name }) => ({
                    name,
                    value,
                  }))}
                  onChange={(value) => i18n.changeLanguage(value)}
                />
              )}
              <button type="button" onClick={() => toggleLogoutMenu(!showLogoutMenu)}>
                <i className="fa fa-sign-out fa-2x" />
              </button>
              {showLogoutMenu && (
                <div className="logout-menu-list">
                  <Link to="/logout">
                    <i className="fa fa-power-off fa-2x" /> <span>Salir de Foco</span>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </ul>
      </nav>
      <div className="px-content">
        {routes.map(({ path, Component }) => (
          <Route
            exact
            path={path}
            render={(props) => {
              // TODO improve this validation to duplicated routes like payment_state
              const access = get(
                menuRoutesAccess,
                [path.includes('/payment_state') ? '/payment_states' : path.replace('/home', '')],
                []
              ).reduce(
                (acc, { ACCESO, CODIGO_OPCION }) => ({ ...acc, [CODIGO_OPCION]: ACCESO }),
                {}
              );
              if (
                !isEmpty(access) &&
                !access.CONSULTAR &&
                history.location.pathname !== '/home/welcome'
              ) {
                message.warning(
                  `No tienes permiso para acceder a la ruta ${history.location.pathname}`
                );
                return <Redirect to="/home/welcome" />;
              }
              return (
                <Component
                  role={menuRoutesRoles[path.replace('/home', '')]}
                  {...props}
                  access={access}
                  t={t}
                />
              );
            }}
          />
        ))}
      </div>
    </div>
  );
};

Home.propTypes = {
  auth: PropTypes.shape({
    isLogged: PropTypes.bool,
    token: PropTypes.string,
    isFetching: PropTypes.bool,
    error: PropTypes.bool,
    user: PropTypes.shape({
      username: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
  menu: PropTypes.shape({
    data: PropTypes.array,
    isFetching: PropTypes.bool,
  }).isRequired,
  works: PropTypes.shape({
    data: PropTypes.array,
    selectedWork: PropTypes.number,
    isFetching: PropTypes.bool,
  }).isRequired,
  communes: PropTypes.shape({
    data: PropTypes.array,
    isFetching: PropTypes.bool,
  }).isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  setSelectedWork: PropTypes.func.isRequired,
  getMenu: PropTypes.func.isRequired,
  getWorks: PropTypes.func.isRequired,
  getCommunes: PropTypes.func.isRequired,
  access: PropTypes.string.isRequired,
};

Home.defaultProps = {
  children: null,
};

export default connect(
  (store) => ({
    menu: store.menu,
    auth: store.auth,
    works: store.works,
    communes: store.communes,
  }),
  (dispatch) => ({
    login: bindActionCreators(loginAction, dispatch),
    setSelectedWork: bindActionCreators(setSelectedWorkAction, dispatch),
    getWorks: bindActionCreators(getWorksAction, dispatch),
    getMenu: bindActionCreators(getMenuAction, dispatch),
    getCommunes: bindActionCreators(getCommunesAction, dispatch),
  })
)(Home);
