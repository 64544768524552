import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getClients as getClientsAction } from '../../actions/clients';
import { getMenu as getMenuAction } from '../../actions/menu';
import { getWorks as getWorksAction } from '../../actions/works';
import { getCommunes as getCommunesAction } from '../../actions/communes';

import logo from '../../images/login/logo-foco.png';

import './index.scss';

const ClientsList = ({
  clients,
  getClients,
  history,
  getMenu,
  works,
  getWorks,
  menu,
  communes,
  getCommunes,
}) => {
  const { isFetching: loadingClients, data: clientsData, error: clientsError } = clients;
  const { isFetching: loadingMenu, data: menuData, error: menuError } = menu;
  const { isFetching: loadingWorks, data: worksData, error: worksError } = works;
  const { isFetching: loadingCommunes, data: communesData, error: communesError } = communes;

  const isLoadingData = loadingClients || loadingMenu || loadingWorks || loadingCommunes;
  useEffect(() => {
    if (!clientsData) {
      getClients();
    }
  }, []);

  useEffect(() => {
    if (clientsData && clientsData.length === 1) {
      const currentClient = clientsData[0].NOMBRE_CLIENTE;
      getMenu(currentClient);
      getWorks(currentClient);
      getCommunes(currentClient);
    }
  }, [clientsData]);

  useEffect(() => {
    if (menuData && worksData && communesData && !isLoadingData) {
      history.push('/home/welcome');
    }
  }, [menuData, worksData, communesData, isLoadingData]);

  const getHomeData = (client) => {
    getMenu(client);
    getWorks(client);
    getCommunes(client);
  };

  const content = clientsError ? (
    <div className="error">
      Lo siento algo salio mal obteniendo la lista de clientes por favor recargue la pagina{' '}
    </div>
  ) : (
    <>
      {clientsData && clientsData.length > 1 && (
        <div className="content">
          <div className="box">
            <div>
              <img id="Image1" src={logo} alt="" />
              <h3> Suite FOCOENOBRA </h3>
              <span>Servicios profesionales para la construcción.</span>
            </div>
            <div>
              <div className="select-title">
                <span>Seleccione una empresa</span>
              </div>
              <ul>
                {clientsData.map(({ NOMBRE_CLIENTE }) => (
                  <li>
                    <button type="button" onClick={() => getHomeData(NOMBRE_CLIENTE)}>
                      {NOMBRE_CLIENTE}
                    </button>
                  </li>
                ))}
              </ul>
              <button className="cancel" type="button" onClick={() => history.push('/logout')}>
                Cancelar
              </button>
              {menuError ||
                worksError ||
                (communesError && (
                  <div className="error">
                    Lo siento algo salio mal obteniendo la informacion del cliente
                  </div>
                ))}
            </div>
          </div>
          <div className="bottom-text">
            <span>¿Aún no estás en Foco en Obra? </span>
            <a href="http://www.focoenobra.cl">Conócenos</a>
          </div>
        </div>
      )}
    </>
  );

  return (
    <div className={`clients ${isLoadingData || clientsError ? 'flex' : ''}`}>
      {isLoadingData ? <div className="spinner-border align" role="status" /> : content}
    </div>
  );
};

ClientsList.propTypes = {
  auth: PropTypes.shape({
    isLogged: PropTypes.bool,
    token: PropTypes.bool,
    isFetching: PropTypes.bool,
    error: PropTypes.bool,
  }).isRequired,
  clients: PropTypes.shape({
    isFetching: PropTypes.bool,
    data: PropTypes.array,
    error: PropTypes.bool,
  }).isRequired,
  communes: PropTypes.shape({
    isFetching: PropTypes.bool,
    data: PropTypes.array,
    error: PropTypes.bool,
  }).isRequired,
  menu: PropTypes.shape({
    isFetching: PropTypes.bool,
    data: PropTypes.array,
    error: PropTypes.bool,
  }).isRequired,
  works: PropTypes.shape({
    isFetching: PropTypes.bool,
    data: PropTypes.array,
    error: PropTypes.bool,
  }).isRequired,
  getClients: PropTypes.func.isRequired,
  getMenu: PropTypes.func.isRequired,
  getWorks: PropTypes.func.isRequired,
  getCommunes: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default connect(
  (store) => ({
    clients: store.clients,
    menu: store.menu,
    works: store.works,
    communes: store.communes,
    auth: store.auth,
  }),
  (dispatch) => ({
    getClients: bindActionCreators(getClientsAction, dispatch),
    getMenu: bindActionCreators(getMenuAction, dispatch),
    getWorks: bindActionCreators(getWorksAction, dispatch),
    getCommunes: bindActionCreators(getCommunesAction, dispatch),
  })
)(ClientsList);
