import React, { useState, useEffect } from 'react';
import update from 'immutability-helper';
import axios from 'axios';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Modal, Button, message, Radio } from 'antd';
import get from 'lodash/get';
import { setJobCost as setJobCostAction } from '../../../actions/jobCost';

import './index.scss';

const UpdateJobCost = ({ setJobCost, isFetching, erpCode, jobCostData }) => {
  const [state, setState] = useState({
    show: false,
    saving: false,
    value: jobCostData ? jobCostData.TIPO_DESCARGA : 3,
  });
  const { show, saving } = state;

  useEffect(() => {
    if (get(jobCostData, 'TIPO_DESCARGA')) {
      setState(
        update(state, {
          value: { $set: jobCostData.TIPO_DESCARGA },
        })
      );
    }
  }, [jobCostData]);

  useEffect(() => {
    if (!show && get(jobCostData, 'TIPO_DESCARGA')) {
      setState(
        update(state, {
          value: { $set: jobCostData.TIPO_DESCARGA },
        })
      );
    }
  }, [show]);

  const submit = () => {
    const token = 'Foco.2k21';
    setState(update(state, { saving: { $set: true } }));
    const { value } = state;
    let requestDate = '';

    let month = 0;
    let year = 0;

    if (value === 1) {
      const now = moment();
      month = now.format('MM');
      year = now.format('YY');
      requestDate = `/${now.format('YYYY')}/${month}`;
    } else if (value === 2) {
      const now = moment().subtract(2, 'months');
      month = now.format('MM');
      year = now.format('YY');

      requestDate = `/${now.format('YYYY')}/${month}`;
    }
    message.loading('Descargando costos');

    axios
      .get(`https://cas.conpax.cl/api/v1/costos/${erpCode}${requestDate}?token=${token}&reporte=4`)
      .then((res) => {
        message.loading('Transfiriendo costos');

        const requestBody = {
          GLMCU: erpCode,
          ANHO_DESCARGA: Number(year),
          MES_DESCARGA: Number(month),
          COSTO_API_CONPAX: res.data,
          TIPO_DESCARGA: value,
        };
        setJobCost(requestBody)
          .then(() => {
            message.success('Costos transferidos correctamente');
            setState(update(state, { saving: { $set: false }, show: { $set: false } }));
          })
          .catch(() => {
            message.error('Error al trasnferir los costos');
            setState(update(state, { saving: { $set: false } }));
          });
      })
      .catch(() => {
        message.error('Error al descargar los costos');
        setState(update(state, { saving: { $set: false } }));
      });
  };

  const onChange = (e) => {
    setState(update(state, { value: { $set: e.target.value } }));
  };

  return (
    <div className="update-job-cost">
      <Button
        onClick={() =>
          setState(
            update(state, {
              show: { $set: true },
            })
          )
        }
        disabled={isFetching}
        type="primary"
      >
        Actualizar Job Cost
      </Button>
      <Modal
        closable={false}
        wrapClassName="common-modal update-job-cost-modal"
        visible={show}
        width={500}
        onCancel={() => {
          setState(
            update(state, {
              show: { $set: false },
            })
          );
        }}
        footer={null}
        okText="Guardar"
      >
        <div className="modal-container">
          <div className="modal-header">
            <div />
            <span>Actualización de Job Cost</span>
            <i
              className="fa fa-times"
              onClick={() => {
                setState(
                  update(state, {
                    show: { $set: false },
                  })
                );
              }}
            />
          </div>
          <div className="sync-selector">
            <span>Modo de sincronización</span>
            <Radio.Group className="sync-options" onChange={onChange} value={state.value}>
              <Radio value={1}>Mes Actual</Radio>
              <Radio value={2}>Últimos 3 Meses</Radio>
              <Radio value={3}>Histórico de Costos</Radio>
            </Radio.Group>
          </div>
          <div className="modal-footer">
            <Button
              disabled={saving}
              onClick={() => {
                setState(
                  update(state, {
                    show: { $set: false },
                  })
                );
              }}
            >
              Cancelar
            </Button>
            <Button loading={saving} disabled={saving} type="primary" onClick={submit}>
              Actualizar
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

UpdateJobCost.propTypes = {
  setJobCost: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  erpCode: PropTypes.string.isRequired,
  jobCostData: PropTypes.object.isRequired,
};

export default connect(
  ({ contractCurrency, production, works }) => ({
    contractCurrency,
    production,
    selectedWork: works.selectedWork,
  }),
  (dispatch) => ({
    setJobCost: bindActionCreators(setJobCostAction, dispatch),
  })
)(UpdateJobCost);
