import _ from 'lodash';
import { message } from 'antd';
import api, { getHeaders } from '../utils/api';
import {
  GET_ITEMS_DATA,
  SET_ERROR_ENCLOSURE,
  GET_MANAGER,
  GET_CONFIGURACION,
  GET_LISTADO_PANEL,
  GET_OPTIONS,
  GET_PROJECT_USR,
} from '../reducers/panelcontrol';
import { SET_USER } from '../reducers/configuration';
import store from '../store';

export const setError = (error = '') => ({
  type: SET_ERROR_ENCLOSURE,
  payload: { error },
  result: { error },
});

export const getItemsData = (withDate, tdEstado, tdGerente, tdProyecto) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ESTADO_OBR: tdEstado,
      FECHA_CORTE: withDate,
      GERENTE: tdGerente,
      PROYECTO: tdProyecto,
    };
    const errorMessage = 'No se cargaron éxitosamente el cuadro de mando.';
    api
      .post(`/api/PanelControl/ListarCuadroDeMando`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: GET_ITEMS_DATA, payload: { itemsData: res.data } });
          resolve(res.data);
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });
export const getManager = () => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
    };
    const errorMessage = 'No se cargaron éxitosamente los gerentes.';
    api
      .post(`api/PanelControl/ListarGerentes`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: GET_MANAGER, payload: { manager: res.data } });
          resolve(res.data);
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });
export const getConfiguration = () => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_EMP: 1,
    };
    const errorMessage = 'No se cargaron éxitosamente la configuracion.';
    api
      .post(`api/PanelControl/Configuracion`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: GET_CONFIGURACION, payload: { configuracion: res.data } });
          resolve(res.data);
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });
export const getPanelList = () => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_EMP: 1,
    };
    const errorMessage = 'No se cargaron éxitosamente la configuracion.';
    api
      .post(`api/PanelControl/MiPanel`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: GET_LISTADO_PANEL, payload: { panel: res.data } });
          resolve(res.data);
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });
export const getProjectUsr = () => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      Vigente_Obr: 1,
    };
    const errorMessage = 'No se actualizo listado de proyectos.';
    api
      .post(`api/PanelControl/obrasPorUsuario`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: GET_PROJECT_USR, payload: { project: res.data } });
          resolve(res.data);
          console.log(res.data);
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });
export const setUser =
  (fullNameField, phoneField, mailField, projectField, idUser, countryField) => (dispatch) =>
    new Promise((resolve, reject) => {
      const requestBody = {
        NOMBRE_USU: _.get(store.getState(), 'auth.user.username', ''),
        EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
        NOMCOM_USU: fullNameField,
        CELULAR: phoneField,
        EMAIL_USU: mailField,
        ID_ObrPred: projectField,
        ID_USU: idUser,
        PAIS_CELULAR: countryField,
      };
      const errorMessage = 'No se actualizo éxitosamente.';
      api
        .post(`api/Login/UpdDatosPersonales`, requestBody, getHeaders())
        .then((res) => {
          if (res.status === 200) {
            dispatch({ type: SET_USER, payload: { request: res.data } });
            resolve(res.data);
            console.log(res.data);
          } else {
            dispatch(setError(errorMessage));
            message.error(errorMessage, 4);
            reject(res.data);
          }
        })
        .catch((err) => {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(err);
        });
    });
export const getOptions = (projectField) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR: projectField,
    };
    const errorMessage = 'No se cargaron éxitosamente el cuadro de mando.';
    api
      .post(`/api/Login/TraeMenu`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: GET_OPTIONS, payload: { optionsM: res.data } });
          resolve(res.data);
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });
