import React, { useEffect, useState } from 'react';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import { InputNumber } from 'antd';
import { FileOutlined } from '@ant-design/icons';
import { getFormattedNumber } from '../../../../utils';

import { getTotals } from '../columns';

import './index.scss';

let changeTimeOut = null;

const ResumeBox = ({ isEditing, tree, paymentStateInfo, onChangeValues, saving }) => {
  const [state, setState] = useState({ subtotal: 0, discount: 0, show: false });

  useEffect(() => {
    if (tree && tree[0]) {
      const prevTotal = getTotals(tree[0], 'CANT_EP_ANT', 'PRECIO_VENTA_EP_ANT');
      const currentTotal = getTotals(tree[0], 'CANT_EP');
      const subtotal = currentTotal - prevTotal;
      setState(update(state, { subtotal: { $set: subtotal } }));
    }
  }, [tree]);

  useEffect(() => {
    setState(
      update(state, {
        discount: { $set: paymentStateInfo.DESCUENTO_EP },
        readjustment: { $set: paymentStateInfo.REAJUSTE_EP },
      })
    );
  }, [paymentStateInfo, isEditing]);

  const { subtotal, discount, show, readjustment } = state;

  const onChange = (value, key = 'discount') => {
    if (value <= subtotal) {
      setState(update(state, { [key]: { $set: value } }));
      if (changeTimeOut) {
        clearTimeout(changeTimeOut);
      }

      changeTimeOut = setTimeout(() => {
        onChangeValues(value, key);
      }, 500);
    }
  };

  return (
    <>
      {show ? (
        <div className="resume-box">
          <div className="resume-title">
            <div />
            <span>Resumen EP</span>
            <i
              className="fa fa-times"
              onClick={() => {
                setState(
                  update(state, {
                    show: { $set: false },
                  })
                );
              }}
            />
          </div>
          <div className={`resume-content ${saving ? 'saving' : ''}`}>
            <>
              <div>
                <div>Subtotal</div>
                <div>{`$${getFormattedNumber(subtotal, null, true)}`}</div>
              </div>
              <div>
                <div>Descuento</div>
                <div className={!isEditing && 'discount'}>
                  {isEditing ? (
                    <InputNumber
                      min={0}
                      max={subtotal}
                      value={discount}
                      onChange={onChange}
                      formatter={(value) => `-$${value}`}
                      parser={(value) => value.replace('-', '').replace('$', '')}
                    />
                  ) : (
                    `-$${getFormattedNumber(paymentStateInfo.DESCUENTO_EP, null, true)}`
                  )}
                </div>
              </div>
              <div>
                <div>Monto Total Neto</div>
                <div>{getFormattedNumber(subtotal - discount, null, true, '$')}</div>
              </div>
              <div>
                <div>Reajuste</div>
                <div className={!isEditing && 'with-border'}>
                  {isEditing ? (
                    <InputNumber
                      min={0}
                      max={subtotal}
                      value={readjustment}
                      onChange={(e) => onChange(e, 'readjustment')}
                      formatter={(value) => `$${value}`}
                      parser={(value) => value.replace('$', '')}
                    />
                  ) : (
                    getFormattedNumber(paymentStateInfo.REAJUSTE_EP, null, true, '$')
                  )}
                </div>
              </div>
              <div>
                <div>Monto Total</div>
                <div>{getFormattedNumber(subtotal - discount + readjustment, null, true, '$')}</div>
              </div>
            </>
          </div>
        </div>
      ) : (
        <div className="open-button-container">
          <button type="button" onClick={() => setState(update(state, { show: { $set: true } }))}>
            <FileOutlined className="file-icon" />
          </button>
          <span>Resumen EP</span>
        </div>
      )}
    </>
  );
};

ResumeBox.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  tree: PropTypes.bool.isRequired,
  paymentStateInfo: PropTypes.object.isRequired,
  onChangeValues: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
};

export default ResumeBox;
