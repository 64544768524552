/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { Column, HeaderCell, Cell } from 'rsuite-table';
import { Modal } from 'antd';
import update from 'immutability-helper';

import ResourceSelector from '../ResourceSelector';
import Table from '../../../../components/TableExtend';
import EditableCell from '../EditableCell';

import './index.scss';

const { confirm } = Modal;

const ResourcesTable = ({
  inputsTypes,
  resources,
  units,
  unitsData,
  onUpdateResources,
  resourcesError,
  canAddSubItems,
}) => {
  const addResources = (data) => {
    let newResources = [...resources];
    data.forEach((newResource) => {
      const index = newResources.findIndex((e) => e.ID_ITEM === newResource.ID_ITEM);
      if (index === -1) {
        let NOMABR_UNI;

        if (unitsData) {
          ({ NOMABR_UNI } = unitsData.find((e) => newResource.ID_UNI === e.ID_UNI) || newResource);
        }

        newResources = update(newResources, { $push: [{ ...newResource, NOMABR_UNI }] });
      }
    });

    onUpdateResources(newResources);
  };

  const removeParameter = (id) => {
    const index = resources.findIndex((e) => e.ID_ITEM === id);
    onUpdateResources(
      update(resources, {
        $splice: [[index, 1]],
      })
    );
  };

  const showConfirm = (id, name, NOMBRE_TIN) => {
    confirm({
      title: `Esta a punto de eliminar ${
        NOMBRE_TIN === 'Subpartida' ? 'la Subpartida ' : 'el recurso '
      } "${name}"`,
      content: 'Confirma esta acción',
      onOk() {
        removeParameter(id);
      },
    });
  };

  const editCell = (rowData, dataKey, value) => {
    const index = resources.findIndex((e) => e.ID_ITEM === rowData.ID_ITEM);
    onUpdateResources(
      update(resources, {
        [index]: {
          [dataKey]: { $set: value },
        },
      })
    );
  };

  return (
    <div className="resource-add">
      <div className="resource-add-head">
        <span>{`Recursos ${canAddSubItems ? ' / Partidas' : ''}`}</span>

        <ResourceSelector
          canAddSubItems={canAddSubItems}
          units={units}
          inputsTypes={inputsTypes}
          addResources={addResources}
        />
      </div>
      <div className="new-item-resources-table">
        <Table
          rowKey="ID_ITEM"
          bordered
          rowHeight={28}
          virtualized
          locale={{
            emptyMessage: 'No hay elementos agregados',
          }}
          data={resources}
        >
          <Column verticalAlign="middle" fixed="left" align="left" width={80}>
            <HeaderCell>Código</HeaderCell>
            <Cell dataKey="CODIGO_ITEM" />
          </Column>
          <Column verticalAlign="middle" fixed="left" align="left" width={100}>
            <HeaderCell>Nombre</HeaderCell>
            <Cell dataKey="NOMBRE_ITEM" />
          </Column>
          <Column verticalAlign="middle" fixed="left" align="left" width={100}>
            <HeaderCell>Naturaleza</HeaderCell>
            <Cell dataKey="NOMBRE_TIN" />
          </Column>
          <Column verticalAlign="middle" align="left" width={55}>
            <HeaderCell>Ud.</HeaderCell>
            <Cell dataKey="NOMABR_UNI">{(record) => record.NOMABR_UNI}</Cell>
          </Column>
          <Column verticalAlign="middle" align="center" width={110}>
            <HeaderCell>Precio Unitario</HeaderCell>
            <Cell dataKey="PRECIO_ITEM">
              {({ PRECIO_ITEM }) => `$${new Intl.NumberFormat().format(PRECIO_ITEM)}`}
            </Cell>
          </Column>
          <Column verticalAlign="middle" align="center" width={120}>
            <HeaderCell>Factor</HeaderCell>
            <EditableCell onSave={editCell} dataKey="FACTOR_MOD" />
          </Column>
          <Column verticalAlign="middle" align="center" width={120}>
            <HeaderCell>Cantidad</HeaderCell>
            <EditableCell onSave={editCell} min={0} dataKey="CANTIDAD_MOD" />
          </Column>
          <Column verticalAlign="middle" align="center" width={140}>
            <HeaderCell>Total</HeaderCell>
            <Cell dataKey="TOTAL">
              {(record) => {
                const total = record.FACTOR_MOD * record.CANTIDAD_MOD * record.PRECIO_ITEM;
                return `$${new Intl.NumberFormat().format(total.toFixed())}`;
              }}
            </Cell>
          </Column>
          <Column
            verticalAlign="middle"
            align="center"
            width={30}
            fixed={resources?.length ? 'right' : ''}
          >
            <HeaderCell />
            <Cell dataKey="DELETE">
              {({ ID_ITEM, NOMBRE_ITEM, NOMBRE_TIN }) => {
                return (
                  <i
                    role="button"
                    tabIndex={-1}
                    onKeyDown={null}
                    className="fa fa-trash"
                    onClick={() => showConfirm(ID_ITEM, NOMBRE_ITEM, NOMBRE_TIN)}
                  />
                );
              }}
            </Cell>
          </Column>
        </Table>
        {resourcesError && (
          <div className="resources-error ant-form-item-explain ant-form-item-explain-error">
            <div role="alert">{`Por favor ingrese al menos un Recurso${
              canAddSubItems ? ' o Subpartida' : ''
            }`}</div>
          </div>
        )}
      </div>
    </div>
  );
};

ResourcesTable.propTypes = {
  inputsTypes: PropTypes.array.isRequired,
  units: PropTypes.array.isRequired,
  unitsData: PropTypes.array.isRequired,
  onUpdateResources: PropTypes.func.isRequired,
  resources: PropTypes.array.isRequired,
  resourcesError: PropTypes.bool.isRequired,
  canAddSubItems: PropTypes.bool.isRequired,
};

export default ResourcesTable;
