import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const DoubleLineCell = ({ className, firstValue, secondValue }) => (
  <div className={className}>
    <div>{firstValue}</div>
    <div>{secondValue}</div>
  </div>
);

DoubleLineCell.defaultProps = {
  className: '',
};

DoubleLineCell.propTypes = {
  className: PropTypes.string,
  firstValue: PropTypes.string.isRequired,
  secondValue: PropTypes.string.isRequired,
};

export default DoubleLineCell;
