import update from 'immutability-helper';

const getChildren = (parentId, path, treeObject) => {
  return treeObject[parentId].map((e, i) => {
    const newPath = `${path}.${i}`;
    return {
      ...e,
      PATH: newPath,
      label: e.NOMBRE_UCO,
      value: e.ID_UCO,
      ...(treeObject[e.ID_UCO]
        ? {
            children: getChildren(e.ID_UCO, newPath, treeObject),
          }
        : {}),
    };
  });
};

export const getEnclosureTree = (data) => {
  let rootElement = null;
  let treeObject = {};

  data.forEach((item) => {
    if (item.UCO_SUP === item.ID_UCO) {
      rootElement = item;
    } else if (treeObject[item.UCO_SUP]) {
      treeObject = update(treeObject, {
        [item.UCO_SUP]: {
          $push: [item],
        },
      });
    } else {
      treeObject = update(treeObject, {
        [item.UCO_SUP]: {
          $set: [item],
        },
      });
    }
  });

  return [
    {
      ...rootElement,
      PATH: 0,
      label: rootElement.NOMBRE_UCO,
      value: rootElement.ID_UCO,
      children: getChildren(rootElement.ID_UCO, 0, treeObject),
    },
  ];
};

const getChildrenId = (parentId, treeObject) => {
  if (treeObject[parentId]) {
    const toReturn = [];
    treeObject[parentId].forEach((e) => {
      toReturn.push(...[e.ID_UCO, ...getChildrenId(e.ID_UCO, treeObject)]);
    });

    return toReturn;
  }

  return [];
};

export const getSelectedEnClosure = (data, selected) => {
  const selectedEnclosure = [];
  let treeObject = {};

  data.forEach((item) => {
    if (item.ID_UCO !== item.UCO_SUP) {
      if (treeObject[item.UCO_SUP]) {
        treeObject = update(treeObject, {
          [item.UCO_SUP]: {
            $push: [item],
          },
        });
      } else {
        treeObject = update(treeObject, {
          [item.UCO_SUP]: {
            $set: [item],
          },
        });
      }
    }
  });

  selected.forEach((e) => {
    selectedEnclosure.push(...[e, ...getChildrenId(e, treeObject)]);
  });

  return selectedEnclosure;
};

const getChildrenOfChapter = (parentId, path, treeObject) => {
  return treeObject[parentId].map((e, i) => {
    const newPath = `${path}.${i}`;
    return {
      ...e,
      PATH: newPath,
      label: e.NOMBRE_INS,
      value: e.ID_INS,
      ...(treeObject[e.ID_INS]
        ? {
            children: getChildrenOfChapter(e.ID_INS, newPath, treeObject),
          }
        : {}),
    };
  });
};

export const getChaptersTree = (data) => {
  let rootElement = null;
  let treeObject = {};

  data.forEach((item) => {
    if (item.ID_INS_SUP === 0) {
      rootElement = item;
    } else if (treeObject[item.ID_INS_SUP]) {
      treeObject = update(treeObject, {
        [item.ID_INS_SUP]: {
          $push: [item],
        },
      });
    } else {
      treeObject = update(treeObject, {
        [item.ID_INS_SUP]: {
          $set: [item],
        },
      });
    }
  });

  return [
    {
      ...rootElement,
      PATH: 0,
      label: rootElement.NOMBRE_INS,
      value: rootElement.ID_INS,
      children: getChildrenOfChapter(rootElement.ID_INS, 0, treeObject),
    },
  ];
};

const getChildrenChapterId = (parentId, treeObject) => {
  if (treeObject[parentId]) {
    const toReturn = [];
    treeObject[parentId].forEach((e) => {
      toReturn.push(...[e.ID_INS, ...getChildrenChapterId(e.ID_INS, treeObject)]);
    });

    return toReturn;
  }

  return [];
};

export const getSelectedChapters = (data, selected) => {
  const selectedEnclosure = [];
  let treeObject = {};

  data.forEach((item) => {
    if (item.ID_INS_SUP !== 0) {
      if (treeObject[item.ID_INS_SUP]) {
        treeObject = update(treeObject, {
          [item.ID_INS_SUP]: {
            $push: [item],
          },
        });
      } else {
        treeObject = update(treeObject, {
          [item.ID_INS_SUP]: {
            $set: [item],
          },
        });
      }
    }
  });

  selected.forEach((e) => {
    selectedEnclosure.push(...[e, ...getChildrenChapterId(e, treeObject)]);
  });

  return selectedEnclosure;
};
