import _ from 'lodash';
import { message } from 'antd';
import moment from 'moment';
import api, { getHeaders } from '../utils/api';
import {
  PRODUCTION_FETCHED,
  GET_PRODUCTION,
  GET_PRODUCTION_ERROR,
  UPDATE_PRODUCTION,
  RESET_PRODUCTION,
  GET_ADVANCE_DATES,
  ADVANCE_DATES_FETCHED,
  GET_ADVANCE_DATES_ERROR,
  ADD_ADVANCE_DATE,
} from '../reducers/production';
import store from '../store';

export const productionFetched = (production, workId, advanceDate) => ({
  type: PRODUCTION_FETCHED,
  production,
  workId,
  advanceDate,
});

export const updateProductionRows = (recordsToUpdate, workId, advanceDateId) => ({
  type: UPDATE_PRODUCTION,
  recordsToUpdate,
  workId,
  advanceDateId,
});

export const advanceDatesFetched = (advanceDates, workId) => ({
  type: ADVANCE_DATES_FETCHED,
  advanceDates,
  workId,
});

export const addAdvanceDate = (advanceDate, workId) => ({
  type: ADD_ADVANCE_DATE,
  advanceDate,
  workId,
});

export const resetProduction = () => ({ type: RESET_PRODUCTION });

export const getProduction = (ID_OBR, ID_AVA_ENC, ID_AVA_ENC_ANT, filters = null) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: GET_PRODUCTION });

    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR,
      ID_AVA_ENC,
      ...(ID_AVA_ENC_ANT ? { ID_AVA_ENC_ANT } : {}),
      ...(filters || {
        CAPITULOS: [],
        PARTIDAS: [],
        RECURSOS: [],
        CUENTAS: [],
        CON_AVANCE_PARTIDAS: 0,
        CON_AVANCE_RECURSO: 0,
        CON_AVANCE_PARCIAL: 0,
      }),
    };
    api
      .post('/api/Produccion/GetControlProduccion', requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch(productionFetched(res.data, ID_OBR, ID_AVA_ENC));
          resolve(res.data);
        } else {
          dispatch({
            type: GET_PRODUCTION_ERROR,
          });
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_PRODUCTION_ERROR,
        });
        reject(err);
      });
  });

const editSuccessMessage = 'Celdas editadas correctamente';
const editErrorMessage = 'Ocurrió un error al editar las celdas';

export const editAdvanceQuantity = (data, advanceDateId) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ITEM_CANTIDAD_AVA: data,
      ID_AVA_ENC: advanceDateId,
    };
    api
      .post('/api/Produccion/SetCantidadAvance', requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200 && res.data === 'OK') {
          resolve(res.data);
          message.success(editSuccessMessage, 8);
        } else {
          reject(res.data);
          message.error(editErrorMessage, 8);
        }
      })
      .catch((err) => {
        message.error(editErrorMessage, 8);
        reject(err);
      });
  });

export const getAdvanceDates = (ID_OBR) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: GET_ADVANCE_DATES });

    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR,
    };
    api
      .post('/api/Produccion/GetProduccionFechaAvance', requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch(advanceDatesFetched(res.data, ID_OBR));
          resolve(res.data);
        } else {
          dispatch({
            type: GET_ADVANCE_DATES_ERROR,
          });
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_ADVANCE_DATES_ERROR,
        });
        reject(err);
      });
  });

export const createNewAdvanceDate = (ID_OBR, FECHA_AVA) => (dispatch) =>
  new Promise((resolve, reject) => {
    const fechaAVA = moment(FECHA_AVA, 'DD-MM-YYYY').endOf('month').format('DD-MM-YYYY');
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR,
      FECHA_AVA,
    };
    api
      .post('/api/Produccion/SetProduccionFechaAvance', requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            addAdvanceDate({ ID_AVA_ENC: res.data.ID_AVA_ENC, FECHA_AVA: fechaAVA }, ID_OBR)
          );
          message.success('Avance creado correctamente', 8);
          resolve(res.data);
        } else {
          reject(res.data);
        }
      })
      .catch((err) => {
        message.error('Ocurrió un error creando el avance', 8);
        reject(err);
      });
  });
