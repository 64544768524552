import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import update from 'immutability-helper';
import { normalizeString } from '../../utils';

import './index.scss';

let searchTimeout = null;

const ListFilter = ({
  buttonTitle,
  onClick,
  onClose,
  options,
  show,
  subtitle,
  title,
  showSearch,
  popupMode,
  scrollable,
  onSave,
  elementKey,
  elementTitleKey,
  allowSelectAll,
  classNameToPrimaryButton,
  singleSelection,
  classNameToFirstButton,
}) => {
  const [search, onChangeSearch] = useState('');
  const [showOptions, setShowOptions] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [lastSavedOptions, setLastSavedOptions] = useState([]);

  const selectedOptionsMap = new Map(selectedOptions.map((e, i) => [e, i]));
  useEffect(() => {
    if (show) {
      setSelectedOptions(lastSavedOptions);
    }
  }, [show]);

  const isAllSelected = selectedOptions.length === options.length;

  const filteredOptions =
    options && options.length > 0
      ? options
          .sort((a, b) => {
            if (a[elementTitleKey] < b[elementTitleKey]) {
              return -1;
            }
            if (a[elementTitleKey] > b[elementTitleKey]) {
              return 1;
            }
            return 0;
          })
          .filter((current) =>
            normalizeString(current[elementTitleKey]).includes(normalizeString(search))
          )
      : options;

  const changeSearch = (value) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    searchTimeout = setTimeout(() => {
      onChangeSearch(value);
    }, 300);
  };

  const getOptions = (list) => {
    return (
      list.length > 0 && (
        <>
          {list.map((current) => {
            const isChecked = selectedOptionsMap.has(current[elementKey]);
            const index = selectedOptionsMap.get(current[elementKey]);
            const content = (
              <div className="node-element">
                <Checkbox
                  checked={isChecked}
                  onChange={() => {
                    if (singleSelection) {
                      if (isChecked) {
                        setSelectedOptions(
                          update(selectedOptions, {
                            $set: [],
                          })
                        );
                      } else {
                        setSelectedOptions(
                          update(selectedOptions, {
                            $set: [current[elementKey]],
                          })
                        );
                      }
                    } else if (isChecked) {
                      setSelectedOptions(
                        update(selectedOptions, {
                          $splice: [[index, 1]],
                        })
                      );
                    } else {
                      setSelectedOptions(
                        update(selectedOptions, {
                          $push: [current[elementKey]],
                        })
                      );
                    }
                  }}
                />
                <div>{current[elementTitleKey]}</div>
              </div>
            );

            return <div className="checkbox-row level-1">{content}</div>;
          })}
        </>
      )
    );
  };
  return (
    <div className="activities-filter">
      {popupMode && (
        <Button
          className={`main-button ${classNameToPrimaryButton} ${classNameToFirstButton}`}
          onClick={() => onClick()}
          type="default"
        >
          {buttonTitle}
        </Button>
      )}
      {(show || !popupMode) && (
        <div className={`options-container ${!popupMode ? 'not-popup' : ''}`}>
          <div className="options-header">
            <div />
            <span>{title}</span>
            <i
              className="fa fa-times"
              onClick={() => {
                onClose();
                onChangeSearch('');
              }}
            />
          </div>
          {showSearch && (
            <div className="search-wrapper">
              <Input
                size="small"
                onChange={(e) => changeSearch(e.target.value)}
                placeholder="Búsqueda por texto"
                prefix={<SearchOutlined />}
              />
            </div>
          )}
          {options.length > 0 && subtitle && (
            <div className="toggle-filters" onClick={() => setShowOptions(!showOptions)}>
              <div />
              <span>{subtitle}</span>
              <i className={`fa fa-chevron-${showOptions ? 'up' : 'down'}`} />
            </div>
          )}
          {showOptions && (
            <>
              {allowSelectAll && (
                <div className="checkbox-row level-1 selectAllAndClean">
                  <div className="node-element">
                    <Checkbox
                      checked={
                        search.length === 0
                          ? isAllSelected
                          : selectedOptions.length === filteredOptions.length
                      }
                      onChange={() =>
                        setSelectedOptions(
                          // eslint-disable-next-line no-nested-ternary
                          isAllSelected
                            ? []
                            : // eslint-disable-next-line no-nested-ternary
                            search.length === 0
                            ? options.map((current) => current[elementKey])
                            : selectedOptions.length === filteredOptions.length
                            ? []
                            : filteredOptions.map((current) => current[elementKey])
                        )
                      }
                    />
                    <div>Seleccionar Todos</div>
                  </div>
                  <Button
                    disabled={!selectedOptions.length}
                    onClick={() => {
                      setSelectedOptions([]);
                    }}
                    className={selectedOptions.length ? classNameToPrimaryButton : ''}
                  >
                    Limpiar
                  </Button>
                </div>
              )}
              <div className={`options ${scrollable ? 'scrollable' : ''}`}>
                {getOptions(filteredOptions, 1)}
              </div>
            </>
          )}
          <div className="buttons">
            <Button
              type="default"
              onClick={() => {
                onClose();
                setSelectedOptions(lastSavedOptions);
                onChangeSearch('');
              }}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => {
                onSave(selectedOptions);
                setLastSavedOptions(selectedOptions);
                onChangeSearch('');
              }}
              type="primary"
              className={classNameToPrimaryButton}
            >
              Aplicar
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

ListFilter.propTypes = {
  buttonTitle: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  show: PropTypes.bool.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  showSearch: PropTypes.bool,
  popupMode: PropTypes.bool,
  scrollable: PropTypes.bool,
  onSave: PropTypes.func,
  elementTitleKey: PropTypes.string,
  elementKey: PropTypes.string.isRequired,
  allowSelectAll: PropTypes.bool,
  classNameToPrimaryButton: PropTypes.string,
  singleSelection: PropTypes.bool,
  classNameToFirstButton: PropTypes.string,
};

ListFilter.defaultProps = {
  showSearch: true,
  popupMode: true,
  scrollable: false,
  onSave: () => {},
  elementTitleKey: 'title',
  allowSelectAll: true,
  classNameToPrimaryButton: '',
  singleSelection: false,
  classNameToFirstButton: '',
};

export default ListFilter;
