import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './index.scss';
import { Table } from 'rsuite';
import {
  getProfile as getProfileAction,
  getConfig as getConfigAction,
  deleteProfile as deleteProfileAction,
} from '../../../actions/Profile';

import ProfileForm from '../Form/index';
import InputWithTopLabel from '../../../components/InputWithTopLabel';
import { SearchIcon, normalizeString } from '../../../utils';

const ProfileScreen = ({ getProfile, profile, getConfig, deleteProfile, access }) => {
  const [showFormModal, setShowFormModal] = useState(false);
  const [activeRecord, setActiveRecord] = useState(null);
  const [stateProfileData, setStateProfileData] = useState([]);

  const { Column, HeaderCell, Cell } = Table;

  const { isFetching: loadingProfile, data: profileData, config } = profile;

  const { data: configTree } = config;

  const { confirm } = Modal;

  const showConfirm = (rowData) => {
    confirm({
      title: rowData.ELIMINAR ? 'Borrar Perfil' : 'No se puede borrar este Perfil',
      icon: <ExclamationCircleOutlined />,
      content: profileData.ELIMINAR
        ? 'Esta seguro que desea borrar este Perfil?'
        : 'Este perfil esta asociado a un usuario',

      onOk: () => {
        if (rowData.ELIMINAR) {
          deleteProfile(rowData.ID_PERFIL_SUITE);
        } else {
          console.log('Cancel');
        }
      },
      onCancel() {
        if (rowData.ELIMINAR) {
          console.log('Cancel');
        } else {
          console.log('Cancel');
        }
      },
    });
  };

  useEffect(() => {
    if (!profileData) {
      getProfile();
    } else {
      setStateProfileData(profileData);
    }
  }, [profileData]);

  useEffect(() => {
    if (!configTree) {
      getConfig();
    }
  }, [configTree]);

  const handleSearch = (value) => {
    if (value !== '') {
      const newData = profileData.filter((record) => {
        const val = normalizeString(`${record.NOMBRE_PERFIL}`);
        return val.indexOf(normalizeString(value)) !== -1;
      });
      setStateProfileData(newData);
    } else if (value === '') {
      setStateProfileData(profileData);
    }
  };

  const editModal = (rowData) => {
    setShowFormModal(true);
    setActiveRecord(rowData);
  };

  return (
    <div className="containerProfileTable">
      <Modal
        title={`${activeRecord ? 'Editar' : 'Nuevo'} Perfil`}
        visible={showFormModal}
        onOk={() => setShowFormModal(false)}
        onCancel={() => setShowFormModal(false)}
        wrapClassName="company-form-modal"
        maskClosable={false}
        closable={false}
        footer={null}
      >
        <ProfileForm
          activeRecord={activeRecord}
          setShowFormModal={setShowFormModal}
          configTree={configTree}
          profileData={profileData}
          onClose={() => {
            setActiveRecord(null);
          }}
        />
      </Modal>

      <div className="containerTable">
        <div className="buttonNewProfile">
          {!!access.PERFILES_CREAR && (
            <Button type="primary" onClick={() => setShowFormModal(true)}>
              <i className="fa fa-plus iconSpace" />
              Nuevo Perfil
            </Button>
          )}

          <InputWithTopLabel
            icon={<SearchIcon />}
            onChange={(value) => handleSearch(value)}
            label="Buscar"
          />
        </div>
        <div>
          <Table
            className="common-table "
            loading={loadingProfile}
            autoHeight
            width={600}
            locale={{ loading: 'Cargando ...' }}
            data={stateProfileData || []}
          >
            <Column resizable width={100} fixed align="center" className="border-right">
              <HeaderCell />

              <Cell>
                {(rowData) => {
                  return (
                    <span>
                      <a onClick={() => editModal(rowData)}>
                        <i className="fas fa-edit iconSize" />
                      </a>

                      <a onClick={() => showConfirm(rowData)}>
                        <i className="fas fa-trash iconSize" />
                      </a>
                    </span>
                  );
                }}
              </Cell>
            </Column>
            <Column resizable width={300} align="center" fixed className="border-right">
              <HeaderCell className="color-header-cell">Perfiles</HeaderCell>
              <Cell dataKey="NOMBRE_PERFIL" />
            </Column>
            <Column resizable width={200} fixed align="center">
              <HeaderCell className="color-header-cell">Cantidad de accesos</HeaderCell>
              <Cell dataKey="CANT_ACCESOS" />
            </Column>
          </Table>
        </div>
      </div>
    </div>
  );
};

ProfileScreen.propTypes = {
  getProfile: PropTypes.func.isRequired,
  getConfig: PropTypes.func.isRequired,
  deleteProfile: PropTypes.func.isRequired,
  access: PropTypes.bool.isRequired,
  profile: PropTypes.shape({
    isFetching: PropTypes.bool,
    data: PropTypes.array,
    config: PropTypes.shape({
      isFetching: PropTypes.bool,
      data: PropTypes.array,
    }).isRequired,
  }).isRequired,

  projects: PropTypes.shape({
    data: PropTypes.array,
    isFetching: PropTypes.bool,
  }).isRequired,
};

export default connect(
  (store) => ({
    profile: store.profile,
    config: store.profile,
  }),
  (dispatch) => ({
    getProfile: bindActionCreators(getProfileAction, dispatch),
    getConfig: bindActionCreators(getConfigAction, dispatch),
    deleteProfile: bindActionCreators(deleteProfileAction, dispatch),
  })
)(ProfileScreen);
