/* eslint-disable no-restricted-globals */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Cell } from 'rsuite-table';
import { Input, Form, Button } from 'antd';
import { getValueWithUnit } from '../../utils/budgetAndProductionHelpers';

import './index.scss';

const EditableCell = ({
  editable,
  dataIndex,
  editButtonCallback,
  rowData,
  handleSave,
  includeRecordFields,
  unit,
  canShow,
  type,
  validator,
  additionalInputFields,
  objectModifier,
  conditionToEditable,
  className,
  calculateValue,
  isUnitPrefix,
  maxButtonValue,
  extraSave,
  decimalsIndex,
  customClass,
  tooltip,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const [form] = Form.useForm();

  const [edited, setEdited] = useState(false);

  const finalDecimalsIndex =
    typeof decimalsIndex === 'function' ? decimalsIndex(rowData) : decimalsIndex;

  const calculatedValue = calculateValue(rowData);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: rowData[dataIndex] || calculatedValue,
    });
  };

  const save = (event) => {
    if (event) {
      event.preventDefault();
    }
    const values = form.getFieldsValue();
    toggleEdit();
    const value = values[dataIndex];

    // eslint-disable-next-line no-restricted-globals
    let isValidType = type === 'number' && !isNaN(value);

    if (type === 'string') {
      isValidType = true;
    }

    let additionalFields = {};

    includeRecordFields.forEach((field) => {
      additionalFields[field] = rowData[field];
    });

    if (value && isValidType && value !== rowData[dataIndex] && validator(value, rowData)) {
      let finalValue = value;

      if (type === 'number') {
        finalValue = Number(value);
      }
      setEdited(true);
      additionalFields = objectModifier(additionalFields, rowData, finalValue);

      let tree;
      extraSave(rowData, finalValue).forEach(({ info, id }) => {
        tree = handleSave(id, info, true, tree);
      });

      handleSave(
        rowData.ID_ITEM,
        {
          [dataIndex]: finalValue,
          PATH: rowData.PATH,
          ...additionalFields,
        },
        false,
        tree
      );
    }
  };

  useEffect(() => {
    if (!editable) {
      setEdited(false);
    }
  }, [editable]);
  const cellValue = rowData[dataIndex] !== undefined ? rowData[dataIndex] : calculatedValue;
  let children = getValueWithUnit(
    unit,
    cellValue,
    isUnitPrefix,
    type,
    finalDecimalsIndex,
    undefined,
    tooltip && tooltip(rowData)
  );

  if (editable && conditionToEditable(rowData)) {
    const handleFocus = (event) => event.target.select();

    const editButton = editButtonCallback ? (
      <i
        className="fa fa-edit"
        onMouseDown={(e) => {
          e.stopPropagation();
          editButtonCallback(rowData[dataIndex], rowData);
        }}
      />
    ) : null;

    const maxButton = (
      <Button
        type="default"
        className="max-button"
        onMouseDown={(e) => {
          e.stopPropagation();
          form.setFieldsValue({
            [dataIndex]: maxButtonValue(rowData),
          });
          save();
        }}
      >
        MAX
      </Button>
    );
    children = editing ? (
      <div className="editable-form-item-wrapper" title={tooltip && tooltip(rowData)}>
        <Form form={form} component={false}>
          <Form.Item className="editable-form-item" name={dataIndex}>
            <Input
              ref={inputRef}
              onPressEnter={save}
              onBlur={save}
              onFocus={handleFocus}
              {...additionalInputFields(rowData)}
            />
          </Form.Item>
        </Form>
        {editButton}
        {maxButtonValue ? maxButton : null}
      </div>
    ) : (
      <div
        title={tooltip && tooltip(rowData)}
        className="editable-cell-value-wrap"
        onClick={toggleEdit}
      >
        {!isNaN(children)
          ? getValueWithUnit(
              unit,
              cellValue,
              isUnitPrefix,
              type,
              finalDecimalsIndex,
              undefined,
              tooltip && tooltip(rowData)
            )
          : children}
        {editButton}
      </div>
    );
  } else {
    children = getValueWithUnit(
      unit,
      cellValue,
      isUnitPrefix,
      type,
      finalDecimalsIndex,
      undefined,
      tooltip && tooltip(rowData)
    );
  }
  if (!canShow(rowData)) {
    return <Cell dataKey={dataIndex}>{null}</Cell>;
  }

  return (
    <Cell
      {...restProps}
      className={`table-editable-cell ${customClass}  ${
        className(rowData) && conditionToEditable(rowData) ? className(rowData) : ''
      } ${edited ? 'edited' : ''}`}
    >
      {children}
    </Cell>
  );
};

EditableCell.propTypes = {
  editable: PropTypes.bool.isRequired,
  dataIndex: PropTypes.string.isRequired,
  editButtonCallback: PropTypes.func,
  rowData: PropTypes.object.isRequired,
  handleSave: PropTypes.func.isRequired,
  includeRecordFields: PropTypes.array,
  unit: PropTypes.string,
  canShow: PropTypes.bool,
  type: PropTypes.string,
  validator: PropTypes.func,
  additionalInputFields: PropTypes.func,
  objectModifier: PropTypes.func,
  conditionToEditable: PropTypes.func,
  className: PropTypes.func,
  calculateValue: PropTypes.func,
  isUnitPrefix: PropTypes.bool,
  maxButtonValue: PropTypes.number,
  extraSave: PropTypes.func,
  decimalsIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
  customClass: PropTypes.string,
  tooltip: PropTypes.string,
};

EditableCell.defaultProps = {
  editButtonCallback: null,
  includeRecordFields: [],
  unit: '',
  canShow: () => true,
  type: 'number',
  validator: () => true,
  additionalInputFields: () => ({}),
  objectModifier: (obj) => obj,
  conditionToEditable: () => true,
  className: () => '',
  calculateValue: () => '',
  isUnitPrefix: true,
  maxButtonValue: '',
  extraSave: () => [],
  decimalsIndex: null,
  customClass: '',
  tooltip: undefined,
};

export default EditableCell;
