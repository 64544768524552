/* eslint-disable no-restricted-properties */
/* eslint-disable radix */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import api, { getHeaders } from './api';

const initialState = { data: null, isFetching: false, error: false };

export const commonPropTypes = PropTypes.shape({
  isFetching: PropTypes.bool,
  data: PropTypes.array,
  error: PropTypes.bool,
});

export const commonSelectProps = {
  optionFilterProp: 'children',
  showSearch: true,
  filterOption: (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
};

/**
 * @description Get formatted number like 1.111
 * 123456 => 123.456
 * 123456,123 => 123.456,123
 * @param {string} number - Number to format
 * @return {string}
 */
export const getFormattedNumber = (
  value,
  numberOfDecimals = null,
  onlyInteger = false,
  unitPreffix = null
) => {
  if (numberOfDecimals >= 0) {
    value = Number(value).toFixed(numberOfDecimals + 1);
  }
  const integerAndDecimal = String(value).split('.');
  /* Format integer */
  let integer = integerAndDecimal[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  if (unitPreffix) {
    integer = integer.includes('-')
      ? `-${unitPreffix}${integer.replace('-', '')}`
      : `${unitPreffix}${integer}`;
  }
  if (onlyInteger) return integer;
  const decimalPart = integerAndDecimal[1];

  return `${integer}${decimalPart?.length ? `,${decimalPart}` : ''}`;
};
export const toFixed = (x) => {
  let e;
  if (Math.abs(x) < 1.0) {
    e = parseInt(x.toString().split('e-')[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = `0.${new Array(e).join('0')}${x.toString().substring(2)}`;
    }
  } else {
    e = parseInt(x.toString().split('+')[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join('0');
    }
  }
  return x;
};

/**
 * Get formatted rut
 * 123456785 => 12.345.678-5
 * @param {string} rut
 * @returns {string}
 */
export const getFormattedRut = (rut) => {
  const onlyNumbers = rut.replaceAll('.', '').replaceAll('-', '');
  const firstPart = onlyNumbers.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  const secondPart = onlyNumbers.slice(onlyNumbers.length - 1, onlyNumbers.length);

  return `${firstPart}-${secondPart}`;
};

/**
 * Return HH:mm:ss
 * @param {date} date1
 * @param {date} date2
 * @returns {string}
 */
export const diffHoursInTwoDates = (date1, date2) => {
  const diff = moment(date1).diff(moment(date2));
  let seconds = moment.duration(diff).seconds();
  let minutes = moment.duration(diff).minutes();
  let hours = Math.trunc(moment.duration(diff).asHours());

  if (seconds === 59) {
    seconds = '00';
    minutes += 1;

    if (minutes === 60) {
      minutes = '00';
      hours += 1;
    }
  }

  if (minutes === 60) {
    minutes = '00';
    hours += 1;
  }

  return `${hours}:${minutes}:${seconds}`;
};

/**
 * Remove extra spaces in string
 * @param {string} text
 * @returns {string}
 */
export const removeSpaces = (text) => text.replace(/\s+/g, ' ');

/**
 * Get minutes into two hours
 * @param {string} highHour - 02:00
 * @param {string} lowHour - 01:00
 * @returns {number}
 */
export const getMinutesFromTwoHours = (highHour, lowHour) => {
  const highHourSplit = highHour.split(':');
  const lowHourSplit = lowHour.split(':');
  const minutes =
    Number(highHourSplit[0]) * 60 +
    Number(highHourSplit[1]) -
    (Number(lowHourSplit[0]) * 60 + Number(lowHourSplit[1]));

  return minutes;
};

/**
 * Get days in month
 * @param {number} month
 * @param {number} year
 * @return {Date[]}
 */
export const getDaysInMonth = (month, year) => {
  const date = new Date(year, month, 1);
  const days = [];
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
};

/**
 * Get days in current week
 */
export const getDaysInCurrentWeek = () => {
  const current = new Date();
  const firstDay = current.getDate() - current.getDay();
  const days = [];

  for (let i = firstDay; i <= firstDay + 6; i++) {
    days.push(new Date(current.setDate(i)).toUTCString());
  }

  return days;
};

/**
 * Get dates between two dates
 * @param {string} date1 - DD/MM/YYYY
 * @param {string} date2 - DD/MM/YYYY
 */
export const getDatesBetweenTwoDates = (date1, date2) => {
  const dates = [];

  for (let date = date1; date <= date2; ) {
    dates.push(date);
    date = moment(date, 'DD/MM/YYYY').add(1, 'days').format('DD/MM/YYYY');
  }

  return dates;
};

/**
 * Get day
 * @param {Date} date
 */
export const getDay = (date) => {
  if (date.getDay() === 0) {
    return 'D';
  }
  if (date.getDay() === 1) {
    return 'L';
  }
  if (date.getDay() === 2) {
    return 'M';
  }
  if (date.getDay() === 3) {
    return 'M';
  }
  if (date.getDay() === 4) {
    return 'J';
  }
  if (date.getDay() === 5) {
    return 'V';
  }

  return 'S';
};

/**
 * Months
 */
export const months = [
  { name: 'Enero', value: 0 },
  { name: 'Febrero', value: 1 },
  { name: 'Marzo', value: 2 },
  { name: 'Abril', value: 3 },
  { name: 'Mayo', value: 4 },
  { name: 'Junio', value: 5 },
  { name: 'Julio', value: 6 },
  { name: 'Agosto', value: 7 },
  { name: 'Septiembre', value: 8 },
  { name: 'Octubre', value: 9 },
  { name: 'Noviembre', value: 10 },
  { name: 'Diciembre', value: 11 },
];

export const rutRegex = /^[0-9]+[-|‐]{1}[0-9kK]{1}$/;

export const floatRegex = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/;

export const SearchIcon = () => <i className="fa fa-search" style={{ fontSize: '14px' }} />;

export const normalizeString = (str) => {
  return String(str)
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
};

export const normalizeStringToApi = (str) => {
  const newStr = str.toLowerCase().replace(/\s+/g, ' ').trim();
  const words = newStr.split(' ');
  let strToReturn = '';

  words.forEach((e, i) => {
    const word = e.charAt(0).toUpperCase() + e.slice(1);

    if (i === words.length - 1) {
      strToReturn = `${strToReturn}${word}`;
    } else {
      strToReturn = `${strToReturn}${word} `;
    }
  });

  return strToReturn;
};

const genericReducer = (info = initialState, action, actionKey, dataKey) => {
  if (!dataKey) dataKey = actionKey.toLowerCase();
  switch (action.type) {
    case `${actionKey}_FETCHED`:
      return {
        data: action[dataKey],
        isFetching: false,
        error: false,
      };
    case `GET_${actionKey}_ERROR`:
      return {
        data: null,
        isFetching: false,
        error: true,
      };
    case `GET_${actionKey}`:
      return {
        ...info,
        isFetching: true,
        error: false,
      };

    case 'AUTH_LOGOUT':
      return initialState;

    default:
      return info;
  }
};

export const getConfigAction = (actionKey, parameterKey, urlSuffix) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: `GET_${actionKey}`,
    });
    const requestBody = {
      usuario: localStorage.getItem('foco_username'),
      empresa: localStorage.getItem('foco_client'),
    };
    api
      .post(`/api/ParametrosGenerales/${urlSuffix}`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: `${actionKey}_FETCHED`,
            [parameterKey]: res.data,
          });
          resolve(res.data);
        } else {
          dispatch({
            type: `GET_${actionKey}_ERROR`,
          });
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: `GET_${actionKey}_ERROR`,
        });
        reject(err);
      });
  });

export default genericReducer;
