import React, { useState } from 'react';
import get from 'lodash/get';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import { Modal, Button, Input, Form } from 'antd';
import { addNewChapter as saveNewChapter } from '../../../../actions/budget';

import '../index.scss';
import './index.scss';

const NewChapter = ({ activeParentChapter, addNewChapter, onClose }) => {
  const [state, setState] = useState({
    saving: false,
  });
  const [form] = Form.useForm();

  const submit = (values) => {
    setState(update(state, { saving: { $set: true } }));

    saveNewChapter({ ...values, ID_ITEM: 0, ID_ITEM_SUP: activeParentChapter.ID_ITEM })
      .then((res) => {
        const PATH = `${activeParentChapter.PATH}.${activeParentChapter.children.length}`;
        addNewChapter(activeParentChapter, {
          ...values,
          ...res,
          ID_NAT: 1,
          ID_ITEM_SUP: activeParentChapter.ID_ITEM,
          children: [],
          PATH,
        });
        form.resetFields();
        onClose();
        setState(update(state, { saving: { $set: false } }));
      })
      .catch(() => {
        setState(update(state, { saving: { $set: false } }));
      });
  };

  const parentCode = get(activeParentChapter, 'CODIGO_ITEM', null);

  return (
    <div className="add-resource">
      <Modal
        closable={false}
        wrapClassName="item-modal"
        visible={!!activeParentChapter}
        width={900}
        onCancel={() => {
          form.resetFields();
          onClose();
        }}
        footer={null}
        okText="Guardar"
      >
        <div className="modal-container">
          <div className="modal-header">
            <div />
            <span>Agregar Capítulo</span>
            <i
              className="fa fa-times"
              onClick={() => {
                form.resetFields();
                onClose();
              }}
            />
          </div>
          <div className={`form-body new-chapter ${state.saving ? 'saving' : ''}`}>
            {activeParentChapter && (
              <div className="parent-name">{`Capítulo Padre: ${activeParentChapter.CODIGO_ITEM} - ${activeParentChapter.NOMBRE_ITEM}`}</div>
            )}
            <Form form={form} layout="vertical" onFinish={submit}>
              <Form.Item
                initialValue={
                  activeParentChapter
                    ? `${parentCode}${parentCode[parentCode.length - 1] !== '.' ? '.' : ''} `
                    : ''
                }
                label="Código Capítulo"
                name="CODIGO_ITEM"
                required
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese un codigo de capítulo',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Nombre Capítulo"
                name="NOMBRE_ITEM"
                required
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese un nombre de capítulo',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <div className="modal-footer">
                <Button
                  onClick={() => {
                    form.resetFields();
                    onClose();
                  }}
                  disabled={state.saving}
                >
                  Cancelar
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={state.saving}
                  loading={state.saving}
                >
                  Agregar
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

NewChapter.propTypes = {
  activeParentChapter: PropTypes.object.isRequired,
  addNewChapter: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NewChapter;
