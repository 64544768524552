import _ from 'lodash';
import api, { getHeaders } from '../utils/api';
import { JOB_COST_FETCHED, GET_JOB_COST, GET_JOB_COST_ERROR } from '../reducers/jobCost';
import store from '../store';

export const jobCostFetched = (jobCost, workId) => ({
  type: JOB_COST_FETCHED,
  jobCost,
  workId,
});

export const getJobCost = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: GET_JOB_COST });
    const ID_OBR = _.get(store.getState(), 'works.selectedWork', '');
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR,
    };
    api
      .post('/api/CostoApiConpax/GetJobCost', requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch(jobCostFetched(res.data, ID_OBR));
          resolve(res.data);
        } else {
          dispatch({
            type: GET_JOB_COST_ERROR,
          });
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_JOB_COST_ERROR,
        });
        reject(err);
      });
  });

export const setJobCost = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: GET_JOB_COST });
    const ID_OBR = _.get(store.getState(), 'works.selectedWork', '');
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR,
      ...data,
    };
    api
      .post('/api/CostoApiConpax/SetCostoApiConpax', requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          const { TIPO_DESCARGA, COSTO_API_CONPAX, FECHA } = res.data;
          dispatch(jobCostFetched({ TIPO_DESCARGA, COSTO_API_CONPAX, FECHA }, ID_OBR));
          resolve(res.data);
        } else {
          dispatch({
            type: GET_JOB_COST_ERROR,
          });
          reject();
        }
      })
      .catch((err) => {
        console.log('error', err);
        dispatch({
          type: GET_JOB_COST_ERROR,
        });
        reject(err);
      });
  });
