import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import update from 'immutability-helper';
import { Modal, Button, Form, Select, Input, InputNumber, TreeSelect } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { commonPropTypes } from '../../../utils';

import NewChapter from './NewChapter';
import ResourcesTable from './ResourcesTable';

import {
  addNewItem,
  getUnits as getUnitsAction,
  getInputsTypes as getInputsTypesAction,
} from '../../../actions/budget';

import './index.scss';

const { TreeNode } = TreeSelect;

const NewItem = ({
  itemParents,
  units,
  getUnits,
  inputsTypes,
  getInputsTypes,
  reloadTree,
  addChapterToTree,
  tree,
  disabledButton,
  showButton,
  activeItemEdition,
  onClose,
}) => {
  const [state, setState] = useState({
    show: false,
    saving: false,
    resources: [],
    disableItemCodeInput: true,
    capValue: null,
    treeData: null,
    activeParentChapter: null,
    lastAddedChapter: null,
  });

  const treeSelect = useRef(null);
  const itemCode = useRef(null);
  const { show, resources = [], saving, treeData, activeParentChapter, lastAddedChapter } = state;
  const [form] = Form.useForm();
  const { data: unitsData, isFetching: isFetchingUnits } = units;

  useEffect(() => {
    setState(
      update(state, {
        treeData: { $set: tree },
        activeParentChapter: { $set: null },
      })
    );
  }, [tree]);

  useEffect(() => {
    if (activeItemEdition) {
      setState(
        update(state, {
          show: { $set: true },
          resources: { $set: activeItemEdition.children },
        })
      );

      const unit_id =
        unitsData &&
        unitsData.find((current) => current.NOMABR_UNI === activeItemEdition.NOMABR_UNI);

      const {
        ID_ITEM,
        CODIGO_ITEM,
        ID_ITEM_SUP,
        NOMBRE_ITEM,
        ID_TIN,
        CANTIDAD_MOD,
        FACTOR_MOD,
        PRECIO_ITEM,
        PRECIO_ITEM_VENTA,
        ITEM_RECURSO,
        ID_NAT,
        PORCENTAJE_EXITO_MOD,
      } = activeItemEdition;

      const adjustedTotalPrice = (PRECIO_ITEM * PORCENTAJE_EXITO_MOD) / 100;

      form.setFieldsValue({
        ID_ITEM,
        ID_ITEM_SUP,
        CODIGO_ITEM,
        NOMBRE_ITEM,
        ID_UNI: unit_id && unit_id.ID_UNI,
        ID_TIN,
        CANTIDAD_MOD,
        FACTOR_MOD,
        PRECIO_ITEM,
        PRECIO_ITEM_VENTA,
        ITEM_RECURSO,
        ID_NAT,
        PORCENTAJE_EXITO_MOD,
        PRECIO_TOTAL_AJUSTADO: adjustedTotalPrice,
      });
    }
  }, [activeItemEdition]);

  useEffect(() => {
    if (lastAddedChapter) {
      form.setFieldsValue({ ID_ITEM_SUP: lastAddedChapter });
      setState((prev) =>
        update(prev, {
          capValue: { $set: lastAddedChapter },
          lastAddedChapter: { $set: null },
        })
      );
    }
  }, [treeData]);

  useEffect(() => {
    if (!unitsData && !isFetchingUnits) {
      getUnits();
    }
    if (show) {
      if (!inputsTypes.data && !inputsTypes.isFetching) {
        getInputsTypes();
      }
    }
  }, [show]);

  const getUpdatedValues = (perc) => {
    const total =
      resources.reduce(
        (acc, current) => acc + current.PRECIO_ITEM * current.FACTOR_MOD * current.CANTIDAD_MOD,
        0
      ) || 0;

    const percentage = perc / 100;
    const price = (percentage * total || 0).toFixed();
    return {
      PRECIO_ITEM: total.toFixed(),
      PRECIO_TOTAL_AJUSTADO: price,
      PRECIO_ITEM_VENTA: price,
    };
  };

  useEffect(() => {
    const values = getUpdatedValues(form.getFieldValue('PORCENTAJE_EXITO_MOD'));
    form.setFieldsValue(values);
  }, [resources]);

  const submit = (values) => {
    const save = () => {
      setState(update(state, { saving: { $set: true } }));
      const unitsByNames = {};

      unitsData.forEach((e) => {
        unitsByNames[e.NOMABR_UNI] = e.ID_UNI;
      });
      const resourcesAndSubitemsToSave = resources.map((resource) => {
        const {
          ID_ITEM,
          CODIGO_ITEM,
          NOMBRE_ITEM,
          NOMABR_UNI,
          ID_TIN,
          CANTIDAD_MOD,
          FACTOR_MOD,
          PRECIO_ITEM,
          ID_NAT,
        } = resource;
        const newResource = {
          ID_ITEM: !_.isString(ID_ITEM) ? ID_ITEM : 0,
          CODIGO_ITEM,
          NOMBRE_ITEM,
          ID_UNI: unitsByNames[NOMABR_UNI],
          ID_TIN,
          CANTIDAD_MOD,
          FACTOR_MOD,
          PRECIO_ITEM,
          ID_NAT,
        };

        return newResource;
      });

      const requestBody = {
        ID_ITEM: activeItemEdition ? activeItemEdition.ID_ITEM : 0,
        ID_ITEM_SUP: values.ID_ITEM_SUP,
        CODIGO_ITEM: values.CODIGO_ITEM,
        NOMBRE_ITEM: values.NOMBRE_ITEM,
        ID_UNI: values.ID_UNI,
        CANTIDAD_MOD: values.CANTIDAD_MOD,
        PRECIO_ITEM: Number(values.PRECIO_ITEM),
        PRECIO_ITEM_VENTA: Number(values.PRECIO_ITEM_VENTA),
        ITEM_RECURSO: resourcesAndSubitemsToSave,
        PORCENTAJE_EXITO_MOD: values.PORCENTAJE_EXITO_MOD,
      };

      addNewItem(requestBody)
        .then(() => {
          reloadTree();
          form.resetFields();
          onClose();
          setState(
            update(state, {
              saving: { $set: false },
              show: { $set: false },
              resources: { $set: [] },
            })
          );
        })
        .catch((error) => {
          setState(update(state, { saving: { $set: false } }));
          console.log('error', error);
        });
    };
    if (!resources.length) {
      Modal.confirm({
        title: !activeItemEdition
          ? 'Se generará una nueva partida sin recursos asociados'
          : 'Se guardara la partida sin recursos asociados',
        icon: <ExclamationCircleOutlined />,
        content: 'Confirma esta acción',
        okText: 'Ok',
        cancelText: 'Cancelar',
        onOk: save,
      });
    } else {
      save();
    }
  };

  const onValuesChange = (changedValues) => {
    const { PORCENTAJE_EXITO_MOD } = changedValues;
    if (PORCENTAJE_EXITO_MOD >= 0) {
      form.setFieldsValue(getUpdatedValues(PORCENTAJE_EXITO_MOD));
    }
  };

  const onChange = (value) => {
    setState(
      update(state, {
        capValue: { $set: _.get(value, '[0].key', value) },
      })
    );
  };

  const onSelect = ({ node }) => {
    let codeToSet = node.CODIGO_ITEM;
    if (codeToSet[codeToSet.length - 1] !== '.') {
      codeToSet += '.';
    }
    form.setFieldsValue({
      CODIGO_ITEM: codeToSet,
    });
    itemCode.current.focus();
  };

  const openNewChapterModal = (e, currentNode) => {
    e.stopPropagation();
    e.preventDefault();

    treeSelect.current.blur();
    setTimeout(() => {
      setState(
        update(state, {
          activeParentChapter: { $set: currentNode },
        })
      );
    }, 200);
  };

  const getTreeNodes = (nodes) => {
    return nodes.map((currentNode) => {
      const primaryLabel = `${currentNode.CODIGO_ITEM ? `${currentNode.CODIGO_ITEM} - ` : ''}${
        currentNode.NOMBRE_ITEM
      }`;
      return (
        <TreeNode
          value={currentNode.ID_ITEM}
          primaryLabel={primaryLabel}
          node={currentNode}
          title={
            <div className="tree-select-node">
              <span>{primaryLabel}</span>
              <i
                title="Agregar Capítulo"
                className="fa fa-plus"
                onClick={(e) => openNewChapterModal(e, currentNode)}
              />
            </div>
          }
        >
          {currentNode.ID_NAT === 1 &&
          (_.get(currentNode, 'children', []).some((e) => e.ID_NAT === 1) ||
            _.get(currentNode, 'children.length', null) === 0)
            ? getTreeNodes(currentNode.children.filter((e) => e.ID_NAT === 1))
            : null}
        </TreeNode>
      );
    });
  };

  return (
    <div className="new-item">
      {showButton && (
        <Button
          disabled={disabledButton}
          onClick={() =>
            setState(
              update(state, {
                show: { $set: true },
              })
            )
          }
          type="primary"
          className="new-item-button"
        >
          <i className="fa fa-plus" /> Nueva Partida
        </Button>
      )}

      <NewChapter
        activeParentChapter={activeParentChapter}
        addNewChapter={(parent, newChapter) => {
          setState(
            update(state, {
              lastAddedChapter: { $set: newChapter.ID_ITEM },
            })
          );
          addChapterToTree(parent, newChapter);
        }}
        onClose={() => {
          setState(
            update(state, {
              activeParentChapter: { $set: null },
            })
          );
        }}
      />
      <Modal
        closable={false}
        wrapClassName="item-modal"
        visible={show}
        width={900}
        footer={null}
        okText="Guardar"
        onCancel={onClose}
      >
        <div className="modal-container">
          <div className="modal-header">
            <div />
            <span>{activeItemEdition ? 'Editar Partida' : 'Nueva Partida'}</span>
            <i
              className="fa fa-times"
              onClick={() => {
                onClose();
                form.resetFields();
                setState(
                  update(state, {
                    show: { $set: false },
                    resources: { $set: [] },
                  })
                );
              }}
            />
          </div>
          <div className={`form-body ${saving ? 'saving' : ''}`}>
            <div id="blank-div" />
            <Form onValuesChange={onValuesChange} form={form} layout="vertical" onFinish={submit}>
              {treeData && (
                <Form.Item
                  label="Capítulo Padre"
                  name="ID_ITEM_SUP"
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Por favor seleccione el capítulo padre',
                    },
                  ]}
                >
                  <TreeSelect
                    showSearch
                    disabled={activeItemEdition}
                    ref={treeSelect}
                    placeholder=""
                    dropdownClassName="caps-dropdown"
                    style={{ width: '100%' }}
                    value={state.capValue}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    allowClear
                    treeNodeFilterProp="primaryLabel"
                    treeNodeLabelProp="primaryLabel"
                    onChange={onChange}
                    onSelect={(_value, obj) => onSelect(obj)}
                    treeDefaultExpandedKeys={treeData.length ? [treeData[0].ID_ITEM] : []}
                  >
                    {getTreeNodes(tree)}
                  </TreeSelect>
                </Form.Item>
              )}

              <Form.Item
                label="Código Partida"
                name="CODIGO_ITEM"
                required
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese el codigo de la partida',
                  },
                  {
                    validator: async (obj, value) => {
                      if (!activeItemEdition && form.getFieldValue('ID_ITEM_SUP')) {
                        const parent = itemParents.find(
                          (e) => e.ID_ITEM === form.getFieldValue('ID_ITEM_SUP')
                        );

                        const itemCodeAlreadyExists =
                          parent &&
                          parent.children &&
                          parent.children.find((e) => e.CODIGO_ITEM === value);

                        if (!activeItemEdition && itemCodeAlreadyExists) {
                          return Promise.reject(
                            new Error(
                              'Ya existe una partida con este codigo para el capítulo seleccionado'
                            )
                          );
                        }
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input ref={itemCode} />
              </Form.Item>
              <Form.Item
                label="Nombre Partida"
                name="NOMBRE_ITEM"
                required
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese el nombre de la partida',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <div className="form-row">
                <Form.Item
                  label="Cantidad"
                  name="CANTIDAD_MOD"
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese la cantidad',
                    },
                  ]}
                >
                  <InputNumber min={1} />
                </Form.Item>
                <Form.Item
                  label="Unidad"
                  name="ID_UNI"
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Por favor seleccione la unidad',
                    },
                  ]}
                >
                  <Select
                    loading={isFetchingUnits}
                    disabled={isFetchingUnits}
                    optionFilterProp="children"
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {(unitsData || []).map(({ NOMABR_UNI, ID_UNI, NOMBRE_UNI }) => (
                      <Select.Option value={ID_UNI} key={ID_UNI}>
                        {`${NOMBRE_UNI} (${NOMABR_UNI})`}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <ResourcesTable
                canAddSubItems
                onUpdateResources={(data) => {
                  setState(update(state, { resources: { $set: data } }));
                }}
                resources={resources}
                inputsTypes={inputsTypes}
                units={units}
                unitsData={unitsData}
              />
              <div className="form-row four">
                <Form.Item label="Precio Unitario Total" name="PRECIO_ITEM" required>
                  <InputNumber className="input-with-unit" disabled={resources.length} />
                </Form.Item>
                <Form.Item
                  initialValue={100}
                  label="% de éxito"
                  name="PORCENTAJE_EXITO_MOD"
                  required
                >
                  <InputNumber type="number" min={1} max={100} />
                </Form.Item>
                <Form.Item
                  initialValue={0}
                  label="Precio Total Ajustado"
                  name="PRECIO_TOTAL_AJUSTADO"
                  required
                >
                  <InputNumber className="input-with-unit" disabled={resources.length} />
                </Form.Item>
                <Form.Item
                  initialValue={0}
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese el Precio de Venta',
                    },
                  ]}
                  label="Precio de venta"
                  name="PRECIO_ITEM_VENTA"
                  required
                >
                  <InputNumber
                    disabled={resources.length}
                    className="input-with-unit"
                    type="number"
                    min={0}
                  />
                </Form.Item>
              </div>

              <div className="modal-footer">
                <Button
                  disabled={saving}
                  onClick={() => {
                    form.resetFields();
                    onClose();
                    setState(
                      update(state, {
                        show: { $set: false },
                        resources: { $set: [] },
                      })
                    );
                  }}
                >
                  Cancelar
                </Button>
                <Button type="primary" htmlType="submit" disabled={saving} loading={saving}>
                  {!activeItemEdition ? 'Agregar Partida' : 'Editar Partida'}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

NewItem.defaultProps = {
  onClose: () => null,
};

NewItem.propTypes = {
  itemParents: PropTypes.array.isRequired,
  units: commonPropTypes.isRequired,
  inputsTypes: commonPropTypes.isRequired,
  getUnits: PropTypes.func.isRequired,
  getInputsTypes: PropTypes.func.isRequired,
  activeItemEdition: PropTypes.func.isRequired,
  reloadTree: PropTypes.func.isRequired,
  tree: PropTypes.object.isRequired,
  disabledButton: PropTypes.bool.isRequired,
  showButton: PropTypes.bool.isRequired,
  addChapterToTree: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

export default connect(
  ({ budget: { units, inputsTypes, resourcesAndSubitems } }) => ({
    units,
    inputsTypes,
    resourcesAndSubitems,
  }),
  (dispatch) => ({
    getUnits: bindActionCreators(getUnitsAction, dispatch),
    getInputsTypes: bindActionCreators(getInputsTypesAction, dispatch),
  })
)(NewItem);
