import _ from 'lodash';
import { message } from 'antd';
import api, { getHeaders } from '../utils/api';
import {
  DELETE_WORKER,
  GET_SUPERVISORS,
  GET_WORKERS,
  SET_ERROR_WORKERS,
  SET_WORKER,
  UPDATE_SUPERVISORS_WORKER,
  UPDATE_WORKER,
} from '../reducers/workers';
import store from '../store';

export const setError = (error = '') => ({
  type: SET_ERROR_WORKERS,
  payload: { error },
});

export const getWorkers = () => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
    };
    const errorMessage = 'No se cargaron éxitosamente los trabajadores.';
    api
      .post(`/api/Productividad/GetTrabajadores`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: GET_WORKERS, payload: { workers: res.data } });
          resolve(res.data);
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });

export const getWorkersAttendance = (month, year) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR: _.get(store.getState(), 'works.selectedWork', ''),
      ANHO: year,
      MES: month,
    };
    const errorMessage = 'No se cargaron éxitosamente los trabajadores.';
    api
      .post(`/api/Productividad/GetTrabajadoresAsistencia`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: GET_WORKERS, payload: { workers: res.data } });
          resolve(res.data);
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });

export const deleteWorker = (workerId) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_TRA: workerId,
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
    };
    const errorMessage = 'No se pudo eliminar el trabajador.';
    api
      .post(`/api/Productividad/DelTrabajador`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          const { ID_TRA } = res.data;
          if (ID_TRA === 0) {
            dispatch(setError(errorMessage));
            message.error(errorMessage, 4);
            reject(res.data);
          } else {
            dispatch({ type: DELETE_WORKER, payload: { workerId } });
            message.success('Trabajador eliminado correctamente', 4);
            resolve(res.data);
          }
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });

export const setWorker = (workerData) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ...workerData,
    };
    const errorMessage = 'No se pudo guardar el nuevo trabajador.';
    api
      .post(`/api/Productividad/SetTrabajador`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          const { ID_TRA } = res.data;
          if (ID_TRA === 0) {
            dispatch(setError(errorMessage));
            message.error(errorMessage, 4);
            reject(res.data);
          } else {
            // const data = { ...workerData, EMPRESA: null, ID_TRA, USUARIO: null };

            // dispatch({ type: SET_WORKER, payload: { data } });
            message.success('Trabajador agregado correctamente', 4);

            resolve(res.data);
          }
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });

export const updateWorker = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ...data,
    };
    const errorMessage = 'No se pudo actualizar el trabajador.';
    api
      .post(`/api/Productividad/UpdTrabajador`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          const { ID_TRA } = res.data;
          if (ID_TRA === 0) {
            dispatch(setError(errorMessage));
            message.error(errorMessage, 4);
            reject(res.data);
          } else {
            dispatch({ type: UPDATE_WORKER, payload: { worker: data } });
            message.success('Trabajador actualizado correctamente', 4);

            resolve(res.data);
          }
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });

export const updateMassSupervisor = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      TRABAJADORES: data,
    };
    const errorMessage = 'No se pudo actualizar el supervisor de forma masiva.';
    api
      .post(`/api/Productividad/UpdTrabajadorSupervisor`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          if (res.data === 'OK') {
            dispatch({ type: UPDATE_SUPERVISORS_WORKER, payload: { workers: data } });
            message.success('Trabajadores actualizados correctamente', 2);
            resolve(res.data);
          } else {
            dispatch(setError(errorMessage));
            message.error(errorMessage, 4);
            reject(res.data);
          }
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });

export const getSupervisors = () => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
    };
    const errorMessage = 'No se cargaron éxitosamente los supervisores.';
    api
      .post(`/api/Productividad/GetSupervisores`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: GET_SUPERVISORS, payload: { supervisors: res.data } });
          resolve(res.data);
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });
