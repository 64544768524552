/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Input, Switch } from 'antd';

import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUser as getUserAction } from '../../actions/usercontrol';
import FormBox from '../../components/FormBox';
import './index.scss';

const DetailsUser = ({ auth, ItemsInRedux, getUser }) => {
  const { isUser } = ItemsInRedux;
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const IdUser = params.get('id');

  useEffect(() => {
    if (auth.token && auth.user.username) {
      (async () => {
        await getUser(IdUser);
      })();
    }
  }, [auth]);
  const [form] = Form.useForm();
  return (
    <div className="company-form">
      {!isUser ? (
        <div className="spin">
          <LoadingOutlined />
        </div>
      ) : (
        <>
          <Form requiredMark="optional" scrollToFirstErr or form={form} layout="vertical">
            <FormBox title="información básica">
              <Form.Item label="Nombre Usuario" name="RUTMAS_EMP">
                <Input initialValues={isUser.NombreUsu} />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese un Nombre Completo',
                  },
                ]}
                name="NOMBRE_EMP"
                label="Nombre Completo"
                required
              >
                <Input initialValues={isUser.NombreCompleto} />
              </Form.Item>
              <Form.Item
                name="RAZON_SOCIAL_EMP"
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese una Razón Social',
                  },
                ]}
                required
                label="Cargo"
              >
                <Input initialValues={isUser.NombreCargo} />
              </Form.Item>
              <Form.Item name="GIRO_EMP" required label="Contraseña">
                <Input initialValues={isUser.password_original} />
              </Form.Item>
            </FormBox>
            <FormBox title="información de contacto">
              <Form.Item
                label="Correo eléctronico"
                required
                name="NOMBRE_CLASIFICADOR_COSTO"
                tooltip={{
                  title:
                    'Es el que realiza el registro de costos de acuerdo a su tipo o función dentro de la empresa',
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Input initialValues={isUser.Email} />
              </Form.Item>
              <Form.Item
                label="Teléfono"
                required
                name="NOMBRE_CLASIFICADOR_COSTO"
                tooltip={{
                  title:
                    'Es el que realiza el registro de costos de acuerdo a su tipo o función dentro de la empresa',
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Input initialValues={isUser.Celular} />
              </Form.Item>
            </FormBox>
            <FormBox title="Proyectos">
              <label>Selecciona los proyectos a los que tendra acceso el usuario</label>
              <div className="options">
                <div className="switch-row">
                  Restringir Proyectos
                  <Switch className="switch" />
                </div>
              </div>
            </FormBox>
            <FormBox title="Perfiles">
              <label>Selecciona los proyectos a los que tendra acceso el usuario</label>
              <Form.Item
                label="Restringir Proyectos"
                required
                name="NOMBRE_CLASIFICADOR_COSTO"
                tooltip={{
                  title:
                    'Es el que realiza el registro de costos de acuerdo a su tipo o función dentro de la empresa',
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Proyectos"
                required
                name="NOMBRE_CLASIFICADOR_COSTO"
                tooltip={{
                  title:
                    'Es el que realiza el registro de costos de acuerdo a su tipo o función dentro de la empresa',
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Input />
              </Form.Item>
            </FormBox>
            <FormBox title="Módulos">
              <label>Selecciona los modulos a los que tendra acceso el usuario</label>
              <div className="options">
                <div className="switch-row">
                  Planner
                  <Switch className="switch" />
                </div>
                <div className="switch-row">
                  Costos
                  <Switch className="switch" />
                </div>
                <div className="switch-row">
                  Productividad
                  <Switch className="switch" />
                </div>
                <div className="switch-row">
                  Calidad
                  <Switch className="switch" />
                </div>
                <div className="switch-row">
                  Prevención
                  <Switch className="switch" />
                </div>
              </div>
            </FormBox>
            <div className="buttons-wrapper">
              <Form.Item>
                <Button />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Guardar
                </Button>
              </Form.Item>
            </div>
          </Form>
        </>
      )}
    </div>
  );
};

DetailsUser.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.object,
    token: PropTypes.string,
  }).isRequired,
  ItemsInRedux: PropTypes.shape({
    isUser: PropTypes.array,
  }).isRequired,
  getUser: PropTypes.func.isRequired,
};

DetailsUser.defaultProps = {};
export default connect(
  (store) => ({
    auth: store.auth,
    ItemsInRedux: store.UserControl,
  }),
  (dispatch) => ({
    getUser: bindActionCreators(getUserAction, dispatch),
  })
)(DetailsUser);
