import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Button, Tree } from 'antd';

import './index.scss';

const ChapterFilter = ({
  buttonTitle,
  classNameToPrimaryButton,
  defaultExpandItemValues,
  defaultValue,
  onClick,
  onClose,
  onSave,
  show,
  title,
  tree,
  nameKey = 'NOMBRE_ITEM',
  idKey = 'ID_ITEM',
  returnObject,
  disableButton,
  reset,
  conditional,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState(defaultValue);
  const [lastSavedSelectedOptions, setLastSavedSelectedOptions] = useState([]);
  const [lastSavedCheckedKeys, setLastSavedCheckedKeys] = useState([]);

  useEffect(() => {
    if (reset) {
      setSelectedOptions([]);
      setCheckedKeys(defaultValue);
      setLastSavedSelectedOptions([]);
      setLastSavedCheckedKeys([]);
    }
  }, [reset]);

  const getTreeNodes = (nodes) => {
    return nodes.map((currentNode) => {
      const primaryLabel = `${currentNode.CODIGO_ITEM ? `${currentNode.CODIGO_ITEM} - ` : ''}${
        currentNode[nameKey]
      }`;
      return {
        title: primaryLabel,
        key: currentNode[idKey],
        ...(currentNode.PATH ? { PATH: currentNode.PATH } : {}),
        ...(conditional(currentNode) &&
        get(currentNode, 'children', []).some(
          (e) => conditional(e) && get(e, 'children.length', 0) > 0
        )
          ? {
              children: getTreeNodes(
                currentNode.children.filter(
                  (e) => conditional(e) && get(e, 'children.length', 0) > 0
                )
              ),
            }
          : {}),
      };
    });
  };

  const onCheck = (keys, info) => {
    const checkedNodes = [];
    setCheckedKeys(keys);
    info.checkedNodes.forEach((e) => {
      if (!e.children) {
        const value = returnObject ? e : e.key;
        checkedNodes.push(value);
      }
    });
    setSelectedOptions(checkedNodes);
  };

  return (
    <div className="tree-filter">
      <Button
        className={`main-button ${classNameToPrimaryButton}`}
        onClick={() => onClick()}
        type="default"
        disabled={disableButton}
      >
        {buttonTitle}
      </Button>
      {show && !disableButton && (
        <div className={`options-container `}>
          <div className="options-header">
            <div />
            <span>{title}</span>
            <i
              className="fa fa-times"
              onClick={() => {
                setSelectedOptions(lastSavedSelectedOptions);
                setCheckedKeys(lastSavedCheckedKeys);
                onClose();
              }}
            />
          </div>
          <Tree
            virtual
            switcherIcon={<i className="fa fa-chevron-down" />}
            checkable
            defaultValue={checkedKeys}
            checkedKeys={checkedKeys}
            defaultExpandedKeys={defaultExpandItemValues}
            onCheck={onCheck}
            treeData={getTreeNodes(tree)}
          />
          <div className="buttons">
            <Button
              type="default"
              onClick={() => {
                setSelectedOptions(lastSavedSelectedOptions);
                setCheckedKeys(lastSavedCheckedKeys);
                onClose();
              }}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => {
                onSave(selectedOptions);
                setLastSavedSelectedOptions(selectedOptions);
                setLastSavedCheckedKeys(checkedKeys);
              }}
              type="primary"
              className={classNameToPrimaryButton}
            >
              Aplicar
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

ChapterFilter.defaultProps = {
  classNameToPrimaryButton: '',
  disableButton: false,
  reset: false,
  conditional: (e) => e.ID_NAT === 1,
};

ChapterFilter.propTypes = {
  buttonTitle: PropTypes.string.isRequired,
  classNameToPrimaryButton: PropTypes.string,
  defaultExpandItemValues: PropTypes.arrayOf(PropTypes.number).isRequired,
  defaultValue: PropTypes.arrayOf(PropTypes.number).isRequired,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  tree: PropTypes.arrayOf(PropTypes.object).isRequired,
  nameKey: PropTypes.string.isRequired,
  idKey: PropTypes.string.isRequired,
  returnObject: PropTypes.bool.isRequired,
  disableButton: PropTypes.bool,
  reset: PropTypes.bool,
  conditional: PropTypes.func,
};

export default ChapterFilter;
