import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Input, InputNumber, Popconfirm, Form, Button, Modal, message } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getCompanies as getCompaniesAction,
  deleteCompany as deleteCompanyAction,
} from '../../../actions/companies';

import { getProjects as getProjectsAction } from '../../../actions/projects';
import Table from '../../../components/Table';
import DoubleLineCell from '../../../components/DoubleLineCell';
import CompanyForm from '../Form';
import { getFormattedRut } from '../../../utils';

import './index.scss';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

EditableCell.propTypes = {
  editing: PropTypes.bool.isRequired,
  dataIndex: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  inputType: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  children: PropTypes.element.isRequired,
};

const Companies = ({
  getCompanies,
  companies,
  deleteCompany,
  projects,
  getProjects,
  role,
  access,
}) => {
  const [editingKey, setEditingKey] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);
  const [activeRecord, setActiveRecord] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);

  const { isFetching: loadingCompanies, data: companiesData } = companies;
  const { isFetching: loadingProjects, data: projectsData } = projects;

  useEffect(() => {
    if (!companiesData) {
      getCompanies();
    }

    if (!projectsData) {
      getProjects();
    }
  }, []);

  const removeCompany = (id) => {
    setIsDeleting(true);

    deleteCompany(id)
      .then(() => {
        setIsDeleting(false);
        setShowDeleteModal(false);
        setActiveRecord(null);
      })
      .catch(() => {
        setIsDeleting(false);
        setShowDeleteModal(false);
        setActiveRecord(null);
      });
  };

  const isEditing = (record) => record.key === editingKey;

  const cancel = () => {
    setEditingKey('');
  };

  const hideModal = () => {
    setShowFormModal(false);
    setActiveRecord(null);
  };

  const remove = (record) => {
    const projectWithCompany = projectsData.find((p) => p.ID_EMP === record.ID_EMP);
    if (projectWithCompany) {
      message.error(
        'No puede eliminar esta empresa ya que existe un proyecto con esta empresa asociada'
      );
    } else {
      setShowDeleteModal(true);
      setActiveRecord(record);
    }
  };

  const columns = [
    {
      title: '',
      dataIndex: 'operation',
      width: '100px',
      render: (_value, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Popconfirm title="Desea cancelar la edicion?" onConfirm={cancel}>
              <a>Cancelar</a>
            </Popconfirm>
          </span>
        ) : (
          <div className="edit-icons">
            <i
              className="fa fa-edit edit-icon"
              onClick={() => {
                setShowFormModal(true);
                setActiveRecord(record);
              }}
            />

            <i className="fa fa-trash" type="primary" onClick={() => remove(record)} />
          </div>
        );
      },
    },
    {
      title: <DoubleLineCell firstValue="Nombre /" secondValue="Razón Social" />,
      dataIndex: 'NOMBRE_EMP',
      editable: true,
      render: (value, { RAZON_SOCIAL_EMP }) => (
        <DoubleLineCell firstValue={value} secondValue={RAZON_SOCIAL_EMP} />
      ),
    },
    {
      title: <DoubleLineCell firstValue="RUT /" secondValue="Giro" />,
      dataIndex: 'RUTMAS_EMP',
      editable: true,
      render: (value, { GIRO_EMP }) => (
        <DoubleLineCell firstValue={getFormattedRut(value)} secondValue={GIRO_EMP} />
      ),
    },
    {
      title: 'Comuna',
      dataIndex: 'NOMBRE_COMUNA',
      editable: true,
    },
    {
      title: 'Dirección',
      dataIndex: 'DIRECCION_EMP',
      editable: true,
    },
    {
      title: 'Clasificador Costo',
      dataIndex: 'NOMBRE_CLASIFICADOR_COSTO',
      editable: true,
    },
    {
      title: 'Tipo Recurso',
      dataIndex: 'NOMBRE_TIPO_RECURSO',
      editable: true,
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return col;
  });
  return (
    <div className="company-list">
      <Modal
        title={`${activeRecord ? 'Editando' : 'Nueva'} empresa`}
        visible={showFormModal}
        onOk={hideModal}
        onCancel={hideModal}
        wrapClassName="company-form-modal"
        footer={null}
        maskClosable={false}
        closable={false}
      >
        <CompanyForm
          role={role}
          editData={
            activeRecord
              ? {
                  ...activeRecord,
                  RUTMAS_EMP: getFormattedRut(activeRecord.RUTMAS_EMP),
                  EMPRESA_ASOCIADA: activeRecord.EMPRESA_ASOCIADA.map((e) => ({
                    ...e,
                    RUT_EMP_ASOCIADA: getFormattedRut(e.RUT_EMP_ASOCIADA),
                  })),
                }
              : activeRecord
          }
          onCancel={hideModal}
        />
      </Modal>
      {activeRecord && (
        <Modal
          title="Eliminando empresa"
          visible={showDeleteModal}
          onOk={() => setShowDeleteModal(false)}
          onCancel={() => setShowDeleteModal(false)}
          footer={[
            <Button
              key="back"
              disabled={isDeleting}
              onClick={() => {
                setShowDeleteModal(false);
                setActiveRecord(null);
              }}
            >
              Cancelar
            </Button>,
            <Button
              key="submit"
              disabled={isDeleting}
              loading={isDeleting}
              type="primary"
              onClick={() => removeCompany(activeRecord.ID_EMP)}
            >
              Eliminar
            </Button>,
          ]}
        >
          <p
            style={{ textAlign: 'center' }}
          >{`¿Confirma que desea eliminar a ${activeRecord.NOMBRE_EMP}?`}</p>
        </Modal>
      )}

      <Table
        loading={loadingCompanies || loadingProjects}
        linkButton={
          !!access.EMPRESA_CREAR && (
            <Button type="primary" onClick={() => setShowFormModal(true)}>
              <i className="fa fa-plus" />
              Nueva Empresa
            </Button>
          )
        }
        editableCell={EditableCell}
        dataSource={companiesData}
        columnsInfo={mergedColumns}
        rowClassName="editable-row"
      />
    </div>
  );
};

Companies.propTypes = {
  getCompanies: PropTypes.func.isRequired,
  getProjects: PropTypes.func.isRequired,
  companies: PropTypes.shape({
    isFetching: PropTypes.bool,
    data: PropTypes.array,
  }).isRequired,
  deleteCompany: PropTypes.func.isRequired,
  projects: PropTypes.shape({
    data: PropTypes.array,
    isFetching: PropTypes.bool,
  }).isRequired,
  role: PropTypes.string.isRequired,
  access: PropTypes.object.isRequired,
};

export default connect(
  (store) => ({
    companies: store.companies,
    projects: store.projects,
  }),
  (dispatch) => ({
    getCompanies: bindActionCreators(getCompaniesAction, dispatch),
    getProjects: bindActionCreators(getProjectsAction, dispatch),
    deleteCompany: bindActionCreators(deleteCompanyAction, dispatch),
  })
)(Companies);
