import Login from '../views/Login';
import Home from '../views/Home';
import ClientsList from '../views/ClientsList';
import Logout from '../views/Logout';
import AttendanceControl from '../views/AttendanceControl';
import AdvanceControl from '../views/AdvanceControl';
import AdvanceIncome from '../views/AdvanceIncome';
import PaymentStatus from '../views/PaymentStatus';
import AdvanceMatrix from '../views/AdvanceMatrix';
import PanelControl from '../views/PanelControl';
import Configuration from '../views/Configuration';
import Help from '../views/Help';
import DetailsUser from '../views/DetailsUser';
import UserControl from '../views/UserControl';
import Management from '../views/Management';

export default [
  {
    path: '/login',
    Component: Login,
  },
  {
    path: '/home',
    Component: Home,
  },
  {
    path: '/clients',
    Component: ClientsList,
  },
  {
    path: '/logout',
    Component: Logout,
  },
  {
    path: '/management',
    Component: Management,
  },
  {
    path: '/attendance-control',
    Component: AttendanceControl,
  },
  {
    path: '/advance-control',
    Component: AdvanceControl,
  },
  {
    path: '/advance-income',
    Component: AdvanceIncome,
  },
  {
    path: '/panel-control',
    Component: PanelControl,
  },
  {
    path: '/details-user',
    Component: DetailsUser,
  },
  {
    path: '/user-control',
    Component: UserControl,
  },
  {
    path: '/configuration',
    Component: Configuration,
  },
  {
    path: '/payment-status',
    Component: PaymentStatus,
  },
  {
    path: '/advance-matrix',
    Component: AdvanceMatrix,
  },
  {
    path: '/help',
    Component: Help,
  },
];
