export const OPTIONS_SCREEN_FETCHED = 'OPTIONS_SCREEN_FETCHED';
export const GET_OPTIONS_SCREEN = 'GET_OPTIONS_SCREEN';
export const GET_OPTIONS_SCREEN_ERROR = 'GET_OPTIONS_SCREEN_ERROR';

const optionScreenInit = {
  data: {},
  isFetching: false,
  error: false,
};

const options = (state = optionScreenInit, action) => {
  switch (action.type) {
    case OPTIONS_SCREEN_FETCHED:
      return { ...optionScreenInit, data: action.options, isFetching: false, error: false };
    case GET_OPTIONS_SCREEN_ERROR:
      return { ...optionScreenInit, data: null, isFetching: false, error: true };
    case GET_OPTIONS_SCREEN:
      return {
        ...optionScreenInit,
        isFetching: true,
        error: false,
      };

    default:
      return state;
  }
};

export default options;
