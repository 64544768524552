import React, { useState, useEffect } from 'react';
import update from 'immutability-helper';
import { connect } from 'react-redux';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Modal, Button, Input, Form, Select, InputNumber, DatePicker } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import {
  getStates as getStatesAction,
  editPaymentState as editPaymentStateAction,
} from '../../../../actions/paymentState';

import './index.scss';

const { TextArea } = Input;

const EditPaymentState = ({
  info,
  getStates,
  paymentState,
  selectedWork,
  editPaymentState,
  access,
}) => {
  const [state, setState] = useState({
    show: false,
    resourceCount: 1,
    currencyPreffix: '-',
  });

  const { isFetching, states, list } = paymentState;
  const [form] = Form.useForm();
  const { show, saving } = state;

  useEffect(() => {
    if (!paymentState.states && !isFetching) {
      getStates();
    }
  }, [paymentState]);

  useEffect(() => {
    if (!show) {
      form.resetFields();
    }
  }, [show]);

  const submit = (values) => {
    const save = () => {
      setState(update(state, { saving: { $set: true } }));
      const requestBody = {
        ...values,
        ID_EP_ENC: info.ID_EP_ENC,
        ...(values.FECHA_FACTURACION
          ? { FECHA_FACTURACION: values.FECHA_FACTURACION.format('DD-MM-YYYY') }
          : {}),
        FECHA_EP: values.FECHA_EP ? values.FECHA_EP.format('DD-MM-YYYY') : info.FECHA_EP,
        VALOR_CAMBIO_EP: 0,
      };

      const stateUpdates = { saving: { $set: false }, show: { $set: false } };
      editPaymentState(requestBody, selectedWork)
        .then(() => {
          setState(update(state, stateUpdates));
        })
        .catch(() => {
          setState(update(state, stateUpdates));
        });
    };

    if (values.ID_ESTADO_EP && (values.ID_ESTADO_EP === 2 || values.ID_ESTADO_EP === 3)) {
      Modal.confirm({
        title: 'Confirma la Edición',
        icon: <ExclamationCircleOutlined />,
        content: 'No podra volver a editar este estado de pago',
        okText: 'Confirmar',
        cancelText: 'Cancelar',
        onOk: () => save(),
      });
    } else {
      save();
    }
  };
  const onValuesChange = (changedValues) => {
    const { ID_ESTADO_EP } = changedValues;

    if (ID_ESTADO_EP) {
      setState(update(state, { canShowBillingDate: { $set: ID_ESTADO_EP === 3 } }));
    }
  };

  const disable = isFetching || info.ID_ESTADO_EP === 3 || info.ID_ESTADO_EP === 2;
  const enable = isFetching || info.ID_ESTADO_EP === 2;

  return (
    <div className="add-payment-state">
      <div className="button-wrapper">
        {access && !!access.CONTROL_EP_EDITAR_PARAMETROS && (
          <Button
            disabled={access && !access.CONTROL_EP_EDITAR_FACTURADO ? disable : enable}
            loading={isFetching}
            onClick={() =>
              setState(
                update(state, {
                  show: { $set: true },
                })
              )
            }
            type="primary"
            className="new-item-button"
          >
            Editar Parametros EP
          </Button>
        )}
      </div>
      <Modal
        closable={false}
        wrapClassName="common-modal edit-payment-state-modal"
        visible={show}
        width={700}
        onCancel={() => {
          setState(
            update(state, {
              show: { $set: false },
            })
          );
        }}
        footer={null}
        okText="Guardar"
      >
        <div className="modal-container">
          <div className="modal-header">
            <div />
            <span>Edición Parámetros EP</span>
            <i
              className="fa fa-times"
              onClick={() => {
                setState(
                  update(state, {
                    show: { $set: false },
                  })
                );
              }}
            />
          </div>
          <div className="form-body">
            <Form form={form} layout="vertical" onFinish={submit} onValuesChange={onValuesChange}>
              <Form.Item
                initialValue={info.NUMERO_EP}
                label="Número EP"
                name="NUMERO_EP"
                required
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese el número EP',
                  },
                  {
                    validator: async (_obj, value) => {
                      const isRepeatedNumber = list[selectedWork].some(
                        (e) => e.ID_EP_ENC !== info.ID_EP_ENC && e.NUMERO_EP === value
                      );
                      if (isRepeatedNumber) {
                        return Promise.reject(new Error('Ya se esta usando este número EP'));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <InputNumber min={0} />
              </Form.Item>
              <Form.Item
                label="Ingresar Fecha de EP al"
                initialValue={info.FECHA_EP ? moment(info.FECHA_EP, 'DD-MM-YYYY') : null}
                name="FECHA_EP"
                required
                rules={[
                  {
                    required: true,
                    message: 'Por favor seleccione una fecha',
                  },
                  {
                    validator: async (_obj, value) => {
                      if (value) {
                        const isRepeatedDate = list[selectedWork].some(
                          (e) =>
                            e.FECHA_EP === value.format('DD-MM-YYYY') &&
                            e.ID_ESTADO_EP !== 2 &&
                            e.ID_EP_ENC !== info.ID_EP_ENC
                        );
                        if (isRepeatedDate) {
                          return Promise.reject(
                            new Error(
                              'Ya se esta usando esta fecha en otro encabezado de estado de pago'
                            )
                          );
                        }
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <DatePicker placeholder="dd-mm-aaaa" format="DD-MM-YYYY" />
              </Form.Item>
              <Form.Item
                initialValue={info.ID_ESTADO_EP}
                label="Estado EP"
                name="ID_ESTADO_EP"
                required
                rules={[
                  {
                    required: true,
                    message: 'Por favor seleccione un estado',
                  },
                ]}
              >
                <Select loading={isFetching} disabled={isFetching || info.ID_ESTADO_EP === 2}>
                  {(states || []).map(({ ID_ESTADO_EP, NOMBRE_ESTADO_EP }) => (
                    <Select.Option value={ID_ESTADO_EP}>{NOMBRE_ESTADO_EP}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {state.canShowBillingDate && (
                <Form.Item
                  initialValue={
                    info && info.FECHA_FACTURACION
                      ? moment(info.FECHA_FACTURACION, 'DD-MM-YYYY')
                      : moment()
                  }
                  label="Fecha de Facturación"
                  name="FECHA_FACTURACION"
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Por favor seleccione una fecha',
                    },
                  ]}
                >
                  <DatePicker placeholder="dd-mm-aaaa" format="DD-MM-YYYY" />
                </Form.Item>
              )}
              {/* <div className="currency">Moneda: UF</div>
              <Form.Item
                initialValue={info.VALOR_CAMBIO_EP}
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese un valor',
                  },
                ]}
                label="Valor Moneda"
                name="VALOR_CAMBIO_EP"
              >
                <InputNumber formatter={(value) => `$ ${value}`} min={0} />
              </Form.Item> */}
              <Form.Item
                initialValue={info.OBSERVACION_EP}
                label="Comentario"
                name="OBSERVACION_EP"
                required
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese un comentario',
                  },
                ]}
              >
                <TextArea rows={4} />
              </Form.Item>
              <div className="modal-footer">
                <Button
                  disabled={saving}
                  onClick={() =>
                    setState(
                      update(state, {
                        show: { $set: false },
                      })
                    )
                  }
                >
                  Cancelar
                </Button>
                <Button loading={saving} disabled={saving} type="primary" htmlType="submit">
                  Guardar Parámetros EP
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

EditPaymentState.propTypes = {
  info: PropTypes.object.isRequired,
  paymentState: PropTypes.object.isRequired,
  getStates: PropTypes.func.isRequired,
  selectedWork: PropTypes.number.isRequired,
  editPaymentState: PropTypes.func.isRequired,
  access: PropTypes.object.isRequired,
};

export default connect(
  ({ paymentState }) => ({
    paymentState,
  }),
  (dispatch) => ({
    getStates: bindActionCreators(getStatesAction, dispatch),
    editPaymentState: bindActionCreators(editPaymentStateAction, dispatch),
  })
)(EditPaymentState);
