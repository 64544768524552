import update from 'immutability-helper';

export const DELETE_WORKER = 'DELETE_WORKER';
export const GET_WORKERS = 'GET_WORKERS';
export const SET_ERROR_WORKERS = 'SET_ERROR_WORKERS';
export const SET_WORKER = 'SET_WORKER';
export const UPDATE_SUPERVISORS_WORKER = 'UPDATE_SUPERVISORS_WORKER';
export const UPDATE_WORKER = 'UPDATE_WORKER';
export const GET_SUPERVISORS = 'GET_SUPERVISORS';

const initialState = {
  data: null,
  isFetching: true,
  error: '',
  supervisors: null,
};

const workers = (state = initialState, action) => {
  switch (action.type) {
    case SET_ERROR_WORKERS: {
      const { error } = action.payload;
      return update(state, {
        error: { $set: error },
        isFetching: { $set: false },
      });
    }

    case GET_WORKERS: {
      const { workers: data } = action.payload;
      return update(state, {
        data: { $set: data },
        isFetching: { $set: false },
      });
    }

    case SET_WORKER: {
      const { data: newWorker } = action.payload;
      const { data } = state;

      if (data) {
        return update(state, {
          data: { $push: [newWorker] },
        });
      }

      return update(state, {
        data: { $set: [newWorker] },
      });
    }

    case UPDATE_SUPERVISORS_WORKER: {
      return update(state, {
        data: {
          $apply: (data) => {
            let newData = data;
            action.payload.workers.forEach((t) => {
              const index = state.data.findIndex((e) => e.ID_TRA === t.ID_TRA);
              newData = update(newData, {
                [index]: { $merge: { ID_SUP: t.ID_SUP } },
              });
            });
            return newData;
          },
        },
      });
    }

    case UPDATE_WORKER: {
      const { worker } = action.payload;
      const index = state.data.findIndex((e) => e.ID_TRA === worker.ID_TRA);

      return update(state, {
        data: {
          [index]: {
            $merge: worker,
          },
        },
      });
    }

    case DELETE_WORKER: {
      const { workerId } = action.payload;
      const index = state.data.findIndex((e) => e.ID_TRA === workerId);

      return update(state, {
        data: {
          $splice: [[index, 1]],
        },
      });
    }

    case GET_SUPERVISORS: {
      const { supervisors } = action.payload;
      return update(state, {
        supervisors: { $set: supervisors },
      });
    }

    default:
      return state;
  }
};
export default workers;
