import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Calendar, Form, Input, Modal, Radio, Select } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import {
  setCalendar as setCalendarAction,
  updateCalendar as updateCalendarAction,
} from '../../../actions/calendars';
import { normalizeString, normalizeStringToApi } from '../../../utils';
import './index.scss';

moment.updateLocale('en', {
  weekdaysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
});

const CalendarMonth = ({ headerTitle, holidaysMap, initialValue, onClick }) => (
  <Calendar
    fullscreen={false}
    value={moment(initialValue, 'DD/MM/YYYY')}
    disabledDate={(e) => e.month() !== moment(initialValue, 'DD/MM/YYYY').month()}
    headerRender={() => <div className="monthName">{headerTitle}</div>}
    dateCellRender={(value) => {
      const date = moment(value).format('DD/MM/YYYY');
      const holidayInfo = holidaysMap.get(date);

      return (
        <div
          title={`${holidayInfo && holidayInfo.holidayName ? holidayInfo.holidayName : ''}`}
          onClick={() => onClick(date)}
          className={`${
            holidayInfo &&
            holidayInfo.isHoliday &&
            moment(value).month() === moment(initialValue, 'DD/MM/YYYY').month()
              ? 'holiday'
              : ''
          }`}
        >
          {moment(value).month() === moment(initialValue, 'DD/MM/YYYY').month() ? value.date() : ''}
        </div>
      );
    }}
  />
);

CalendarMonth.propTypes = {
  headerTitle: PropTypes.string.isRequired,
  holidaysMap: PropTypes.object.isRequired,
  initialValue: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

const NewHoliday = ({ dateToEdit, holidayToEdit, onClose, onSave }) => {
  const [form] = Form.useForm();

  const closeModalToAddHolidays = () => {
    form.resetFields();
    onClose();
  };

  const submit = (values) => {
    const { holidayDescription, holidayName, isHoliday } = values;
    onSave(holidayDescription, holidayName, isHoliday);
  };

  return (
    <Modal className="newHoliday" visible onCancel={() => closeModalToAddHolidays()} footer={null}>
      <Form layout="vertical" form={form} onFinish={submit}>
        <Form.Item label="Día Seleccionado" name="date" initialValue={dateToEdit}>
          <Input disabled />
        </Form.Item>

        <Form.Item
          name="isHoliday"
          label="Tipo día"
          rules={[
            {
              required: true,
              message: 'Por favor ingrese un tipo de día',
            },
          ]}
          initialValue={Boolean(holidayToEdit.isHoliday)}
        >
          <Radio.Group>
            <Radio value={false}>Laboral</Radio>
            <Radio value>Festivo</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="holidayName"
          label="Nombre"
          initialValue={holidayToEdit.holidayName || ''}
          rules={[
            {
              required: true,
              message: 'Por favor ingrese un nombre',
            },
          ]}
        >
          <Input maxLength={200} />
        </Form.Item>

        <Form.Item
          name="holidayDescription"
          label="Descripción"
          initialValue={holidayToEdit.holidayDescription || ''}
        >
          <Input.TextArea />
        </Form.Item>

        <div className="buttons">
          <Form.Item>
            <Button onClick={() => closeModalToAddHolidays()}>Cancelar</Button>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Aceptar
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

NewHoliday.propTypes = {
  dateToEdit: PropTypes.string.isRequired,
  holidayToEdit: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

const NewCalendar = ({
  calendars,
  infoToEdit,
  onClose,
  selectedWork,
  setCalendar,
  title,
  updateCalendar,
}) => {
  const [year, setYear] = useState(moment().year());
  const [dateToEdit, setDateToEdit] = useState(null);
  const [holidays, setHolidays] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    if (infoToEdit.ID_CALENDARIO) {
      const { CALENDARIO_DIA } = infoToEdit;
      const holidaysInCalendar = [];

      CALENDARIO_DIA.forEach((holiday) => {
        const { DESC_DIA_CALENDARIO, FECHA_DIA, NOMBRE_DIA_CALENDARIO, TIPO_DIA } = holiday;

        holidaysInCalendar.push({
          date: FECHA_DIA,
          holidayDescription: DESC_DIA_CALENDARIO,
          holidayName: NOMBRE_DIA_CALENDARIO,
          isHoliday: TIPO_DIA === 2,
        });
      });

      setHolidays(holidaysInCalendar);
    }
  }, [infoToEdit]);

  const [form] = Form.useForm();

  const handleAddHolidays = (holidayDescription, holidayName, isHoliday) => {
    const index = holidays.findIndex((e) => e.date === dateToEdit);

    setHolidays(
      update(holidays, {
        ...(index === -1
          ? {
              $push: [
                {
                  date: dateToEdit,
                  holidayDescription,
                  holidayName,
                  isHoliday,
                },
              ],
            }
          : {
              [index]: {
                $merge: {
                  holidayDescription,
                  holidayName,
                  isHoliday,
                },
              },
            }),
      })
    );
    setDateToEdit(null);
    setUnsavedChanges(true);
  };

  const holidaysMap = new Map(holidays.map((e) => [e.date, e]));

  const submit = (values) => {
    const { calendarName } = values;

    const CALENDARIO_DIA = [];
    let FERIADOS = 0;

    holidays.forEach((day) => {
      if (day.isHoliday) {
        FERIADOS++;
      }
      CALENDARIO_DIA.push({
        DESC_DIA_CALENDARIO: day.holidayDescription,
        EMPRESA: null,
        FECHA_DIA: day.date,
        ID_CALENDARIO: 0,
        ID_DIA_CALENDARIO: 0,
        NOMBRE_DIA_CALENDARIO: day.holidayName,
        TIPO_DIA: day.isHoliday ? 2 : 1,
        USUARIO: null,
      });
    });

    const data = {
      CALENDARIO_DIA,
      FERIADOS,
      ID_CALENDARIO: infoToEdit.ID_CALENDARIO || 0,
      ID_OBR: selectedWork,
      JORNADAS: 0,
      NOMBRE_CALENDARIO: normalizeStringToApi(calendarName),
    };

    setIsSaving(true);
    if (infoToEdit.ID_CALENDARIO) {
      updateCalendar(data)
        .then(() => {
          onClose();
          setIsSaving(false);
        })
        .catch(() => {
          setIsSaving(false);
        });
    } else {
      setCalendar(data)
        .then(() => {
          onClose();
          setIsSaving(false);
        })
        .catch(() => {
          setIsSaving(false);
        });
    }
  };

  const handleClose = () => {
    if (unsavedChanges) {
      setShowMessage(true);
    } else {
      onClose();
    }
  };

  return (
    <>
      <Modal
        title={title}
        visible
        onCancel={() => handleClose()}
        footer={null}
        width={1200}
        wrapClassName="newCalendar"
      >
        <Form layout="horizontal" form={form} onFinish={submit}>
          <div className="formContainer">
            <div className="form">
              <Form.Item
                name="calendarName"
                label="Nombre Calendario"
                initialValue={infoToEdit.NOMBRE_CALENDARIO || ''}
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese un nombre de calendario',
                  },
                  {
                    validator: async (obj, value) => {
                      if (
                        (!infoToEdit.NOMBRE_CALENDARIO || infoToEdit.NOMBRE_CALENDARIO !== value) &&
                        calendars.some(
                          (e) => normalizeString(e.NOMBRE_CALENDARIO) === normalizeString(value)
                        )
                      ) {
                        return Promise.reject(new Error('Ya existe un calendario con este nombre'));
                      }

                      if (value.length && value.trim().length === 0) {
                        return Promise.reject(new Error('No se permiten solo espacios vacios'));
                      }

                      if (!/^[a-zA-Z\u00C0-\u024F0-9 ]*$/.test(value)) {
                        return Promise.reject(new Error('No se permiten caracteres especiales'));
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input maxLength={200} onChange={() => setUnsavedChanges(true)} />
              </Form.Item>

              <Form.Item name="template" label="Precargar Plantilla Feriados">
                <Select placeholder="Seleccionar...">
                  <Select.Option value={1}>Festivos1</Select.Option>
                  <Select.Option value={2}>Festivos2</Select.Option>
                  <Select.Option value={3}>Festivos3</Select.Option>
                  <Select.Option value={4}>Festivos4</Select.Option>
                  <Select.Option value={5}>Festivos5</Select.Option>
                  <Select.Option value={6}>Festivos6</Select.Option>
                  <Select.Option value={7}>Festivos7</Select.Option>
                </Select>
              </Form.Item>
            </div>
            <div className="buttons">
              <Form.Item>
                <Button disabled={isSaving} onClick={() => handleClose()}>
                  Cancelar
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  disabled={!unsavedChanges || isSaving}
                  loading={isSaving}
                  type="primary"
                  htmlType="submit"
                >
                  Guardar Calendario
                </Button>
              </Form.Item>
            </div>
          </div>
          <div className="yearContainer">
            <LeftOutlined onClick={() => setYear(year - 1)} />
            {year}
            <RightOutlined onClick={() => setYear(year + 1)} />
          </div>
          <div className="monthsContainer">
            {['Enero', 'Febrero', 'Marzo', 'Abril'].map((e, i) => (
              <div className="monthContainer">
                <CalendarMonth
                  headerTitle={e}
                  holidaysMap={holidaysMap}
                  initialValue={`01/${i + 1}/${year}`}
                  onClick={setDateToEdit}
                />
              </div>
            ))}
          </div>
          <div className="monthsContainer">
            {['Mayo', 'Junio', 'Julio', 'Agosto'].map((e, i) => (
              <div className="monthContainer">
                <CalendarMonth
                  headerTitle={e}
                  holidaysMap={holidaysMap}
                  initialValue={`01/${i + 5}/${year}`}
                  onClick={setDateToEdit}
                />
              </div>
            ))}
          </div>
          <div className="monthsContainer">
            {['Septiembre', 'Octubre', 'Noviembre', 'Diciembre'].map((e, i) => (
              <div className="monthContainer">
                <CalendarMonth
                  headerTitle={e}
                  holidaysMap={holidaysMap}
                  initialValue={`01/${i + 9}/${year}`}
                  onClick={setDateToEdit}
                />
              </div>
            ))}
          </div>
        </Form>
      </Modal>
      {dateToEdit && (
        <NewHoliday
          dateToEdit={dateToEdit}
          holidayToEdit={holidaysMap.get(dateToEdit) || {}}
          onClose={() => setDateToEdit(null)}
          onSave={handleAddHolidays}
        />
      )}
      {showMessage && (
        <Modal
          title="Advertencia"
          onOk={() => onClose()}
          onCancel={() => setShowMessage(false)}
          visible
        >
          <p style={{ textAlign: 'center' }}>Tienes cambios sin guardar, ¿quieres salir?</p>
        </Modal>
      )}
    </>
  );
};

NewCalendar.defaultProps = {
  infoToEdit: {},
};

NewCalendar.propTypes = {
  calendars: PropTypes.arrayOf(PropTypes.object).isRequired,
  infoToEdit: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  selectedWork: PropTypes.number.isRequired,
  setCalendar: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  updateCalendar: PropTypes.func.isRequired,
};

export default connect(
  (store) => ({
    calendars: store.calendars.data,
    selectedWork: store.works.selectedWork,
  }),
  (dispatch) => ({
    setCalendar: bindActionCreators(setCalendarAction, dispatch),
    updateCalendar: bindActionCreators(updateCalendarAction, dispatch),
  })
)(NewCalendar);
