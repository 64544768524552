import _ from 'lodash';
import { message } from 'antd';
import api, { getHeaders } from '../utils/api';
import {
  GET_ADVANCE_DATES_IN_ADVANCE_MATRIX,
  GET_ENCLOSURE_TREE_IN_ADVANCE,
  GET_ENCLOSURE_TYPES_IN_ADVANCE,
  GET_ENCLOSURES_AND_ITEMS_IN_ADVANCE,
  GET_ENCLOSURES_IN_ADVANCE,
  GET_ITEMS_IN_ADVANCE,
  GET_ITEMS_TREE,
  SET_ADVANCE,
} from '../reducers/advanceMatrix';
import store from '../store';

export const getEnclosures = () => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR: _.get(store.getState(), 'works.selectedWork', ''),
    };
    const errorMessage = 'No se cargaron éxitosamente los recintos.';
    api
      .post(`/api/EstadoAvance/GetRecintos`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: GET_ENCLOSURES_IN_ADVANCE, payload: { enclosures: res.data } });
          resolve(res.data);
        } else {
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        message.error(errorMessage, 4);
        reject(err);
      });
  });

export const getItems = () => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR: _.get(store.getState(), 'works.selectedWork', ''),
    };
    const errorMessage = 'No se cargaron éxitosamente las partidas.';
    api
      .post(`/api/EstadoAvance/GetPartidas`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: GET_ITEMS_IN_ADVANCE, payload: { items: res.data } });
          resolve(res.data);
        } else {
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        message.error(errorMessage, 4);
        reject(err);
      });
  });

export const getAdvanceDates = () => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR: _.get(store.getState(), 'works.selectedWork', ''),
    };
    const errorMessage = 'No se cargaron éxitosamente las fechas con avance.';
    api
      .post(`/api/EstadoAvance/GetFechaAvance`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: GET_ADVANCE_DATES_IN_ADVANCE_MATRIX,
            payload: { advanceDates: res.data },
          });
          resolve(res.data);
        } else {
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        message.error(errorMessage, 4);
        reject(err);
      });
  });

export const getEnclosuresAndItems = (advanceId) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR: _.get(store.getState(), 'works.selectedWork', ''),
      ID_AVA: advanceId,
    };
    const errorMessage = 'No se cargaron éxitosamente los recintos y partidas.';
    api
      .post(`/api/EstadoAvance/GetRecintosPartidas`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: GET_ENCLOSURES_AND_ITEMS_IN_ADVANCE,
            payload: { enclosuresAndItems: res.data },
          });
          resolve(res.data);
        } else {
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        message.error(errorMessage, 4);
        reject(err);
      });
  });

export const setAdvance = (ID_AVA, ID_INS, ID_UCO, PORCEN_DAV) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR: _.get(store.getState(), 'works.selectedWork', ''),
      ID_AVA,
      ID_INS,
      ID_UCO,
      PORCEN_DAV,
    };
    const errorMessage = 'No se guardo el avance.';
    dispatch({ type: SET_ADVANCE, payload: { ID_INS, ID_UCO, PORCEN_DAV } });

    api
      .post(`/api/EstadoAvance/SetDetalleEA`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200 && res.data.ID_UCO !== 0) {
          resolve(res.data);
        } else {
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        message.error(errorMessage, 4);
        reject(err);
      });
  });

export const getItemsTree = () => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR: _.get(store.getState(), 'works.selectedWork', ''),
    };
    const errorMessage = 'No se cargo éxitosamente el árbol con las partidas.';
    api
      .post(`/api/EstadoAvance/GetPartidasArbol`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: GET_ITEMS_TREE, payload: { itemsTree: res.data } });
          resolve(res.data);
        } else {
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        message.error(errorMessage, 4);
        reject(err);
      });
  });

export const getEnclosuresTree = () => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR: _.get(store.getState(), 'works.selectedWork', ''),
    };
    const errorMessage = 'No se cargo éxitosamente el árbol con las recintos.';
    api
      .post(`/api/EstadoAvance/GetRecintosArbol`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: GET_ENCLOSURE_TREE_IN_ADVANCE, payload: { enclosuresTree: res.data } });
          resolve(res.data);
        } else {
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        message.error(errorMessage, 4);
        reject(err);
      });
  });

export const getEnclosuresTypes = () => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR: _.get(store.getState(), 'works.selectedWork', ''),
    };
    const errorMessage = 'No se cargaron éxitosamente los tipos de recintos.';
    api
      .post(`/api/EstadoAvance/GetRecintosTipos`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: GET_ENCLOSURE_TYPES_IN_ADVANCE,
            payload: { enclosuresTypes: res.data },
          });
          resolve(res.data);
        } else {
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        message.error(errorMessage, 4);
        reject(err);
      });
  });
