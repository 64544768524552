import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Input, Modal, Button, Tooltip, Select, message, Form, Checkbox } from 'antd';
import { TreeList, treeToFlat } from '@progress/kendo-react-treelist';
import { FloatingActionButton } from '@progress/kendo-react-buttons';
import update from 'immutability-helper';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { CopyOutlined } from '@ant-design/icons';
import DoubleLineCell from '../../../components/DoubleLineCell';
import NewWorker from '../../Workers/NewWorker';
import { getDay } from '../../../utils';
import { validateDayValue, validateHourValue, validNumber } from '../../../utils/attendanceHelpers';
import './index.scss';

const isValidValueHour = (attendanceTypeSet, tValue) => {
  if (tValue.length === 0) {
    return true;
  }

  if (tValue.length > 2) {
    return false;
  }
  const valueUC = tValue.toUpperCase();

  return Boolean(validateHourValue(valueUC) || attendanceTypeSet.has(valueUC));
};

const isValidValueDay = (attendanceTypeSet, tValue) => {
  if (tValue.length === 0) {
    return true;
  }

  if (tValue.length > 4) {
    return false;
  }

  if (Number.isNaN(parseFloat(tValue))) {
    const valueUC = tValue.toUpperCase();
    return Boolean(attendanceTypeSet.has(valueUC));
  }
  return Boolean(validateDayValue(tValue));
};

const EditIconCell = ({ setWorkerToEdit, editSupervisorState, ...props }) => {
  const {
    dataItem: { workerId, workerName },
  } = props;
  return (
    <td
      {...props}
      className={`${workerName === 'Resumen' || workerName === '' ? 'headerColor' : ''}`}
    >
      {workerName !== 'Estado Del Día' &&
        workerName !== 'Resumen' &&
        workerName !== '' &&
        workerName !== 'Trabajadores Presentes' &&
        workerName !== 'Total Jornadas Trabajadas' &&
        workerName !== 'Total Horas Trabajadas' &&
        !editSupervisorState && (
          <div className="icons">
            <i className="fa fa-edit edit-icon" onClick={() => setWorkerToEdit(workerId)} />
          </div>
        )}
    </td>
  );
};

EditIconCell.defaultProps = {
  dataItem: {},
};

EditIconCell.propTypes = {
  dataItem: PropTypes.object,
  setWorkerToEdit: PropTypes.func.isRequired,
  editSupervisorState: PropTypes.bool.isRequired,
};

const NameCell = React.memo(
  ({
    handleMassSupervisorUpdate,
    editSupervisorState,
    supervisors,
    massSupervisorUpdate,
    ...props
  }) => {
    const { Option } = Select;
    const { dataItem, dataKey } = props;
    const [valueOption, setValueOption] = useState(dataItem[dataKey]);
    const [pristine, setPristine] = useState(true);
    useEffect(() => {
      if (!pristine) {
        const id = (supervisors.find((sup) => sup.NOMBRE_SUP === valueOption) || { ID_SUP: 0 })
          .ID_SUP;
        handleMassSupervisorUpdate(dataItem.workerId, id);
      }
    }, [pristine, valueOption, setValueOption, dataItem]);

    const valueFromProps = (massSupervisorUpdate.find((e) => e.ID_TRA === dataItem.workerId) || {})
      .ID_SUP;
    return [
      'Resumen',
      'Total Horas Trabajadas',
      'Total Jornadas Trabajadas',
      'Trabajadores Presentes',
      'Estado Del Día',
      '',
    ].includes(dataItem.workerName) ? (
      <td
        {...props}
        className={`${dataItem.workerName === '' ? 'headerColor' : ''}`}
        style={{ paddingLeft: '10px' }}
      >
        {dataItem[dataKey]}
      </td>
    ) : dataKey !== 'supervisorName' || !editSupervisorState ? (
      <td
        {...props}
        style={{ paddingLeft: '10px' }}
        className={`${
          dataKey === 'supervisorName' &&
          dataItem.supervisorName === 'Sin Definir' &&
          'worker-without-supervisor'
        }`}
      >
        {dataItem[dataKey]}
      </td>
    ) : (
      <td {...props} style={{ paddingLeft: '10px' }}>
        <Select
          value={
            valueFromProps !== undefined
              ? valueFromProps
              : (supervisors.find((sup) => sup.NOMBRE_SUP === valueOption) || { ID_SUP: 0 }).ID_SUP
          }
          onChange={(e) => {
            setPristine(false);
            setValueOption(
              e === 0 ? 'Sin Definir' : supervisors.find((sup) => sup.ID_SUP === e).NOMBRE_SUP
            );
          }}
          showSearch
          style={{ width: '100%' }}
          placeholder="Seleccinar Supervisor"
          optionFilterProp="children"
          suffixIcon={
            <CopyOutlined
              className="cloneSupervisor"
              onClick={() => {
                navigator.clipboard.writeText(valueOption);
                message.info('Copiado al Portapapeles', 2);
              }}
            />
          }
        >
          <Option key={0} value={0}>
            Sin Definir
          </Option>
          {supervisors &&
            supervisors.map((sup) => (
              <Option key={sup.ID_SUP} value={sup.ID_SUP}>
                {sup.NOMBRE_SUP}
              </Option>
            ))}
        </Select>
      </td>
    );
  }
);
NameCell.defaultProps = {
  dataItem: {},
  editSupervisorState: null,
  handleMassSupervisorUpdate: () => {},
  supervisors: [],
};

NameCell.propTypes = {
  dataKey: PropTypes.string.isRequired,
  dataItem: PropTypes.object,
  editSupervisorState: PropTypes.bool,
  handleMassSupervisorUpdate: PropTypes.func,
  supervisors: PropTypes.array,
  massSupervisorUpdate: PropTypes.array.isRequired,
};

let newToEdit = [];
const EditCell = ({
  attendanceTypeSet,
  attendanceViewType,
  closedAttendanceSet,
  dataKey,
  editableCell,
  handleAttendance,
  setDayToClosed,
  setDayToOpened,
  setEditableCell,
  ...props
}) => {
  const { dataItem } = props;
  const { workerId, workerName } = dataItem;
  if (workerName === 'Estado Del Día') {
    return (
      <td {...props}>
        <div className="icons">
          <i
            className={`${closedAttendanceSet.has(dataKey) ? 'fa fa-lock' : 'fa fa-unlock'}`}
            onClick={() =>
              !closedAttendanceSet.has(dataKey) ? setDayToClosed(dataKey) : setDayToOpened(dataKey)
            }
            style={{ color: `${closedAttendanceSet.has(dataKey) ? '#808080' : '#007bff'}` }}
          />
        </div>
      </td>
    );
  }

  if (workerName === 'Resumen') {
    return <td {...props} className="headerColor" />;
  }
  if (workerName === '') {
    return <td {...props} className="headerColor" />;
  }

  if (
    workerName === 'Total Horas Trabajadas' ||
    workerName === 'Total Jornadas Trabajadas' ||
    workerName === 'Trabajadores Presentes'
  ) {
    return (
      <Tooltip placement="bottom" title={dataItem[dataKey]}>
        <td {...props}>{dataItem[dataKey]}</td>
      </Tooltip>
    );
  }

  const isEditable = editableCell === `${workerId}-${dataKey}`;

  const [value, setValue] = useState('');
  const [copyValue, setCopyValue] = useState('');
  const inputRef = useRef(null);
  const [spinVisible, setSpinVisible] = useState({ display: 'none' });

  useEffect(() => {
    if (isEditable) {
      inputRef.current.focus();
      setTimeout(() => {
        inputRef.current.select();
      }, 5);
      setValue(dataItem[dataKey]);
    }
  }, [isEditable]);

  if (isEditable) {
    return (
      <td {...props}>
        <div className="inputContainer">
          <Input
            ref={inputRef}
            className="input"
            value={`${attendanceViewType === 1 ? value : copyValue}`}
            onChange={(e) => {
              if (attendanceViewType === 1 && isValidValueHour(attendanceTypeSet, e.target.value)) {
                setValue(e.target.value.toUpperCase());
                // setSpinVisible({ display: 'block' });
              } else if (attendanceViewType === 2) {
                setCopyValue(e.target.value);
                if (isValidValueDay(attendanceTypeSet, e.target.value)) {
                  setValue(
                    Number.isNaN(parseFloat(e.target.value))
                      ? e.target.value.toUpperCase()
                      : e.target.value
                  );
                }
                // setSpinVisible({ display: 'block' });
              }
            }}
            onBlur={() => {
              handleAttendance([dataKey], 'user', value || '0', workerId);
              setEditableCell(null);
            }}
            onPressEnter={() => {
              handleAttendance([dataKey], 'user', value || '0', workerId);
              setEditableCell(null);
            }}
          />
          {/* <Spin indicator={<LoadingOutlined heigth="24" spin />} style={spinVisible} /> */}
        </div>
      </td>
    );
  }

  const cellColor =
    moment(dataKey, 'DD/MM/YYYY').day() === 6 || moment(dataKey, 'DD/MM/YYYY').day() === 0
      ? '#eaeaea'
      : '#fff';

  let backgroundColor = cellColor;
  const color = closedAttendanceSet.has(dataKey) ? '#bebebe' : '#fff';
  const cursor = closedAttendanceSet.has(dataKey) ? 'default' : 'pointer';

  if (dataItem[dataKey]) {
    if (validNumber(dataItem[dataKey])) {
      backgroundColor = '#6aa84f';
    } else {
      backgroundColor = '#007bff';
    }
  }
  return !closedAttendanceSet.has(dataKey) ? (
    <td {...props} style={{ backgroundColor: cellColor }}>
      <div
        onClick={() => {
          if (!closedAttendanceSet.has(dataKey)) {
            if (dataItem[dataKey]) {
              setEditableCell(`${workerId}-${dataKey}`);
            } else {
              if (attendanceViewType === 1) {
                handleAttendance([dataKey], 'user', '9', workerId);
              }
              if (attendanceViewType === 2) {
                handleAttendance([dataKey], 'user', '1', workerId);
              }
            }
          }
        }}
        className="cellWithAttendance"
        style={{ backgroundColor, color, cursor }}
      >
        {dataItem[dataKey]}
      </div>
    </td>
  ) : (
    <Tooltip placement="bottom" title="Día Cerrado">
      <td {...props} style={{ backgroundColor: cellColor }}>
        <div className="cellWithAttendance" style={{ backgroundColor, color, cursor }}>
          {dataItem[dataKey]}
        </div>
      </td>
    </Tooltip>
  );
};

EditCell.defaultProps = {
  dataItem: {},
};

EditCell.propTypes = {
  attendanceTypeSet: PropTypes.object.isRequired,
  attendanceViewType: PropTypes.number.isRequired,
  closedAttendanceSet: PropTypes.object.isRequired,
  dataKey: PropTypes.string.isRequired,
  editableCell: PropTypes.string.isRequired,
  handleAttendance: PropTypes.func.isRequired,
  dataItem: PropTypes.object,
  setDayToClosed: PropTypes.func.isRequired,
  setDayToOpened: PropTypes.func.isRequired,
  setEditableCell: PropTypes.func.isRequired,
};
const SelectCell = React.memo(
  ({
    setStateFlot,
    handleMassSupervisorUpdate,
    editSupervisorState,
    supervisors,
    massSupervisorUpdate,
    ...props
  }) => {
    const { dataItem, dataKey } = props;

    return [
      'Resumen',
      'Total Horas Trabajadas',
      'Total Jornadas Trabajadas',
      'Trabajadores Presentes',
      'Estado Del Día',
      '',
    ].includes(dataItem.workerName) ? (
      <td
        {...props}
        className={`${dataItem.workerName === '' ? 'headerColor' : ''}`}
        style={{ paddingLeft: '10px' }}
      >
        {dataItem[dataKey]}
      </td>
    ) : dataKey !== 'selected' ? (
      <td
        {...props}
        style={{ paddingLeft: '10px' }}
        className={`${
          dataKey === 'selected' && dataItem.selected === 'Sin Definir' && 'worker-without-selected'
        }`}
      >
        {dataItem[dataKey]}
      </td>
    ) : (
      <td {...props} style={{ paddingLeft: '10px' }}>
        <Checkbox
          onChange={(e) => {
            if (e.target.checked) {
              newToEdit.push({
                trabajador: dataItem.workerId,
              });

              setStateFlot(true);
            } else {
              const index = newToEdit.findIndex((a) => a.trabajador === dataItem.workerId);

              if (index > -1) {
                newToEdit = update(newToEdit, {
                  $splice: [[index, 1]],
                });
              }
            }
          }}
          // value={record.DIA}
          // checked={daysToEditSet.has(record.DIA)}
        >
          {/* {record.DIA} */}
        </Checkbox>
      </td>
    );
  }
);
SelectCell.defaultProps = {
  dataItem: {},
  editSupervisorState: null,
  handleMassSupervisorUpdate: () => {},
  supervisors: [],
};

SelectCell.propTypes = {
  dataKey: PropTypes.string.isRequired,
  dataItem: PropTypes.object,
  setStateFlot: PropTypes.func.isRequired,
  editSupervisorState: PropTypes.bool,
  handleMassSupervisorUpdate: PropTypes.func,
  supervisors: PropTypes.array,
  massSupervisorUpdate: PropTypes.array.isRequired,
};

const getInitialState = () => ({
  isEditing: false,
});

const AttendanceTable = ({
  attendanceTypes,
  attendanceViewType,
  closedAttendance,
  dataTable,
  daysInMonth,
  editSupervisorState,
  handleAttendance,
  handleClosedAttendance,
  handleOpenedAttendance,
  loading,
  month,
  nameWork,
  supervisors,
  handleMassSupervisorUpdate,
  viewOptions,
  workers,
  year,
  massSupervisorUpdate,
  savingExcel,
  setState,
  state,
}) => {
  const [workerToEdit, setWorkerToEdit] = useState(null);
  const [editableCell, setEditableCell] = useState('');
  const [dayToClosed, setDayToClosed] = useState(null);
  const [dayToOpened, setDayToOpened] = useState(null);
  const closedAttendanceSet = new Set(closedAttendance);
  const attendanceTypeSet = new Set(attendanceTypes.map((e) => e.ABREVIA_CNS));
  const viewOptionsSet = new Set(viewOptions.filter((e) => e.value).map((e) => e.name));
  const [showChapterForm, setShowChapterForm] = useState(false);
  const [stateFlot, setStateFlot] = useState(false);

  const modalAttendence = (day, setDayFunction, handleAttendanceFunction) => {
    return (
      <Modal
        title={`Día: ${day}`}
        visible
        onCancel={() => setDayFunction(null)}
        footer={null}
        width={350}
      >
        <div className="dayToClosed-modal">
          <div>{`${
            closedAttendanceSet.has(day)
              ? '¿Confirma la apertura de asistencia?'
              : 'Luego de cerrar la asistencia ésta no se puede modificar'
          }`}</div>
          <div className="buttons-selection">
            <Button type="default" onClick={() => setDayFunction(null)}>
              Cancelar
            </Button>
            <Button
              onClick={() => {
                handleAttendanceFunction(day.replaceAll('/', '-'));
                setDayFunction(null);
              }}
              type="primary"
            >
              {`${closedAttendanceSet.has(day) ? 'Abrir Asistencia' : 'Cerrar Asistencia'}`}
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  const columnsB = () => {
    return [
      //  {
      //         field: 'selected',
      //         title:'',
      //         width: 40,
      //         cell:(props)=>(
      //            <SelectCell
      //             {...props}
      //               dataKey="selected"
      //               setStateFlot={setStateFlot}
      //               editSupervisorState={editSupervisorState}
      //               handleMassSupervisorUpdate={handleMassSupervisorUpdate}
      //               supervisors={supervisors}
      //               massSupervisorUpdate={massSupervisorUpdate}
      //         />
      //         )
      //       },
      {
        sortable: false,
        filterable: false,
        field: '',
        title: '',
        // locked: true,
        width: '50px',
        cell: (props) => {
          return (
            <EditIconCell
              {...props}
              setWorkerToEdit={setWorkerToEdit}
              editSupervisorState={editSupervisorState}
            />
          );
        },
      },
      {
        field: 'workerName',
        title: 'Nombre Trabajador',
        width: '280px',
      },
      ...(viewOptionsSet.has('Mostrar Rut')
        ? [
            {
              field: 'rut',
              title: 'Rut',
              width: 80,
            },
          ]
        : []),
      ...(viewOptionsSet.has('Mostrar Área')
        ? [
            {
              field: 'department',
              title: 'Área',
              width: 40,
            },
          ]
        : []),
      ...(viewOptionsSet.has('Mostrar Cargo')
        ? [
            {
              field: 'position',
              title: 'Cargo',
              width: 180,
            },
          ]
        : []),
      ...(viewOptionsSet.has('Mostrar Supervisor')
        ? [
            {
              field: '',
              title: 'Supervisor',
              width: 180,
              cell: (props) => (
                <NameCell
                  {...props}
                  dataKey="supervisorName"
                  editSupervisorState={editSupervisorState}
                  handleMassSupervisorUpdate={handleMassSupervisorUpdate}
                  supervisors={supervisors}
                  massSupervisorUpdate={massSupervisorUpdate}
                />
              ),
            },
          ]
        : []),
      ...(viewOptionsSet.has('Mostrar Inicio Contrato')
        ? [
            {
              field: 'startDate',
              title: 'Fecha Inicio',
              width: 80,
            },
          ]
        : []),
      ...(viewOptionsSet.has('Mostrar Término Contrato')
        ? [
            {
              field: 'endDate',
              title: 'Fecha Término',
              width: 120,
            },
          ]
        : []),
      ...daysInMonth.map((day) => {
        return {
          // title: `${moment(day).format('DD/MM/YYYY')}`,
          field: String(moment(day).format('DD/MM/YYYY')),
          title: (
            <DoubleLineCell firstValue={String(getDay(day))} secondValue={String(day.getDate())} />
          ),
          width: '32px',
          cell: (props) => (
            <EditCell
              {...props}
              attendanceTypeSet={attendanceTypeSet}
              attendanceViewType={attendanceViewType}
              closedAttendanceSet={closedAttendanceSet}
              dataKey={`${moment(day).format('DD/MM/YYYY')}`}
              handleAttendance={handleAttendance}
              editableCell={editableCell}
              setDayToClosed={setDayToClosed}
              setDayToOpened={setDayToOpened}
              setEditableCell={setEditableCell}
            />
          ),
        };
      }),
    ];
  };

  const tableColumns = React.useMemo(() => columnsB());
  let _export;
  //   const {
  //   savingExcel,

  // } = state;

  const finalTableData = dataTable;

  useEffect(() => {
    if (savingExcel) {
      setTimeout(() => {
        _export.save(treeToFlat(finalTableData, 'expanded', 'children'), tableColumns);
      }, 500);
    }
  }, [savingExcel]);

  const exportToExcel = () => {
    setState(update(state, { savingExcel: { $set: true } }));
  };
  const [form] = Form.useForm();

  const submit = (values) => {
    newToEdit.forEach((a) => {
      handleMassSupervisorUpdate(a.trabajador, values.supervisor);
    });
    newToEdit = [];
    setShowChapterForm(false);
  };

  const handleClick = () => {
    setShowChapterForm(true);
  };

  return (
    <div className="attendanceTable">
      <ExcelExport
        // eslint-disable-next-line no-return-assign
        ref={(exporter) => (_export = exporter)}
        hierarchy
        onExportComplete={() => setState(update(state, { savingExcel: { $set: false } }))}
      >
        <TreeList
          className="common-table text-align-center "
          height={window.innerHeight - window.innerHeight * 0.13}
          style={{
            height: `${window.innerHeight - 100}px`,
            overflow: 'auto',
            width: `${window.innerWidth - 20}px`,
          }}
          resizable
          tableProps={{
            style: {
              tableLayout: 'fixed',
            },
          }}
          rowHeight={28}
          scrollable="virtual"
          locale={{
            emptyMessage: 'No hay datos para mostrar',
            loading: 'Guardando...',
          }}
          columns={tableColumns}
          data={dataTable}
          // data={extendData(dataState, selectedState, expandedState)}
          // selectedField={SELECTED_FIELD}
          // expandField={EXPAND_FIELD}
          // subItemsField={SUB_ITEMS_FIELD}
          // dataItemKey={DATA_ITEM_KEY}
          // onSelectionChange={onSelectionChange}
          // onHeaderSelectionChange={onHeaderSelectionChange}
          // onExpandChange={onExpandChange}
        />
      </ExcelExport>
      {dayToClosed && modalAttendence(dayToClosed, setDayToClosed, handleClosedAttendance)}
      {dayToOpened && modalAttendence(dayToOpened, setDayToOpened, handleOpenedAttendance)}
      {workerToEdit && (
        <NewWorker
          infoToEdit={workers.find((e) => e.ID_TRA === workerToEdit)}
          onClose={() => setWorkerToEdit(null)}
          supervisors={supervisors}
          title="Editar Trabajador"
        />
      )}
      <FloatingActionButton
        icon="check"
        text="Asignar Supervisor"
        style={{
          display: stateFlot ? '' : 'none',
        }}
        onClick={handleClick}
      />
      <Modal
        title="Asignación de Supervisor"
        visible={showChapterForm}
        onOk={null}
        onCancel={() => setShowChapterForm(false)}
        width={400}
        footer={null}
        wrapClassName="massAttendance"
      >
        <Form layout="vertical" form={form} onFinish={submit}>
          <Form.Item name="supervisor" label="Supervisor" initialValue="">
            <Select placeholder="Seleccionar...">
              {supervisors.map((supervisor) => (
                <Select.Option value={supervisor.ID_SUP} key={`supervisor-${supervisor.ID_SUP}`}>
                  {supervisor.NOMBRE_SUP}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <div className="buttons">
            <Form.Item>
              <Button>Cancelar</Button>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Asignar Supervisor
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

AttendanceTable.propTypes = {
  attendanceTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  attendanceViewType: PropTypes.number.isRequired,
  closedAttendance: PropTypes.arrayOf(PropTypes.string).isRequired,
  dataTable: PropTypes.arrayOf(PropTypes.object).isRequired,
  daysInMonth: PropTypes.array.isRequired,
  editSupervisorState: PropTypes.bool.isRequired,
  handleAttendance: PropTypes.func.isRequired,
  handleClosedAttendance: PropTypes.func.isRequired,
  handleOpenedAttendance: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  savingExcel: PropTypes.bool.isRequired,
  month: PropTypes.number.isRequired,
  nameWork: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  supervisors: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleMassSupervisorUpdate: PropTypes.func.isRequired,
  viewOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  workers: PropTypes.arrayOf(PropTypes.object).isRequired,
  year: PropTypes.number.isRequired,
  massSupervisorUpdate: PropTypes.func.isRequired,
  setState: PropTypes.func.isRequired,
};

export default AttendanceTable;
