import update from 'immutability-helper';

export const DELETE_SCHEDULE = 'DELETE_SCHEDULE';
export const GET_SCHEDULE = 'GET_SCHEDULE';
export const SET_COLLATIONS_TYPE = 'SET_COLLATIONS_TYPE';
export const SET_ERROR_SCHEDULE = 'SET_ERROR_SCHEDULE';
export const SET_INITIAL_VALUES_IN_SCHEDULE = 'SET_INITIAL_VALUES_IN_SCHEDULE';
export const SET_SCHEDULE = 'SET_SCHEDULE';
export const UPDATE_SCHEDULE = 'UPDATE_SCHEDULE';

const initialState = {
  data: null,
  isFetching: true,
  error: '',
  collationsType: null,
};

const schedule = (state = initialState, action) => {
  switch (action.type) {
    case SET_COLLATIONS_TYPE: {
      const { data } = action.payload;

      return update(state, {
        collationsType: { $set: data },
      });
    }

    case GET_SCHEDULE: {
      const { data } = action.payload;
      return update(state, {
        data: { $set: data },
        isFetching: { $set: false },
      });
    }

    case SET_ERROR_SCHEDULE: {
      const { error } = action.payload;
      return update(state, {
        error: { $set: error },
        isFetching: { $set: false },
      });
    }

    case SET_INITIAL_VALUES_IN_SCHEDULE: {
      return update(state, {
        data: { $set: null },
        error: { $set: '' },
        isFetching: { $set: true },
      });
    }

    case SET_SCHEDULE: {
      const { newSchedule } = action.payload;
      const { data } = state;

      if (data) {
        return update(state, {
          data: { $push: [newSchedule] },
        });
      }

      return update(state, {
        data: { $set: [newSchedule] },
      });
    }

    case DELETE_SCHEDULE: {
      const { scheduleId } = action.payload;
      const index = state.data.findIndex((e) => e.ID_JORNADA === scheduleId);

      return update(state, {
        data: {
          $splice: [[index, 1]],
        },
      });
    }

    case UPDATE_SCHEDULE: {
      const { data } = action.payload;
      const index = state.data.findIndex((e) => e.ID_JORNADA === data.ID_JORNADA);

      return update(state, {
        data: {
          [index]: {
            $merge: data,
          },
        },
      });
    }

    default:
      return state;
  }
};
export default schedule;
