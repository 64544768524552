import update from 'immutability-helper';
import { SET_SCHEDULE } from './schedule';

export const DELETE_CALENDAR = 'DELETE_CALENDAR';
export const GET_CALENDARS = 'GET_CALENDARS';
export const SET_CALENDAR = 'SET_CALENDAR';
export const SET_ERROR_CALENDARS = 'SET_ERROR_CALENDARS';
export const SET_INITIAL_VALUES_IN_CALENDAR = 'SET_INITIAL_VALUES_IN_CALENDAR';
export const UPDATE_CALENDAR = 'UPDATE_CALENDAR';

const initialState = {
  data: null,
  isFetching: true,
  error: '',
};

const workers = (state = initialState, action) => {
  switch (action.type) {
    case SET_ERROR_CALENDARS: {
      const { error } = action.payload;
      return update(state, {
        error: { $set: error },
        isFetching: { $set: false },
      });
    }

    case GET_CALENDARS: {
      const { calendars: data } = action.payload;
      return update(state, {
        data: { $set: data },
        isFetching: { $set: false },
      });
    }

    case SET_INITIAL_VALUES_IN_CALENDAR: {
      return initialState;
    }

    case SET_CALENDAR: {
      const { newCalendar } = action.payload;
      const { data } = state;

      if (data) {
        return update(state, {
          data: { $push: [newCalendar] },
        });
      }

      return update(state, {
        data: { $set: [newCalendar] },
      });
    }

    case UPDATE_CALENDAR: {
      const { data } = action.payload;
      const index = state.data.findIndex((e) => e.ID_CALENDARIO === data.ID_CALENDARIO);

      return update(state, {
        data: {
          [index]: {
            $merge: data,
          },
        },
      });
    }

    case DELETE_CALENDAR: {
      const { calendarId } = action.payload;
      const index = state.data.findIndex((e) => e.ID_CALENDARIO === calendarId);

      return update(state, {
        data: {
          $splice: [[index, 1]],
        },
      });
    }

    case SET_SCHEDULE: {
      const {
        newSchedule: { ID_CALENDARIO },
      } = action.payload;
      const index = state.data.findIndex((e) => e.ID_CALENDARIO === ID_CALENDARIO);

      return update(state, {
        data: {
          [index]: {
            JORNADAS: {
              $set: state.data[index].JORNADAS + 1,
            },
          },
        },
      });
    }

    default:
      return state;
  }
};
export default workers;
