import React, { useState } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { Modal, Button, Switch } from 'antd';
import './index.scss';

const ViewOptions = ({ onClose, onSave, options }) => {
  const [optionsInForm, setOptionsInForm] = useState(options);

  return (
    <Modal title="Opciones de vista" visible onCancel={() => onClose()} footer={null} width={280}>
      <div className="viewOptions">
        <div className="options-container">
          <div className="options">
            {optionsInForm.map((option, index) => (
              <div className="switch-row" key={`option-${option.name}`}>
                {option.name}
                <Switch
                  className="switch"
                  checked={option.value}
                  onChange={(checked) =>
                    setOptionsInForm(
                      update(optionsInForm, { [index]: { value: { $set: checked } } })
                    )
                  }
                />
              </div>
            ))}
          </div>
          <div className="buttons-options">
            <Button type="default" onClick={() => onClose()}>
              Cancelar
            </Button>
            <Button
              onClick={() => {
                onSave(optionsInForm);
                onClose();
              }}
              type="primary"
            >
              Aplicar
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

ViewOptions.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ViewOptions;
