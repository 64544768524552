import React from 'react';
import _ from 'lodash';
import { Column, HeaderCell, Cell, ColumnGroup } from 'rsuite-table';
import PropTypes from 'prop-types';
import { Select, Tooltip } from 'antd';
import DoubleLineCell from '../../components/DoubleLineCell';
import EditableCell from '../../components/EditableCell/Production';
import { hiddeCellRender } from '../../utils/budgetAndProductionHelpers';
import { getFormattedNumber } from '../../utils';

import './index.scss';

export const getTotals = (record, key = 'CANTIDAD_VIGENTE', priceKey = 'PRECIO_ITEM_VENTA') => {
  let total = record.ID_NAT === 3 ? record[key] * record[priceKey] : 0;
  if (record.ID_NAT !== 3 && record.children && record.children.length) {
    total = record.children.reduce((acc, childrenRecord) => {
      return acc + getTotals(childrenRecord, key, priceKey);
    }, 0);
  }
  return total;
};

const SelectCell = ({
  editable,
  rowData,
  handleSave,
  options,
  loading,
  typesDataObj,
  typesData,
  ...rest
}) => {
  const { ID_NAT } = rowData;
  if (ID_NAT !== 3) return null;

  const onChange = (value) => {
    const dataToEdit = {
      ID_ITEM: rowData.ID_ITEM,
      PATH: rowData.PATH,
      COMENTARIO_PNF: rowData.COMENTARIO_PNF,
      ID_TIPO_PNF: value || 0,
    };
    handleSave([dataToEdit]);
  };
  const text =
    typesDataObj && rowData.ID_TIPO_PNF !== 0
      ? typesDataObj[rowData.ID_TIPO_PNF].NOMBRE_TIPO_PNF
      : '';

  const textIndex =
    typesData && rowData.ID_TIPO_PNF !== 0
      ? typesData.findIndex((e) => e.ID_TIPO_PNF === rowData.ID_TIPO_PNF)
      : null;
  return (
    <Cell width={320} dataKey="TIPO" className="switch-cell" {...rest}>
      {editable ? (
        <Select
          allowClear
          disabled={loading}
          value={rowData.ID_TIPO_PNF || null}
          loading={loading}
          className={`type-select ${editable && 'editable'}`}
          onChange={onChange}
        >
          {options.map(({ label, value }) => (
            <Select.Option value={value}>{label}</Select.Option>
          ))}
        </Select>
      ) : (
        <Tooltip placement="topLeft" destroyTooltipOnHide={{ keepParent: false }} title={text}>
          <span className="value">Nivel {textIndex + 2}</span>
        </Tooltip>
      )}
    </Cell>
  );
};

SelectCell.propTypes = {
  rowData: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  handleSave: PropTypes.func.isRequired,
  helpers: PropTypes.shape({
    changeExpandedRows: PropTypes.func,
  }).isRequired,
  options: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  typesDataObj: PropTypes.object.isRequired,
  typesData: PropTypes.array.isRequired,
};

const columns = (activeOptions, activeEdition, handleSave, helpers) => {
  const canEdit = activeEdition === 'editPNF';
  const columnsResult = [
    <Column
      resizable
      fixed
      verticalAlign="middle"
      align="center"
      width={90}
      className="border-right"
    >
      <HeaderCell>Ítem </HeaderCell>
      <Cell dataKey="CODIGO_ITEM" />
    </Column>,
    <Column
      resizable
      fixed
      treeCol
      verticalAlign="middle"
      align="left"
      width={350}
      className="border-right"
    >
      <HeaderCell>Descripción </HeaderCell>
      <Cell dataKey="NOMBRE_ITEM" />
    </Column>,
    <Column
      resizable
      fixed
      verticalAlign="middle"
      align="center"
      width={90}
      className="border-right"
    >
      <HeaderCell>PU Venta</HeaderCell>
      <Cell dataKey="PRECIO_ITEM_VENTA" className="align-right">
        {(record) => hiddeCellRender(record.PRECIO_ITEM_VENTA, record, true, true, null)}
      </Cell>
    </Column>,
    <Column
      resizable
      fixed
      verticalAlign="middle"
      align="center"
      width={70}
      className="border-right"
    >
      <HeaderCell>Ud</HeaderCell>
      <Cell dataKey="NOMABR_UNI">{(record) => hiddeCellRender(record.NOMABR_UNI, record)}</Cell>
    </Column>,
    <ColumnGroup align="center" header="Contrato Vigente" width={300}>
      {[
        ...(activeOptions.option0
          ? [
              <Column
                resizable
                verticalAlign="middle"
                width={130}
                className={!activeOptions.option1 ? 'border-right' : ''}
              >
                <HeaderCell>Cantidad</HeaderCell>
                <Cell className="align-right">
                  {(record) => hiddeCellRender(record.CANTIDAD_VIGENTE, record, true, false, 1)}
                </Cell>
              </Column>,
            ]
          : []),
        ...(activeOptions.option1
          ? [
              <Column resizable verticalAlign="middle" align="center" width={140}>
                <HeaderCell>Total Venta</HeaderCell>
                <Cell dataKey="TOTAL_VENTA" className="align-right border-right">
                  {({ CANTIDAD_VIGENTE, PRECIO_ITEM_VENTA }) => {
                    const total = CANTIDAD_VIGENTE * PRECIO_ITEM_VENTA;
                    return (
                      <span title={`$${getFormattedNumber(total)}`}>{`$${getFormattedNumber(
                        total,
                        null,
                        true
                      )}`}</span>
                    );
                  }}
                </Cell>
              </Column>,
            ]
          : []),
      ]}
    </ColumnGroup>,
    <ColumnGroup align="center" header="Contrato Forecast" width={300}>
      {[
        ...(activeOptions.option0
          ? [
              <Column
                resizable
                verticalAlign="middle"
                width={130}
                className={!activeOptions.option1 ? 'border-right' : ''}
              >
                <HeaderCell>Cantidad</HeaderCell>
                <Cell className="align-right">
                  {(record) => hiddeCellRender(record.CANTIDAD_MOD, record, true, false, 1)}
                </Cell>
              </Column>,
            ]
          : []),
        ...(activeOptions.option1
          ? [
              <Column resizable verticalAlign="middle" align="center" width={140}>
                <HeaderCell>Total Venta</HeaderCell>
                <Cell dataKey="TOTAL_VENTA" className="align-right border-right">
                  {({ CANTIDAD_MOD, PRECIO_ITEM_VENTA }) => {
                    const total = CANTIDAD_MOD * PRECIO_ITEM_VENTA;
                    return (
                      <span title={`$${getFormattedNumber(total)}`}>{`$${getFormattedNumber(
                        total,
                        null,
                        true
                      )}`}</span>
                    );
                  }}
                </Cell>
              </Column>,
            ]
          : []),
      ]}
    </ColumnGroup>,
    <ColumnGroup
      align="center"
      header={
        <DoubleLineCell
          firstValue="Producción Acum. al"
          secondValue={_.get(helpers, 'data.FECHA_AVA', ' ...')}
        />
      }
    >
      {[
        ...(activeOptions.option0
          ? [
              <Column
                resizable
                verticalAlign="middle"
                width={130}
                className={!activeOptions.option1 ? 'border-right' : ''}
              >
                <HeaderCell>Cantidad</HeaderCell>
                <Cell className="align-right">
                  {(record) => hiddeCellRender(record.CANT_AVA, record, true, false, 1)}
                </Cell>
              </Column>,
            ]
          : []),
        ...(activeOptions.option1
          ? [
              <Column resizable verticalAlign="middle" align="center" width={160}>
                <HeaderCell>Total Venta</HeaderCell>
                <Cell dataKey="TOTAL_ACUM" className="align-right border-right">
                  {(record) => {
                    const total = `${getTotals(record, 'CANT_AVA')}`;
                    return (
                      <span title={`$${getFormattedNumber(total)}`}>{`$${getFormattedNumber(
                        total,
                        null,
                        true
                      )}`}</span>
                    );
                  }}
                </Cell>
              </Column>,
            ]
          : []),
      ]}
    </ColumnGroup>,
    <ColumnGroup
      align="center"
      header={
        <DoubleLineCell
          firstValue="Estado de Pago Acum."
          secondValue={` Presentado al ${_.get(helpers, 'data.FECHA_EP', ' ...')}`}
        />
      }
    >
      {[
        ...(activeOptions.option0
          ? [
              <Column
                resizable
                verticalAlign="middle"
                width={150}
                className={!activeOptions.option1 ? 'border-right' : ''}
              >
                <HeaderCell>Cantidad</HeaderCell>
                <Cell className="align-right">
                  {(record) => hiddeCellRender(record.CANT_EP, record, true, false, 1)}
                </Cell>
              </Column>,
            ]
          : []),
        ...(activeOptions.option1
          ? [
              <Column resizable verticalAlign="middle" align="center" width={160}>
                <HeaderCell>Total Venta</HeaderCell>
                <Cell className="align-right border-right">
                  {(record) => {
                    const total = getTotals(record, 'CANT_EP');
                    return (
                      <span title={`$${getFormattedNumber(total)}`}>{`$${getFormattedNumber(
                        total,
                        null,
                        true
                      )}`}</span>
                    );
                  }}
                </Cell>
              </Column>,
            ]
          : []),
      ]}
    </ColumnGroup>,
    <ColumnGroup align="center" header="PNF Nivel 2,3,4">
      {[
        <Column resizable verticalAlign="middle" width={140}>
          <HeaderCell>Avance acum vs EDP </HeaderCell>
          <Cell className="align-right">
            {(record) => {
              if (record.ID_NAT === 1) return null;
              return getFormattedNumber(record.CANT_TOTAL, 1);
            }}
          </Cell>
        </Column>,
        <Column resizable verticalAlign="middle" align="center" width={140}>
          <HeaderCell>Total PNF</HeaderCell>
          <Cell dataKey="CANT_TOTAL" className="align-right">
            {(record) => {
              const total = getTotals(record, 'CANT_TOTAL');
              return (
                <span title={getFormattedNumber(total, null, null, '$')}>
                  {getFormattedNumber(total, null, true, '$')}
                </span>
              );
            }}
          </Cell>
        </Column>,
        <Column resizable verticalAlign="middle" align="center" width={140}>
          <HeaderCell>
            <Tooltip
              placement="topLeft"
              destroyTooltipOnHide={{ keepParent: false }}
              title={
                helpers.typesData && (
                  <ul>
                    {helpers.typesData.map((current) => {
                      return <li>{`Nivel ${current.NOMBRE_TIPO_PNF}`}</li>;
                    })}
                  </ul>
                )
              }
            >
              <i className="fas fa-info-circle info-icon mr-2" />
            </Tooltip>
            Tipo
          </HeaderCell>
          <SelectCell
            handleSave={handleSave}
            editable={canEdit}
            typesDataObj={helpers.typesDataObj}
            typesData={helpers.typesData}
            loading={helpers.isFetchingTypes}
            options={(helpers.typesData || []).map(({ ID_TIPO_PNF }, i) => ({
              label: `Nivel ${i + 2}`,
              value: ID_TIPO_PNF,
            }))}
          />
        </Column>,
        <Column resizable verticalAlign="middle" width={250}>
          <HeaderCell align="center">Comentario</HeaderCell>
          <EditableCell
            dataIndex="COMENTARIO_PNF"
            editable={canEdit}
            handleSave={handleSave}
            includeRecordFields={['ID_TIPO_PNF']}
            type="string"
            canShow={(record) => record.ID_NAT !== 1}
            conditionToEditable={(record) => record.ID_NAT === 3}
            className={() => canEdit && 'editable'}
          />
        </Column>,
      ]}
    </ColumnGroup>,
  ];
  return columnsResult;
};

export default columns;
