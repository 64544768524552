/* eslint-disable no-restricted-globals */
import _ from 'lodash';
import { message } from 'antd';

import api, { getHeaders } from '../utils/api';
import store from '../store';

import {
  PROJECTS_FETCHED,
  GET_PROJECTS,
  GET_PROJECTS_ERROR,
  ADD_NEW_PROJECT,
  EDIT_PROJECT,
  INACTIVE_OR_CLOSE_PROJECT,
} from '../reducers/projects.js';

export const addNewProject = (project) => ({
  type: ADD_NEW_PROJECT,
  project,
});

export const setProjects = (projects) => ({
  type: PROJECTS_FETCHED,
  projects,
});

export const editProject = (project) => ({
  type: EDIT_PROJECT,
  project,
});

export const error = () => ({
  type: GET_PROJECTS_ERROR,
});

export const getProjects = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: GET_PROJECTS });
    const requestBody = {
      usuario: localStorage.getItem('foco_username'),
      ip: '1.1.1.1',
      dispositivo: 'web',
      empresa: _.get(store.getState(), 'auth.user.client', ''),
    };
    api
      .post(`/api/ParametrosGenerales/GetProyectos`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch(setProjects(res.data));
          resolve(res.data);
        } else {
          dispatch(error());
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(error());
        reject(err);
      });
  });

export const saveProject = (projectData, action) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      ...projectData,
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
    };

    const errorMessage = `Error al ${action === 'create' ? 'crear' : 'editar'} el proyecto`;
    api
      .post(
        `/api/ParametrosGenerales/${action === 'create' ? 'SetProyecto' : 'UpdProyecto'}`,
        requestBody,
        getHeaders()
      )
      .then((res) => {
        if (res.status === 200 && !_.isEmpty(res.data)) {
          const { ID_OBR, PROYECTO_PARAMETRO } = res.data;
          if (action === 'create') {
            const { contractCurrency, contractType, communes, companies } = store.getState();
            const data = {
              ...projectData,
              VIGENTE_OBR: '1',
              ...(projectData.ID_EMP
                ? {
                    NOMBRE_EMP: companies.data.find(({ ID_EMP }) => ID_EMP === projectData.ID_EMP)
                      .NOMBRE_EMP,
                  }
                : {}),
              ...(projectData.ID_COMUNA
                ? {
                    NOMBRE_COMUNA: communes.data.find(
                      ({ ID_COMUNA }) => ID_COMUNA === projectData.ID_COMUNA
                    ).NOMBRE_COMUNA,
                  }
                : {}),
              ...(projectData.ID_TIPO_CONTRATO
                ? {
                    NOMBRE_TIPO_CONTRATO: contractType.data.find(
                      ({ ID_TIPO_CONTRATO }) => ID_TIPO_CONTRATO === projectData.ID_TIPO_CONTRATO
                    ).NOMBRE_TIPO_CONTRATO,
                  }
                : {}),
              ...(projectData.ID_MONEDA_CONTRATO
                ? {
                    NOMBRE_MONEDA_CONTRATO: contractCurrency.data.find(
                      ({ ID_MONEDA_CONTRATO }) =>
                        ID_MONEDA_CONTRATO === projectData.ID_MONEDA_CONTRATO
                    ).NOMBRE_MONEDA_CONTRATO,
                  }
                : {}),
            };
            dispatch(addNewProject({ ...data, ID_OBR, PROYECTO_PARAMETRO }));
          } else if (action === 'edit') {
            dispatch(
              editProject({
                ...projectData,
                PROYECTO_PARAMETRO,
              })
            );
          }
          message.success(
            `Proyecto ${action === 'create' ? 'agregado' : 'editado'} correctamente`,
            4
          );

          resolve();
        } else {
          message.error(errorMessage, 4);
          reject();
        }
      })
      .catch(() => {
        message.error(errorMessage, 4);
        reject();
      });
  });

export const inactiveOrCloseProject = (ID_OBR, code) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: localStorage.getItem('foco_username'),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_OBR,
      VIGENTE_OBR: code,
    };
    api
      .post(`/api/ParametrosGenerales/UpdEstadoProyecto`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200 && res.data === 'OK') {
          message.success('Proyecto actualizado correctamente', 4);
          dispatch({
            type: INACTIVE_OR_CLOSE_PROJECT,
            project: {
              ID_OBR,
              code,
            },
          });
          resolve();
        } else {
          message.error('Error al editar el Proyecto', 4);
          reject();
        }
      })
      .catch(() => {
        message.error('Error al editar el Proyecto', 4);
        reject();
      });
  });
