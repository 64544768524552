import update from 'immutability-helper';

export const SET_ERROR_ENCLOSURE = 'SET_ERROR_ENCLOSURE';
export const GET_LISTADO_PANEL = 'GET_LISTADO_PANEL';
export const GET_USER = 'GET_USER';

const initialState = {
  isPanel: null,
  isUser: null,
  isFetching: true,
  error: '',
};

const usercontrol = (state = initialState, action) => {
  switch (action.type) {
    case SET_ERROR_ENCLOSURE: {
      const { error } = action.payload;
      return update(state, {
        error: { $set: error },
        isFetching: { $set: false },
      });
    }

    case GET_LISTADO_PANEL: {
      const { isPanel } = action.payload;
      return update(state, {
        isPanel: { $set: isPanel },
        isFetching: { $set: false },
      });
    }
    case GET_USER: {
      const { isUser } = action.payload;
      return update(state, {
        isUser: { $set: isUser },
        isFetching: { $set: false },
      });
    }
    default:
      return state;
  }
};
export default usercontrol;
