import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { v4 as uuidv4, validate } from 'uuid';
import isEmpty from 'lodash/isEmpty';
import { Row, Col, Button } from 'antd';
import 'moment/locale/es';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import update from 'immutability-helper';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import Table from '../../components/TableExtend';
import { getUnits as getUnitsAction } from '../../actions/budget';
import ViewOptions from '../../components/ViewOptions';

import {
  getBaseTender as getBaseTenderAction,
  setBaseTender as setBaseTenderAction,
  addMonthTender as addMonthTenderAction,
  getInputsTypesTender as getInputsTypesTenderAction,
  deleteRowTender as deleteRowTenderAction,
} from '../../actions/TenderGG';

import columns from './columns';
import { getChildren, getNodeObjectToUpdate } from '../../utils/budgetAndProductionHelpers';

const sortSelectArrays = (key) => (a, b) => {
  if (a[key] < b[key]) {
    return -1;
  }
  if (a[key] > b[key]) {
    return 1;
  }
  return 0;
};

const TenderGG = ({
  selectedWork,
  baseTender,
  getBaseTender,
  setBaseTender,
  deleteRowTender,
  access,
  addMonthTender,
  units,
  inputsTypes,
  getInputsTypesTender,
  getUnits,
}) => {
  const [active, setActive] = useState(null);
  const [tree, setTree] = useState(null);
  const [expandedRowKeys, setExpandeRowKeys] = useState([]);
  const [activeEdition, setActiveEdition] = useState(null);
  const [activeOptions, setActiveOptions] = useState({ option0: true });
  const [fieldsToSave, setFieldsToSave] = useState([]);
  const [isFirstRequest, setIsFirstRequest] = useState(true);

  // eslint-disable-next-line no-unused-vars
  const [savingEdition, setSavingEdition] = useState(false);
  const [lastSavedTree, setLastSavedTree] = useState(null);
  const [inputsTypesDataObj, setInputsTypesDataObj] = useState(null);
  const [unitsDataObj, setUnitsDataObj] = useState(null);
  const { data, isFetching } = baseTender;
  const { data: unitsData, isFetching: isFetchingUnits } = units;
  const { data: inputsTypesData, isFetching: isFetchingInputsTypes } = inputsTypes;

  const createTreeData = (treeData) => {
    if (treeData && treeData.length > 0) {
      let treeObject = {};

      const initialExpandedRowKeys = [];

      treeData.forEach((item) => {
        if (treeObject[item.ID_CC_SUP]) {
          treeObject = update(treeObject, {
            [item.ID_CC_SUP]: {
              $push: [item],
            },
          });
        } else {
          treeObject = update(treeObject, {
            [item.ID_CC_SUP]: {
              $set: [item],
            },
          });
        }

        if (item.TIPO === 1) {
          initialExpandedRowKeys.push(item.ID_CC);
        }
      });

      const newTree = [];
      const paths = {
        [treeObject[0][0].ID_CC]: '0',
      };
      const savePath = (id, path) => {
        if (!paths[id]) {
          paths[id] = path;
        }
      };

      treeObject[0].forEach((current, i) => {
        newTree.push({
          ...current,
          PATH: `${i}`,
          children: getChildren(current.ID_CC, treeObject, `${i}`, savePath, null, 'ID_CC'),
        });
      });

      setTree(newTree);
      setLastSavedTree(newTree);
      if (!expandedRowKeys.length) {
        setExpandeRowKeys(initialExpandedRowKeys);
      }
    } else if (treeData && treeData.length === 0) {
      setTree([]);
      setLastSavedTree([]);
      setExpandeRowKeys([]);
    }
  };

  useEffect(() => {
    if ((!data[selectedWork] || isFirstRequest) && !isFetching) {
      getBaseTender(selectedWork);
      setIsFirstRequest(false);
    } else if (data[selectedWork] && !isFetching) {
      const treeData = get(data, [selectedWork, 'PRESUPUESTO_GG']);
      createTreeData(treeData);
    }

    if (!inputsTypesData && !isFetchingInputsTypes) {
      getInputsTypesTender();
    } else if (inputsTypesData && inputsTypesData.length && !inputsTypesDataObj) {
      const typesObj = {};

      inputsTypesData.forEach((e) => {
        typesObj[e.ID_TIN] = e;
      });
      setInputsTypesDataObj(typesObj);
    }

    if (!unitsData && !isFetchingUnits) {
      getUnits();
    } else if (unitsData && unitsData.length && !unitsDataObj) {
      const unitsObj = {};

      unitsData.forEach((e) => {
        unitsObj[e.ID_UNI] = e;
      });
      setUnitsDataObj(unitsObj);
    }
  }, [selectedWork, inputsTypesData, unitsData]);

  useEffect(() => {
    if (data[selectedWork]) {
      const treeData = get(data, [selectedWork, 'PRESUPUESTO_GG']);
      createTreeData(treeData);
    }
  }, [data]);

  const getCommonProps = (key) => ({
    onClick: () => setActive(key),
    onClose: () => setActive(null),
    show: active === key,
  });

  const changeExpandedRows = (expanded, ID_CC) => {
    if (expanded) {
      setExpandeRowKeys(
        update(expandedRowKeys, {
          $push: [ID_CC],
        })
      );
    } else {
      const index = expandedRowKeys.indexOf(ID_CC);

      if (index >= 0) {
        setExpandeRowKeys(
          update(expandedRowKeys, {
            $splice: [[index, 1]],
          })
        );
      }
    }
  };
  const saveEdition = () => {
    if (fieldsToSave.length) {
      const newItems = fieldsToSave.map((fieldToSave) => {
        const valuesKeys = {};
        fieldToSave.MESES_ITEM.forEach((e) => {
          valuesKeys[`FLUJO_${e.NOMBRE_COL}`] = e.CANTIDAD;
        });

        Object.keys(fieldToSave).forEach((key) => {
          if (key.includes('FLUJO_')) {
            valuesKeys[key] = fieldToSave[key];
          }
        });
        const isNewRecord = validate(fieldToSave.ID_CC);
        const newFieldToSave = {
          ID_CC_SUP: fieldToSave.ID_CC_SUP,
          ID_CC: validate(fieldToSave.ID_CC) ? 0 : fieldToSave.ID_CC,
          ...(!isEmpty(valuesKeys)
            ? {
                MESES_ITEM: get(data, [selectedWork, 'MESES'], [])
                  .filter((e) => valuesKeys[`FLUJO_${e.NOMBRE_COL}`] >= 0)
                  .map((e) => ({ ...e, CANTIDAD: valuesKeys[`FLUJO_${e.NOMBRE_COL}`] })),
              }
            : {}),
          DESCRIPCION_ITEM: fieldToSave.DESCRIPCION_ITEM,
          ID_UNI: fieldToSave.ID_UNI,
          ID_TIN: fieldToSave.ID_TIN,
          ITEM: fieldToSave.ITEM,
          FACTOR_PROY: fieldToSave.FACTOR_PROY,
          PRECIO_ITEM: fieldToSave.PRECIO_ITEM,
          CODIGO_CC: fieldToSave.CODIGO_CC,
          CODIGO_ITEM: fieldToSave.CODIGO_ITEM,
          CANTIDAD_BASE: fieldToSave.CANTIDAD_BASE,
          ...(isNewRecord
            ? {
                CODIGO_ITEM: 'COD000',
                ORDEN: 0,
                CANTIDAD_BASE: 0,
                TOTAL_BASE: 0,
                CANT_AVA: 0,
                TOTAL_AVA: 0,
                MONTO_COSTO_ACUM: 0,
                NUM_IND: 1,
                FACTOR_PROY: fieldToSave.FACTOR_PROY || 1,
                PRECIO_ITEM: fieldToSave.PRECIO_ITEM || 0,
                ...(isEmpty(valuesKeys) ? { MESES_ITEM: [] } : {}),
              }
            : {}),
        };
        return newFieldToSave;
      });
      setSavingEdition(true);
      setBaseTender({
        PRESUPUESTO_GG: newItems,
      })
        .then(() => {
          setSavingEdition(false);
          setActiveEdition('editProjection');
          setFieldsToSave([]);
          setLastSavedTree(tree);
        })
        .catch(() => setSavingEdition(false));
    } else {
      setActiveEdition(null);
      setLastSavedTree(lastSavedTree);
    }
  };

  const editCell = (fieldsToUpdate) => {
    setFieldsToSave(
      update(fieldsToSave, {
        $apply: (fields) => {
          let newFields = [...fields];

          fieldsToUpdate.forEach((current) => {
            const currentIndex = newFields.findIndex((e) => e.ID_CC === current.ID_CC);

            if (currentIndex !== -1) {
              newFields = update(newFields, {
                [currentIndex]: { $merge: current },
              });
            } else {
              newFields = update(newFields, {
                $push: [current],
              });
            }
          });

          return newFields;
        },
      })
    );

    let newTree = [...tree];

    fieldsToUpdate.forEach((element) => {
      const path = element.PATH.split('.');
      const updatedNode = getNodeObjectToUpdate(path, 0, { $merge: element });

      newTree = update(newTree, updatedNode);
    });

    setTree(newTree);
  };

  const addRow = (record) => {
    const id = record.ID_CC;
    changeExpandedRows(true, id);

    const path = record.PATH.split('.');
    // TODO hacer request para crear nueva fila
    const newPath = `${record.PATH}.${get(record, 'children.length', 0)}`;
    const newNode = {
      TIPO: 4,
      children: undefined,
      ITEM: '',
      DESCRIPCION_ITEM: '',
      ID_CC: uuidv4(),
      PATH: newPath,
      ID_CC_SUP: record.ID_CC,
      MESES_ITEM: get(data, [selectedWork, 'MESES'], []).map((e) => ({ ...e, CANTIDAD: 1 })),
      CODIGO_CC: record.CODIGO_CC,
      ORDEN: 0,
      PRECIO_ITEM: 0,
      ID_UNI: 1,
      ID_TIN: 1,
      CANTIDAD_BASE: 0,
      TOTAL_BASE: 0,
      CANT_AVA: 0,
      TOTAL_AVA: 0,
      MONTO_COSTO_ACUM: 0,
      NUM_IND: 1,
      FACTOR_PROY: 1,
    };
    const updatedNode = getNodeObjectToUpdate(path, 0, {
      children: record.children
        ? {
            $push: [newNode],
          }
        : { $set: [newNode] },
    });

    setFieldsToSave(update(fieldsToSave, { $push: [newNode] }));
    const newTree = update(tree, updatedNode);
    setTree(newTree);
  };

  return (
    <div className="tenderGG">
      <Row gutter={[30, 30]}>
        <Col className="first-buttons-col" span={24}>
          {access.GG_ESTUDIO_AJUSTAR && (
            <>
              {activeEdition ? (
                <div className="edit-buttons ">
                  <Button
                    type="primary"
                    disabled={isFetching}
                    onClick={() => addMonthTender(selectedWork)}
                  >
                    Agregar Mes al Flujo
                  </Button>
                  <Button
                    onClick={() => {
                      setActiveEdition(null);
                      setTree(lastSavedTree);
                      setFieldsToSave([]);
                    }}
                    disabled={savingEdition}
                  >
                    Cancelar
                  </Button>
                  <Button
                    disabled={savingEdition || !fieldsToSave.length}
                    type="primary"
                    onClick={saveEdition}
                  >
                    Guardar
                  </Button>
                </div>
              ) : (
                <>
                  <div>
                    <Button
                      type="primary"
                      disabled={isFetching}
                      onClick={() => {
                        setActiveEdition('editProjection');
                      }}
                    >
                      Ajustar GG Estudio
                    </Button>
                  </div>
                  <div>
                    <ViewOptions
                      defaultActiveOptions={activeOptions}
                      updateOptions={(opt) => setActiveOptions(opt)}
                      activeOptionsProps={activeOptions}
                      options={['Mostrar Flujo', 'Mostrar Descripción']}
                      {...getCommonProps('view')}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </Col>
      </Row>
      <Row gutter={[30, 30]}>
        <Col className="production-col mt-3 pt-0" span={24}>
          <Table
            className="common-table"
            height={window.innerHeight - 160}
            hover={false}
            rowKey="ID_CC"
            bordered
            headerHeight={75}
            rowHeight={28}
            virtualized
            shouldUpdateScroll={false}
            isTree
            locale={{
              emptyMessage: 'No hay datos para mostrar',
              loading: 'Cargando...',
            }}
            loading={isFetching || savingEdition}
            data={tree || []}
            rowClassName={(record) => {
              if (record) {
                let type = 'level';
                if (record.TIPO === 1) {
                  type = 'green-row';
                } else if (record.TIPO === 3) {
                  type = 'green-row';
                }
                return type;
              }
              return '';
            }}
            renderTreeToggle={(_icon, _row, expanded) => {
              if (expanded) {
                return <CaretUpOutlined />;
              }
              return <CaretDownOutlined />;
            }}
            expandedRowKeys={expandedRowKeys}
            onExpandChange={(expanded, rowData) => {
              changeExpandedRows(expanded, rowData.ID_CC);
            }}
          >
            {columns(activeOptions, activeEdition, editCell, deleteRowTender, setActiveEdition, {
              addRow,
              months: get(data, [selectedWork, 'MESES'], []),
              units: (unitsData || []).sort(sortSelectArrays('NOMABR_UNI')),
              inputsTypes: (inputsTypesData || []).sort(sortSelectArrays('NOMABR_TIN')),
              unitsObj: unitsDataObj,
              inputsTypesObj: inputsTypesDataObj,
              isFetchingUnits,
              isFetchingInputsTypes,
            })}
          </Table>
        </Col>
      </Row>
    </div>
  );
};

TenderGG.propTypes = {
  selectedWork: PropTypes.number.isRequired,
  baseTender: PropTypes.object.isRequired,
  getBaseTender: PropTypes.func.isRequired,
  deleteRowTender: PropTypes.func.isRequired,
  access: PropTypes.bool.isRequired,
  setBaseTender: PropTypes.func.isRequired,
  addMonthTender: PropTypes.func.isRequired,
  units: PropTypes.array.isRequired,
  inputsTypes: PropTypes.array.isRequired,
  getUnits: PropTypes.func.isRequired,
  getInputsTypesTender: PropTypes.func.isRequired,
};

export default connect(
  ({ works, baseTender, budget: { units } }) => ({
    selectedWork: works.selectedWork,
    baseTender,
    units,
    inputsTypes: baseTender.inputsTypes,
  }),
  (dispatch) => ({
    getBaseTender: bindActionCreators(getBaseTenderAction, dispatch),
    setBaseTender: bindActionCreators(setBaseTenderAction, dispatch),
    deleteRowTender: bindActionCreators(deleteRowTenderAction, dispatch),
    addMonthTender: bindActionCreators(addMonthTenderAction, dispatch),
    getUnits: bindActionCreators(getUnitsAction, dispatch),
    getInputsTypesTender: bindActionCreators(getInputsTypesTenderAction, dispatch),
  })
)(TenderGG);
