import _ from 'lodash';
import { message } from 'antd';
import api, { getHeaders } from '../utils/api';
import {
  SET_ERROR_ENCLOSURE,
  GET_LISTADO_PANEL,
  SET_PANEL,
  GET_USER,
  GET_GMAIL_ACCOUNT,
  SET_GMAIL_ACCOUNT,
  SET_UNLINK_GMAIL,
  SET_PASSWORD,
  GET_PROJECT_USR,
  SET_USER,
} from '../reducers/configuration';
import store from '../store';

export const setError = (error = '') => ({
  type: SET_ERROR_ENCLOSURE,
  payload: { error },
  result: { error },
});
export const getPanelList = () => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_EMP: 1,
    };
    const errorMessage = 'No se cargaron éxitosamente la configuracion.';
    api
      .post(`api/PanelControl/MiPanel`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: GET_LISTADO_PANEL, payload: { isPanel: res.data } });
          resolve(res.data);
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });
export const setPanel = (column, value, idColumn, idFila) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_EMP: 1,
      COLUMNA: column,
      VALOR: column === 'VISIBLE' ? (value === false ? 0 : 1) : value,
      ID_COLUMNA: idColumn,
      ID_FILA: idFila,
    };
    const errorMessage = 'No se cargaron éxitosamente la configuracion.';
    api
      .post(`api/PanelControl/SetPanel`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: SET_PANEL, payload: { isSavePanel: res.data } });
          resolve(res.data);
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });
export const getUser = () => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      ID_EMP: 1,
    };
    const errorMessage = 'No se cargaron éxitosamente los Datos Personales.';
    api
      .post(`api/Login/DatosPersonales`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: GET_USER, payload: { isUser: res.data } });
          resolve(res.data);
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });
export const getGmailAccount = () => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      NOMBRE_USU: _.get(store.getState(), 'auth.user.username', ''),
    };
    const errorMessage = 'No se cargaron éxitosamente los Datos de Gmail.';
    api
      .post(`api/Usuario/VerificarLoginGmail`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: GET_GMAIL_ACCOUNT, payload: { Account: res.data } });
          resolve(res.data);
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });
export const setGmailAccount = (mail, lastName, name, id, picture, activation) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      NOMBRE_USU: _.get(store.getState(), 'auth.user.username', ''),
      EMAIL_GMAIL: mail,
      APELLIDO_GMAIL: lastName,
      NOMBRE_GMAIL: name,
      ID_GMAIL: id,
      PICTURE_GMAIL: picture,
      ACTIVACION_GMAIL: activation,
    };
    const errorMessage = 'No se actualizo éxitosamente los Datos de Gmail.';
    api
      .post(`api/Usuario/ActivarGmailDirecto`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: SET_GMAIL_ACCOUNT, payload: { answer: res.data } });
          resolve(res.data);
          console.log(res.data);
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });
export const setUnlink = () => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      NOMBRE_USU: _.get(store.getState(), 'auth.user.username', ''),
    };
    const errorMessage = 'No se actualizo éxitosamente los Datos de Gmail.';
    api
      .post(`api/Usuario/DesactivarLoginGmail`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: SET_UNLINK_GMAIL, payload: { unlink: res.data } });
          resolve(res.data);
          console.log(res.data);
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });
export const setPassword = (newKeyField, idUser, currentKeyField) => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      ID_USU: idUser,
      NEW_PASSWORD: newKeyField,
      CURRENT_PASSWORD: currentKeyField,
      Empresa: _.get(store.getState(), 'auth.user.client', ''),
    };
    const errorMessage = 'No se actualizo la clave éxitosamente.';
    api
      .post(`api/PanelControl/CambiarPass`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: SET_PASSWORD, payload: { key: res.data } });
          resolve(res.data);
          console.log(res.data);
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });
export const getProjectUsr = () => (dispatch) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      USUARIO: _.get(store.getState(), 'auth.user.username', ''),
      EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
      Vigente_Obr: 1,
    };
    const errorMessage = 'No se actualizo listado de proyectos.';
    api
      .post(`api/Obra/ObtenerUsuario`, requestBody, getHeaders())
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: GET_PROJECT_USR, payload: { project: res.data } });
          resolve(res.data);
          console.log(res.data);
        } else {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(res.data);
        }
      })
      .catch((err) => {
        dispatch(setError(errorMessage));
        message.error(errorMessage, 4);
        reject(err);
      });
  });
export const setUser =
  (fullNameField, phoneField, mailField, projectField, idUser, countryField) => (dispatch) =>
    new Promise((resolve, reject) => {
      const requestBody = {
        NOMBRE_USU: _.get(store.getState(), 'auth.user.username', ''),
        EMPRESA: _.get(store.getState(), 'auth.user.client', ''),
        NOMCOM_USU: fullNameField,
        CELULAR: phoneField,
        EMAIL_USU: mailField,
        ID_ObrPred: projectField,
        ID_USU: idUser,
        PAIS_CELULAR: countryField,
      };
      const errorMessage = 'No se actualizo éxitosamente.';
      api
        .post(`api/Login/UpdDatosPersonales`, requestBody, getHeaders())
        .then((res) => {
          if (res.status === 200) {
            dispatch({ type: SET_USER, payload: { request: res.data } });
            resolve(res.data);
            console.log(res.data);
          } else {
            dispatch(setError(errorMessage));
            message.error(errorMessage, 4);
            reject(res.data);
          }
        })
        .catch((err) => {
          dispatch(setError(errorMessage));
          message.error(errorMessage, 4);
          reject(err);
        });
    });
