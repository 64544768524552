import React, { useEffect } from 'react';
import 'devextreme/data/odata/store';
import PropTypes from 'prop-types';
import qs from 'querystring';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LoadingOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import 'react-phone-input-2/lib/style.css';
import DataGrid, {
  Column,
  Sorting,
  Button,
  SearchPanel,
  Paging,
  Scrolling,
  LoadPanel,
} from 'devextreme-react/data-grid';
import { setInformationFromPaymentURL as setInformationFromPaymentURLAction } from '../../actions/auth';
import { getPanelList as getPanelListAction } from '../../actions/usercontrol';
import './index.scss';
import 'devextreme/excel_exporter';

const UserControl = ({
  auth,
  ItemsInRedux,
  getPanelList,
  location,
  setInformationFromPaymentURL,
}) => {
  const { isPanel } = ItemsInRedux;

  const { foco_client, foco_payment_id, foco_token, foco_username } = qs.parse(
    location.search.replace('?', '')
  );

  useEffect(() => {
    if (foco_token) {
      setInformationFromPaymentURL(foco_client, foco_payment_id, foco_token, foco_username);
      sessionStorage.setItem('foco_token', foco_token);
    }
  });

  useEffect(() => {
    if (auth.token && auth.user.username) {
      (async () => {
        await getPanelList();
      })();
    }
  }, [auth]);

  const getHeight = () => {
    return window.innerHeight - 10;
  };
  const history = useHistory();

  function handleRoute(e) {
    history.push(`/details-user?id=${e}`);
  }
  const GenericoCell = (e) => {
    console.log(e);
    return (
      <div>
        <div className="flexbox-container">
          <span>aaaa</span>
        </div>
      </div>
    );
  };

  return (
    <div className="usr">
      {!isPanel ? (
        <div className="spin">
          <LoadingOutlined />
        </div>
      ) : (
        <>
          <DataGrid
            id="gridContainer"
            dataSource={isPanel}
            showBorders="true"
            width="100%"
            height={getHeight}
            // allowColumnReordering="true"
            // allowColumnResizing="true"
            columnAutoWidth="true"
            showColumnLines="true"
            showRowLines="true"
            rowAlternationEnabled="true"
            keyExpr="IdUsuario"
          >
            {/* <FilterRow visible="true" /> */}
            <Scrolling useNative="true" />
            <LoadPanel enabled="true" />
            <SearchPanel visible="true" width="400" />
            <Paging enabled="true" />
            <Sorting mode="none" />
            <Column type="buttons" width="100" fixed="true" fixedPosition="left" caption="Opciones">
              <Button
                hint="Editar"
                icon="fas fa-user-edit"
                onClick={(e) => {
                  handleRoute(e.row.data.IdUsuario);
                }}
              />
              <Button
                hint="Eliminar"
                icon="fas fa-minus-circle" // Font Awesome 5
                color="red"
                // onClick={(e) => {
                //   (async () => {
                //     setShowTextProject(e.row.data.NOM_ABR_OBR);
                //     await getOptions(e.row.data.ID_OBR);
                //   })();

                //   setShowProject(e.row.data.ID_OBR);
                //   setShow(true);
                // }}
              />
            </Column>
            <Column
              dataField="NombreUsu"
              caption="Nombre Usuario"
              fixedPosition="left"
              alignment="left"
            />
            <Column
              dataField="NombreCompleto"
              caption="Nombre Completo"
              fixedPosition="left"
              alignment="left"
            />
            <Column dataField="Estado" caption="Estado" alignment="center" />
            <Column dataField="NombreCargo" caption="Cargo" fixedPosition="left" alignment="left" />
            <Column dataField="Email" caption="Email" fixedPosition="left" alignment="left" />
            <Column dataField="Celular" caption="Celular" fixedPosition="left" alignment="right" />
            <Column
              dataField="Proyectos"
              caption="Proyectos"
              fixedPosition="left"
              alignment="right"
              cellRender={GenericoCell}
            />
            <Column
              dataField="Perfiles"
              caption="Perfiles"
              fixedPosition="left"
              alignment="right"
            />
            <Column dataField="Modulos" caption="Modulos" fixedPosition="left" alignment="right" />
            <Column
              dataField="PagActivas"
              caption="Pag. Activas"
              fixedPosition="left"
              alignment="right"
            />
            <Column
              dataField="UltAcceso"
              caption="Ultimo Acceso"
              fixedPosition="left"
              alignment="center"
            />
            ;
          </DataGrid>
        </>
      )}
    </div>
  );
};
UserControl.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.object,
    token: PropTypes.string,
  }).isRequired,
  ItemsInRedux: PropTypes.shape({
    isPanel: PropTypes.array,
  }).isRequired,
  getPanelList: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  setInformationFromPaymentURL: PropTypes.func.isRequired,
};

export default connect(
  (store) => ({
    auth: store.auth,
    ItemsInRedux: store.UserControl,
  }),
  (dispatch) => ({
    setInformationFromPaymentURL: bindActionCreators(setInformationFromPaymentURLAction, dispatch),
    getPanelList: bindActionCreators(getPanelListAction, dispatch),
  })
)(UserControl);
