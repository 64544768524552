import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Switch } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

import './index.scss';

const defaultOptions = [
  'Mostrar Factor',
  'Mostrar Plan de Cuentas',
  'Mostrar Contrato Base',
  'Mostrar Contrato Vigente',
  'Mostrar Forecast',
  'Mostrar Producción',
  'Mostrar Recursos',
  'Gestion Precios del Contrato',
  'Mostrar Venta',
  'Mostrar CD',
  'Mostrar Variación',
];

const ViewOptions = ({
  updateOptions,
  onClick,
  onClose,
  options,
  show,
  defaultActiveOptions,
  activeOptionsProps,
  className,
}) => {
  const [activeOptions, setActiveOptions] = useState(defaultActiveOptions);
  const [lastSavedOptions, setLastSavedOptions] = useState(defaultActiveOptions);

  useEffect(() => {
    setActiveOptions(activeOptionsProps);
    setLastSavedOptions(activeOptionsProps);
  }, [activeOptionsProps]);

  useEffect(() => {
    setActiveOptions(lastSavedOptions);
  }, [show]);

  return (
    <div className={`view-options ${className}`}>
      <Button className="d-flex align-items-center" onClick={() => onClick()} type="Default">
        <EyeOutlined className="viewIcon" />
        Vista
      </Button>
      {show && (
        <div className="options-container">
          <div className="options-header">
            <div />
            <span>Opciones de vista</span>
            <i className="fa fa-times" onClick={() => onClose()} />
          </div>
          <div className="options">
            {options.map((key, index) => (
              <div className="switch-row">
                {key}
                <Switch
                  className="switch"
                  checked={activeOptions[`option${index}`]}
                  onChange={(checked) =>
                    setActiveOptions({ ...activeOptions, [`option${index}`]: checked })
                  }
                />
              </div>
            ))}
          </div>
          <div className="buttons">
            <Button
              type="default"
              className="mr-1"
              onClick={() => {
                onClose();
                setActiveOptions(lastSavedOptions);
              }}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => {
                updateOptions(activeOptions);
                setLastSavedOptions(activeOptions);
                onClose();
              }}
              type="primary"
            >
              Aplicar
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

ViewOptions.propTypes = {
  updateOptions: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  defaultActiveOptions: PropTypes.object.isRequired,
  activeOptionsProps: PropTypes.object.isRequired,
  options: PropTypes.array,
  className: PropTypes.string,
};

ViewOptions.defaultProps = {
  options: defaultOptions,
  className: '',
};

export default ViewOptions;
